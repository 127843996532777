import { defineStore } from 'pinia'

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken } from "firebase/messaging";


import { NOTIFICATIONS } from '../mutation-types';
import Bowser from "bowser";
let store = {
	
		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		status:  {} as any,
		markets:  {} as any,
	
}

import {parse} from '@/helpers/uaparser';

import { parseDevice } from '@/helpers/deviceParser';

const config = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
	messagingSenderId: import.meta.env.VITE_FIREBASE_SENDER_ID
};

import { register } from 'register-service-worker';
import { getStore } from '..';

export interface NotificationsState {
	isSupported: boolean;
	isSubscribed: boolean;
	isBlocked: boolean;
	isBlockedBrave: boolean;
	device: string | null;
	token: string | null;
	messaging: any;
	loaded: boolean;
	sw_registration: ServiceWorkerRegistration | null;
	initialized: boolean;
}
export const useNotificationsStore = defineStore('notifications', {
  state: () => {
	store = getStore()

    const initialState: NotificationsState =  {
      isSupported: true,
      isSubscribed: false,
      isBlocked: false,
      isBlockedBrave: false,
      device: null,
      messaging: null,
      loaded: false,
      sw_registration: null,
      token: null,
      initialized: false
    };

    return initialState
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
		// register the firebase service worker and callback to the getNotificationToken action
		async registerServiceWorker(callback: any) {
			try {
				const currentStore = this
				register(`${import.meta.env.BASE_URL}firebase/firebase-messaging-sw.js`, {
					ready() {},
					registered(registration) {
						currentStore.sw_registration = registration;
						if (callback && callback.callbackAction) {
							if (callback.callbackAction == 'removeNotificationToken') {
								return currentStore.removeNotificationToken( )
							}

							if (callback.callbackAction == 'removeAllNotificationTokens') {
								return currentStore.removeAllNotificationTokens( )
							}
							if (callback.callbackAction == 'getNotificationToken') {
								return currentStore.getNotificationToken( callback.payload )
							}
							return
						} 
						else return;
					},
					error(error) {
						console.error('Error during firebase worker registration:', error);
					}
				});
			} catch (err) {
				console.log('error registering service worker', err);
			}
		},
		// Delete a notification token from firebase and update the user in the database
		async removeNotificationToken() {
			if (!this.sw_registration) {
				return this.registerServiceWorker({ callbackAction: 'removeNotificationToken', payload: false });
			}

			getToken(this.messaging, { serviceWorkerRegistration: this.sw_registration })
			.then(async currentToken => {
				this[NOTIFICATIONS.SET_TOKEN](currentToken);

				const storeUser = store.user.data;
				const oldToken = localStorage.getItem('notification:' + storeUser?.id)
				localStorage.setItem('notification:' + storeUser?.id,currentToken)


				deleteToken(this.messaging)
				.then(message => {
					const notification = {
						token: currentToken,
						timestamp: Date.now(),
						device: this.device,
						subscribe: false,
						unsubscribe: true,
						unsubscribeAll: false,
						oldToken
					};
					store.user.updateUserNotifications( { notificationJSON: notification, notificationBoolean: false });
				})
				.catch(err => {
					return err;
				});

			});
		},
		// Delete all notifications token from firebase and update the user in the database
		async removeAllNotificationTokens() {
			if (!this.sw_registration) {
				return this.registerServiceWorker({ callbackAction: 'removeAllNotificationTokens', payload: false });
			}
			const storeUser = store.user.data;

			if (!storeUser) {
				return
			}
			const allAlerts = Object.keys(storeUser.push_notifications_json || {});

				getToken(this.messaging, { serviceWorkerRegistration: this.sw_registration })
				.then(async currentToken => {
					this[NOTIFICATIONS.SET_TOKEN](currentToken);

					const storeUser = store.user.data;
					if (storeUser) {
						const oldToken = localStorage.getItem('notification:' + storeUser.id)
						localStorage.setItem('notification:' + storeUser.id,currentToken)
					

					deleteToken(this.messaging)
					.then(message => {
						const notification = {
							token: currentToken,
							timestamp: Date.now(),
							device: this.device,
							subscribe: false,
							unsubscribe: true,
							unsubscribeAll: true,
							oldToken
						};
						store.user.updateUserNotifications({ notificationJSON: notification, notificationBoolean: false });
					})
					.catch(err => {
						return err;
					});
				}
			})
		},
		// Get a firebase notification token and update the user notifiations data
		async getNotificationToken(getNotificationOptions:any) {
			if (!this.sw_registration) {
				return this.registerServiceWorker({ callbackAction: 'getNotificationToken', payload: getNotificationOptions } );
			}

			getToken(this.messaging, { serviceWorkerRegistration: this.sw_registration })
			.then(async currentToken => {
				this[NOTIFICATIONS.SET_TOKEN](currentToken);
				if (currentToken) {

					
					const storeUser = store.user.data;
					if (!storeUser) {
						return
					}

					const oldToken = localStorage.getItem('notification:' + storeUser.id)
					localStorage.setItem('notification:' + storeUser.id,currentToken)

					const notification = {
						token: currentToken,
						timestamp: Date.now(),
						device: this.device,
						subscribe: getNotificationOptions.subscribe === true,
						unsubscribe: false,
						unsubscribeAll: false,
						oldToken
					};

					this[NOTIFICATIONS.TOGGLE_BLOCKED](false);
					this[NOTIFICATIONS.TOGGLE_SUBSCRIBED](true);

					await store.modals.toggleHeaderFirebaseModal(false);
					await store.modals.toggleBrowserAlertsModal(false);

					await store.user.updateUserNotifications({ notificationJSON: notification, notificationBoolean: true, suppressMessage: getNotificationOptions.suppressMessage });
					this[NOTIFICATIONS.SET_INITIALIZED](true);
				} else {
					if (Notification.permission === 'denied') {
						this[NOTIFICATIONS.TOGGLE_BLOCKED](true);
					}
					// Show permission request.
					this[NOTIFICATIONS.TOGGLE_SUBSCRIBED](false);
					this[NOTIFICATIONS.SET_INITIALIZED](true);
				}
			})
			.catch(async err => {
				if (err.toString().toLowerCase().includes('registration failed - push service error')) {
					if (getNotificationOptions.initialize !== true) {
						await store.modals.toggleBraveAlertsModal( true);
						this[NOTIFICATIONS.TOGGLE_BLOCKED_BRAVE](true);

					}
				}

				//'Registration failed - push service error'
				await store.modals.toggleHeaderFirebaseModal(false);
				await store.modals.toggleBrowserAlertsModal(false);


				if (Notification.permission === 'denied') {
					this[NOTIFICATIONS.TOGGLE_BLOCKED](true);
				}

				this[NOTIFICATIONS.SET_INITIALIZED](true);
				this.loaded = true;
			});
		},
		// initialise firebase and refresh any expired notification tokens
		async handleNotifications() {
			if (this.loaded) return;
			this.loaded = true;

			if ('serviceWorker' in navigator && 'PushManager' in window) {
				const app = initializeApp(config);
				this.messaging = getMessaging(app);
			}

			const browser = parse(navigator.userAgent);
			const bowser = Bowser.getParser(window.navigator.userAgent);
			let device = browser.device_type + '-' + browser.os.toLowerCase() + '-' + bowser.getBrowser().name + '-' + parseDevice();
			device = device.replace(/\s+/g, '_');

			this[NOTIFICATIONS.SET_DEVICE](device);

			if ('serviceWorker' in navigator && 'PushManager' in window) {
				console.log('Service Worker and Push is supported', Notification.permission);

				if (Notification.permission == 'granted') {


					await this.getNotificationToken({ suppressMessage: true, initialize: true });
				} else {
					if (Notification.permission === 'denied') {
						this[NOTIFICATIONS.TOGGLE_BLOCKED](true);
					}
					this[NOTIFICATIONS.SET_INITIALIZED](true);
				}



			} else {
				this[NOTIFICATIONS.TOGGLE_SUPPORTED](false);
				this[NOTIFICATIONS.SET_INITIALIZED](true);
				return;
			}

			const storeUser = store.user.data;


			if (storeUser && !storeUser.push_notifications_enabled && Notification.permission === 'denied') {
				this[NOTIFICATIONS.TOGGLE_BLOCKED](true);
				this[NOTIFICATIONS.SET_INITIALIZED](true);
				return;
			}

		},
		setDevice( device: string) {
		 this[NOTIFICATIONS.SET_DEVICE](device);
		},
		toggleSupported( toggle: boolean) {
			this[NOTIFICATIONS.TOGGLE_SUPPORTED](toggle);
		},
		toggleBlocked( toggle: boolean) {
			this[NOTIFICATIONS.TOGGLE_BLOCKED](toggle);
		},
			[NOTIFICATIONS.SET_DEVICE](device: string) {
				this.device = device;
			},
	
			[NOTIFICATIONS.SET_TOKEN](token: string) {
				this.token = token;
			},
	
			[NOTIFICATIONS.SET_INITIALIZED](initialized: boolean) {
				this.initialized = initialized;
			},
	
			[NOTIFICATIONS.TOGGLE_BLOCKED](isBlocked: boolean) {
				this.isBlocked = isBlocked;
			},
	
			[NOTIFICATIONS.TOGGLE_BLOCKED_BRAVE](isBlockedBrave: boolean) {
				this.isBlockedBrave = isBlockedBrave;
			},
	
			[NOTIFICATIONS.TOGGLE_SUPPORTED](isSupported: boolean) {
				this.isSupported = isSupported;
			},
	
			[NOTIFICATIONS.TOGGLE_SUBSCRIBED](isSubscribed: boolean) {
				this.isSubscribed = isSubscribed;
			},
	
	
		
	},
})