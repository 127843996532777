query getNotificationTemplate($id: String) {
    getNotificationTemplate(id: $id) {
        id
        title
        description
        lang
        aws
        timestamp
        text
        filter
        status
        template_type   
        link
        text_translate
        title_translate
    }
}