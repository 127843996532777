query getTemplate($id: String, $translate: String) {
    getTemplate(id: $id, translate: $translate) {
        id
        title
        description
        lang
        aws
        timestamp
        from_address
        html
        text
        filter
        status
        template_type   
        noTrackingOption
        title_translate
        text_translate
        html_translate
    }
}