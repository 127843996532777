<template>
	<div class="dimmer" :class="{ 'dimmer-active': active }"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	props: {
		active: Boolean
	}
});
</script>

<style>
.dimmer {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100vw;
	z-index: 60;
	background: #000;
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s cubic-bezier(0.2, 0.2, 0.2, 1);
}

.dimmer-active {
	visibility: visible;
	opacity: 0.4;
}
</style>
