<template>
	<div class="tp-card has-font-manrope">
		<div class="tp-name">
			{{name}}
		</div>
		<div>
			<img src="@/assets/auth/tpstar.svg" class="tp-star" alt="star" />
			<img src="@/assets/auth/tpstar.svg" class="tp-star" alt="star" />
			<img src="@/assets/auth/tpstar.svg" class="tp-star" alt="star" />
			<img src="@/assets/auth/tpstar.svg" class="tp-star" alt="star" />
			<img src="@/assets/auth/tpstar.svg" class="tp-star" alt="star" />
		</div>
		<div class="tp-text">
			{{text}}
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

//vuex

/* @group Components_Auth */
/*
  <h4> <b>Account Deleted Page</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'TrustpilotCard',
	props: {
		// Message shown when no market provided
		name: {
			type: String,
		},
		text: {
			type: String,
		}
	},
	data() {
		return {};
	},
	computed: {

	},
	async mounted() { },
	methods: {

		// Submits login request
		async formAction() { }
	}
});
</script>
<style scoped>
 .tp-card {
	display: flex;
	width: 320px;
	min-width: 320px;
	padding: 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	border-radius: 8px;
	background: #FFF;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
 }
 .tp-star {
	width: 24px;
	height: 24px;
	margin-right: 4px;
 }
 .tp-text {
	text-align: left;
	color: var(--text-text-secondary, #686475);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.14px;
 }
 .tp-name {
	text-align: left;
	color: var(--text-text-primary, #333);
	font-size: 16px;
	font-weight: 600;
	line-height: 22px; /* 137.5% */
	letter-spacing: -0.16px;
 }
</style>
