<template>
	<section>
		<b-modal :modelValue="showKYCCaptureModal" has-modal-card @close="closeModal(false)" :can-cancel="true">
			<div :class="{
				terminal: !mobile,
				'modal-card': !mobile,
				'modal-card-mobile': mobile
			}">
				<div class="deposit-modal-spacing has-text-left" v-if="!initializing && sumsub_data">
					<div class="header-bar-global">
						<button class="button back-btn" @click="closeModal(false)">
							<img src="@/assets/funds/arrow-left.svg" alt="Back" />
						</button>
						{{ $t('VERIFY_IDENTITY') }}
					</div>

					<div id="sumsub-websdk-container"></div>

					<!-- Mobile View -->
					<div v-if="mobile">

						<div
							class="card-section highlighted inner-card mt-20 is-flex is-align-items-center is-flex-direction-row">
							<img class="helpful-icon" src="@/assets/kyc/speedometer-icon.svg" />
							<p class="is-size-14">{{ $t('kyc.TAKES_5_MINUTES') }}</p>
						</div>
					</div>

					<!-- Desktop View -->
					<div v-else>

						<div
							class="card-section highlighted inner-card mt-20 is-flex is-align-items-center is-flex-direction-row">
							<img class="helpful-icon" src="@/assets/kyc/speedometer-icon.svg" />
							<p class="is-size-14">{{ $t('kyc.TAKES_5_MINUTES') }}</p>
						</div>
					</div>

					<!-- <header class="modal-header has-text-centered">
							<p class="has-text-centered">{{ $t('kyc.QR_SCAN_HEADING') }}</p>
						</header>

						<div v-if="mobile">
							<p
								class="mt-5 is-size-6"
								v-html="
									$t('kyc.ID_ANALYZER_LINK_MOBILE', { reference: sumsub_data.reference, url: sumsub_data.url })
								"
							></p>

							<p class="mt-5 is-size-6">{{ $t('kyc.SCAN_QR_MOBILE') }}</p>
							<p class="mt-5">
								<img :src="sumsub_data.qrcode" alt="QR Code" />
							</p>
						</div>

						<div v-else>
							<figure class="has-text-centered">
								<img class="scan-img" src="@/assets/icons/qr-scan.png" :alt="$t('CLOCK_REVIEW_PENDING_ICON')" />
							</figure>

							<p class="mt-5 is-size-6">{{ $t('kyc.SCAN_QR_DESKTOP') }}</p>
							<p class="mt-5">
								<img :src="sumsub_data.qrcode" alt="QR Code" />
							</p>

							<p
								class="mt-5 is-size-6"
								v-html="
									$t('kyc.ID_ANALYZER_LINK_DESKTOP', { reference: sumsub_data.reference, url: sumsub_data.url })
								"
							></p>
						</div>

						<button class="button big-outlined-button is-thick is-size-6 transition-faster mt-20">
							{{ $t('CLOSE_DIALOG') }}
						</button> -->
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { parse } from '@/helpers/uaparser';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { copyToClipboard } from '@/helpers/utils';
import { createPopover } from '@typeform/embed'
import util from '@/services/shared';
import snsWebSdk from '@sumsub/websdk';

/* @group Components_Modals */
/*
  <h4> <b> New portfolio detected </b> </h4>
  <br> Airdrop message - 10k MPH added to wallet
*/
export default defineComponent({
	name: 'KYCCaptureModal',
	data() {
		return {
			initializing: true,
			sumsub_data: null as any,
			os: '',
			mobile: false,
			matiDisabled: false,
			geoBlocked: false,
			geoLocation: { country_code: '', country: '' } as { country_code?: string, country?: string, Error?: any },
			snsWebSdkInstance: null as any
		};
	},
	watch: {
		showKYCStatusModal(nv) {
			if (nv) {
				this.toggleKYCCaptureModal(false);
			}
		},
		showClaimAirdropModal(nv) {
			if (nv) {
				this.toggleKYCCaptureModal(false);
			}
		},
		user(nv) {
			if (nv.allow_kyc !== true) {
				this.matiDisabled = true;
			} else {
				this.matiDisabled = false;
			}
		}
	},

	computed: {
		...mapState(useModalsStore, {
			showKYCCaptureModal: (state) => state.showKYCCaptureModal,
			showKYCStatusModal: (state) => state.showKYCStatusModal,
			showClaimAirdropModal: (state) => state.showClaimAirdropModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),

	},
	async created() {
		if (this.user?.allow_kyc !== true) {
			this.matiDisabled = true;
		} else {
			this.matiDisabled = false;
		}
	},
	async mounted() {
		if (this.user?.allow_kyc !== true) {
			this.matiDisabled = true;
		} else {
			this.matiDisabled = false;
		}

		this.checkLocation();

	},
	methods: {
		...mapActions(useModalsStore, {
			toggleKYCCaptureModal: 'toggleKYCCaptureModal',
		}),
		...mapActions(useUserStore, {
			startKYC: 'startKYC',
			getTokenUserData: 'getTokenUserData',
			updateUserPayload: 'updateUserPayload',
			checkGeoblock: 'checkGeoblock',
		}),

		async closeModal(closeOption: boolean) {
			this.toggleKYCCaptureModal(closeOption);
		},
		copyLink() {
			copyToClipboard(this.sumsub_data.url, this);
		},
		async getNewAccessToken() {
			const result = await this.startKYC({
				user_id: this.user?.id
			});

			this.sumsub_data = result;

			return this.sumsub_data.token;

		},
		launchSumsub() {
			if (this.snsWebSdkInstance && document.getElementById('sumsub-websdk-container')) {
				this.snsWebSdkInstance.launch('#sumsub-websdk-container')
			} else {
				setTimeout(() => {
					this.launchSumsub();
				}, 50)

			}

		},
		async loadKYC() {
			const result = await this.startKYC({
				user_id: this.user?.id
			});

			this.sumsub_data = result;

			if (this.sumsub_data && this.sumsub_data.token) {

				this.snsWebSdkInstance = snsWebSdk.init(
					this.sumsub_data.token,
					// token update callback, must return Promise
					// Access token expired
					// get a new one and pass it to the callback to re-initiate the WebSDK
					() => this.getNewAccessToken()
				)
					.withConf({
						lang: this.$i18n.locale || 'en', //language of WebSDK texts and comments (ISO 639-1 format)
						email: this.user?.email || '',
						//phone: applicantPhone,
						//i18n: customI18nMessages, //JSON of custom SDK Translations
						// uiConf: {
						// 	customCss: "https://url.com/styles.css"
						// 	// URL to css file in case you need change it dynamically from the code
						// 	// the similar setting at Customizations tab will rewrite customCss
						// 	// you may also use to pass string with plain styles `customCssStr:`
						// },
					})
					.withOptions({ addViewportTag: false, adaptIframeHeight: true })
					// see below what kind of messages WebSDK generates
					.on('idCheck.stepCompleted', (payload) => {
						console.log('stepCompleted', payload)
					})
					.on('idCheck.onError', (error) => {
						console.log('onError', error)
					})
					.build();

				this.launchSumsub()



			}
			const browser = parse(navigator.userAgent);
			this.os = browser.os.toLowerCase();
			if (this.os == 'ipad' || this.os == 'iphone') {
				this.os = 'ios';
			}

			if (this.os && (this.os == 'ios' || this.os == 'android')) {
				this.mobile = true;
			}

			this.initializing = false;
			if (this.mobile) window.open(this.sumsub_data.url, '_blank'); // Auto launch in new window on mobile

			// setTimeout(async () => {
			// 	const user_data = await this.getTokenUserData({
			// 			loading: false,
			// 			component: this
			// 		});	

			// 	const country = user_data.ip_country  || user_data.document_country;	

			// 	if (user_data.kyc_status == 'started' && !user_data.payload.kycSurvey  ) {

			// 		this.updateUserPayload({payloadOption: 'kycSurvey', payloadValue: 'true'});

			// 		const { open } = createPopover('hQyCbwCR', {
			// 			container: document.getElementById('typeform-trade'),
			// 			chat: true,
			// 			hideHeaders: true,
			// 			opacity: 100,
			// 			iframeProps: {title: 'KYC Dropoff'},
			// 			transitiveSearchParams: true,
			// 			disableScroll: true,
			// 			autoResize: true,
			// 			medium: 'snippet',
			// 			hidden: {
			// 				eth_address: this.user.eth_address,
			// 				country
			// 			},
			// 		});

			// 		open();			
			// 	}

			// }, 480000)
		},
		async checkLocation() {
			this.geoLocation = await util.getLocation();

			const geoLoc = await this.checkGeoblock({ location: this.geoLocation.country_code });

			if (geoLoc && geoLoc.blocked) {
				this.geoBlocked = true;
				this.geoLocation.country_code = geoLoc.country_code || this.geoLocation.country_code;
				this.geoLocation.country = geoLoc.country_code || this.geoLocation.country;
			}

			if ((this.geoBlocked || (this.user?.access_level?.kyc !== 'allow' && this.user?.access_level?.kyc !== 'required') || this.matiDisabled) && this.user?.status !== 'kyc') {
				this.toggleKYCCaptureModal(false);
				this.$router.push('/settings').catch(() => { });

			} else {
				this.loadKYC();
			}

			this.$forceUpdate();
		}
	}
});
</script>

<style scoped>
.scan-img {
	height: 120px;
}

.helpful-icon {
	height: 16px;
	min-height: 16px;
}

.mt-min-top {
	margin-top: -10px;
}

.header-bar {
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	cursor: pointer;
	display: flex;
	height: 48px;
	line-height: 48px;
}

.modal-header {
	width: 100%;
	font-size: 20px !important;
	font-weight: 500;
	font-family: 'Rubik', sans-serif;
}

.modal-card {
	min-width: 600px;
}

@media screen and (max-width: 768px) {
	.modal-card {
		min-width: 100%;
		margin: 0;
		padding: 0;
	}
}

.modal-card-mobile {
	background-color: #fff;
	border: 0;
	border-radius: 0;
	position: absolute;
	min-width: 100%;
	margin: 0;
	padding: 0;
	top: 0px;
	height: 100%;
}

.card-section {
	border: 1px solid #eae9ed;
	border-radius: 12px;
}

.inner-card {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	padding: 1rem;
}

.top-border {
	border-top: 1px solid #eae9ed;
}

.highlighted {
	background-color: #f8f9fd;
	box-shadow: none;
}

.copy-link-section {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
}

.break-the-word {
	word-break: break-all;
}
</style>
