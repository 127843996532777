<template>
	<div class="has-font-manrope">
		<b-modal :modelValue="installAppModal" has-modal-card @close="installAppModal = false">
			<div class="modal-card terminal">
				<div class="helper-header has-text-centered">
					{{ $t('migration.HELPER_HEADER_COMPLETE') }}
				</div>

				<div class="text has-text-centered mt-2 mb-0 is-hidden-mobile">

					<span>{{ $t('migration.HELPER_TEXT_COMPLETE_2') }}</span>
				</div>
				<div class="helper-image is-hidden-mobile">
					<img class="qr" src="@/assets/img/mobile/mobile_qr.svg" />
				</div>
				<div class="text has-text-centered mt-2 mb-0">

					<span>{{ $t('FIND_APP_STORE') }}</span>
				</div>
				<div class="app-buttons">
					<a href="https://apps.apple.com/at/app/morpher-trading-and-investing/id6444658444?l=en-GB"
						target="_blank">
						<img src="@/assets/img/mobile/app_store_badge.svg" class="app-button" />
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.morpher.trade.beta" target="_blank">
						<img src="@/assets/img/mobile/play_store_badge.svg" class="app-button" />
					</a>
				</div>
			</div>
		</b-modal>
		<transition name="fade">
			<div class="notification is-size-14 has-font-manrope" v-if="showModal && !notificationClosed">
				<div class="text-section has-text-left">
					<div>
						<img src="@/assets/icons/mobile-icon.svg" class="mobile-icon" />
					</div>
					<div class="is-line-height-18">
						<span>{{ $t('TRY_APP_BANNER') }}</span>
						<span class="router-link has-text-weight-semibold is-blue transition-faster cursor"
							@click="openInstallModal">
							{{ $t('TRY_APP_BUTTON') }}
						</span>
					</div>

					<img src="@/assets/icons/close-icon.svg" class="notification-close-icon"
						@click="closeNotification" />

				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'HeaderTryAppModal',
	components: {},
	data() {
		return {
			notificationClosed: true,
			installAppModal: false
		};
	},
	computed: {
		...mapState(useUserStore, {
			payload: (state) => state.data?.payload,
			user: (state) => state.data
		}),

		showModal() {
			if (this.$route.path.toLowerCase().indexOf('/migrate') !== -1) {
				return false;
			}
			if (this.user?.payload?.migration_started !== true) {

				if (this.user?.wallet_type === 'morpherwallet' && !this.payload?.mobile_os && Number(this.user?.timestamp || 0) < Date.now() - 1000 * 60 * 60 * 24 * 2) {
					return true;
				} else {
					return false;
				}


			} else {
				return false;
			}
		},
	},
	// watch: {
	// 	$route(nv) {
	// 		if (nv.path.toLowerCase().indexOf('/migrate') !== -1) {
	// 			this.showModal = false;
	// 		} else if (!this.showModal) {
	// 			this.checkModal();
	// 		}
	// 	}
	// },
	mounted() {
		const lastSeen = localStorage.getItem('app_try_notification_seen');

		if (lastSeen && Number(lastSeen) >= Date.now() - 1000 * 60 * 60 * 24 * 7)
			this.notificationClosed = true;
		else
			this.notificationClosed = false;
	},
	methods: {


		openInstallModal() {
			localStorage.setItem('app_try_notification_seen', Date.now().toString())
			this.notificationClosed = true;
			this.installAppModal = true;

		},
		closeNotification() {
			localStorage.setItem('app_try_notification_seen', Date.now().toString())
			this.notificationClosed = true;
		}
	}
});
</script>
<style scoped>
.notification {
	background: #DFFBE9;
	padding: 0.75rem 1.25rem;
	position: fixed;
	left: 0px;
	width: 100%;
	z-index: 2999;
	top: 82px;
	transition: 200ms;
}

.text-section {
	max-width: 1070px;
	margin-left: auto;
	margin-right: auto;

	display: flex;
	align-items: center;
	gap: 0.5rem;
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 1216px) {
	.notification {
		top: 62px;
		z-index: 29;
		padding-left: 40px;
		padding-left: 40px;
	}

	.text-section {
		max-width: unset;
	}
}

/* mobile */
@media screen and (max-width: 768px) {
	.notification {
		top: 60px;
		z-index: 29;
		padding-left: 20px;
		padding-right: 20px;
	}

	.text-section {
		max-width: unset;
	}
}


.is-blue:not(:hover) {
	color: #1A6DF0 !important;
}

.notification-close {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.35);
}

.cursor {
	cursor: pointer;
}

.mobile-icon {
	height: 24px;
	width: 24px;
	min-width: 24px;
}

.notification-close-icon {
	height: 24px;
	width: 24px;
	min-width: 24px;
	margin-left: auto;
	cursor: pointer;
}

.modal-card {
	padding: 20px;
	max-width: 400px !important;

}

.helper-header {
	font-size: 28px;
	font-weight: 700;

}

.qr {
	max-width: 188px;
}

.text {
	font-size: 14px;
	font-weight: 400;
}

.app-buttons {
	margin-top: 16px;
}

.helper-image {
	margin-top: 16px;
}

.app-button {
	width: 177px;
	height: 52px;
}
</style>
