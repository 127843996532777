
import EventBus from  '@/store/event-bus'

import Fortmatic from 'fortmatic'
import { getWalletClient } from './viem'

const sideChainNode = {
  rpcUrl: 'https://sidechain.morpher.com',
  chainId: 21
}

const fortmaticConfig = async function () {
  const fortmatic = new Fortmatic(import.meta.env.VITE_FORTMATIC_API_KEY, sideChainNode)
  const provider = (await fortmatic.getProvider()) as any
	const walletClient = getWalletClient(provider)

  return {
    fortmatic,
    walletClient
  }
}

let yPosition = 0
let isActive = false

/**
 * Set the position of the wallet so that it is always at the top of the page
 */

const checkPosition = function () {
  if (!isActive) return

  const fortmaticContainer = document.getElementById('fortmaticOverlayContainer')
  const fortmaticFrame = document.getElementsByClassName('fortmatic-iframe')
  const fortmaticOverlayTextClaim = document.getElementById('fortmaticOverlayTextClaim')
  const fortmaticOverlayTextLimit = document.getElementById('fortmaticOverlayTextLimit')
  const fortmaticOverlayTextTrade = document.getElementById('fortmaticOverlayTextTrade')

  if (
    fortmaticContainer &&
    fortmaticFrame &&
    fortmaticFrame.length > 0 &&
    fortmaticOverlayTextClaim &&
    fortmaticOverlayTextLimit &&
    fortmaticOverlayTextTrade
  ) {
    const frameElement: any = fortmaticFrame[0]

    // Fixed overlay top position when screen too small
    const compressedScreen = window.innerHeight < 500
    const narrowScreen = window.innerWidth < 400

    // Tiny vertical screen
    if (compressedScreen) {
      fortmaticOverlayTextClaim.style.top = '110px'
      fortmaticOverlayTextClaim.style.marginTop = '0'
      fortmaticOverlayTextClaim.style.transform = 'translate(-50%, 0)'
      fortmaticOverlayTextLimit.style.top = '110px'
      fortmaticOverlayTextLimit.style.marginTop = '0'
      fortmaticOverlayTextLimit.style.transform = 'translate(-50%, 0)'
      fortmaticOverlayTextTrade.style.top = '110px'
      fortmaticOverlayTextTrade.style.marginTop = '0'
      fortmaticOverlayTextTrade.style.transform = 'translate(-50%, 0)'
      fortmaticOverlayTextClaim.style.width = '310px'
      fortmaticOverlayTextLimit.style.width = '310px'
      fortmaticOverlayTextTrade.style.width = '310px'
    }
    // Smallish vertical screens
    if (narrowScreen) {
      fortmaticOverlayTextClaim.style.width = '310px'
      fortmaticOverlayTextLimit.style.width = '310px'
      fortmaticOverlayTextTrade.style.width = '310px'
    }
    // Otherwise Revert to Default Settings
    if (!compressedScreen && !narrowScreen) {
      fortmaticOverlayTextClaim.style.top = '50%'
      fortmaticOverlayTextClaim.style.marginTop = '-35px'
      fortmaticOverlayTextClaim.style.transform = 'translate(-50%, -50%)'
      fortmaticOverlayTextClaim.style.minHeight = '220px'
      fortmaticOverlayTextClaim.style.width = '350px'
      fortmaticOverlayTextLimit.style.top = '50%'
      fortmaticOverlayTextLimit.style.marginTop = '-35px'
      fortmaticOverlayTextLimit.style.transform = 'translate(-50%, -50%)'
      fortmaticOverlayTextLimit.style.minHeight = '220px'
      fortmaticOverlayTextLimit.style.width = '350px'
      fortmaticOverlayTextTrade.style.top = '50%'
      fortmaticOverlayTextTrade.style.marginTop = '-35px'
      fortmaticOverlayTextTrade.style.transform = 'translate(-50%, -50%)'
      fortmaticOverlayTextTrade.style.minHeight = '220px'
      fortmaticOverlayTextTrade.style.width = '350px'
    }
  }
}

/**
 *
 * @param type display the fortmatic overlay
 */

const openFortmaticOverlay = async function (type: string) {
  isActive = true
  const fortmaticContainer = document.getElementById('fortmaticOverlayContainer')
  const fortmaticFrame = document.getElementsByClassName('fortmatic-iframe')
  const fortmaticOverlayTextClaim = document.getElementById('fortmaticOverlayTextClaim')
  const fortmaticOverlayTextLimit = document.getElementById('fortmaticOverlayTextLimit')
  const fortmaticOverlayTextTrade = document.getElementById('fortmaticOverlayTextTrade')

  if (
    fortmaticContainer &&
    fortmaticFrame &&
    fortmaticFrame.length > 0 &&
    fortmaticOverlayTextClaim &&
    fortmaticOverlayTextLimit &&
    fortmaticOverlayTextTrade
  ) {
    fortmaticContainer.style.display = 'flex'
    if (modalOverlay) {
      fortmaticContainer.style.zIndex = '60'
    } else {
      fortmaticContainer.style.zIndex = ''
    }
    if (type === 'claim') {
      fortmaticOverlayTextClaim.style.display = 'flex'
    } else if (type === 'limit') {
      fortmaticOverlayTextLimit.style.display = 'flex'
    } else {
      fortmaticOverlayTextTrade.style.display = 'flex'
    }

    const frameElement: any = fortmaticFrame[0]
    if (modalOverlay) {
      frameElement.style.zIndex = '70'
    } else {
      frameElement.style.zIndex = '41'
    }
    // Center position frame
    frameElement.style.position = 'fixed'
    frameElement.style.top = '50%'
    frameElement.style.left = '50%'
    frameElement.style.transform = 'translate(-50%, -50%)'

    // Resize Listeners and position sizing of text overlay
    checkPosition()
    window.onscroll = checkPosition
    window.onresize = checkPosition

    // Freeze page background
    yPosition = window.pageYOffset
    document.body.style.position = 'fixed'
  }
}

/**
 * Close the fortmatic overlay
 */
const closeFortmaticOverlay = async function () {
  isActive = false
  if (timer) clearInterval(timer)
  if (timer2) clearInterval(timer2)

  const fortmaticContainer = document.getElementById('fortmaticOverlayContainer')
  const fortmaticFrame = document.getElementsByClassName('fortmatic-iframe')
  const fortmaticOverlayTextClaim = document.getElementById('fortmaticOverlayTextClaim')
  const fortmaticOverlayTextLimit = document.getElementById('fortmaticOverlayTextLimit')
  const fortmaticOverlayTextTrade = document.getElementById('fortmaticOverlayTextTrade')

  if (
    fortmaticContainer &&
    fortmaticFrame &&
    fortmaticFrame.length > 0 &&
    fortmaticOverlayTextClaim &&
    fortmaticOverlayTextLimit &&
    fortmaticOverlayTextTrade
  ) {
    const frameElement: any = fortmaticFrame[0]

    frameElement.style.position = 'fixed'
    frameElement.style.top = '50%'
    frameElement.style.left = '50%'
    frameElement.style.transform = 'translate(-50%, -50%)'

    fortmaticContainer.style.display = 'none'
    fortmaticOverlayTextClaim.style.display = 'none'
    fortmaticOverlayTextLimit.style.display = 'none'
    fortmaticOverlayTextTrade.style.display = 'none'

    // Unfreeze app scrolling
    document.body.style.position = ''
    window.scrollTo(0, yPosition)
  }
}

/**
 * Close the fortmatic overlay
 */
const clickedToCloseOverlay = async function () {
  const fortmaticContainer = document.getElementById('fortmaticOverlayContainer')
  const fortmaticFrame = document.getElementsByClassName('fortmatic-iframe')
  const fortmaticOverlayTextClaim = document.getElementById('fortmaticOverlayTextClaim')
  const fortmaticOverlayTextLimit = document.getElementById('fortmaticOverlayTextLimit')
  const fortmaticOverlayTextTrade = document.getElementById('fortmaticOverlayTextTrade')

  if (
    fortmaticContainer &&
    fortmaticFrame &&
    fortmaticFrame.length > 0 &&
    fortmaticOverlayTextClaim &&
    fortmaticOverlayTextLimit &&
    fortmaticOverlayTextTrade
  ) {
    fortmaticContainer.style.display = 'none'
    fortmaticOverlayTextClaim.style.display = 'none'
    fortmaticOverlayTextLimit.style.display = 'none'
    fortmaticOverlayTextTrade.style.display = 'none'
  }
}

let overlayType = ''
let modalOverlay = false
let timer: NodeJS.Timeout | null = null
let timer2: NodeJS.Timeout | null = null
/**
 * Wait for fortmatic to be visible and then display the fortmatic overlay
 */
const openOverlayTimer = function () {
  const fortmaticFrame = document.getElementsByClassName('fortmatic-iframe')
  if (fortmaticFrame && fortmaticFrame.length > 0) {
    const frameElement: any = fortmaticFrame[0]
    if (frameElement.style.display === 'block') {
      if (timer) clearInterval(timer)
      openFortmaticOverlay(overlayType)
      if (timer2) clearInterval(timer2)
      timer2 = setInterval(closeOverlayTimer, 20)
    }
  }
}

/**
 * Wait for the frame to be hidden and then close the fortmatic overlay
 */

const closeOverlayTimer = function () {
  const fortmaticFrame = document.getElementsByClassName('fortmatic-iframe')
  if (fortmaticFrame && fortmaticFrame.length > 0) {
    const frameElement: any = fortmaticFrame[0]
    if (frameElement.style.display === 'none') {
      if (timer2) clearInterval(timer2)

      closeFortmaticOverlay()
    }
  }
}

/**
 *
 * @param type initialise the fortmatic overlay to display trading information instead of eth adress and values
 */
const processOverlay = function (type: string, modal: boolean = false) {
  overlayType = type
  modalOverlay = modal || false
  if (timer) clearInterval(timer)
  timer = setInterval(openOverlayTimer, 100)
}

export { fortmaticConfig, processOverlay, closeFortmaticOverlay, clickedToCloseOverlay }
