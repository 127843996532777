fragment OrderFragment on Order {
    id
    direction
    created_at
    leverage
    price
    eth_address
    price_above
    price_below	
    good_from
    good_until
    open_mph_token_amount
    close_shares_amount
    shares
    status
    tx_hash 
    callback_hash
    type
    liquidation_timestamp
    spread
    token_amount
    execute_at
    payload
    mph_price
    unadjusted_price
    market {
        type
        symbol
        name
        id
    }
    position {
        created_at
    }
    position_history
}
