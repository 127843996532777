mutation createNotificationTemplate($data: NotificationTemplateInput!) {
    createNotificationTemplate(data: $data) {
        id
        title
        description
        filter
        timestamp
        status
    }
}
