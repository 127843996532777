<template>
	<section>
		<b-modal :modelValue="showMPHRewardModal" has-modal-card @close="closeModal">
			<form @submit.prevent="closeModal">
				<div class="modal-card terminal">
					<div class="terminal-content has-text-left has-font-manrope">
						<img src="@/assets/img/mph_on_logon.svg" :alt="$t('LOGON_MPH_HEAD')" class="head-image" />

						<h1 class="text-head">
							{{ $t('LOGON_MPH_HEAD') }}
						</h1>

						<p class="text-welcome">
							{{ $t('WELCOME__BACK') }}
						</p>

						<p class="text-body">
							{{ $t('LOGON_MPH_TEXT') }}
						</p>

						<b-button class="button big-button transition-faster is-logon is-centered mt-15 "
							@click="closeModal">
							{{ $t('DONE') }}
						</b-button>
					</div>
				</div>
			</form>
		</b-modal>

	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import VueQrcode from '@chenfengyuan/vue-qrcode';
/* @group Components_Modals */
/*
  <h4> <b> New portfolio detected </b> </h4>
  <br> Airdrop message - 10k MPH added to wallet
*/
export default defineComponent({
	name: 'MPHRewardModal',
	components: {
		VueQrcode
	},
	data() {
		return {
			landingPage: import.meta.env.VITE_MORPHER_LANDING_PAGE,
			isMobile: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showMPHRewardModal: (state) => state.showMPHRewardModal,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),

	},

	mounted() {
		this.updateUserPayload({ payloadOption: 'mph_reward', payloadValue: 'false' });
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleMPHRewardModal: 'toggleMPHRewardModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),

		closeModal() {
			this.toggleMPHRewardModal(false);
		},
	}
});
</script>

<style scoped>
.head-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}


.text-head {
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	margin-top: 16px;
	color: #333;
}

.text-welcome {
	color: #039954;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
}

.text-body {
	font-size: 14px;
	font-weight: 400;
	margin-top: 16px;
	text-align: center;
	color: #333;
}
</style>
