<template>
	<div class="formcontainer">
		<div class="headerImage">
			<a href="https://www.morpher.com" target="_blank" alt="Morpher Homepage">
				<img class="image" src="@/assets/wallet/recover_icon.svg" alt="Morpher Logo" />
			</a>
		</div>

		<div v-if="!config.application_live && $route.query && $route.query.adminoverride !== 'true'" class="authBox">
			{{ $t('MAINTANANCE_MODE') }}
		</div>
		<div class="authBox">
			<div v-show="!initialising">
				<!-- Connect Fortmatic -->
				<div>
					<h2 data-cy="logInTitle" v-if="step == 'password'" class="authTitle">{{
						$t('auth.RESET_PASSWORD_TITLE') }}</h2>
					<h2 data-cy="logInTitle" v-else class="authTitle">{{ $t('auth.RECOVER_HEADER') }}</h2>

					<p data-cy="logInDescription" v-if="step == 'email'" class="authText">{{ $t('auth.RECOVER_EMAIL') }}
					</p>
					<p data-cy="logInDescription" v-else-if="step == 'password'" class="authText">{{
						$t('auth.RESET_PASSWORD_DESCRIPTION') }}</p>
					<p data-cy="logInDescription" v-else class="authText">{{ $t('auth.RECOVER_OPTION') }}</p>

					<!-- Signin with email/password -->
					<div v-if="step == 'email'">
						<div class="field">
							<label class="label">{{ $t('auth.EMAIL') }}</label>
							<div class="control">
								<input type="email" class="input" data-cy="walletEmail"
									:placeholder="$t('auth.ENTER_EMAIL')" name="walletEmail" v-model="walletEmail" />
							</div>
						</div>

						<button type="submit" @click="recoverEmailCheck" data-cy="submit"
							:class="{ 'is-loading': recoverProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('CONTINUE') }}</span>
						</button>

						<div class="error" v-if="loginError">
							<p data-cy="loginError">
								⚠️ <span v-html="loginError"></span>
							</p>
						</div>

						<p class="loginLink">
							<router-link to="/login" class="login-router transition-faster"><span>&lt;&nbsp;&nbsp; {{
								$t('auth.BACK') }} </span></router-link>
						</p>

					</div>
					<div v-else-if="step == 'password'" class="pt-30">
						<div class="field">
							<label class="label">{{ $t('auth.NEW_PASSWORD') }}</label>

							<div class="control">
								<input type="password" class="input" ref="login_password" @keydown="checkKeyPress"
									data-cy="walletPassword" :placeholder="$t('auth.ENTER_PASSWORD')"
									name="walletPassword" v-model="walletPassword" />
								<button class="password-toggle" v-on:click="togglePasswordVisibility"
									data-cy="password-toggle-button">
									<img v-if="passwordIsVisible" class="image"
										src="@/assets/auth/password-visible-icon.svg" alt="Visible Button" />
									<img v-if="!passwordIsVisible" class="image"
										src="@/assets/auth/password-invisible-icon.svg" alt="Invisible Button" />
								</button>
								<password v-model="walletPassword" :strength-meter-only="true" :secure-length="8"
									style="max-width: initial" />
								<div class="password-help">
									<p>{{ $t('auth.REQUIREMENTS') }}</p>
									<ul class="items">
										<li :class="{
											done: passwordChecks.min === 'pass',
											fail: passwordChecks.min === 'fail'
										}">
											{{ $t('auth.MIN_CHARACTERS') }}
										</li>
										<li :class="{
											done: passwordChecks.lowercase === 'pass',
											fail: passwordChecks.lowercase === 'fail'
										}">
											{{ $t('auth.LOWERCASE_LETTER') }}
										</li>
										<li :class="{
											done: passwordChecks.uppercase === 'pass',
											fail: passwordChecks.uppercase === 'fail'
										}">
											{{ $t('auth.UPPERCASE_LETTER') }}
										</li>
										<li :class="{
											done: passwordChecks.number === 'pass',
											fail: passwordChecks.number === 'fail'
										}">
											{{ $t('auth.NUMBER') }}
										</li>
										<li :class="{
											done: passwordChecks.match === 'pass',
											fail: passwordChecks.match === 'fail'
										}">
											{{ $t('auth.PASSWORD_MATCH') }}
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="field">
							<label class="label">{{ $t('auth.PASSWORD_CONFIRM') }}</label>

							<div class="control">
								<input type="password" class="input" ref="login_password_confirm"
									@keydown="checkKeyPress" data-cy="walletPasswordConfirm"
									name="walletPasswordConfirm" v-model="walletPasswordConfirm" />
								<button class="confirm-password-toggle" v-on:click="toggleConfirmPasswordVisibility"
									data-cy="password-toggle-button">
									<img v-if="confirmPasswordIsVisible" class="image"
										src="@/assets/auth/password-visible-icon.svg" alt="Visible Button" />
									<img v-if="!confirmPasswordIsVisible" class="image"
										src="@/assets/auth/password-invisible-icon.svg" alt="Invisible Button" />
								</button>
							</div>
						</div>

						<button type="submit" @click="submitPassword" data-cy="submit" :disabled="passwordChecks.match !== 'pass'
							|| passwordChecks.min !== 'pass'
							|| passwordChecks.uppercase !== 'pass'
							|| passwordChecks.lowercase !== 'pass'
							|| passwordChecks.number !== 'pass'" :class="{ 'is-loading': recoverProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('auth.UPDATE_PASSWORD') }}</span>
						</button>

						<div class="error" v-if="loginError">
							<p data-cy="loginError">
								⚠️ <span v-html="loginError"></span>
							</p>
						</div>

						<p class="loginLink">
							<router-link to="/login" class="login-router transition-faster"><span>&lt;&nbsp;&nbsp; {{
								$t('CANCEL') }} </span></router-link>
						</p>

					</div>

					<div v-else class="pt-30">

						<LoginGoogle :recover="true" @processMethod="recoverGoogle" :loading="loginProcessingGoogle">
						</LoginGoogle>

						<LoginApple :recover="true" @processMethod="recoverApple" :loading="loginProcessingApple">
						</LoginApple>


						<!-- <HFaceBookLogin 
							:logo-style="{
								display: 'none'
							}"
							class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
							:app-id="facebookClientId"
							@onFailure="onFailure"
  							scope="email,public_profile"
  							fields="id,name,email,first_name,last_name,birthday"
							@onSuccess="recoverFacebook"
							:version="'v18.0'"
							v-model="facebook.model"
							data-cy="facebookButton"
							><span class="is-flex is-align-items-center" slot="login">
								<span class="icon img">
									<img src="@/assets/wallet/fb_logo.svg" class="buttonIcon" alt="Facebook Logo" />
								</span>
								<span class="ml-5px">{{ $t('auth.RECOVER_FACEBOOK') }} </span>
							</span>
						</HFaceBookLogin > -->

						<button class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
							:class="{ 'is-loading': loading.vk }" @click="recoverVK" data-cy="showWalletRecover">
							<span class="icon img">
								<img src="@/assets/wallet/fb_logo.svg" class="buttonIcon" alt="VK Logo" />
							</span>
							<span class="ml-5px">{{ $t('auth.RECOVER_FACEBOOK') }} </span>
						</button>


						<button class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
							:class="{ 'is-loading': loading.vk }" @click="recoverVK" data-cy="recoverVKButton">
							<span class="icon img">
								<img src="@/assets/wallet/vk_logo.svg" class="buttonIcon" alt="VK Logo" />
							</span>
							<span class="ml-5px">{{ $t('auth.RECOVER_VK') }} </span>
						</button>

						<div class="error" v-if="loginError">
							<p data-cy="loginError">
								⚠️ <span v-html="loginError"></span>
							</p>
						</div>

						<p class="loginLink">
							<router-link to="/login" class="login-router transition-faster"><span>&lt;&nbsp;&nbsp; {{
								$t('CANCEL') }} </span></router-link>
						</p>
					</div>
				</div>
			</div>
		</div>

		<!-- Need help logging in -->
		<!-- <div class="loginHelp"></div>

		<p class="loginLink">
			{{ $t('NEED_HELP') }}
			<a
				:href="supportArticleLink('LoginIssues')"
				class="transition-faster"
				target="_blank"
				>{{ $t('auth.VISIT_SUPPORT') }}</a
			>
		</p> -->

		<!-- <vue-recaptcha
			ref="recaptcha"
			size="invisible"
			:sitekey="recaptchaSiteKey"
			:load-recaptcha-script="true"
			@verify="onCaptchaVerified"
			@error="onCaptchaError"
			@expired="onCaptchaExpired"
			@render="onCaptchaLoaded"
		/> -->
	</div>
</template>

<script lang="ts">
//vuex
import { ERROR } from '@/store/mutation-types';

//import { getDictionaryValue } from '@/helpers/wallet/dictionary';
import { accessTokenName, refreshTokenName, getUserID } from '@/helpers/utils';
import deviceType from '@/helpers/deviceType';
import { UserService } from '@/services/UserService';
import LoginApple from './LoginApple.vue';
import LoginGoogle from './LoginGoogle.vue';

import * as blockies from 'ethereum-blockies-png';
//import RecaptchaMixin from '@/mixins/recaptcha.mixin';
import AuthMixin from '@/mixins/auth.mixin';
//import VueRecaptcha from 'vue-recaptcha';
import Cookie from 'js-cookie';
import { morpherWalletConfig } from '@/helpers/morpherwallet';
import Password from 'vue-simple-password-meter';
import { HFaceBookLogin } from '@healerlab/vue3-facebook-login';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { useUserStore } from '@/store/modules/user';
import { useAdminStore } from '@/store/modules/admin';
import { useContractStore } from '@/store/modules/contract';
import { defineComponent } from 'vue';
import type { TMorpherWalletInit } from '@/types/graphql-global-types';


/* @group Components_Auth */
/*
  <h4> <b>Login Form</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'WalletRecover',
	components: { LoginApple, LoginGoogle, Password, HFaceBookLogin }, // , 'vue-recaptcha': VueRecaptcha
	mixins: [AuthMixin], // RecaptchaMixin
	data() {
		return {
			passwordIsVisible: false,
			confirmPasswordIsVisible: false,
			walletEmail: '',
			walletPassword: '',
			walletError: '',
			walletPasswordConfirm: '',
			loginError: '',
			loginUser: {} as any,
			initialising: true,
			showRecovery: false,
			walletClientlogin: {} as any,
			morpherWalletInit: {} as any,
			step: 'email',
			recoverProcessing: false,
			loginProcessingGoogle: false,
			loginProcessingApple: false,
			facebookClientId: import.meta.env.VITE_FACEBOOK_APP_ID,
			facebook: {
				FB: {},
				model: {},
				scope: {}
			},

			loading: {
				google: false,
				facebook: false,
				vk: false
			},
			passwordChecks: {
				min: '',
				uppercase: '',
				lowercase: '',
				number: '',
				match: ''
			},
			recovery_type: null,
			default_wallet: '',
			showMetamaskHelp: false,
			airdrop_id: '',
			referred_by: '',
			custom_invite: '',
			morpherwallet: null as any,
			isMetamaskSupported: false,
			newEmail: ''

		};
	},
	computed: {
		...mapState(useAdminStore, {
			config: state => state.config,
		}),

		...mapState(useUserStore, {
			user: state => state.data,
		}),
		...mapState(useContractStore, {

			contract: state => state as any,
		})
	},
	watch: {
		walletError(newValue) {
			if (newValue) {
				// If there is a logon error then log it to sentry
				this.logSentryError('Logon Error:' + newValue);
				this.error({ message: 'WALLET_CONNECT_ERROR' });
			}
		},
		walletPassword(newValue) {
			this.passwordChecks = this.checkPassword(newValue, false, this.passwordChecks, this.walletPasswordConfirm);
		},

		walletPasswordConfirm(newValue) {
			this.passwordChecks = this.checkPassword(this.walletPassword, false, this.passwordChecks, newValue, true);
		}
	},
	async mounted() {
		// Fill emailOrUsernameStorage from url 'account' query parameter
		if (this.$route.query.account !== undefined) {
			localStorage.setItem('emailOrUsernameStorage', this.$route.query.account)
		}

		if (localStorage.getItem('walletType')) {
			this.default_wallet = localStorage.getItem('walletType') || '';
		}


		// browsers that support metamask
		const browserMetamask = ['chrome', 'brave', 'opera', 'yandex', 'firefox', 'edge'];

		// return only the first word od the browser name
		const browser = navigator.sayswho.replace(/ .*/, '').toLowerCase();

		if (browserMetamask.includes(browser) || deviceType.isAnyMobile()) {
			this.isMetamaskSupported = true;
			this.showMetamaskHelp = false;
		}

		this.airdrop_id = this.$route.query.airdrop_id ? this.$route.query.airdrop_id : null;
		this.referred_by = this.$route.query.referred_by ? this.$route.query.referred_by : null;
		this.custom_invite = this.$route.query.custom_invite ? this.$route.query.custom_invite : null;

		if (this.referred_by) localStorage.setItem('airdrop_referred_by', this.referred_by);
		else this.referred_by = localStorage.getItem('airdrop_referred_by') || '';

		if (this.custom_invite) localStorage.setItem('airdrop_custom_invite', this.custom_invite);
		else this.custom_invite = localStorage.getItem('airdrop_custom_invite') || '';

		this.initMorpherWallet();

		this.initialising = false;
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR
		}),
		...mapActions(useUserStore, {
			loginWallet: 'loginWallet',
			confirmWallet: 'confirmWallet',
		}),
		...mapActions(useContractStore, {
			setWalletClient: 'setWalletClient',
			setMorpherWalletProvider: 'setMorpherWalletProvider'
		}),
		async initMorpherWallet() {
			if (this.contract && this.contract.morpherwallet) {
				this.morpherWalletInit = {
					morpherwallet: this.contract.morpherwallet,
					provider: this.contract.provider,
					walletClient: this.contract.walletClient
				};
			} else {
				this.morpherWalletInit = await morpherWalletConfig();
				this.morpherWalletInit.morpherwallet.setLanguage(this.$i18n.locale);
			}
		},
		checkKeyPress(e: KeyboardEvent) {
			if (e.key == 'Enter') {
				if (this.walletPassword && this.walletPasswordConfirm) {
					this.submitPassword();
				} else {
					// set focus to the password field if it is blanck
					window.setTimeout(() => {
						const passwordElement = (this.$refs.login_password as InstanceType<typeof HTMLInputElement>);
						if (passwordElement) passwordElement.focus();
					}, 100);
				}
			}
		},
		async submitPassword() {

			this.loginError = '';


			if (!this.morpherWalletInit) {
				await this.initMorpherWallet();
			}

			this.morpherwallet = this.morpherWalletInit.morpherwallet;
			this.walletClientlogin = this.morpherWalletInit.walletClient;
			this.setWalletClient(this.walletClientlogin);

			let eth_address = '';
			this.setMorpherWalletProvider(this.morpherWalletInit);

			this.morpherwallet.onClose(async () => {
				this.recoverProcessing = false;

			});

			this.morpherwallet.onLoginError(async (email: any, error: any) => {
				if (error) {
					this.recoverProcessing = false;

					if (error.toString() === 'invalid password') {
					}

					if (error.includes('_')) {
						this.loginError = this.$t('errors.' + error)
					} else {
						this.loginError = error;
					}

				}
			});

			this.morpherwallet.onLogout(() => { });

			this.morpherwallet.onError((error: any) => {
				if (error) {
					this.recoverProcessing = false;

					if (error.toString() === 'invalid password') {
					}

					if (error.includes('_')) {
						this.loginError = this.$t('errors.' + error)
					} else {
						this.loginError = error;
					}
				}
			});


			this.morpherwallet.onRecovery((type: any, data: any) => {

				if (type == 'emailValidated') {
					this.newEmail = data;
					this.loginError = '';
					this.recoverProcessing = false;
					this.step = 'recovery';
				} else if (type == 'setPassword') {
					this.loginError = '';
					this.recoverProcessing = false;
					this.step = 'password';
				} else if (type == 'passwordUpdated') {
					this.loginError = '';
					this.recoverProcessing = false;
					this.step = 'email';
					this.$router.push('/login').catch(() => { });
				} else {
					console.log('onRecovery', type, data)
				}
			});

			this.morpherwallet.onLogin(async (walletAddress: any, walletEmail: any) => {
				console.log('logged in')
			});

			this.recoverProcessing = true;

			this.morpherwallet.walletRecoveryHidden('password', { password: this.walletPassword, passwordConfirm: this.walletPasswordConfirm });


		},

		checkPassword(newValue: any, checkErrors: any, oldChecks: any, comparePassword: any, checkRepeatOnly = false) {
			let updatedChecks = checkRepeatOnly
				? oldChecks
				: {
					min: '',
					uppercase: '',
					lowercase: '',
					number: '',
					match: ''
				};

			if (checkErrors) {
				updatedChecks = {
					min: 'fail',
					uppercase: 'fail',
					lowercase: 'fail',
					number: 'fail',
					match: 'fail'
				};
			}

			if (newValue) {
				if (!checkRepeatOnly) {
					if (newValue.length >= 8) {
						updatedChecks.min = 'pass';
					} else if (checkErrors) updatedChecks.min = 'fail';

					if (/[A-Z]/.test(newValue)) {
						updatedChecks.uppercase = 'pass';
					} else if (checkErrors) updatedChecks.uppercase = 'fail';

					if (/[a-z]/.test(newValue)) {
						updatedChecks.lowercase = 'pass';
					} else if (checkErrors) updatedChecks.lowercase = 'fail';

					if (/[0-9]/.test(newValue)) {
						updatedChecks.number = 'pass';
					} else if (checkErrors) updatedChecks.number = 'fail';
				}

				if (comparePassword) {
					if (newValue === comparePassword) {
						updatedChecks.match = 'pass';
					} else updatedChecks.match = 'fail';
				} else {
					if (checkErrors) updatedChecks.match = 'fail';
					else updatedChecks.match = '';
				}
			}

			return updatedChecks;
		},

		togglePasswordVisibility() {
			this.passwordIsVisible = !this.passwordIsVisible;
			(this.$refs.login_password as InstanceType<typeof HTMLInputElement>).type = this.passwordIsVisible ? 'text' : 'password';
		},
		toggleConfirmPasswordVisibility() {
			this.confirmPasswordIsVisible = !this.confirmPasswordIsVisible;
			(this.$refs.login_password_confirm as InstanceType<typeof HTMLInputElement>).type = this.confirmPasswordIsVisible ? 'text' : 'password';
		},

		async clearStorage() {
			localStorage.removeItem(accessTokenName);
			localStorage.removeItem(refreshTokenName);
			localStorage.removeItem('emailOrUsernameStorage');
			localStorage.removeItem('morpher-role');
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('tokenStorage');
			localStorage.removeItem('eth_address');
		},

		async recaptchaError() {
			//this.recoverProcessing = false;
		},
		async reloadPage(fortmaticNoAccountFound = false) {
			// remove all old cached items
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			if (fortmaticNoAccountFound) return;

			// reload the page if the previous reload was more than 1 hour ago (to avoid refresh loop)
			const reloadDate = localStorage.getItem('reloadDateLogin');
			const timestamp = Date.now();

			if (!reloadDate || Number(reloadDate) < timestamp - 3600000) {
				localStorage.setItem('reloadDateLogin', String(timestamp));
				window.location.reload();
			}
		},

		recoverGoogle(data: any) {

			if (data) {
				this.morpherwallet.walletRecoveryHidden('google', data);

			} else {
				this.loginProcessingGoogle = false;
			}
		},
		recoverApple(data: any) {

			if (data) {
				this.morpherwallet.walletRecoveryHidden('apple', data);

			} else {
				this.loginProcessingApple = false;
			}
		},
		async recoverFacebook(data: any) {
			if (data) {
				this.morpherwallet.walletRecoveryHidden('facebook', data);

			} else {
				this.loginProcessingGoogle = false;
			}
		},
		showWalletRecover() {
			if (this.morpherwallet) this.morpherwallet.showWallet();
		},
		recoverVK() {

			if (this.morpherwallet) this.morpherwallet.showWallet();
			return;
		},
		vkPopup(options: any) {
			const screenX = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft,
				screenY = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop,
				outerWidth = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.body.clientWidth,
				outerHeight = typeof window.outerHeight != 'undefined' ? window.outerHeight : document.body.clientHeight - 22,
				width = options.width,
				height = options.height,
				left = parseInt(String(screenX + (outerWidth - width) / 2), 10),
				top = parseInt(String(screenY + (outerHeight - height) / 2.5), 10),
				features = 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top;
			return window.open(options.url, 'vk_oauth', features);
		},
		async recoverEmailCheck() {

			this.loginError = '';


			if (!this.morpherWalletInit) {
				await this.initMorpherWallet();
			}

			this.morpherwallet = this.morpherWalletInit.morpherwallet;
			this.walletClientlogin = this.morpherWalletInit.walletClient;
			this.setWalletClient(this.walletClientlogin);

			let eth_address = '';
			this.setMorpherWalletProvider(this.morpherWalletInit);

			this.morpherwallet.onClose(async () => {
				this.recoverProcessing = false;

			});

			this.morpherwallet.onLoginError(async (email: any, error: any) => {
				if (error) {
					this.recoverProcessing = false;

					if (error.toString() === 'invalid password') {

					}

					if (error.includes('_')) {
						this.loginError = this.$t('errors.' + error)
					} else {
						this.loginError = error;
					}

				}
			});

			this.morpherwallet.onLogout(() => { });

			this.morpherwallet.onError((error: any) => {
				if (error) {
					this.recoverProcessing = false;

					if (error.toString() === 'invalid password') {

					}

					if (error.includes('_')) {
						this.loginError = this.$t('errors.' + error)
					} else {
						this.loginError = error;
					}
				}
			});


			this.morpherwallet.onRecovery((type: any, data: any) => {

				if (type == 'emailValidated') {
					this.newEmail = data;
					this.loginError = '';
					this.recoverProcessing = false;
					this.step = 'recovery';
				} else if (type == 'setPassword') {
					this.loginError = '';
					this.recoverProcessing = false;
					this.step = 'password';

				} else {
					console.log('onRecovery', type, data)
				}
			});

			this.morpherwallet.onLogin(async (walletAddress: any, walletEmail: any) => {
				console.log('logged in')
			});

			this.recoverProcessing = true;

			this.morpherwallet.walletRecoveryHidden('email', { email: this.walletEmail });


		},

		async disconnectWallet(goToLogin = false) {
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			this.clearStorage();

			if (this.morpherwallet && this.morpherwallet.logout) this.morpherwallet.logout();

			if (goToLogin) this.$router.push('/login').catch(() => { });

			window.location.reload();
		},
		generateWalletName(wallet_type: any) {
			let name = '';

			if (wallet_type === 'metamask') {
				name = 'Metamask';
			} else if (wallet_type === 'portis') {
				name = 'Portis';
			} else if (wallet_type === 'fortmatic') {
				name = 'Fortmatic';
			} else if (wallet_type === 'morpherwallet') {
				name = 'Morpher Wallet';
			}

			return name;
		},

		// Submits login request
		async formAction() { }
	}
});
</script>
<style scoped>
.button-img-primary {
	height: 28px;
	padding-right: 15px;
}

.button-img-alt {
	position: absolute;
	right: calc(50% + 70px);
}

.alt-button {
	padding-left: 46px;
}

.login-help {
	margin-top: -0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.settings-link {
	line-height: 1rem;
	cursor: default;
}



@media only screen and (max-width: 400px) {
	.button-img-alt {
		display: none;
	}

	.alt-button {
		padding-left: initial;
	}
}

.spaced {
	margin-right: 7px;
}

.authBox {
	margin-top: 0px;
}

.formcontainer {
	display: flex;
	flex-direction: column;
	width: 360px;
	margin: auto auto;
}

.headerImage img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.authTitle {
	font-size: 32px;
	font-weight: 700;
	line-height: 38px;
	margin-top: 25px;
	color: #333333;
}

.headerImage {
	width: 100%;
}

.authText {
	font-weight: 400;
	color: #666666;
	margin-top: 12px;
	font-size: 16px;
}

.label {
	margin-top: 20px;
	margin-bottom: 4px;
	text-align: left;
	color: #686475;
	font-weight: 700;
	font-size: 12px;
}

.input {
	padding: 23px 16px;
	background-color: #fff;
	border-radius: 8px !important;
	border-color: #d0d5dd !important;
}

.input::placeholder {
	font-family: 'Manrope';
	line-height: 20px;
	font-size: 14px !important;
	font-weight: 400;
	color: #686475;
}




.loginDivider {
	height: 1px;
	width: 100%;
	margin: 20px 0;
	border-top: 1px solid #eae9ed;
}

.pt-30 {
	padding-top: 30px;
}

.loginHelp {
	height: calc(100vh - 800px);
}

@media only screen and (max-width: 768px) {
	.formcontainer {
		margin: 0 auto;
		height: 100%;
	}
}

.password-toggle {
	position: absolute;
	right: 10px;
	top: 8%;
	background: none;
	border: none;
	cursor: pointer;
}

.confirm-password-toggle {
	position: absolute;
	right: 10px;
	top: 30%;
	background: none;
	border: none;
	cursor: pointer;
}

.password-help {
	font-size: 12px;
	text-align: left;
}

.password-help p {
	margin-bottom: 5px;
}

.items {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 5px;
}

.password-help li {
	margin: 0;
	padding-left: 15px;
	position: relative;
	transition: 200ms;
}

.password-help li::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
	transition: 200ms;
}

.password-help .done {
	color: #00c386;
}

.password-help .done::before {
	border: none;
	border-bottom: 2px solid #00c386;
	border-right: 2px solid #00c386;
	transform: translateY(-50%) rotate(45deg);
	border-radius: 0;
	width: 6px;
	height: 10px;
}

.password-help .fail {
	color: #fc6404;
}

.password-help .fail::before {
	content: '✕';
	border: none;
	border-radius: 0;
	top: auto;
	transform: none;
}
</style>
