<template>
	<section>
		<b-modal :modelValue="showPortfolioInUseModal" has-modal-card @close="togglePortfolioInUseModal(false)">
			<form @submit.prevent="togglePortfolioInUseModal(false)">
				<!-- Portis -->
				<div v-if="walletType === 'portis'" class="modal-card terminal">
					<div class="terminal-content has-text-left">
						<header class="trade-tabs">
							<p>{{ $t('WALLET_ALREADY_IN_USE') }}</p>
						</header>
						<p class="mt-10">
							{{ $t('WALLET_ALREADY_IN_USE_PORTIS') }}
						</p>
						<figure class="image terminal-img" style="width: 230px">
							<img src="../../assets/icons/portis_logout.svg" />
						</figure>
						<p class="mt-20">
							{{ $t('WALLET_ALREADY_IN_USE_PORTIS_LOGOUT') }}
						</p>
						<button class="button big-button is-blue transition-fast mt-20" @click="openPortisWallet">
							{{ $t('LAUNCH_PORTIS') }}
						</button>
					</div>
				</div>

				<!-- Metamask -->
				<div v-else class="modal-card terminal">
					<div class="terminal-content has-text-left">
						<header class="trade-tabs">
							<p>{{ $t('PORTFOLIO_ALREADY_IN_USE') }}</p>
						</header>
						<figure class="image is-64x64 terminal-img">
							<img src="../../assets/wrong.svg" />
						</figure>
						<p class="mt-20">
							{{ $t('PORTFOLIO_ALREADY_IN_USE_METAMASK') }}
						</p>
						<p class="mt-10" v-html="$t('PORTFOLIO_ALREADY_IN_USE_METAMASK_RECONNECT', {
							link: 'mailto:contact@help.morpher.com'
						})
							"></p>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { mapActions, mapState } from 'pinia';

//vuex
/* @group Components_Modals */
/*
  <h4> <b> Modal which shows that portfolio is assigned to current account </b> </h4>
  <br> Example: https://sketch.cloud/s/49kjZ/52VOwnx
*/
export default defineComponent({
	name: 'PortfolioInUseModal',
	data() {
		return {};
	},
	computed: {
		...mapState(useModalsStore, {
			showPortfolioInUseModal: (state) => state.showPortfolioInUseModal,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),

	},
	methods: {
		...mapActions(useModalsStore, {
			togglePortfolioInUseModal: 'togglePortfolioInUseModal',
		}),
		...mapActions(useContractStore, {
			openPortisWallet: 'openPortisWallet',
		}),

	}
});
</script>
