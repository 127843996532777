/**
 * Watchlist service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';
import addToWatchlist from '@/graphql/watchlist/addToWatchlist.mutation.graphql';
import updateWatchlist from '@/graphql/watchlist/updateWatchlist.mutation.graphql';
import removeFromWatchlist from '@/graphql/watchlist/removeFromWatchlist.mutation.graphql';
import type { addToWatchlistVariables, addToWatchlist as addToWatchlistResponse } from '@/graphql/watchlist/types/addToWatchlist';
import type { updateWatchlistVariables, updateWatchlist as updateWatchlistResponse } from '@/graphql/watchlist/types/updateWatchlist';

import type {
	removeFromWatchlistVariables,
	removeFromWatchlist as removeFromWatchlistResponse
} from '@/graphql/watchlist/types/removeFromWatchlist';

export const WatchlistService = {
	addToWatchlist(data: addToWatchlistVariables) {
		return apollo.mutate<addToWatchlistResponse>({
			mutation: addToWatchlist,
			variables: {
				id: data.id
			}
		});
	},
	updateWatchlist(data: updateWatchlistVariables) {
		return apollo.mutate<updateWatchlistResponse>({
			mutation: updateWatchlist,
			variables: {
				watchlist: data.watchlist
			}
		});
	},
	removeFromWatchlist(data: removeFromWatchlistVariables) {
		return apollo.mutate<removeFromWatchlistResponse>({
			mutation: removeFromWatchlist,
			variables: {
				id: data.id
			}
		});
	}
};
