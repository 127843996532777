import { defineStore } from 'pinia'

import { AdminService }  from '@/services/AdminService';
import type {Config} from '@/services/AdminService';
import { ADMIN, ERROR, SUCCESS, ROOT, MARKETS } from '../mutation-types';
import to from 'await-to-js';

import type { Position, Email_Template, Email_List, Airdrop_List , Airdrop_Block, Transaction, User, Scalars, Maybe } from '@/types/schema';
import type { createMarketVariables } from '@/graphql/market/types/createMarket';
import type { deleteMarketVariables } from '@/graphql/market/types/deleteMarket';
import type { createMarketSplitVariables } from '@/graphql/market/types/createMarketSplit';
import type { editMarketSplitVariables } from '@/graphql/market/types/editMarketSplit';
import type { deleteMarketSplitVariables } from '@/graphql/market/types/deleteMarketSplit';
import type { enableMarketVariables } from '@/graphql/market/types/enableMarket';
import type { enableMainchainMarketVariables } from '@/graphql/market/types/enableMainchainMarket';
import type { pauseMarketVariables } from '@/graphql/market/types/pauseMarket';
import type { updateMarketDataVariables } from '@/graphql/market/types/updateMarketData';

import type { getUsersVariables } from '@/graphql/user/types/getUsers';
import type { getPositionsVariables } from '@/graphql/positions/types/getPositions';
import type { lockPositionVariables } from '@/graphql/positions/types/lockPosition';
import type { approvePositionVariables } from '@/graphql/positions/types/approvePosition';
import type { getPurchaseTransactionVariables } from '@/graphql/transaction/types/getPurchaseTransaction';
import type { getEmailSendListVariables } from '@/graphql/email_template/types/getEmailSendList';
import type { getEmailSendSummaryVariables } from '@/graphql/email_template/types/getEmailSendSummary';

import type { getTemplateVariables } from '@/graphql/email_template/types/getTemplate';
import type { deleteTemplateVariables } from '@/graphql/email_template/types/deleteTemplate';
import type { testTemplateVariables } from '@/graphql/email_template/types/testTemplate';
import type { sendTemplateVariables } from '@/graphql/email_template/types/sendTemplate';
import type { clearTemplateUsersVariables } from '@/graphql/email_template/types/clearTemplateUsers';
import type { updateTemplateUsersVariables } from '@/graphql/email_template/types/updateTemplateUsers';
import type { getEmailsVariables } from '@/graphql/email_list/types/getEmails';
import type { updateEmailVariables } from '@/graphql/email_list/types/updateEmail';
import type { deleteEmailVariables } from '@/graphql/email_list/types/deleteEmail';
import type { insertEmailsVariables } from '@/graphql/email_list/types/insertEmails';
import type { updateGlobalMessageVariables } from '@/graphql/maintenance/types/updateGlobalMessage';
import type { updateApplicationLiveVariables } from '@/graphql/maintenance/types/updateApplicationLive';

import type { getAirdropListVariables } from '@/graphql/airdrop_list/types/getAirdropList';
import type { getBlockListVariables } from '@/graphql/airdrop_list/types/getBlockList';
import type { updateAirdropListVariables } from '@/graphql/airdrop_list/types/updateAirdropList';
import type { getLastDataVariables } from '@/graphql/user/types/getLastData';
import type { getWorkersStatusVariables } from '@/graphql/user/types/getWorkersStatus';
import { useStatusStore } from './status';
import { useMarketsStore } from './markets';
import { useUserStore } from './user';
import { getStore } from '..';

export type KYC_Data = {
	__typename?: 'KYC_Data',
	uuid?: Maybe<Scalars['String']>,
	email?: Maybe<Scalars['String']>,
	full_name?: Maybe<Scalars['String']>,
	identification_document_back_file?: Maybe<Scalars['String']>,
	identification_document_front_file?: Maybe<Scalars['String']>,
	airdrop_list?: Maybe<Airdrop_List>,
  
  }

export interface AdminState {
	config: Config;
	data: string[];
	positions: Position[];
	invitations: string[];
	templates: Email_Template[];
	emails: Email_List[];
	airdrop_list: Airdrop_List[];
	block_list: Airdrop_Block[];
	kyc_data: KYC_Data[];
	users: User[];
	purchase: Transaction[];
	migrate_fortmatic: boolean;
}


let store = {
		status: {} as any,
		markets:undefined as any,
		user:undefined as any,
}

export const useAdminStore = defineStore('admin', {
  state: () => {

	store = getStore()
	

    return {
      config: { application_live: true },
      data: [],
      positions: [],
      invitations: [],
      templates: [],
      emails: [],
      airdrop_list: [],
      block_list: [],
      kyc_data: [],
      users: [],
      purchase: [],
      migrate_fortmatic: false,
    } as AdminState
  },
  // could also be defined as

  actions: {
		/**
		 * Create market
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async createMarket(data: createMarketVariables) {
			const [err, result] = await to(AdminService.createMarket(data));
			if (err) return store.status[ERROR](err);
			store.markets.getMarketsByIds(
				{
					variables: {
						ids: [],
						minutely: false
					},
					format: true,
					commitType: MARKETS.ALL
				}as any
			);
			if (result.data.createMarket) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[ERROR]('ERROR_DEFAULT');
		},
		/**
		 * Delete market
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async deleteMarket(data: deleteMarketVariables) {
			const [err, result] = await to(AdminService.deleteMarket(data));
			if (err) return store.status[ERROR](err);
			store.markets.getMarketsByIds(
				{
					variables: {
						ids: [],
						minutely: false
					},
					format: true,
					commitType: MARKETS.ALL
				} as any
			);
			if (result.data.deleteMarket) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},
		async createMarketSplit(data: createMarketSplitVariables) {
			const [err, result] = await to(AdminService.createMarketSplit(data));
			if (err) store.status[SUCCESS]('ERROR_DEFAULT');
			else store.status[SUCCESS]('SUCCESS_DEFAULT');
		},
		async editMarketSplit( data: editMarketSplitVariables) {
			const [err, result] = await to(AdminService.editMarketSplit(data));
			if (err) store.status[SUCCESS]('ERROR_DEFAULT');
			else store.status[SUCCESS]('SUCCESS_DEFAULT');
		},
		async deleteMarketSplit( data: deleteMarketSplitVariables) {
			const [err, result] = await to(AdminService.deleteMarketSplit(data));
			if (err) store.status[SUCCESS]('ERROR_DEFAULT');
			else store.status[SUCCESS]('SUCCESS_DEFAULT');
		},
		/**
		 * Enable/Disable selected market
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async enableMarket( data: enableMarketVariables) {
			const [err, result] = await to(AdminService.enableMarket(data));
			if (err) return store.status[ERROR](err);
			store.markets.getMarketsByIds(
				{
					variables: {
						ids: [data.market_id],
						minutely: true,
					},
					commitType: MARKETS.SELECTED
				} as any
			);
			if (result.data.enableMarket) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
			this[ADMIN.INVITATIONS](this.invitations);
		},
		async enableMainchainMarket( data: enableMainchainMarketVariables) {
			const [err, result] = await to(AdminService.enableMainchainMarket(data));
			if (err) return store.status[ERROR](err);
			store.markets.getMarketsByIds(
				{
					variables: {
						ids: [data.market_id],
						minutely: true
					},
					commitType: MARKETS.SELECTED
				} as any
			);
			if (result.data.enableMainchainMarket) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
			this[ADMIN.INVITATIONS](this.invitations);
		},
		/**
		 * Pause/Unpause selected market
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async pauseMarket( data: pauseMarketVariables) {
			const [err, result] = await to(AdminService.pauseMarket(data));
			if (err) return store.status[ERROR](err);
			store.markets.getMarketsByIds(
				{
					variables: {
						ids: [data.market_id],
						minutely: true
					},
					commitType: MARKETS.SELECTED
				} as any
			);
			if (result.data.pauseMarket) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},
		/**
		 * Pause/Unpause selected market
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async updateMarketData( data: updateMarketDataVariables) {
			const [err, result] = await to(AdminService.updateMarketData(data));
			if (err) return store.status[ERROR](err);
			store.markets.getMarketsByIds(
				{
					variables: {
						ids: [data.market_id],
						minutely: true
					},
					commitType: MARKETS.SELECTED
				} as any
			);
			if (result.data.updateMarketData) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},

		/**
		 * Get user list
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getUsers( data: getUsersVariables) {
			const [err, result] = await to(AdminService.getUsers(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.USERS](result.data.getUsers);
		},

		async getPositions( data: getPositionsVariables) {
			const [err, result] = await to(AdminService.getPositions(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.POSITIONS](result.data.getPositions);
		},

		async lockPosition( data: lockPositionVariables) {
			const [err, result] = await to(AdminService.lockPosition(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.POSITIONS](result.data.lockPosition);
		},

		async approvePosition( data: approvePositionVariables) {
			const [err, result] = await to(AdminService.approvePosition(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.POSITIONS](result.data.approvePosition);
		},

		/**
		 * Get purchase list
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getPurchaseTransaction( data: getPurchaseTransactionVariables) {
			const [err, result] = await to(AdminService.getPurchaseTransaction(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.PURCHASE](result.data.getPurchaseTransaction);
		},

		/**
		 * Get email send list
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getEmailSendList( data: getEmailSendListVariables) {
			const [err, result] = await to(AdminService.getEmailSendList(data));
			if (err) return store.status[ERROR](err);

			return result.data.getEmailSendList;
		},

		/**
		 * Get email send list summary
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getEmailSendSummary( data: getEmailSendSummaryVariables) {
			const [err, result] = await to(AdminService.getEmailSendSummary(data));
			if (err) return store.status[ERROR](err);
			return result.data.getEmailSendSummary;
		},

		/**
		 * Get a single email template
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getTemplate( data: getTemplateVariables) {
			const [err, result] = await to(AdminService.getTemplate(data));
			if (err) return store.status[ERROR](err);
			return result.data.getTemplate;
		},

		/**
		 * Delete template
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async deleteTemplate( data: deleteTemplateVariables) {
			const [err, result] = await to(AdminService.deleteTemplate(data));
			if (err) return store.status[ERROR](err);
			if (result.data.deleteTemplate) {
				for (let i = 0; i < this.templates.length; i++) {
					if (this.templates[i].id === data.id) {
						this.templates.splice(i, 1);
					}
				}
				store.status[SUCCESS]('SUCCESS_DELETE');
				
				this[ADMIN.TEMPLATES](this.templates);
			}
		},

		/**
		 * Test email template
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async testTemplate( data: testTemplateVariables) {
			const [err, result] = await to(AdminService.testTemplate(data));
			if (err) return store.status[ERROR](err);
			if (result.data.testTemplate) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},
		/**
		 * Send email template
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async sendTemplate( data: sendTemplateVariables) {
			const [err, result] = await to(AdminService.sendTemplate(data));
			if (err) return store.status[ERROR](err);
			if (result.data.sendTemplate) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},
		/**
		 * Send email template
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async cancelSendTemplate( data: sendTemplateVariables) {
			const [err, result]: any = await to(AdminService.cancelSendTemplate(data));
			if (err) return store.status[ERROR](err);
			if (result.data.cancelSendTemplate) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},

		/**
		 * Send email template
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async clearTemplateUsers( data: clearTemplateUsersVariables) {
			const [err, result] = await to(AdminService.clearTemplateUsers(data));
			if (err) return store.status[ERROR](err);
			if (result.data.clearTemplateUsers) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},
		/**
		 * Send email template
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async updateTemplateUsers( data: updateTemplateUsersVariables) {
			const [err, result] = await to(AdminService.updateTemplateUsers(data));
			if (err) return store.status[ERROR](err);
			if (result.data.updateTemplateUsers) store.status[SUCCESS]('SUCCESS_DEFAULT');
			else store.status[SUCCESS]('ERROR_DEFAULT');
		},

		/**
		 * Get email from the list
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getEmails( data: getEmailsVariables) {
			const [err, result] = await to(AdminService.getEmails(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.EMAILS](result.data.getEmails);
		},

		/**
		 * Get airdrop users from the airdrop list
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getAirdrop( data: getAirdropListVariables) {
			const [err, result] = await to(AdminService.getAirdropList(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.AIRDROP](result.data.getAirdropList);
		},

		/**
		 * Get airdrop users from the airdrop list
		 * @param commit
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getBlockList( data: getBlockListVariables) {
			const [err, result] = await to(AdminService.getBlockList(data));
			if (err) return store.status[ERROR](err);
			this[ADMIN.BLOCKLIST](result.data.getBlockList);
		},

		/**
		 * Update airdrop list entry
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async updateAirdrop( data: updateAirdropListVariables) {
			const [err, result] = await to(AdminService.updateAirdrop(data));
			if (err) return store.status[ERROR](err);
			if (result.data.updateAirdropList) {
				for (let i = 0; i < this.airdrop_list.length; i++) {
					if (this.airdrop_list[i].uuid === data.id) {
						this.airdrop_list[i] = result.data.updateAirdropList;
					}
				}
				for (let i = 0; i < this.kyc_data.length; i++) {
					if (this.kyc_data[i] && this.kyc_data[i].airdrop_list?.uuid === data.id) {
						this.kyc_data[i].airdrop_list = result.data.updateAirdropList;
					}
				}
				store.status[SUCCESS]('SUCCESS_DEFAULT');
				this[ADMIN.AIRDROP](this.airdrop_list);
			} else store.status[SUCCESS]('ERROR_DEFAULT');
		},
		/**
		 * Update email in the list
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async updateEmail( data: updateEmailVariables) {
			const [err, result] = await to(AdminService.updateEmail(data));
			if (err) return store.status[ERROR](err);
			if (result.data.updateEmail) {
				for (let i = 0; i < this.emails.length; i++) {
					if (this.emails[i].id === data.id) {
						this.emails[i] = result.data.updateEmail;
					}
				}
				store.status[SUCCESS]('SUCCESS_DEFAULT');
				this[ADMIN.EMAILS](this.emails);
			} else store.status[SUCCESS]('ERROR_DEFAULT');
		},
		/**
		 * Delete email from the list
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async deleteEmail( data: deleteEmailVariables) {
			const [err, result] = await to(AdminService.deleteEmail(data));
			if (err) return store.status[ERROR](err);
			if (result.data.deleteEmail) {
				for (let i = 0; i < this.emails.length; i++) {
					if (this.emails[i].id === data.id) {
						this.emails.splice(i, 1);
					}
				}
				store.status[SUCCESS]('SUCCESS_DELETE');
				this[ADMIN.EMAILS](this.emails);
			}
		},
		/**
		 * Insert new emails
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async insertEmails(data: insertEmailsVariables) {
			const [err, result] = await to(AdminService.insertEmails(data));
			if (err) return store.status[ERROR](err);
			this.emails = this.emails.concat(result.data.insertEmails);
			store.status[SUCCESS]('SUCCESS_INVITE');
			this[ADMIN.EMAILS](this.emails);
		},

		/**
		 * Application config collection
		 * - Subscribed for the changes
		 * @param commit
		 * @param state
		 * @param rootState
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getConfig() {
			const [err, result] = await to(AdminService.getConfig());
			if (err) return store.status[ERROR](err);
			this[ADMIN.CONFIG](result.data);

			if (store.user && store.user.data) {
				if (this.config.application_live === false && store.user.data.role !== 'admin') {
					store.user.logout({});
				}
			}

			AdminService.subscribeConfig(this.config, data => {
				try {
					if (store.user && store.user.data && store.user.data.role) {
						if (
							data.data && data.data.updateMaintenance.application_live === false &&
							(!store.user.data || store.user.data.role !== 'admin')
						) {
							store.status[ERROR]({ message: 'MAINTENANCE_MESSAGE' })
							store.user.logout({});
						}
					}
				} catch (err) {}
			});
		},
		/**
		 * Application maintain
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async updateApplicationLive( data: updateApplicationLiveVariables) {
			const [err] = await to(AdminService.updateApplicationLive(data));
			if (err) return store.status[ERROR](err);
			store.status[SUCCESS]('SUCCESS_DEFAULT');
		},
		/**
		 * Application global message
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async updateGlobalMessage( data: updateGlobalMessageVariables) {
			const [err] = await to(AdminService.updateGlobalMessage(data));
			if (err) return store.status[ERROR](err);
			store.status[SUCCESS]('SUCCESS_DEFAULT');
		},

		/**
		 * Last order/position data for admin
		 * @param commit
		 * @param state
		 * @param data
		 * @returns {Promise<*>}
		 */
		async getLastData( data: getLastDataVariables) {
			const [err, result] = await to(AdminService.getLastData(data));
			if (err) return store.status[ERROR](err);
			return result.data.getLastData;
		},

		async getWorkersStatus( data: getWorkersStatusVariables) {
			const [err, result] = await to(AdminService.getWorkersStatus(data));
			if (err) return store.status[ERROR](err);
			return result.data.getWorkersStatus;
		},

		reset() {
			this.$reset();
		},

		
			[ADMIN.CONFIG](data: any) {
				this.config = data;
			},
			[ADMIN.AIRDROP](data: Airdrop_List[]) {
				this.airdrop_list = data;
			},
			[ADMIN.BLOCKLIST](data: Airdrop_Block[]) {
				this.block_list = data;
			},
	
			[ADMIN.EMAILS]( data: Email_List[]) {
				this.emails = data;
			},
			[ADMIN.KYC]( data: KYC_Data[]) {
				this.kyc_data = data;
			},
			[ADMIN.INVITATIONS]( data: string[]) {
				this.invitations = data;
			},
			[ADMIN.TEMPLATES]( data: Email_Template[]) {
				this.templates = data;
			},
			[ADMIN.USERS]( data: User[]) {
				this.users = data;
			},
			[ADMIN.POSITIONS]( data: Position[]) {
				this.positions = data;
			},
			[ADMIN.PURCHASE]( data: Transaction[]) {
				this.purchase = data;
			},
			
		
	},
} )