query getNotificationTemplates($limit: Int, $offset: Int, $order: JSON, $template_type: String, $group: Boolean, $title: String, $filter: String) {
    getNotificationTemplates(limit: $limit, offset: $offset, order: $order, template_type: $template_type, group: $group, title: $title, filter: $filter) {
        id
        title
        description
        aws
        timestamp
        filter
        lang
        total_notifications
        notifications_sent
        total_opened
        status   
        template_type   
    }
}
