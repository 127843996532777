<template>
	<section>
		<div class="has-font-manrope">
			<b-modal :modelValue="showMobileAppModal" has-modal-card @close="closeModal">
				<div class="modal-card terminal">
					<div class="helper-header has-text-centered">
						{{ $t('migration.HELPER_HEADER_COMPLETE') }}
					</div>
					<div class="text has-text-centered mt-2 mb-0 is-hidden-mobile">
						<span>{{ $t('migration.HELPER_TEXT_COMPLETE_2') }}</span>
					</div>
					<div class="helper-image is-hidden-mobile">
						<img class="qr" src="@/assets/img/mobile/mobile_qr.svg" />
					</div>
					<div class="text has-text-centered mt-2 mb-0">
						<span>{{ $t('FIND_APP_STORE') }}</span>
					</div>
					<div class="app-buttons">
						<a href="https://apps.apple.com/at/app/morpher-trading-and-investing/id6444658444?l=en-GB"
							target="_blank">
							<img src="@/assets/img/mobile/app_store_badge.svg" class="app-button" />
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.morpher.trade.beta" target="_blank">
							<img src="@/assets/img/mobile/play_store_badge.svg" class="app-button" />
						</a>
					</div>
				</div>
			</b-modal>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useModalsStore } from '@/store/modules/modals';
import VueQrcode from '@chenfengyuan/vue-qrcode';
/* @group Components_Modals */
/*
  <h4> <b> New portfolio detected </b> </h4>
  <br> Airdrop message - 10k MPH added to wallet
*/
export default defineComponent({
	name: 'MobileAppModal',
	components: {
		VueQrcode
	},
	data() {
		return {
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showMobileAppModal: (state) => state.showMobileAppModal,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),

	},
	mounted() {
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleMobileAppModal: 'toggleMobileAppModal',
		}),

		closeModal() {
			this.toggleMobileAppModal(false);
		},
	}
});
</script>

<style scoped>
.modal-card {
	padding: 20px;
	max-width: 400px !important;

}

.helper-header {
	font-size: 28px;
	font-weight: 700;

}

.qr {
	max-width: 188px;
}

.text {
	font-size: 14px;
	font-weight: 400;
}

.app-buttons {
	margin-top: 16px;
}

.helper-image {
	margin-top: 16px;
}

.app-button {
	width: 177px;
	height: 52px;
}
</style>
