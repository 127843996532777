import { useMarketsStore } from "@/store/modules/markets";
import { mapState } from "pinia";
import { defineComponent } from "vue";

/**
 Live price updates:
 "live" boolean flag which says if price and change should be live
 Implemented by: ChartOHLC and PortfolioLine Components
 Chart Events:
    *updateHover* -> live is set to false - hover price and hover change
    *updateLive* -> if live is true update change and change percent
    *updateChange* -> update new init change and change percent
 */
export default defineComponent({
	data() {
		return {
			changeByRange: false,
			firstInRange: 0,
			live: true,
			lastChange: 0,
			lastChangePercent: 0,
			changePercent: 0,
			change: 0,
			price: 0,
			dayRange: 1,
			chartType: ''
		};
	},
	computed: {

		...mapState(useMarketsStore, {
			selectedMarket: (state) => state.selected,


		}),
	},
	methods: {
		updateLive(data: any) {
			this.chartType = data.chartType;
			if (data.dayRange) {
				this.dayRange = data.dayRange;
			}
			
			if (this.live) {
				if (this.dayRange === 1) {
					this.change = this.selectedMarket.change || 0;
					this.changePercent = this.selectedMarket.change_percent || 0;
				} else {
					this.change = data.last - data.first;
					this.changePercent = (this.change / data.first) * 100;
				}
			}
		},
		updateChange(data: any) {
			if (this.live) {
				this.reset();
				if (data.dayRange !== 1) this.changeByRange = true;
				this.firstInRange = data.first;
				this.chartType = data.chartType;
				if (data.dayRange) {
					this.dayRange = data.dayRange;
				}

				if (this.chartType === 'market') {
					if (data.dayRange === 1) {
						this.change = this.selectedMarket.change || 0;
						this.changePercent = this.selectedMarket.change_percent || 0;
					} else {
						this.change = (this.selectedMarket?.close || 0) - this.firstInRange || 0;
						this.changePercent = (this.change / this.firstInRange) * 100 || 0;
					}
				} else {
					this.change = data.last - this.firstInRange || 0;
					this.changePercent = (this.change / this.firstInRange) * 100 || 0;
				}
				this.lastChangePercent = this.changePercent;
				this.lastChange = this.change;
			}
		},
		updateHover(data: any) {
			if (this.dayRange !== 1) this.changeByRange = true;
			this.chartType = data.chartType;
			this.changeByRange = true;
			this.firstInRange = parseFloat(data.first);
			if (this.live) this.live = false;
			if (!this.live) {
				this.price = data.last;
				this.change = parseFloat(data.last) - parseFloat(data.first);
				this.changePercent = (this.change / data.first) * 100;
			}
		},
		reset() {
			this.live = true;
			if (this.dayRange === 1) this.changeByRange = false;
			this.changePercent = this.lastChangePercent;
			this.change = this.lastChange;
		}
	},
	watch: {
		'selectedMarket.close'(nv) {
			if (nv && !this.changeByRange && this.chartType === 'market') {
				this.change = nv - this.firstInRange || 0;
				this.changePercent = (this.change / this.firstInRange) * 100 || 0;
			}
		}
	}
});
