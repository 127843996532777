<template>
	<div class="has-font-manrope">
		<div class="deposit_content has-text-left">
			<div class="header-bar-global">
				<button class="button back-btn" @click="closeDepositBonus()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('DEPOSIT_EARN') }}
			</div>

			<div class="has-text-centered mt-4">
				<img src="@/assets/img/rewards/blue_reward.svg" />
			</div>

			<div v-if="mph_market && mph_market.close" class="mt-4">
				<div class="slider-callout">
					<p>
						{{ $t('DEPOSIT_PROMO_GET', { number_rewards: calcNumRewards() }) }}
					</p>
					<p class="deposit-text mt-3">
						{{ currencySymbol }} {{ formatDeposit() }}
					</p>
					<div class="slider-width mt-2 mb-1">
						<b-slider v-model="depositUSD" rounded :min="50" :step="10" :max="5000" :tooltip="false"
							@change="moved_slider = true" class="earn-slider is-blorange">
						</b-slider>
					</div>
					<p v-if="calcNumRewards() > 0" class="is-size-14">
						{{ $t('DEPOSIT_PROMO_CHANCE', {
							percent: calcChance(), currency_symbol: currencySymbol, amount:
								calcRewardAmount()
						}) }}
					</p>
					<p v-else class="is-size-14">
						{{ $t('DEPOSIT_PROMO_THRESHOLD', { currency_symbol: currencySymbol, amount: formatThreshold() })
						}}
					</p>
				</div>
				<p class="mt-5 is-size-14 is-line-height-20">
					{{ $t('DEPOSIT_PROMO_EXPLAIN', { amount: formatUSDEstimate(100000, mph_market.close, 50) }) }}
				</p>
			</div>

			<p class="is-size-7 mt-4 has-text-asphalt">
				{{ $t('DEPOSIT_PROMO_DISCLAIMER') }}
				<a :href="supportArticleLink('DepositBonus')" target="_blank">
					{{ $t('LEARN_MORE') }}
				</a>
			</p>

			<button class="button plain-primary-btn is-fullwidth mt-4" @click="closeDepositBonus()">
				{{ $t('DONE') }}
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useFundsStore } from '@/store/modules/funds';
import { useMarketsStore } from '@/store/modules/markets';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Application_View */
/*
  <h4> <b> Deposit Bonus </b> </h4>
  <br>
  <br> Screen for promoting and estimated deposit bonus.
*/
export default defineComponent({
	name: 'DepositBonus',
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		},
		totalWithdraw: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			depositUSD: 500,
			depositExchange: 0,
			moved_slider: false
		};
	},
	computed: {
		...mapState(useFundsStore, {
			funds: (state) => state,
		}),
		...mapState(useMarketsStore, {
			mph_market: (state) => state.mph_market,
		}),

	},
	async mounted() {
		if (!this.mph_market) {
			this.getMPHMarket({
				component: this
			});
		}
	},
	beforeUnmount() {
		this.updateFunds({ showDepositBonus: false });
	},
	methods: {
		...mapActions(useFundsStore, {
			updateFunds: 'updateFunds',
		}),
		...mapActions(useMarketsStore, {
			getMPHMarket: 'getMPHMarket',
		}),

		exchangeAmount(value: number) {
			if (this.funds?.currency?.toLowerCase() === 'usd') {
				return value
			} else {
				return value / this.currencyExchange;
			}
		},
		closeDepositBonus() {
			// Set deposit amount if user played with slider
			if (this.moved_slider && this.depositUSD >= 100) {
				this.updateFunds({ showDepositBonus: false, amount: this.exchangeAmount(this.depositUSD) });
			} else {
				this.updateFunds({ showDepositBonus: false });
			}
		},
		calcNumRewards() {
			if (this.depositUSD > (100 + this.totalWithdraw)) {
				const rewards = Math.floor((this.depositUSD - this.totalWithdraw) / 100);
				return rewards
			} else return 0
		},
		calcChance() {
			const rewards = this.calcNumRewards();
			const percent_win = 1 - 0.025;
			const chance = 1 - Math.pow(percent_win, rewards);
			return Math.round(chance * 100)
		},
		calcRewardAmount() {
			const usd_value = this.exchangeAmount((this.mph_market?.close || 0) * 500);
			return usd_value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		},
		formatDeposit() {
			const amount = this.exchangeAmount(this.depositUSD);
			return Math.round(amount).toLocaleString();
		},
		formatThreshold() {
			const amount = this.exchangeAmount(this.totalWithdraw + 100);
			return Math.round(amount).toLocaleString();
		}
	}
});
</script>
<style scoped>
.deposit_content {
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 24px;
}

.is-64 {
	width: 64px;
	height: 64px;
	min-width: 64px;
}

.slider-callout {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid #EAE9ED;
	border-radius: 0.5rem;
	padding: 1rem;
}

.deposit-text {
	font-size: 2rem;
	font-weight: 700;
}

.slider-width {
	margin: 0 1.25rem;
}
</style>