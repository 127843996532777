/**
 * Trades service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';
import getOrder from '@/graphql/order/getOrder.query.graphql';
import getOpeningOrder from '@/graphql/order/getOpeningOrder.query.graphql';
import type { getOrderVariables, getOrder as getOrderResponse } from '@/graphql/order/types/getOrder';
import type { getOpeningOrderVariables, getOpeningOrder as getOpeningOrderResponse } from '@/graphql/order/types/getOpeningOrder';

import orderResultUpdate from '@/graphql/order/orderResultUpdate.mutation.graphql';
import type { orderResultUpdateVariables, orderResultUpdate as orderResultUpdateResponse } from '@/graphql/order/types/orderResultUpdate';
/**
 * Position service which wraps all GraphQL queries for this model
 */

import type { FetchResult, Observable } from '@apollo/client/core';
import type { updateOrder as updateOrderResult } from '@/graphql/order/types/updateOrder';
import updateOrder from '@/graphql/order/updateOrder.subscription.graphql';
import updateMarket from '@/graphql/market/updateMarket.subscription.graphql';
import type { updateMarket as updateMarketResult } from '@/graphql/market/types/updateMarket';

let SubscriptionList: any[] = [];

export const OrderService = {
	subscribeUpdateOrder(eth_address: string, callback: (data: FetchResult<updateOrderResult>) => void) {
		const existingSubscription = SubscriptionList.find(sub => sub.id === eth_address.toLowerCase());
		if (existingSubscription) {
			this.unsubscribe(existingSubscription.id, existingSubscription.sub);
		}

		const subscription = apollo
			.subscribe({
				query: updateOrder,
				variables: {
					event: 'ORDER_' + eth_address.toLowerCase()
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeUpdateOrder', err)
				}
			});
		return subscription;
	},
	unsubscribe(eth_address: String, subscription: any) {
		if (subscription) {
			subscription.unsubscribe();
			SubscriptionList = SubscriptionList.filter(sub => sub.id !== eth_address.toLowerCase());
		}
	},
	getOpeningOrder(variables: getOpeningOrderVariables) {
		return apollo.query<getOpeningOrderResponse>({
			query: getOpeningOrder,
			variables
		});
	},

	
	getOrder(variables: getOrderVariables) {
		return apollo.query<getOrderResponse>({
			query: getOrder,
			variables
		});
	},
	orderResultUpdate(variables: orderResultUpdateVariables) {
		return apollo.mutate<orderResultUpdateResponse>({
			mutation: orderResultUpdate,
			variables
		});
	}
};
