<template>
	<section>
		<b-modal :modelValue="showNetworkMetamaskModal" has-modal-card @close="close">
			<form @submit.prevent="close">
				<div class="modal-card terminal">
					<div class="terminal-content has-text-left">
						<h1 class="has-font-rubik is-size-5 has-text-centered has-text-weight-medium mb-10">
							{{ $t('network.SWITCH_NETWORKS_TITLE') }}
						</h1>
						<div v-if="transactOnNetwork !== 'mainchain'">
							<div v-if="currentChainValid && currentChainValid === 'different'">
								<p>{{ $t('network.SIDECHAIN_DESC') }}</p>
								<button class="button big-button is-blue mt-4 mb-3"
									@click="() => addNetworkToMetamask(false)">
									{{ $t('network.CONNECT') }}
								</button>
							</div>
							<!-- If already have another network: new message with instructions. Hide FAQ question 1. -->
							<div v-else-if="currentChainValid && currentChainValid === 'same_not_valid'">
								<p>{{ $t('network.SAME_ID_WRONG_NETWORK') }}</p>
								<div class="is-size-14 mt-1 mb-3">
									<p class="mt-2">
										{{ $t('network.FAQ_1_INFO') }}
									</p>
									<p class="mt-2">
										<span class="has-text-weight-medium">{{ $t('network.NETWORK_NAME') }}:</span>
										{{ $t('MORPHER_SIDECHAIN_SMALL') }}
									</p>
									<p class="has-text-weight-medium mt-1">{{ $t('network.RPC_URL') }}</p>
									<div class="field is-grouped">
										<div class="control is-expanded">
											<b-input v-model="sidechainURL" type="url" readonly />
										</div>
										<div class="control">
											<button type="button" class="button is-info transition-faster copy-btn"
												@click="copyTextToClipboard">
												<b-icon icon="fas fa-copy" pack="fas" size="is-small" />
											</button>
										</div>
									</div>
									<p class="mt-1">
										<span class="has-text-weight-medium">{{ $t('network.CHAIN_ID') }}:</span>
										{{ chain_id }}
									</p>
								</div>
							</div>
							<hr class="trade-hr" />
							<!-- FAQs -->
							<div class="mt-2" />
							<b-collapse v-show="currentChainValid === 'different'" animation="slide" :open="false"
								class="mb-3">
								<template #trigger="props">
									<div class="is-flex is-justify-content-space-between" role="button">
										<p class="has-text-weight-medium">
											{{ $t('network.FAQ_1') }}
										</p>
										<p class="ml-3">
											<b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
										</p>
									</div>
								</template>
								<div class="is-size-14">
									<p class="mt-2">
										{{ $t('network.FAQ_1_INFO') }}
									</p>
									<p class="mt-2">
										<span class="has-text-weight-medium">{{ $t('network.NETWORK_NAME') }}:</span>
										{{ $t('MORPHER_SIDECHAIN_SMALL') }}
									</p>
									<p class="has-text-weight-medium mt-1">{{ $t('network.RPC_URL') }}</p>
									<div class="field is-grouped">
										<div class="control is-expanded">
											<b-input v-model="sidechainURL" type="url" readonly />
										</div>
										<div class="control">
											<button type="button" class="button is-info transition-faster copy-btn"
												@click="copyTextToClipboard">
												<b-icon icon="fas fa-copy" pack="fas" size="is-small" />
											</button>
										</div>
									</div>
									<p class="mt-1">
										<span class="has-text-weight-medium">{{ $t('network.CHAIN_ID') }}:</span>
										{{ chain_id }}
									</p>
								</div>
							</b-collapse>
							<b-collapse animation="slide" :open="false">
								<template #trigger="props">
									<div class="is-flex is-justify-content-space-between" role="button">
										<p class="has-text-weight-medium">
											{{ $t('network.FAQ_2') }}
										</p>
										<p class="ml-3">
											<b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
										</p>
									</div>
								</template>
								<div>
									<p class="is-size-14 mt-2">
										{{ $t('network.FAQ_2_INFO') }}
									</p>
								</div>
							</b-collapse>
						</div>
						<div v-else>
							<p v-html="$t('network.SWITCH_TO_MAIN_NETWORK')" />
							<button class="button big-button is-blue mt-4 mb-3"
								@click="() => addNetworkToMetamask(true)">
								{{ $t('network.CONNECT_MAINCHAIN') }}
							</button>
							<hr class="trade-hr" />
							<!-- FAQs -->
							<div class="mt-2" />
							<b-collapse animation="slide" :open="false">
								<template #trigger="props">
									<div class="is-flex is-justify-content-space-between" role="button">
										<p class="has-text-weight-medium">
											{{ $t('network.FAQ_1') }}
										</p>
										<p class="ml-3">
											<b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
										</p>
									</div>
								</template>
								<div>
									<p class="is-size-14 mt-2" v-html="$t('network.SWITCH_MAINNET')" />
								</div>
							</b-collapse>
							<p class="mt-4 is-size-7" v-html="$t('network.MAINNET_ETH_FEES')" />
						</div>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';
import { useModalsStore } from '@/store/modules/modals';
import EventBus from '@/store/event-bus';
import { copyToClipboard } from '@/helpers/utils';
import { toHex } from 'viem';
/* @group Components_Modals */
/*
	<h4> <b> Modal that automatically switches networks for Metamask users. </b> </h4>
*/
export default defineComponent({
	name: 'NetworkMetamaskModal',
	data() {
		return {
			sidechainURL: 'https://sidechain.morpher.com',

		};
	},
	computed: {
		...mapState(useUserStore, {
			user: (state) => state.data,
			chain_id: (state) => state.data?.blockchain_info?.chain_id
		}),
		...mapState(useModalsStore, {
			showNetworkMetamaskModal: (state) => state.showNetworkMetamaskModal,
		}),
		...mapState(useContractStore, {
			currentChainValid: (state) => state.currentChainValid,
			transactOnNetwork: (state) => state.transactOnNetwork,
		}),

	},
	watch: {
		showNetworkMetamaskModal(nv) {
			// Do not show switching modal on deposit or purchase route
			if (
				(nv && this.$route.path.toLowerCase().includes('/funds/deposit')) ||
				(nv && this.$route.path.toLowerCase().includes('/funds/purchase'))
			) {
				this.toggleNetworkMetamaskModal(false);
			}
		}
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleNetworkMetamaskModal: 'toggleNetworkMetamaskModal',
		}),

		close() {
			this.toggleNetworkMetamaskModal(false);
		},
		copyTextToClipboard() {
			copyToClipboard(this.sidechainURL, this);
		},
		addNetworkToMetamask(isMainchain = false) {
			if (!window.ethereum) return;

			if (isMainchain) {
				window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: '0x1' }]
				});
				return;
			}
			window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [this.getNetworkParams()]
			});
		},
		getNetworkParams() {
			const chainRPC = import.meta.env.VITE_SIDECHAIN;
			const scan = import.meta.env.VITE_ETHERSCAN_URL;
			const environment = import.meta.env.VITE_MODE;
			let chainId = '0x15'
			if (this.user?.blockchain_info?.chain_id) {
				chainId = toHex(Number(this.user.blockchain_info.chain_id))
			}

			return {
				chainId: chainId,
				chainName: 'Morpher Sidechain' + (environment === 'development' || environment === 'staging' ? ' - Test' : ''),
				nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
				rpcUrls: [chainRPC],
				blockExplorerUrls: [scan]
			};
		}
	}
});
</script>
<style scoped>
.copy-btn {
	height: 40px;
	width: 3rem;
}

.manual-icon {
	transition: 200ms;
}

.manual-icon.open {
	transform: rotateZ(90deg);
}

.manual-button {
	cursor: pointer;
}
</style>
