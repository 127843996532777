import { CONTRACT } from '@/store/mutation-types'
import { i18n } from '../plugins/i18n'

export const getCurrencyList = () => {
	const currency_list = {
		cash: [
      
			 {
				id: 'usd',
				symbol: 'USD',
				name: i18n.t('USD'),
				icon: '$',
				icon_image: 'usd.svg'
			},
			{
				id: 'eur',
				symbol: 'EUR',
				name: i18n.t('EUR'),
				icon: '€',
				icon_image: 'eur.svg'
			},
			{
				id: 'gbp',
				symbol: 'GBP',
				name: i18n.t('GBP'),
				icon: '£',
				icon_image: 'gbp.svg'
			}
		],
		crypto:  [
			{
				id: 'coinbase',
				symbol: i18n.t('funds.MULTIPLE_CURRENCIES'),
				name: i18n.t('funds.PAY_WITH_COINBASE'),
				icon: 'coinbase.svg',
				provider: 'coinbase',
				chain: 'polygon'
			},
			{
				id: 'eth',
				symbol: 'ETH',
				name: 'Ethereum',
				icon: 'eth.svg',
				provider: 'morpher',
				chain: 'eth'
			},
			{
				id: 'pol',
				symbol: 'POL ' + i18n.t('ON') + ' Polygon',
				name: 'Polygon',
				icon: 'pol.svg',
				provider: 'morpher',
				chain: 'polygon'
			},
			{
				id: 'bnb',
				symbol: 'BNB ' + i18n.t('ON') + ' BNB Smart Chain',
				name: 'BNB',
				icon: 'bnb.svg',
				provider: 'morpher',
				chain: 'bnb'
			},
			{
				id: 'usdt',
				symbol: 'USDT ' + i18n.t('ON') + ' Ethereum',
				name: 'Tether',
				icon: 'usdt.svg',
				provider: 'morpher',
				chain: 'eth'
			},
			{
				id: 'usdc',
				symbol: 'USDC ' + i18n.t('ON') + ' Ethereum',
				name: 'USD Coin',
				icon: 'usdc.svg',
				provider: 'morpher',
				chain: 'eth'
			},
			{
				id: 'busd',
				symbol: 'BUSD ' + i18n.t('ON') + ' BNB Smart Chain',
				name: 'Binance USD',
				icon: 'busd.svg',
				provider: 'morpher',
				chain: 'bnb'
			},			
			{
				id: 'mph',
				symbol: 'MPH ' + i18n.t('ON') + ' Ethereum',
				name: 'Morpher Token',
				icon: 'mph.svg',
				provider: 'morpher',
				chain: 'eth'
			},
			{
				id: 'dai',
				symbol: 'DAI ' + i18n.t('ON') + ' Ethereum',
				name: 'Dai',
				icon: 'dai.svg',
				provider: 'morpher',
				chain: 'eth'
			},	
			{
				id: 'pol',
				symbol: 'POL ' + i18n.t('ON') + ' Ethereum',
				name: 'Polygon',
				icon: 'pol.svg',
				provider: 'morpher',
				chain: 'eth'
			},
			{
				id: 'bnb',
				symbol: 'BNB ' + i18n.t('ON') + ' Ethereum',
				name: 'BNB',
				icon: 'bnb.svg',
				provider: 'morpher',
				chain: 'eth'
			},		
			{
				id: 'busd',
				symbol: 'BUSD ' + i18n.t('ON') + ' Ethereum',
				name: 'Binance USD',
				icon: 'busd.svg',
				provider: 'morpher',
				chain: 'eth'
			},
			{
				id: 'wbtc',
				symbol: 'WBTC ' + i18n.t('ON') + ' Ethereum',
				name: 'Wrapped Bitcoin',
				icon: 'wbtc.svg',
				provider: 'morpher',
				chain: 'eth'
			},
			{
				 id: 'mph',
				 symbol: 'MPH ' + i18n.t('ON') + ' Polygon',
				 name: 'Morpher Token',
				 icon: 'mph.svg',
				 provider: 'morpher',
				 chain: 'polygon'
			},
			{
				id: 'usdc',
				symbol: 'USDC ' + i18n.t('ON') + ' Polygon',
				name: 'USD Coin',
				icon: 'usdc.svg',
				provider: 'morpher',
				chain: 'polygon'
			},
			{
				id: 'dai',
				symbol: 'DAI ' + i18n.t('ON') + ' Polygon',
				name: 'Dai',
				icon: 'dai.svg',
				provider: 'morpher',
				chain: 'polygon'
			},	
			{
				id: 'usdt',
				symbol: 'USDT ' + i18n.t('ON') + ' Polygon',
				name: 'Tether',
				icon: 'usdt.svg',
				provider: 'morpher',
				chain: 'polygon'
			},			
			{
				id: 'weth',
				symbol: 'WETH ' + i18n.t('ON') + ' Polygon',
				name: 'WETH',
				icon: 'weth.svg',
				provider: 'morpher',
				chain: 'polygon'
			},						
			{
				id: 'wmatic',
				symbol: 'WMATIC ' + i18n.t('ON') + ' Polygon',
				name: 'Wrapped MATIC',
				icon: 'wmatic.svg',
				provider: 'morpher',
				chain: 'polygon'
			},				
			{
				id: 'wbtc',
				symbol: 'WBTC ' + i18n.t('ON') + ' Polygon',
				name: 'Wrapped Bitcon',
				icon: 'wbtc.svg',
				provider: 'morpher',
				chain: 'polygon'
			},				
			{
				id: 'eth',
				symbol: 'ETH ' + i18n.t('ON') + ' BNB Smartchain',
				name: 'Ethereum',
				icon: 'eth.svg',
				provider: 'morpher',
				chain: 'bnb'
			},				
			{
				id: 'wbnb',
				symbol: 'WBNB ' + i18n.t('ON') + ' BNB Smartchain',
				name: 'Wrapped BNB',
				icon: 'bnb.svg',
				provider: 'morpher',
				chain: 'bnb'
			},
		]
	}

  return currency_list
}

const default_provider: any = {
  GB: {
    currency: 'GBP',
    provider: 'moonpay'
  },
  AT: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  BE: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  BG: {
    currency: 'EUR',
    provider: 'advcash'
  },
  HR: {
    currency: 'EUR',
    provider: 'advcash'
  },
  CY: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  CZ: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  DK: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  EE: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  FI: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  FR: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  DE: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  GR: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  HU: {
    currency: 'EUR',
    provider: 'advcash'
  },
  IE: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  IT: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  LV: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  LT: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  LU: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  MT: {
    currency: 'EUR',
    provider: 'advcash'
  },
  NL: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  PL: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  PT: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  RO: {
    currency: 'EUR',
    provider: 'advcash'
  },
  SK: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  SI: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  ES: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  SE: {
    currency: 'EUR',
    provider: 'moonpay'
  },
  RU: {
    currency: 'USD',
    provider: 'advcash'
  },
  JP: {
    currency: 'USD',
    provider: 'advcash'
  },
  VE: {
    currency: 'USD',
    provider: 'advcash'
  },
  MA: {
    currency: 'USD',
    provider: 'advcash'
  },
  CO: {
    currency: 'USD',
    provider: 'advcash'
  },
  AR: {
    currency: 'USD',
    provider: 'advcash'
  },
  DZ: {
    currency: 'USD',
    provider: 'advcash'
  }
}

const hide_ramp = ['JP', 'VE', 'MA', 'CO', 'AR', 'AZ', 'RU']

const sepa_country = ['AT', 'BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB',]
const show_paypal = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'IE',
  'AL',
  'AD',
  'AM',
  'BA',
  'FO',
  'GE',
  'GI',
  'IS',
  'IM',
  'XK',
  'LI',
  'MK',
  'MD',
  'MC',
  'ME',
  'NO',
  'SM',
  'RS',
  'CH',
  'UA',
  'GB',
  'VA',
  'CA',
  'AU',
  'NZ',
  'KR',
  'JP',
  'IL'
] // ,'TR'

const hide_moonpay = [
  'AF',
  'AL',
  'AI',
  'AW',
  'BD',
  'BB',
  'BY',
  'BM',
  'BO',
  'BW',
  'BF',
  'KH',
  'KY',
  'CF',
  'CN',
  'CD',
  'CK',
  'CI',
  'CU',
  'DM',
  'EC',
  'FJ',
  'GH',
  'GW',
  'HT',
  'HK',
  'IS',
  'IR',
  'IQ',
  'JM',
  'JP',
  'JO',
  'KP',
  'XK',
  'LA',
  'LB',
  'LR',
  'LY',
  'MO',
  'ML',
  'MT',
  'MU',
  'MN',
  'MA',
  'MM',
  'NP',
  'NI',
  'PK',
  'PW',
  'PA',
  'PG',
  'PH',
  'PR',
  'RU',
  'WS',
  'SN',
  'SC',
  'SB',
  'SO',
  'SS',
  'LK',
  'SD',
  'SY',
  'TL',
  'TT',
  'TN',
  'TR',
  'TC',
  'UG',
  'UA',
  'VU',
  'VE',
  'VG',
  'YE',
  'EH',
  'ZW'
]

export { default_provider, hide_ramp, show_paypal, hide_moonpay, sepa_country }
