<template>
	<div class="slider-tablet" :class="{ 'slider-tablet-active': active }">
		<div class="slider-content">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	props: {
		active: Boolean
	}
});
</script>

<style scoped>
.slider-tablet {
	position: fixed;
	width: 100%;
	right: 0;
	top: 62px;
	background-color: white;
	list-style: none;
	z-index: 29;
	overflow: hidden;
	/* Change overflow to hidden */

	transition: top 0.25s ease;
	transform: translate3d(100%, 0, 0);

	/* border-bottom: 1px solid #EAECF0; */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 4px 20px 0px rgba(0, 0, 0, 0.10);

}

.slider-tablet-active {
	transform: translate3d(0, 0, 300px);
}

.slider-content {
	max-height: calc(100vh - 0px);
	/* Set the maximum height of the content */

}
</style>