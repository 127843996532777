<template>
	<div class="formcontainer">
		<div class="headerImage">
			<a href="https://www.morpher.com" target="_blank" alt="Morpher Homepage">
				<img class="image" src="@/assets/auth/morpher-logo.svg" alt="Morpher Logo" />
			</a>
		</div>

		<div v-if="!config.application_live && $route.query && $route.query.adminoverride !== 'true'" class="authBox">
			{{ $t('MAINTANANCE_MODE') }}
		</div>
		<div class="authBox">
			<div v-show="!initialising">
				<!-- Connect Fortmatic -->
				<h2 data-cy="logInTitle" class="authTitle">{{ $t('auth.MORE_LOGIN_OPTIONS') }}</h2>
				<div class="buttonContainer mt-10">
					<button class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loading.fortmatic }" @click="loginFortmatic" data-cy="appleButton">
						<span class="icon img">
							<img src="@/assets/wallet/fortmatic_logo.png" class="buttonIcon" alt="Fortmatic Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_FORTMATIC') }} </span>
					</button>

					<button class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loading.portis }" @click="loginPortis" data-cy="appleButton">
						<span class="icon img">
							<img src="@/assets/wallet/portis_logo.svg" class="buttonIcon second" alt="Portis Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_PORTIS') }} </span>
					</button>

					<!-- 					
					<button class="button big-outlined-button transition-faster button-spacing loginButton mt-15"  :class="{'is-loading':loading.metamask}"
						@click="loginMetamask" data-cy="appleButton">
						<span class="icon img">
							<img src="@/assets/wallet/metamask_logo.svg" class="buttonIcon" alt="Metamask Logo" />
						</span>
						<span class="ml-5px" >{{  $t('auth.LOG_IN_METAMASK') }} </span>
					</button> -->

					<p class="loginLink">
						<router-link to="/login" class="login-router transition-faster"><span>&lt;&nbsp;&nbsp; {{
							$t('auth.BACK') }}</span></router-link>
					</p>
				</div>
			</div>
		</div>

		<!-- Need help logging in -->
		<!-- <div class="loginHelp">
			<p class="loginLink">
				{{ $t('NEED_HELP') }}
				<a
					:href="supportArticleLink('LoginIssues')"
					class="transition-faster"
					target="_blank"
					>{{ $t('auth.VISIT_SUPPORT') }}
				</a>
			</p>
		</div> -->

		<!-- <vue-recaptcha
			ref="recaptcha"
			size="invisible"
			:sitekey="recaptchaSiteKey"
			:load-recaptcha-script="true"
			@verify="onCaptchaVerified"
			@error="onCaptchaError"
			@expired="onCaptchaExpired"
			@render="onCaptchaLoaded"
		/> -->
	</div>
</template>

<script lang="ts">
//vuex
import { ERROR } from '@/store/mutation-types';

//import { getDictionaryValue } from '@/helpers/wallet/dictionary';
import { accessTokenName, refreshTokenName, getUserID } from '@/helpers/utils';
import deviceType from '@/helpers/deviceType';
import { UserService } from '@/services/UserService';
import LoginApple from './LoginApple.vue';
import LoginGoogle from './LoginGoogle.vue';

import * as blockies from 'ethereum-blockies-png';
//import RecaptchaMixin from '@/mixins/recaptcha.mixin';
import AuthMixin from '@/mixins/auth.mixin';
//import VueRecaptcha from 'vue-recaptcha';
import EventBus from '@/store/event-bus';
import { portisConfig } from '@/helpers/portis';
import { fortmaticConfig } from '@/helpers/fortmatic';
import MetaMaskOnboarding from '@metamask/onboarding';
import { mapActions, mapState } from 'pinia';
import { useAdminStore } from '@/store/modules/admin';
import { useUserStore } from '@/store/modules/user';
import { useContractStore } from '@/store/modules/contract';
import { useStatusStore } from '@/store/modules/status';
import { defineComponent } from 'vue';
import type { WalletClient } from 'viem';
import { getWalletClient } from '@/helpers/viem';
import type { TMorpherWalletInit } from '@/types/graphql-global-types';

/* @group Components_Auth */
/*
  <h4> <b>Login Form</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'WalletLoginMore',
	components: { LoginApple, LoginGoogle }, // , 'vue-recaptcha': VueRecaptcha
	mixins: [AuthMixin], // RecaptchaMixin,
	data() {
		return {
			walletEmail: '',
			walletPassword: '',
			walletError: '',
			logonError: '',
			loginUser: {} as any,
			initialising: true,
			loginProcessing: false,
			showRecovery: false,
			walletClientlogin: {} as any,
			morpherWalletInit: {} as any,
			loading: {
				fortmatic: false,
				portis: false,
				metamask: false,
				morpherwallet: false
			},
			loaded: {
				fortmatic: false,
				fortmaticInterval: 0 as null | NodeJS.Timeout | number,
				fortmaticLoggedIn: false,
				morpherWalletLoggedIn: false
			},
			default_wallet: '',
			showMetamaskHelp: false,
			airdrop_id: '',
			referred_by: '',
			custom_invite: '',
			morpherwallet: null as any,
			isMetamaskSupported: false,
			wallet_type: '',
			walletClientPortis: {} as any,
			portis: {} as any,
			email: '',
			eth_address: '',
			blockie: '',
			walletClientFortmatic: {} as any,
			fortmatic: {} as any,
			name: '',
			active_eth_address: '',
		};
	},
	computed: {
		...mapState(useAdminStore, {
			config: state => state.config,
		}),
		...mapState(useUserStore, {
			user: state => state.data,
		}),
		...mapState(useContractStore, {
			contract: state => state as any,
		})
	},
	watch: {
		walletError(newValue) {
			if (newValue) {
				// If there is a logon error then log it to sentry
				this.logSentryError('Logon Error:' + newValue);
				this.error({ message: 'WALLET_CONNECT_ERROR' });
			}
		}
	},
	async mounted() {
		// Fill emailOrUsernameStorage from url 'account' query parameter
		if (this.$route.query.account !== undefined) {
			localStorage.setItem('emailOrUsernameStorage', this.$route.query.account)
		}

		if (localStorage.getItem('walletType')) {
			this.default_wallet = localStorage.getItem('walletType') || '';
		}

		// browsers that support metamask
		const browserMetamask = ['chrome', 'brave', 'opera', 'yandex', 'firefox', 'edge'];

		// return only the first word od the browser name
		const browser = navigator.sayswho.replace(/ .*/, '').toLowerCase();

		if (browserMetamask.includes(browser) || deviceType.isAnyMobile()) {
			this.isMetamaskSupported = true;
			this.showMetamaskHelp = false;
		}

		this.airdrop_id = this.$route.query.airdrop_id ? this.$route.query.airdrop_id : null;
		this.referred_by = this.$route.query.referred_by ? this.$route.query.referred_by : null;
		this.custom_invite = this.$route.query.custom_invite ? this.$route.query.custom_invite : null;

		if (this.referred_by) localStorage.setItem('airdrop_referred_by', this.referred_by);
		else this.referred_by = localStorage.getItem('airdrop_referred_by') || '';

		if (this.custom_invite) localStorage.setItem('airdrop_custom_invite', this.custom_invite);
		else this.custom_invite = localStorage.getItem('airdrop_custom_invite') || '';

		this.initialising = false;
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
		}),
		...mapActions(useUserStore, {
			loginWallet: 'loginWallet',
		}),
		...mapActions(useContractStore, {
			setWalletClient: 'setWalletClient',
			setMorpherWalletProvider: 'setMorpherWalletProvider',
		}),
		async clearStorage() {
			localStorage.removeItem(accessTokenName);
			localStorage.removeItem(refreshTokenName);
			localStorage.removeItem('emailOrUsernameStorage');
			localStorage.removeItem('morpher-role');
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('tokenStorage');
			localStorage.removeItem('eth_address');
		},

		async recaptchaError() {
			//this.loginProcessing = false;
		},
		async reloadPage(fortmaticNoAccountFound = false) {
			// remove all old cached items
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			if (fortmaticNoAccountFound) return;

			// reload the page if the previous reload was more than 1 hour ago (to avoid refresh loop)
			const reloadDate = localStorage.getItem('reloadDateLogin');
			const timestamp = Date.now();

			if (!reloadDate || Number(reloadDate) < timestamp - 3600000) {
				localStorage.setItem('reloadDateLogin', String(timestamp));
				window.location.reload();
			}
		},


		/*
		 * Connect to morpher wallet and log on the user using the wallet address
		 */
		/*
		 * Connect to portis and log on the user using the portis wallet address
		 */
		async loginPortis() {
			try {
				this.walletError = '';
				this.wallet_type = 'portis';
				this.loading.portis = true;

				// configure portis access
				if (!this.walletClientPortis) {
					const result = await portisConfig({});
					this.portis = result.portis;
					this.walletClientPortis = result.walletClient;
				}
				this.setWalletClient(this.walletClientPortis);

				// Callback function for when portis is logged on
				this.portis.onLogin(async (eth_address: any, email: any, reputation: any) => {
					this.loading.portis = false;

					const user_id = await getUserID(eth_address, 'portis');

					if (email) this.email = email;


					if (eth_address) {
						this.eth_address = eth_address;
						this.blockie = blockies.createDataURL({
							seed: eth_address.toLowerCase()
						});
					}

					this.clearStorage();
					localStorage.setItem('walletType', 'portis');

					// Log the user into the system
					const result = await this.loginWallet({
						variables: {
							eth_address,
							wallet_type: 'portis',
							user_id,
							hash: ''
						},
						isTokenLogin: false,
						email,
						walletClient: this.walletClientPortis
					});

					if (result && result.error) {
						let accountNotFound = false;

						if (
							result.error.graphQLErrors &&
							result.error.graphQLErrors[0] &&
							result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
						) {
							accountNotFound = true;
						}

						if (accountNotFound) {
							return;
						}

						setTimeout(() => {
							this.reloadPage();
						}, 2000);
					}

					// Display the email address updated notification if the email address was updated
					if (result && result.old_address) {
						this.$buefy.dialog.alert({
							title: this.$t('auth.EMAIL_UPDATED'),
							message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
								newEmail: result.email,
								oldEmail: result.old_address
							}),
							confirmText: this.$t('OK')
						});
					}

					// if the user is not registered then register the user
					if (!result) {
						this.loading.portis = false;
						this.portis.showPortis();
					}
					// store the email for defaulting next tme the user logs in
					if (result) localStorage.setItem('emailOrUsernameStorage', email)
					this.loading.portis = false;
				});

				this.portis.setDefaultEmail(localStorage.getItem('emailOrUsernameStorage'));

				let client: WalletClient = this.walletClientPortis
				await client.getAddresses();

				// check if the user is logged in
				await this.portis.isLoggedIn().then((loginResult: any) => {
					// set the login state

					if (loginResult.error) {
						this.loading.portis = false;
						this.walletError = this.$t('auth.PORTIS_ERROR') + ' ' + loginResult.error.message;
					} else {
						if (!loginResult.result) {
							this.loading.portis = false;
							this.walletError = this.$t('auth.PORTIS_ERROR');
						}
					}
				});
			} catch (err: any) {
				this.walletError = err.message;
				this.loading.portis = false;
			}
		},
		/**
		 * Wait for fortmatic to load before continuing with logon
		 */
		async awaitFortmaticLoad() {
			if (this.loaded.fortmatic) {
				if (this.loaded.fortmaticInterval)
					clearInterval(this.loaded.fortmaticInterval);
				this.loaded.fortmaticInterval = 0;
				this.loginFortmatic();
			}
		},
		/**
		 * Preload the fortmatic interface to speed up logon
		 */
		async loadFortmatic() {
			this.walletError = '';


			// configure portis access
			const result = await fortmaticConfig();
			this.fortmatic = result.fortmatic;
			this.walletClientFortmatic = result.walletClient;
			this.loaded.fortmaticLoggedIn = await this.fortmatic.user.isLoggedIn();
			this.loaded.fortmatic = true;
		},

		/*
		 * Connect to fortmatic
		 */
		async loginFortmatic() {
			this.walletError = '';
			this.wallet_type = 'fortmatic';

			try {
				this.setWalletClient(this.walletClientFortmatic);
				this.loading.fortmatic = true;
				if (!this.loaded.fortmatic) {
					this.loadFortmatic();
					if (this.loaded.fortmaticInterval) {
						clearInterval(this.loaded.fortmaticInterval);
					}
					this.loaded.fortmaticInterval = setInterval(this.awaitFortmaticLoad, 200);
					return;
				}

				// log the user if if they are not already logged in
				if (!this.loaded.fortmaticLoggedIn) {
					await this.fortmatic.user.login();
					this.loaded.fortmaticLoggedIn = true;
				}

				// get the account info from viem
				try {
					let client: WalletClient = this.walletClientFortmatic
					client.getAddresses().then(async (accounts: any) => {
						if (accounts && accounts.length > 0) {
							const data = await this.fortmatic.user.getUser();

							const user_id = data.userId;

							this.name = data.userId;
							this.email = data.email;

							if (accounts[0]) {
								this.eth_address = accounts[0];
								this.blockie = blockies.createDataURL({
									seed: accounts[0].toLowerCase()
								});
							}

							this.clearStorage();
							localStorage.setItem('walletType', 'fortmatic');

							// Log the user into the system
							const result = await this.loginWallet({
								variables: {
									eth_address: accounts[0],
									wallet_type: 'fortmatic',
									user_id,
									hash: ''
								},
								isTokenLogin: false,
								email: this.email,
								walletClient: this.walletClientFortmatic
							});

							if (result && result.error) {
								await this.fortmatic.user.logout();
								let accountNotFound = false;

								if (
									result.error.graphQLErrors &&
									result.error.graphQLErrors[0] &&
									result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
								) {
									accountNotFound = true;
								}

								if (accountNotFound) {
									const userData = await UserService.getUserData({ email: data.email });

									if (userData && userData.data.getUserData) {
										const user = userData.data.getUserData;

										if (user && user.wallet_type && user.wallet_type !== 'fortmatic') {
											this.fortmatic.user.logout();
											this.loading.fortmatic = false;
											this.wallet_type = user.wallet_type;
											return;
										}

										if (
											user &&
											user.eth_address &&
											accounts[0] &&
											user.eth_address.toLowerCase() !== accounts[0].substring(0, 6).toLowerCase()
										) {
											this.loading.fortmatic = false;
											this.wallet_type = 'fortmatic';
											this.active_eth_address = user.eth_address.toLowerCase();
											return;
										}
									}

									return;
								}

								setTimeout(() => {
									// Refresh cache and clear storage
									// Handle all the errors as previous except LOGIN_WRONG_CREDENTIALS
									this.reloadPage(accountNotFound);

									// Refresh page if user do no go to sign up after 3 seconds
									// Wait 3 seconds 2000 + 1000 for user to do the action
									// If no route has been changed, page will restart
									// Otherwise, page is switched to sign up and timeout will be cleared
									if (accountNotFound) {
										setTimeout(() => {
											window.location.reload();
										}, 1000);
									}
								}, 2000);
							}

							// Display the email address updated notification if the email address was updated
							if (result && result.old_address) {
								this.$buefy.dialog.alert({
									title: this.$t('auth.EMAIL_UPDATED'),
									message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
										newEmail: result.email,
										oldEmail: result.old_address
									}),
									confirmText: this.$t('OK')
								});
							}

							if (!result) {
								this.loading.fortmatic = false;
								// if the user was not logged in to the system then log them out of fortmatic
								await this.fortmatic.user.logout();
								// if the user is not registered then register the user
							}
							this.loading.fortmatic = false;
						} else {
							this.loading.fortmatic = false;
							this.walletError = this.$t('auth.FORTMATIC_NO_ACCOUNT');
						}
					})

				} catch (err: any) {
					this.loading.fortmatic = false;
					this.walletError = this.$t('auth.FORTMATIC_ERROR') + ' ' + err.message;
				}
			} catch (err: any) {
				this.walletError = err.message;
				this.loading.fortmatic = false;
			}
		},
		async disconnectWallet(goToLogin = false) {
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			this.clearStorage();

			if (this.fortmatic && this.fortmatic.user) this.fortmatic.user.logout();
			if (this.morpherwallet && this.morpherwallet.logout) this.morpherwallet.logout();

			if (goToLogin) this.$router.push('/login').catch(() => { });

			window.location.reload();
		},
		generateWalletName(wallet_type: string) {
			let name = '';

			if (wallet_type === 'metamask') {
				name = 'Metamask';
			} else if (wallet_type === 'portis') {
				name = 'Portis';
			} else if (wallet_type === 'fortmatic') {
				name = 'Fortmatic';
			} else if (wallet_type === 'morpherwallet') {
				name = 'Morpher Wallet';
			}

			return name;
		},
		executeSignatureTimeout() {
			this.$buefy.toast.open({
				duration: 10000,
				message: this.$t('auth.METAMAS_SIGNATURE_ERROR'),
				type: 'is-warning'
			});
		},

		/*
		 * Connect to metamask and log on the user using the metamask wallet address
		 */
		async loginMetamask() {
			this.walletError = '';
			this.wallet_type = 'metamask';
			try {
				EventBus.$off('signatureTimeout', this.executeSignatureTimeout);
				EventBus.$on('signatureTimeout', this.executeSignatureTimeout);

				const onboarding = new MetaMaskOnboarding();

				if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
					if (deviceType.isAnyMobile()) {
						if (import.meta.env.VITE_METAMASK_DEEP_LINK) window.location.href = import.meta.env.VITE_METAMASK_DEEP_LINK;
						return;
					}

					onboarding.startOnboarding();

					this.showMetamaskHelp = true;
				} else {
					this.loading.metamask = true;

					let eth_address = '';

					let walletClientMetamask: any
					// Get the users Ethereum address
					if (window.ethereum) {
						window.ethereum.autoRefreshOnNetworkChange = false;
						const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });

						walletClientMetamask = getWalletClient(window.ethereum);
						this.setWalletClient(walletClientMetamask);

						eth_address = addresses[0] ? addresses[0].toLowerCase() : '';

						if (eth_address) {
							this.eth_address = eth_address;
							this.blockie = blockies.createDataURL({
								seed: eth_address.toLowerCase()
							});
						}
					}

					const user_id = await getUserID(eth_address, 'metamask');

					this.clearStorage();
					localStorage.setItem('walletType', 'metamask');

					const result = await this.loginWallet({
						variables: {
							eth_address,
							wallet_type: 'metamask',
							user_id,
							hash: ''
						},
						isTokenLogin: false,
						email: '',
						walletClient: walletClientMetamask
					});

					if (result && result.error) {
						let accountNotFound = false;

						if (
							result.error.graphQLErrors &&
							result.error.graphQLErrors[0] &&
							result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
						) {
							accountNotFound = true;
						}

						if (accountNotFound) {
							return;
						}

						setTimeout(() => {
							this.reloadPage();
						}, 2000);
					}

					// Display the email address updated notification if the email address was updated
					if (result && result.old_address) {
						this.$buefy.dialog.alert({
							title: this.$t('auth.EMAIL_UPDATED'),
							message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
								newEmail: result.email,
								oldEmail: result.old_address
							}),
							confirmText: this.$t('OK')
						});
					}

					// if the user is not registered then register the user
					if (!result) {
						this.loading.metamask = false;
					}
					// store the email for defaulting next tme the user logs in
					if (result) localStorage.setItem('emailOrUsernameStorage', result.email);
					this.loading.metamask = false;
				}
			} catch (err: any) {
				console.log(err);
				this.walletError = err.message;
				this.loading.metamask = false;
			}
		},

		// Submits login request
		async formAction() { }
	}
});
</script>
<style scoped>
.button-img-primary {
	height: 28px;
	padding-right: 15px;
}

.button-img-alt {
	position: absolute;
	right: calc(50% + 70px);
}

.alt-button {
	padding-left: 46px;
}

.login-help {
	margin-top: -0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.settings-link {
	line-height: 1rem;
	cursor: default;
}

@media only screen and (max-width: 400px) {
	.button-img-alt {
		display: none;
	}

	.alt-button {
		padding-left: initial;
	}
}

.spaced {
	margin-right: 7px;
}

.authBox {
	margin-top: 0px;
}

.formcontainer {
	width: 360px;
	margin: auto auto;
	text-align: center;
}

.headerImage img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.authTitle {
	font-size: 28px;
	font-weight: 700;
	line-height: 36px;
	margin-top: 25px;
	color: #333333;
}

.headerImage {
	width: 100%;
}

.authText {
	font-weight: 400;
	color: #666666;
	margin-top: 12px;
	font-size: 16px;
}

.label {
	margin-top: 20px;
	margin-bottom: 2px;
	text-align: left;
	color: #333333;
	font-weight: 500;
	font-size: 16px;
}

.input {
	background-color: #fff;
	border-radius: 8px !important;
	border-color: #d0d5dd !important;
}

.input::placeholder {
	color: #666666;
}





.loginDivider {
	height: 1px;
	width: 100%;
	margin: 20px 0;
	border-top: 1px solid #eae9ed;
}

.loginHelp {
	height: calc(100vh - 800px);
}


.buttonIcon.second {
	width: 14px;
}

@media only screen and (max-width: 768px) {
	.formcontainer {
		margin: 0 auto;
		height: 100%;
	}
}
</style>
