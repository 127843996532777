/**
 * Payment Processing Shared logic
 * -
 */
 import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { useFundsStore, type FundsState } from '@/store/modules/funds';
import { TransactionService } from '../services/TransactionService';
 import { copyToClipboard } from '../helpers/utils';
 import { ERROR, MARKETS } from '@/store/mutation-types';
 //vuex
 import EventBus  from '@/store/event-bus';
 import { getCurrencyList , default_provider} from '../helpers/funding';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			order_id: '',
			purchase_price: '',
			purchase_cost: '',
            purchase_fees: '',
			purchase_total: '',
			purchase_amount_mph: '',
			transaction_id: '',
			purchase_loading: false,
			process_status: 'new',
			transaction_data: null as any,
			number_seperators: {} as {
                decimal: string,
                thousand: string
            },
		};
	},
	computed: {
		...mapState(useContractStore, {
		    balance: (state) => state.balance,
		}),
		...mapState(useUserStore, {
		    user: (state) => state.data,
		    deposit_address: (state) => state.deposit_address,
			deposit_reference: (state) => state.deposit_reference,
		}),
		...mapState(useFundsStore, {
		    funds: (state) => state,
		}),


	},
	beforeUnmount() {

		EventBus.$off('payment_update', this.paymentUpdate);

	},
	async mounted() {
		EventBus.$off('payment_update', this.paymentUpdate);
		EventBus.$on('payment_update', this.paymentUpdate);
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
		}),
		...mapActions(useFundsStore, {
		    openFunds: 'openFunds',
		    updateFunds: 'updateFunds',
		    resetFunds: 'resetFunds',
		}),
		...mapActions(useUserStore, {
		    loadDepositAddress: 'loadDepositAddress',
			loadDepositReference: 'loadDepositReference',
		    createDepositAddress: 'createDepositAddress',
		    updateUserPayload: 'updateUserPayload',
		}),
		...mapActions(useModalsStore, {
		    toggleDepositWithdrawModal: 'toggleDepositWithdrawModal',
		}),
		...mapActions(useContractStore, {
		    setBalance: 'setBalance',
		}),

		paymentUpdate(data: any)  {

			if (data && data.provider == this.funds.provider && data.status == 'requested' && (data.provider == 'morpher' || data.provider == 'moonpay')) {
				this.process_status = 'requested'
				if (data.transaction_id) {
					this.getTransactionInfo(data.transaction_id)
				}
			}

			if (data && data.provider == this.funds.provider && data.status == 'processing') {
				this.process_status = 'processing'
				if (data.transaction_id) {
					this.getTransactionInfo(data.transaction_id)
				}
			}

			if (data && data.provider == this.funds.provider && (data.status == 'error' || data.status == 'failed')) {
				this.process_status = 'error'
				if (data.transaction_id) {
					this.getTransactionInfo(data.transaction_id)
				}
			}

			if (data && data.provider == this.funds.provider  && data.status == 'success') {
				this.process_status = 'success'
				if (data.transaction_id) {
					this.getTransactionInfo(data.transaction_id)
				}
			}

		},

		cancelPurchase() {
			this.$router.push('/funds').catch(() => {});
			this.updateFunds({ showPaymentType: null, step: null } as FundsState);
			this.toggleDepositWithdrawModal(false);
		},
		async getTransactionInfo(transaction_id: string) {
			if (!transaction_id) {
				return;
			}
			const return_data = await TransactionService.getTransaction({ eth_address: this.user?.activePortfolio?.eth_address, transaction_id })
			if (return_data && return_data.data && return_data.data.getTransaction) {
				this.transaction_data = return_data.data.getTransaction
			} else {
				this.transaction_data = null;
			}
			
		},
		copyAddress() {
			copyToClipboard(this.deposit_address.deposit_eth_address, this);
		},
		currencySelector() {
			this.updateFunds({
				type:'deposit',
				showPaymentMethod: false,
				step: null,
				showPaymentType: null,
				withdraw_amount: 0,
				showWithdrawAddress: false,
				showWithdrawMethod: false,
				withdraw_page: '',
				withdraw_account: '',
				withdraw_account_name: '',
				currency: '',
				source: ''
			} as FundsState)
		},
		closePayment() {

			if (this.funds.provider === 'morpher') {
				const country = ((this.user ? this.user.document_country : '') || '').toUpperCase();

				let currency = 'USD';
				if (default_provider[country]) {
					currency = default_provider[country]['currency'];
				}	

				if (currency === "" || currency === "USD"  || currency ===  "EUR"  || currency ===  "GBP")
				this.updateFunds({showPaymentType: null, provider: this.funds.default_provider, currency: currency, step: null} as FundsState)
			} else {
				this.updateFunds({showPaymentType: null, step: null}as FundsState)
			}
			
		},
		showPurchaseAgreement() {
			this.updateFunds({ showPurchaseAgreement: true }as FundsState);
		},
		showPaymentMethod() {
			this.updateFunds({ showPaymentMethod: true }as FundsState);
		},
		getCurrencySymbol(source_currency: string) {
			if (!source_currency) {
				return '$';
			}
			source_currency = source_currency.toLowerCase();
			if (source_currency == 'usd') {
				return '$';
			}
			if (source_currency == 'eur') {
				return '€';
			}
			if (source_currency == 'gbp') {
				return '£';
			}

			if (source_currency == 'rub') {
				return '₽';
			}
			
			return source_currency;
		},

		formatAmount(deposit_amount: number) {
			if (!this.number_seperators) {
				this.getNumberSeparators();
			}
			let number_unformatted = String(deposit_amount || '0');


			const regex = new RegExp("[^0-9" + + this.number_seperators.thousand + this.number_seperators.decimal + "]", "g")
			
			number_unformatted = number_unformatted.replace(regex, '')

			if (this.number_seperators.thousand) {
				var re = new RegExp('[' + this.number_seperators.thousand + ']', 'g');
                number_unformatted = String(number_unformatted).replace(re, '');
			}


			const decimal_split = number_unformatted.split(this.number_seperators.decimal)

			let after_decimal = null;
			let before_decimal = null;

			if (decimal_split.length > 2) {

				after_decimal = decimal_split[decimal_split.length - 1]
				decimal_split.pop();
				before_decimal = decimal_split.join('');

			} else if (decimal_split.length == 2) {
				before_decimal = decimal_split[0]
				after_decimal  = decimal_split[1]
			} else {
				before_decimal = number_unformatted;
			}

			if (after_decimal && this.funds.provider !== 'coinbase' && after_decimal.length > 2) {
				after_decimal = after_decimal.substring(0,2)
			}

			return parseFloat(before_decimal).toLocaleString() + (after_decimal == null ? '' : this.number_seperators.decimal + after_decimal);
		},
		getNumberSeparators() {
			
			// default
			const res = {
				"decimal": ".",
				"thousand": ""
			};
			
			// convert a number formatted according to locale
			const str = parseFloat('1234.56').toLocaleString();
			
			// if the resulting number does not contain previous number
			// (i.e. in some Arabic formats), return defaults
			if (!str.match("1"))
				return res;
			
			// get decimal and thousand separators
			res.decimal = str.replace(/.*4(.*)5.*/, "$1");
			res.thousand = str.replace(/.*1(.*)2.*/, "$1");
			
			// return results
			this.number_seperators = res;
		}
	}
});
