<template>
	<div class="spark-line-control" v-if="!colorLoading">

		<Sparklines :indicator-styles="spIndicatorStyles1" :width="width" :styles="{
				stroke: color,
				strokeWidth: '2px'
			}"
		 :height="40">
			<SparklineLine :data="chart_data" :limit="chart_data.length"  :styles="{
				stroke: color,
				strokeWidth: '2px'
			}" :width="width"
				:height="40" />
		</Sparklines>
	</div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { Sparklines, SparklineLine } from 'vue-sparklines';

/* @group Components_Chart */
/*
 <h4> <b>Spark Line Chart Component</b> </h4>
  <br> - Receives sparklinedata and char color as prop
  <br> - chart generated using live data retreived from redis
  <br>
  <br> Contains:
  <br> vue-sparklines control
  <br>
*/
export default defineComponent({
	name: 'ChartSparkLine',

	components: {
		Sparklines, SparklineLine
	},

	props: {
		// Used to preload content
		isLoading: {
			type: Boolean,
			required: false,
			default: true
		},
		// sparklinedata object from redis collection
		sparkLineData: {
			type: Object as PropType<any>,
			required: true,
			default: () => {
				return {
					minutely: []
				};
			}
		},
		color: {
			type: String,
			required: true,
			default: 'green'
		},
		isMph: {
			type: Boolean,
			required: false,
			default: false
		},
		width: {
			type: Number,
			required: false
		},
		height: {
			type: Number,
			required: false
		}
	},
	data() {
		return {
			colorLoading: false,
			chart_data: [],
			spIndicatorStyles1: false,
			sparkLineStyle: {
				stroke: this.color,
				strokeWidth: '2px'
			},
			sparkRefLineType: 'custom',
			sparkRefLineStyle: {
				stroke: this.color,
				strokeOpacity: 1,
				strokeDasharray: '2, 2'
			},
			sparkRefLineProp: {
				value: 1
			}
		};
	},
	watch: {
		async sparkLineData(val) {
			// reload the garph data
			if (this.sparkLineData) await this.initialize();
		},
		color(val) {
			this.sparkLineStyle.stroke = val;
			this.sparkRefLineStyle.stroke = val;

			this.colorLoading = true;
			setTimeout(() => {
				this.colorLoading = false
			}, 1000);
		}
	},
	async created() {
		if (this.sparkLineData) await this.initialize();
	},
	methods: {
		// @vuese
		// Initialize spark line chart - set chart data to minutelyClose data
		async initialize() {
			// Refresh the data if the sparkLineData has changed
			if (this.sparkLineData && this.sparkLineData.minutelyClose && this.sparkLineData.minutelyClose.length > 0) {
				this.chart_data = this.sparkLineData.minutelyClose;
				// calculate the reference line position (the sparkline control does not support setting it automatically)
				if (this.sparkLineData.max !== this.chart_data[0]) {
					const Point1 = this.sparkLineData.max - this.sparkLineData.min;
					const Point2 = this.sparkLineData.max - this.chart_data[0];
					const position = (Point2 / Point1) * 28;
					this.sparkRefLineProp.value = position;
				} else {
					this.sparkRefLineProp.value = 1;
				}
			}
		}
	}
});
</script>

<style scoped>
.spark-line-control {}
</style>
