<template>
	<div class="has-font-manrope">
		<div class="deposit-modal-spacing has-text-left">

			<div v-if="process_status === 'qr'" class="header-bar-global">
				{{ $t('funds.SEPA_QR_HEAD') }}
				<button class="button back-btn" @click="process_status = 'new'">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
			</div>
			<div v-else-if="process_status === 'processing'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_PROCESSING') }}
			</div>
			<div v-else-if="process_status === 'success'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_SUCCESS') }}
			</div>
			<div v-else-if="process_status === 'error'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_ERROR') }}
			</div>
			<div v-else-if="process_status === 'requested'" class="header-bar-global">
				{{ $t('funds.SEPA_DEPOSIT_REQUESTED') }}
			</div>

			<div v-else class="header-bar-global">
				<button class="button back-btn" @click="closePayment()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.BANK_DEPOSIT') }}
			</div>

			<div v-if="depositDisabled">
				Due to the Ethereum Merge, crypto deposits are temporarily disabled.
			</div>



			<!-- payment requested - after submit to blockchain -->
			<div v-else-if="process_status === 'requested'" class="payment-flow_box">


				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/sepa_tx.svg" alt="Bank icon" />
				</div>

				<div class="payment_provider_section">
					<div >
						<img src="@/assets/funds/check-icon.svg" class="checkmark" />
					</div>

					<div >
						<div class="provider_header" v-if="transaction_data && transaction_data.payload">
							{{ transaction_data.payload.purchase_crypto_currency.toUpperCase() }}
						</div>
						<div class="sepa_header" v-else>
							{{ $t('funds.BANK_DEPOSIT') }}
						</div>
						<p class="sepa_text" v-html="$t('funds.SEPA_TRANSACTION_RECEIVED')"></p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment processing - after crypto deposit completed - waiting for deposit -->
			<div v-else-if="process_status === 'processing'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/processing.svg" alt="pending icon" />
				</div>

				<div class="payment_provider_section">
					<div v-if="transaction_data && transaction_data.payload">
						<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')
							" class="transaction_icon" />
					</div>

					<div class="provider_text">
						<div class="provider_header" v-if="transaction_data && transaction_data.payload">
							{{ transaction_data.payload.purchase_crypto_currency.toUpperCase() }}
						</div>
						<div class="provider_header" v-else>
							{{ $t('funds.MORPHER') }}
						</div>
						<p class="is-size-14">{{ $t('funds.TRANSACTION_PROCESSING') }} {{ $t('funds.CLOSE_WINDOW') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<div v-else-if="process_status === 'qr'" class="payment-flow_box">
				<div v-if="qr" class="has-text-centered">
					<img :src="qr">
				</div>

				<div class="details">
					{{ $t('funds.QR_DETAILS') }}
				</div>


				<button class="button flat-primary-btn xlarge is-fullwidth mt-20" @click="createOrder()"
					:class="{ 'is-loading': orderExecuting }">
					{{ $t('funds.SEPA_SENT') }}
				</button>
			</div>


			<!-- payment success - after deposit completed -->
			<div v-else-if="process_status === 'success'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/success.svg" alt="Success checkmark image" />
				</div>

				<div class="transaction_details_section" v-if="transaction_data && transaction_data.payload">
					<div class="transaction_header">
						<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')
							" class="transaction_icon" />
						{{ roundFormatter(transaction_data.payload.purchase_total) }}
						{{ transaction_data.payload.purchase_crypto_currency }}
					</div>

					<div class="transaction_details_lines">
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MPH_CONVERSION') }}
							</p>
							<p>{{ roundFormatter(transaction_data.payload.purchase_amount_mph) }} MPH</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MORPHER_FEES') }}
							</p>
							<p>
								{{ transaction_data.payload.purchase_fees == 0 ? '✨' : ''
								}}{{ formatAmount(transaction_data.payload.purchase_fees) }}
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.TOTAL_VALUE') }}
							</p>
							<p>${{ formatAmount(transaction_data.payload.purchase_total_usd) }}</p>
						</div>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment error -->
			<div v-else-if="process_status === 'error'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/error.svg" alt="payment error" />
				</div>

				<div class="payment_provider_section">
					<div class="provider_text">
						<p class="is-size-14">
							{{ $t('funds.ERROR_TEXT') }}
							{{ $t('funds.ERROR_TRY_AGAIN') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.LEARN_MORE_DEPOSITS') }}
							<a target="_blank" :href="supportArticleLink('DepositMethod')">{{
								$t('funds.HERE')
							}}</a>.
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="closePayment()">
					{{ $t('BACK') }}
				</button>
			</div>

			<!-- new crypto payment  -->
			<div v-else-if="deposit_reference">

				<div class="deposit-sub-head">
					{{ $t('funds.SEPA_DEPOSIT_HEADING') }}
				</div>

				<div class="deposit-text">
					{{ $t('funds.SEPA_DEPOSIT_TEXT') }}
				</div>

				<div class="">




					<div class="deposit-details" v-if="deposit_reference">

						<p class="deposit-headers has-text-darkgrey">
							{{ $t('funds.REFERENCE') }}
						</p>
						<div class="deposit-data">

							<input :value="deposit_reference.deposit_reference" readonly />
							<button class="copy-button" @click="copyData(deposit_reference.deposit_reference)">
								<img src="@/assets/funds/copy.svg" class="copy-icon" />
							</button>
						</div>


					</div>
					<p class="provider-tag mt-2">{{ $t('funds.REFERENCE_INCLUDE') }}</p>

					<div class="deposit-details" v-if="deposit_reference">
						<p class="deposit-headers has-text-darkgrey">
							{{ $t('funds.IBAN') }}
						</p>
						<div class="deposit-data">

							<input :value="deposit_reference.iban" readonly />
							<button class="copy-button" @click="copyData(deposit_reference.iban)">
								<img src="@/assets/funds/copy.svg" class="copy-icon" />
							</button>
						</div>
					</div>

					<div class="deposit-details" v-if="deposit_reference">
						<p class="deposit-headers has-text-darkgrey">
							{{ $t('funds.BIC') }}
						</p>
						<div class="deposit-data">

							<input :value="deposit_reference.bic" readonly />
							<button class="copy-button" @click="copyData(deposit_reference.bic)">
								<img src="@/assets/funds/copy.svg" class="copy-icon" />
							</button>
						</div>
					</div>

					<div class="deposit-details" v-if="deposit_reference">
						<p class="deposit-headers has-text-darkgrey">
							{{ $t('funds.ACCOUNT_NAME') }}
						</p>
						<div class="deposit-data">

							<input :value="deposit_reference.account_name" readonly />
							<button class="copy-button" @click="copyData(deposit_reference.account_name)">
								<img src="@/assets/funds/copy.svg" class="copy-icon" />
							</button>
						</div>
					</div>

					<div class="deposit-details" v-if="deposit_reference">
						<p class="deposit-headers has-text-darkgrey">
							{{ $t('funds.BANK_ADDRESS') }}
						</p>
						<div class="deposit-data">

							<input :value="deposit_reference.bank_address" readonly />
							<button class="copy-button" @click="copyData(deposit_reference.bank_address)">
								<img src="@/assets/funds/copy.svg" class="copy-icon" />
							</button>
						</div>
					</div>


					<div class="deposit-details" v-if="deposit_reference">
						<p class="deposit-headers has-text-darkgrey">
							{{ $t('funds.BANK_NAME') }}
						</p>
						<div class="deposit-data">

							<input :value="deposit_reference.bank_name" readonly />
							<button class="copy-button" @click="copyData(deposit_reference.bank_name)">
								<img src="@/assets/funds/copy.svg" class="copy-icon" />
							</button>
						</div>
					</div>

				</div>

				<div class="fee-section">


					<div class="fee-item seperator">
						<p class="head">{{ $t('FEE') }}</p>
						<p class="amount">€0.00</p>
					</div>

					<div class="fee-item seperator">
						<p class="head">{{ $t('funds.MIN_DEPOSIT') }}</p>
						<p class="amount">€1.00</p>
					</div>


					<div class="fee-item">
						<p class="head">{{ $t('funds.MAX_DEPOSIT') }}</p>
						<p class="amount">€{{ usdFormatter(maxLeft) }}</p>
					</div>




				</div>

				<div class="details mt-16">
					{{ $t('funds.SEPA_DETAILS') }}
				</div>

				<button class="button flat-white-btn xlarge is-fullwidth mt-20" @click="process_status = 'qr'">
					<img src="@/assets/funds/qr.svg" class="mr-5px" />
					{{ $t('funds.SCAN_QR') }}
				</button>

				<button class="button flat-primary-btn xlarge is-fullwidth mt-20" @click="createOrder()"
					:class="{ 'is-loading': orderExecuting }">
					{{ $t('funds.SEPA_SENT') }}
				</button>
				<!-- <div class="deposit_section no_shadow consent_section mt-15">
					<p>
						{{ $t('funds.CONSENT_SEPA') }}
						<a class="transition-faster" @click="showPurchaseAgreement()">{{
							$t('funds.TOKEN_PURCHASE_AGREEMENT') }}</a>
					</p>
				</div> -->
			</div>
			<div v-else>
				{{ $t('funds.SEPA_DEPOSIT_LOADING') }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import PaymentMixin from '@/mixins/payment.mixin';
import { TransactionService } from '@/services/TransactionService';
import { getCurrencyList } from '@/helpers/funding';
import { UserService } from '@/services/UserService';
import { copyToClipboard } from '@/helpers/utils';
import { SEPAQRGenerator, type SEPAData } from "sepa-qr-code";

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'PaymentTypeSEPA',
	mixins: [PaymentMixin],
	components: {
		VueQrcode
	},
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		maxLeft: {
			type: Number,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			depositDisabled: false,
			qr: '',
			orderExecuting: false

		};
	},
	computed: {
		usdAmountFormatted() {
			if (this.funds.amount) {
				return this.usdFormatter(this.funds.amount)
			} else {
				return this.usdFormatter(100)
			}
		},
		currency() {
			if (!this.funds.chain || !this.funds.currency) {
				return {};
			}



			return {};
		}
	},
	watch: {
		deposit_reference(nv) {
			this.generateQR();
		},
		amount(nv) {
			this.generateQR();
		},
	},

	async mounted() {
		this.process_status = 'new';

		await this.loadDepositReference();


		UserService.sendAnalytics({
			event: 'begin_checkout',
			data: {
				currency: this.funds.currency,
				network: this.funds.chain,
				transaction_id: '',
				value: 0,
				provider: 'morpher',
			}
		});

		this.generateQR();


	},
	methods: {
		copyData(data: string) {
			copyToClipboard(data, this);
		},
		async createOrder() {

			this.orderExecuting = true
			const result = await TransactionService.purchaseSEPAOrderRequest({
				data: {
					eth_address: this.user?.activePortfolio?.eth_address || '',
					amount: this.usdAmountFormatted
				}
			});

			this.process_status = 'requested'
			this.orderExecuting = false

		},
		generateQR() {
			if (!this.deposit_reference || !this.deposit_reference.deposit_reference || !this.deposit_reference.account_name) {
				this.qr = '';
				return
			}
			const generator = new SEPAQRGenerator();

			const data: SEPAData = {
				name: this.deposit_reference.account_name,
				bic: this.deposit_reference.bic,
				iban: this.deposit_reference.iban,
				currency: "EUR",
				amount: this.funds.amount?.toString() || '0',
				remittanceInfo: this.deposit_reference.deposit_reference,
			};

			generator
				.generateQRCode(data, "base64")
				.then((qrCode) => {
					this.qr = qrCode.toString();
				})
				.catch((error) => {
					this.qr = '';
				});
		}
	}
});
</script>
<style scoped src="../../assets/css/payment.css"></style>
<style scoped>
.provider-header {
	font-size: 16px;
	font-weight: 500;
	border-bottom: 1px solid #eae9ed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -1rem;
	margin-bottom: 1rem;
	padding: 1rem;
	transition: background-color 0.25s;
	border-radius: 7px 7px 0 0;
}

.provider-header:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.provider-icon {
	height: 20px;
}

.payment-currency {
	font-size: 12px;
	line-height: 20px;
	color: #666666;
}

.centered-flex-gap {
	display: flex;
	align-items: center;
	gap: 5px;
}

.token_header {
	font-size: 16px;
	font-weight: bold;
	border-bottom: 1px solid #eae9ed;
	padding: 15px;
	cursor: pointer;
}

.token_icon_image {
	position: relative;
	top: 4px;
}

.token_address_copy_icon {
	float: right;
	display: block;
	width: 25px;
	position: relative;
	top: -60px;
}

.deposit-header {
	font-weight: 700;
	color: #333;
	font-size: 18px;
	line-height: 20px;
	margin-top: 15px;
}

.deposit-sub-head {
	font-weight: 700;
	color: #333;
	font-size: 14px;
	line-height: 20px;
	margin-top: 15px;
}

.deposit-text {
	font-weight: 700;
	color: #333;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.provider-tag {
	color: rgb(26, 109, 240);
	padding: 5px 8px;
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
	background: #EAFCFF;
	border-radius: 5px;
	margin-top: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.deposit-headers {
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
	color: #686475;
	margin-top: 16px;

}

.deposit-details {
	display: flex;
	flex-direction: column;
}

.deposit-data {
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	background: #FFF;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center
}

.deposit-data input {
	border: 0;
	width: 85%;
	font-family: Manrope;
	font-size: 14px;
	color: #686475;
	font-weight: 400;
	margin-left: 16px;
}

.deposit-data input:focus {
	outline: none;
}

.copy-button {
	border: 0;
	cursor: pointer;
	background-color: #FFF;
	margin: 5px
}

.details {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
}

.fee-section {
	border-radius: 8px;
	border: 1px solid #EAE9ED;
	background: #FFF;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-top: 16px;
	font-size: 14px;
	font-weight: 400;
}

.fee-item {
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
	padding-bottom: 8px;
}

.seperator {
	border-bottom: 1px solid #EAE9ED;

}

.checkmark {
	width: 24px;
	height: 24px;
	padding: 0;
	max-width: 24px;
}

@media screen and (max-width: 768px) {
	.deposit-text {
		padding: 0px;
		margin-top: 16px !important;
	}

	.deposit-sub-head {
		font-size: 18px;
	}

	.deposit-text {
		font-size: 14px;
	}

	.deposit-headers {
		font-size: 14px;
	}

	.details {
		font-size: 14px;
	}



}

.sepa_header {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px; /* 142.857% */
	color:  #333;

}

.sepa_text {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px; /* 150% */
	letter-spacing: -0.12px;
	color:  #333;

}

.deposit-modal-spacing {
	margin: 20px
}
</style>
