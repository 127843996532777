query getPortfolioHistory($eth_address: String, $range: String) {
    getPortfolioHistory(eth_address: $eth_address, range: $range) {
        points {
            timestamp
            cash
            stake
            positions
            total
            returns
        }
        current
    }
}
