/**
 * Apollo GraphQL Client configuration file
 */
import { ApolloClient, InMemoryCache, split, concat, HttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createClient } from 'graphql-ws';
import { accessTokenName, refreshTokenName } from './helpers/utils';
import * as Sentry from '@sentry/vue';
import { onError } from '@apollo/client/link/error'


import router from './router/';
import { getMainDefinition } from '@apollo/client/utilities';

const url = import.meta.env.VITE_REQUEST_ENDPOINT;
const socketUrl = import.meta.env.VITE_SOCKET_ENDPOINT;

// GraphQL Subscriptions Link
const httpLink = new HttpLink({
	// You should use an absolute URL here
	uri: url
});

const authMiddleware = setContext(async (request, previousContext) => {
	// add the authorization to the headers
	const accessToken = localStorage.getItem(accessTokenName);
	const refreshToken = localStorage.getItem(refreshTokenName);
	const amplitude_device_id = localStorage.getItem('amplitude_device_id');
  
	return {
		headers: accessToken !== null && accessToken !== '' ? { Authorization: 'Bearer ' + accessToken + '||' + refreshToken, 'amplitude-device-id': amplitude_device_id || '', 'request-path': router.currentRoute.value.fullPath } : { 'amplitude-device-id': amplitude_device_id || '', 'request-path': router.currentRoute.value.fullPath }
	};
  });

  
// Create the subscription WebSocket link Jwt authorization parameter as Authorization header
const getWsLink = () => {
	const accessToken = localStorage.getItem(accessTokenName);
	const refreshToken = localStorage.getItem(refreshTokenName);
	const shouldConnect = accessToken !== null && accessToken !== '';

	let wsLink: any;

	

	if (import.meta.env.VITE_GRAPHQL_WS_TYPE && import.meta.env.VITE_GRAPHQL_WS_TYPE =='graphql-ws' && socketUrl) {

		wsLink = new GraphQLWsLink(createClient({
			url: socketUrl,
			connectionParams: () => {
				return shouldConnect ? { authorization: 'Bearer ' + accessToken + '||' + refreshToken } : {};
			},
			retryAttempts: Infinity,
			shouldRetry: () => true,
			keepAlive: 10000,
			onNonLazyError: (err) => {
				console.log('onNonLazyError', err)
			},
			on: {
				
				connected: (socket) => {
					// // refresh user data if the user reconects 
					// if (store.state.user.last_refresh && store.state.user?.data?.id && store.state.user.last_refresh < Date.now() - 1000 * 60) {
					// 	store.state.user.getTokenUserData({
					// 		loading: false,
					// 		component: this,
					// 		updatePortfolio: true
					// 	} );
					// 	store.state.markets.getAIMarketInsights({component: null})
					// }

				},
				ping: (received) => {
					//console.log('ping', received)
				},
				pong: (received) => {
					//console.log('pong', received)
				},
				error: (error) => {
					console.log('error', error)
				}
				
				
			}				
		  }));
	
	} else {
		wsLink = new WebSocketLink({
			uri: socketUrl,
			options: {
				reconnect: true,
				connectionParams: () => {
					return shouldConnect ? { authorization: 'Bearer ' + accessToken + '||' + refreshToken } : {};
				}
			}
		});

	}

	return wsLink;
};

const errorLink = onError(error => {
	console.log('apollo error', error)
  })

// Using the ability to split links, you can send data to each link depending on what kind of operation is being sent
const link = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query);

		if (definition.kind === 'OperationDefinition' && definition.operation !== 'subscription') {
			Sentry.addBreadcrumb({
				category: 'graphQL',
				message: JSON.stringify({
					operation: definition.operation,
					name: definition.name?.value,
					selections: definition.selectionSet?.selections
				}),
				level: 'info'
			});
		}

		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	getWsLink(),
	concat(authMiddleware, httpLink)
);


// Create the Apollo client
export default new ApolloClient({
	link: errorLink.concat(link),
	cache: new InMemoryCache( {
		resultCaching: false
	}),
	connectToDevTools: true,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'network-only'
		},
		query: {
			fetchPolicy: 'network-only'
		}
	}
});
