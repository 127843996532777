<template>
	<div>
		<ul>
			<li>
				<div class="navigation-item pt-24 is-flex is-justify-content-space-between non-hover-item">
					<button class="button currency-toggle-btn no-translate" @click="
						currencySelector = !currencySelector;
					changeCurrency();
					">
						<img src="@/assets/icons/currency_switch.svg" class="currency-icon" />
						{{ currentView === 'USD' ? 'USD' : 'MPH' }}
					</button>
					<!-- Cash Balance -->
					<div v-show="portfolioItem === 0" class="">
						<p class="number-headers">{{ $t('BALANCE') }}:</p>
						<p v-if="activePortfolio && currentView === 'MPH'" class="highlighted-mini">
							{{ floorRound((activePortfolio?.cash_balance || 0) / Math.pow(10, 18), 2) }} MPH
						</p>
						<p v-else-if="currentView === 'USD' && mph_market" class="highlighted-mini">
							$ {{ usdFormatter(((activePortfolio?.cash_balance || 0) / Math.pow(10, 18)) *
								(mph_market?.close || 0)) }}
						</p>
					</div>
				</div>
			</li>
		</ul>


		<p class="navigation-item navigation-header">{{ $t('My Assets') }}</p>
		<!-- TODO -->
		<ul>
			<!-- Admin -->
			<li v-if="user && user.role === 'admin'">
				<router-link class="navigation-item admin-tabs navigation-link"
					active-class="navigation-item navigation-link-active" to="/admin/markets"
					@click.native="onLinkClick">{{ $t('ADMIN') }}</router-link>
			</li>
			<!-- Support -->
			<li v-if="user && user.role === 'support'">
				<router-link class="navigation-item  admin-tabs navigation-link"
					active-class="navigation-item navigation-link-active" to="/admin/verification"
					@click.native="onLinkClick">{{ $t('SUPPORT') }}</router-link>
			</li>
			<!-- Marketing -->
			<li v-if="user && user.role === 'marketing'">
				<router-link class="navigation-item admin-tabs navigation-link"
					active-class="navigation-item navigation-link-active" to="/admin/templates"
					@click.native="onLinkClick">{{ $t('MARKETING') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/portfolio" data-testid="portfolio-route"
					@click.native="onLinkClick">{{ $t('PORTFOLIO') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />

			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/savings" data-testid="savings-route"
					data-cy="savingsRoute" @click.native="onLinkClick">{{ $t('SAVINGS') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active " to="/funds" data-cy="fundsRoute"
					@click.native="onLinkClick">{{ $t('FUNDS') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />

			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active " to="/rewards" data-cy="rewards-route"
					@click.native="onLinkClick">{{ $t('REWARDS') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0" />

		</ul>

		<p class="navigation-item navigation-header">{{ $t('MARKETS') }}</p>
		<ul>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/stock" data-testid="stock-route"
					@click.native="onLinkClick">{{ $t('STOCKS') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link class="navigation-item navigation-link" to="/crypto"
					active-class="navigation-item navigation-link-active" data-cy="cryptoRoute"
					@click.native="onLinkClick">{{ $t('CRYPTOCURRENCIES') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/commodity"
					data-testid="commodities-route" @click.native="onLinkClick">{{ $t('COMMODITIES') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/forex" data-testid="forex-route"
					@click.native="onLinkClick">{{ $t('FOREX') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/index" data-testid="indices-route"
					@click.native="onLinkClick">{{ $t('INDEX') }}</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/unique" data-testid="unique-route"
					@click.native="onLinkClick">{{ $t('UNIQUES') }}
					<!-- <div class="new-tag">
						<img src="@/assets/icons/new/tada.png" class="new-icon" :alt="$t('unique.NEW')" />{{ $t('unique.NEW') }}
					</div> -->
				</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0" />


		</ul>
		<p class="navigation-item navigation-header">{{ $t('ACCOUNT') }}</p>
		<ul>
			<li>
				<div
					class="lang-frame navigation-item navigation-link navigation-link  dropdown-text-items transition-faster">
					<LanguageSelectorNavbar />
				</div>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link v-if="walletType !== 'metamask'" to="/wallet" id="wallet-link"
					class="navigation-item navigation-link navigation-link  dropdown-text-items transition-faster"
					@click.native="openCryptoWallet">
					{{ $t('WALLET') }}
				</router-link>
			</li>
			<hr class="hr-divider-links" v-if="walletType !== 'metamask'" style="margin: 0px 20px" />
			<li>
				<router-link id="settings-link" data-cy="settingsLink" to="/history" @click.native="onLinkClick"
					class="navigation-item navigation-link navigation-link dropdown-text-items transition-faster">
					{{ $t('HISTORY') }}
				</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link id="settings-link" data-cy="settingsLink" to="/settings" @click.native="onLinkClick"
					class="navigation-item navigation-link navigation-link dropdown-text-items transition-faster">
					{{ $t('SETTINGS') }}
				</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<router-link id="settings-link" to="/help" @click.native="onLinkClick"
					class="navigation-item navigation-link navigation-link dropdown-text-items transition-faster">
					{{ $t('HELP') }}
				</router-link>
			</li>
			<hr class="hr-divider-links" style="margin: 0px 20px" />
			<li>
				<a id="logout-link" data-cy="logoutLink"
					class="navigation-item navigation-link navigation-link dropdown-text-items transition-faster"
					@click="logout">
					{{ $t('LOGOUT') }}
				</a>
			</li>
		</ul>

		<!-- <li>
				<router-link
					class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active"
					to="/history"
					data-testid="history-route"
					data-cy="historyRoute"
					@click.native="onLinkClick"
					>{{ $t('HISTORY') }}</router-link
				>
			</li> -->

		<!-- <li v-if="user.wallet_type !=='morpherwallet'"> 
				<router-link
					class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active"
					data-testid="history-route"
					to="/migrate"
					@click.native="onLinkClick"
					>{{ $t('migration.MIGRATE') }}
				</router-link>
			</li> -->

	</div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { defineComponent } from 'vue'
//vuex
import LanguageSelectorNavbar from '@/components/LanguageSelectorNavbar.vue';

export default defineComponent({
	components: {
		LanguageSelectorNavbar
	},
	data() {
		return {
			currencySelector: false,
			portfolioItem: 0,
			currentView: 'MPH'
		};
	},
	computed: {
		...mapState(useUserStore, {
			user: (state) => state.data,
			payload: (state) => state.data?.payload,
			activePortfolio: (state) => state.activePortfolio,
			airdrop: (state) => state.data?.airdrop,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),
		...mapState(useMarketsStore, {
			mph_market: (state) => state.mph_market,
		}),

		portfolioValueUsd() {
			return 0;
		},
		availableClaims() {
			if (this.user?.airdrop?.available_claims && this.user.airdrop.available_claims > 0) {
				return true;
			} else {
				return false;
			}
		}
	},
	watch: {
		payload(payloadValue) {
			if (this.currentView !== (payloadValue.currency_view || 'MPH')) {
				this.currentView = payloadValue.currency_view || 'MPH';
				this.currencySelector = this.currentView === 'USD';
				if (this.currentView === 'USD' && !this.mph_market) {
					this.getMPHMarket({
						component: this
					});
				}
			}
		}
	},
	async mounted() {
		this.currentView = this.payload?.currency_view || 'MPH';
		this.currencySelector = this.currentView === 'USD';
		if (this.currentView === 'USD' && !this.mph_market) {
			this.getMPHMarket({
				component: this
			});
		}
	},
	methods: {
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
			logout: 'logout',
		}),
		...mapActions(useContractStore, {
			openCryptoWallet: 'openCryptoWallet',
		}),
		...mapActions(useMarketsStore, {
			getMPHMarket: 'getMPHMarket',
		}),


		switchPortfolioItem() {
			if (this.portfolioItem > 2) {
				this.portfolioItem = 0;
			} else if (this.portfolioItem === 2 && (!this.activePortfolio?.stake_value || Number(this.activePortfolio?.stake_value) === 0)) {
				this.portfolioItem = 0;
			} else if (this.portfolioItem === 1) {
				// Prevent showing infinite return
				this.portfolioItem = 0;
			} else {
				this.portfolioItem += 1;
			}
		},
		onLinkClick() {
			this.$emit('linkclick');
		},

		changeCurrency() {
			if (this.currencySelector) {
				this.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'USD' });
				this.currentView = 'USD';
			} else {
				this.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'MPH' });
				this.currentView = 'MPH';
			}
			this.currencySelector = this.currentView === 'USD';

			if (this.currentView === 'USD' && !this.mph_market) {
				this.getMPHMarket({
					component: this
				});
			}
		}
	}
});
</script>

<style scoped>
.navigation-header {
	color: var(--text-text-secondary, #686475);

	/* Small Text */
	font-family: 'Manrope';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
	padding-top: 4px;
	padding-bottom: 4px;
	margin-top: 16px;
}

.navigation-item {
	text-align: left;
	word-break: break-word;
}

.navigation-link:after {
	position: absolute;
	left: 0px;
	width: 5px;
	height: 100%;
	background: #00c386;
	content: '';
	opacity: 0;
	bottom: 0px;
	transition: all 0.3s;
}

.navigation-link-active {
	color: #000;
}

.navigation-link-active:after {
	position: absolute;
	left: 0px;
	width: 5px;
	height: 100%;
	background: #00c386;
	content: '';
	opacity: 1;
	bottom: 0px;
	transition: all 0.3s;
}

.navigation-search-container {
	margin-left: 32px;
	margin-right: 32px;
	padding-top: 3px;
	width: 60vw;
}

.new-tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 10px;
	width: 71px;
	height: 24px;
	background: #f9f5ff;
	border-radius: 16px;

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6941c6;
	margin-left: 10px;
}

.new-icon {
	width: 16px;
	height: 16px;
	margin-right: 1px;
}

.green-bubble {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 22px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	background: #dffbe9;
	border-radius: 16px;
	font-weight: 700;
	font-size: 12px;
	color: #039954;
	text-align: center;
	padding-top: 0px;
}

.navigation-link {
	color: var(--text-text-primary, #333);

	/* Normal Text Bold */
	font-family: 'Manrope';
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
	padding-top: 12px;
	padding-bottom: 12px;
}



.currency-selector-label {
	color: #4c4c4c;
}

.button.currency-toggle-btn {
	background: #00c386;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Manrope';
	border-radius: 20px;
	border-color: transparent;
	transition: all 0.25s;
}

.button.currency-toggle-btn:hover {
	background: #039954;
	transition: all 0.25s;
}

.currency-toggle-btn .currency-icon {
	margin-right: 0.5rem;
}

.currency-selector-label {
	color: #4c4c4c;
}

#logout-link {
	color: var(--text-text-red, #D70228);
}

.hr-divider-links {
	height: 1px;
	background-color: var(--border-border-light, #EAECF0);
}

.admin-tabs {

	color: blue;
}

.number-headers {
	color: var(--text-text-secondary, #686475);

	/* Normal Text */
	font-family: Manrope;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.14px;
}

.highlighted-mini {
	font-weight: 500;
	color: var(--text-text-primary, #333);

	/* Large Text Bold */
	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: -0.16px;
}

.navigation-link:hover {
	color: var(--Dark-Green, #039954);

}

#logout-link:hover {
	color: var(--Dark-Green, #039954);
}
</style>