/**
 * Market service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';
import getMinutely from '@/graphql/market/getMinutely.query.graphql';
import getHourly from '@/graphql/market/getHourly.query.graphql';
import getMarketsByIds from '@/graphql/market/getMarketsByIds.query.graphql';
import getFootballHistory from '@/graphql/market/getFootballHistory.query.graphql';
import getFootballScores from '@/graphql/market/getFootballScores.query.graphql';
import getMarketsAdmin from '@/graphql/market/getMarketsAdmin.query.graphql';
import getMarketDaily from '@/graphql/market/getMarketDaily.query.graphql';
import updateMarket from '@/graphql/market/updateMarket.subscription.graphql';
import updateFootball from '@/graphql/market/updateFootball.subscription.graphql';
import updateMarketStatus from '@/graphql/market/updateMarketStatus.subscription.graphql';
import updateNews from '@/graphql/market/updateNews.subscription.graphql';
import getSectors from '@/graphql/market/getSectors.query.graphql';
import getMarketSpark from '@/graphql/market/getMarketSpark.query.graphql';
import getDiscoveryMarkets from '@/graphql/market/getDiscoveryMarkets.query.graphql';
import getMarketSegments from '@/graphql/market/getMarketSegments.query.graphql';
import getSegmentMarkets from '@/graphql/market/getSegmentMarkets.query.graphql';
import getMarketSplits from '@/graphql/market/getMarketSplits.query.graphql';
import getMarketNews from '@/graphql/market/getMarketNews.query.graphql';
import getMarketTweets from '@/graphql/market/getMarketTweets.query.graphql';
import getAIMarketInsights from '@/graphql/market/getAIMarketInsights.query.graphql';
import getOnboardingMarkets from '@/graphql/market/getOnboardingMarkets.query.graphql';
import marketDataExport from '@/graphql/market/marketDataExport.query.graphql';
import marketHashesExport from '@/graphql/market/marketHashesExport.query.graphql';
import Util from './shared';
import type { getSectors as getSectorsResponse } from '@/graphql/market/types/getSectors';
import type { getMarketHourlyVariables, getMarketHourly } from '@/graphql/market/types/getMarketHourly';
import type { getMarketMinutelyVariables, getMarketMinutely } from '@/graphql/market/types/getMarketMinutely';
import type { getMarketsByIdsVariables, getMarketsByIds as getMarketsByIdsResponse } from '@/graphql/market/types/getMarketsByIds';
import type { getFootballHistoryVariables, getFootballHistory as getFootballHistoryResponse } from '@/graphql/market/types/getFootballHistory';
import type { getFootballScores as getFootballScoresResponse } from '@/graphql/market/types/getFootballScores';
import { ungzip } from 'node-gzip';


import type { getMarketsAdminVariables, getMarketsAdmin as getMarketsAdminResponse } from '@/graphql/market/types/getMarketsAdmin';

import type { getMarketDailyVariables, getMarketDaily as getMarketDailyResponse } from '@/graphql/market/types/getMarketDaily';
import type { getMarketSparkVariables, getMarketSpark as getMarketSparkResponse } from '@/graphql/market/types/getMarketSpark';
import type { Market } from '../types/schema';


import type { updateMarket as updateMarketResult } from '@/graphql/market/types/updateMarket';
import type { updateFootball as updateFootballResult } from '@/graphql/market/types/updateFootball';
import type { updateMarketStatus as updateMarketStatusResult } from '@/graphql/market/types/updateMarketStatus';
import type { updateNews as updateNewsResult } from '@/graphql/market/types/updateNews';
import type { FetchResult, Observable } from '@apollo/client/core';
import type {
	getDiscoveryMarketsVariables,
	getDiscoveryMarkets as getDiscoveryMarketsResponse
} from '@/graphql/market/types/getDiscoveryMarkets';
import type { getMarketSegmentsVariables, getMarketSegments as getMarketSegmentsResponse } from '@/graphql/market/types/getMarketSegments';
import type { getSegmentMarketsVariables, getSegmentMarkets as getSegmentMarketsResponse } from '@/graphql/market/types/getSegmentMarkets';
import type { getMarketSplitsVariables, getMarketSplits as getMarketSplitsResponse } from '@/graphql/market/types/getMarketSplits';
import type { getMarketNewsVariables, getMarketNews as getMarketNewsResponse } from '@/graphql/market/types/getMarketNews';
import type { getMarketTweetsVariables, getMarketTweets as getMarketTweetsResponse } from '@/graphql/market/types/getMarketTweets';

import type { getAIMarketInsightsVariables, getAIMarketInsights as getAIMarketInsightsResponse } from '@/graphql/market/types/getAIMarketInsights';
import type { getOnboardingMarketsVariables, getOnboardingMarkets as getOnboardingMarketsResponse } from '@/graphql/market/types/getOnboardingMarkets';

import util from './shared'




export interface MarketWithSubscription extends Market {
	subscription?: any;
	subscription_tv?: any;
}


let marketSubscriptions: {
	[component: string]: {
		[market:string] : any
	}
} = {}
let SubscriptionList: any[] = [];

let existingNewsSubscription: any = undefined;
let existingAiNewsSubscription: any = undefined;
let existingMarketStatusSubscription: any = undefined;

export const MarketService = {
	getSector() {
		return util.formatReturnData(apollo.query<getSectorsResponse>({
			query: getSectors
		}));
	},
	getHourly(variables: getMarketHourlyVariables) {
		return util.formatReturnData(apollo.query<getMarketHourly>({
			query: getHourly,
			variables
		}));
	},
	getMinutely(variables: getMarketMinutelyVariables) {
		return util.formatReturnData(apollo.query<getMarketMinutely>({
			query: getMinutely,
			variables
		}));
	},
	getFootballHistory(variables: getFootballHistoryVariables) {
		return util.formatReturnData(apollo.query<getFootballHistoryResponse>({
			query: getFootballHistory,
			variables
		}));
	},
	getFootballScores() {
		return util.formatReturnData(apollo.query<getFootballScoresResponse>({
			query: getFootballScores
		}));
	},
	
	async getMarketsByIds(variables: getMarketsByIdsVariables) {
		const market_data: any = await util.formatReturnData(apollo.query<any>({
			query: getMarketsByIds,
			variables
		}));


		if (!market_data?.data?.getMarketsByIds) {
			return null
		}
		const data_compressed = market_data.data.getMarketsByIds;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		const data = JSON.parse(data_decompressed.toString());

		const return_data: Market[] = [];
		// restore the property names from the single characters returned by backend
		if (data.n) {
			return_data.push( {
				id: variables.ids ? variables.ids[0] : '' ,
				name: data.n,
				type: data.t,
				symbol: data.s,
				open: data.o,
				high: data.h,
				low: data.l,
				close: data.c,
				change_percent: data.p,
				is_enabled: data.e,
				is_paused: data.a,
				pause_reason: data.r,
				volume: data.v,
				spread: data.d,
				change: data.g,
				oldest_close: data.q,
				timestamp: data.i,
				minutely: data.m,
				exchange_status: data.x,
				next_status: data.xn,
				next_status_timestamp: data.xt,
				exchange_timezone: data.xz,
				exchange_open: data.xo,
				exchange_close: data.xc,
				first_timestamp: data.f,
				market_delay: data.de,
				sector: data.se,
                limit_direction: data.ld,
                max_leverage: data.ml,
                min_position_age: data.ma,
				logo_image: data.li,
				logo_date: data.lt,
				card_data: data.cd,
				country: data.co,
				constituents: data.cn,
				market_assets: data.as,
				market_cap: data.ca,
				

			} ) ;
		} else {
			for (const [key, val] of Object.entries(data)) {
				const value: any = val;

				return_data.push( {
					name: value.n,
					type: value.t,
					symbol: value.s,
					open: value.o,
					high: value.h,
					low: value.l,
					close: value.c,
					change_percent: value.p,
					is_enabled: value.e,
					is_paused: value.a,
					pause_reason: value.r,
					volume: value.v,
					spread: value.d,
					change: value.g,
					oldest_close: value.q,
					timestamp: value.i,
					minutely: value.m,
					exchange_status: value.x,
					next_status: data.xn,
					next_status_timestamp: data.xt,
					exchange_timezone: data.xz,
					exchange_open: data.xo,
					exchange_close: data.xc,
					first_timestamp: value.f,
					market_delay: value.de,
					sector: value.se,
					limit_direction: value.ld,
					max_leverage: value.ml,
					min_position_age: value.ma,		
					logo_image: value.li,
					logo_date: value.lt,
					card_data: value.cd,	
					country: value.co,
					constituents: value.cn,
					market_assets: value.as,
					market_cap: value.ca,	
				} );
			}
		}

		
		return return_data;
	},
	getMarketsAdmin(variables: getMarketsAdminVariables) {
		return util.formatReturnData(apollo.query<getMarketsAdminResponse>({
			query: getMarketsAdmin,
			variables
		}));
	},

	async getMarketSpark(variables: getMarketSparkVariables) {
		const market_data: any = await util.formatReturnData(apollo.query<getMarketSparkResponse>({
			query: getMarketSpark,
			variables
		}));


		const data_compressed = market_data.data.getMarketSpark;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		const data = JSON.parse(data_decompressed.toString());

		market_data.data.getMarketSpark = data;

		return market_data;
	},
	marketDataExport() {
		return util.formatReturnData(apollo.query<JSON>({
			query: marketDataExport
		}));
	},
	marketHashesExport() {
		return util.formatReturnData(apollo.query<JSON>({
			query: marketHashesExport
		}));
	},
	getMarketDaily(variables: getMarketDailyVariables) {
		return util.formatReturnData(apollo.query<getMarketDailyResponse>({
			query: getMarketDaily,
			variables
		}));
	},
	async getDiscoveryMarkets(variables: getDiscoveryMarketsVariables, user_id: string) {
		variables.compact = false;
		variables.fetch_logo = false;

		let start = Date.now()


		let market_data;

		market_data = await apollo.query<getDiscoveryMarketsResponse>({
			query: getDiscoveryMarkets,
			variables
		});

		const data_compressed = JSON.parse(JSON.stringify(market_data.data.getDiscoveryMarkets));

		return {
			data: {
				getDiscoveryMarkets: data_compressed
			} 
		}


	},
	async getMarketSegments(variables: getMarketSegmentsVariables) {
		const segment_data = await util.formatReturnData(apollo.query<getMarketSegmentsResponse>({
			query: getMarketSegments,
			variables
		}));


		if (variables.market_id == 'all') {

			const data_compressed = segment_data.data.getMarketSegments;

			const buf = Buffer.from(data_compressed, 'base64');
	
			const data_decompressed = await ungzip(buf);
	
			const data = JSON.parse(data_decompressed.toString());
	
			segment_data.data.getMarketSegments = data;
		}

		return segment_data;
	},
	getSegmentMarkets(variables: getSegmentMarketsVariables) {
		return util.formatReturnData(apollo.query<getSegmentMarketsResponse>({
			query: getSegmentMarkets,
			variables
		}));
	},
	getMarketSplits(variables: getMarketSplitsVariables) {
		try {
			return util.formatReturnData(apollo.query<getMarketSplitsResponse>({
				query: getMarketSplits,
				variables
			}));
		} catch (err: any) {
			console.log('error in getMarketSplits', err.toString())
			return null;
		}
	},
	async getMarketNews(variables: getMarketNewsVariables) {

		variables.compact = true;
		

		const market_data = await apollo.query<any>({
			query: getMarketNews,
			variables
		});

		const data_compressed = market_data.data.getMarketNews;


		const buf = Buffer.from(data_compressed, 'base64');
	
		const data_decompressed = await ungzip(buf);

		const data = JSON.parse(data_decompressed.toString());
		return {
			data: {
				getMarketNews: data
			} as getMarketNewsResponse
		} 
	},	
	getMarketTweets(variables: getMarketTweetsVariables) {
		return util.formatReturnData(apollo.query<getMarketTweetsResponse>({
			query: getMarketTweets,
			variables
		}));
	},	
	
	async getAIMarketInsights() {
		

		const market_data = await  util.formatReturnData(apollo.query<getAIMarketInsightsResponse>({
			query: getAIMarketInsights,
			variables: { compact: true}
		}));

		const data_compressed = market_data.data.getAIMarketInsights;


		const buf = Buffer.from(data_compressed, 'base64');
	
		const data_decompressed = await ungzip(buf);

		const data = JSON.parse(data_decompressed.toString());

		return {
			data: {
				getAIMarketInsights: data
			} 
		}
	},		
	getOnboardingMarkets() {
		return util.formatReturnData(apollo.query<getOnboardingMarketsResponse>({
			query: getOnboardingMarkets
		}));
	},		
	
	unsubscribeFromMarket(market: MarketWithSubscription, component: string) {

		if (market.subscription && market.subscription.unsubscribe) {
			market.subscription.unsubscribe();
			market.subscription = undefined;

			marketSubscriptions[component][Util.formatMarketId(market.type || '', market.symbol || '')] = undefined;
			
		}
	},
	unsubscribeFromNews(subscription: any) {
		if (subscription) {
			subscription.unsubscribe();
			subscription = undefined;
			existingNewsSubscription = undefined;
		}
	},
	subscribeToNews(market_id: string, callback: (data: any) => void) {
		
		if (existingNewsSubscription) {
			this.unsubscribeFromNews(existingNewsSubscription);
		}

		existingNewsSubscription = apollo
			.subscribe({
				query: updateNews,
				variables: {
					event: 'NEWS_' + market_id
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeToNews', err)
				}
			});
		
		return existingNewsSubscription
	},

	unsubscribeFromMarketStatus(subscription: any) {
		if (subscription) {
			subscription.unsubscribe();
			subscription = undefined;
			existingMarketStatusSubscription = undefined;
		}
	},
	subscribeToMarketStatus(eth_address: string, callback: (data: any) => void) {
		
		if (existingMarketStatusSubscription) {
			this.unsubscribeFromMarketStatus(existingMarketStatusSubscription);
		}

		existingMarketStatusSubscription = apollo
			.subscribe({
				query: updateMarketStatus,
				variables: {
					event: 'MARKET_STATUS_' + eth_address
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeToMarketStatus', err)
				}
			});
		
		return existingMarketStatusSubscription
	},


	unsubscribeFromAINews(subscription: any) {
		if (subscription) {
			subscription.unsubscribe();
			subscription = undefined;
			existingAiNewsSubscription = undefined;
		}
	},
	subscribeToAINews(market_id: string, callback: (data: any) => void) {
		
		if (existingAiNewsSubscription) {
			this.unsubscribeFromAINews(existingAiNewsSubscription);
		}

		existingAiNewsSubscription = apollo
			.subscribe({
				query: updateNews,
				variables: {
					event: 'NEWS_' + market_id
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeToAINews', err)
				}
			});

		return existingAiNewsSubscription
		
	},
	subscribeToMarketPosition(market: Market, callback: (data: FetchResult<updateMarketResult>) => void) {
		try {
			
			const subscription = apollo
				.subscribe({
					query: updateMarket,
					variables: {
						event: Util.formatMarketId(market.type || '', market.symbol || '')
					}, errorPolicy: 'ignore'
				})
				.subscribe({
					next(data) {
						try {
							callback(data);
						} catch {}
					},
					error(err) {
						//console.log('error in subscribeToMarket', err)
					}
				});
			
			return subscription
		} catch (err) {
			console.log('error in subscribeToMarket', err)
		}
	},

	subscribeToMarket(market: MarketWithSubscription, component: string ,callback: (data: FetchResult<updateMarketResult>) => void) {
		try {
			if (!marketSubscriptions[component]) {
				marketSubscriptions[component] = {};
			}

			const existingSubscription = marketSubscriptions[component][Util.formatMarketId(market.type || '', market.symbol || '')] ;
			if (existingSubscription) {
				market.subscription = existingSubscription.sub;
				this.unsubscribeFromMarket(market, component);
			}
			if (market.subscription !== undefined) market.subscription = undefined;
			market.subscription = apollo
				.subscribe({
					query: updateMarket,
					variables: {
						event: Util.formatMarketId(market.type || '', market.symbol || '')
					}, errorPolicy: 'ignore'
				})
				.subscribe({
					next(data) {
						try {
							callback(data);
						} catch {}
					},
					error(err) {
						//console.log('error in subscribeToMarket', err)
					}
				});
				if (!marketSubscriptions[component]) {
					marketSubscriptions[component] = {};
				}
				marketSubscriptions[component][Util.formatMarketId(market.type || '', market.symbol || '')] = market.subscription
			
		} catch (err) {
			console.log('error in subscribeToMarket', err)
		}
	},

	unsubscribeFromFootball(market_id:string, subscription: any) {
		if (subscription && subscription.unsubscribe) {
			subscription.unsubscribe();
			subscription = undefined;
			SubscriptionList = SubscriptionList.filter(sub => sub.id !== "FOOTBALL_" + market_id);
		}
	},

	subscribeToFootball(market_id: string, callback: (data: FetchResult<updateFootballResult>) => void) {
		const existingSubscription = SubscriptionList.find(sub => sub.id === "FOOTBALL_" + market_id);
		if (existingSubscription) {

			this.unsubscribeFromFootball(market_id, existingSubscription);
		}
		
		const subscription = apollo
			.subscribe({
				query: updateFootball,
				variables: {
					event: "FOOTBALL_" + market_id
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeToFootball', err)
				}
			});
		SubscriptionList.push({ id: "FOOTBALL_" + market_id, sub: subscription });
	}

};
