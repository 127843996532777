<template>
	<div class="loginContainer">
		<section v-if="!config.application_live && $route.query && $route.query.adminoverride !== 'true'"
			class="box auth">
			{{ $t('MAINTENANCE_MESSAGE') }}
		</section>
		<section v-else-if="$route.name === 'Unlock'" ref="screen" class="hero is-fullheight">
			<Unlock />
		</section>
		<div v-else class="loginSection form">
			<WalletLoginMore v-if="formType == 'login' && $route.params.status == 'more'" />
			<WalletRecover v-else-if="formType == 'login' && $route.params.status == 'recover'" />
			<WalletLogin2fa v-else-if="formType == 'login' && $route.params.status == '2fa'" />
			<AccountDeleted v-else-if="formType == 'login' && $route.params.status == 'accountdeleted'" />
			<TokenLoginRetry v-else-if="formType == 'login' && $route.params.status == 'retry'" />
			<WalletSignup v-else-if="formType == 'register' && $route.params.status == 'geoblock'" />
			<WalletSignup v-else-if="formType == 'register'" />
			<WalletLogin v-else-if="formType !== ''" />
			<BuildInfo v-if="false" />

			<div class="is-flex  is-hidden-mobile " style="align-items: center;
	justify-content: center;">
				<div class="lang-frame mr-3">
					<LanguageSelector />
				</div>

				<p class="loginLink ml-20 mt-0">
					{{ $t('NEED_HELP') }}
					<a :href="supportArticleLink('LoginIssues')" class="login-router transition-faster"
						target="_blank">{{ $t('auth.VISIT_SUPPORT') }}</a>
				</p>
			</div>

			<BuildInfo v-if="false" />


		</div>
		<div class="loginSection info">
			<img class="auth-image" v-if="formType == 'login' && $route.params.status == 'more'"
				src="@/assets/auth/auth-login-more.svg" />
			<img class="auth-image" v-else-if="formType == 'login' && $route.params.status == 'recover'"
				src="@/assets/auth/auth-recover.svg" />
			<img class="auth-image" v-else-if="formType == 'login' && $route.params.status == 'retry'"
				src="@/assets/auth/auth-error.svg" />
			<img class="auth-image" v-else-if="formType == 'login' && $route.params.status == 'migrated'"
				src="@/assets/auth/auth-migrated.svg" />
			<img class="auth-image" v-else-if="formType == 'login' && $route.params.status == '2fa'"
				src="@/assets/auth/auth-login.svg" />
			<img class="auth-image" v-else-if="formType == 'login' && $route.params.status == 'kyc'"
				src="@/assets/auth/auth-kyc.svg" />
			<img class="auth-image limit-size"
				v-else-if="formType == 'login' && ['method', 'wallet', 'email', 'new', 'migrating'].includes(($route.params.status).toString())"
				src="@/assets/auth/auth-error.svg" />

			<img class="auth-image" v-else-if="formType == 'login' && $route.params.status == 'kyc_processing'"
				src="@/assets/auth/auth-kyc-processing.svg" />

			<img class="auth-image max-width-385"
				v-else-if="formType == 'login' && $route.params.status == 'accountdeleted'"
				src="@/assets/auth/account-deleted.svg" />
			<div v-else-if="formType == 'register'" :class="{'auth-image': $route.params.status, 'auth-image-register': $route.params.status }">
				<div v-if="$route.params.status == 'geoblock'">
					<img src="@/assets/auth/auth-geoblock.svg" />
				</div>
				<div
					v-else-if="$route.params.status == 'email' || $route.params.status == 'verify' || $route.params.status == 'success'">
					<h2 data-cy="logInTitle" class="authTitle">{{ $t('auth.PLASMA_SIDECHAIN') }}</h2>

					<div class="is-flex">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<div class="bullet">
							<p data-cy="logInDescription" class="authText head">{{ $t('auth.PLASMA_SIDECHAIN_1_HEAD') }}
							</p>
							<p data-cy="logInDescription" class="authText">{{ $t('auth.PLASMA_SIDECHAIN_1_TEXT') }}</p>
						</div>

					</div>
					<div class="is-flex">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<div class="bullet">
							<p data-cy="logInDescription" class="authText head">{{ $t('auth.PLASMA_SIDECHAIN_2_HEAD') }}
							</p>
							<p data-cy="logInDescription" class="authText">{{ $t('auth.PLASMA_SIDECHAIN_2_TEXT') }}</p>
						</div>

					</div>
					<div class="is-flex">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<div class="bullet">
							<p data-cy="logInDescription" class="authText head">{{ $t('auth.PLASMA_SIDECHAIN_3_HEAD') }}
							</p>
							<p data-cy="logInDescription" class="authText">{{ $t('auth.PLASMA_SIDECHAIN_3_TEXT') }}</p>
						</div>

					</div>


					<img src="@/assets/auth/auth-metamask.svg" />
				</div>
				<div v-else-if="$route.params.status == 'rejected'">
					<img src="@/assets/auth/auth-rejected.svg" />
				</div>


				<div v-else-if="invited">
					<img class="auth-image-mobile" src="@/assets/auth/auth-signup-invite.svg" />
					<h2 data-cy="logInTitle" class="authTitle">{{ $t('auth.INVITED_HEADER') }}</h2>
					<p data-cy="logInDescription" class="authText">{{ $t('auth.INVITED_DESCRIPTION') }}</p>
					<img class="auth-image-text" src="@/assets/auth/auth-signup-invite.svg" />
				</div>
				<div v-else>
					<RegisterSide1></RegisterSide1> 
					 
					
					<!-- <h2 data-cy="logInTitle" class="authTitle">{{ $t('auth.CRYPTO_GIFT') }}</h2>
					<p data-cy="logInDescription" class="authText">{{ $t('auth.CRYPTO_GIFT_DESCRIPTION') }}</p>
					<img class="auth-image-text" src="@/assets/auth/auth-signup.svg" /> -->
					
				</div>
			</div>
			<img class="auth-image" v-else src="@/assets/auth/auth-login.svg" />
		</div>

		<div class="is-flex  is-hidden-desktop is-hidden-tablet mobile-support " style="align-items: center;
	justify-content: center;">
			<div class="lang-frame mr-3">
				<LanguageSelector />
			</div>

			<p class="loginLink ml-20 mt-0">
				{{ $t('NEED_HELP') }}
				<a :href="supportArticleLink('LoginIssues')" class="login-router transition-faster" target="_blank">{{
					$t('auth.VISIT_SUPPORT') }}</a>
			</p>
		</div>

		<KYCCaptureModal v-if="modals.showKYCCaptureModal" />
	</div>
</template>

<script lang=ts>
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { useAdminStore } from '@/store/modules/admin';
import WalletLogin from '@/components/auth/WalletLogin.vue';
import WalletRecover from '@/components/auth/WalletRecover.vue';
import WalletLoginMore from '@/components/auth/WalletLoginMore.vue';
import WalletLogin2fa from '@/components/auth/WalletLogin2fa.vue';
import WalletSignup from '@/components/auth/WalletSignup.vue';
import AccountDeleted from '@/components/auth/AccountDeleted.vue';
import TokenLoginRetry from '@/components/auth/TokenLoginRetry.vue';
import Unlock from '@/components/auth/Unlock.vue';
import BuildInfo from '@/components/BuildInfo.vue';
//vuex
import { LOADING } from '@/store/mutation-types';
import { accessTokenName, installHotJar, refreshTokenName } from '../helpers/utils';
import NetworkMetamaskModal from '@/components/modals/NetworkMetamaskModal.vue';
import FortmaticOverlayModal from '@/components/modals/FortmaticOverlayModal.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import KYCCaptureModal from '@/components/modals/KYCCaptureModal.vue';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { defineComponent } from 'vue';
import RegisterSide1 from '@/components/auth/RegisterSide1.vue';

/* @group Application_View */
/*
  <h4> <b> Auth page </b> </h4>
  <br> Authentication Page which shows Login and Register form

  <br> Contains:


*/
export default defineComponent({
	name: 'Auth',
	components: {
		AccountDeleted,
		BuildInfo,
		NetworkMetamaskModal,
		FortmaticOverlayModal,
		Unlock,
		LanguageSelector,
		WalletLogin,
		WalletLoginMore,
		WalletRecover,
		WalletLogin2fa,
		WalletSignup,
		KYCCaptureModal,
		TokenLoginRetry,
		RegisterSide1
	},
	props: {
		// The name of the form to display
		authType: {
			// `login` / `register`
			type: String,
			required: false,
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
			screenWidth: 1024,
			formType: '',
			title: 'Login',
			initialized: false,
			morpherWallet: false,
			invited: false,
			altSignup: false
		};
	},
	computed: {
		...mapState(useAdminStore, {
			config: (state) => state.config,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useModalsStore, {
			modals: (state) => state,
		}),

	},
	watch: {
		'$route.name'(nv) {
			if (nv === 'Login' || nv === 'Register' || nv === 'RegisterInvited') this.loading(false);
		},
		authType(nv) {
			this.formType = nv;
			this.title = nv.charAt(0).toUpperCase() + nv.toLowerCase().slice(1);
			this.$emit('updateHead');
			this.$t('fgh')
			this.$i18n
		}
	},
	async created() {
		window.addEventListener('resize', this.handleResize);
	},
	mounted() {
		
		// AB Test disabled - uncomment to enable
		//checkABTestVariant()

		if (this.$refs.screen) this.screenWidth = (this.$refs.screen as any).clientWidth;
		this.formType = this.authType;

		if (this.$route.query.private_invite) {
			localStorage.setItem('private_invite', this.$route.query.private_invite.toString());
		}

		if (
			this.$route.name &&
			(this.$route.name === 'Login' || this.$route.name === 'Register' || this.$route.name === 'RegisterInvited')
		) {
			this.title = this.$route.name.charAt(0).toUpperCase() + this.$route.name.toLowerCase().slice(1);
			this.$emit('updateHead');

			this.loading(false);


			if (
				localStorage.getItem(accessTokenName) !== null &&
				localStorage.getItem(accessTokenName) !== 'null' &&
				localStorage.getItem(refreshTokenName) !== null &&
				localStorage.getItem(refreshTokenName) !== 'null'
			) {
				this.initialized = true;
				if (this.user && this.user.status && this.user.status == 'confirmed') {
					return this.$router.push('/').catch(() => { });
				}

			}
		}

		let referred_by = this.$route.query.referred_by ? this.$route.query.referred_by : null;

		if (!referred_by) {
			referred_by = localStorage.getItem('airdrop_referred_by');
		}

		if (referred_by) {
			this.invited = true;
		} else {
			this.invited = false;
		}


		installHotJar('')

		this.initialized = true;
	},
	methods: {
		...mapActions(useStatusStore, {
			loading: LOADING,
		}),
		handleResize() {
			if (this.$refs.screen) this.screenWidth = (this.$refs.screen as any)?.clientWidth;
		},
		selectWallet(wallet: string) {
			if (wallet == 'morpher') {
				this.morpherWallet = true;
			} else {
				this.morpherWallet = false;
			}
		},
		checkABTestVariant() {
			if (window.posthog) {
				try {
					// if (window.posthog.featureFlags && window.posthog.featureFlags.override) {
					// 	window.posthog.featureFlags.override({'signup-page-conversion': 'variant_1'})
					// }

					let flag = window.posthog.getFeatureFlag('signup-page-conversion')
					if (!flag) {
						const self = this
						window.posthog.onFeatureFlags(function () {
							flag = window.posthog.getFeatureFlag('signup-page-conversion')
							//console.log('flag2',flag)
							if (flag == 'variant_1') {
								self.altSignup = true;
							} else {
								self.altSignup = false;
							}
						})
					}
					//console.log('flag1',flag)
					if (flag == 'variant_1') {
						this.altSignup = true;
					} else {
						this.altSignup = false;
					}
				} catch (err: any) {
					console.log('posthog err:' + err.toString())
				}
			}
		},
	},
	head: {
		title() {
			return {
				inner: this.title
			};
		}
	}
});
</script>

<style scoped>
.container {
	width: 480px;
}

.loginContainer {
	font-family: 'Manrope';
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	display: flex;
	min-height: 100vh;
	height: 100%;
	background-color: #FFF;
}

.loginSection {
	display: block;
	flex: 50%;
}

.loginSection.form {
	display: flex;
	flex-direction: column;
	padding: 32px 0px;
	background-color: #FFF;
}

.loginSection.info {
	background-color: #dffbe9;
	text-align: center;
	position: relative;
}

.auth-image {
	width: 80%;
	max-width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.auth-image.limit-size {
	max-width: 385px;
}

.auth-image-text {
	margin-top: 80px;
}

.auth-image-mobile {
	display: none;
}

.max-width-385 {
	max-width: 480px;
}

@media only screen and (max-width: 768px) {
	.loginSection.form {
		width: 100%;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.loginSection.info {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-top: 32px;
		border-radius: 24px 24px 0px 0px;

		text-align: center;
		padding-bottom: 32px;
	}

	.auth-image-text {
		display: none;
	}

	.auth-image-mobile {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 368px;
		margin-bottom: 32px;
	}

	.loginContainer {
		display: inline;
	}

	.auth-image {
		width: 80%;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		position: unset;
		top: unset;
		left: unset;
		transform: unset;
	}
}

.policies {
	margin: 0 auto;
	padding-bottom: 1rem;
	font-size: 14px;
}

.policies a {
	color: #666;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.policies a:hover {
	color: #000;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media only screen and (max-width: 768px) {
	.policies {
		width: 90%;
		justify-content: flex-start !important;
	}

	.lang-frame .language-selector {
		margin: 0 !important;
		margin-right: auto !important;
		margin-left: 10px !important;
	}
	
	.loginSection.info {
		background-color: #FFF!important;
	}

}

.lang-frame {
	display: flex;
	align-items: center;
	justify-content: center;
}

.lang-frame .language-selector {
	margin: 0 auto;
}

.auth-image-register {
	max-width: 400px;
}

.authTitle {
	font-size: 28px;
	font-weight: 400;
	line-height: 40px;
	color: #333333;
	text-align: left;
	margin-bottom: 16px;
}

.authText {
	font-family: 'Manrope';
	font-weight: 400;
	color: #686475;
	font-size: 16px;
	text-align: left;
}

.authText.head {
	font-weight: 700;
}

.bullet {
	padding-left: 10px;
	margin-top: 5px;
	margin-bottom: 5px;
}



.mobile-support {
	padding-top: 32px;
	padding-bottom: 32px;
}
</style>
