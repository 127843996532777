<template>
	<div class="portfolio-line-container">
		<div class="portfolio-line-chart">
			<highcharts v-show="initialized" ref="chart"
				:class="'stock fade-in portfolio-chart-' + color + (sparkline ? '-spark' : '')"
				:constructor-type="'stockChart'" :options="chart.stockOptions"></highcharts>
		</div>

	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import EventBus from '@/store/event-bus';
import { LOADING } from '@/store/mutation-types';

import { UserService } from '@/services/UserService';
import { useStatusStore } from '@/store/modules/status';
/* @group Components_Chart */
/*
  <h4> <b> Portfolio Line Chart </b> </h4>
  <br> - Similar component to ChartOHLC
  <br>
  <br> Updates chart data every 10 seconds using updateInterval from vuex
  <br> Emits same events as ChartOHLC component like:
  <br> updateHover, updateLive, updateChange where parent component
  <br> Portfolio view, listens to this events and triggers functions
  <br> form same mixin chartEvents.mixin as ChartOHLC component
  <br> - chartType flag used to distinguish between this 2 components
  <br> - sparkline flag used for small header equity chart which converts
  <br> chart to look like sparkline by setting custom properties
*/
export default defineComponent({
	name: 'PortfolioSparkLine',
	props: {
		height: {
			type: Number,
			required: false,
			default: 70
		},
		width: {
			type: Number,
			required: false,
			default: 120
		},
		sparkline: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			// for chartEvents.mixin on emit event
			chartType: 'portfolio',
			// Flag for the future switching between returns only
			// and returns + cash_balance which is currently in use
			color: 'green',
			initialized: false,
			dateFormat: 'HH:mm MMM DD',

			// Updating chart live
			updateChart: true,
			currentView: 'Value',

			// Highcharts properties
			chart: {
				percentage: 0,
				startValue: 0,
				stockOptions: {
					defs: {
						gradient0: {
							tagName: 'linearGradient',
							id: 'gradient-green-sparkline',
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1,
							children: [
								{
									tagName: 'stop',
									offset: 0
								},
								{
									tagName: 'stop',
									offset: 1
								}
							]
						},
						gradient1: {
							tagName: 'linearGradient',
							id: 'gradient-red-sparkline',
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1,
							children: [
								{
									tagName: 'stop',
									offset: 0
								},
								{
									tagName: 'stop',
									offset: 1
								}
							]
						}
					},
					time: {
						useUTC: false
					},
					chart: {
						animation: false,
						styledMode: true,
						type: 'area',
						backgroundColor: 'rgba(255, 255, 255, 0.0)',
						renderTo: 'container',
						spacingTop: 10,
						spacingBottom: this.sparkline ? 0 : 10,
						spacingLeft: 0,
						spacingRight: 0
					},
					scrollbar: {
						enabled: false
					},
					rangeSelector: {
						enabled: false
					},
					credits: false,
					plotOptions: {
						series: {
							states: {
								hover: {
									enabled: true,
									halo: {
										size: 7
									}
								}
							}
						}
					},
					yAxis: [
						{
							labels: {
								enabled: false
							}
						},
						{
							labels: {
								enabled: false
							},
							title: {
								enabled: false
							},
							top: '65%',
							height: '35%'
						}
					],
					xAxis: {
						enabled: true,
						type: 'datetime',
						labels: {
							enabled: true
						},
						events: {
							setExtremes: this.changeColor
						}
					},
					navigator: {
						enabled: false
					},
					tooltip: {
						enabled: true,
						useHTML: true,
						borderRadius: 13,
						formatter: this.formatTooltip,
						valueDecimals: 2,
						// Position date tooltip label so centered, fixed, and doesn't get hidden at edges
						positioner(boxWidth: number, boxHeight: number, point: any) {
							// let xPos = point.plotX + 10 - boxWidth/2; // If want centered position
							let xPos = point.plotX - 10 - boxWidth;
							const yPos = 10;
							// Left Most Boundary
							if (point.plotX < boxWidth - 5) {
								xPos = point.plotX + 15;
							}

							return { x: xPos, y: yPos };
						},
						shape: 'callout'
					},
					series: [
						{
							data: [],
							tooltip: {
								valueDecimals: 2
							},
							dataGrouping: {
								enabled: true
							},
							point: {
								events: {
									mouseOver: this.hovering
								}
							}
						}
					]
				}
			}
		};
	},
	// Takes portfolio data from the store
	computed: {

		...mapState(useUserStore, {
			activePortfolio: (state) => state.activePortfolio,
			payload: (state) => state.data?.payload,
			valueSum: (state) => state.valueSum,
			portfolioLineData: (state) => state.portfolioLineData,
			portfolioLineEquityData: (state) => state.portfolioLineEquityData,
		}),

		daysActive() {
			const start = this.moment(this.activePortfolio?.history_start_date);
			const current = this.moment();
			return this.moment.duration(current.diff(start)).asDays();
		},
		weekActive() {
			return this.portfolioLineData.length > 0 && this.daysActive >= 2;
		},
		month1Active() {
			return this.portfolioLineData.length > 0 && this.daysActive >= 7;
		},
		month3Active() {
			return this.portfolioLineData.length > 0 && this.daysActive >= 30;
		},
		month6Active() {
			return this.portfolioLineData.length > 0 && this.daysActive >= 90;
		},
		year1Active() {
			return this.portfolioLineData.length > 0 && this.daysActive >= 180;
		},
		year5Active() {
			return this.portfolioLineData.length > 0 && this.daysActive >= 365;
		}
	},
	watch: {
		payload(payloadValue) {
			if (this.currentView !== (payloadValue?.portfolio_line_view || 'Value')) {
				this.currentView = payloadValue?.portfolio_line_view || 'Value';
				this.initialize();
			}
		}
	},


	mounted() {
		this.currentView = this.payload?.portfolio_line_view || 'Value';
		EventBus.$off('resetPortfolioLine', this.initialize);
		EventBus.$on('resetPortfolioLine', this.initialize);
		// In case of sparkline
		// Set width and height from props
		// Remove the tooltip
		if (this.sparkline) {
			this.chart.stockOptions.xAxis.labels.enabled = false;
			this.chart.stockOptions.tooltip.enabled = false;
			this.chart.stockOptions.plotOptions.series.states.hover.enabled = false;
		} else {
			try {
				UserService.sendAnalytics({
					event: 'check_portfolio',
					data: {
						timeframe: '1D'
					}
				});
			} catch (err) { }
		}

		setTimeout(() => {
			this.initialize();
		}, 500);
	},
	methods: {
		...mapActions(useStatusStore, {
			'loading': LOADING
		}),
		...mapActions(useUserStore, {
			getPortfolioLineDataDaily: 'getPortfolioLineDataDaily',
			getPortfolioLineEquityDataDaily: 'getPortfolioLineEquityDataDaily',
			updateUserPayload: 'updateUserPayload',
		}),

		// @vuese
		// On hover event, publish the data for parent component
		hovering(e: any) {
			// Update over emitted for Market.vue component
			// @arg Object { live: false, last: e.target.y, first: firstValue }
			this.$emit('updateHover', {
				live: false,
				last: e.target.y,
				first: (this.$refs.chart as any).chart.series[0].processedYData[0]
			});
		},
		// @vuese
		// Call change color function to color the chart after the X axis extremes are set
		// Takes first and last point in the chart and calculates change and change_percent
		// Colors the chart based on change - if negative red, else green
		// Publish the data for parent component
		// Zoom the chart again
		changeColor() {
			const firstValue = (this.$refs.chart as any).chart.series[0].processedYData[0];
			const lastValue = (this.$refs.chart as any).chart.series[0].processedYData[(this.$refs.chart as any).chart.series[0].processedYData.length - 1];
			this.color = ((lastValue - firstValue) / firstValue) * 100 >= 0 ? 'green' : 'red';

			// Change update emitted for Market.vue component
			// @arg Object { chartType:'market', dayRange: 1, first: firstValue }
			this.$emit('updateChange', { last: lastValue, first: firstValue });

			// const ex = (this.$refs.chart as any).chart.yAxis[0].getExtremes();
			// (this.$refs.chart as any).chart.yAxis[0].setExtremes(ex.dataMin * 0.999999, ex.dataMax * 1.000001);
		},

		// @vuese
		// Function for updating the chart live
		async updateLive() {
			const data = JSON.parse(JSON.stringify((this.$refs.chart as any).chart.series[0].options.data));

			if (this.$refs && this.$refs.chart && data && data.length > 0) {
				const type = this.currentView;

				if (type !== 'Equity') {
					const firstValue = (this.$refs.chart as any).chart.series[0].processedYData[0];
					let lastValue =
						parseFloat(this.valueSum.toString()) + (this.activePortfolio?.cash_balance || 0) + Number(this.activePortfolio?.stake_value || 0);

					if (data[data.length - 1][0] < Date.now() - 1000 * 60) {
						data[data.length - 1][0] = Date.now();
					}
					data[data.length - 1][1] = lastValue / 10 ** 18;

					(this.$refs.chart as any).chart.series[0].setData(data, true);

				}

				this.setChartLimits();
			}
		},
		// @vuese
		// Formatting the tooltip on the highcharts component
		formatTooltip(e: any) {
			return '<span style="color:black">' + this.moment(e.chart.hoverPoint.x).format(this.dateFormat) + '</span>';
		},
		// @vuese
		// Set the y axis limits so that the chart doest appear too dramatic on minor changes. Padding is changed by setting he chart extremes
		//   1. if min and max are equal (flat chart) ensure the chart line is centered
		//   2. if total change is < .5% then add 30% padding
		//   3. if total change is < 1% then add 20% padding
		//   4. otherwise add 10% padding to main chart and 5% padding to sparkline
		//
		setChartLimits() {
			const ex = (this.$refs.chart as any).chart.yAxis[0].getExtremes();

			if (Number(ex.dataMax) !== 0) {
				if (ex.dataMax === ex.dataMin || Math.abs(ex.dataMin / ex.dataMax) > 0.99999999999) {
					//   1. if min and max are equal (flat chart) ensure the chart line is centered
					const offset = 1 * Math.pow(10, 15); // 0.001 offset
					(this.$refs.chart as any).chart.yAxis[0].setExtremes(ex.dataMin - offset, ex.dataMax + offset, true);
				} else if (Math.abs(ex.dataMin / ex.dataMax) > 0.995) {
					//   2. if total change is < .5% then add 30% padding
					const offset = (ex.dataMax - ex.dataMin) * 0.3;
					(this.$refs.chart as any).chart.yAxis[0].setExtremes(ex.dataMin - offset, ex.dataMax + offset, true);
				} else if (Math.abs(ex.dataMin / ex.dataMax) > 0.99) {
					//   3. if total change is < 1% then add 20% padding
					const offset = (ex.dataMax - ex.dataMin) * 0.2;
					(this.$refs.chart as any).chart.yAxis[0].setExtremes(ex.dataMin - offset, ex.dataMax + offset, true);
				} else {
					//   4. otherwise add 30% padding to chart
					const offset = (ex.dataMax - ex.dataMin) * 0.3;
					(this.$refs.chart as any).chart.yAxis[0].setExtremes(ex.dataMin, ex.dataMax + offset, true);
				}
			} else {
				if (ex.dataMax === ex.dataMin) {
					//   5. Center 0 value portfolios
					const offset = 1;
					(this.$refs.chart as any).chart.yAxis[0].setExtremes(ex.dataMin - offset, ex.dataMax + offset, true);
				}
			}
		},
		// @vuese
		// Initialize the chart depending on value/equity flag
		async initialize() {
			const type = this.currentView;
			let highchartData = [];


			highchartData = this.portfolioLineData;

			if (this.$refs.chart && highchartData[0]) {
				const startDate = highchartData[highchartData.length - 1][0] - 86000000 * 1;
				const dataFiltered = highchartData.filter((data) => data[0] >= startDate);
				(this.$refs.chart as any).chart.series[0].setData(dataFiltered, true);

				if (this.sparkline) {
					(this.$refs.chart as any).chart.setSize(this.width, this.height, false);
				}
				this.changeColor();

				// Update chart with newest data immediately
				this.updateLive();
			}
			this.initialized = true;
			this.loading(false);
			setTimeout(() => {
				this.redrawChart();
			}, 100);
		},

		redrawChart() {
			if (this.$refs && this.$refs.chart && (this.$refs.chart as any).chart) {
				(this.$refs.chart as any).chart.redraw();
				(this.$refs.chart as any).chart.reflow();
			}
		}
	}
});
</script>

<style>
@media screen and (max-width: 768px) {
	.portfolio-line-container {
		min-height: 0.8vh;
	}
}

/* Disable 3. time tooltip */
.portfolio-line-chart .highcharts-color-none {
	display: none;
}

/* Disable xAxis */
.portfolio-line-chart .highcharts-xaxis {
	display: none;
}

/* Padding from the bottom */
.portfolio-line-chart .highcharts-root {
	overflow: visible;
}

/* Transparent background */
.portfolio-line-chart .highcharts-background {
	fill-opacity: 0;
}

/* Chart bar color */
.portfolio-line-chart .highcharts-color-1 {
	fill: #cdd5ce;
	stroke: #282a28;
}

/* Start dashed line style */
.portfolio-line-chart .highcharts-plot-line {
	fill: none;
	stroke: #00a16f;
	stroke-width: 1px;
	stroke-dasharray: 2px;
}

.portfolio-line-chart .highcharts-range-selector-buttons text {
	fill: #000000;
}

.range-buttons {
	align-items: center;
	margin-top: 24px;
}

@media (min-width: 768px) {
	.range-buttons a {
		margin-right: 20px;
	}
}

.active-range {
	border-radius: 8px;
	background: #eaecf5;
	color: var(--Dark-Grey, #333);

	/* Large Text Bold */
	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: -0.16px;
	flex-shrink: 0;
	width: 42px;
	height: 30px;
	text-align: center;
	align-items: center;
	padding-top: 3px !important;
}

.highcharts-axis-labels {
	font-family: 'Manrope';
	font-weight: 500;
	font-size: 12px;
}

#gradient-greenie stop[offset='0'] {
	stop-opacity: 0.2;
	stop-color: #00c386;
}

#gradient-greenie stop[offset='1'] {
	stop-opacity: 0;
	stop-color: #00c386;
}

.ohlc-chart .chart-green .highcharts-color-0 .highcharts-area,
.portfolio-line-chart .chart-green .highcharts-color-0 .highcharts-area {
	fill-opacity: 1;
	fill: url('#gradient-greenie') !important;
}

.ohlc-chart .chart-green .highcharts-color-0,
.portfolio-line-chart .chart-green .highcharts-color-0 {
	stroke: #00c386;
	fill: #00c386;
}

.ohlc-chart .chart-green .highcharts-crosshair,
.portfolio-line-chart .chart-green .highcharts-crosshair {
	stroke: #00c386;
	stroke-width: 1px;
}

.ohlc-chart .ohlc-chart .chart-green .highcharts-color-0 .highcharts-halo,
.portfolio-line-chart .chart-green .highcharts-color-0 .highcharts-halo {
	fill: #00c386;
	fill-opacity: 1;
	stroke: #fff;
	stroke-width: 2;
}

#gradient-redie stop[offset='0'] {
	stop-opacity: 0.2;
	stop-color: #f32d50;
}

#gradient-redie stop[offset='1'] {
	stop-opacity: 0;
	stop-color: #f32d50;
}

.ohlc-chart .chart-red .highcharts-color-0 .highcharts-area,
.portfolio-line-chart .chart-red .highcharts-color-0 .highcharts-area {
	fill-opacity: 1;
	fill: url('#gradient-redie') !important;
}

.ohlc-chart .chart-red .highcharts-color-0,
.portfolio-line-chart .chart-red .highcharts-color-0 {
	stroke: #f32d50;
	fill: #f32d50;
}

.ohlc-chart .chart-red .highcharts-crosshair,
.portfolio-line-chart .chart-red .highcharts-crosshair {
	stroke: #f32d50;
	stroke-width: 1px;
}

.ohlc-chart .chart-red .highcharts-color-0 .highcharts-halo,
.portfolio-line-chart .chart-red .highcharts-color-0 .highcharts-halo {
	fill: #f32d50;
	fill-opacity: 1;
	stroke: #fff;
	stroke-width: 2;
}

.market_delay {
	width: 50px;
	/*background-color: #ededed;*/
	position: relative;
	margin-bottom: 12px;
	margin-top: 20px;
}

.delay_time {
	position: absolute;
	bottom: 0;
	margin: auto;
	width: 100%;
}

.delay_icon {
	width: 25px;
	height: 25px;
	margin-top: 10px;
}
</style>
