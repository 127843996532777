mutation updateAirdropList($id: String!, $data: AirdropInput!) {
    updateAirdropList(id: $id, data: $data) {
       uuid
        email
        full_name
        date_added
        tokens_earned
        num_referrals
        kyc_status
        kyc_date
        ranking
    }
}
