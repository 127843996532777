<template>
	<div class="watch" :class="{ expandedArea: expandedArea }" @click.prevent="e => watchlistMarket(e, market)"
		@mouseover="hovered = true" @mouseleave="resetWatch">
		<button class="button watch-button transition-fast"
			:title="String(watchlist.includes(Util.formatMarketId(market.type, market.symbol)) ? $t('REMOVE_FROM_WATCHLIST') : $t('ADD_TO_WATCHLIST'))"
			:disabled="disabled" :class="{ 'larger-btn': isMobile }">
			<!-- <transition name="fade"> -->
			<img v-if="hovered && !flipped && watchlist.includes(Util.formatMarketId(market.type, market.symbol))"
				src="@/assets/icons/new/favorite-remove.svg" />
			<img v-else-if="watchlist.includes(Util.formatMarketId(market.type, market.symbol))"
				src="@/assets/icons/new/favorite-filled.svg" />
			<img v-else-if="hovered && !unflipped" src="@/assets/icons/new/favorite-hover.svg" />
			<img v-else src="@/assets/icons/new/favorite.svg" />
			<!-- </transition> -->
		</button>
	</div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import Util from '../services/shared';
import { ERROR, SUCCESS } from '@/store/mutation-types';
//vuex
import { i18n } from '../plugins/i18n';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';

export default defineComponent({
	props: {
		handleWatchlist: {
			type: Function,
			required: true
		},
		watchlist: {
			type: Array,
			default: () => []
		},
		market: {
			type: Object as PropType<any>,
			required: true,
			default: () => {
				return {};
			}
		},
		disabled: {
			type: Boolean,
			default: false
		},
		expandedArea: {
			type: Boolean,
			default: false
		},
		watchSource: {
			type: String
		},
		isMobile: {
			type: Boolean
		}
	},
	data() {
		return {
			hovered: false,
			flipped: false,
			unflipped: false,
			Util
		};
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
			success: SUCCESS
		}),
		watchlistMarket(e: any, market: any) {
			e.stopPropagation();
			market['watchSource'] = this.watchSource;
			this.handleWatchlist(market);
			if (this.watchlist?.includes(Util.formatMarketId(market.type, market.symbol))) {
				this.flipped = false;
				this.unflipped = true;
			} else {
				this.success(i18n.t('ADDED_TO_WATCHLIST'));
				this.flipped = true;
				this.unflipped = false;
			}
			// this.flipped = this.watchlist?.includes(Util.formatMarketId(market.type, market.symbol)) ? false : true;
			// this.unflipped = this.watchlist?.includes(Util.formatMarketId(market.type, market.symbol)) ? true : false;
		},
		resetWatch() {
			this.flipped = false;
			this.hovered = false;
			this.unflipped = false;
		}
	}
});
</script>

<style scoped>
.watch {
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
}

.watch .expandedArea {
	flex: 0 0 auto;
	width: 50px;
	text-align: right;
	padding: 5px;
	position: absolute;
	right: 1rem;
	height: 100%;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button.watch-button {
	color: #ababac;
	font-size: 12px;
	padding: 0;
	border: none;
	background: transparent;
	width: 20px;
	height: 20px;
}

.button.watch-button.filled {
	background: #ababac;
	border: 2px solid #ababac;
}

.button.watch-button.filled .watch-icon {
	color: #fff;
}

.button.watch-button.larger-btn {
	width: 24px;
}

.button.watch-button.larger-btn img {
	width: 24px;
}
</style>
