<template>
	<div class="formcontainer">
		<div class="headerImage">
			<div div class="control">
				<a href="https://www.morpher.com" target="_blank" alt="Morpher Homepage">
					<img src="@/assets/morpher_logo.svg" alt="Morpher Logo" />
				</a>
				<h2 data-cy="logInTitle" class="authTitle">{{ $t('delete.DELETED_HEADING') }}</h2>
				<p data-cy="logInDescription" class="authText">{{ $t('delete.DELETED_INTRO') }}</p>
			</div>
		</div>
		<div class="has-text-left" ref="accordionContainer">
			<b-collapse class="accordionItem" animation="slide" :open="false">
				<template #trigger="props">
					<div class="is-flex is-justify-content-space-between" role="button">
						<h2 class="title is-size-5 has-text-weight-medium has-text-left"
							v-html="$t('delete.DELETED_MORPHER_WALLET_HEADING')"></h2>
						<p class="ml-3">
							<b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
						</p>
					</div>
				</template>
				<div class="accordionWrap">
					<p>{{ $t('delete.DELETED_MORPHER_WALLET') }}</p>
					<p class="mt-10" v-html="$t('delete.DELETED_MORPHER_WALLET_LINK')"></p>
				</div>
			</b-collapse>
			<!-- metamask and fortmatic -->
			<b-collapse class="accordionItem" animation="slide" :open="false">
				<template #trigger="props">
					<div class="is-flex is-justify-content-space-between" role="button">
						<h2 class="title is-size-5 has-text-weight-medium has-text-left "
							v-html="$t('delete.DELETED_METAMASK_WALLET_HEADING')"></h2>
						<p class="ml-3">
							<b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
						</p>
					</div>
				</template>
				<div class="accordionWrap">
					<p v-html="$t('delete.DELETED_METAMASK_WALLET')"></p>
				</div>
			</b-collapse>

			<!-- data-->
			<b-collapse class="accordionItem" animation="slide" :open="false">
				<template #trigger="props">
					<div class="is-flex is-justify-content-space-between" role="button">
						<h2 class="title is-size-5 has-text-weight-medium has-text-left "
							v-html="$t('delete.DELETED_DATA_HEADING')"></h2>
						<p class="ml-3">
							<b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
						</p>
					</div>
				</template>
				<div class="accordionWrap">
					<p v-html="$t('delete.DELETED_DATA')"></p>
					<p class="mt-10" v-html="$t('delete.DELETED_DATA_EMAIL')"></p>
				</div>
			</b-collapse>
		</div>

		<!-- Switch to Register -->
		<p class="loginLink">
			<span>{{ $t('auth.ALREADY_HAVE_ACCOUNT') }}&nbsp;</span>
			<router-link to="/login" class="login-router transition-faster">
				<span data-cy="signUpButton">
					{{ $t('auth.LOGIN') }}
				</span>
			</router-link>
		</p>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

//vuex

/* @group Components_Auth */
/*
  <h4> <b>Account Deleted Page</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'AccountDeleted',
	data() {
		return {};
	},
	computed: {

	},
	async mounted() { },
	methods: {

		// Submits login request
		async formAction() { }
	}
});
</script>
<style scoped>
.formcontainer {
	display: flex;
	flex-direction: column;
	margin: auto auto;
	width: 360px;
}

.authTitle {
	font-size: 28px;
	font-weight: 700;
	line-height: 38px;
	margin-top: 25px;
	color: #333333;
}

.headerImage {
	width: 100%;
}

.accordionWrap {
	margin-top: 16px;
}

.authText {
	font-weight: 400;
	color: #686475;
	margin-top: 12px;
	font-size: 16px;
}

.accordionItem {
	margin-top: 16px;
	padding: 20px;
	background: #ffffff;
	border: 1px solid #eaecf0;
	border-radius: 8px;
}

.is-size-5 {
	margin-bottom: 0px !important;
	font-size: 16px !important;
}

.button-img-primary {
	height: 28px;
	padding-right: 15px;
}

.button-img-alt {
	position: absolute;
	right: calc(50% + 70px);
}

.alt-button {
	padding-left: 46px;
}

.login-help {
	margin-top: -0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}



.settings-link {
	line-height: 1rem;
	cursor: default;
}

@media only screen and (max-width: 400px) {
	.button-img-alt {
		display: none;
	}

	.alt-button {
		padding-left: initial;
	}
}

.spaced {
	margin-right: 7px;
}
</style>
