<template>
	<div id="defaultLayout" ref="screen">
		<!-- <HeaderNotificationModal v-if="modals.showHeaderNotificationModal" /> -->
		<div v-if="$route.name === 'TradingView' || $route.name === 'AppLogin'">
			<RouterView />
		</div>

		<div v-else>
			<!-- Desktop Layout (screenWidth >= 1200) -->
			<Header v-if="screenWidth >= 1200"></Header>
			<TabletHeader v-else-if="screenWidth >= 768" />
			<MobileHeader v-else />

			<div class="desktop-container">
				<div ref="dashboardContainer" :class="{
					'dashboard-container-desktop': screenWidth >= 1200
					, 'dashboard-container': screenWidth < 1200
				}">
					<div :class="{
						'app-view-desktop': screenWidth >= 1200
						, 'app-view': screenWidth < 1200
					}">
						<RouterView />
					</div>
				</div>
			</div>

		</div>

		<!-- Modals -->
		<!-- Help Modal for Spreads / Margin Interest -->
		<b-modal v-model:modelValue="modals.supportModal.active" style="height: 100%">
			<iframe :src="modals.supportModal.link" style="width: 100%; height: 95%"></iframe>
		</b-modal>
		<UpgradeTierModal v-if="modals.showUpgradeTierModal" />
		<DepositWithdrawModal v-if="modals.showDepositWithdrawModal" />
		<NetworkMetamaskModal v-if="modals.showNetworkMetamaskModal" />
		<PortfolioInUseModal v-if="modals.showPortfolioInUseModal" />
		<OnboardModal v-if="modals.showOnboardModal" />
		<KYCStatusModal v-if="modals.showKYCStatusModal" />
		<KYCCaptureModal v-if="modals.showKYCCaptureModal" />
		<ETHBalanceModal v-if="modals.showETHBalanceModal" />
		<FortmaticOverlayModal />
		<ClaimAirdropModal v-if="modals.showClaimAirdropModal" />
		<ResetMetamaskModal v-if="modals.showResetMetamaskModal" />
		<MainchainTransferModal v-if="modals.showMainchainTransferModal" />
		<!-- <NotificationModal v-if="modals.showNotificationModal" /> -->
		<NotificationRewardModal v-if="modals.showNotificationRewardModal" />
		<Tier3Modal v-if="modals.showTier3Modal" />
		<Tier4Modal v-if="modals.showTier4Modal" />
		<BrowserAlertsModal v-if="modals.showBrowserAlertsModal" />
		<BraveAlertsModal v-if="modals.showBraveAlertsModal" />
		<AddMPHMetamaskModal v-if="modals.showAddMPHMetamaskModal" />
		<TrustPilotModal v-if="modals.showTrustPilotModal" />
		<FAQModal v-if="modals.showFAQModal" />
		<MobileAppModal v-if="modals.showMobileAppModal" />
		<MPHRewardModal v-if="modals.showMPHRewardModal" />
		<UserInterviewModal v-if="modals.showInterviewModal" />
		<AISidebar v-if="modals.showAIInsightsModal !== ''" />
		<UnlockWalletModal v-if="modals.showUnlockWalletModal" />

		<div id="typeform-trade"></div>
	</div>

</template>

<script lang="ts">
/* eslint-disable no-undef */
import { useNotificationsStore } from '@/store/modules/notifications';
import { useAdminStore } from '@/store/modules/admin';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { useContractStore } from '@/store/modules/contract';
import { useMarketsStore } from '@/store/modules/markets';
import Header from '@/components/Header.vue';
import MobileHeader from '@/components/MobileHeader.vue';
import TabletHeader from '@/components/TabletHeader.vue';
import DesktopMenu from '@/components/DesktopMenu.vue';
// //vuex
import { MARKETS, LOADING } from '@/store/mutation-types';
import { NotificationProgrammatic as Notification } from 'buefy';

import PortfolioInUseModal from '@/components/modals/PortfolioInUseModal.vue';
import OnboardModal from '@/components/modals/OnboardModal.vue';
import ClaimAirdropModal from '@/components/modals/ClaimAirdropModal.vue';
import WalletRecoveryModal from '@/components/modals/WalletRecoveryModal.vue';
import WalletMigrationModal from '@/components/modals/WalletMigrationModal.vue';
import UnlockWalletModal from '@/components/modals/UnlockWalletModal.vue';

import ResetMetamaskModal from '@/components/modals/ResetMetamaskModal.vue';
import MainchainTransferModal from '@/components/modals/MainchainTransferModal.vue';
//import NotificationModal from '@/components/modals/NotificationModal_Elections_Showcase.vue';
import Tier3Modal from '@/components/modals/NotificationModal_Tier3.vue';
import Tier4Modal from '@/components/modals/NotificationModal_Tier4.vue';
import MobileAppModal from '@/components/modals/MobileAppModal.vue';
import MPHRewardModal from '@/components/modals/MPHRewardModal.vue';
import UserInterviewModal from '@/components/modals/UserInterviewModal.vue';
import NotificationRewardModal from '@/components/modals/NotificationModal_New_Reward.vue';
import KYCStatusModal from '@/components/modals/KYCStatusModal.vue';
import AISidebar from '@/components/modals/AISidebar.vue';
import KYCCaptureModal from '@/components/modals/KYCCaptureModal.vue';
import NetworkMetamaskModal from '@/components/modals/NetworkMetamaskModal.vue';
import ETHBalanceModal from '@/components/modals/ETHBalanceModal.vue';
import FortmaticOverlayModal from '@/components/modals/FortmaticOverlayModal.vue';
import DepositWithdrawModal from '@/components/modals/DepositWithdrawModal.vue';
import UpgradeTierModal from '@/components/modals/UpgradeTierModal.vue';
import HeaderNotificationModal from '@/components/modals/HeaderNotificationModal.vue';
import BrowserAlertsModal from '@/components/modals/BrowserAlertsModal.vue';
import BraveAlertsModal from '@/components/modals/BraveAlertsModal.vue';
import AddMPHMetamaskModal from '@/components/modals/AddMPHMetamaskModal.vue';
import TrustPilotModal from '@/components/modals/TrustPilotModal.vue';
import FAQModal from '@/components/modals/FAQModal.vue';
import { createPopover } from '@typeform/embed';
import '@typeform/embed/build/css/popover.css';
import { UserService } from '../services/UserService';
import { TransactionService } from '../services/TransactionService';
import { MarketService } from '../services/MarketService';
import Highcharts from 'highcharts';
import Cookie from 'js-cookie';
import momen from 'moment';
import Util from '../services/shared';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { RouterLink, RouterView } from 'vue-router'

/* @group Components_Layout */
/*
  <h4> <b> Default Application Layout </b> </h4>
  <br>
  <br> Implements:
  <br> - Header Component
  <br> - Router Views as slot - /views folder components
  <br> - Shows message "Not optimized for small screens" in case window size < 1024 pixels
  <br>
  <br> Functionality:
  <br> - Application initialization:
  <br>    -> Refresh Token auth to get user data
  <br>    -> Get leaderboard portfolios
  <br>    -> Get all markets

  <br> - Refresh Interval - Initialization method called every 10 minutes
*/
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'DefaultLayout',
	components: {
		Header,
		DesktopMenu,
		TabletHeader,
		MobileHeader,
		PortfolioInUseModal,
		OnboardModal,
		ClaimAirdropModal,
		WalletRecoveryModal,
		WalletMigrationModal,
		UnlockWalletModal,
		ResetMetamaskModal,
		MainchainTransferModal,
		// NotificationModal,
		NotificationRewardModal,
		Tier3Modal,
		Tier4Modal,
		KYCStatusModal,
		KYCCaptureModal,
		NetworkMetamaskModal,
		ETHBalanceModal,
		FortmaticOverlayModal,
		DepositWithdrawModal,
		UpgradeTierModal,
		HeaderNotificationModal,
		BrowserAlertsModal,
		BraveAlertsModal,
		AddMPHMetamaskModal,
		TrustPilotModal,
		FAQModal,
		MobileAppModal,
		MPHRewardModal,
		UserInterviewModal,
		AISidebar,

	},
	data() {
		return {
			screenWidth: 0,
			metamaskStarted: false,
			metamaskPopup: false,
			refreshInterval: null as NodeJS.Timeout | null,
			checkLoginMetamask: null as NodeJS.Timeout | null,
			mobileMenuActive: false,
			surveyStarted: false,
			browserLocale: (navigator.languages && navigator.languages[0]) || navigator.language || '',
			purchase_update_subscription: null as any,
			purchase_update_eth_address: null as string | null,
			market_status_subscription: null as any,
		};
	},
	computed: {
		...mapState(useContractStore, {
			isLoggedInMetamask: (state) => state.isLoggedInMetamask,
			morpherAlpha: (state) => state.morpherAlpha,
			walletType: (state) => state.walletType,
		}),
		...mapState(useAdminStore, {
			config: (state) => state.config,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useMarketsStore, {
			markets: (state) => state.data,
		}),
		...mapState(useModalsStore, {
			showHeaderNotificationModal: (state) => state.showHeaderNotificationModal,
			anyModalOpen: (state) => state.anyModalOpen,
			modals: (state) => state,
		}),
		...mapState(useNotificationsStore, {
			notifications: (state) => state,
		}),

	},
	watch: {
		isLoggedInMetamask(nv, ov) {
			if (ov && !nv) {
				this.logoutWallet();
			}
		},
		user(nv) {
			this.formatMarkets(nv?.role || 'user');
			if (nv && nv.id && nv.email) {
				this.startAnalytics();

				if (nv?.payload?.new_user !== true && (Number(nv.timestamp || 0)) < Date.now() * 1000 * 60 * 10) {
					setTimeout(() => {
						this.checkSurvey();
					}, 5000)
				}
			}

			if (nv && nv.affiliate && nv.affiliate.length > 0) {
				this.updateMissedAffiliates();
			}
			this.subscribePurchase();
		},
		showHeaderNotificationModal() {
			this.handleNotificationSize();
		},
	},
	async created() {
		window.addEventListener('resize', this.handleResize);

		this.initialize();
		this.refreshInterval = setInterval(async () => {
			await this.initialize(true);
		}, 1000 * 60 * 15);
	},
	beforeMount() {
		this.screenWidth =
			window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
	},
	mounted() {
		this.startAnalytics();

		this.screenWidth = (this.$refs.screen as any)?.clientWidth;

		// Check if Redis has global message to show
		if (this.config.show_global_message && import.meta.env.VITE_MODE === 'production') {
			let showMessage = true;
			const lastSeen = localStorage.getItem('info_msg_seen');
			// Check if seen less than 3 days ago
			if (lastSeen !== null) {
				if (Date.now() - parseInt(lastSeen) < 259200000) {
					showMessage = false;
				}
			}
			if (showMessage) {
				Notification.open({
					indefinite: true,
					message: this.config.global_message || '',
					position: 'is-top-right',
					hasIcon: true,
					type: 'is-info'
				});
				localStorage.setItem('info_msg_seen', Date.now().toString());
			}
		}
		// Make chat dissappear if user is finished with it
		if (window.$crisp && window.$crisp.is && window.$crisp.is('chat:closed')) this.hideChat();

		setTimeout(() => {
			this.handleResize();
		}, 10);

		if (this.user && this.user?.payload?.new_user !== true && (Number(this.user?.timestamp || 0)) < Date.now() * 1000 * 60 * 10) {
			setTimeout(() => {
				this.checkSurvey();
			}, 5000)
		}
		


	},
	updated() {
		// Header Notification
		this.handleNotificationSize();
	},
	unmounted() {
		if (this.refreshInterval) clearInterval(this.refreshInterval);
		if (this.checkLoginMetamask) clearInterval(this.checkLoginMetamask);
		if (this.market_status_subscription) {
			MarketService.unsubscribeFromMarketStatus(this.market_status_subscription);
			this.market_status_subscription = null;
		}
	},
	methods: {
		// ...mapActions(useStatusStore, {
		// 	loading: LOADING

		// }),
		...mapActions(useMarketsStore, {
			formatMarkets: 'format'

		}),
		...mapActions(useMarketsStore, {
			getMarketsByIds: 'getMarketsByIds',
		}),
		...mapActions(useContractStore, {
			toggleIsLoggedInMetamask: 'toggleIsLoggedInMetamask',
			logoutWallet: 'logoutWallet',
			startWallet: 'startWallet',
		}),
		...mapActions(useModalsStore, {
			toggleAllDoneModal: 'toggleAllDoneModal',
			toggleNotificationModal: 'toggleNotificationModal',
			toggleRewardNotificationModal: 'toggleRewardNotificationModal',
			toggleHeaderFirebaseModal: 'toggleHeaderFirebaseModal',
			toggleMobileAppModal: 'toggleMobileAppModal',
			toggleMPHRewardModal: 'toggleMPHRewardModal',
			toggleInterviewModal: 'toggleInterviewModal',
			toggleTrustPilotModal: 'toggleTrustPilotModal',
			toggleTier3Modal: 'toggleTier3Modal',
			toggleTier4Modal: 'toggleTier4Modal',
			toggleWalletMigrationModal: 'toggleWalletMigrationModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),


		handleResize() {
			if (this.$refs.screen) this.screenWidth = (this.$refs.screen as any)?.clientWidth;
			this.handleNotificationSize();
		},
		handleNotificationSize() {
			if (this.showHeaderNotificationModal) {
				if (!document.getElementById('header-notification')) return;

				const height = document.getElementById('header-notification')?.clientHeight || 0;

				// Shift Down
				const toolbar = document.getElementById('mainToolbar');
				if (toolbar) {
					toolbar.style.marginTop = height + 20 + 'px';
				}
				const layout = document.getElementById('defaultLayout');
				const menu = document.getElementById('desktopMenu');
				if (layout) {
					layout.style.marginTop = height + 'px';
				}

				if (menu) {
					menu.style.height = 'calc(100vh - ' + height + 'px)';
				}
			} else {
				// Shift Back Up
				const toolbar = document.getElementById('mainToolbar');
				if (toolbar) {
					toolbar.style.marginTop = '0px';
				}
				const layout = document.getElementById('defaultLayout');
				const menu = document.getElementById('desktopMenu');
				if (layout) {
					layout.style.marginTop = '0px';
				}

				if (menu) {
					menu.style.height = '100%';
				}
			}


		},
		subscribeMarketStatus() {
			if (!this.user || !this.user.activePortfolio || !this.user.activePortfolio.eth_address) {
				if (this.market_status_subscription) {
					MarketService.unsubscribeFromMarketStatus(this.market_status_subscription);
					this.market_status_subscription = null;
				}
			} else {

				if (!this.market_status_subscription) {
					this.market_status_subscription = MarketService.subscribeToMarketStatus(
						this.user.activePortfolio.eth_address,
						async data => {
							if (data?.data?.updateMarketStatus?.changedMarkets) {
								let symbol = this.$route?.params?.symbol;
								let type = this.$route?.params?.type;
								let market_list = data?.data?.updateMarketStatus?.changedMarkets

								if (symbol) {
									if (!type) {
										type = 'unique;'
									}
									const market_id = Util.formatMarketId((this.$route.params.type.toString() || 'unique'), this.$route.params.symbol.toString());

									if (market_list.includes(market_id)) {

										this.getMarketsByIds({
											variables: {
												ids: [market_id],
												minutely: true,

											},
											commitType: MARKETS.SELECTED,
											component: this,

										});
									}
								}

							}
						}
					);

				}
			}

		},
		subscribePurchase() {
			if (!this.user || !this.user.activePortfolio || !this.user.activePortfolio.eth_address) {
				if (this.purchase_update_subscription || this.purchase_update_eth_address) {
					if (this.purchase_update_subscription) {
						this.purchase_update_subscription.unsubscribe();
					}
					this.purchase_update_eth_address = null;
				}
			} else {
				if (this.user.activePortfolio.eth_address !== this.purchase_update_eth_address) {
					if (this.purchase_update_subscription) {
						this.purchase_update_subscription.unsubscribe();
					}
					this.purchase_update_eth_address = null;
				}
				if (!this.purchase_update_eth_address) {
					this.purchase_update_subscription = TransactionService.subscribePurchaseUpdate(
						this.user.activePortfolio.eth_address,
						async (data: any) => {
							data = data.data?.updateTransaction;
							if (data.transaction_id && data.status && data.status == 'success') {
								this.updateAffiliate(data.transaction_id);
							}
						}
					);

					this.purchase_update_eth_address = this.user.activePortfolio.eth_address;
				}
			}
		},
		notificationSeen() {
			localStorage.setItem('notification_seen', Date.now().toString());
		},
		async updateMissedAffiliates() {
			if (this.user?.affiliate && this.user.affiliate.length > 0) {
				const transaction_id = this.user.affiliate.pop();
				await this.updateAffiliate(transaction_id);
				if (this.user.affiliate.length > 0) {
					setTimeout(() => {
						this.updateMissedAffiliates();
					}, 1000);
				}
			}
		},
		// @vuese
		// Initialize functions fires important functions:
		// - Refresh Token, Fetching All Markets from Redis, Fetch Leaderboard, Start Analytics and Metamask Interval
		async initialize(reload = false) {
			// If user doesn't exists -> fire token auth
			if (!this.user || !this.user.role || reload) {
				if (this.formAction) await this.formAction();
			} else {
				this.formatMarkets(this.user.role);

				if (this.user && this.user.id && this.user.email) this.startAnalytics();

				if (this.user && this.user.affiliate && this.user.affiliate.length > 0) {
					this.updateMissedAffiliates();
				}
			}

			// If there are no market in the store -> fetch all
			if (this.markets.length === 0 || reload) {
				this.getMarketsByIds({
					variables: {
						ids: [],
						minutely: false
					},
					commitType: MARKETS.ALL,
					format: true,
					sort: true,
					component: this
				}).then(() => {
					if (this.user && this.user.role) this.formatMarkets(this.user.role);
				});
			}
			this.subscribePurchase();
			this.subscribeMarketStatus();
		},
		async checkSurvey() {
			if (!this.user) {
				return;
			}

			let feedback = localStorage.getItem('feedback_date')

			if (this.user && this.user.request_review && (!feedback || Number(feedback) < Date.now() - (1000 * 60 * 60 * 24))) {
				window.setTimeout(() => {
					this.toggleTrustPilotModal(true);
					localStorage.setItem('review_date', String(Date.now()))
				}, 2000);
			} else {
				let showNotification = false;
				// Notification Popup
				if (!this.modals.anyModalOpen) {
					showNotification = true;

					const lastSeen = localStorage.getItem('notification_seen');



					// only show the notification if the user has not see it before
					if (
						(this.user.payload.notification_seen && this.user.payload.notification_seen == 'elections') ||
						lastSeen == 'elections'
					)
						showNotification = false;

					if (showNotification && !this.modals.showOnboardModal) {
						//this.toggleNotificationModal(true);
						//setTimeout(this.notificationSeen, 10000);
					}
				}

				// if (!showNotification) {
				// 	const country = this.user.ip_country || this.user.document_country;

				// 	if (this.user.payload.advSurvey === true && this.user.access_level.tier < 3) {
				// 		this.updateUserPayload({ payloadOption: 'advSurvey', payloadValue: 'false' });

				// 		const { open } = createPopover('HlHzkXkL', {
				// 			container: document.getElementById('typeform-trade'),
				// 			chat: true,
				// 			hideHeaders: true,
				// 			opacity: 100,
				// 			iframeProps: { title: 'Advcash Dropoff' },
				// 			transitiveSearchParams: true,
				// 			disableScroll: true,
				// 			autoResize: true,
				// 			medium: 'snippet',
				// 			hidden: {
				// 				eth_address: this.user.eth_address,
				// 				country
				// 			}
				// 		});

				// 		open();
				// 	}
				// }

				// reset the
				const currentLocale = Cookie.get('locale');

				this.$i18n.locale = currentLocale || 'en';


				localStorage.setItem('locale', this.$i18n.locale || 'en');
				const months = JSON.parse(this.$t('chart-months'));
				const weekdays = JSON.parse(this.$t('chart-weekdays'));
				const shortMonths = JSON.parse(this.$t('chart-shortMonths'));

				Highcharts.setOptions({
					lang: { months, weekdays, shortMonths }
				});

				if (
					!showNotification &&
					!this.modals.anyModalOpen &&
					this.user?.airdrop?.available_claims &&
					this.user?.airdrop?.available_claims > 0 &&
					(this.user.payload.newReward == undefined || this.user.payload.newReward == true)
				) {
					this.toggleRewardNotificationModal(true);
				}


				const tier3Seen = this.user.payload.notification_tier3;
				if (this.user?.access_level?.tier == 3 && !tier3Seen && !this.modals.anyModalOpen) {
					this.toggleTier3Modal(true);
				}
				const tier4Seen = this.user.payload.notification_tier4;
				if (this.user?.access_level?.tier == 4 && !tier4Seen && !this.modals.anyModalOpen) {
					this.toggleTier4Modal(true);
				}

				// Mobile App Notification - hidden while showcasing sneakers
				// if (this.walletType == 'morpherwallet' && !this.anyModalOpen) {
				// 	let appNotification = true;

				// 	const lastSeen = localStorage.getItem('app_notification_seen');

				// 	if (lastSeen && Number(lastSeen) >= Date.now() - 1000 * 60 * 60 * 24 * 7) appNotification = false;

				// 	if (appNotification) {
				// 		localStorage.setItem('app_notification_seen', Date.now().toString());
				// 		this.toggleMobileAppModal(true);
				// 	}
				// }

				if (this.user.payload.mph_reward && !this.modals.anyModalOpen) {
					this.toggleMPHRewardModal(true);
				}
				if (this.user.payload.invite_to_survey && !this.modals.anyModalOpen) {
					const lastSeen = localStorage.getItem('survey_seen');
					if (!lastSeen || (lastSeen && Number(lastSeen) <= Date.now() - 1000 * 60 * 60 * 24 * 7)) {
						this.toggleInterviewModal(true);
					}
				}

				if (!showNotification &&
					!this.modals.anyModalOpen &&
					!this.user.payload.migration_started &&
					(this.walletType == 'fortmatic' ||
						this.walletType == 'portis')) {

					const lastSeen = localStorage.getItem('migrate_notification');

					if (!lastSeen || (lastSeen && Number(lastSeen) <= Date.now() - 1000 * 60 * 60 * 24 * 7)) {
						this.toggleWalletMigrationModal(true);
					}
				} else if (this.user.payload.migration_started) {
					this.$router.push('/migrate').catch(() => { });
				}

				momen.locale(this.$i18n.locale || 'en')
			}
		},
		formAction() { },
	}
});
</script>
<style scoped>
.desktop-container {
	display: flex;
	min-height: 100vh;
	padding-top: 0 !important;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;

}

.app-view-desktop {

	max-width: 1070px;
	margin-left: auto;
	margin-right: auto;

}


.dashboard-container {
	padding-top: 70px;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
}

.notification-offset-def {
	margin-top: 15px;
}

.lang-notify {
	padding: 10px;
	font-size: 13px;
	background: #fff;
}

@media screen and (min-width: 769px) {
	.dashboard-container {
		padding-left: 40px;
		width: 100%;
		padding-right: 40px;
	}
}

@media screen and (max-width: 768px) {
	.dashboard-container {
		padding-right: 20px;
		padding-left: 20px;
		padding-top: 70px;
	}
}

.dashboard-container-desktop {
	width: 100%;
	padding-top: 80px;

}


.hidden-desktop {
	display: none;
}
</style>