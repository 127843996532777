<template>
  <div id="app">
    <!-- <vue-topprogress ref="topProgress" color="#00c386" :trickle-speed="200" /> -->
    <MobileAppBanner v-if="
      $route.name &&
      ($route.name == 'Login' || $route.name == 'Register' || $route.name == 'Unlock' || $route.name == 'RegisterInvited')
    " />


    <DefaultLayout v-if="
      initialized &&
      $route.name &&
      $route.name !== 'Reset' &&
      $route.name !== 'Login' &&
      $route.name !== 'Register' &&
      $route.name !== 'Unlock' &&
      $route.name !== 'RegisterInvited'
    ">
    </DefaultLayout>

    <Auth v-else-if="initialized && $route.name" :auth-type="$route.name.toLowerCase()" />
  </div>
</template>

<script lang="ts">
import DefaultLayout from './layout/DefaultLayout.vue'
import { getStore } from './store';
import Auth from '@/views/Auth.vue';
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia';
import { useAdminStore } from './store/modules/admin';
import { useStatusStore } from './store/modules/status';
import { useUserStore } from './store/modules/user';
import { asyncForEach, emailClick, refreshTokenName } from './helpers/utils';
import eventBus from './store/event-bus';
import { ERROR, SUCCESS } from './store/mutation-types';
import { UserService } from './services/UserService';
import MobileAppBanner from '@/components/app/MobileAppBanner.vue'
export default defineComponent({
  // type inference enabled
  components: {
    MobileAppBanner,
    //vueTopprogress,
    DefaultLayout,
    Auth
  },
  data() {
    return {
      initialized: false,
      refreshInterval: null as (any),
      previous_page: null
    };
  },
  computed: {
    ...mapState(useAdminStore, {
      config: state => state.config,
    }),
    ...mapState(useStatusStore, {
      loading: state => state.loading,
    }),
    ...mapState(useUserStore, {
      user: state => state.data,
      impersonating: state => state.impersonating
    })
  },
  watch: {
    loading(nv) {
      // if (nv) this.$refs.topProgress.start();
      // else this.$refs.topProgress.done();
    },
    '$route.query'(nv) {


      const mclid = nv.mclid;

      if (mclid) {
        emailClick(mclid)
      }

      const utm_source = nv.utm_source;
      const utm_medium = nv.utm_medium;
      const utm_campaign = nv.utm_campaign;
      const utm_term = nv.utm_term;
      const utm_content = nv.utm_content;
      const at_gd = nv.at_gd;
      const candlesticks = nv.candlesticks;
      const affiliate_language = nv.affiliate_language;
      const affiliate_browser_language = nv.affiliate_browser_language;

      if (candlesticks) {
        localStorage.setItem('candlesticks', candlesticks || '');
      }


      if (utm_source || utm_medium || utm_campaign || utm_term || utm_content || at_gd) {
        localStorage.setItem('utm_source', utm_source || '');
        localStorage.setItem('utm_medium', utm_medium || '');
        localStorage.setItem('utm_campaign', utm_campaign || '');
        localStorage.setItem('utm_term', utm_term || '');
        localStorage.setItem('utm_content', utm_content || '');
        localStorage.setItem('at_gd', at_gd || '');
      }

      if (affiliate_language) {
        localStorage.setItem("affiliate_language", affiliate_language)
      } else if (!localStorage.getItem("affiliate_language")) {
        if (this.$i18n?.locale?.toUpperCase()) {
          localStorage.setItem("affiliate_language", this.$i18n.locale.toUpperCase())
        }
      }

      if (affiliate_browser_language) {
        localStorage.setItem("affiliate_browser_language", affiliate_browser_language || "");
      } else if (!localStorage.getItem("affiliate_browser_language")) {
        const browser_language = navigator.language || (navigator as any).userLanguage; //no ?s necessary
        if (browser_language) {
          localStorage.setItem("affiliate_browser_language", browser_language || "");
        }
      }

      var referrer = document.referrer;
      if (referrer) {
        localStorage.setItem('utm_referrer', referrer);
      }

      if (nv.ref) {
        const ref = localStorage.getItem('ref');
        if (!ref || ref.toLowerCase() !== nv.ref.toLowerCase()) {
          localStorage.setItem('ref', nv.ref);
          if (nv.tap_s) localStorage.setItem('source_id', nv.tap_s);
          localStorage.setItem('affiliate_referrer', document.referrer || '');
        }
      }

      if (this.$route.query.aff_id) {
        const affiliate_id = localStorage.getItem('affiliate_id');
        if (!affiliate_id || affiliate_id.toLowerCase() !== nv.aff_id.toLowerCase()) {
          localStorage.setItem('affiliate_id', nv.aff_id);
          localStorage.setItem('affiliate_campaign', nv.campaign || '');
          localStorage.setItem('affiliate_referrer', document.referrer || '');
          localStorage.setItem('affiliate_utm_source', utm_source || '');
          localStorage.setItem('affiliate_utm_medium', utm_medium || '');
          localStorage.setItem('affiliate_utm_campaign', utm_campaign || '');
          localStorage.setItem('affiliate_path', this.$route.path);
        }
      }
      if (this.$route.query.at_gd) {
        const at_gd = localStorage.getItem('at_gd');
        if (!at_gd || at_gd.toLowerCase() !== nv.at_gd.toLowerCase()) {
          localStorage.setItem('affiliate_id', nv.aff_id);
          localStorage.setItem('affiliate_campaign', nv.campaign || '');
          localStorage.setItem('affiliate_referrer', document.referrer || '');
          localStorage.setItem('affiliate_utm_source', utm_source || '');
          localStorage.setItem('affiliate_utm_medium', utm_medium || '');
          localStorage.setItem('affiliate_utm_campaign', utm_campaign || '');
          localStorage.setItem('at_gd', at_gd || '');
          localStorage.setItem('affiliate_path', this.$route.path);
        }
      }
    }
  },
  async mounted() {
    //if (this.loading) this.$refs.topProgress.start();
    this.getConfig();
    this.initialize();
    if (this.refreshInterval) clearInterval(this.refreshInterval);
    this.refreshInterval = setInterval(async () => {
      await this.refresh();
    }, 1000 * 60 * 7);

    eventBus.$off('refreshToken', this.refresh);
    eventBus.$on('refreshToken', this.refresh);

    if (this.$route.query) {
      const mclid = this.$route.query.mclid;

      if (mclid) {
        emailClick(String(mclid))
      }

      const utm_source = this.$route.query.utm_source ? this.$route.query.utm_source.toString() : '';
      const utm_medium = this.$route.query.utm_medium ? this.$route.query.utm_medium.toString() : '';
      const utm_campaign = this.$route.query.utm_campaign ? this.$route.query.utm_campaign.toString() : '';
      const utm_term = this.$route.query.utm_term ? this.$route.query.utm_term.toString() : '';
      const utm_content = this.$route.query.utm_content ? this.$route.query.utm_content.toString() : '';
      const at_gd = this.$route.query.at_gd ? this.$route.query.at_gd.toString() : '';
      const candlesticks = this.$route.query.candlesticks ? this.$route.query.candlesticks.toString() : '';
      const affiliate_language = this.$route.query?.affiliate_language?.toString();
      const affiliate_browser_language = this.$route.query?.affiliate_browser_language?.toString();
      if (affiliate_language) {
        localStorage.setItem("affiliate_language", affiliate_language)
      } else if (!localStorage.getItem("affiliate_language")) {
        if (this.$i18n?.locale?.toUpperCase()) {
          localStorage.setItem("affiliate_language", this.$i18n.locale.toUpperCase())
        }
      }

      if (affiliate_browser_language) {
        localStorage.setItem("affiliate_browser_language", affiliate_browser_language || "");
      } else if (!localStorage.getItem("affiliate_browser_language")) {
        const browser_language = navigator.language || (navigator as any).userLanguage; //no ?s necessary
        if (browser_language) {
          localStorage.setItem("affiliate_browser_language", browser_language || "");
        }
      }

      if (candlesticks) {
        localStorage.setItem('candlesticks', candlesticks || '');
      }

      if (utm_source || utm_medium || utm_campaign || utm_term || utm_content || at_gd) {
        localStorage.setItem('utm_source', utm_source || '');
        localStorage.setItem('utm_medium', utm_medium || '');
        localStorage.setItem('utm_campaign', utm_campaign || '');
        localStorage.setItem('utm_term', utm_term || '');
        localStorage.setItem('utm_content', utm_content || '');
        localStorage.setItem('at_gd', at_gd || '');
      }

      var referrer = document.referrer;
      if (referrer) {
        localStorage.setItem('utm_referrer', referrer);
      }

      if (this.$route.query.aff_id) {
        const affiliate_id = localStorage.getItem('affiliate_id');
        if (!affiliate_id || affiliate_id.toLowerCase() !== this.$route.query.aff_id.toString()) {
          localStorage.setItem('affiliate_id', this.$route.query.aff_id.toString().toLowerCase());
          localStorage.setItem('affiliate_campaign', this.$route.query.campaign ? this.$route.query.campaign.toString() : '');
          localStorage.setItem('affiliate_referrer', document.referrer || '');
          localStorage.setItem('affiliate_utm_source', utm_source || '');
          localStorage.setItem('affiliate_utm_medium', utm_medium || '');
          localStorage.setItem('affiliate_utm_campaign', utm_campaign || '');
          localStorage.setItem('affiliate_path', this.$route.path);
        }
      }
      if (this.$route.query.at_gd) {
        const at_gd = localStorage.getItem('at_gd');
        if (!at_gd || at_gd.toLowerCase() !== this.$route.query.at_gd.toString().toLowerCase()) {
          localStorage.setItem('affiliate_campaign', this.$route.query.campaign ? this.$route.query.campaign.toString() : '');
          localStorage.setItem('affiliate_referrer', document.referrer || '');
          localStorage.setItem('affiliate_utm_source', utm_source || '');
          localStorage.setItem('affiliate_utm_medium', utm_medium || '');
          localStorage.setItem('affiliate_utm_campaign', utm_campaign || '');
          localStorage.setItem('at_gd', this.$route.query.at_gd.toString().toLowerCase() || '');
          localStorage.setItem('affiliate_path', this.$route.path);
        }
      }

      if (this.$route.query.ref) {
        const ref = localStorage.getItem('ref');
        if (!ref || ref.toLowerCase() !== this.$route.query.ref.toString().toLowerCase()) {
          localStorage.setItem('ref', this.$route.query.ref.toString());
          if (this.$route.query.tap_s) localStorage.setItem('source_id', this.$route.query.tap_s.toString());
          localStorage.setItem('affiliate_referrer', document.referrer || '');
        }
      }

      //posthog disabled - uncomment to enable
      //this.initPostHog();
    }
    this.startAnalytics();
  },
  methods: {
    ...mapActions(useStatusStore, {
      error: ERROR,
      success: SUCCESS
    }),
    ...mapActions(useUserStore, {
      logout: 'logout',
      loginToken: 'loginToken',
      refreshToken: 'refreshToken',
      setDeviceId: 'setDeviceId'

    }),
    ...mapActions(useAdminStore, {
      getConfig: 'getConfig',
    }),
    async initPostHog() {
				if (import.meta.env.VITE_POSTHOG_API_KEY) {
					
          if (!document.getElementById('script_posthog') && !window.posthog) {

            const scriptHTML = `!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.crossOrigin="anonymous",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey getNextSurveyStep identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted captureException loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
            posthog.init('${import.meta.env.VITE_POSTHOG_API_KEY}', {api_host: 'https://eu.i.posthog.com', disable_session_recording: true, person_profiles: 'identified_only'})`


		
            // session recording disabled by defauly, control using posthog.startSessionRecording()/ posthog.stopSessionRecording().


            const posthogScript = document.createElement('script');
            posthogScript.setAttribute('id', 'script_posthog');
            posthogScript.innerHTML = scriptHTML;
            document.head.appendChild(posthogScript);

          }
        }
    },
    async refresh() {
      // Refresh token
      if (localStorage.getItem(refreshTokenName) !== null && localStorage.getItem(refreshTokenName) !== 'null') {
        if (!(this as any).impersonating) {
          await this.refreshToken({ refreshToken: localStorage.getItem(refreshTokenName) || '', component: this });
        }
      } else {
        this.logout();
      }
    },
    async logPageView(page: any, force = false) {
      try {
        if (!page) {
          page = this.$router.currentRoute.value;
        }

        if (!force && page.fullPath == this.previous_page) {
          return;
        }

        this.previous_page = page.fullPath;

        const parameter = {
          title: document.title,
          name: page.name,
          path: page.path,
          params: JSON.stringify(page.params),
          query: JSON.stringify(page.query),
          url: window.location.href,
          page: ''
        };

        if ((this as any).user && (this as any).user.id) {
          UserService.sendAnalytics({ event: 'page_view', data: parameter });
        } else {
          if (this.$amplitude) {
            parameter.page = this.$router.currentRoute.value.fullPath;
            this.$amplitude.logEvent('page_view', parameter);
          }
        }
      } catch (err) {
        console.log('error logging amplitude', err);
      }
    },
    async reloadPage() {
      let cachesKeys = await caches.keys()
      // remove all old cached items
      await asyncForEach(cachesKeys, async (cacheName: string) => {
        await caches.delete(cacheName);
      });
      window.location.reload();

    },
    checkVersion() {

      let app_version = process.env.VITE_GIT_VERSION
      if (app_version) {
        let saved_version = localStorage.getItem('app_version')
        localStorage.setItem('app_version', app_version)
        if (saved_version && saved_version !== app_version) {
          this.reloadPage()
        }
      }
    },
    async initialize() {
      if (this.$amplitude) {
        const deviceId =this.$amplitude.getDeviceId() + '|' + this.$amplitude.getSessionId();
        if (deviceId) {
          this.setDeviceId(deviceId);
        }
        this.logPageView(null);
      }

      eventBus.$off('page_view', (page: any) => {
        this.logPageView(page);
      });

      eventBus.$on('page_view', (page: any) => {
        this.logPageView(page);
      });

      if (!this.initialized) {
        await this.loginToken({
          updatePortfolio: true,
          component: this,
          loading: true
        });
        this.initialized = true;
      }
      this.checkVersion();
    }
  }
})

</script>


<style lang="scss">
@import 'bulma/sass/utilities/index';
@import url('./assets/css/main.css');

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

html {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

/**
  Modifications of Bulma and Buefy Defaults
*/
$body-background-color: #fbfafc; //#fbf8fb
$turquoise: #00c386;
$green: #00c386;
$blue: #228cdb;
$red: #fc6404;
$primary: $turquoise;
$success: $primary;
$danger: $red;
$title-size: 28px;

$stock: #fc6404;
$stock-invert: findColorInvert($stock);
$crypto: #228cdb;
$crypto-invert: findColorInvert($crypto);
$commodity: #00c386;
$commodity-invert: findColorInvert($commodity);
$forex: #ffdd57;
$forex-invert: findColorInvert($forex);
$index: $dark;
$index-invert: findColorInvert($index);
$light-invert: findColorInvert($light);
$dark-invert: findColorInvert($dark);
// $colors: (
//   'white': ($white,
//     $black),
//   'black': ($black,
//     $white),
//   'light': ($light,
//     $light-invert),
//   'dark': ($dark,
//     $dark-invert),
//   'primary': ($primary,
//     $primary-invert),
//   'info': ($info,
//     $info-invert),
//   'success': ($success,
//     $success-invert),
//   'warning': ($warning,
//     $warning-invert),
//   'danger': ($danger,
//     $danger-invert),
//   'stock': ($stock,
//     $stock-invert),
//   'crypto': ($crypto,
//     $crypto-invert),
//   'commodity': ($commodity,
//     $commodity-invert),
//   'forex': ($forex,
//     $forex-invert),
//   'index': ($index,
//     $index-invert)
// );

$modal-background-background-color: rgba(251, 250, 252, 0.91);
$modal-card-head-border-bottom: none;

$menu-item-hover-background-color: none;
$menu-item-hover-color: #000;

$navbar-item-hover-color: none;
$navbar-item-hover-background-color: none;
$navbar-dropdown-arrow: $grey-dark;
$navbar-dropdown-boxed-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
$navbar-dropdown-border-top: 3px solid $white-bis;
$navbar-dropdown-boxed-radius: 10px;
$navbar-dropdown-item-hover-background-color: none;
$navbar-dropdown-item-active-color: $red;
$navbar-dropdown-item-active-background-color: none;
$navbar-dropdown-offset: -22px;

$dropdown-content-radius: 10px;
$dropdown-content-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
$dropdown-item-hover-background-color: none;
$dropdown-item-active-background-color: none;

// $button-color: $grey-darker;
// $button-border-color: $grey-lighter;
$button-background-color: $body-background-color;
$button-focus-border-color: none;
$button-focus-box-shadow-size: 0;
$button-focus-box-shadow-color: none;

$tabs-border-bottom-style: none;
$tabs-link-color: hsl(0, 0%, 56%);
$tabs-link-hover-color: #4c4c4c;
$tabs-link-active-color: #4c4c4c;
$tabs-link-padding: 0 15px 0 0;

$input-background-color: $body-background-color;
$input-shadow: none;
$input-border-color: hsl(0, 0%, 88%);
$input-hover-border-color: hsl(0, 0%, 65%);
// $input-hover-border-color: #00A16F;
$input-focus-border-color: $input-hover-border-color;
$input-focus-box-shadow-color: none;
$input-focus-box-shadow-size: none;
$input-placeholder-color: #353535;

$table-background-color: none;
$table-cell-border: solid hsl(0, 0%, 88%);
$table-cell-border-width: 0 0 1.25px;
$table-cell-padding: 0.75em 0.75em;
$table-head-cell-border-width: 0;
$table-head-cell-color: #6e6e6e;
$table-row-active-background-color: none;
$table-row-active-color: none;
$table-striped-row-even-background-color: none;
$table-row-hover-background-color: #f5f3f8;
$table-striped-row-even-hover-background-color: $table-row-hover-background-color;

$tag-background-color: $body-background-color;
$tag-radius: 5px;

$steps-maker-default-color: #d8d8d8;
$steps-marker-default-border: 0.25em solid $body-background-color;
$steps-active-color: #00c386;
$steps-previous-color: #00c386;
$steps-divider-height: 0.25em;

$slider-tick-background: #fff;

$progress-text-color: #333333;

$carousel-indicator-color: #c4c4c4;

$datepicker-today-border: solid 1px rgba($turquoise, 1);
$datepicker-item-hover-background-color: #f5f3f8;
$datepicker-item-selected-background-color: $turquoise;

// @import '~bulma';
// @import '~buefy/src/scss/buefy';

/**
  Helper css classes - margins and padding
  Credits: https://github.com/jgthms/bulma/issues/451#issuecomment-331758839
 */
$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: (
  ('none', 0),
  ('xxs', 0.125),
  ('xs', 0.25),
  ('sm', 0.5),
  ('md', 1),
  ('lg', 2),
  ('xl', 4),
  ('xxl', 8)
);
$positions: (
  ('t', 'top'),
  ('r', 'right'),
  ('b', 'bottom'),
  ('l', 'left')
);

@function sizeValue($key, $value) {
  @return if($key =='none', 0, $value + $sizeUnit);
}

@each $size in $sizes {
  $sizeKey: nth($size, 1);
  $sizeValue: nth($size, 2);

  .#{$marginKey}#{$separator}#{$sizeKey} {
    margin: sizeValue($sizeKey, $sizeValue);
  }

  .#{$paddingKey}#{$separator}#{$sizeKey} {
    padding: sizeValue($sizeKey, $sizeValue);
  }

  @each $position in $positions {
    $posKey: nth($position, 1);
    $posValue: nth($position, 2);

    .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
      margin-#{$posValue}: sizeValue($sizeKey, $sizeValue) !important;
    }

    .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
      padding-#{$posValue}: sizeValue($sizeKey, $sizeValue) !important;
    }
  }
}

a,
.link {
  color: #1A6DF0;
}
</style>
