import { i18n } from '../plugins/i18n';
import { SnackbarProgrammatic } from 'buefy';
import { fortmaticConfig } from './fortmatic';

// localStorage values
export const accessTokenName = 'morpher-access-token';
export const refreshTokenName = 'morpher-refresh-token';

export const supportArticles: any = {
	"Support":{
	   "en":"https://support.morpher.com/",
	   "de":"https://support.morpher.com/de/",
	   "ru":"https://support.morpher.com/ru/"
	},
	"AboutMph":{
	   "en":"https://support.morpher.com/en/article/about-morpher-tokens-mph-pdf0cg/",
	   "de":"https://support.morpher.com/de/article/uber-morpher-token-mph-1ntb8p6/",
	   "ru":"https://support.morpher.com/ru/article/o-tokene-mph-tketg7/"
	},
	"DepositBonus":{
	   "en":"https://support.morpher.com/en/article/deposit-bonuses-od0etd/",
	   "de":"https://support.morpher.com/de/article/einzahlungsboni-fsj2um/",
	   "ru":"https://support.morpher.com/ru/article/bonus-za-depozit-1axkwh3/"
	},
	"DepositPaypal":{
	   "en":"https://support.morpher.com/en/article/depositing-with-paypal-aj3nuy/",
	   "de":"https://support.morpher.com/de/article/einzahlung-mit-paypal-65l4to/",
	   "ru":""
	},
	"DepositMethod":{
	   "en":"https://support.morpher.com/en/article/deposit-methods-1fv3611/",
	   "de":"https://support.morpher.com/de/article/einzahlungsmethoden-1chvjff/",
	   "ru":"https://support.morpher.com/ru/article/metody-popolneniya-scheta-if810r/"
	},
	"ExportWallet":{
	   "en":"https://support.morpher.com/en/article/export-morpher-wallet-d6wr6g/",
	   "de":"https://support.morpher.com/de/article/morpher-wallet-exportieren-rmn66p/",
	   "ru":"https://support.morpher.com/ru/article/kak-eksportirovat-koshelek-morpher-wallet-1c4bnsu/"
	},
	"WithdrawLimit":{
	   "en":"https://support.morpher.com/en/article/funding-limits-3do1h0/#3-withdrawal-limits",
	   "de":"https://support.morpher.com/de/article/finanzierungslimits-14hlo9t/",
	   "ru":"https://support.morpher.com/ru/article/limity-na-tranzakcii-1i1lgb9/"
	},
	"Staking":{
	   "en":"https://support.morpher.com/en/article/how-savings-staking-works-v0oh8j/",
	   "de":"https://support.morpher.com/de/article/wie-funktioniert-stakingsavings-1wo2hor/",
	   "ru":"https://support.morpher.com/ru/article/sberezheniya-i-stejking-na-morpher-13gff89/"
	},
	"KYC":{
	   "en":"https://support.morpher.com/en/article/kyc-11v1n5t/",
	   "de":"https://support.morpher.com/de/article/kyc-13msk94/",
	   "ru":"https://support.morpher.com/ru/article/kyc-y7og1a/"
	},
	"Liquidation":{
	   "en":"https://support.morpher.com/en/article/liquidations-1umvr72/?bust=1624454914034",
	   "de":"https://support.morpher.com/de/article/liquidierungen-5a1iqn/",
	   "ru":"https://support.morpher.com/ru/article/likvidaciya-pozicij-1657q9z/"
	},
	"WithdrawLocked":{
	   "en":"https://support.morpher.com/en/article/locked-withdrawals-1earc5q/",
	   "de":"https://support.morpher.com/de/article/gesperrte-auszahlungen-6mqwdy/",
	   "ru":"https://support.morpher.com/ru/article/blokirovka-vyvoda-sredstv-6vh58m/"
	},
	"WithdrawBankAccount":{
		"en":"https://support.morpher.com/en/article/withdraw-to-bank-account-1dh6tro/",
		"de":"https://support.morpher.com/de/article/auszahlung-an-ein-bankkonto-10k0iat/",
		"ru":"https://support.morpher.com/ru/article/kak-vyvesti-sredstva-na-rossijskuyu-kartu-qwzv9g/"
	 },
	 "WithdrawExchange":{
		"en":"https://support.morpher.com/en/article/withdraw-to-exchanges-binance-ngr180/",
		"de":"https://support.morpher.com/de/article/auszahlung-an-eine-krypto-borse-binance-gehbti/",
		"ru":"https://support.morpher.com/ru/article/vyvod-na-kriptobirzhi-binance-mzn2kz/"
	 },
	
	"LoginIssues":{
	   "en":"https://support.morpher.com/en/article/logging-in-50fbrn/#1-troubleshooting-login-issues",
	   "de":"https://support.morpher.com/de/article/einloggen-1g2ap38/",
	   "ru":"https://support.morpher.com/ru/article/vhod-v-akkaunt-sq1z10/"
	},
	"TradingMarginInterest":{
	   "en":"https://support.morpher.com/en/article/margin-interest-1pajpeh/#3-margin-interest",
	   "de":"https://support.morpher.com/de/article/margin-zinsen-3yev61/#3-margin-zinsen",
	   "ru":"https://support.morpher.com/ru/article/procentnaya-marzha-wg4xhg/#3-procentnaya-marzha"
	},
	"MetamaskGas":{
	   "en":"https://support.morpher.com/en/article/metamask-mobile-gas-bug-1pfnrw9/?bust=1614866393956#3-3-manually-change-gas-price",
	   "de":"https://support.morpher.com/de/article/metamask-mobile-gas-bug-smvi8t/",
	   "ru":"https://support.morpher.com/ru/article/oshibka-s-ustanovkoj-ceny-na-gaz-v-prilozhenii-metamask-7kfgfe/"
	},
	"RewardsProgram":{
	   "en":"https://support.morpher.com/en/article/morpher-rewards-program-2wyt0h",
	   "de":"https://support.morpher.com/de/article/morpher-belohnungsprogramm-1dboxt7/",
	   "ru":"https://support.morpher.com/ru/article/referalnaya-programma-morpher-16px9we/"
	},
	"TradingZeroFees":{
	   "en":"https://support.morpher.com/en/article/morphers-zero-fees-explained-prhjj6/",
	   "de":"https://support.morpher.com/de/article/erklarung-der-null-gebuhren-177tuk1/",
	   "ru":"https://support.morpher.com/ru/article/o-nulevoj-komissii-14v2xwa/"
	},
	"MarketsNonTradable":{
	   "en":"https://support.morpher.com/en/article/non-tradable-markets-z3u17u/",
	   "de":"https://support.morpher.com/de/article/nicht-handelbare-markte-dvm72/",
	   "ru":"https://support.morpher.com/ru/article/netorguemye-rynki-qmdrd1/"
	},
	"MetamaskReset":{
	   "en":"https://support.morpher.com/en/article/resetting-metamask-1891t1o/",
	   "de":"https://support.morpher.com/de/article/metamask-zurucksetzen-1ag9bc8/",
	   "ru":"https://support.morpher.com/ru/article/kak-perezagruzit-metamask-il0rwd/"
	},
	"TradingSpreads":{
	   "en":"https://support.morpher.com/en/article/spreads-50dnh0/#3-introduction-to-spreads",
	   "de":"https://support.morpher.com/de/article/spreads-1bvp28e/#3-einfuhrung-in-spreads",
	   "ru":"https://support.morpher.com/ru/article/spredy-1x5iiqs/#3-vvedenie-v-spredy"
	},
	"TradingStockSplit":{
	   "en":"https://support.morpher.com/en/article/stock-splits-2x21rb/",
	   "de":"https://support.morpher.com/de/article/aktiensplits-8kvkiv/",
	   "ru":"https://support.morpher.com/ru/article/droblenie-akcij-split-7k08mx/"
	},
	"TradingCommodityRollover":{
		"en":"https://support.morpher.com/en/article/rollover-adjustments-aroxqo/",
		"de":"https://support.morpher.com/de/article/rollover-anpassungen-9h3a88/",
		"ru":"https://support.morpher.com/ru/article/korrektirovka-dlya-nepreryvnyh-fyuchersnyh-kontraktov-usrj1a/"
	 },
	"SignupCountries":{
	   "en":"https://support.morpher.com/en/article/supported-countries-1ep1a0g/#3-countries-limited-or-unsupported-right-now",
	   "de":"https://support.morpher.com/de/article/unterstutzte-lander-15f59os/#3-lander-die-derzeit-eingeschrankt-oder-nicht-unterstutzt-werden",
	   "ru":"https://support.morpher.com/ru/article/podderzhivaemye-strany-vwz6jy/#3-strany-s-ogranichennym-dostupom-ili-ne-podderzhivaemye-na-dannyj-moment"
	},
	"TradingGas":{
	   "en":"https://support.morpher.com/en/article/what-is-virtual-ethereum-1hy9qpq/",
	   "de":"https://support.morpher.com/de/article/was-ist-virtuelles-ethereum-1fria1g/",
	   "ru":"https://support.morpher.com/ru/article/chto-takoe-virtualnyj-efir-virtual-ethereum-1c20769/"
	},
	"TradingPaused":{
	   "en":"https://support.morpher.com/en/article/why-are-markets-paused-i630f/#1-what-causes-markets-to-pause",
	   "de":"https://support.morpher.com/de/article/warum-werden-die-markte-pausiert-1s2ipgz/",
	   "ru":"https://support.morpher.com/ru/article/pochemu-torgovlya-na-rynke-priostanovlena-132qtgg/"
	},
	"Withdraw":{
	   "en":"https://support.morpher.com/en/article/withdrawals-pfh5dt/",
	   "de":"https://support.morpher.com/de/article/auszahlung-9nafqd/",
	   "ru":"https://support.morpher.com/ru/article/vyvod-sredstv-r716a8/"
	},
	"Portfolio":{
	   "en":"https://support.morpher.com/en/article/your-portfolio-1fql1au/",
	   "de":"https://support.morpher.com/de/article/ihr-portfolio-138w2ll/",
	   "ru":"https://support.morpher.com/ru/article/vash-portfel-1m1yjct/"
	},
	"Blockchain":{
	   "en":"https://support.morpher.com/en/category/blockchain-and-tokenomics-1y5p9d/",
	   "de":"https://support.morpher.com/de/category/blockchain-und-tokenomics-1fanyi0/",
	   "ru":"https://support.morpher.com/ru/category/blokchejn-i-tokenomika-1i51qat/"
	},
	"GettingStarted":{
	   "en":"https://support.morpher.com/en/category/getting-started-1i4gh9b/",
	   "de":"https://support.morpher.com/de/category/erste-schritte-1av5lvq/",
	   "ru":"https://support.morpher.com/ru/category/pervye-shagi-1h3bpp9/"
	},
	"MorpherWallet":{
	   "en":"https://support.morpher.com/en/category/morpher-wallet-idvnts/",
	   "de":"https://support.morpher.com/de/category/morpher-wallet-11vkl28/",
	   "ru":"https://support.morpher.com/ru/category/morpher-wallet-1va32bu/"
	},
	"LoginAccount":{
	   "en":"https://support.morpher.com/en/category/my-account-login-xojlkd/",
	   "de":"https://support.morpher.com/de/category/mein-konto-und-anmeldung-1qyt32d/",
	   "ru":"https://support.morpher.com/ru/category/uchetnaya-zapis-i-vhod-8nms0w/"
	},
	"Trading":{
	   "en":"https://support.morpher.com/en/category/trading-ycrll3/",
	   "de":"https://support.morpher.com/de/category/handeln-1lwy6lv/",
	   "ru":"https://support.morpher.com/ru/category/torgovlya-1avdbsf/"
	},
	"Transactions":{
	   "en":"https://support.morpher.com/en/category/transactions-1xflvjj/",
	   "de":"https://support.morpher.com/de/category/transaktionen-vu29fa/",
	   "ru":"https://support.morpher.com/ru/category/tranzakcii-1no84vh/"
	}
 }

 export const roundFormatter = (param: any) => {
	const price = parseFloat(param);
	const abs = Math.abs(price);
	let round = 0;
	if (10000 > abs && abs >= 10) round = 2;
	else if (10 > abs && abs >= 1) round = 3;
	else if (1 > abs && abs >= 0.1) round = 4;
	else if (0.1 > abs && abs >= 0.01) round = 5;
	else if (0.01 > abs && abs >= 0.001) round = 6;
	else if (0.001 > abs && abs >= 0.0001) round = 7;
	else if (0.0001 > abs && abs >= 0.00001) round = 8;
	else if (0.00001 > abs) round = 9;
	return price ? price.toFixed(round) : '0';
}

export const getMobileOperatingSystem = () => {
	const userAgent = window.navigator.userAgent || window.navigator.vendor || (window.window as any).opera;
	if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) ) {
		return 'ios';
	} else if( userAgent.match( /Android/i ) ) {
		return 'android';
	} else {
		return 'unknown';
	}
}
/*
 * Function uses to copy the given text to the clipboard. ses a hidden textArea to copy the text
 */
export const copyToClipboard = (text: string, self: any) => {
	const textArea = document.createElement('textarea');
	// Place in top-left corner of screen regardless of scroll position.
	textArea.style.position = 'fixed';
	textArea.style.top = '0px';
	textArea.style.left = '0px';

	// Ensure it has a small width and height. Setting to 1px / 1em
	// doesn't work as this gives a negative w/h on some browsers.
	textArea.style.width = '2em';
	textArea.style.height = '2em';

	// We don't need padding, reducing the size if it does flash render.
	textArea.style.padding = '0px';

	// Clean up any borders.
	textArea.style.border = 'none';
	textArea.style.outline = 'none';
	textArea.style.boxShadow = 'none';

	// Avoid flash of white box if rendered for any reason.
	textArea.style.background = 'transparent';
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	let a = SnackbarProgrammatic as any
	let app = getCurrentInstance()
	const Snackbar = new a(app)

	try {
		const successful = document.execCommand('copy');
		textArea.style.display = 'none';

		if (successful) {
			Snackbar.open({
				duration: 5000,
				message: i18n.t('COPY_CLIPBOARD_SUCCESS').toString(),
				type: 'is-success',
				position: 'is-bottom-left',
				actionText: 'OK',
				queue: true,
				onAction: () => {}
			});
		} else {
			Snackbar.open({
				duration: 5000,
				message: i18n.t('COPY_CLIPBOARD_FAIL').toString(),
				type: 'is-success',
				position: 'is-bottom-left',
				actionText: 'OK',
				queue: true,
				onAction: () => {}
			});
		}
	} catch (err) {
		Snackbar.open({
			duration: 5000,
			message: i18n.t('COPY_CLIPBOARD_FAIL').toString(),
			type: 'is-danger',
			position: 'is-bottom-left',
			actionText: 'OK',
			queue: true,
			onAction: () => {}
		});
		console.error('Fallback: Oops, unable to copy', err);
	}
	document.body.removeChild(textArea);
};
/*
 * Open the specified social media network and pass a link for sharing
 * Link structure sourced from https://github.com/bradvin/social-share-urls
 */
export const openSocialMediaWindow = (network: string, shareLink: string, title: string) => {
	let url = '';
	// Get the url for the social media network
	switch (network) {
		case 'x':
			url = 'https://x.com/share?url=' + shareLink + '&text=' + title + '&via=morpher_io';
			break;

		case 'x_ad':
			url = 'https://x.com/share?url=' + shareLink + '&text=' + title;
			break;

		case 'facebook':
			url = 'https://www.facebook.com/sharer/sharer.php?u=' + shareLink;
			break;

		case 'linkedin':
			url = 'http://www.linkedin.com/shareArticle?mini=true&url=' + shareLink + '&title=' + title + '&source=www.morpher.com';
			break;

		case 'reddit':
			url = 'https://reddit.com/submit?url=' + shareLink + '&title=' + title;
			break;

		case 'pocket':
			url = 'https://getpocket.com/edit?url=' + shareLink;
			break;

		case 'telegram':
			url = 'https://telegram.me/share/url?url=' + shareLink + '&text=' + title;
			break;

		case 'whatsapp':
			url = 'https://wa.me/?text=' + title + ' ' + shareLink;
			break;

		default:
			break;
	}

	// Open the social media network in a new window
	const posTop = screen.height / 2 - 250;
	const posLeft = screen.width / 2 - 250;
	const windowConfig = 'width=500px,height=500px,top=' + posTop + ',left=' + posLeft;
	window.open(url, '_blank', windowConfig);
};


export const asyncForEach = async <T>(array: T[], callback: (object: any, index: number, array: T[]) => void) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

/**
 * Generate a unique user-id based on the eth_address. Used for wallets without a user id.
 * @param eth_address
 */
export const getUserID = async (eth_address: string, wallet_type: string) => {
	let returnData = '';
	if (wallet_type === 'fortmatic') {
		const result = await fortmaticConfig();
		const fortmatic = result.fortmatic;
		const userData = await fortmatic.user.getUser();
		return userData.userId;
	}
	eth_address = eth_address.replace('0x', '');

	// looping through each character of string
	for (let i = eth_address.length - 1; i >= 0; i--) {
		// storing integer ASCII value of
		// the character in 'asc'
		const asc = eth_address.charCodeAt(i);

		// 'rem' contains coded value which
		// needs to be rounded to 26
		const rem = asc - (26 - (asc - 'a'.charCodeAt(0)));

		// coverting 'rem' character in range
		// 0-25 and storing in 'm'
		const m = rem % 26;

		// printing character by adding ascii value of 'a'
		// so that it becomes in the desired range i.e. a-z
		returnData += String.fromCharCode(m + 'a'.charCodeAt(0));
	}
	return returnData;
};

export const parseDate = (date: any) => {
	let dd = date.getDate();

	let mm = date.getMonth() + 1;
	const yyyy = date.getFullYear();
	if (dd < 10) {
		dd = `0${dd}`;
	}

	if (mm < 10) {
		mm = `0${mm}`;
	}

	return `${dd}${mm}${yyyy}`;
};

export const trimWhiteSpace = (text: string): string => {
	return text.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');
};

export const translateSegmentName = (name: string): string => {
	return i18n.t('categories.' + name, {
			default: name
		})
		.toString();
};

export const amountBucket = (amount: any) => {
	amount = Number(amount)
	if (amount < 10) return '0'
	else if (amount < 100) return '10'
	else if (amount < 1000) return '100'
	else if (amount < 10000) return '1k'
	else if (amount < 50000) return '10k'
	else if (amount < 100000) return '50k'
	else if (amount < 500000) return '100k'
	else if (amount < 1000000) return '500k'
	else if (amount < 10000000) return '1M'
	else if (amount < 100000000) return '10M'
	else if (amount < 200000000) return '100M'
	else return 'MM'
}
export const numberBucket = (amount: any)  => {
    amount = Number(amount)
    if (amount < 1) return '0'
    else if (amount < 5) return '1'
    else if (amount < 10) return '5'
    else if (amount < 50) return '10'
    else return '50'
}
export const percentageBucket = (percentage: any) => {
	percentage = Number(percentage)

	if (percentage < -0.8) return '-1'
	else if (percentage < -.5) return '-.8'
	else if (percentage < -0.2) return '-.5'
	else if (percentage < -0.1) return '-.2'
	else if (percentage < -0.05) return '-.1'
	else if (percentage < 0) return '-.05'
	else if (percentage < .05) return '0'
	else if (percentage < .1) return '.05'
	else if (percentage < .2) return '.1'
	else if (percentage < .5) return '.2'
	else if (percentage < .8) return '.5'
	else if (percentage < 1) return '.8'
	else if (percentage < 2) return '1'
	else if (percentage < 10) return '2'
	else return '10+'

}

import axios from 'axios';
import type { AxiosResponse } from 'axios';
import util from '@/services/shared';
import { getCurrentInstance } from 'vue';

export const resolveUnstoppable = (domain:string, currency: string) => {
	return new Promise<string>((resolve, reject) => {
		try {
			const url = `https://resolve.unstoppabledomains.com/domains/${domain}`

			axios.get(
				url, {headers: { Authorization: 'Bearer 8f723828-d4a3-4a1b-aec8-bcf8d262e53c' }}
			).then((locationData: AxiosResponse) => {
				if (locationData && locationData.data && locationData.data.records  && locationData.data.records['crypto.ETH.address']) {
					const address = locationData.data.records['crypto.ETH.address']
					resolve(address)
	
				} else {
					reject('Error fetching unstoppable domain')
				}
			})
			.catch((err:any) => {
				
				reject('Error fetching unstoppable domain')
			});
			
		} catch (err) {
			reject('Error fetching unstoppable domain')
		}

	});
}

export const getHomeSegments = (segments:[any]) => {
	const segment_list: any[] = [];

	segments.forEach((segment) => {
		if (segment.home_page == true) {
			if (!segment_list.find(seg => seg.segment_id == segment.segment_id)) {
				segment_list.push(segment)
			}
		}
	})

	return segment_list;
}

export const getMarketSegments = (segments:any[], market_id: string) => {
	const segment_list: any[] = [];

	if (market_id == 'UNDEFINED_UNDEFINED') {
		return segment_list;
	}

	segments.forEach((segment) => {
		if (segment.market_id == market_id) {
			if (!segment_list.find(seg => seg.segment_id == segment.segment_id)) {
				segment_list.push(segment)
			}
		}
	})

	return segment_list;
}

export const getUniqueSegments = (segments:any[]) => {
	let segment_list: any[] = [];


	segments.forEach((segment) => {
		if (!segment_list.find(seg => seg.segment_id == segment.segment_id)) {
			segment_list.push(segment)
		}
	})

	segment_list = segment_list.sort((m1: any, m2: any) => {
		if (Number(m1['order'] || 9999999) < Number(m2['order'] || 9999999)) {
			return -1;
		} else  {
			return 1;
		}
	})

	return segment_list;
}


export const getMarketTypeSegments = (segments:any[], type: string) => {
	const segment_list: any[] = [];

	segments.forEach((segment) => {
		if (type == 'STOCK') {
			if (segment && segment.market_id && segment.market_id.includes(type + '_') && segment.home_page == true) {
				if (!segment_list.find(seg => seg.segment_id == segment.segment_id)) {
					segment_list.push(segment)
				}				
			}
				
		} else {
			if (segment && segment.market_id && segment.market_id.includes(type + '_')) {
				if (!segment_list.find(seg => seg.segment_id == segment.segment_id)) {
					segment_list.push(segment)
				}
			}
		}		
	})

	return segment_list;
}

export const getSegmentMarkets = (segments:any[], markets:any[], segment_id: string) => {
	const market_list: any[] = [];
	
	segments.forEach((segment) => {
		if (segment.segment_id == segment_id) {
			const market = markets.find(market => util.formatMarketId(market.type, market.symbol) == segment.market_id) 
			if (market && !market_list.find(mark => util.formatMarketId(mark.type, mark.symbol) == util.formatMarketId(market.type, market.symbol))) {
				market_list.push(market);
			}
		}

	})

	return market_list;
}

export const sleep = (ms: number) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
};

export const getAuth = () => {
	const accessToken = localStorage.getItem(accessTokenName);
	const refreshToken = localStorage.getItem(refreshTokenName);
  
	return 'Bearer ' + accessToken + '||' + refreshToken 
	
}

const client = axios.create();
let loading = false
export const checkLocation = async () =>  {
	try {
		if (loading) {
			let counter = 0;
			while (loading && counter < 50) {
				await sleep(100)
				counter +=1;
			}
		}
		loading = true;
		let ip_location = JSON.parse(localStorage.getItem('ip_location') || '{}');

		if (!ip_location || !ip_location.timestamp || ip_location.timestamp < Date.now() - 1000 * 60 * 60 * 24) {
			const result= await client.get(import.meta.env.VITE_API_ROOT + '/v1/web/checkLocation/')
			const country_code = result.data;

			
			if (country_code && country_code.ip_address && country_code.country_code) {
				ip_location = { timestamp: Date.now(), country_code: country_code.country_code, ip_address: country_code.ip_address }
			} else {
				ip_location = { timestamp: Date.now(), country_code: 'unknown', ip_address: 'unknown' }
			}
			
			
			localStorage.setItem('ip_location', JSON.stringify(ip_location))
		}

		loading = false;
		return ip_location;
	} catch (err) {
		loading = false;
		return {ip_address: 'unknown', country_code: 'unknown'}
	}
}

export const emailClick = async (mclid:string) => {
	try {
		if (loading) {
			let counter = 0;
			while (loading && counter < 50) {
				await sleep(100)
				counter +=1;
			}
		}
		loading = true;
		const email_click = JSON.parse(localStorage.getItem('email_click') || '{}');

		if (!email_click || !email_click.mclid || email_click.mclid !== mclid) {
			const result= await client.post(import.meta.env.VITE_API_ROOT + '/v1/web/emailClick/', { mclid })
			const data  = { timestamp: Date.now(), mclid }
			localStorage.setItem('email_click', JSON.stringify(data))
		}

		loading = false;
		return email_click;
	} catch (err) {
		loading = false;
		return {mclid: 'unknown'}
	}
}
export const hotjarPage = (page: string) => {
	if (window.hj) {
		window.hj('stateChange', '/trade' + page);
	}
}

export const disableHotJar = async () => {
	if (window.hj) {
		console.log('window.hj', window.hj)
	}
}

export const updateHotJarUser = async (eth_address: string) => {
	if (window.hj) {
		window.hj('identify', eth_address, {});
	}
}

export const installHotJar = async (eth_address: string) => {
	if (!document.getElementById('script_hotjar') && !document.getElementById('script_hotjar_injected')) {
		if (import.meta.env.VITE_MODE === 'production') {
			const scriptHTML = "     (function(h,o,t,j,a,r){ \n \
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; \n \
				h._hjSettings={hjid:3058011,hjsv:6}; \n \
				a=o.getElementsByTagName('head')[0]; \n \
				r=o.createElement('script');r.async=1; \n \
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; \n \
				a.appendChild(r); \n \
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"


			const hotjarScript = document.createElement('script');
			hotjarScript.setAttribute('id', 'script_hotjar');
			hotjarScript.innerHTML = scriptHTML
			document.head.appendChild(hotjarScript);
		} else {
			const scriptHTML = "     (function(h,o,t,j,a,r){ \n \
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; \n \
				h._hjSettings={hjid:3067715,hjsv:6}; \n \
				a=o.getElementsByTagName('head')[0]; \n \
				r=o.createElement('script');r.async=1; \n \
				r.id='script_hotjar_injected'; \n \
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; \n \
				a.appendChild(r); \n \
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"


			const hotjarScript = document.createElement('script');
			hotjarScript.setAttribute('id', 'script_hotjar');
			hotjarScript.innerHTML = scriptHTML
			document.head.appendChild(hotjarScript);

			if (eth_address) {
				window.setTimeout(() => {
					if (window.hj) {
						var userId = eth_address || null; // Replace your_user_id with your own if available.
						window.hj('identify', userId, {});
						console.log('id done')
					}
				}, 1000)
			}
		}
	}
}