
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import {i18nPlugin} from './plugins/i18n'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import 'highcharts/css/highcharts.css';

import type { BuefyConfig } from 'buefy/src/types/components'
import { accessTokenName, hotjarPage, refreshTokenName } from './helpers/utils'
import vueClickOutsideElement from 'vue-click-outside-element'
import * as amplitude from '@amplitude/analytics-browser';

window.global ||= window;

const app = createApp(App)

app.use(createPinia());
app.use(router);
app.use(vueClickOutsideElement);

app.use(i18nPlugin);

// Highcharts library
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import 'highcharts/css/highcharts.css';
import { plugin as VueInputAutowidth } from 'vue-input-autowidth';

import highchartsAccessibility from "highcharts/modules/accessibility";

if (import.meta.env.VITE_AMPLITUDE_API_KEY) {
	try {
		amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {autocapture: {
			attribution: true,
			pageViews: false,
			sessions: false,
			formInteractions: false,
			fileDownloads: false,
			elementInteractions: false,
		  }});
		app.config.globalProperties.$amplitude = amplitude;
	} catch (err: any) {
		console.log('amplitude init error', err.toString())
	}

}





// app.use(accessibility as any);

app.use(VueInputAutowidth)
Highcharts.setOptions({
	lang: {
		rangeSelectorZoom: ''
	},
	accessibility: {
		enabled: false,
		keyboardNavigation: {
		  enabled: false,
		},
	  },
});
highchartsAccessibility(Highcharts);
app.use(HighchartsVue);
stockInit(Highcharts);

app.use(Buefy as any, {
	defaultIconPack: 'fas',
	defaultDateFormatter: (date: any) => {
		if (date.length === 0) {
			return null;
		}

		if (date.length && date.length > 1) {
			let return_data: string = '';
			date.forEach((element: any) => {
				return_data += new Intl.DateTimeFormat('en-GB').format(element) + ' - ';
			});

			return_data = return_data.substring(0, return_data.length - 3);

			return return_data;
		} else {
			return new Intl.DateTimeFormat('en-GB').format(date);
		}
	}
}
)
import globalMixin from '@/mixins/global.mixin'

app.mixin(globalMixin)

router.afterEach((to, from) => {
	eventBus.$emit('page_view', to);
});

router.beforeEach((to, from, next) => {
	

	// if (from.name == 'PortfolioFunds' && to.name != 'PortfolioFunds') {
	// 	stopRecording();
	// }
	// const hasLang = to.params && to.params.locale;
	// const hadLang = from.params && from.params.locale;

	// if (hasLang && hadLang && from.params.locale.toLowerCase() === to.params.locale.toLowerCase()) {
	// 	next();
	// }

	// let locale = to.params.locale || Cookie.get('locale') || defaultLocale;

	// if (!supportedLocales.includes(locale)) {
	// 	locale = defaultLocale;
	// }

	// i18n.locale = locale;
	// Cookie.set('locale', locale);

	// if (locale === 'en' && hasLang) {
	// 	console.log(to);
	// 	let path = to.fullPath;
	// 	path = path.replace('/en', '');

	// 	next({
	// 		path: `${path}`,
	// 		params: {
	// 			locale: 'en'
	// 		}
	// 	});
	// }

	// if (locale !== 'en' && !hasLang) {
	// 	next({ path: `/${locale}${to.fullPath}` });
	// }

	hotjarPage(to.fullPath)

	const token = localStorage.getItem(accessTokenName);
	const refreshToken = localStorage.getItem(refreshTokenName);
	if (
		(token === null || refreshToken === null) &&
		to.name !== 'Reset' &&
		to.name !== 'Login' &&
		to.name !== 'Register' &&
		to.name !== 'Unlock' &&
		to.name !== 'AppLogin' &&
		to.name !== 'RegisterInvited'
	) {
		if (to.name !== 'Home') {
			localStorage.setItem('logonRoute', to.fullPath);
		}
		next({ path: '/login', query: to.query || from.query });
	} else if (token && token !== '' && to.name === 'Auth' && to.params.authType !== 'reset') {
		next({ path: '/', query: to.query || from.query });
	} else {
		next();
	}

});

if (import.meta.env.VITE_MODE === 'production' || import.meta.env.VITE_MODE === 'staging') {
	app.config.performance = false;


} else {

	
	app.config.performance = true;
}


import en_flag from '@/assets/img/flags/en.svg'
import de_flag from '@/assets/img/flags/de.svg'
import ru_flag from '@/assets/img/flags/ru.svg'
import eventBus from './store/event-bus'

export type TLanguage = {
	code: string;
	name: string;
	flag: string;
}
const activeLanguagesList: TLanguage[] = [
	{
		code: 'en',
		name: 'English',
		flag: en_flag
	},
	{
		code: 'de',
		name: 'Deutsch',
	 	flag: de_flag
	},
	{
		code: 'ru',
		name: 'Русский',
		flag: ru_flag
	}
	// {
	// 	code: 'pt',
	// 	name: 'Português',
	//  flag: '🇵🇹'
	// },
	// {
	// 	code: 'ar',
	// 	name: '‏العربية‏',
	//  flag: '🇸🇦'
	// },
	// {
	// 	code: 'zh',
	// 	name: '中文',
	//  flag: '🇨🇳'
	// },
	// {
	// 	code: 'ja',
	// 	name: '日本語',
	//  flag: '🇯🇵'
	// },
	// {
	// 	code: 'bs',
	// 	name: 'Bosanski',
	//  flag: '🇧🇦'
	// }
];
const getLanguageParameter = (languageCode: string, parameter: 'code' | 'name' | 'flag') => {
	let value: string = activeLanguagesList[0][parameter];

	const findLang = activeLanguagesList.find((lang: any) => lang.code === languageCode);

	if (findLang) {
		value = findLang[parameter];
	}

	return value;
};

app.mount('#app')

export { activeLanguagesList, getLanguageParameter };