/**
 * Portfolios service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';
import checkMerkleProof from '@/graphql/portfolio/checkMerkleProof.query.graphql';

import getTransactions from '@/graphql/portfolio/getTransactions.query.graphql';
import getWithdrawalAddress from '@/graphql/transaction/getWithdrawalAddress.query.graphql';
import getOrders from '@/graphql/portfolio/getOrders.query.graphql';
import getLastOrder from '@/graphql/portfolio/getLastOrder.query.graphql';
import getPortfolioHistory from '@/graphql/portfolio/getPortfolioHistory.query.graphql';
import getBalance from '@/graphql/portfolio/getBalance.query.graphql';

import type { checkMerkleProofVariables, checkMerkleProof as checkMerkleProofResponse } from '@/graphql/portfolio/types/checkMerkleProof';
import type { getTransactionsVariables, getTransactions as getTransactionsResponse } from '@/graphql/portfolio/types/getTransactions';
import type { getWithdrawalAddressVariables, getWithdrawalAddress as getWithdrawalAddressResponse } from '@/graphql/transaction/types/getWithdrawalAddress';

import type { getOrdersVariables, getOrders as getOrdersResponse } from '@/graphql/portfolio/types/getOrders';
import type { getLastOrderVariables, getLastOrder as getLastOrderResponse } from '@/graphql/portfolio/types/getLastOrder';
import type { getPortfolioHistoryVariables, getPortfolioHistory as getPortfolioHistoryResponse } from '@/graphql/portfolio/types/getPortfolioHistory';

import type { getBalanceVariables, getBalance as getBalanceResponse } from '@/graphql/portfolio/types/getBalance';
import { ungzip } from 'node-gzip';

export const PortfolioService = {

	checkMerkleProof(variables: checkMerkleProofVariables) {
		return apollo.query<checkMerkleProofResponse>({
			query: checkMerkleProof,
			variables
		});
	},

	async getWithdrawalAddress(variables: getWithdrawalAddressVariables) {

		let transaction_data: any
		try {
			transaction_data = await apollo.query<getWithdrawalAddressResponse>({
				query: getWithdrawalAddress,
				variables
			});

			return JSON.parse(JSON.stringify(transaction_data.data.getWithdrawalAddress));	
		} catch (error) {
			return transaction_data;
		}	
	},

	async getTransactions(variables: getTransactionsVariables) {

		let transaction_data: any
		try {
			transaction_data = await apollo.query<getTransactionsResponse>({
				query: getTransactions,
				variables
			});

			transaction_data = JSON.parse(JSON.stringify(transaction_data))

			const data_compressed = transaction_data.data.getTransactions;

			const buf = Buffer.from(data_compressed, 'base64');
	
			const data_decompressed = await ungzip(buf);

			const data = JSON.parse(data_decompressed.toString());

			transaction_data.data.getTransactions = data

			return transaction_data;	
		} catch (error) {
			return transaction_data;


		}	

		
	},
	async getOrders(variables: getOrdersVariables) {
		let order_data: any
		try {

			order_data = await apollo.query<getOrdersResponse>({
				query: getOrders,
				variables
			});

			order_data = JSON.parse(JSON.stringify(order_data))

			const data_compressed = order_data.data.getOrders;

			const buf = Buffer.from(data_compressed, 'base64');
	
			const data_decompressed = await ungzip(buf);

			const data = JSON.parse(data_decompressed.toString());

			order_data.data.getOrders = data

			return order_data;		
		} catch (error) {
			return order_data; 


		}
	},
	getLastOrder(variables: getLastOrderVariables) {
		return apollo.query<getLastOrderResponse>({
			query: getLastOrder,
			variables
		});
	},
	getPortfolioHistory(variables: getPortfolioHistoryVariables) {
		return apollo.query<getPortfolioHistoryResponse>({
			query: getPortfolioHistory,
			variables
		});
	},
	
	async getBalance(variables: getBalanceVariables) {
		const data = await apollo.query<getBalanceResponse>({
			query: getBalance,
			variables
		});
		return JSON.parse(JSON.stringify(data))
	}
};
