<template>
  <div class="language-section">
    <!-- Language -->
    <div class="language-selector" @click="dropdownOpen = true;">
      <p class="dropdown-text-items">{{ $t('LANGUAGE') }}</p>
      <img class="language-flag"
        :src="languages.find(lang => lang.code === $i18n.locale.split('-')[0].toLowerCase())?.flag" />
    </div>
    <b-modal data-lang-modal="yes" :modelValue="dropdownOpen" :has-modal-card="true" @close="dropdownOpen = false">
      <div class="modal-card terminal has-font-manrope">
        <div class="language-items">
          <span v-for="(language, key) in languages" :key="language.code">
            <div class="lang-item" @click="() => setLanguage(language.code)">
              <img class="language-flag mr-20px" :src="language.flag" />
              <span>{{ language.name }}</span>
            </div>
            <hr v-if="key !== languages.length - 1" data-v-61dd7a3d="" style="margin: 5px 0px;">
          </span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import Cookie from 'js-cookie';
import Highcharts from 'highcharts';
import { activeLanguagesList, getLanguageParameter } from '../main';
import EventBus from '@/store/event-bus';

export default defineComponent({
  name: 'LanguageSelectorNavbar',
  props: ['isMobile'],
  data() {
    return {
      dropdownOpen: false,
      languages: activeLanguagesList,
      morpherwallet: {}
    };
  },
  computed: {
    ...mapState(useUserStore, {
      user: (state) => state.data,
    }),
    ...mapState(useContractStore, {
      contract: (state) => state as any,
    }),

  },
  async mounted() {

    const months = JSON.parse(this.$t('chart-months'));
    const weekdays = JSON.parse(this.$t('chart-weekdays'));
    const shortMonths = JSON.parse(this.$t('chart-shortMonths'));

    Highcharts.setOptions({
      lang: { months, weekdays, shortMonths }
    });

    EventBus.$off('updateLocale', this.updateLocale);
    EventBus.$on('updateLocale', this.updateLocale);

    this.checkUserLocale()
  },
  unmounted() {
    EventBus.$off('updateLocale', this.updateLocale);
  },
  methods: {
    ...mapActions(useUserStore, {
      updateUserPayload: 'updateUserPayload',
    }),

    checkUserLocale() {

      if (this.$i18n.locale && this.user && this.user.payload && this.user.payload.app_lang !== this.$i18n.locale) {
        if ((!this.user.payload.app_lang || this.user.payload.app_lang == 'en') && this.$i18n.locale !== 'en') {
          this.updateUserPayload({ payloadOption: 'app_lang', payloadValue: String(this.$i18n.locale) });
        } else {
          this.updateLocale(this.user.payload.app_lang)
        }
      }
    },
    getLanguageParameter(code: string, parameter: 'code' | 'name' | 'flag') {
      return getLanguageParameter(code, parameter);
    },
    updateLocale(locale: string) {
      this.setLanguage(locale)

    },
    setLanguage(lang: string) {

      if (!lang) {
        lang = 'en'
      }
      this.dropdownOpen = false;
      if (lang !== this.$i18n.locale) {
        this.$i18n.locale = lang;
        localStorage.setItem('locale', this.$i18n.locale || 'en')
        document.querySelector('html')?.setAttribute('lang', lang);
        if (lang === 'ar') document.querySelector('html')?.setAttribute('dir', 'rtl');
        else document.querySelector('html')?.setAttribute('dir', '');
        Cookie.set('locale', lang);

        this.updateUserPayload({ payloadOption: 'app_lang', payloadValue: lang });

        const months = JSON.parse(this.$t('chart-months'));
        const weekdays = JSON.parse(this.$t('chart-weekdays'));
        const shortMonths = JSON.parse(this.$t('chart-shortMonths'));

        Highcharts.setOptions({
          lang: { months, weekdays, shortMonths }
        });

        if (this.contract && this.contract.morpherwallet) {
          this.contract.morpherwallet.setLanguage(lang);
        }

        this.moment.locale(lang);

        EventBus.$emit('localeUpdated', lang)

        this.moment.locale(this.$i18n.locale || 'en')
      }
    },
    closeDropdown() {
      if (this.dropdownOpen) {
        this.dropdownOpen = false;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.language-flag {
  width: 24px;
  height: 24px;
  align-self: flex-end;
}

.language-section {
  width: 100%;
}

.language-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 700;

}

.language-items {
  padding: 20px;
}

.lang-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;

}

.language-selector {
  color: var(--Dark-Grey, #333);
}

.language-selector:hover {
  color: #039954 !important;
}

.dropdown-text-items {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 700;

  line-height: 24px;
  letter-spacing: -0.14px;
  text-align: left;
}

.lang-item {
  color: #333 !important;
  transition: all 0.25s;
}

.lang-item:hover {
  color: #039954 !important;
  transition: all 0.25s;
}


@media only screen and (max-width: 768px) {
  .modal-card {
    max-height: 100vh;
    max-width: 90vw !important;
  }
}
</style>