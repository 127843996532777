import { defineStore } from 'pinia'
import { MarketService } from '@/services/MarketService';
import { MARKETS, ERROR, ROOT, WATCHLIST } from '../mutation-types';
import to from 'await-to-js';
import type { Market } from '@/types/schema';
import type { getMarketsByIdsVariables } from '@/graphql/market/types/getMarketsByIds';
import type { getMarketDailyVariables } from '@/graphql/market/types/getMarketDaily';
import type { getMarketHourlyVariables } from '@/graphql/market/types/getMarketHourly';
import type { getMarketSparkVariables } from '@/graphql/market/types/getMarketSpark';
import util from '@/services/shared';
import type { getDiscoveryMarketsVariables } from '@/graphql/market/types/getDiscoveryMarkets';
import type { getMarketSegmentsVariables } from '@/graphql/market/types/getMarketSegments';
import type { getSegmentMarketsVariables } from '@/graphql/market/types/getSegmentMarkets';
import type { getMarketNewsVariables } from '@/graphql/market/types/getMarketNews';
import type { getMarketTweetsVariables } from '@/graphql/market/types/getMarketTweets';
import { getStore } from '..';
import { ungzip } from 'node-gzip';
import { roundFormatter } from '@/helpers/utils';

let store = {
		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		status:  {} as any,
		markets:  {} as any,
	
}

export interface SelectedMarket {
	watched: boolean;
	minutely?: number[][];
	daily?: number[][];
	weekly?: number[][];
	type?: string;
	symbol?: string;
	name?: string;
	open?: number;
	high?: number;
	low?: number;
	close?: number;
	change?: number;
	change_percent?: number;
	logo_image?: string;
	constituents?: any;

}

export interface LiveData {
	open?: number;
	high?: number;
	low?: number;
	close?: number;
	change?: number;
	change_percent?: number;
	closeFormatted?:string;
	spread?: number;
}

export interface MarketsState {
	[key: string]: any;
	data: Market[];
	marketData: {
		[key: string]: LiveData
	};
	discovery: Market[];
	marketdiscovery: {
		[key: string]:Market[];
	}
	discoveryLoading: boolean;
	segments: any[];
	segmentsLoading: boolean;
	sparklineLoading: boolean;
	news: any[];
	aiInsights: any[];
	newsLoading: boolean;
	aiInsightsLoading: number;
	category: Market[];
	categoryLoading: boolean;
	sortField: string;
	sortDirection: 'asc' | 'desc';
	selected: Market;
	mph_market: Market | null;
	disabled_markets: Market[];
	tweets: any[];
	tweetsLoading: boolean;
	logosLoading: boolean;
}

const getLiveData = (market_list: Market[]) => {
	const data:{
		[key: string]: LiveData
	} = {}

	market_list.forEach(market => {
		if (market && market.type && market.symbol) {
			const market_id = util.formatMarketId(market.type, market.symbol)
			const liveData:LiveData = {
				open: market.open || undefined,
				high: market.high || undefined,
				low: market.low || undefined,
				close: market.close || undefined,
				change: market.change || undefined,
				change_percent: market.change_percent || undefined,
				spread: market.spread || 0,
				closeFormatted: roundFormatter(market.close || 0)
			}

			data[market_id] = liveData
			
		}
	})

	return data

}

export const useMarketsStore = defineStore('markets', {
  state: () => {
	store = getStore()

    const initialState: MarketsState = {
      data: [],
	  marketData: {},
      discovery: [],
	  marketdiscovery: {},
      discoveryLoading: false,
      category: [],
      categoryLoading: false,
      segments: [],
      segmentsLoading: false,
      sparklineLoading: false,
      news: [],
      aiInsights: [],
      newsLoading: false,
      aiInsightsLoading: 0,
      sortField: 'alphabetical',
      sortDirection: 'asc',
      selected: {
        watched: false
      },
      byCategory: {
        gainers: [{}, {}, {}, {}, {}, {}],
        losers: [{}, {}, {}, {}, {}, {}]
      },
      mph_market: null,
      disabled_markets: [],
      tweets: [],
      tweetsLoading: false,
	  logosLoading: true
    }
    return initialState
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
		async getMarketLogos(
			params: any
		) {
			try {

				let market_list: any = {}

				params.forEach((market: any) => {
					if (market.type && market.symbol) {
						let market_id = util.formatMarketId(market.type, market.symbol)
						market_list[market_id] = market;
					}
					
				})

				let refresh_logos = false

				let logo_data:any = localStorage.getItem('logo_data')

				const keys= Object.keys(market_list)


				if (!logo_data) {
					refresh_logos = true;					
				} else {

					logo_data = JSON.parse(logo_data)

					if (!logo_data.timestamp || logo_data.timestamp < Date.now() - 1000 * 60 * 60 * 24 * 7) {
						refresh_logos = true;	
					} else {

						keys.forEach(key => {
							const logo = logo_data.data.find((dat: any) => dat.market_id === key)
							if (logo?.logo_date !== market_list[key].logo_date) {
								
								refresh_logos = true;	
							} 

						})
					}

				}


				if (refresh_logos) {

					const param: getMarketsByIdsVariables = {
						ids: [],
						fetch_logo: true
					}
				
					const logo_fetch = await MarketService.getMarketsByIds(param);
					if (!logo_fetch) {
						return 
					}
					const logos: any[] = []
					
					logo_fetch.forEach(market => {
						const logo = {market_id: market.type && market.symbol ? util.formatMarketId(market.type, market.symbol) : '',
							type: market.type, 
							symbol: market.symbol, 
							logo_date: market.logo_date, 
							logo_image: market.logo_image

						}
						logos.push(logo)
					})

					logo_data = {
						timestamp: Date.now(),
						data: logos
					}

					localStorage.setItem('logo_data', JSON.stringify(logo_data))
					
				}

				if (this.data && logo_data.data && logo_data.data.length > 0) {
					const market_data: Market[] = JSON.parse(JSON.stringify(this.data))
					logo_data.data.forEach((mark: any) => {
						if (this.selected.type === mark.type && this.selected.symbol === mark.symbol) {
							this.selected.logo_image = mark.logo_image
						}
						const index = market_data.findIndex(market => market.type === mark.type && market.symbol === mark.symbol);	
						if (index >= 0) {
							market_data[index].logo_date = mark.logo_date;
							market_data[index].logo_image = mark.logo_image;
						}
					})
					this.data = market_data
				}

				this.logosLoading = false;

			} catch (err) {
				this.logosLoading = false;
				return store.status[ERROR]('DATA_FETCHING_ERROR');
			}
		},
		/**  Get all active market by ids */
		async getMarketsByIds(
			params: {
				variables: getMarketsByIdsVariables;
				commitType: string;
				format?: boolean;
				sort?: boolean;
				component: any;
			}
		) {
			try {
				const a = true
				
				if (!store.user || !store.user.data || !store.user.data.role) {
					return [];
				}

				if (params.variables.fetch_logo !== true) {
					params.variables.fetch_logo = false;
				}
				let result = await MarketService.getMarketsByIds(params.variables);
				if (!result) {
					return []
				}
				// if (result?.length > 10) {
				// 	result = result.slice(0,10) 
				// }

				
				if (params.format && store.user.data.role === 'user') {
					result = result.filter(market => market.is_enabled && (market.is_enabled === true || market.is_enabled === 'notrade'));
				}
				if ((this as any)[params.commitType])
					(this as any)[params.commitType](result);
				
				if (params.sort) {
					this.sort({
						sortField: this.sortField,
						sortDirection: this.sortDirection,
						group: 'data'
					});
				}


				if (!params.variables.ids || params.variables.ids.length == 0) {
					this.getMarketLogos(result)

					try {
						store.user.formatActivePortfolio();
					} catch (err){
						console.log('error calling active portfolio')
					}
				}

				


				return result;
			} catch (err) {
				if (params?.component?.logSentryError) params.component.logSentryError(err);
				return store.status[ERROR]('DATA_FETCHING_ERROR');
			}
		},

		async getMPHMarket(
			params: {
				component: any;
			}
		) {
			try {
				const variables = { ids: ['MORPHER_MPH_USD'], minutely: false, fetch_logo: false };
				const result = await MarketService.getMarketsByIds(variables);
				if (!result) {
					return []
				}
				const market = result[0];

				const sparkline_result = await MarketService.getMarketSpark(variables);
				const marketSparkData = sparkline_result.data.getMarketSpark;
				if (marketSparkData && marketSparkData.length > 0) {
					market.sparkLineData = marketSparkData[0];
				}

				// subscribe to the market updates and add the market to the list
				market.subscribe = () =>
					MarketService.subscribeToMarket(market, 'MPHMArket', data => {
						const market_update = this.mph_market;
						if (market_update && data.data) {
							market_update.close = data.data.updateMarket.close;
							market_update.change = data.data.updateMarket.change;
							market_update.change_percent = data.data.updateMarket.change_percent;
							this[MARKETS.MORPHER](market_update);
						}
					});

				market.subscribe();

				this[MARKETS.MORPHER](result[0]);
			} catch (err) {
				if (params?.component?.logSentryError) params.component.logSentryError(err);
				return store.status[ERROR]('DATA_FETCHING_ERROR');
			}
		},

		/**  Get daily data => selected.daily */
		async getDailyForSelectedMarket(data: getMarketDailyVariables) {
			let [err, result] = await to(MarketService.getMarketDaily(data));
			if (err) return store.status[ERROR](err);

			result = JSON.parse(JSON.stringify(result))

			const buf = Buffer.from(result.data.getMarketDaily as any, 'base64');

			const daily_data = await ungzip(buf);

			this.selected.daily = JSON.parse(daily_data.toString());

			await this[MARKETS.SELECTED]( [this.selected]);
		},

		async getMinutelyForSparkLine(
			params: {
				data: getMarketSparkVariables;
				group: string;
			}
		) {
			this[MARKETS.SPARKLINE_LOADING]( true);


			const [err, result] = await to(MarketService.getMarketSpark(params.data));
			if (err) {
				console.log('err', err)
				this[MARKETS.SPARKLINE_LOADING](false);
				return store.status[ERROR](err);
			}
			
			const marketSparkData = result.data.getMarketSpark;

			const market_data: Market[] = JSON.parse(JSON.stringify(this.data))
			marketSparkData.forEach((mark: any) => {
				
				if (this.selected.type && this.selected.symbol && util.formatMarketId(this.selected.type, this.selected.symbol) === mark.market) {
					this.selected.sparkLineData = mark;
				}
				


				const index = market_data.findIndex(market => util.formatMarketId(market.type || '', market.symbol || '') === mark.market);	
				if (index >= 0) {
					mark.timestamp = Date.now();
					market_data[index].sparkLineData = mark;
					
				}
			})

			this.data = market_data
			

			this[MARKETS.SPARKLINE_LOADING](false);

			store.user.updateActivePortfolio(market_data);

			return marketSparkData;
		},

		/**  Get weekly data => selected.minutely  */
		async getWeeklyForSelectedMarket(data: getMarketHourlyVariables) {
			let [err, result] = await to(MarketService.getHourly(data));
			if (err) return store.status[ERROR](err);

			result = JSON.parse(JSON.stringify(result))

			const buf = Buffer.from(result.data.getMarketHourly as any, 'base64');

			const hourly_data = await ungzip(buf);

			this.selected.weekly = JSON.parse(hourly_data.toString());
			await this[MARKETS.SELECTED]([this.selected]);
		},
		selectMarket(market_id: any) {
			const market_find = this.data.filter(market => util.formatMarketId(market.type || '', market.symbol || '') === market_id);
			if (market_find && market_find.length > 0) {
				this[MARKETS.SELECTED]({
					...market_find[0],
					watched: false
				} as any);
			}
		},

		clearSelectedMarket() {
			this[MARKETS.SELECTED]([{ watched: false }]);
		},
		updateMarket(data: any) {

			if (!data.group) data.group = 'data';

			this[MARKETS.UPDATE](data);
		},
		/**  Get all discovery markets or for specific market_id */
		async getDiscoveryMarkets(
			params: {
				variables: getDiscoveryMarketsVariables;
				format?: boolean;
				sort?: boolean;
				component?: any;
			}
		) {
			try {
				
				if (params.variables.fetch_logo !== true) {
					params.variables.fetch_logo = false;
				}
				this.discoveryLoading = true;

				if (!store.user || !store.user.data || !store.user.data.role) {
					this.discoveryLoading = false;
					return [];
				}
				
				const result = await MarketService.getDiscoveryMarkets(params.variables, store.user.data.id);

				let data =JSON.parse(JSON.stringify(result.data.getDiscoveryMarkets));
				data = util.shuffleArray(data)

				data.forEach((market: any) => {
					if (typeof market === 'object' && market.change_percent === undefined) {
						market.change_percent = 0;
					}
				});
	
				
				if (params.variables.market_id) {
					this.marketdiscovery[params.variables.market_id] = data;
				} else {
					this.discovery = data;
				}
				
				this.discoveryLoading = false;

			} catch (err) {
				
				this.discoveryLoading = false;
				if (params?.component?.logSentryError) params.component.logSentryError(err);
				return store.status[ERROR]('DATA_FETCHING_ERROR');
			}
		},
		/**  Get all segments or for specific market_id */
		async getMarketSegments(
			params: {
				variables: getMarketSegmentsVariables;
				commitType: string;
				component: any;
			}
		) {
			try {
				this[MARKETS.SEGMENTS_LOADING](true);

				if (!store.user || !store.user.data || !store.user.data.role) {
					this[MARKETS.SEGMENTS_LOADING](false);
					return [];
				}

				params.variables.compact = true;
				const fetch = await MarketService.getMarketSegments(params.variables);

				let segments: any = []

				fetch.data.getMarketSegments.m.forEach((m: any) => {
					const s = fetch.data.getMarketSegments.s.find((seg: any) => seg.i == m.s)

					if (s) {
						segments.push( {
							market_id: m.m,
							segment_id: s.i,
							segment_name: s.n,
							description: s.d,
							category_tag: s.c,
							source: s.s,
							home_page: s.h,
							icon_image: s.ic,
							order: s.o,
							mobile_order: s.mo,
							color: s.co
						})
					}

					
				})
				
				
				segments = segments.sort((a: any, b: any) => a.order - b.order);


				if ((this as any)[params.commitType])
					(this as any)[params.commitType](segments);
				this[MARKETS.SEGMENTS_LOADING](false);
			} catch (err) {

				this[MARKETS.SEGMENTS_LOADING](false);
				if (params?.component?.logSentryError) params.component.logSentryError(err);
				return store.status[ERROR]('DATA_FETCHING_ERROR');
			}
		},
		/**  Get all news data for specific market_id */
		async getMarketNews(params: {
						variables: getMarketNewsVariables;
						component: any;
					}
		) {
			try {
				if (store.markets.newsLoading) {
					return;
				}
				this.newsLoading = true

				if (!store.user || !store.user.data || !store.user.data.role) {
					this.newsLoading = false;
					return [];
				}
				const result = await MarketService.getMarketNews(params.variables);

				if (result?.data?.getMarketNews) {
					this.news = Object.values(result?.data?.getMarketNews);
					
				}
				this.newsLoading = false;
			} catch (err) {
				this.newsLoading = false;
				if (params?.component?.logSentryError) params.component.logSentryError(err);
				return store.status[ERROR]('DATA_FETCHING_ERROR');
			}
		},
		/**  Get all news data for specific market_id */
		async getMarketTweets(params: {
				variables: getMarketTweetsVariables;
				component: any;
			}
		) {
			try {
				if (store.markets.tweetsLoading) {
					return;
				}
				this[MARKETS.TWEETS_LOADING](true);

				if (!store.user || !store.user.data || !store.user.data.role) {
					this[MARKETS.TWEETS_LOADING](false);
					return [];
				}
				const result = await MarketService.getMarketTweets(params.variables);

				this[MARKETS.TWEETS](result.data.getMarketTweets);
				this[MARKETS.TWEETS_LOADING](false);
			} catch (err) {
				this[MARKETS.TWEETS_LOADING](false);
				if (params?.component?.logSentryError) params.component.logSentryError(err);
				return store.status[ERROR]('DATA_FETCHING_ERROR');
			}
		},

		
		/**  Get all news data for specific market_id */
		async getAIMarketInsights(
			params: {
				component: any;
			}
			) {
				try {
					if (store.markets.aiInsightsLoading > Date.now() - 1000 * 60) {
						return;
					}

					this[MARKETS.AI_INSIGHTS_LOADING](Date.now());

					if (!store.user || !store.user.data || !store.user.data.role) {
						return [];
					}
					let result = await MarketService.getAIMarketInsights();
					result = JSON.parse(JSON.stringify(result))

					this[MARKETS.AI_INSIGHTS](result.data.getAIMarketInsights);
					
				} catch (err) {
					this[MARKETS.AI_INSIGHTS_LOADING]( 0);
					if (params?.component?.logSentryError) params.component.logSentryError(err);
					return store.status[ERROR]('DATA_FETCHING_ERROR');
				}
			},		
		/**  Get all markets for a specific segment_id */
		async getSegmentMarkets(
			params: {
				variables: getSegmentMarketsVariables;
				commitType: string;
				format: boolean;
				sort: boolean;
				component: any;
			}
		) {
			try {
				this[MARKETS.CATEGORY_LOADING](true);

				if (!store.user || !store.user.data || !store.user.data.role) {
					this[MARKETS.CATEGORY_LOADING](false);
					return null;
				}
				const result = await MarketService.getSegmentMarkets(params.variables);

				if ((this as any)[params.commitType])
					(this as any)[params.commitType](result.data.getSegmentMarkets.markets);
				if (params.format) this.format(store.user.data.role);


				this[MARKETS.CATEGORY_LOADING](false);
				return result.data.getSegmentMarkets.segment;
			} catch (err) {
				this[MARKETS.CATEGORY_LOADING](false);
				if (params?.component?.logSentryError) params.component.logSentryError(err);
				store.status[ERROR]('DATA_FETCHING_ERROR');
				return null;
			}
		},
			/**
			 * Format markets - hide disabled markets if role not admin
			 * @param state
			 * @param role
			 */
			format(role: string) {
				try {
					if (role === 'user') {
						this.data = this.data.filter(market => market.is_enabled && (market.is_enabled === true || market.is_enabled === 'notrade'));
					}
				} catch (err: any) {
					console.log('format error', err.toString())
				}
			},
			/**
			 * Sort markets - Used in ChartTable in MarketType view
			 * @param state
			 * @param data
			 * @param group
			 */
			sort(data: any) {
				this.sortField = data.sortField ? data.sortField : this.sortField;
				this.sortDirection = data.sortDirection ? data.sortDirection : this.sortDirection;
				let marketsArray: any[] = Object.values(this[data.group]).sort((m1: any, m2: any) => {
					if (m1.type < m2.type) {
						return 1;
					} else if (m1.type > m2.type) {
						return -1;
					}
					return 0;
				});
	
				if (data.sortField == 'close') {
					marketsArray = marketsArray.sort((m1: any, m2: any) => {
						if (Number(m1[this.sortField]) < Number(m2[this.sortField])) {
							return this.sortDirection === 'desc' ? 1 : -1;
						} else if (Number(m1[this.sortField]) > Number(m2[this.sortField])) {
							return this.sortDirection === 'desc' ? -1 : 1;
						}
	
						return 0;
					});
				} else {
					marketsArray = marketsArray.sort((m1: any, m2: any) => {
						if (m1[this.sortField] < m2[this.sortField]) {
							return this.sortDirection === 'desc' ? 1 : -1;
						} else if (m1[this.sortField] > m2[this.sortField]) {
							return this.sortDirection === 'desc' ? -1 : 1;
						}
	
						return 0;
					});
				}
				this.data = marketsArray;
			},
			[MARKETS.ALL]( data: Market[]) {
				data.forEach(market => {
					if (typeof market === 'object' && market.change_percent === undefined) {
						market.change_percent = 0;
					}
				});
				this.data = data;
				this.marketData = getLiveData(data)
			},
			[MARKETS.SELECTED]( data: Market[]) {
				try {
					if (!data || data.length < 1) {
						return
					}
					const market =  data[0]

					if (market) {
						const market_id = util.formatMarketId(market.type || '', market.symbol || '');
		
						const merk = this.data.find(mark =>  util.formatMarketId(mark.type || '', mark.symbol || '')  == market_id )
						if (!merk) {
							const dat = this.data
							const market_add:Market = {
								...market,
								daily: [],
								minutely: []
							}
							dat.push(market_add)
							this.data = dat
						} else {
							
							market.logo_image = merk.logo_image || undefined
						}
					}

					this.selected = market ;
				} catch (err: any) {
					console.log('error selecting market', err.toString())
				}
			},
			[MARKETS.UPDATE]( data: any) {
				if (!data.market.market) {
					data.market.market = util.formatMarketId(data.market.type, data.market.symbol);
				}


				const market: any = this.data.find((market: any) => util.formatMarketId(market.type, market.symbol) === data.market.market);
				let index = this.data.findIndex((market: any) => util.formatMarketId(market.type, market.symbol) === data.market.market);

				const market_id = data.market.market;

				
				let close = roundFormatter(data.market.close || 0)
				if (this.marketData[market_id] && this.marketData[market_id].closeFormatted !== close) {
					
					if (!data.market.high && data.market.close && this.marketData[market_id].high && data.market.close > this.marketData[market_id].high ) {
						data.market.high = data.market.close
					}
					if (!data.market.low && data.market.close && this.marketData[market_id].low && data.market.close < this.marketData[market_id].low ) {
						data.market.low = data.market.close
					}
					const liveData:LiveData = {
						open: data.market.open || this.marketData[market_id].open,
						high: data.market.high || this.marketData[market_id].high,
						low: data.market.low || this.marketData[market_id].low,
						close: data.market.close || data.market.close,
						change: data.market.change || this.marketData[market_id].change,
						spread: data.market.spread || this.marketData[market_id].spread,
						change_percent: data.market.change_percent || this.marketData[market_id].change_percent,
						closeFormatted: close
					}

					this.marketData[market_id] = liveData;
				}
				 

	
				if (market) {
					if (data.market.type && data.market.symbol) {
	
						market.change = data.market.change;
						market.change_percent = data.market.change_percent;
						market.close = data.market.close;
						market.high = data.market.high;
						market.is_enabled = data.market.is_enabled;
						market.is_paused = data.market.is_paused;
						market.low = data.market.low;
						market.oldest_close = data.market.oldest_close;
						market.open = data.market.open;
						market.pause_reason = data.market.pause_reason;
						market.spread = data.market.spread;
						market.timestamp = data.market.timestamp;
						market.volume = data.market.volume;
	
						if (this.selected.type == market.type && this.selected.symbol == market.symbol) {
							this.selected.change = market.change;
							this.selected.change_percent = market.change_percent;
							this.selected.close = market.close;
							this.selected.open = market.open;
							this.selected.high = market.high;
							this.selected.low = market.low;
						}					
					} else {
						market.sparkLineData = data.market;
					}
					this.data[index] = market
				}
			},
			[MARKETS.MORPHER]( market: Market) {
				this.mph_market = market;
			},
			[MARKETS.SEGMENTS]( data: any[]) {
				this.segments = Object.values(data);
			},
			
			[MARKETS.AI_INSIGHTS]( data: any[]) {
	
				data.forEach(mark => {
						const market = this.data.find(m => util.formatMarketId(m.type || '',  m.symbol || '') == mark.market_id )
						mark.market = market;
					}
				)
				
				this.aiInsights = Object.values(data);
			},
			newAIMarketInsight( data: any) {
	
				let insights = this.aiInsights;
				if (!insights) {
					insights = [];
				}
	
				if (insights.find(insight => insight.market_id === data.market_id)) {
					insights = insights.filter(insight => insight.market_id !== data.market_id );
				}
	
	
				insights.unshift(data)
	
				this.aiInsights = insights;
	
			},
					
			[MARKETS.TWEETS_LOADING]( loading: boolean) {
				this.tweetsLoading = loading;
			},
			[MARKETS.TWEETS]( data: any[]) {
				this.tweets = Object.values(data);
			},
			
			
			[MARKETS.AI_INSIGHTS_LOADING]( loading: number) {
				this.aiInsightsLoading = loading;
			},
			
			[MARKETS.SEGMENTS_LOADING]( loading: boolean) {
				this.segmentsLoading = loading;
			},
			[MARKETS.SPARKLINE_LOADING]( loading: boolean) {
				this.sparklineLoading = loading;
			},
			[MARKETS.CATEGORY]( data: any[]) {
				Object.values(data).forEach(market => {
					if (typeof market === 'object' && market.change_percent === undefined) {
						market.change_percent = 0;
					}
				});
	
				this.category = Object.values(data);
			},
			[MARKETS.CATEGORY_LOADING]( loading: boolean) {
				this.categoryLoading = loading;
			},
			[MARKETS.DISABLED]( data: Market[]) {
				this.disabled_markets = Object.values(data);
			},
	

		
	},
})