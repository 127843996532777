<template>
	<section>
		<b-modal :modelValue="showClaimAirdropModal" has-modal-card @close="claimAirdrop()">
			<div class="modal-card terminal">
				<AirdropClaim :modal="true" />
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import AirdropClaim from '@/components/AirdropClaim.vue';

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'ClaimAirdropModal',
	components: { AirdropClaim },
	data() {
		return {

			email: '',
			loading: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showClaimAirdropModal: (state) => state.showClaimAirdropModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),

	},
	watch: {
		user() {
			this.initialize();
		},

		showClaimAirdropModal() {
			this.initialize();
		}
	},
	mounted() { },
	methods: {
		...mapActions(useModalsStore, {
			toggleClaimAirdropModal: 'toggleClaimAirdropModal',
		}),
		...mapActions(useUserStore, {
			validateRegistrationField: 'validateRegistrationField',
			updateUserPayload: 'updateUserPayload',
		}),

		initialize() {

		},
		async claimAirdrop() {
			this.loading = true;
			await this.updateUserPayload({ payloadOption: 'claim_airdrop', payloadValue: 'false' });

			this.toggleClaimAirdropModal(false);
			this.loading = false;
		}
	}
});
</script>
<style scoped>
.modal-card {
	min-width: 500px;
}

@media screen and (max-width: 768px) {
	.modal-card {
		min-width: inherit;
	}
}
</style>
