<template>
	<div class="field">
		<div class="control is-expanded">
			<button class="button big-outlined-button transition-faster button-spacing loginButton"
				:class="{ 'is-loading': loading || googleProcessing }" :disabled="loading || googleProcessing"
				:params="{ clientId }" @click="googleTokenLogin" data-cy="googleButton">
				<span class="icon img" v-if="!loading && !googleProcessing">
					<img src="@/assets/wallet/google_logo.svg" class="buttonIcon" alt="Google Logo" />
				</span>
				<span class="ml-5px" v-if="signIn">{{ $t('auth.SIGN_UP_GOOGLE') }} </span>
				<span class="ml-5px" v-else-if="unlock">{{ $t('auth.UNLOCK_GOOGLE') }} </span>
				<span class="ml-5px" v-else-if="update">{{ $t('auth.UPDATE_GOOGLE') }} </span>
				<span class="ml-5px" v-else-if="recover">{{ $t('auth.RECOVER_GOOGLE') }} </span>
				<span class="ml-5px" v-else-if="showText">{{ $t('auth.LOG_IN_GOOGLE') }} </span>
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AuthMixin from '@/mixins/auth.mixin';

export default defineComponent({
	name: 'LoginGoogle',
	components: {},
	props: {
		signIn: {
			type: Boolean,
			required: false,
			default: false
		},
		unlock: {
			type: Boolean,
			required: false,
			default: false
		},
		update: {
			type: Boolean,
			required: false,
			default: false
		},
		recover: {
			type: Boolean,
			required: false,
			default: false
		},
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		showText: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	mixins: [AuthMixin],
	data() {
		return {
			hasRecoveryMethod: false,
			clientId: import.meta.env.VITE_GOOGLE_APP_ID,
			googleLibrarySrc: 'https://accounts.google.com/gsi/client',
			apiLoadIntitited: false,
			recoveryTypeId: 3,
			googleProcessing: false
		};
	},
	async mounted() {
		try {
			this.loadGApi();
			//this.hasRecoveryMethod = await this.hasRecovery(this.recoveryTypeId);
		} catch (err) {
			console.log('init error', err);
		}
	},
	methods: {
		// @vuese
		// Detect if user scrolled down to transform look
		processMethod(data: any) {
			this.$emit('processMethod', data);
		},
		async loadGApi() {
			return new Promise((resolve) => {
				// To resolve errors in nuxt3
				const isRunningInBrowser = typeof window !== 'undefined';

				if (!this.apiLoadIntitited && isRunningInBrowser) {
					const script = document.createElement('script');
					this.apiLoadIntitited = true;
					script.addEventListener('load', () => {
						resolve(window.google);
					});
					script.src = this.googleLibrarySrc;
					script.async = true;
					script.defer = true;
					document.head.appendChild(script);
				}
			});
		},
		onError(error: any) {
			this.googleProcessing = false;
			let errorMessage = error.error || error.err || error.message || JSON.stringify(error);
			this.logSentryError(errorMessage, {
				hasRecoveryMethod: this.hasRecoveryMethod,
				clientId: this.clientId,
				recoveryTypeId: this.recoveryTypeId
			});
			let errorText = error.error || error.err || 'Google login Error';

			if (String(errorText.toLowerCase()).includes('script not loaded correctly')) {
				errorText = 'google_script_blocked';
			}

			this.processMethod({
				success: false,
				error: errorText
			});
		},

		googleTokenLogin() {
			this.googleProcessing = true;

			try {
				window.google.accounts.oauth2
					.initTokenClient({
						client_id: import.meta.env.VITE_GOOGLE_APP_ID,
						scope: 'email profile',
						callback: (response: any) => {
							if (response.access_token) {
								this.onLogin(response);
							} else {
								this.onError(response);
							}
						}
					})
					.requestAccessToken();
			} catch (err) {
				this.onError(err);
			}
		},
		async onLogin(googleUser: any) {
			const request = new XMLHttpRequest();

			try {
				request.open('GET', 'https://www.googleapis.com/oauth2/v1/userinfo');
				request.setRequestHeader('Authorization', 'Bearer ' + googleUser.access_token);
				request.addEventListener('load', () => {
					let googlePayload = JSON.parse(request.response);
					const userID = googlePayload.id;
					const key = this.clientId + userID;
					const token = googleUser.access_token;
					this.processMethod({
						success: true,
						type: 'google',
						userID,
						key,
						token,
						recoveryTypeId: this.recoveryTypeId,
						email: googlePayload.email
					});
					this.googleProcessing = false;
					return;
				});
				request.addEventListener('error', () => {
					this.googleProcessing = false;
					console.error('XHR error');
				});

				request.send();
			} catch (error) {
				//Not sure what to do here - or if we want to do anything at all?!
				this.onError(error);
				console.error(`XHR error ${request.status}`);
			}
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.field {
	width: 100%;
}

.buttonIcon {
	width: 24px !important;
}
</style>
