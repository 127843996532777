<template>
  <!-- Language -->
  <div class="language-selector has-line-height-1" v-click-outside-element="closeDropdown">
    <div :class="{
      dropdown: true,
      active: dropdownOpen,
      mobile: isMobile
    }">
      <button class="button lang-button is-size-14 dark-btn has-text-weight-normal"
        @click="dropdownOpen = !dropdownOpen">
        <img :src="languages.find(lang => lang.code === $i18n.locale.split('-')[0].toLowerCase())?.flag" />
        <span class="has-text-weight-medium lang-name">{{ getLanguageParameter($i18n.locale, 'name') }}</span>
        <i class="fas fa-chevron-up arrow" :class="{
          active: dropdownOpen
        }"></i>
      </button>

      <div class="dropdown-items">
        <div v-for="language in languages" :key="language.code" class="lang-item has-text-grey-faded"
          @click="() => setLanguage(language.code)">
          <img :src="language.flag" />
          <span>{{ language.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import Cookie from 'js-cookie';
import Highcharts from 'highcharts';
import { activeLanguagesList, getLanguageParameter } from '../main';
import EventBus from '@/store/event-bus';

export default defineComponent({
  name: 'LanguageSelector',
  props: ['isMobile'],
  data() {
    return {
      dropdownOpen: false,
      languages: activeLanguagesList,
      morpherwallet: {}
    };
  },
  computed: {
    ...mapState(useUserStore, {
      user: (state) => state.data,
    }),
    ...mapState(useContractStore, {
      contract: (state) => state as any,
    }),

  },
  async mounted() {

    const months = JSON.parse(this.$t('chart-months'));
    const weekdays = JSON.parse(this.$t('chart-weekdays'));
    const shortMonths = JSON.parse(this.$t('chart-shortMonths'));

    Highcharts.setOptions({
      lang: { months, weekdays, shortMonths }
    });

    EventBus.$off('updateLocale', this.updateLocale);
    EventBus.$on('updateLocale', this.updateLocale);

    this.checkUserLocale()
  },
  unmounted() {
    EventBus.$off('updateLocale', this.updateLocale);
  },
  methods: {
    ...mapActions(useUserStore, {
      updateUserPayload: 'updateUserPayload',
    }),

    checkUserLocale() {

      if (this.$i18n.locale && this.user && this.user.payload && this.user.payload.app_lang !== this.$i18n.locale) {
        if ((!this.user.payload.app_lang || this.user.payload.app_lang == 'en') && this.$i18n.locale !== 'en') {
          if (this.user && this.user.payload && this.user.payload.app_lang !== String(this.$i18n.locale)) {
            this.updateUserPayload({ payloadOption: 'app_lang', payloadValue: String(this.$i18n.locale) });
          }
        } else {
          this.updateLocale(this.user.payload.app_lang)
        }
      }
    },
    getLanguageParameter(code: string, parameter: 'code' | 'name' | 'flag') {
      return getLanguageParameter(code, parameter);
    },
    updateLocale(locale: string) {
      this.setLanguage(locale)

    },
    setLanguage(lang: string) {

      if (!lang) {
        lang = 'en'
      }
      this.dropdownOpen = false;
      if (lang !== this.$i18n.locale) {
        this.$i18n.locale = lang;
        localStorage.setItem('locale', this.$i18n.locale || 'en')
        document.querySelector('html')?.setAttribute('lang', lang);
        if (lang === 'ar') document.querySelector('html')?.setAttribute('dir', 'rtl');
        else document.querySelector('html')?.setAttribute('dir', '');
        Cookie.set('locale', lang);

        if (this.user && this.user.payload && this.user.payload.app_lang !== lang) {
          this.updateUserPayload({ payloadOption: 'app_lang', payloadValue: lang });
        }

        const months = JSON.parse(this.$t('chart-months'));
        const weekdays = JSON.parse(this.$t('chart-weekdays'));
        const shortMonths = JSON.parse(this.$t('chart-shortMonths'));

        Highcharts.setOptions({
          lang: { months, weekdays, shortMonths }
        });

        if (this.contract && this.contract.morpherwallet) {
          this.contract.morpherwallet.setLanguage(lang);
        }

        this.moment.locale(lang);

        EventBus.$emit('localeUpdated', lang)

        this.moment.locale(this.$i18n.locale || 'en')
      }
    },
    closeDropdown() {
      if (this.dropdownOpen) {
        this.dropdownOpen = false;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.language-selector {
  display: flex;
  align-items: center;
  margin-right: auto;

  .dropdown {
    position: relative;

    &.mobile {
      .dropdown-items {
        min-width: 160px;
        right: 0;
        left: auto;
      }
    }

    .dropdown-items {
      position: absolute;
      bottom: 25px;
      left: 0;
      background: #fff;
      border: 1px solid #eae9ed;
      box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.1);
      border-radius: 13px;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      margin-bottom: 0.5rem;
      opacity: 0;
      visibility: hidden;
      transition: 200ms;
      min-width: 180px;

      .lang-item {
        text-align: left;
        padding: 0.5rem 0.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        +.lang-item {
          border-top: 1px solid #eae9ed;
        }
      }
    }

    &.active {
      .dropdown-items {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .lang-button {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    height: 30px;
    width: auto;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;

    >img {
      margin-right: 5px;
      width: 18px;
      height: 18px;
    }

    .lang-name {
      margin-right: 5px;
    }

    .arrow {
      transition: 200ms;
      transform-origin: center;

      &.active {
        transform: rotateZ(180deg);
      }
    }
  }

  .lang-item {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
      text-decoration: none;
    }

    >img {
      margin-right: 5px;
      width: 18px;
      height: 18px;
    }
  }
}
</style>
