import { defineStore } from 'pinia'
import { ERROR, ROOT, LOADING, SUCCESS } from '../mutation-types';
import to from 'await-to-js';
import type {
	resetPositionLimitsVariables,
	resetPositionLimits_resetPositionLimits as resetPositionLimitsReturn
} from '@/graphql/positions/types/resetPositionLimits';
import { PositionService } from '@/services/PositionService';
import { getStore } from '..';
let store = {
		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		status:  {} as any,
		markets:  {} as any,
}

export interface PositionState {
	id: string | null;
	direction: string | null;
	average_price: string | null;
	average_spread: string | null;
	average_leverage: string | null;
	long_shares: string | null;
	short_shares: string | null;
	timestamp: number | null;
	value: string | null;
	value_weight: string | null;
	total_return: string | null;
	total_return_percent: string | null;
	stop_loss: string | null;
	take_profit: string | null;
	market: {
		id: string | null;
		name: string | null;
		type: string | null;
		symbol: string | null;
		close: string | null;
		change: string | null;
		change_percent: string | null;
	};
}
export const usePositionsStore = defineStore('positions', {
  state: () => {
	store = getStore()

    const initialState: PositionState =  {
      id: null,
      direction: null,
      average_price: null,
      average_spread: null,
      average_leverage: null,
      long_shares: null,
      short_shares: null,
      timestamp: null,
      value: null,
      value_weight: null,
      total_return: null,
      total_return_percent: null,
      stop_loss: null,
      take_profit: null,
      market: {
        id: null,
        name: null,
        type: null,
        symbol: null,
        close: null,
        change: null,
        change_percent: null
      }
    };
    return initialState
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions:{
		async resetPositionLimits(params: resetPositionLimitsVariables) {
			try {
				store.status[LOADING](true);

				// Execute the suer update
				const [err, result] = await to(PositionService.resetPositionLimits(params));
				store.status[LOADING](false);

				if (err) {
					store.status[ERROR]({ message: 'ERROR_DEFAULT' })
					return false;
				} else {
					if (result.data?.resetPositionLimits){
						const data: resetPositionLimitsReturn = result.data?.resetPositionLimits;

						if (data && data.id === '00000000-0000-0000-0000-000000000000') {
							return data;
						}

						store.status[SUCCESS]('POSITION_LIMITS_CANCELLED');
					}
					return true;
				}
			} catch (err) {
				return store.status[ERROR]({ message: 'BAD_POSITION_LIMIT_DATA' })
				
			}
		}
	},
})