import type { IOptions, INetwork } from '@portis/web3';


import EventBus from  '@/store/event-bus';
import { getWalletClient } from './viem';



const portisConfig = async function(options: IOptions) {
	const sideChainNode: INetwork = {
		nodeUrl: import.meta.env.VITE_SIDECHAIN || '',
		chainId: String(import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID)
	};

	const Portis = (await import('@portis/web3')).default;


	const portis = new Portis(import.meta.env.VITE_PORTIS_API_KEY, sideChainNode, options);

	portis.onLogin(() => {
		EventBus.$emit('portisLogin');
	});
	portis.onLogout(() => {
		EventBus.$emit('portisLogout');
	});

	const walletClient = getWalletClient(portis.provider)


	return {
		portis,
		walletClient
	};
};

export { portisConfig };
