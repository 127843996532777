<template>
	<div class="formContainer">
		<div class="headerImage">
			<a href="https://www.morpher.com" target="_blank" alt="Morpher Homepage">
				<img class="image" src="@/assets/auth/morpher-logo.svg" alt="Morpher Logo" />
			</a>
			<!-- Page title for all states -->
			<h2 data-cy="logInTitle" v-if="loginVerifying" class="authTitle">{{ $t('auth.VERIFYING_YOUR_ACCOUNT_TITLE')
				}}</h2>
			<h2 data-cy="logInTitle" v-else-if="twoFA && twoFAAuthenticator" class="authTitle">{{ $t('auth.2FA_TITLE')
				}}</h2>
			<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'kyc'" class="authTitle">{{
				$t('TIER_INVESTOR_UPGRADE_HEAD') }}</h2>
			<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'new'" class="authTitle">{{
				$t('auth.NEW_WALLET_TITLE') }}</h2>
			<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'kyc_processing'" class="authTitle">{{
				$t('VERIFICATION_IN_PROGRESS') }}</h2>
			<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'method'" class="authTitle">{{
				$t('auth.LOGIN_METHOD_TITLE') }}</h2>
			<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'wallet'" class="authTitle">{{
				$t('auth.LOGIN_WALLET_TITLE') }}</h2>
			<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'migrated'" class="authTitle">{{
				$t('auth.WALLET_MIGRATED_TITLE') }}</h2>
			<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'migrating'" class="authTitle">{{
				$t('auth.MIGRATION_UNFINISHED_TITLE') }}</h2>

			<h2 data-cy="logInTitle" v-else-if="twoFA" class="authTitle">{{ $t('auth.2FA_HEADING_EMAIL') }}</h2>
			<h2 data-cy="logInTitle" v-else class="authTitle">{{ $t('auth.WELCOME_BACK') }}</h2>

			<!-- Page text for all states -->
			<div v-if="loginVerifying">
				<p ref="verifiction_text" data-cy="logInDescription" class="authText fade">
					{{ $t('auth.VERIFYING_YOUR_ACCOUNT_DESCRIPTION_1') }}</p>
			</div>
			<p data-cy="logInDescription" v-else-if="twoFA && twoFAAuthenticator" class="authText">{{
				$t('auth.2FA_DESCRIPTION_AUTH') }}</p>
			<p data-cy="logInDescription" v-else-if="$route.params.status == 'kyc'" class="authText">{{
				$t('KYC_REQUIRED_POINT_1') }}</p>
			<p data-cy="logInDescription" v-else-if="$route.params.status == 'new'" class="authText">{{
				$t('auth.NO_TRADING_ACCOUNT_FOUND') }}</p>
			<p data-cy="logInDescription" v-else-if="$route.params.status == 'kyc_processing'" class="authText">{{
				$t('KYC_REQUIRED_PROCESSING') }}</p>
			<p data-cy="logInDescription" v-else-if="$route.params.status == 'migrated'" class="authText">{{
				$t('auth.WALLET_MIGRATED_TEXT') }}</p>
			<p data-cy="logInDescription" v-else-if="$route.params.status == 'migrating'" class="authText">{{
				$t('auth.MIGRATION_UNFINISHED_TEXT', {
					type: $t('auth.LOGIN_WALLET_' + ($route.query.type || 'fortmatic'
					).toUpperCase())
				}) }}</p>
			<p data-cy="logInDescription" v-else-if="$route.params.status == 'method'" class="authText">{{
				$t('auth.LOGIN_METHOD_TEXT', { method: $t('auth.LOGIN_METHOD_' + wallet_type.toUpperCase()) }) }}</p>
			<p data-cy="logInDescription" v-else-if="$route.params.status == 'wallet'" class="authText">{{
				$t('auth.LOGIN_WALLET_TEXT', { wallet: $t('auth.LOGIN_WALLET_' + wallet_type.toUpperCase()) }) }}</p>
			<p data-cy="logInDescription" v-else-if="twoFA" class="authText">{{ $t('auth.2FA_DESCRIPTION') }}</p>
			<p data-cy="logInDescription" v-else class="authText">{{ $t('auth.LOGIN_TITLE') }}</p>
		</div>

		<div v-if="!config.application_live && $route.query && $route.query.adminoverride !== 'true'" class="authBox">
			{{ $t('MAINTANANCE_MODE') }}
		</div>
		<div class="authBox">
			<div v-show="!initialising">
				<!-- Login Processing  -->
				<div v-if="loginVerifying" class="pt-30">
					<p class="p">
						<img class="loader-icon" src="@/assets/icons/spinner.svg" />
					</p>

					<p class="loginLink">
						<span>{{ $t('auth.ALREADY_HAVE_ACCOUNT') }}&nbsp;</span>
						<a @click="loginVerifying = false" class="login-router transition-faster">
							<span data-cy="signUpButton">
								{{ $t('auth.LOGIN') }}
							</span>
						</a>
					</p>

				</div>
				<!-- KYC Blocked - force user to KYC -->
				<div v-else-if="$route.params.status == 'kyc'">
					<br>
					<p class="is-size-14" v-html="$t('KYC_REQUIRED_POINT_2')">
					</p>
					<br>
					<p class="is-size-14">
						{{ $t('KYC_REQUIRED_POINT_3') }}
					</p>
					<br>
					<KYCButton green :required="true"></KYCButton>

					<p class="loginLink">
						<a @click="logout()" class="login-router transition-faster">
							<span>{{ $t('auth.CANCEL') }}</span>
						</a>
					</p>


				</div>
				<!-- wallet migrated -->
				<div v-else-if="$route.params.status == 'migrated'">


					<button class="button big-button-new is-buy transition-faster mt-20"
						:class="{ 'is-loading': loginProcessing }"
						style="display: block; max-width: 320px; margin-left: auto; margin-right: auto;"
						@click="loginMorpher">
						<span style="margin-left: 5px">{{ $t('auth.START_MORPHER') }}</span>
					</button>

				</div>

				<!-- wallet migrating -->
				<div v-else-if="$route.params.status == 'migrating'">


					<LoginMetamask :showText="true" v-if="$route.query.type == 'metamask'"
						@checkMetamask="checkMetamask" :isMetamaskSupported="isMetamaskSupported" class="mt-15">
					</LoginMetamask>


					<button v-else-if="$route.query.type == 'portis'"
						class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loginProcessingPortis }" @click="loginPortis" data-cy="portisButton">
						<span class="icon img">
							<img src="@/assets/wallet/portis_logo.svg" class="buttonIcon second" alt="Portis Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_PORTIS') }} </span>
					</button>

					<button v-else class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loginProcessingFortmatic }" @click="loginFortmatic"
						data-cy="fortmaticButton">
						<span class="icon img">
							<img src="@/assets/wallet/fortmatic_logo.png" class="buttonIcon" alt="Fortmatic Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_FORTMATIC') }} </span>
					</button>


					<button class="button big-button-new transition-faster mt-20"
						:class="{ 'is-loading': loginProcessing }"
						style="display: block; max-width: 320px; margin-left: auto; margin-right: auto;"
						@click="walletNewBack">
						<span style="margin-left: 5px">{{ $t('auth.BACK_SIGNIN') }}</span>
					</button>

					<div class="error" v-if="loginError">
						<p data-cy="loginError">
							⚠️ <span v-html="loginError"></span>
							<router-link v-if="showRecovery" to="/recovery" class="login-router transition-faster">
								<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
							</router-link>
						</p>
					</div>

					<p class="loginLink">
						<span>{{ $t('auth.DO_NOT_HAVE_WALLET') }} &nbsp;</span>
						<router-link to="/register" class="login-router transition-faster">
							<span data-cy="signUpButton">
								{{ $t('auth.SIGNUP') }}
							</span>
						</router-link>
					</p>



				</div>


				<!-- New wallet - register -->
				<div v-else-if="$route.params.status == 'new'">
					<div class="wallet-box mt-15">
						<div class="columns is-vcentered is-centered ">
							<div class="column is-narrow">
								<img v-if="blockie" class="blockie" :src="blockie" />
							</div>
							<div class="column has-text-left">
								<p class="is-size-14" v-if="email">
									<b>{{ formatEthAddress(email) }}</b>

								</p>

								<p class="is-size-14 mt-5px" v-if="eth_address">

									{{ formatEthAddressLong(eth_address) }}
								</p>
							</div>
						</div>

						<div class="columns is-vcentered is-centered div-line">
							<div class="column is-narrow">
								<img src="@/assets/icons/new/alert-triangle.svg" />
							</div>
							<div class="column has-text-left">


								<p class="is-size-12" v-html="$t('auth.NO_TRADING_ACCOUNT')" @click="walletNewBack">

								</p>
							</div>
						</div>
					</div>

					<button class="button big-button-new is-buy transition-faster mt-20"
						:class="{ 'is-loading': loginProcessing }"
						style="display: block; max-width: 320px; margin-left: auto; margin-right: auto;"
						@click="signupWallet">
						<span style="margin-left: 5px">{{ $t('CONTINUE') }}</span>
					</button>


					<p class="loginLink">
						<a @click="walletNewBack()" class="login-router transition-faster">
							<span>{{ $t('BACK') }}</span>
						</a>
					</p>

				</div>
				<!-- KYC Blocked - kyc is processing -->
				<div v-else-if="$route.params.status == 'kyc_processing'">
					<p v-if="user && user.airdrop && user.airdrop.kyc_date"
						class="is-size-12 mt-20 is-flex has-text-centered"
						style="align-content: center; justify-content: center;">
						<img class="mr-5px" src="@/assets/icons/new/waiting.svg" />

						{{ $t('KYC_INITIATED_ON', { date: (formatDate(user.airdrop.kyc_date)) }) }}

					</p>


					<button class="button big-button-new-white is-buy transition-faster mt-20"
						style="display: block; max-width: 320px; margin-left: auto; margin-right: auto;"
						@click="openLink('mailto:contact@help.morpher.com')">
						<span style="margin-left: 5px">{{ $t('CONTACT_SUPPORT') }}</span>
					</button>


					<p class="loginLink">
						<a @click="logout()" class="login-router transition-faster">
							<span>{{ $t('auth.CANCEL') }}</span>
						</a>
					</p>
				</div>
				<!-- Attempted login with incorrect wallet -->
				<div class="pt-30" v-else-if="$route.params.status == 'wallet'">
					<LoginMetamask :showText="true" v-if="wallet_type == 'metamask'" @checkMetamask="checkMetamask"
						:isMetamaskSupported="isMetamaskSupported"></LoginMetamask>

					<button v-if="wallet_type == 'fortmatic'"
						class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loginProcessingFortmatic }" @click="loginFortmatic"
						data-cy="fortmaticButton">
						<span class="icon img">
							<img src="@/assets/wallet/fortmatic_logo.png" class="buttonIcon" alt="Fortmatic Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_FORTMATIC') }} </span>
					</button>

					<button v-if="wallet_type == 'portis'"
						class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loginProcessingPortis }" @click="loginPortis" data-cy="portisButton">
						<span class="icon img">
							<img src="@/assets/wallet/portis_logo.svg" class="buttonIcon second" alt="Portis Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_PORTIS') }} </span>
					</button>

					<div class="error" v-if="loginError">
						<p data-cy="loginError">
							⚠️ <span v-html="loginError"></span>
							<router-link v-if="showRecovery" to="/recovery" class="login-router transition-faster">
								<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
							</router-link>
						</p>
					</div>

					<router-link to="/login" class="login-router transition-faster">
						<span data-cy="backUpButton">
							&lt; {{ $t('auth.BACK') }}
						</span>
					</router-link>
				</div>
				<!-- Attempted login with incorrect method - morpher wallet -->
				<div class="pt-30" v-else-if="$route.params.status == 'method'">
					<LoginGoogle :showText="true" v-if="wallet_type == 'google'" @processMethod="processMethod"
						:loading="loginProcessingGoogle"></LoginGoogle>
					<LoginApple :showText="true" v-if="wallet_type == 'apple'" @processMethod="processMethod"
						:loading="loginProcessingApple"></LoginApple>

					<div v-if="wallet_type == 'email'">
						<div class="field">
							<label class="label">{{ $t('auth.EMAIL') }}</label>
							<div class="control">
								<input type="email" class="input" ref="login_email" @keydown="checkKeyPress"
									data-cy="walletEmail" :placeholder="$t('auth.ENTER_EMAIL')" name="walletEmail"
									v-model="walletEmail" />
							</div>
						</div>

						<div class="field">
							<label class="label">{{ $t('auth.PASSWORD') }}</label>
							<div class="control">
								<input type="password" class="input" ref="login_password" @keydown="checkKeyPress"
									data-cy="walletPassword" name="walletPassword"
									:placeholder="$t('auth.ENTER_PASSWORD')" v-model="walletPassword" />
								<button class="password-toggle" v-on:click="togglePasswordVisibility"
									data-cy="password-toggle-button">
									<img v-if="passwordIsVisible" class="image"
										src="@/assets/auth/password-visible-icon.svg" alt="Visible Button" />
									<img v-if="!passwordIsVisible" class="image"
										src="@/assets/auth/password-invisible-icon.svg" alt="Invisible Button" />
								</button>
							</div>
						</div>

						<button type="submit" @click="() => {
							login('email');
						}
							" data-cy="submit" :disabled="!walletEmail || !walletPassword" :class="{ 'is-loading': loginProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('auth.LOGIN') }}</span>
						</button>

					</div>

					<div class="error" v-if="loginError">
						<p data-cy="loginError">
							⚠️ <span v-html="loginError"></span>
							<router-link v-if="showRecovery" to="/recovery" class="login-router transition-faster">
								<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
							</router-link>
						</p>
					</div>

					<router-link to="/login" class="login-router transition-faster">
						<span data-cy="backUpButton">
							&lt; {{ $t('auth.BACK') }}
						</span>
					</router-link>

				</div>
				<!-- 2fa required -->
				<div v-else-if="twoFA">
					<div class="field">
						<label class="label">{{ twoFAAuthenticator ? $t('auth.AUTH_CODE') : $t('auth.EMAIL_CODE')
							}}</label>
						<div class="control">
							<input type="email" class="input" ref="twofa_code" data-cy="twofaCode"
								:placeholder="twoFAAuthenticator ? $t('auth.ENTER_CODE') : $t('auth.EMAIL_CODE')"
								name="walletEmail" v-model="twofaCode" />
						</div>

						<button type="submit" @click="submit2fa" data-cy="submit"
							:class="{ 'is-loading': loginProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('auth.SUBMIT') }}</span>
						</button>

						<div class="error" v-if="loginError">
							<p data-cy="loginError">
								⚠️ <span v-html="loginError"></span>
								<router-link v-if="showRecovery" to="/recovery" class="login-router transition-faster">
									<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
								</router-link>
							</p>
						</div>

						<p class="loginLink" v-if="twoFAEmail">
							<span>{{ $t('auth.DIDNT_GET_CODE') }}</span>
							<a @click="twoFACodeResend" class="login-router transition-faster" v-if="!twoFASending">
								<span data-cy="signUpButton">
									{{ $t('auth.SEND_AGAIN') }}
								</span>
							</a>
						</p>

						<p class="loginLink">
							<a @click="twoFA = false" class="login-router transition-faster">
								<span data-cy="signUpButton">
									{{ $t('CANCEL') }}
								</span>
							</a>
						</p>

					</div>
				</div>

				<!-- welcome back - login again with previous method -->
				<div class="pt-30"
					v-else-if="default_wallet && default_wallet !== 'email' && default_wallet !== 'morpherwallet'">

					<LoginMetamask :showText="true" v-if="default_wallet == 'metamask'" @checkMetamask="checkMetamask"
						:isMetamaskSupported="isMetamaskSupported"></LoginMetamask>
					<LoginGoogle :showText="true" v-if="default_wallet == 'google'" @processMethod="processMethod"
						:loading="loginProcessingGoogle"></LoginGoogle>
					<LoginApple :showText="true" v-if="default_wallet == 'apple'" @processMethod="processMethod"
						:loading="loginProcessingApple"></LoginApple>

					<button v-if="default_wallet == 'walletconnect'"
						class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loginProcessing }" @click="walletConnectOpen" data-cy="fortmaticButton">
						<span class="icon img">
							<img src="@/assets/wallet/walletconnect.svg" class="buttonIcon" alt="Fortmatic Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_WALLET_CONNECT') }} </span>
					</button>

					<button v-if="default_wallet == 'fortmatic'"
						class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loginProcessingFortmatic }" @click="loginFortmatic"
						data-cy="fortmaticButton">
						<span class="icon img">
							<img src="@/assets/wallet/fortmatic_logo.png" class="buttonIcon" alt="Fortmatic Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_FORTMATIC') }} </span>
					</button>

					<button v-if="default_wallet == 'portis'"
						class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
						:class="{ 'is-loading': loginProcessingPortis }" @click="loginPortis" data-cy="portisButton">
						<span class="icon img">
							<img src="@/assets/wallet/portis_logo.svg" class="buttonIcon second" alt="Portis Logo" />
						</span>
						<span class="ml-5px">{{ $t('auth.LOG_IN_PORTIS') }} </span>
					</button>

					<div class="error" v-if="loginError">
						<p data-cy="loginError">
							⚠️ <span v-html="loginError"></span>
							<router-link v-if="showRecovery" to="/recovery" class="login-router transition-faster">
								<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
							</router-link>
						</p>
					</div>

					<p class="loginLink">
						<span>{{ $t('auth.NOT_PREFERRED_METHOD') }}</span>
						<span @click="clearDefault" class="login-router transition-faster">
							<span data-cy="signUpButton">
								&nbsp;{{ $t('auth.SHOW_OPTIONS') }}
							</span>
						</span>
					</p>

					<p class="loginLink">
						<span>{{ $t('auth.DO_NOT_HAVE_WALLET') }} &nbsp;</span>
						<router-link to="/register" class="login-router transition-faster">
							<span data-cy="signUpButton">
								{{ $t('auth.SIGNUP') }}
							</span>
						</router-link>
					</p>


				</div>


				<!-- Defailt page - login again with email/password - show other options -->
				<div v-else>
					<!-- Signin with email/password -->
					<div>
						<div class="field">
							<label class="label">{{ $t('auth.EMAIL') }}</label>
							<div class="control">
								<input type="email" class="input" ref="login_email" @keydown="checkKeyPress"
									data-cy="walletEmail" :placeholder="$t('auth.ENTER_EMAIL')" name="walletEmail"
									v-model="walletEmail" />
							</div>
						</div>

						<div class="field">
							<label class="label">{{ $t('auth.PASSWORD') }}</label>
							<div class="control">
								<input type="password" class="input" ref="login_password" @keydown="checkKeyPress"
									data-cy="walletPassword" name="walletPassword"
									:placeholder="$t('auth.ENTER_PASSWORD')" v-model="walletPassword" />
								<button class="password-toggle" v-on:click="togglePasswordVisibility"
									data-cy="password-toggle-button">
									<img v-if="passwordIsVisible" class="image"
										src="@/assets/auth/password-visible-icon.svg" alt="Visible Button" />
									<img v-if="!passwordIsVisible" class="image"
										src="@/assets/auth/password-invisible-icon.svg" alt="Invisible Button" />
								</button>
							</div>
						</div>

						<button type="submit" @click="() => {
							login('email');
						}
							" data-cy="submit" :disabled="!walletEmail || !walletPassword" :class="{ 'is-loading': loginProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('auth.LOGIN') }}</span>
						</button>


						<div class="error" v-if="loginError">
							<p data-cy="loginError">
								⚠️ <span v-html="loginError"></span>
								<router-link v-if="showRecovery" to="/recovery" class="login-router transition-faster">
									<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
								</router-link>
							</p>
						</div>

						<div class="loginDivider"></div>

						<h1 class="otherTitle">{{ $t('auth.OTHER_LOGIN_OPTIONS') }}:</h1>
						<div class="loginOptions">
							<LoginGoogle @processMethod="processMethod" :loading="loginProcessingGoogle"></LoginGoogle>
							<LoginApple @processMethod="processMethod" :loading="loginProcessingApple"></LoginApple>
							<LoginMetamask :showText="false" @checkMetamask="checkMetamask"
								:isMetamaskSupported="isMetamaskSupported"></LoginMetamask>
							<div class="more">
								<p class="loginLink">
									<router-link to="/login/more" class="login-router transition-faster">
										<span class="moreSpan">{{ $t('auth.MORE') }} > </span>
									</router-link>
								</p>
							</div>
						</div>

						<button class="button big-outlined-button transition-faster button-spacing loginButton mt-15"
							:class="{ 'is-loading': loginProcessing }" @click="walletConnectOpen"
							data-cy="fortmaticButton" v-if="walletConnectEnabled">
							<span class="icon img">
								<img src="@/assets/wallet/walletconnect.svg" class="buttonIcon" alt="Fortmatic Logo" />
							</span>
							<span class="ml-5px">{{ $t('auth.LOG_IN_WALLET_CONNECT') }} </span>
						</button>


						<div v-show="showMetamaskHelp">
							<p class="help is-size-14">
								{{ $t('auth.METAMASK_NOT_DETECTED') }}
							</p>
						</div>
						<p class="loginLink">
							{{ $t('auth.FORGOT_PASSWORD') }}
							<router-link to="/login/recover" class="login-router transition-faster">
								<span>{{ $t('auth.RECOVER_YOUR_WALLET') }}</span>
							</router-link>
						</p>

						<p class="loginLink">
							<span>{{ $t('auth.DO_NOT_HAVE_WALLET') }}&nbsp;</span>
							<router-link to="/register" class="login-router transition-faster">
								<span data-cy="signUpButton">
									{{ $t('auth.SIGNUP') }}
								</span>
							</router-link>
						</p>
					</div>
				</div>
			</div>
		</div>

		<!-- <vue-recaptcha
			ref="recaptcha"
			size="invisible"
			:sitekey="recaptchaSiteKey"
			:load-recaptcha-script="true"
			@verify="onCaptchaVerified"
			@error="onCaptchaError"
			@expired="onCaptchaExpired"
			@render="onCaptchaLoaded"
		/> -->
	</div>
</template>

<script lang="ts">
//vuex
import { ERROR } from '@/store/mutation-types';

//import { getDictionaryValue } from '@/helpers/wallet/dictionary';
import { accessTokenName, refreshTokenName, getUserID } from '@/helpers/utils';
import deviceType from '@/helpers/deviceType';
import { UserService } from '@/services/UserService';
import LoginApple from './LoginApple.vue';
import LoginGoogle from './LoginGoogle.vue';
import LoginMetamask from './LoginMetamask.vue';

import * as blockies from 'ethereum-blockies-png';
//import RecaptchaMixin from '@/mixins/recaptcha.mixin';
import AuthMixin from '@/mixins/auth.mixin';
//import VueRecaptcha from 'vue-recaptcha';
import Cookie from 'js-cookie';
import { morpherWalletConfig } from '@/helpers/morpherwallet';
import MetaMaskOnboarding from '@metamask/onboarding';
import KYCButton from '../KYCButton.vue';
import { fortmaticConfig } from '@/helpers/fortmatic';
import { portisConfig } from '@/helpers/portis';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { useUserStore } from '@/store/modules/user';
import { useContractStore } from '@/store/modules/contract';
import { useAdminStore } from '@/store/modules/admin';
import { defineComponent } from 'vue';
import { getWalletClient } from '@/helpers/viem';
import { toHex } from 'viem';
import type { TAddress, TMorpherWalletInit } from '@/types/graphql-global-types';



/* @group Components_Auth */
/*
  <h4> <b>Login Form</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'WalletLogin',
	components: { LoginApple, LoginGoogle, LoginMetamask, KYCButton }, // 'vue-recaptcha': VueRecaptcha
	mixins: [AuthMixin], // RecaptchaMixin
	data() {
		return {
			passwordIsVisible: false,
			walletEmail: '',
			walletPassword: '',
			walletError: '',
			loginUser: {} as any,
			initialising: true,
			loginVerifying: false,
			loginProcessing: false,
			loginProcessingGoogle: false,
			isMetamaskSupported: false,
			showMetamaskHelp: false,
			loginProcessingApple: false,
			loginProcessingFortmatic: false,
			loginProcessingPortis: false,
			showRecovery: false,
			walletClientlogin: undefined as any | undefined,
			walletClientFortmatic: undefined as any | undefined,
			walletClientPortis: undefined as any | undefined,
			morpherWalletInit: {} as any,
			default_wallet: '',
			twoFA: false,
			twoFAAuthenticator: false,
			twoFAEmail: false,
			twofaCode: '',
			twoFASending: false,
			loginError: '',
			kycStatus: '',
			wallet_type: 'portis',
			loading: {
				fortmatic: false,
				portis: false,
				metamask: false,
				morpherwallet: false
			},
			loaded: {
				fortmatic: false,
				fortmaticInterval: 0 as null | NodeJS.Timeout | number,
				fortmaticLoggedIn: false,
				morpherWalletLoggedIn: false
			},
			fortmatic: null as any,
			portis: null as any,
			walletTimer: null as null | NodeJS.Timeout | number,
			verifycount: 0,
			interval: null as null | NodeJS.Timeout,
			blockie: '',
			email: '',
			eth_address: '',
			airdrop_id: '',
			referred_by: '',
			custom_invite: '',
			recovery_type: null as null | number,
			walletConnectEnabled: import.meta.env.VITE_MODE !== 'production',
			walletConnectUri: '',
			walletconnectProvider: null as any,
			morpherwallet: null as any,
			active_eth_address: '',
			metamaskEthAddress: '',
			metamaskAlreadyRegistered: false,
			logonError: '',
			name: ''
		};
	},
	computed: {
		...mapState(useAdminStore, {
			config: state => state.config,
		}),
		...mapState(useUserStore, {
			user: state => state.data,
		}),
		...mapState(useContractStore, {
			contract: state => state as any,
		})
	},
	watch: {

		loginVerifying(newValue) {
			if (newValue) {
				this.verifycount = 1;

				if (this.interval) {
					clearInterval(this.interval)
					this.interval = null;
				}
				this.interval = setInterval(() => {
					this.verifycount += 1;
					if (this.verifycount == 3) {
						if (this.$refs.verifiction_text) {
							(this.$refs.verifiction_text as InstanceType<typeof HTMLParagraphElement>).className = 'authText fade2'
						}
						if (this.interval) clearInterval(this.interval)
						this.interval = null;

					}
					if (this.$refs.verifiction_text) {

						(this.$refs.verifiction_text as InstanceType<typeof HTMLParagraphElement>).innerText = this.$t('auth.VERIFYING_YOUR_ACCOUNT_DESCRIPTION_' + this.verifycount);
					}


				}, 3000)
			} else {
				if (this.interval) {
					clearInterval(this.interval)
					this.interval = null;
				}
			}

		},

		walletError(newValue) {
			if (newValue) {
				// If there is a logon error then log it to sentry
				this.logSentryError('Logon Error:' + newValue);
				this.error({ message: 'WALLET_CONNECT_ERROR' });
			}
		},
		user() {
			if (this.user && this.user.kyc_status) {
				this.kycStatus = this.user.kyc_status;

				if (this.$route.params.status == 'kyc') {
					if (this.kycStatus == 'input complete' || this.kycStatus == 'review') {
						this.$router.push('/login/kyc_processing').catch(() => { });
					}
				}
			}

		},
		twofaCode(nv) {
			if (nv && nv.length == 6) {
				this.submit2fa();
			}
		}
	},
	async mounted() {
		// Fill emailOrUsernameStorage from url 'account' query parameter
		if (this.$route.query.account !== undefined) {
			localStorage.setItem('emailOrUsernameStorage', this.$route.query.account)
		}

		if (localStorage.getItem('walletType')) {
			const walletType = localStorage.getItem('walletType');
			if (walletType == 'morpherwallet') {
				this.default_wallet = localStorage.getItem('walletLoginMethod') || '';
			} else {
				this.default_wallet = walletType || '';
			}

		}

		// browsers that support metamask
		const browserMetamask = ['chrome', 'brave', 'opera', 'yandex', 'firefox', 'edge'];

		// return only the first word od the browser name
		if (navigator.sayswho) {
			const browser = navigator.sayswho.replace(/ .*/, '').toLowerCase();

			if (browserMetamask.includes(browser) || deviceType.isAnyMobile()) {
				this.isMetamaskSupported = true;
			}
		}

		this.airdrop_id = this.$route.query.airdrop_id ? this.$route.query.airdrop_id : null;
		this.referred_by = this.$route.query.referred_by ? this.$route.query.referred_by : null;
		this.custom_invite = this.$route.query.custom_invite ? this.$route.query.custom_invite : null;

		if (this.referred_by) localStorage.setItem('airdrop_referred_by', this.referred_by);
		else this.referred_by = localStorage.getItem('airdrop_referred_by') || '';

		if (this.custom_invite) localStorage.setItem('airdrop_custom_invite', this.custom_invite);
		else this.custom_invite = localStorage.getItem('airdrop_custom_invite') || '';

		this.initialising = false;

		this.initMorpherWallet();

		this.setInputFocus();

		if (this.$route.params.status == 'method') {
			if (!this.wallet_type) {
				await this.$router.push('/login').catch(() => { });
			}
		}

		if (this.$route.params.status == 'wallet') {
			if (!this.wallet_type) {
				await this.$router.push('/login').catch(() => { });
			}
		}

		if (this.$route.params.status == 'new') {
			if (!this.eth_address) {
				await this.$router.push('/login').catch(() => { });
			}
		}

		if (this.$route.params.status == 'kyc') {
			if (!this.user || !this.user.kyc_status) {
				await this.$router.push('/login').catch(() => { });
			}
			this.kycStatus = this.user?.kyc_status || '';

			if (this.kycStatus == 'input complete' || this.kycStatus == 'review') {
				await this.$router.push('/login/kyc_processing').catch(() => { });
			}
		}

		this.initWalletConnect()

	},
	beforeUnmount() {
		if (this.morpherwallet) {
			this.morpherwallet.onLogin(() => { });
		}
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR
		}),
		...mapActions(useUserStore, {
			loginWallet: 'loginWallet',
			confirmWallet: 'confirmWallet',
			logout: 'logout',
		}),
		...mapActions(useContractStore, {
			setWalletClient: 'setWalletClient',
			setMorpherWalletProvider: 'setMorpherWalletProvider',
		}),
		async walletConnectOpen() {

			const walletClient = getWalletClient(this.walletconnectProvider)


			await this.walletconnectProvider.disconnect()

			const accounts = await this.walletconnectProvider.enable()

			if (!accounts || accounts.length == 0) {
				console.log('no account selected')
				return
			}

			this.walletClientlogin = walletClient;
			this.setWalletClient(walletClient);

			let walletEmail = ''
			this.stopTimer();
			this.loginVerifying = true;
			// remove the wallet logon event so that it doest get called if the user logs on in the system
			let eth_address = accounts[0].toLowerCase();
			const user_id = await getUserID(eth_address, 'morpherwallet');

			if (eth_address) {
				this.eth_address = eth_address;
				this.blockie = blockies.createDataURL({
					seed: eth_address.toLowerCase()
				});
			}
			if (walletEmail) this.email = walletEmail;

			const result = await this.loginWallet({
				variables: {
					eth_address,
					user_id,
					wallet_type: 'walletconnect'
				},
				isTokenLogin: false,
				email: walletEmail,
				walletClient: this.walletClientlogin
			});

			await this.walletconnectProvider.request({
				"method": "wallet_addEthereumChain",
				"params": [
					{
						"chainId": toHex(80002),
						"chainName": "Polygon Amoy",
						"rpcUrls": [
							"https://polygon-amoy.infura.io/v3/598403d89a3243edb17eacce90925614"
						],
						"iconUrls": [
							"https://xdaichain.com/fake/example/url/xdai.svg",
							"https://xdaichain.com/fake/example/url/xdai.png"
						],
						"nativeCurrency": {
							"name": "MPHgas",
							"symbol": "MPHgas",
							"decimals": 18
						},
						"blockExplorerUrls": [
							"https://amoy.polygonscan.com/"
						]
					}
				]
			})

			await this.walletconnectProvider.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: toHex(80002) }], });


			if (result && result.error) {
				this.loginVerifying = false;
				let accountNotFound = false;

				if (
					result.error.graphQLErrors &&
					result.error.graphQLErrors[0] &&
					result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
				) {
					accountNotFound = true;
				}

				if (accountNotFound) {
					const userData = await UserService.getUserData({ email: walletEmail });

					if (userData && userData.data.getUserData) {
						const user = userData.data.getUserData;
						if (user && user.wallet_type && user.wallet_type !== 'morpherwallet') {
							this.loginProcessing = false;
							this.loginProcessingGoogle = false;
							this.loginProcessingApple = false;

							this.morpherwallet.logout();
							this.morpherwallet.hideWallet();
							this.wallet_type = user.wallet_type;
							await this.$router.push('/login/wallet').catch(() => { });
							return;
						} else if (user && user.wallet_type && user.wallet_type === 'morpherwallet') {
							if (user.login && Number(user.login) === 3) {
								this.wallet_type = 'google';
								await this.$router.push('/login/method').catch(() => { });

								return;
							}

							if (user.login && Number(user.login) === 6) {
								this.wallet_type = 'apple';
								await this.$router.push('/login/method').catch(() => { });
								return;
							}

							if (user.login && Number(user.login) === 1) {
								this.wallet_type = 'email';
								await this.$router.push('/login/method').catch(() => { });
								return;
							}
						}

						if (
							user &&
							user.eth_address &&
							eth_address &&
							user.eth_address.toLowerCase() !== eth_address.substring(0, 6).toLowerCase()
						) {
							this.loginProcessing = false;
							this.loginProcessingGoogle = false;
							this.loginProcessingApple = false;

							this.wallet_type = 'morpherwallet';
							await this.$router.push('/login/email').catch(() => { });
							this.active_eth_address = user.eth_address.toLowerCase();
							return;
						}
					}
					this.loginProcessing = false;
					this.loginProcessingGoogle = false;
					this.loginProcessingApple = false;

					this.wallet_type = 'morpherwallet';
					this.eth_address = eth_address;
					this.blockie = blockies.createDataURL({
						seed: eth_address.toLowerCase()
					});

					await this.$router.push('/login/new').catch(() => { });
					return;
				}
			}

			// Display the email address updated notification if the email address was updated
			if (result && result.old_address) {
				this.$buefy.dialog.alert({
					title: this.$t('auth.EMAIL_UPDATED'),
					message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
						newEmail: result.email,
						oldEmail: result.old_address
					}),
					confirmText: this.$t('OK')
				});
			}

			localStorage.setItem('walletType', 'walletconnect');

			localStorage.setItem('walletLoginMethod', '');

			// if the user is not registered then register the user
			if (!result) {
				this.loginVerifying = false;
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;
			} else if (result === 'register') {
				//this.walletConfirm('morpherwallet', eth_address, null);
			} else {
				setTimeout(() => {
					this.loginVerifying = false;
				}, 1000)

				// store the email for defaulting next tme the user logs in
				if (result) localStorage.setItem('emailOrUsernameStorage', walletEmail.toLowerCase());
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;
			}


			return

		},
		async initWalletConnect() {

			try {

				this.walletconnectProvider = await EthereumProvider.init({
					projectId: '4823d29af138b15b9c4cec762fb1916e',
					metadata: {
						name: 'Morpher',
						description: 'Morpher Trading Web Application',
						url: 'https://www.morpher.com', // origin must match your domain & subdomain
						icons: ['https://www.morpher.com/favicon.ico']
					},
					showQrModal: true,
					//chains: [210],

					optionalChains: [137],

					/*Optional - Add custom RPCs for each supported chain*/
					rpcMap: {
						137: 'http://ec2-3-68-113-82.eu-central-1.compute.amazonaws.com:8546'

					}
				})



				//  Initialize the provider
				// const provider = await UniversalProvider.init({
				// projectId: '4823d29af138b15b9c4cec762fb1916e',
				// metadata: {
				// 	name: 'React App',
				// 	description: 'React App for WalletConnect',
				// 	url: 'https://walletconnect.com/',
				// 	icons: ['https://avatars.githubusercontent.com/u/37784886']
				// },
				// client: undefined // optional instance of @walletconnect/sign-client
				// })

				this.walletconnectProvider.on('display_uri', (uri: any) => {
					this.walletConnectUri = uri
				})

				this.walletconnectProvider.on('accountsChanged', (account: any) => {
					console.log('account', account)
				})

				this.walletconnectProvider.on("connect", (accounts: any) => {
					console.log('accounts', accounts)

					console.info('connect', this.walletconnectProvider.accounts)
					//modal.closeModal()
				});



				//  create sub providers for each namespace/chain
				// await provider.connect({
				// 	optionalNamespaces: {
				// 		eip155: {
				// 		methods: [
				// 			'eth_sendTransaction',
				// 			'eth_signTransaction',
				// 			'eth_sign',
				// 			'personal_sign',
				// 			'eth_signTypedData'
				// 		],
				// 		chains: ['eip155:80002', 'eip155:210'],
				// 		events: ['chainChanged', 'accountsChanged'],
				// 		rpcMap: {
				// 			80002:
				// 			'https://polygon-amoy.infura.io/v3/598403d89a3243edb17eacce90925614',
				// 			210: 'https://sidechain-test.morpher.com'

				// 		}
				// 		}
				// 	},
				// 	// pairingTopic: '<123...topic>', // optional topic to connect to
				// 	skipPairing: false // optional to skip pairing ( later it can be resumed by invoking .pair())
				// })


			} catch (err: any) {
				console.log('error initializing wallet - ' + err.toString())
			}


		},
		openLink(url: string) {
			window.open(url, '_blank');
		},
		togglePasswordVisibility() {
			this.passwordIsVisible = !this.passwordIsVisible;
			(this.$refs.login_password as InstanceType<typeof HTMLInputElement>).type = this.passwordIsVisible ? 'text' : 'password';
		},
		setInputFocus() {
			const emailElement = (this.$refs.login_email as InstanceType<typeof HTMLInputElement>);

			if (emailElement) {
				setTimeout(() => {
					emailElement.focus();
				}, 10);

			} else {
				setTimeout(() => {
					this.setInputFocus();
				}, 50)
			}

		},
		async submit2fa() {
			if (!this.morpherWalletInit) {
				await this.initMorpherWallet();
			}
			this.loginProcessing = true;
			this.loginError = '';
			this.morpherwallet = this.morpherWalletInit.morpherwallet;
			this.walletClientlogin = this.morpherWalletInit.walletClient;
			this.setWalletClient(this.walletClientlogin);

			this.morpherwallet.loginWallet2fa(this.twofaCode);
		},
		async initMorpherWallet() {
			if (this.contract && this.contract.morpherwallet) {
				this.morpherWalletInit = {
					morpherwallet: this.contract.morpherwallet,
					provider: this.contract.provider,
					walletClient: this.contract.walletClient
				};
			} else {
				this.morpherWalletInit = await morpherWalletConfig();
				this.morpherWalletInit.morpherwallet.setLanguage(this.$i18n?.locale || 'en');

			}
		},

		async clearStorage() {
			localStorage.removeItem(accessTokenName);
			localStorage.removeItem(refreshTokenName);
			localStorage.removeItem('emailOrUsernameStorage');
			localStorage.removeItem('morpher-role');
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('tokenStorage');
			localStorage.removeItem('eth_address');
		},

		async recaptchaError() {
			//this.loginProcessing = false;
		},
		async reloadPage(fortmaticNoAccountFound = false) {
			// remove all old cached items
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			if (fortmaticNoAccountFound) return;

			// reload the page if the previous reload was more than 1 hour ago (to avoid refresh loop)
			const reloadDate = localStorage.getItem('reloadDateLogin');
			const timestamp = Date.now();

			if (!reloadDate || Number(reloadDate) < timestamp - 3600000) {
				localStorage.setItem('reloadDateLogin', String(timestamp));
				window.location.reload();
			}
		},

		clearDefault() {
			this.default_wallet = ''
		},

		recaptchaComplete() {
			this.login();
		},

		/*
		 * Connect to morpher wallet and log on the user using the wallet address
		 */
		async login(method: any = '') {
			if (!method) method = 'email';

			if (!this.morpherWalletInit) {
				await this.initMorpherWallet();
			}

			this.morpherwallet = this.morpherWalletInit.morpherwallet;
			this.walletClientlogin = this.morpherWalletInit.walletClient;
			this.setWalletClient(this.walletClientlogin);

			let eth_address = '';
			this.setMorpherWalletProvider(this.morpherWalletInit);

			// if (
			// 	!this.recaptchaToken &&
			// 	(!localStorage.getItem('recaptcha_date') || Number(localStorage.getItem('recaptcha_date')) < Date.now() - 1000 * 60 * 8)
			// )
			// 	return this.executeRecaptcha(() => {
			// 		this.login(method);
			// 	});


			// block if login is already executing
			if (this.loginProcessing || this.loginProcessingGoogle || this.loginProcessingApple) {
				return;
			}

			if (method == 'email') this.loginProcessing = true;
			else if (method.type == 'google') this.loginProcessingGoogle = true;
			else if (method.type == 'apple') this.loginProcessingApple = true;
			this.loginError = '';
			let email = this.walletEmail;
			let password = this.walletPassword;
			//let recaptchaToken = this.recaptchaToken;

			this.morpherwallet.onClose(async () => {
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;
			});

			this.morpherwallet.onLoginError(async (email: any, error: any) => {
				this.stopTimer();
			});

			this.morpherwallet.onLogin(async (walletAddress: string, walletEmail: string, recovery_type: number) => {
				email = walletEmail;
				this.stopTimer();
				this.loginVerifying = true;
				this.recovery_type = recovery_type;
				// remove the wallet logon event so that it doest get called if the user logs on in the system
				this.morpherwallet.onLogin(() => { });
				eth_address = walletAddress;
				const user_id = await getUserID(eth_address, 'morpherwallet');

				if (eth_address) {
					this.eth_address = eth_address;
					this.blockie = blockies.createDataURL({
						seed: eth_address.toLowerCase()
					});
				}
				if (walletEmail) this.email = walletEmail;

				const result = await this.loginWallet({
					variables: {
						eth_address,
						user_id,
						wallet_type: 'morpherwallet'
					},
					isTokenLogin: false,
					email: walletEmail,
					walletClient: this.walletClientlogin
				});

				if (result && result.error) {
					this.loginVerifying = false;
					let accountNotFound = false;

					if (
						result.error.graphQLErrors &&
						result.error.graphQLErrors[0] &&
						result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
					) {
						accountNotFound = true;
					}

					if (accountNotFound) {
						const userData = await UserService.getUserData({ email: walletEmail });

						if (userData && userData.data.getUserData) {
							const user = userData.data.getUserData;
							if (user && user.wallet_type && user.wallet_type !== 'morpherwallet') {
								this.loginProcessing = false;
								this.loginProcessingGoogle = false;
								this.loginProcessingApple = false;

								this.morpherwallet.logout();
								this.morpherwallet.hideWallet();
								this.wallet_type = user.wallet_type;
								await this.$router.push('/login/wallet').catch(() => { });
								return;
							} else if (user && user.wallet_type && user.wallet_type === 'morpherwallet') {
								if (user.login && Number(user.login) === 3) {
									this.wallet_type = 'google';
									await this.$router.push('/login/method').catch(() => { });

									return;
								}

								if (user.login && Number(user.login) === 6) {
									this.wallet_type = 'apple';
									await this.$router.push('/login/method').catch(() => { });
									return;
								}

								if (user.login && Number(user.login) === 1) {
									this.wallet_type = 'email';
									await this.$router.push('/login/method').catch(() => { });
									return;
								}
							}

							if (
								user &&
								user.eth_address &&
								walletAddress &&
								user.eth_address.toLowerCase() !== walletAddress.substring(0, 6).toLowerCase()
							) {
								this.loginProcessing = false;
								this.loginProcessingGoogle = false;
								this.loginProcessingApple = false;

								this.wallet_type = 'morpherwallet';
								await this.$router.push('/login/email').catch(() => { });
								this.active_eth_address = user.eth_address.toLowerCase();
								return;
							}
						}
						this.loginProcessing = false;
						this.loginProcessingGoogle = false;
						this.loginProcessingApple = false;

						this.wallet_type = 'morpherwallet';
						this.eth_address = eth_address;
						this.blockie = blockies.createDataURL({
							seed: eth_address.toLowerCase()
						});

						await this.$router.push('/login/new').catch(() => { });
						return;
					}
				}

				// Display the email address updated notification if the email address was updated
				if (result && result.old_address) {
					this.$buefy.dialog.alert({
						title: this.$t('auth.EMAIL_UPDATED'),
						message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
							newEmail: result.email,
							oldEmail: result.old_address
						}),
						confirmText: this.$t('OK')
					});
				}

				localStorage.setItem('walletType', 'morpherwallet');
				if (method) {
					if (method.type) {
						localStorage.setItem('walletLoginMethod', method.type);
					} else {
						localStorage.setItem('walletLoginMethod', method);
					}
				}

				// if the user is not registered then register the user
				if (!result) {
					this.loginVerifying = false;
					this.loginProcessing = false;
					this.loginProcessingGoogle = false;
					this.loginProcessingApple = false;
				} else if (result === 'register') {
					//this.walletConfirm('morpherwallet', eth_address, null);
				} else {
					setTimeout(() => {
						this.loginVerifying = false;
					}, 1000)

					// store the email for defaulting next tme the user logs in
					if (result) localStorage.setItem('emailOrUsernameStorage', walletEmail.toLowerCase());
					this.loginProcessing = false;
					this.loginProcessingGoogle = false;
					this.loginProcessingApple = false;
				}
			});
			this.morpherwallet.onLogout(() => { });

			this.morpherwallet.onError(async (error: any) => {
				this.stopTimer();
				if (error === 'USER_NOT_FOUND') {
					const userData = await UserService.getUserData({ email });
					if (userData && userData.data.getUserData) {
						const user = userData.data.getUserData;

						if (user && user.wallet_type && user.wallet_type !== 'morpherwallet') {
							this.loginProcessing = false;
							this.loginProcessingGoogle = false;
							this.loginProcessingApple = false;

							this.morpherwallet.logout();
							this.morpherwallet.hideWallet();
							this.wallet_type = user.wallet_type;
							await this.$router.push('/login/wallet').catch(() => { });
							error = '';
						} else if (user && user.wallet_type && user.wallet_type === 'morpherwallet') {
							if (user.login && Number(user.login) === 3) {
								this.wallet_type = 'google';
								await this.$router.push('/login/method').catch(() => { });

								error = '';
							}

							if (user.login && Number(user.login) === 6) {
								this.wallet_type = 'apple';
								await this.$router.push('/login/method').catch(() => { });
								error = '';
							}

							if (user.login && Number(user.login) === 1) {
								this.wallet_type = 'email';
								await this.$router.push('/login/method').catch(() => { });
								error = '';
							}
						}
					}
				}
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;

				if (error) {


					if (error.toString() === 'invalid password') {
						this.twoFA = false;
					}

					this.loginError = this.$t('errors.' + error)
				}

			})

			this.morpherwallet.on2FA(async (data: any) => {
				this.stopTimer();
				this.loginProcessing = false;
				this.twoFA = true;
				this.twoFAAuthenticator = data.authenticator;
				this.twoFAEmail = data.email;
				this.twofaCode = ''
			});

			this.stopTimer();

			this.morpherwallet.loginWalletHidden(method, email, password);

			this.walletTimer = window.setTimeout(() => {

				this.loginError = this.$t('errors.WALLET_LOGIN_TIMEOUT')
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;

				this.morpherwallet.showWallet()

			}, 20000);
		},
		stopTimer() {
			if (this.walletTimer) {
				window.clearTimeout(this.walletTimer)
				this.walletTimer = null;
			}
		},
		twoFACodeResend() {
			this.twoFASending = true;
			window.setTimeout(() => {
				this.twoFASending = false;
			}, 30000)
			this.morpherwallet.loginWallet2faSend();
		},
		checkMetamask() {
			const browserMetamask = ['chrome', 'brave', 'opera', 'yandex', 'firefox', 'edge'];

			// return only the first word od the browser name
			const browser = navigator.sayswho.replace(/ .*/, '').toLowerCase();

			if (!browserMetamask.includes(browser) && !deviceType.isAnyMobile()) {
				this.showMetamaskHelp = true;
			} else {
				if (!window.ethereum || !MetaMaskOnboarding.isMetaMaskInstalled()) {
					this.showMetamaskHelp = true;
				}
			}

		},
		processMethod(data: any) {
			this.loginError = '';

			if (data.success) {
				this.login(data);
			} else {
				if (data.error === 'popup_closed_by_user') {
					this.loginError = this.$t('GOOGLE_COOKIES_BLOCKED');
				} else if (data.error === 'google_script_blocked') {
					this.loginError = this.$t('GOOGLE_SCRIPT_BLOCKED');
				} else {
					this.loginError = this.$t(data.error);
				}
			}
		},
		formatDate(input: any) {
			let date = new Date(Number(input))
			let date_output = this.fillZero(date.getDate(), 2) + '/';
			date_output += this.fillZero(date.getMonth() + 1, 2) + '/';
			date_output += date.getFullYear() + ' ';
			date_output += this.fillZero(date.getHours(), 2) + ':';
			date_output += this.fillZero(date.getMinutes(), 2);
			return date_output;


		},
		resetLoading() {
			this.loading.fortmatic = false;
			this.loading.portis = false;
			this.loading.metamask = false;
			this.loading.morpherwallet = false;
		},
		async walletConfirm(wallet_type: string, eth_address: string, email: string) {
			try {
				if (!eth_address) {
					return;
				}
				if (!email) {
					this.loginProcessing = false;
					this.resetLoading()
					localStorage.setItem('walletType', wallet_type);
					localStorage.setItem('eth_address', eth_address);
					localStorage.setItem('emailOrUsernameStorage', email)
					this.metamaskEthAddress = eth_address;
					this.blockie = blockies.createDataURL({
						seed: eth_address.toLowerCase()
					});

					this.metamaskAlreadyRegistered = false;
					const userData = await UserService.getUserData({ eth_address: eth_address.toLowerCase() });

					if (userData && userData.data.getUserData) {
						const user = userData.data.getUserData;

						if (user && user.wallet_type && user.wallet_type) {
							this.metamaskAlreadyRegistered = true;
						}
					}

					await this.$router.push('/register/email').catch(() => { });
					this.$forceUpdate();
					return;
				} else {

					const hash: string = (await this.walletClientlogin?.signMessage({ account: eth_address as TAddress, message: 'Morpher Registration' })) || '';

					this.loginVerifying = true;
					// Confirm the wallet address in the database
					const result = await this.confirmWallet({
						email: email,
						walletClient: this.walletClientlogin,
						recovery_type: this.recovery_type || undefined,
						variables: {
							wallet_type,
							eth_address,
							email,
							university: this.$route.query.uni ? this.$route.query.uni : null,
							competition: this.$route.query.comp ? this.$route.query.comp : null,
							walletEmail: true,
							referred_by: this.referred_by ? this.referred_by : null,
							custom_invite: this.custom_invite ? this.custom_invite : null,
							hash,
							user_id: await getUserID(eth_address, wallet_type),
							app_lang: this.$i18n.locale
						}
					});

					if (result && (result.status === 'confirmed' || result.status === 'email')) {
						await localStorage.setItem('emailOrUsernameStorage', email)
						await localStorage.setItem('walletType', result.wallet_type);
						await localStorage.setItem('eth_address', result.eth_address);

						if (result.status === 'email') {
							this.loginVerifying = false;
							this.$router.push('/register/verify').catch(() => { });
						} else {

							setTimeout(() => {
								this.loginProcessing = false;
								this.loginVerifying = false;
							}, 2000);
						}
					} else {
						this.loginProcessing = false;
						this.loginVerifying = false;
						this.resetLoading()
						this.loginProcessing = false;

						if (result.status) {
							localStorage.setItem('tokenStorage', result.token);
							await this.$router.push('/register/' + result.status).catch(() => { });
						} else if (result.error) {
							this.logonError = this.$t(result.error)
							this.$emit('updateError', result.error);
						}
						this.$forceUpdate();

						return;
					}
				}
			} catch (err: any) {
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;

				if (this.fortmatic) await this.fortmatic.user.logout();
				this.resetLoading()
				this.$emit('updateError', err.message);
			}
		},
		/**
		 * Wait for fortmatic to load before continuing with logon
		 */
		async awaitFortmaticLoad() {
			if (this.loaded.fortmatic) {
				if (this.loaded.fortmaticInterval)
					clearInterval(this.loaded.fortmaticInterval);
				this.loaded.fortmaticInterval = 0;
				this.loginFortmatic();
			}
		},
		/**
		 * Preload the fortmatic interface to speed up logon
		 */
		async loadFortmatic() {
			this.walletError = '';


			// configure portis access
			const result = await fortmaticConfig();
			this.fortmatic = result.fortmatic;
			this.walletClientFortmatic = result.walletClient;
			this.loaded.fortmaticLoggedIn = await this.fortmatic.user.isLoggedIn();
			this.loaded.fortmatic = true;
		},
		/*
 * Connect to fortmatic
 */
		async loginFortmatic() {
			this.walletError = '';
			this.wallet_type = 'fortmatic';

			try {
				if (this.walletClientFortmatic) this.setWalletClient(this.walletClientFortmatic);
				this.loginProcessingFortmatic = true;
				if (!this.loaded.fortmatic) {
					this.loadFortmatic();
					if (this.loaded.fortmaticInterval) {
						clearInterval(this.loaded.fortmaticInterval);
					}
					this.loaded.fortmaticInterval = setInterval(this.awaitFortmaticLoad, 200);
					return;
				}

				// log the user if if they are not already logged in
				if (!this.loaded.fortmaticLoggedIn) {
					await this.fortmatic.user.login();
					this.loaded.fortmaticLoggedIn = true;
				}

				// get the account info from the walletClient
				try {
					if (this.walletClientFortmatic)
						this.walletClientFortmatic.getAddresses().then(async (accounts: any) => {
							if (accounts && accounts.length > 0) {
								const data = await this.fortmatic.user.getUser();

								const user_id = data.userId;

								this.name = data.userId;
								this.email = data.email;

								if (accounts[0]) {
									this.eth_address = accounts[0];
									this.blockie = blockies.createDataURL({
										seed: accounts[0].toLowerCase()
									});
								}

								this.clearStorage();
								localStorage.setItem('walletType', 'fortmatic');

								// Log the user into the system
								const result = await this.loginWallet({
									variables: {
										eth_address: accounts[0],
										wallet_type: 'fortmatic',
										user_id,
										hash: ''
									},
									isTokenLogin: false,
									email: this.email,
									walletClient: this.walletClientFortmatic
								});

								if (result && result.error) {
									await this.fortmatic.user.logout();
									let accountNotFound = false;

									if (
										result.error.graphQLErrors &&
										result.error.graphQLErrors[0] &&
										result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
									) {
										accountNotFound = true;
									}

									if (accountNotFound) {
										const userData = await UserService.getUserData({ email: data.email });

										if (userData && userData.data.getUserData) {
											const user = userData.data.getUserData;
											if (user && user.wallet_type && user.wallet_type !== 'morpherwallet') {
												this.loginProcessing = false;
												this.loginProcessingGoogle = false;
												this.loginProcessingApple = false;

												this.morpherwallet.logout();
												this.morpherwallet.hideWallet();
												this.wallet_type = user.wallet_type;
												await this.$router.push('/login/wallet').catch(() => { });
												return;
											} else if (user && user.wallet_type && user.wallet_type === 'morpherwallet') {
												if (user.login && Number(user.login) === 3) {
													this.wallet_type = 'google';
													await this.$router.push('/login/method').catch(() => { });

													return;
												} else if (user.login && Number(user.login) === 6) {
													this.wallet_type = 'apple';
													await this.$router.push('/login/method').catch(() => { });
													return;
												} else if (user.login) {
													this.wallet_type = 'email';
													await this.$router.push('/login/method').catch(() => { });
													return;
												}
											}

										}

										await this.$router.push('/login/new')
										return;
									}

									setTimeout(() => {
										// Refresh cache and clear storage
										// Handle all the errors as previous except LOGIN_WRONG_CREDENTIALS
										this.reloadPage(accountNotFound);

										// Refresh page if user do no go to sign up after 3 seconds
										// Wait 3 seconds 2000 + 1000 for user to do the action
										// If no route has been changed, page will restart
										// Otherwise, page is switched to sign up and timeout will be cleared
										if (accountNotFound) {
											setTimeout(() => {
												window.location.reload();
											}, 1000);
										}
									}, 2000);
								}

								// Display the email address updated notification if the email address was updated
								if (result && result.old_address) {
									this.$buefy.dialog.alert({
										title: this.$t('auth.EMAIL_UPDATED'),
										message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
											newEmail: result.email,
											oldEmail: result.old_address
										}),
										confirmText: this.$t('OK')
									});
								}

								if (!result) {
									this.loginProcessingFortmatic = false;
									// if the user was not logged in to the system then log them out of fortmatic
									await this.fortmatic.user.logout();
									// if the user is not registered then register the user
								}
								this.loginProcessingFortmatic = false;
							} else {
								this.loginProcessingFortmatic = false;
								this.walletError = this.$t('auth.FORTMATIC_NO_ACCOUNT');
							}

						});
				} catch (err: any) {
					this.loginProcessingFortmatic = false;
					this.walletError = this.$t('auth.FORTMATIC_ERROR') + ' ' + err.message;
				}
			} catch (err: any) {
				this.walletError = err.message;
				this.loginProcessingFortmatic = false;
			}
		},
		/*
 * Connect to portis and log on the user using the portis wallet address
 */
		async loginPortis() {
			try {
				this.walletError = '';
				this.wallet_type = 'portis';
				this.loginProcessingPortis = true;

				// configure portis access
				if (!this.walletClientPortis) {
					const result = await portisConfig({});
					this.portis = result.portis;
					this.walletClientPortis = result.walletClient;
				}
				this.setWalletClient(this.walletClientPortis);

				// Callback function for when portis is logged on
				this.portis.onLogin(async (eth_address: any, email: any, reputation: any) => {
					this.loginProcessingPortis = false;

					const user_id = await getUserID(eth_address, 'portis');

					if (email) this.email = email;

					if (eth_address) {
						this.eth_address = eth_address;
						this.blockie = blockies.createDataURL({
							seed: eth_address.toLowerCase()
						});
					}

					this.clearStorage();
					localStorage.setItem('walletType', 'portis');

					// Log the user into the system
					const result = await this.loginWallet({
						variables: {
							eth_address,
							wallet_type: 'portis',
							user_id,
							hash: ''
						},
						isTokenLogin: false,
						email,
						walletClient: this.walletClientPortis
					});

					if (result && result.error) {
						let accountNotFound = false;

						if (
							result.error.graphQLErrors &&
							result.error.graphQLErrors[0] &&
							result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
						) {
							accountNotFound = true;
						}

						if (accountNotFound) {
							await this.$router.push('/login/new')
							return;
						}

						setTimeout(() => {
							this.reloadPage();
						}, 2000);
					}

					// Display the email address updated notification if the email address was updated
					if (result && result.old_address) {
						this.$buefy.dialog.alert({
							title: this.$t('auth.EMAIL_UPDATED'),
							message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
								newEmail: result.email,
								oldEmail: result.old_address
							}),
							confirmText: this.$t('OK')
						});
					}

					// if the user is not registered then register the user
					if (!result) {
						this.loginProcessingPortis = false;
						this.portis.showPortis();
					}
					// store the email for defaulting next tme the user logs in
					if (result) localStorage.setItem('emailOrUsernameStorage', email);
					this.loginProcessingPortis = false;
				});

				this.portis.setDefaultEmail(localStorage.getItem('emailOrUsernameStorage'));


				// check if the user is logged in
				await this.portis.isLoggedIn().then((loginResult: any) => {
					// set the login state

					if (loginResult.error) {
						this.loginProcessingPortis = false;
						this.walletError = this.$t('auth.PORTIS_ERROR') + ' ' + loginResult.error.message;
					} else {
						if (!loginResult.result) {
							this.loginProcessingPortis = false;
							this.walletError = this.$t('auth.PORTIS_ERROR');
						}
					}
				});
			} catch (err: any) {
				this.walletError = err.message;
				this.loginProcessingPortis = false;
			}
		},
		async signupWallet() {
			this.walletConfirm('morpherwallet', this.eth_address, this.email);

		},
		async walletNewBack() {
			if (this.fortmatic && this.fortmatic.user) this.fortmatic.user.logout();
			if (this.morpherwallet && this.morpherwallet.logout) {
				this.morpherwallet.logout();
			}

			this.$router.push('/login').catch(() => { });
		},

		async loginMorpher() {
			localStorage.removeItem('walletType');
			localStorage.removeItem('walletLoginMethod');
			this.default_wallet = ''
			this.$router.push('/login').catch(() => { });
		},

		async disconnectWallet(goToLogin = false) {
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			this.clearStorage();

			if (this.fortmatic && this.fortmatic.user) this.fortmatic.user.logout();
			if (this.morpherwallet && this.morpherwallet.logout) this.morpherwallet.logout();

			if (goToLogin) this.$router.push('/login').catch(() => { });

			window.location.reload();
		},
		generateWalletName(wallet_type: string) {
			let name = '';

			if (wallet_type === 'metamask') {
				name = 'Metamask';
			} else if (wallet_type === 'walletconnect') {
				name = 'WalletConnect';
			} else if (wallet_type === 'portis') {
				name = 'Portis';
			} else if (wallet_type === 'fortmatic') {
				name = 'Fortmatic';
			} else if (wallet_type === 'morpherwallet') {
				name = 'Morpher Wallet';
			}

			return name;
		},

		checkKeyPress(e: KeyboardEvent) {
			if (e.key == 'Enter') {
				if (this.walletPassword) {
					this.login('email');
				} else {
					// set focus to the password field if it is blanck
					window.setTimeout(() => {
						const passwordElement = this.$refs.login_password as InstanceType<typeof HTMLInputElement>;
						if (passwordElement) passwordElement.focus();
					}, 100);
				}
			}
		},


		// Submits login request
		async formAction() { }
	}
});
</script>
<style scoped>
.button-img-primary {
	height: 28px;
	padding-right: 15px;
}

.button-img-alt {
	position: absolute;
	right: calc(50% + 70px);
}

.alt-button {
	padding-left: 46px;
}

.text {
	font-family: 'Manrope';
	font-weight: 700;
	font-size: 14px;
}

.login-help {
	margin-top: -0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.settings-link {
	line-height: 1rem;
	cursor: default;
}

@media only screen and (max-width: 400px) {
	.button-img-alt {
		display: none;
	}

	.alt-button {
		padding-left: initial;
	}
}

.spaced {
	margin-right: 7px;
}

.authBox {
	margin-top: 0px;
}

.loginOptions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
}

.formContainer {
	display: flex;
	flex-direction: column;
	margin: auto auto;
	width: 360px;
}

.headerImage img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.password-toggle {
	position: absolute;
	right: 10px;
	top: 30%;
	background: none;
	border: none;
	cursor: pointer;
}

.authTitle {
	font-size: 28px;
	font-weight: 700;
	line-height: 38px;
	margin-top: 25px;
	color: #333333;
}

.headerImage {
	width: 100%;
}

.authText {
	font-weight: 400;
	color: #686475;
	margin-top: 12px;
	font-size: 16px;
}

.label {
	margin-top: 20px;
	margin-bottom: 4px;
	text-align: left;
	color: #686475;
	font-weight: 700;
	font-size: 12px;
}

.input {
	padding: 23px 16px;
	background-color: #fff;
	border-radius: 8px !important;
	border-color: #d0d5dd !important;
}

.input::placeholder {
	font-family: 'Manrope';
	line-height: 20px;
	font-size: 14px !important;
	font-weight: 400;
	color: #686475;
}

.help {
	color: #d70228;
	text-align: left;
}



.more {
	width: 100%;
}

.otherTitle {
	text-align: left;
	padding-bottom: 8px;
	font-size: 12px;
	font-weight: 700;
	color: #686475;
}



.loginDivider {
	height: 1px;
	width: 100%;
	margin: 20px 0;
	border-top: 1px solid #eae9ed;
}

.loginHelp {
	height: calc(100vh - 800px);
}

.error {
	color: #D70228;
	font-size: 14px;
	font-weight: 400;
	padding-top: 5px;
}

.pt-30 {
	padding-top: 30px;
}


.authText.fade {
	height: 50px;
	-webkit-animation: fadeinout 3s linear forwards;
	animation: fadeinout 3s linear forwards;
	opacity: 0;
	animation-iteration-count: infinite;
}

.authText.fade2 {
	height: 50px;
	-webkit-animation: fadeIn 2s linear forwards;
	animation: fadeIn 2s linear forwards;
	opacity: 0;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeinout {
	50% {
		opacity: 1;
	}
}

@keyframes fadeinout {
	50% {
		opacity: 1;
	}

}

.wallet-box {
	border-radius: 8px;
	border: 1px solid #EAE9ED;
	padding: 16px;
}

.wallet-box .div-line {
	border-top: 1px solid #EAE9ED;
}
</style>
<style>
.disconnect-link {
	color: #1A6DF0;
	cursor: pointer;
}
</style>
