<template>
	<div class="has-font-manrope">
		<div class="toolbar-container">
			<div id="mainToolbar" ref="toolbar" class="toolbar transition-fast">

				<div class="left-center-combined">
					<div class="header-left-container">
						<div class="logo-container">
							<router-link class="logo-link" to="/">
								<img class="logo" src="../assets/morpher_logo.svg" :alt="$t('MORPHER_LOGO')" />
							</router-link>

							<!-- Search Bar -->
							<div :class="{ 'bordered-search-input': isScrolled }">
								<SearchInput />
							</div>
						</div>

					</div>
					<div class="header-middle-container">
						<div class="settings-container">
							<!-- Navigation Links -->
							<div class="navigation-links-container">

								<b-dropdown id="markets-dropdown" position="is-bottom-left" aria-role="list"
									class="has-text-left settings-menu" :mobile-modal="false" scrollable
									data-cy="marketsDropdown" :max-height="600" :triggers="['hover']">

									<template #trigger>
										<p class="navigation-header navigation-link"
											active-class="navigation-item navigation-link-active"
											style="align-items: center; display: flex">


											{{ $t('MARKETS') }}
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="ml-5px"
												xmlns="http://www.w3.org/2000/svg">
												<path d="M4 6L8 10L12 6" class="dropdown-arrow" stroke-width="2"
													stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</p>
									</template>



									<b-dropdown-item has-link aria-role="listitem">
										<router-link id="settings-link" data-cy="stockRoute" to="/stock"
											class="regular-link dropdown-text-items transition-faster"
											@click.native="onLinkClick">
											{{ $t('STOCKS') }}
										</router-link>
									</b-dropdown-item>
									<hr style="margin: 5px 0" />

									<b-dropdown-item has-link aria-role="listitem">
										<router-link id="settings-link" data-cy="cryptoRoute" to="/crypto"
											class="regular-link dropdown-text-items transition-faster"
											@click.native="onLinkClick">
											{{ $t('CRYPTOCURRENCIES') }}
										</router-link>
									</b-dropdown-item>
									<hr style="margin: 5px 0" />
									<b-dropdown-item has-link aria-role="listitem">
										<router-link id="settings-link" data-cy="commodityRoute" to="/commodity"
											class="regular-link dropdown-text-items transition-faster"
											@click.native="onLinkClick">
											{{ $t('COMMODITIES') }}
										</router-link>
									</b-dropdown-item>
									<hr style="margin: 5px 0" />
									<b-dropdown-item has-link aria-role="listitem">
										<router-link id="settings-link" data-cy="forexRoute" to="/forex"
											class="regular-link dropdown-text-items transition-faster"
											@click.native="onLinkClick">

											{{ $t('FOREX') }}
										</router-link>
									</b-dropdown-item>
									<hr style="margin: 5px 0" />
									<b-dropdown-item has-link aria-role="listitem">
										<router-link id="settings-link" data-cy="indexRoute" to="/index"
											class="regular-link dropdown-text-items transition-faster"
											@click.native="onLinkClick">
											{{ $t('INDEX') }}
										</router-link>
									</b-dropdown-item>
									<hr style="margin: 5px 0" />
									<b-dropdown-item has-link aria-role="listitem">
										<router-link id="settings-link" data-cy="indexRoute" to="/unique"
											class="regular-link dropdown-text-items transition-faster"
											@click.native="onLinkClick">
											{{ $t('UNIQUES') }}

										</router-link>
									</b-dropdown-item>
									<hr style="margin: 5px 0" />
									<b-dropdown-item has-link aria-role="listitem"
										class="is-inline-flex is-align-items-center">
										<router-link id="settings-link" data-cy="indexRoute" to="/unique?type=football"
											class="regular-link dropdown-text-items transition-faster pr-0"
											@click.native="onLinkClick">
											{{ $t('unique.MARKET_FOOTBALL') }}
										</router-link>
									</b-dropdown-item>
								</b-dropdown>


								<router-link class="navigation-header navigation-link"
									active-class="navigation-item navigation-link-active" to="/portfolio"
									data-testid="portfolio-route" @click.native="onLinkClick">{{ $t('PORTFOLIO')
									}}</router-link>

								<router-link class="navigation-header navigation-link"
									active-class="navigation-item navigation-link-active" to="/savings"
									data-testid="savings-route" data-cy="savingsRoute" @click.native="onLinkClick">{{
										$t('SAVINGS') }}</router-link>

								<router-link class="navigation-header navigation-link"
									active-class="navigation-item navigation-link-active" to="/funds"
									data-cy="fundsRoute" @click.native="onLinkClick">{{ $t('FUNDS') }}</router-link>

								<router-link class="navigation-header navigation-link"
									active-class="navigation-item navigation-link-active" to="/rewards"
									data-cy="rewardsRoute" @click.native="onLinkClick">{{ $t('REWARDS') }}</router-link>
							</div>
						</div>
					</div>
				</div>
				<!-- Account and Settings -->
				<div class="header-right-container">
					<!-- Portfolio Data -->
					<div v-show="activePortfolio && unlockOrNull" class="header-portfolio-info is-hidden-1300">
						<div v-if="!noBalance">
							<div>
								<!-- Cash Balance -->
								<div v-show="portfolioItem === 0" class="has-text-right">
									<p class="number-headers">{{ $t('BALANCE') }}:</p>
									<p v-if="currentView === 'MPH'" class="highlighted-mini">
										{{ floorRound((activePortfolio?.cash_balance || 0) / Math.pow(10, 18), 2) }} MPH
									</p>
									<p v-else-if="currentView === 'USD' && mph_market" class="highlighted-mini">
										$ {{ usdFormatter(((activePortfolio?.cash_balance || 0) / Math.pow(10, 18)) *
											(mph_market?.close || 0))
										}}
									</p>
								</div>
							</div>
						</div>
					</div>

					<!-- Metamask Messages and Account -->
					<WalletInfo />

					<div class="insights-icon" @click="toggleAIInsightsModal('all')">
						<img v-if="aiInsightsOn" src="@/assets/icons/ai_icon_on.svg" />
						<img v-else src="@/assets/icons/ai_icon_off.svg" />
					</div>

					<!-- <router-link to="/" class="insights-icon">
					<img v-if="aiInsightsOn" src="@/assets/icons/notification-bell-on.svg" />
					<img v-else src="@/assets/icons/notificiation-bell.svg" />
				</router-link> -->

					<!-- Blockie Dropdown Settings Menu -->
					<div class="settings-container">

						<b-dropdown id="settings-menu" position="is-bottom-left" aria-role="list"
							class="has-text-left settings-menu" :mobile-modal="false" scrollable data-cy="settingsMenu"
							:max-height="600">

							<template #trigger class="blockie-trigger-container">
								<Blockie :src="activePortfolio?.blockie || ''" />

								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="arrow-icon"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M4 6L8 10L12 6" class="dropdown-arrow" stroke-width="2"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>

							</template>

							<div v-if="unlockWallet" class="unlock-button mt-4" @click="walletUnlock">
								<img src="@/assets/icons/new/unlock.svg" :alt="$t('UNLOCK_WALLET')" />
								<p>
									{{ $t('UNLOCK_WALLET') }}
								</p>
							</div>


							<div v-else class="blockie-username">
								<div class="blockie-pic">
									<Blockie slot="trigger" role="button" :src="activePortfolio?.blockie || ''" />
								</div>
								<div class="username-tier">
									<!-- Username and Email and Tier -->
									<b-dropdown-item aria-role="menuitem" class="non-hover-item">
										<p class="username-text">{{ user?.username }}</p>

										<div v-if="(user?.access_level?.tier || 0) < 3" class="account-level">
											<img src="@/assets/icons/new/tier-novice.svg" />
											<p>{{ $t('TIER_NOVICE') }}</p>
										</div>

										<div v-else-if="(user?.access_level?.tier || 0) === 3" class="account-level">
											<img src="@/assets/icons/new/tier-investor.svg" />
											<p>{{ $t('TIER_INVESTOR') }}</p>
										</div>
										<div v-else-if="(user?.access_level?.tier || 0) === 4" class="account-level">
											<img src="@/assets/icons/new/tier-mogul.svg" />
											<p>{{ $t('TIER_MOGUL') }}</p>
										</div>
									</b-dropdown-item>
								</div>
							</div>
							<hr style="margin: 5px 0" />

							<span v-if="user && user.role === 'admin'">
								<b-dropdown-item has-link aria-role="listitem">
									<router-link id="settings-link" data-cy="settingsLink" to="/admin/markets"
										class="admin-link dropdown-text-items transition-faster ">

										{{ $t('ADMIN') }}
									</router-link>
								</b-dropdown-item>


								<hr style="margin: 5px 0" />
							</span>

							<!-- Support -->
							<span v-if="user && user.role === 'support'">
								<b-dropdown-item has-link aria-role="listitem">
									<router-link id="settings-link" data-cy="settingsLink" to="/admin/verification"
										class="regular-link dropdown-text-items transition-faster  admin-tabs">
										{{ $t('SUPPORT') }}
									</router-link>
								</b-dropdown-item>


								<hr style="margin: 5px 0" />
							</span>



							<!-- Marketing -->
							<span v-if="user && user.role === 'marketing'">
								<b-dropdown-item has-link aria-role="listitem">
									<router-link id="settings-link" data-cy="settingsLink" to="/admin/templates"
										class="regular-link dropdown-text-items transition-faster  admin-tabs">
										{{ $t('MARKETING') }}
									</router-link>
								</b-dropdown-item>


								<hr style="margin: 5px 0" />
							</span>



							<!-- Currency -->
							<div id="settings-link"
								class="is-flex is-align-items-center is-justify-content-space-between regular-link ">
								<p class="dropdown-text-items" @click="
									currencySelector = !currencySelector;
								changeCurrency();">{{ $t('CURRENCY') }}</p>
								<button class="button currency-toggle-btn no-translate" @click="
									currencySelector = !currencySelector;
								changeCurrency();
								">
									<img src="@/assets/icons/currency_switch.svg" class="currency-icon" />
									{{ currentView === 'USD' ? 'USD' : 'MPH' }}
								</button>
							</div>

							<hr style="margin: 5px 0" />

							<div class="is-flex is-align-items-center is-justify-content-space-between non-hover-item ">
								<LanguageSelectorNavbar />
							</div>


							<hr style="margin: 5px 0" />

							<!-- Wallet -->
							<div v-if="walletType !== 'metamask'" id="settings-link"
								class="is-flex is-align-items-center is-justify-content-space-between regular-link">
								<p id="wallet-link" class="dropdown-text-items" @click="openCryptoWallet">
									{{ $t('WALLET') }}
								</p>
							</div>
							<!-- <b-dropdown-item v-show="walletType !== 'metamask'" aria-role="menuitem" class="non-hover-item wallet-section">
							<router-link to="/wallet" id="wallet-link"
								class="regular-link dropdown-text-items transition-faster" @click.native="openCryptoWallet">
								{{ $t('WALLET') }}
							</router-link>
						</b-dropdown-item> -->

							<hr style="margin: 5px 0" v-if="walletType !== 'metamask'" />
							<!-- Wallet -->
							<b-dropdown-item has-link aria-role="listitem">
								<router-link id="settings-link" data-cy="settingsLink" to="/history"
									class="regular-link dropdown-text-items transition-faster">
									{{ $t('HISTORY') }}
								</router-link>
							</b-dropdown-item>

							<hr style="margin: 5px 0" />

							<b-dropdown-item has-link aria-role="listitem">
								<router-link id="settings-link" data-cy="settingsLink" to="/settings"
									class="regular-link dropdown-text-items transition-faster">
									{{ $t('SETTINGS') }}
								</router-link>
							</b-dropdown-item>

							<hr style="margin: 5px 0" />

							<b-dropdown-item has-link aria-role="listitem">
								<router-link id="settings-link" to="/help"
									class="regular-link dropdown-text-items transition-faster">
									{{ $t('HELP') }}
								</router-link>
							</b-dropdown-item>

							<hr style="margin: 5px 0" />

							<b-dropdown-item has-link aria-role="listitem">
								<a id="logout-link" data-cy="logoutLink"
									class="regular-link dropdown-text-items transition-faster" @click="logout">
									{{ $t('LOGOUT') }}
								</a>
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</div>

			</div>
		</div>
		<HeaderMigrationModal />
		<HeaderTryAppModal />
		<WalletRecoveryModal />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStatusStore } from '@/store/modules/status';
import { useModalsStore } from '@/store/modules/modals';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import SearchInput from '@/components/SearchInput.vue';
import PortfolioSparkLine from '@/components/charts/PortfolioSparkLine.vue';
import WalletInfo from '@/components/WalletInfo.vue';
//vuex
import chartEvents from '../mixins/chartEvents.mixin';
import Blockie from '@/components/Blockie.vue';
import { copyToClipboard } from '../helpers/utils';
import NavigationLinks from '@/components/NavigationLinks.vue';
import LanguageSelectorNavbar from '@/components/LanguageSelectorNavbar.vue';
import HeaderMigrationModal from '@/components/modals/HeaderMigrationModal.vue';
import WalletRecoveryModal from '@/components/modals/WalletRecoveryModal.vue';

import HeaderTryAppModal from '@/components/modals/HeaderTryAppModal.vue';
import { UserService } from '../services/UserService';
import { MarketService } from '../services/MarketService';
import { useMarketsStore } from '@/store/modules/markets';
import { mapActions, mapState } from 'pinia';

/* @group Components_General */
/*
  <h4> <b> Header Component </b> </h4>
  <br> Includes wallet opening when clicked on icons (Portis/Metamask)
  <br> Portfolio Line Component displayed in "small" mode for 1d range
  <br> when active portfolio detected in the store
*/
export default defineComponent({
	name: 'Header',
	components: {
		SearchInput,
		PortfolioSparkLine,
		NavigationLinks,
		WalletInfo,
		LanguageSelectorNavbar,
		Blockie,
		HeaderMigrationModal,
		HeaderTryAppModal,
		WalletRecoveryModal
	},

	mixins: [chartEvents],
	data() {
		return {
			isScrolled: false,
			openWalletFlag: false,
			currencySelector: false,
			currentView: 'MPH',
			portfolioItem: 0,
			showPortfolioElements: true,
			showGift: true,
			portfolioLoading: false,
			hasSocialRecovery: true,
			socialRecoveryTimeout: null as null | NodeJS.Timeout | number,
			newsSubscription: null,
			aiInsightsOn: false,
			rewardOn: false,
			unlockWallet: false
		};
	},
	computed: {
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
			morpherwallet: (state) => state.morpherwallet,
			socialRecovery: (state) => state.socialRecovery,
			isLoggedInWallet: (state) => state.isLoggedInWallet,
		}),
		...mapState(useStatusStore, {
			loading: (state) => state.loading,
			walletText: (state) => state.walletText,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
			payload: (state) => state.data?.payload,
			impersonating: (state) => state.impersonating,
			app_lang: (state) => state.data?.payload?.app_lang,
			valueSum: (state) => state.valueSum,
			portfolioLineData: (state) => state.portfolioLineData,
		}),
		...mapState(useMarketsStore, {
			selectedMarket: (state) => state.selected,
			mph_market: (state) => state.mph_market,
			aiInsights: (state) => state.aiInsights,

		}),
		...mapState(useModalsStore, {
			showHeaderNotificationModal: (state) => state.showHeaderNotificationModal,
		}),

		unlockOrNull() {
			if (this.walletText) {
				return this.walletText.message === null || this.walletText.message.toLowerCase().indexOf('unlock') > -1;
			} else {
				return true;
			}
		},
		pending() {
			return this.activePortfolio ? Number(this.activePortfolio.pending) : 0;
		},
		noBalance() {
			if (this.activePortfolio?.cash_balance == 0 && this.activePortfolio?.current_value == 0) {
				return true;
			} else {
				return false;
			}
		},
		portfolioValueUsd() {
			return 0;
		}
	},
	watch: {
		payload(payloadValue) {
			this.updateAILastSeen();
			if (this.currentView !== (payloadValue?.currency_view || 'MPH')) {
				this.currentView = payloadValue?.currency_view || 'MPH';
				this.currencySelector = this.currentView === 'USD';
				if (this.currentView === 'USD' && !this.mph_market) {
					this.getMPHMarket({
						component: this
					});
				}
			}
		},
		app_lang(nv) {
			this.localeUpdated(nv);
		},
		activePortfolio(nv) {
			this.portfolioLoading = true;
			window.setTimeout(() => {
				this.portfolioLoading = false;
			}, 500);
		},
		aiInsights(nv) {
			this.updateAILastSeen();
		},

		isLoggedInWallet() {
			this.checkWalletLocked();
		},
	},
	async created() {
		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	},
	unmounted() {
		if (this.socialRecoveryTimeout) clearTimeout(this.socialRecoveryTimeout);

		this.aiNewsUnSubscribe();

		window.removeEventListener('resize', this.checkHeaderWidth);
	},
	async mounted() {
		this.currentView = this.payload?.currency_view || 'MPH';
		this.currencySelector = this.currentView === 'USD';
		if (this.currentView === 'USD' && !this.mph_market) {
			this.getMPHMarket({
				component: this
			});
		}
		window.addEventListener('resize', this.checkHeaderWidth);
		this.checkHeaderWidth();
		this.checkSocialRecovery();

		if (this.aiInsights.length == 0) {
			this.getAIMarketInsights({ component: this });
		}
		this.aiNewsSubscribe();

		this.checkWalletLocked();

	},
	methods: {
		...mapActions(useUserStore, {
			logout: 'logout',
			updateUserPayload: 'updateUserPayload',
		}),
		...mapActions(useContractStore, {
			startWallet: 'startWallet',
			openPortisWallet: 'openPortisWallet',
			openCryptoWallet: 'openCryptoWallet',
		}),
		...mapActions(useMarketsStore, {
			getMPHMarket: 'getMPHMarket',
			getAIMarketInsights: 'getAIMarketInsights',
		}),
		...mapActions(useModalsStore, {
			toggleKYCCaptureModal: 'toggleKYCCaptureModal',
			toggleAIInsightsModal: 'toggleAIInsightsModal',
			toggleUnlockWalletModal: 'toggleUnlockWalletModal',
		}),

		...mapActions(useMarketsStore, {
			newAIMarketInsight: 'newAIMarketInsight'
		}),


		walletUnlock() {
			this.toggleUnlockWalletModal(true)
		},

		checkWalletLocked() {
			if (this.walletType == 'morpherwallet') {
				if (this.isLoggedInWallet) {
					this.unlockWallet = false
				} else {
					this.unlockWallet = true
				}

			} else {
				this.unlockWallet = false
			}
		},
		// @vuese
		// Detect if user scrolled down to transform look
		handleScroll() {
			this.isScrolled = window.scrollY > 20;
		},
		// @vuese
		// Open Portis Wallet - triggers store action
		openPortisWalletHeader() {
			this.openWalletFlag = true;
			this.openPortisWallet();
		},
		copyETHAddress() {
			copyToClipboard(this.activePortfolio?.eth_address || '', this);
		},
		localeUpdated(locale: string) {
			setTimeout(() => {
				this.getAIMarketInsights({ component: this });
			}, 1000);
		},
		updateAILastSeen() {
			if (this.aiInsights.length > 0 && this.payload) {
				if (this.payload?.ai_last_seen) {
					const fil = this.aiInsights.filter((ai) => Number(ai.timestamp) > Number(this.payload?.ai_last_seen));

					if (fil.length > 0) {
						this.aiInsightsOn = true;
					} else {
						this.aiInsightsOn = false;
					}
				} else {
					this.aiInsightsOn = true;
				}
			} else {
				this.aiInsightsOn = false;
			}
		},
		async aiNewsSubscribe() {
			if (this.newsSubscription) {
				await MarketService.unsubscribeFromAINews(this.newsSubscription);
				this.newsSubscription = null;
			}

			this.newsSubscription = await MarketService.subscribeToAINews('AI_INSIGHTS', (data) => {
				if (data) {
					try {
						const ai_news = data.data.updateNews;

						if (ai_news) {
							const summary = ai_news.summary.split('Assessment:');
							let intro_text = '';
							let summary_text = '';

							if (summary.length == 2) {
								intro_text = summary[0];
								summary_text = summary[1];
							} else {
								const summary2 = ai_news.summary.split('\n');

								if (summary2.length > 1) {
									intro_text = summary2.shift();
									summary_text = summary2.join('\n');
								} else {
									intro_text = '';
									summary_text = ai_news.summary;
								}
							}

							const ai_data = {
								market_id: ai_news.market_id,
								timestamp: ai_news.timestamp,
								headline: intro_text,
								source: 'ai',
								summary: summary_text,
								image_url: null,
								bullish: ai_news.has_paywall,
								change_percent: 0,
								url: null
							};

							if (
								!this.aiInsights.find((item) => item.market_id == ai_news.market_id && item.timestamp == ai_news.timestamp)
							) {
								this.newAIMarketInsight(ai_data);
							}
						}
					} catch (err) { }
				}
			});
		},
		async aiNewsUnSubscribe() {
			if (this.newsSubscription) {
				await MarketService.unsubscribeFromAINews(this.newsSubscription);
				this.newsSubscription = null;
			}
		},
		changeCurrency() {
			if (this.currencySelector) {
				this.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'USD' });
				this.currentView = 'USD';
			} else {
				this.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'MPH' });
				this.currentView = 'MPH';
			}
			this.currencySelector = this.currentView === 'USD';

			if (this.currentView === 'USD' && !this.mph_market) {
				this.getMPHMarket({
					component: this
				});
			}
		},
		switchPortfolioItem() {
			if (this.portfolioItem > 2) {
				this.portfolioItem = 0;
			} else if (this.portfolioItem === 2 && (!this.activePortfolio?.stake_value || Number(this.activePortfolio.stake_value) === 0)) {
				this.portfolioItem = 0;
			} else if (this.portfolioItem === 1 && this.changePercent === Infinity) {
				// Prevent showing infinite return
				this.portfolioItem = 0;
			} else {
				this.portfolioItem += 1;
			}
		},
		// Function to check if walletText and size of screen to dynamically hide header items
		checkHeaderWidth() {
			if (this.walletText && this.walletText.message.toLowerCase().indexOf('Unlock') > -1) {
				if (this.walletText.icon) {
					if ((this.$refs.toolbar as InstanceType<typeof HTMLDivElement>).clientWidth < 790) {
						this.showGift = false;
						this.showPortfolioElements = false;
					} else if ((this.$refs.toolbar as InstanceType<typeof HTMLDivElement>).clientWidth < 1036) {
						this.showGift = true;
						this.showPortfolioElements = false;
					} else {
						this.showGift = true;
						this.showPortfolioElements = true;
					}
				} else {
					if ((this.$refs.toolbar as InstanceType<typeof HTMLDivElement>).clientWidth < 740) {
						this.showGift = false;
						this.showPortfolioElements = false;
					} else if ((this.$refs.toolbar as InstanceType<typeof HTMLDivElement>).clientWidth < 990) {
						this.showGift = true;
						this.showPortfolioElements = false;
					} else {
						this.showGift = true;
						this.showPortfolioElements = true;
					}
				}
			} else {
				if ((this.$refs.toolbar as InstanceType<typeof HTMLDivElement>).clientWidth < 630) {
					this.showGift = false;
					this.showPortfolioElements = false;
				} else if ((this.$refs.toolbar as InstanceType<typeof HTMLDivElement>).clientWidth < 880) {
					this.showGift = true;
					this.showPortfolioElements = false;
				} else {
					this.showGift = true;
					this.showPortfolioElements = true;
				}
			}
		},
		async addRecovery() {
			if (this.walletType === 'morpherwallet') {
				this.morpherwallet.showWalletRecovery();
				return;
			}
		},
		async checkSocialRecovery() {
			if (this.morpherwallet) {
				const hasSocialRecovery = await this.morpherwallet.hasSocialRecoveryMethods();
				if (this.socialRecoveryTimeout) clearTimeout(this.socialRecoveryTimeout);
				//this.socialRecoveryTimeout = setTimeout(this.checkSocialRecovery, 2000);
				this.hasSocialRecovery = hasSocialRecovery;
			} else {
				this.hasSocialRecovery = true;
				//this.socialRecoveryTimeout = setTimeout(this.checkSocialRecovery, 2000);
			}
		},
		sendToRewards() {
			try {
				UserService.sendAnalytics({ event: 'nav_rewards_click', data: {} });
			} catch (err) { }
			this.$router.push('/savings').catch(() => { });
		},
		openDeposit() {
			this.$router.push('/funds/deposit').catch(() => { });
		},
		openVerify() {
			if (this.user?.access_level?.kyc == 'allow' || this.user?.access_level?.kyc == 'required') {
				this.toggleKYCCaptureModal(true);
			} else {
				this.$router.push('/settings').catch(() => { });
			}
		},

		onLinkClick() {
			this.$emit('linkclick');
		}

	}
});
</script>

<style scoped>
.toolbar {

	background-color: inherit;

	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0px;
	border-radius: 0px;
	height: 82px;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
}

.header-right-container {
	display: flex;
	align-items: center;
}

.mode-tag {
	background-color: inherit;
	border: 1.25px solid #808080;
	border-radius: 5px;
	font-size: 14px;
}

.header-portfolio-info {
	display: flex;
	align-items: center;
	margin-right: 24px;
}

.mr-16 {
	margin-right: 1rem;
}



#search_input {
	margin-left: 10px;
}

/* #wallet-link.regular-link {
		color: var(--Dark-Grey, #333);
		cursor: pointer;
	} */
.regular-link #wallet-link {
	width: 100%;
	padding-top: 6px;
	padding-bottom: 6px;
	transition: all 0.2s;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

#settings-link.regular-link {
	color: var(--Dark-Grey, #333);
	cursor: pointer;
}

#settings-link.regular-link:hover {
	color: var(--Dark-Green, #039954);
}

#settings-link.admin-link {
	color: var(--Dark-Blue, #1A6DF0);
}

#settings-link.admin-link:hover {
	color: var(--Dark-green, #039954);
}

#logout-link.regular-link {
	color: var(--Dark-Red, #F32D50);
}

#logout-link.regular-link:hover {
	color: var(--Dark-Red, #039954);
}

.header-divider {
	position: relative;
	border-left: 1px solid #c4c4c4 !important;
	height: 40px;
	margin: 0 20px;
}

.header-image {
	border-radius: 20px;
	width: 40px;
	height: 40px;
	max-height: 100%;
}

.header-image-wallet {
	width: 40px;
	height: 40px;
}

.custom-arrow {
	margin-right: 5px;
	align-self: center;
	vertical-align: middle;
	color: #4a4a4a;
}

.header-text {
	font-weight: 500;
	color: #4c4c4c;
	margin-right: 10px;
	align-self: center;
}

.header-text:hover {
	color: #000;
}

.header-loader {
	margin: auto 10px;
	width: 20px;
	height: 20px;
	border-width: 3px;
	border-color: transparent transparent #00c386 #00c386;
}

.dark-hover {
	color: #666666;
	cursor: pointer;
}

.dark-hover:hover {
	color: #333333;
	cursor: pointer;
}

.highlighted-mini {
	font-weight: 500;
	color: var(--text-text-primary, #333);

	/* Large Text Bold */
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: -0.16px;
}

@media screen and (min-width: 1023px) and (max-width: 1102px) {
	.highlighted-mini {

		font-weight: 500;
		color: var(--text-text-primary, #333);

		/* Large Text Bold */
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 22px;
		/* 137.5% */
		letter-spacing: -0.16px;
	}

	.number-headers {
		color: var(--text-text-secondary, #686475);

		/* Normal Text */
		font-size: 12px !important;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.14px;
	}
}

@media screen and (min-width: 1020px) and (max-width: 1030px) {
	.highlighted-mini {
		font-weight: 500;
		color: var(--text-text-primary, #333);

		/* Large Text Bold */
		font-size: 10px !important;
		font-style: normal;
		font-weight: 600;
		line-height: 22px;
		/* 137.5% */
		letter-spacing: -0.16px;
	}

	.number-headers {
		color: var(--text-text-secondary, #686475);

		/* Normal Text */
		font-size: 10px !important;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.14px;
	}
}

/* Tablet-specific styles */
.home-feature-tablet {
	display: block;

}

.settings-container {
	/* margin-left: 1rem; */
	margin-right: 20px;
}

.equity-switch {
	display: block;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 3px;
}

.currency-selector-label {
	color: #4c4c4c;
}

.equity-switch .switch {
	margin-left: 5px;
	/* margin-right: 0; */
}

.equity-switch .help-icon {
	margin-left: 5px;
	color: #ababac;
	margin-top: 2px;
}

.tag-centered {
	display: flex;
	align-items: center;
}

.tag.is-gold {
	background: linear-gradient(134deg, #ffd194 0%, #d8a159 100%);
	color: #fff;
	box-shadow: 0 2px 3px 0 rgba(102, 190, 255, 0.05), 0 3px 10px 0 rgba(0, 0, 0, 0.08);
	border-radius: 13px;
}

.tag.is-rainbow-promo {
	background-image: linear-gradient(134deg, #ffa3ff 0%, #34aaff 100%);
	box-shadow: 0 2px 3px 0 rgba(102, 190, 255, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.1);
	border-radius: 13px;
	transition-delay: 0s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.tag.is-rainbow-promo:hover {
	background-image: linear-gradient(134deg, #ffc1ff 0%, #54b7ff 100%);
	box-shadow: 0 2px 3px 0 rgba(102, 190, 255, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.1);
	border-radius: 13px;
	transition-delay: 0s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.is-clickable {
	cursor: pointer;
}

.icon.switcher-icon {
	color: #ababac;
	align-self: center;
	font-size: 22px;
	margin-left: 2px;
}

.icon.caret-red {
	margin-top: -5px;
	margin-right: 3px;
}

.icon.caret-green {
	margin-top: -4px;
	margin-right: 3px;
}

.non-hover-item {
	cursor: auto;
	font-size: inherit;
	font-weight: normal;
	color: inherit;
}

.non-hover-item:hover {
	color: #039954;
}

.username-text {
	white-space: initial;
	word-wrap: break-word;


	color: var(--Dark-Grey, #333);

	/* Small Text Bold */
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
}

.account-level {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	color: var(--Asphalt-Grey, #686475);

	/* Small Text */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
}

.account-level img {
	height: 14px;
}

.max-btn {
	height: 26px;
	font-size: 14px;
	font-weight: 600;
	background-color: #d3e8f8;
	border-color: #d3e8f8;
	border-radius: 7px;
}

.max-btn:hover {
	background-color: #91c6ed;
	border-color: #91c6ed;
}

.max-btn:active,
.max-btn:focus {
	background-color: #7abae9;
	border-color: #7abae9;
}

.max-btn.is-muted-buy {
	background-color: #b2e6d8;
	border-color: #b2e6d8;
}

.max-btn.is-muted-buy:hover {
	background-color: #80d5c0;
	border-color: #80d5c0;
}

.max-btn.is-muted-buy:active,
.max-btn.is-muted-buy:focus {
	background-color: #65ccb3;
	border-color: #65ccb3;
}

.is-red {
	color: #ff3860;
}

.wallet-section {
	padding-right: 1rem;
}

.wallet-section .help-section {
	white-space: pre-wrap;
}

.wallet-section button {
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
	text-overflow: ellipsis;
}

.wallet-section button>span.text {
	overflow: hidden;
	text-overflow: ellipsis;
}

.settings-menu {
	z-index: 3000;
}



.verify-button {
	background: #1a6df0;
	border-radius: 8px;
	font-weight: 700;
	font-size: 14px;
	color: #ffffff;
	font-family: 'Manrope';
	padding: 8px 21px;
	margin: 10px;
	cursor: pointer;
}

.button.currency-toggle-btn {
	background: #00c386;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	font-family: 'Manrope';
	border-radius: 20px;
	border-color: transparent;
	transition: all 0.25s;
}

.button.currency-toggle-btn:hover {
	background: #039954;
	transition: all 0.25s;
}

.currency-toggle-btn .currency-icon {
	margin-right: 0.5rem;
}

.insights-icon {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-right: 24px;
}

.header-left-container {
	min-width: 380px;
	margin-right: 5px;
}

.logo-container {
	margin-left: 20px;
	justify-content: flex-start;
	display: flex;
	align-items: center;
}

.logo-link {
	display: flex;
	align-items: center;
}

.logo {
	height: 40px;
	width: 40px;
	min-width: 40px;
}

.header-middle-container {
	margin-left: 24px;
}

.navigation-links-container {
	display: flex;
	margin-left: 0px;
}

.navigation-item {
	padding-left: 0px !important;
	padding-right: 24px !important;
	color: var(--Dark-Grey, #333);

	/* Normal Text Bold */
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
}

.left-center-combined {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.blockie-username {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	max-width: 200px;
}

.navigation-header {
	padding-left: 0px !important;
	padding-right: 24px !important;
	color: var(--text-text-primary, #333);

	/* Large Text Bold */
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: -0.16px;
}

.navigation-header:hover {
	color: #039954;

}

.number-headers {
	color: var(--text-text-secondary, #686475);

	/* Normal Text */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.14px;
}

.blockie-trigger-container {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.arrow-icon {
	margin-left: 8px;
}

.dropdown-menu {
	width: 500px;
}

.has-link {
	color: #34aaff;
}

.dropdown-text-items {


	/* Normal Text Bold */
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
	text-align: left;
}

.dropdown-arrow {
	stroke: #333333;

}

.navigation-header:hover .dropdown-arrow {
	stroke: #039954;

}

.blockie-trigger-container:hover .dropdown-arrow {
	stroke: #039954;

}

.toolbar-container {
	border: 0;
	background-color: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
	position: fixed;
	top: 0;
	left: 0px;
	width: 100%;
	z-index: 30;
}

.regular-link {
	font-size: 14px !important;
}

.green-bubble {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 22px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	background: #dffbe9;
	border-radius: 16px;
	font-weight: 700;
	font-size: 12px;
	color: #039954;
	text-align: center;
	padding-top: 0px;
}

@media screen and (max-width: 1300px) {
	.is-hidden-1300 {
		display: none !important;
	}
}

.unlock-button {
	display: flex;
	height: 44px;
	padding: 12px 10px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	background: #FFF;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	color: #686475;

}

.unlock-button:hover {
	color: #333;

	border: 1px solid #686475;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	cursor: pointer;
}
</style>