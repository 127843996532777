<template>
	<div class="slider-right" :class="{ 'slider-right-active': active }">
		<div class="slider-content">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	props: {
		active: Boolean
	}
});
</script>

<style scoped>
.slider-right {
	position: fixed;
	width: 100%;
	right: 0;
	top: 0;
	background-color: white;
	list-style: none;
	z-index: 100;
	overflow: hidden;
	/* Change overflow to hidden */

	transition: top 0.25s ease;
	transform: translate3d(100%, 0, 0);
}

.slider-right-active {
	transform: translate3d(0, 0, 300px);
}

.slider-content {
	max-height: calc(100vh - 0px);
	/* Set the maximum height of the content */
	overflow-y: auto;
	/* Enable vertical scrolling for content overflow */
	padding: 5px;

}
</style>