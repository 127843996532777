<template>
	<nav class="desktop-menu">
		<div class="logo-container">
			<router-link class="logo-link" to="/">
				<img class="logo" src="../assets/morpher_logo.svg" :alt="$t('MORPHER_LOGO')" />
			</router-link>
		</div>
		<NavigationLinks />

		<div class="lang-frame">
			<LanguageSelector />
		</div>
	</nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavigationLinks from '@/components/NavigationLinks.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';

export default defineComponent({
	components: {
		NavigationLinks,
		LanguageSelector
	}
});
</script>

<style scoped>
.desktop-menu {
	position: fixed;
	height: 100%;
	width: 180px;
	box-shadow: 5px 0 30px 0 rgba(61, 110, 146, 0.05);
	background-color: white;
	z-index: 1;
}

.logo-container {
	padding-left: 18px;
	padding-top: 28px;
	padding-bottom: 28px;
	display: flex;
}

.logo {
	height: 100%;
	width: 38px;
}

.lang-frame {
	position: absolute;
	padding: 10px;
	background: #fff;
	width: 100%;
	bottom: 0;
	display: flex;
	align-items: flex-start;
}
</style>
