/**
 * Transaction service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';
import createTransaction from '@/graphql/transaction/createTransaction.mutation.graphql';
import updateTransactionAffiliate from '@/graphql/transaction/updateTransactionAffiliate.mutation.graphql';
import purchaseADVOrderRequest from '@/graphql/transaction/purchaseADVOrderRequest.mutation.graphql';
import purchaseMoonpayOrderRequest from '@/graphql/transaction/purchaseMoonpayOrderRequest.mutation.graphql';
import purchaseSEPAOrderRequest from '@/graphql/transaction/purchaseSEPAOrderRequest.mutation.graphql';
import addSEPADeposit from '@/graphql/transaction/addSEPADeposit.mutation.graphql';
import withdrawalMoonpayOrderRequest from '@/graphql/transaction/withdrawalMoonpayOrderRequest.mutation.graphql';
import withdrawalMoonpayOrderConfirm from '@/graphql/transaction/withdrawalMoonpayOrderConfirm.mutation.graphql';
import purchasePaypalOrderRequest from '@/graphql/transaction/purchasePaypalOrderRequest.mutation.graphql';
import purchasePaypalOrderCreate from '@/graphql/transaction/purchasePaypalOrderCreate.mutation.graphql';
import purchasePaypalOrderApprove from '@/graphql/transaction/purchasePaypalOrderApprove.mutation.graphql';
import purchasePaypalOrderCaptureError from '@/graphql/transaction/purchasePaypalOrderCaptureError.mutation.graphql';
import purchaseCoinbaseOrderRequest from '@/graphql/transaction/purchaseCoinbaseOrderRequest.mutation.graphql';

import getTransaction from '@/graphql/transaction/getTransaction.query.graphql';
import tradeDataExport from '@/graphql/transaction/tradeDataExport.query.graphql';
import fundsDataExport from '@/graphql/transaction/fundsDataExport.query.graphql';
import type { tradeDataExportVariables, tradeDataExport as tradeDataExportResponse } from '@/graphql/transaction/types/tradeDataExport';
import type { fundsDataExportVariables, fundsDataExport as fundsDataExportResponse } from '@/graphql/transaction/types/fundsDataExport';
import type { getTransactionVariables, getTransaction as getTransactionResponse } from '@/graphql/transaction/types/getTransaction';

import signTransaction from '@/graphql/transaction/signTransaction.mutation.graphql';
import failTransaction from '@/graphql/transaction/failTransaction.mutation.graphql';
import updateTransaction from '@/graphql/transaction/updateTransaction.subscription.graphql';
import mphPrice from '@/graphql/transaction/mphPrice.subscription.graphql';
import type {
	purchasePaypalOrderRequestVariables,
	purchasePaypalOrderRequest as purchasePaypalOrderRequestResponse
} from '@/graphql/transaction/types/purchasePaypalOrderRequest';
import type {
	purchaseADVOrderRequestVariables,
	purchaseADVOrderRequest as purchaseADVOrderRequestResponse
} from '@/graphql/transaction/types/purchaseADVOrderRequest';
import type {
	purchaseMoonpayOrderRequestVariables,
	purchaseMoonpayOrderRequest as purchaseMoonpayOrderRequestResponse
} from '@/graphql/transaction/types/purchaseMoonpayOrderRequest';
import type {
	purchaseSEPAOrderRequestVariables,
	purchaseSEPAOrderRequest as purchaseSEPAOrderRequestResponse
} from '@/graphql/transaction/types/purchaseSEPAOrderRequest';
import type {
	addSEPADepositVariables,
	addSEPADeposit as addSEPADepositResponse
} from '@/graphql/transaction/types/addSEPADeposit';


import type {
	withdrawalMoonpayOrderRequestVariables,
	withdrawalMoonpayOrderRequest as withdrawalMoonpayOrderRequestResponse
} from '@/graphql/transaction/types/withdrawalMoonpayOrderRequest';
import type {
	withdrawalMoonpayOrderConfirmVariables,
	withdrawalMoonpayOrderConfirm as withdrawalMoonpayOrderConfirmResponse
} from '@/graphql/transaction/types/withdrawalMoonpayOrderConfirm';

import type {
	purchasePaypalOrderCreateVariables,
	purchasePaypalOrderCreate as purchasePaypalOrderCreateResponse
} from '@/graphql/transaction/types/purchasePaypalOrderCreate';
import type {
	purchasePaypalOrderApproveVariables,
	purchasePaypalOrderApprove as purchasePaypalOrderApproveResponse
} from '@/graphql/transaction/types/purchasePaypalOrderApprove';
import type {
	purchasePaypalOrderCaptureErrorVariables,
	purchasePaypalOrderCaptureError as purchasePaypalOrderCaptureErrorResponse
} from '@/graphql/transaction/types/purchasePaypalOrderCaptureError';

import type { createTransactionVariables, createTransaction as createTransactionResponse } from '@/graphql/transaction/types/createTransaction';
import type { updateTransactionAffiliateVariables, updateTransactionAffiliate as updateTransactionAffiliateResponse } from '@/graphql/transaction/types/updateTransactionAffiliate';


import type { signTransactionVariables, signTransaction as signTransactionResponse } from '@/graphql/transaction/types/signTransaction';
import type { failTransactionVariables, failTransaction as failTransactionResponse } from '@/graphql/transaction/types/failTransaction';
import type { updateTransaction as updateTransactionResult } from '@/graphql/transaction/types/updateTransaction';
import type { mphPrice as mphPriceResult } from '@/graphql/transaction/types/mphPrice';
import type { Transaction } from '../types/schema';

import type {
	purchaseCoinbaseOrderRequestVariables,
	purchaseCoinbaseOrderRequest as purchaseCoinbaseOrderRequestResponse
} from '@/graphql/transaction/types/purchaseCoinbaseOrderRequest';
import type { FetchResult } from '@apollo/client/core';

export interface TransactionWithSubscription extends Transaction {
	subscription?: any;
}

let SubscriptionList: any[] = [];

export const TransactionService = {
	tradeDataExport(variables: tradeDataExportVariables) {
		return apollo.query<tradeDataExportResponse>({
			query: tradeDataExport,
			variables
		});
	},
	getTransaction(variables: getTransactionVariables) {
		return apollo.query<getTransactionResponse>({
			query: getTransaction,
			variables
		});
	},	
	fundsDataExport(variables: fundsDataExportVariables) {
		return apollo.query<fundsDataExportResponse>({
			query: fundsDataExport,
			variables
		});
	},
	createTransaction(data: createTransactionVariables) {
		return apollo.mutate<createTransactionResponse>({
			mutation: createTransaction,
			variables: {
				data: data.data
			}
		});
	},
	updateTransactionAffiliate(data: updateTransactionAffiliateVariables) {
		return apollo.mutate<updateTransactionAffiliateResponse>({
			mutation: updateTransactionAffiliate,
			variables: 
				data
			
		});
	},	
	purchasePaypalOrderRequest(data: purchasePaypalOrderRequestVariables) {
		return apollo.mutate<purchasePaypalOrderRequestResponse>({
			mutation: purchasePaypalOrderRequest,
			variables: {
				data: data.data
			}
		});
	},
	purchaseADVOrderRequest(data: purchaseADVOrderRequestVariables) {
		return apollo.mutate<purchaseADVOrderRequestResponse>({
			mutation: purchaseADVOrderRequest,
			variables: {
				data: data.data
			}
		});
	},
	purchaseMoonpayOrderRequest(data: purchaseMoonpayOrderRequestVariables) {
		return apollo.mutate<purchaseMoonpayOrderRequestResponse>({
			mutation: purchaseMoonpayOrderRequest,
			variables: {
				data: data.data
			}
		});
	},
	purchaseSEPAOrderRequest(data: purchaseSEPAOrderRequestVariables) {
		return apollo.mutate<purchaseSEPAOrderRequestResponse>({
			mutation: purchaseSEPAOrderRequest,
			variables: {
				data: data.data
			}
		});
	},
	addSEPADeposit(variables: addSEPADepositVariables) {
		return apollo.mutate<addSEPADepositResponse>({
			mutation: addSEPADeposit,
			variables
		});
	},
	withdrawalMoonpayOrderRequest(data: withdrawalMoonpayOrderRequestVariables) {
		return apollo.mutate<withdrawalMoonpayOrderRequestResponse>({
			mutation: withdrawalMoonpayOrderRequest,
			variables: {
				data: data.data
			}
		});
	},
	withdrawalMoonpayOrderConfirm(data: withdrawalMoonpayOrderConfirmVariables) {
		return apollo.mutate<withdrawalMoonpayOrderConfirmResponse>({
			mutation: withdrawalMoonpayOrderConfirm,
			variables: {
				transaction_id: data.transaction_id
			}
		});
	},
	
	purchasePaypalOrderCreate(data: purchasePaypalOrderCreateVariables) {
		return apollo.mutate<purchasePaypalOrderCreateResponse>({
			mutation: purchasePaypalOrderCreate,
			variables: {
				data: data.data
			}
		});
	},
	purchasePaypalOrderApprove(data: purchasePaypalOrderApproveVariables) {
		return apollo.mutate<purchasePaypalOrderApproveResponse>({
			mutation: purchasePaypalOrderApprove,
			variables: {
				data: data.data
			}
		});
	},
	purchasePaypalOrderCaptureError(data: purchasePaypalOrderCaptureErrorVariables) {
		return apollo.mutate<purchasePaypalOrderCaptureErrorResponse>({
			mutation: purchasePaypalOrderCaptureError,
			variables: {
				data: data.data
			}
		});
	},
	purchaseCoinbaseOrderRequest(data: purchaseCoinbaseOrderRequestVariables) {
		return apollo.mutate<purchaseCoinbaseOrderRequestResponse>({
			mutation: purchaseCoinbaseOrderRequest,
			variables: {
				data: data.data
			}
		});
	},
	signTransaction(data: signTransactionVariables) {
		return apollo.mutate<signTransactionResponse>({
			mutation: signTransaction,
			variables: {
				data: data.data
			}
		});
	},
	failTransaction(params: failTransactionVariables) {
		return apollo.mutate<failTransactionResponse>({
			mutation: failTransaction,
			variables: params
		});
	},
	unsubscribe(transaction: TransactionWithSubscription) {
		if (transaction.subscription) {
			transaction.subscription.unsubscribe();
			SubscriptionList = SubscriptionList.filter(sub => sub.id !== transaction.id);
		}
	},
	subscribe(transaction: TransactionWithSubscription, callback: (data: FetchResult<updateTransactionResult>) => void) {
		const existingSubscription = SubscriptionList.find(sub => sub.id === transaction.id);
		if (existingSubscription) {
			transaction.subscription = existingSubscription.sub;
			this.unsubscribe(transaction);
		}

		transaction.subscription = apollo
			.subscribe({
				query: updateTransaction,
				variables: {
					event: 'TRANSACTION_' + transaction.id
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in transaction.subscription', err)
				}
			});

		SubscriptionList.push({ id: transaction.id, sub: transaction.subscription });
	},
	subscribePurchaseUpdate(eth_address: string, callback: (data: FetchResult<updateTransactionResult>) => void) {

		const subscription = apollo
			.subscribe({
				query: updateTransaction,
				variables: {
					event: 'PURCHASE_UPDATE_' + eth_address
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribePurchaseUpdate', err)
				}
			});

		return subscription;
	},	
	unsubscribeMPHPrice(subscription: any) {
		if (subscription) {
			subscription.unsubscribe();
			SubscriptionList = SubscriptionList.filter(sub => sub.id !== 'mphPrice');
		}
	},
	subscribeMPHPrice(callback: (data: FetchResult<updateTransactionResult>) => void) {
		const existingSubscription = SubscriptionList.find(sub => sub.id === 'mphPrice');
		if (existingSubscription) {
			this.unsubscribe(existingSubscription);
		}

		const subscription: any = apollo
			.subscribe({
				query: mphPrice,
				variables: {
					event: 'other'
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeMPHPrice', err)
				}
			});

		SubscriptionList.push({ id: 'mphPrice', sub: subscription });

		return subscription;
	}
};
