query getAirdropList($email: String, $startDate: String, $endDate: String, $blocked: Boolean) {
    getAirdropList(email: $email, startDate: $startDate, endDate: $endDate, blocked: $blocked) {
        uuid
        email
        full_name
        date_added
        tokens_earned
        num_referrals
        kyc_status
        kyc_date
        ranking
        document_front_file
    }
}
