query getBlockList($blocked: Boolean, $unblock_uuid: String) {
    getBlockList(blocked: $blocked, unblock_uuid: $unblock_uuid) {
        uuid
        referred_by
        document_country
        date_blocked
        date_unblocked
        blocked
    }
}
