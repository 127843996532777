<template>
	<div class="has-font-manrope">
		<div class="deposit_content has-text-left">
			<div class="header-bar-global">
				<button class="button back-btn" @click="closePurchaseAgreement()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.PURCHASE_AGREEMENT') }}
			</div>

			<div class="agreement_body has-text-left mt-15">
				<ul class="agreement_list">
					<li>
						{{ $t('NOT_ROBOT') }}
					</li>
					<li>
						{{ $t('AT_LEAST_18') }}
					</li>
					<li>
						{{ $t('WALLET_SUPPORTS_ERC20') }}
					</li>
					<li v-html="$t('READ_PURCHASE_AGREEMENT', {
						documentLink: user?.document_country === 'AT' ? information_sheet : token_offer_doc,
						documentName: user?.document_country === 'AT' ? 'Information Sheet' : 'Token Offer Document',
						agreementLink: purchase_agreement
					})
						"></li>
					<li>
						{{ $t('PURCHASE_MPH_MY_NAME') }}
					</li>
					<li v-if="funds.provider == 'paypal'">
						{{ $t('CONSENT_PAYPAL_WITHDRAWAL') }}
					</li>
					<li v-html="$t('AUSTRIAN_LAW')" />
				</ul>
			</div>

			<button data-cy="withdrawFunds" class="button big-outlined-button-green xlarge is-fullwidth mt-15"
				@click="agreePurchaseAgreement()">
				<span
					style="font-family: 'Manrope'; font-style: normal; font-weight: 700; font-size: 14px;">Agree</span>
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { useFundsStore } from '@/store/modules/funds';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'Deposit',
	data() {
		return {};
	},
	computed: {
		...mapState(useContractStore, {
			balance: (state) => state.balance,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useFundsStore, {
			funds: (state) => state,
		}),

		information_sheet() {
			return 'https://' + import.meta.env.VITE_MORPHER_LANDING_PAGE + '/docs/Information-Sheet_2020-10-06.pdf';
		},
		token_offer_doc() {
			return 'https://' + import.meta.env.VITE_MORPHER_LANDING_PAGE + '/docs/Token-Offer-Document-MPH_2023-02-01.pdf';
		},
		purchase_agreement() {
			return 'https://' + import.meta.env.VITE_MORPHER_LANDING_PAGE + '/docs/Token-Purchase-Agreement_2023-02-01.pdf';
		}
	},
	async mounted() { },
	methods: {
		...mapActions(useFundsStore, {
			updateFunds: 'updateFunds',
		}),

		closePurchaseAgreement() {
			this.updateFunds({ showPurchaseAgreement: false });
		},

		agreePurchaseAgreement() {
			this.updateFunds({ showPurchaseAgreement: false, purchaseAgreementConsent: true });
		}
	}
});
</script>
<style scoped>
.deposit_content {
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 24px;
}

.agreement_body {

	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	padding-left: 5px;
}

.agreement_list {
	list-style: disc outside none;
	padding-left: 16px;
}

.agreement_body {
	overflow-y: auto;
	max-height: calc(100vh - 180px);
	display: inline-block;
}
</style>
