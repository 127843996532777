query getBalance($eth_address: String, $include_mainchain: Boolean) {
    getBalance(eth_address: $eth_address, include_mainchain: $include_mainchain) {
        eth_address
        mph_sidechain
        mph_mainchain
        eth_mainchain
        eth_sidechain
        investment_sidechain
        investment_mainchain
        requested_withdrawal_amount
        processing_withdrawal_amount
        requested_deposit_amount
        processing_deposit_amount
        mph_sidechain_eth_quote
        mph_sidechain_usd_quote
        mph_mainchain_eth_quote
        mph_mainchain_usd_quote
        linked_chain_tokens
        linked_chain_tokens_claimed
        linked_chain_tokens_to_claim
        purchase_cooldown
        gas_price    
        eth_price        
        mph_rate
        proof
        purchase_limits
        exchange_forwarder
    }
}