<template>
	<section>
		<b-modal :modelValue="showInterviewModal" has-modal-card @close="closeModal">
			<form @submit.prevent="closeModal">
				<div class="modal-card terminal">
					<div class="header-image">
						<img src="@/assets/img/img_timer_interview.svg" />
					</div>
					<div class="has-text-left has-font-manrope p-5">
						<h1 class="has-text-weight-bold is-size-18">
							{{ $t('SURVEY_HEADER') }}
						</h1>

						<p class="is-size-14 is-line-height-20 mt-2" v-html="$t('SURVEY_TEXT_1')">

						</p>

						<p class="is-size-14 is-line-height-20 mt-2">
							{{ $t('SURVEY_TEXT_2') }}
						</p>

						<b-button class="button plain-primary-btn is-fullwidth mt-4" @click="openSurvey">
							{{ $t('SURVEY_START_BUTTON') }}
						</b-button>
						<b-button class="button plain-secondary-btn is-fullwidth mt-3" @click="closeModalRemind">
							{{ $t('SURVEY_SKIP_BUTTON') }}
						</b-button>
					</div>
				</div>
			</form>
		</b-modal>

	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex
/* @group Components_Modals */
/*
  <h4> <b> New portfolio detected </b> </h4>
  <br> Airdrop message - 10k MPH added to wallet
*/
export default defineComponent({
	name: 'UserInterviewModal',
	components: {
	},
	data() {
		return {
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showInterviewModal: (state) => state.showInterviewModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),

	},

	mounted() {
		// this.updateUserPayload({ payloadOption: 'mph_reward', payloadValue: 'false' });
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleInterviewModal: 'toggleInterviewModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),

		generateLink() {
			let url = 'https://morpher.typeform.com/to/UUfOpfmF#email='
			if (this.user?.email) {
				url = url + this.user.email;
			}
			return url
		},
		calendlyLink() {
			let url = 'https://calendly.com/denis_morpher/user_interviews?email='
			if (this.user?.email) {
				url = url + this.user.email;
			}
			return url
		},
		openSurvey() {
			// this.updateUserPayload({ payloadOption: 'invite_to_survey', payloadValue: 'false' });
			localStorage.setItem('survey_seen', Date.now().toString());
			const url = this.calendlyLink();
			window.open(url, '_blank');
			this.closeModal();
		},
		closeModalRemind() {
			this.updateUserPayload({ payloadOption: 'invite_to_survey', payloadValue: 'false' });
			localStorage.setItem('survey_seen', (Date.now() - 1000 * 60 * 60 * 24 * 6).toString());
			this.toggleInterviewModal(false);
		},
		closeModal() {
			localStorage.setItem('survey_seen', Date.now().toString());
			this.toggleInterviewModal(false);
		},
	}
});
</script>

<style scoped>
.header-image {
	background-color: #EAFCFF;
	text-align: center;
}

.header-image img {
	height: 200px;
	margin: 16px auto;
}
</style>
