<template>
	<section>
		<b-modal :modelValue="showOnboardModal" has-modal-card :can-cancel="modalPage !== 0" @close="closeModal">
			<div class="modal-card terminal has-font-manrope onboard-card">
				<b-carousel v-model="modalPage" class="custom-car" :autoplay="false" icon-pack="fa"
					icon-size="is-medium" :arrow-hover="false" :arrow="false" :repeat="false" :indicator-inside="false"
					:has-drag="false" :indicator="false">
					<!-- funding processing -->
					<b-carousel-item>

						<div class="funding-animation" ref="onboardFund"></div>

						<h1 class="get-mph-header mt-3">{{ $t('ONBOARDING_FUNDING_HEAD') }}</h1>
						<p class="is-size-14 is-line-height-20 mt-3 mb-20">{{ $t('ONBOARDING_FUNDING_TEXT') }}</p>

					</b-carousel-item>

					<!-- functing received -->
					<b-carousel-item>
						<div class="terminal-content has-text-centered">
							<img src="@/assets/img/mph_on_logon.svg" :alt="$t('LOGON_MPH_HEAD')" />
							<h1 class="get-mph-header mt-3">{{ $t('LOGON_MPH_HEAD') }}</h1>
							<p class="green-subtitle mt-5px">
								{{ $t('WELCOME_TO_MORPHER') }}</p>
							<p class="is-size-14 is-line-height-20 mt-3">
								{{ $t('ONBOARDING_GET_10MPH') }}
							</p>
							<button class="button plain-primary-btn is-fullwidth mt-4" @click="modalPage = 2">
								{{ $t('NEXT') }}
							</button>
						</div>
					</b-carousel-item>

					<!-- First Trade -->
					<b-carousel-item>
						<div>
							<div class="market-backround">
								<svg xmlns="http://www.w3.org/2000/svg" width="320" height="320" viewBox="0 0 320 320"
									fill="none" v-if="onboard_markets && onboard_markets[selectedMarket]">
									<g opacity="0.2" filter="url(#filter0_f_2194_18819)">
										<circle cx="160" cy="160" r="80"
											:fill="onboard_markets[selectedMarket].onboarding_color" />
									</g>
									<defs>
										<filter id="filter0_f_2194_18819" x="0" y="0" width="320" height="320"
											filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
											<feFlood flood-opacity="0" result="BackgroundImageFix" />
											<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
												result="shape" />
											<feGaussianBlur stdDeviation="40"
												result="effect1_foregroundBlur_2194_18819" />
										</filter>
									</defs>
								</svg>
							</div>

							<div class="market-layer">
								<div v-if="onboard_markets && onboard_markets[selectedMarket]"
									class="is-flex market-select">

									<a href="#" @click="marketPrev">
										<img src="@/assets/icons/onboard-btn-prev.svg">
									</a>


									<ChartCardOnboarding :market="onboard_markets[selectedMarket]" class="card-box" />
									<div class="market-select-button" @click="marketNext">
										<img src="@/assets/icons/onboard-btn-next.svg">
									</div>

								</div>

								<h1 class="modal-title mt-20" v-html="$t('ONBOARDING_FIRST_TRADE_HEAD')"></h1>

								<p class="mt-4 has-text-left is-size-14 is-line-height-20">
									{{ $t('ONBOARDING_FIRST_TRADE_TEXT') }}
								</p>


								<button class="button plain-primary-btn is-fullwidth mt-4"
									:disabled="!onboard_markets || !onboard_markets[selectedMarket]"
									:class="{ 'is-loading': tradeProcessing }" @click="processTrade">
									{{ $t('ONBOARDING_PICK_BUTTON') }} {{ onboard_markets &&
										onboard_markets[selectedMarket] ? onboard_markets[selectedMarket].symbol : '' }}
								</button>

							</div>


						</div>
					</b-carousel-item>

					<!-- Trade Success -->
					<b-carousel-item>
						<div class="terminal-content">


							<div>
								<img class="claim-image" src="@/assets/icons/new/success.svg" />


								<h1 class="modal-title">{{ $t('SUCCESS!') }}</h1>

								<p class="mt-4 has-text-center is-size-14 is-line-height-20">
									{{ $t('ONBOARDING_TRADE', { symbol: order.symbol }) }}
								</p>

								<div class="order-result">
									<div class="info is-flex">
										<div class="has-text-left">
											<p class="amount-head">
												{{ $t('INVESTMENT') }}
											</p>
											<p class="amount">
												{{ round(order.amount, 0) }} MPH
											</p>
										</div>
										<div>
											<div class="buy-tag">
												{{ $t('BUY') }}
											</div>
										</div>

									</div>

									<div class="info is-flex">
										<div class="data has-text-left">
											{{ $t('MARKET_PRICE') }}
										</div>

										<div class="data has-text-right">
											{{ roundFormatter(order.price) }}
										</div>


									</div>

									<div class="info-no-border is-flex">
										<div class="data has-text-left">
											{{ $t('LEVERAGE') }}
										</div>

										<div class="data has-text-right">

											{{ round(order.leverage, 1) }}
										</div>


									</div>

								</div>

								<button class="button plain-primary-btn is-fullwidth mt-4" @click="verifyNow()">
									{{ $t('DONE') }}
								</button>
							</div>
						</div>
					</b-carousel-item>

					<!-- funding timeout -->
					<b-carousel-item>
						<div class="terminal-content has-text-centered">
							<img src="@/assets/img/mph_on_logon.svg" :alt="$t('LOGON_MPH_HEAD')" />
							<h1 class="get-mph-header mt-3">{{ $t('ONBOARDING_FUNDING_TIMOUT_HEAD') }}</h1>

							<p class="is-size-14 is-line-height-20 mt-3">
								{{ $t('ONBOARDING_FUNDING_TIMOUT_TEXT') }}
							</p>
							<button class="button plain-primary-btn is-fullwidth mt-4" @click="closeModal">
								{{ $t('DONE') }}
							</button>
						</div>
					</b-carousel-item>

					<!-- trade error -->
					<b-carousel-item>
						<div class="terminal-content has-text-centered">
							<img class="claim-image" src="@/assets/icons/error.svg" />


							<h1 class="modal-title">{{ $t('OOPS') }}!</h1>

							<p class="is-size-14 is-line-height-20 mt-3">
								{{ $t('ONBOARDING_TRADE_ERROR') }}
							</p>
							<button class="button plain-primary-btn is-fullwidth mt-4" @click="closeModal">
								{{ $t('CLOSE') }}
							</button>
						</div>
					</b-carousel-item>
				</b-carousel>
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { useMarketsStore } from '@/store/modules/markets';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { WatchlistService } from '@/services/WatchlistService';
import { MarketService } from '@/services/MarketService';
import { OrderService } from '@/services/OrderService';
import { ERROR } from '@/store/mutation-types';
import lottie from 'lottie-web';
import ChartCardOnboarding from '../charts/ChartCardOnboarding.vue';
import Util from '@/services/shared';
import { processOverlay, closeFortmaticOverlay } from '@/helpers/fortmatic';
import { morpherOracleAbi, morpherTokenAbi } from '@/contracts/abis';
import to from 'await-to-js';
import { sleep } from '@/helpers/utils'
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import type { Market } from '@/types/schema';
import { ESideChainTransaction, getContract, soliditySha3 } from '@/helpers/viem';
import { parseEventLogs } from 'viem';
import type { TAddress } from '@/types/graphql-global-types';

export default defineComponent({
	name: 'OnboardModal',
	components: {
		ChartCardOnboarding
	},
	data() {
		return {
			modalPage: 0,
			displayedNumber: 0,
			displayedNumber2: 0,
			watchlist: [] as any[],
			tradeProcessing: false,
			onboard_markets: [] as any[],
			watchlist_markets: [
				{ id: 'STOCK_ABNB', name: 'Airbnb', selected: false },
				{ id: 'CRYPTO_LTC', name: 'Litecoin', selected: false },
				{ id: 'COMMODITY_XAU', name: 'Gold', selected: false },
				{ id: 'STOCK_META', name: 'Meta', selected: false },
				{ id: 'STOCK_SHOP', name: 'Shopify', selected: false },
				{ id: 'STOCK_TSLA', name: 'Tesla', selected: false },
				{ id: 'UNIQUE_BAYC', name: 'BAYC', selected: false },
				{ id: 'STOCK_DIS', name: 'Disney', selected: false },
				{ id: 'CRYPTO_BTC', name: 'Bitcoin', selected: false },
				{ id: 'STOCK_AAPL', name: 'Apple', selected: false },
				{ id: 'STOCK_COIN', name: 'Coinbase', selected: false },
				{ id: 'COMMODITY_BRENT', name: 'Brent Oil', selected: false },
				{ id: 'CRYPTO_DOGE', name: 'Dogecoin', selected: false },
				{ id: 'STOCK_SPOT', name: 'Spotify', selected: false },
				{ id: 'STOCK_PYPL', name: 'PayPal', selected: false },
				{ id: 'INDEX_SPX', name: 'S&P 500', selected: false },
				{ id: 'STOCK_RBLX', name: 'Roblox', selected: false },
				{ id: 'STOCK_NKE', name: 'Nike', selected: false },
				{ id: 'STOCK_MSFT', name: 'Microsoft', selected: false },
				{ id: 'CRYPTO_1INCH', name: '1inch', selected: false },
				{ id: 'STOCK_BYND', name: 'Beyond Meat', selected: false },
				{ id: 'UNIQUE_PUNK', name: 'CryptoPunks', selected: false },
				{ id: 'STOCK_AMZN', name: 'Amazon', selected: false },
				{ id: 'CRYPTO_SHIB', name: 'Shiba Inu', selected: false },
				{ id: 'STOCK_PFE', name: 'Pfizer', selected: false },
				{ id: 'STOCK_NFLX', name: 'Netflix', selected: false },
				{ id: 'STOCK_ACB', name: 'Aurora Cannabis', selected: false },
				{ id: 'STOCK_MCD', name: 'McDonald’s', selected: false },
				{ id: 'FOREX_EUR_USD', name: 'EUR / USD', selected: false },
				{ id: 'CRYPTO_ETH', name: 'Ethereum', selected: false },
				{ id: 'STOCK_GOOGL', name: 'Alphabet', selected: false },
				{ id: 'STOCK_NVDA', name: 'Nvidia', selected: false },
				{ id: 'CRYPTO_MATIC', name: 'Polygon', selected: false }
			],
			scrolls: {
				top: false,
				bottom: true
			},
			functionTimeout: null as null | NodeJS.Timeout | number,
			selectedMarket: 0,
			onboardingLoaded: false,
			order: { symbol: 'TSLA', amount: 10, price: 133.45, leverage: 1 },
			orderCreating: false,
			orderCreatingTimeout: null as null | NodeJS.Timeout | number,
			waitOnWalletInterval: null as null | NodeJS.Timeout | number,
			waitOnOrderInterval: null as null | NodeJS.Timeout | number,
			waitOnOrderCount: 0,
			orderSubscription: null as any,
			transactionNumber: 0,
			transactionError: 0,
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showOnboardModal: (state) => state.showOnboardModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
			payload: (state) => state.data?.payload,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
			wrongNetwork: (state) => state.wrongNetwork,
			wrongWallet: (state) => state.wrongWallet,
			eth_address: (state) => state.eth_address,
			isLoggedInWallet: (state) => state.isLoggedInWallet,
			fortmatic: (state) => state.fortmatic,
			morpherwallet: (state) => state.morpherwallet,
			lastTransactionDate: (state) => state.lastTransactionDate,

			orderCompleteMessage: (state) => state.orderCompleteMessage,
			walletClient: (state) => state.walletClient,
		}),
		...mapState(useMarketsStore, {
			markets: (state) => state.data,
			mph_market: (state) => state.mph_market,
		}),
		fundedAmount() {
			return Math.trunc((this.activePortfolio?.cash_balance || 0) / Math.pow(10, 18)).toLocaleString();
		},
		mphMarketClose() {
			if (this.mph_market && this.mph_market.close) {
				return Number(this.mph_market.close);
			} else {
				return null;
			}
		},
		userData() {
			return {
				id: this.user?.id,
				email: this.user?.email,
				username: this.user?.username,
				status: this.user?.status,
				role: this.user?.role,
				eth_address: this.user?.eth_address,
				wallet_type: this.user?.wallet_type,
				withdrawal_blocked: this.user?.withdrawal_blocked,
				withdrawal_unblock_date: this.user?.withdrawal_unblock_date,
				withdrawal_block_reason: this.user?.withdrawal_block_reason,
				access_level: this.user?.access_level,
				kyc_status: this.user?.kyc_status,
				airdrop_id: this.user?.airdrop_id
			};
		}
	},
	watch: {
		markets() {

			if (this.markets && this.markets.length > 0 && this.onboard_markets.length == 0 && !this.onboardingLoaded) {
				this.loadOnboardingMarkets();
			}
		},
		activePortfolio(nv) {
			if (this.modalPage == 0) {
				if (nv.cash_balance > 0) {
					if (this.functionTimeout) {
						clearTimeout(this.functionTimeout)
						this.modalPage = 1;
					}
				}
			}
		}
	},
	beforeUnmount() {
		if (this.orderSubscription !== null) {
			OrderService.unsubscribe(this.activePortfolio?.eth_address || '', this.orderSubscription);
			this.orderSubscription = null;
		}
	},
	async mounted() {
		const logoContainer = this.$refs.onboardFund as InstanceType<typeof HTMLDivElement>;;
		if (logoContainer)
			lottie.loadAnimation({
				container: logoContainer,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				path: window.location.protocol + '//' + window.location.host + '/trade/animation/wallet-ethereum.json'
			});

		if (!this.mph_market) {
			this.getMPHMarket({
				component: this
			});
		}

		if (this.markets && this.markets.length > 0 && this.onboard_markets.length == 0 && !this.onboardingLoaded) {
			this.loadOnboardingMarkets();
		}

		this.subscribe();

		setTimeout(() => {
			this.checkFunding();
		}, 3000);

	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,

		}),
		...mapActions(useModalsStore, {
			toggleOnboardModal: 'toggleOnboardModal',
			toggleNetworkMetamaskModal: 'toggleNetworkMetamaskModal',
			toggleResetMetamaskModal: 'toggleResetMetamaskModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
			getTokenUserData: 'getTokenUserData',
			getPositionValue: 'getPositionValue',
		}),
		...mapActions(useMarketsStore, {
			getMPHMarket: 'getMPHMarket',
		}),
		...mapActions(useContractStore, {
			startWallet: 'startWallet',
			checkWalletLoggedIn: 'checkWalletLoggedIn',
			morpherwalletLogout: 'morpherwalletLogout',
			setLastTransactionDate: 'setLastTransactionDate',
			setupContracts: 'setupContracts',
			setOverlayState: 'setOverlayState',
		}),

		async loadOnboardingMarkets() {
			this.onboardingLoaded = true;

			let result = await MarketService.getOnboardingMarkets()

			let onboard_markets: any[] = []

			result.data.getOnboardingMarkets.forEach((market: any) => {

				let mark: any = this.markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') == Util.formatMarketId(market.type, market.symbol));
				if (mark) {
					mark.onboarding_color = market.onboarding_color
					onboard_markets.push(mark)
				}
			});

			this.onboard_markets = onboard_markets;


		},
		closeModal() {
			this.updateUserPayload({ payloadOption: 'new_user', payloadValue: 'false' });
			this.toggleOnboardModal(false);
		},
		checkFunding() {
			if (this.modalPage == 0) {
				if ((this.activePortfolio?.cash_balance || 0) > 0) {
					this.modalPage = 1;
				} else {
					this.functionTimeout = setTimeout(() => {
						this.fundingtimeout();
					}, 12000);
				}
			}
		},
		marketPrev() {
			if (this.selectedMarket == 0) {
				this.selectedMarket = this.onboard_markets.length - 1;
			} else {
				this.selectedMarket = this.selectedMarket - 1;
			}
		},
		marketNext() {
			if (this.selectedMarket == this.onboard_markets.length - 1) {
				this.selectedMarket = 0;
			} else {
				this.selectedMarket = this.selectedMarket + 1;
			}
		},
		fundingtimeout() {
			if (this.modalPage == 0) {
				if ((this.activePortfolio?.cash_balance || 0) > 0) {
					this.modalPage = 1;
				} else {
					this.modalPage = 4;
				}
			}
		},

		verifyNow() {
			this.$router.push('/').catch(() => { });
			this.closeModal();
		},
		async createOrder() {

			if (this.orderCreating) {
				return;
			}

			this.orderCreating = true;
			if (this.orderCreatingTimeout) {
				clearTimeout(this.orderCreatingTimeout);
				this.orderCreatingTimeout = null;
			}
			this.orderCreatingTimeout = setTimeout(() => {
				this.orderCreatingTimeout = null;
				this.orderCreating = false;
			}, 5000);

			let open_mph_token_amount = 10;
			let close_shares_amount = 0;
			const timestamp = Date.now();

			if (this.user?.wallet_type === 'metamask' && this.wrongWallet) {
				this.orderCreating = false;
				this.tradeProcessing = false;

				this.$buefy.dialog.alert({
					title: this.$t('INCORRECT_ACCOUNT_HEAD'),
					message: this.$t('INCORRECT_ACCOUNT_METAMASK'),
					confirmText: this.$t('OK')
				});
				return;
			}

			if (this.user?.wallet_type === 'metamask' && this.wrongNetwork) {
				this.orderCreating = false;
				this.tradeProcessing = false;
				this.toggleNetworkMetamaskModal(true);
				return;
			}

			if (this.user?.wallet_type === 'metamask' && (this.eth_address === '' || this.wrongNetwork !== false)) {
				this.startWallet({ load: true, order: false });
				if (this.waitOnWalletInterval) {
					clearTimeout(this.waitOnWalletInterval);
					this.waitOnWalletInterval = null;
				}
				if (this.orderCreatingTimeout) {
					clearTimeout(this.orderCreatingTimeout);
					this.orderCreatingTimeout = null;
				}
				this.orderCreating = false;
				this.waitOnWallet(() => {
					this.createOrder();
				});
				return;
			}

			await this.checkWalletLoggedIn();

			if (this.wrongWallet) {
				this.orderCreating = false;
				this.tradeProcessing = false;
				if (this.user?.wallet_type === 'morpherwallet') {
					await this.morpherwalletLogout();
				}
				this.$buefy.dialog.alert({
					title: this.$t('INCORRECT_ACCOUNT_HEAD'),
					message: this.$t('INCORRECT_ACCOUNT_TRADE'),
					confirmText: this.$t('OK')
				});
				return;
			}



			if (this.user?.wallet_type === 'morpherwallet') {
				const isLoggedIn = await this.morpherwallet.isLoggedIn();
				if (!isLoggedIn.isLoggedIn) {
					// start the portis wallet - order: true will ensure that the portis login is opened
					this.startWallet({ load: true, order: true });
					if (this.waitOnWalletInterval) {
						clearTimeout(this.waitOnWalletInterval);
						this.waitOnWalletInterval = null;
					}
					if (this.orderCreatingTimeout) {
						clearTimeout(this.orderCreatingTimeout);
						this.orderCreatingTimeout = null;
					}
					this.orderCreating = false;
					this.waitOnWallet(() => {
						this.createOrder();
					});
					return;
				}
			}
			// check if the user is using portis and is not logged in
			if (
				(this.user?.wallet_type === 'portis' || this.user?.wallet_type === 'fortmatic') &&
				(!this.isLoggedInWallet)
			) {
				// start the portis wallet - order: true will ensure that the portis login is opened
				this.startWallet({ load: true, order: true });
				if (this.waitOnWalletInterval) {
					clearTimeout(this.waitOnWalletInterval);
					this.waitOnWalletInterval = null;
				}
				if (this.orderCreatingTimeout) {
					clearTimeout(this.orderCreatingTimeout);
					this.orderCreatingTimeout = null;
				}
				this.orderCreating = false;
				this.waitOnWallet(() => {
					this.createOrder();
				});
				return;
			}


			let selectedMarket = this.onboard_markets[this.selectedMarket];

			if (!selectedMarket) {
				this.orderCreating = false;
				this.tradeProcessing = false;

				return this.error({ message: 'NO_MARKET_SELECTED' });

			}


			this.setLastTransactionDate(Date.now());



			const market = soliditySha3(Util.formatMarketId(selectedMarket.type, selectedMarket.symbol));

			if (this.walletType === 'fortmatic') {
				this.setOverlayState({
					amount: String(open_mph_token_amount),
					state: 'approve',
					market: selectedMarket.name
				});
				processOverlay('trade');
			}

			let currentPosition: any;
			try {
				currentPosition = await this.getPositionValue({
					eth_address: this.user?.eth_address,
					market_id: Util.formatMarketId(selectedMarket.type, selectedMarket.symbol)
				});

				if (currentPosition && currentPosition.error) {
					return this.resetState();
				}
			} catch (err: any) {
				this.resetState();
				return this.error({ message: err.message });
			}

			// create the order using the sidechain smart contract
			let priceAbove = '0';
			let priceBelow = '0';
			let leverage = 1;
			let good_from = 0;
			let good_until = 0;

			this.transactionNumber += 1;
			const transactionNumber = this.transactionNumber;
			const submit_date = Date.now();
			let tx_hash = '';
			let order_id = '';


			try {
				const timeOut = setTimeout(() => {
					if (this.user?.wallet_type === 'metamask') {
						this.$buefy.toast.open({
							duration: 5000,
							message: this.$t('auth.METAMASK_SIGNATURE_ERROR_2'),
							type: 'is-warning'
						});
					}
				}, 15000);

				// if this is an open order then check the requested order token amount againt the users current sidechain token balance

				let openPositionValue = BigInt(Number(open_mph_token_amount) * 10 ** 18);

				if ((!priceAbove || priceAbove == '0') && (!priceBelow || priceBelow == '0') && (openPositionValue && openPositionValue !== BigInt('0')) && this.walletClient) {


					try {


						const sidechainTokenAddress = (this.user?.blockchain_info?.token || '').toLowerCase();

						if (!sidechainTokenAddress) {
							this.orderCreating = false;
							this.tradeProcessing = false;
							this.modalPage = 5;
							return this.error({ message: 'BALANCE_LOOKUP_ERROR' });
						}

						const tokenContract = await getContract(ESideChainTransaction.TOKEN, this.user?.blockchain_info || undefined, this.walletClient)


						let balance = (await tokenContract.contract.read.balanceOf([this.activePortfolio?.eth_address])) as bigint;

						if (Number(balance) == 0) {
							await sleep(8000);
							balance = (await tokenContract.contract.read.balanceOf([this.activePortfolio?.eth_address])) as bigint;
						}

						if (balance < openPositionValue) {
							openPositionValue = balance;
							let minValue = BigInt(1 * Math.pow(10, 18))

							if (openPositionValue < minValue) {
								this.orderCreating = false;
								this.tradeProcessing = false;
								this.modalPage = 5;
								return this.error({ message: 'NOT_ENOUGH_FUNDS' });
							}
						}
					} catch (err) {
						this.orderCreating = false;
						this.tradeProcessing = false;
						this.modalPage = 5;
						return this.error({ message: 'BALANCE_LOOKUP_ERROR' });
					}
				}

				if (this.walletClient) {

					const transaction_data = {
						market,
						close_shares_amount: String(close_shares_amount),
						open_mph_token_amount: openPositionValue.toString(),
						direction: true,
						leverage: this.roundToInteger(leverage),
						priceAbove: priceAbove,
						priceBelow: priceBelow,
						good_until: good_until,
						good_from: good_from
					};

					try {


						this.addSentryBreadcrumb(
							'order',
							JSON.stringify({
								market,
								open_mph_token_amount: openPositionValue.toString(),
								close_shares_amount,
								direction: true,
								leverage: this.roundToInteger(leverage),
								from: this.activePortfolio?.eth_address,
								gasPrice: import.meta.env.VITE_SIDECHAIN_GAS || undefined,
								priceAbove: priceAbove,
								priceBelow: priceBelow,
								good_until: good_until,
								good_from: good_from
							})
						);

						const morpherOracleContract = await getContract(ESideChainTransaction.TRADE, this.user?.blockchain_info || undefined, this.walletClient)

						type TCreateOrderParams = [`0x${string}`, bigint, bigint, boolean, bigint, bigint, bigint, bigint, bigint]

						const createOrderParams: TCreateOrderParams = [market as `0x${string}`, BigInt(close_shares_amount), BigInt(openPositionValue.toString()), true, BigInt(this.roundToInteger(leverage)), BigInt(priceAbove), BigInt(priceBelow), BigInt(good_until), BigInt(good_from)]

						let transaction_hash = await morpherOracleContract.contract.write
							.createOrder(
								createOrderParams,
								{ chain: morpherOracleContract.public_client.chain, gas: 2000000, gasPrice: import.meta.env.VITE_SIDECHAIN_GAS || undefined, account: this.activePortfolio?.eth_address || '' as TAddress }

							)
						tx_hash = transaction_hash;

						clearTimeout(timeOut);


						const receipt = await morpherOracleContract.public_client.waitForTransactionReceipt({ hash: transaction_hash })

						window.clearTimeout(timeOut);
						if (this.walletType === 'fortmatic') {
							this.setOverlayState({
								amount: String(open_mph_token_amount),
								state: 'success',
								market: selectedMarket.name
							});
							processOverlay('claim');
						}
						// load order data (after blockchain worker has been sorted) - set completed percentage and wait for order to be created in DB
						if (receipt) {
							if (
								receipt &&
								receipt.transactionHash &&
								receipt.logs
							) {
								const logs = parseEventLogs({
									abi: morpherOracleAbi,
									logs: receipt.logs,
									eventName: ['OrderCreated'],

								})

								logs.forEach((log: any) => {
									order_id = log?.args?._orderId
								})

								// wait for the order to be completed by the blockchain worker and refresh the order information
								this.waitOnOrder(order_id, receipt.transactionHash);
							}
						}
					} catch (error: any) {
						clearTimeout(timeOut);
						this.transactionErrorUpdate(error, transactionNumber, submit_date, tx_hash, order_id, transaction_data);
						this.modalPage = 5;
					}



					clearTimeout(timeOut);
				}

			} catch (err) {
				this.transactionErrorUpdate(err, transactionNumber, submit_date, tx_hash, order_id, {});
				this.modalPage = 5;
			}

		},
		async transactionErrorUpdate(err: any, transactionNumber: number, submit_date: number, tx_hash: string, order_id: string, transaction_data: any) {
			if (this.transactionError === transactionNumber) {
				return;
			}
			this.transactionError = transactionNumber;

			let walletError = '';
			let error_data = err;
			if (err.message) {
				walletError = err?.message?.toLowerCase();
			} else {
				walletError = err.toString().toLowerCase();
				error_data = err;
			}

			// If there is a logon error then log it to sentry
			if (this.walletType === 'fortmatic') await closeFortmaticOverlay();

			if (
				walletError.includes(String('Transaction was not mined within 50 blocks').toLowerCase()) &&
				this.user?.wallet_type === 'metamask'
			) {
				this.toggleResetMetamaskModal(true);
			}

			if (
				walletError.includes(String('Fortmatic RPC Error').toLowerCase()) &&
				walletError.includes(String('Missing credentials in config').toLowerCase())
			) {
				// reload if page if fortmatic has a timeout issue (this should no longer happen because we force fortmatic logout)

				window.location.reload();
				return;
			} else {
				if (
					walletError.includes(String('User denied transaction signature').toLowerCase()) ||
					walletError.includes(String('user denied transaction').toLowerCase()) ||
					walletError.includes(String('User rejected the request').toLowerCase())
				) {
					this.resetState();
					return this.error({ message: 'SIGNATURE_DENIED' });
				} else {
					//console.log('error log', { submit_date, tx_hash, order_id, transaction_data, error: walletError, transactionNumber, error_data })
					this.logSentryError('Order Transaction Failed', {
						submit_date,
						tx_hash,
						order_id,
						transaction_data,
						error: walletError,
						transactionNumber,
						error_data,
						user: this.userData
					});
					this.resetState();

					return this.error({ message: 'ORDER_TRANSACTION_ERROR' });
				}
			}
		},
		// Function which waits on Metamask to be initialized (logging in)
		// In case when user wants to create an order but he is not logged in into Metamask
		// The function is checking if "eth_address" exists -> means logged in
		// And in that case executes callback function which is passed in parameters and stops.
		async waitOnWallet(callback: any) {
			if (this.waitOnWalletInterval) {
				clearTimeout(this.waitOnWalletInterval);
				this.waitOnWalletInterval = null;
			}
			this.waitOnWalletInterval = setTimeout(async () => {
				this.waitOnWalletInterval = null;
				if (this.wrongWallet) {
					this.orderCreating = false;
					this.tradeProcessing = false;

					if (this.user?.wallet_type === 'morpherwallet') {
						await this.morpherwalletLogout();
					}

					this.$buefy.dialog.alert({
						title: this.$t('INCORRECT_ACCOUNT_HEAD'),
						message: this.$t('INCORRECT_ACCOUNT_TRADE'),
						confirmText: this.$t('OK')
					});

					return;
				}
				let loggedin = true;
				if (this.user?.wallet_type === 'morpherwallet') {
					const isLoggedIn = await this.morpherwallet.isLoggedIn();
					loggedin = isLoggedIn.isLoggedIn;
				}

				if (loggedin && this.eth_address !== '' && this.wrongNetwork === false) {
					if (this.waitOnWalletInterval) {
						clearTimeout(this.waitOnWalletInterval);
						this.waitOnWalletInterval = null;
					}
					callback();
				} else {
					this.waitOnWallet(callback)
				}
			}, 1000);
		},
		async waitOnOrder(order_id: string, transaction_hash: string) {
			this.waitOnOrderCount = 0;
			if (this.waitOnOrderInterval) {
				clearInterval(this.waitOnOrderInterval);
				this.waitOnOrderInterval = null;
			}
			if (this.waitOnWalletInterval) {
				clearTimeout(this.waitOnWalletInterval);
				this.waitOnWalletInterval = null;
			}

			this.waitOnOrderInterval = setInterval(async () => {
				this.waitOnOrderCount += 1;

				if (!this.tradeProcessing) {
					if (this.waitOnOrderInterval) {
						clearInterval(this.waitOnOrderInterval);
						this.waitOnOrderInterval = null;
					}
					if (this.waitOnWalletInterval) {
						clearTimeout(this.waitOnWalletInterval);
						this.waitOnWalletInterval = null;
					}

				} else {
					if (this.waitOnOrderCount >= 30) {
						if (this.waitOnOrderInterval) {
							clearInterval(this.waitOnOrderInterval);
							this.waitOnOrderInterval = null;
						}
						if (this.waitOnWalletInterval) {
							clearTimeout(this.waitOnWalletInterval);
							this.waitOnWalletInterval = null;
						}


						const [err, result] = await to(OrderService.getOrder({ id: order_id }));
						if (err) {
							if (
								err
									.toString()
									.toUpperCase()
									.includes('DATA_NOT_EXIST')
							) {
								this.resetState();
								this.logSentryError('Order Creation Timeout', { order_id, transaction_hash, user: this.userData });
								this.modalPage = 5;
								this.error({ message: 'ORDER_CREATION_TIMEOUT' });
								await this.getTokenUserData({
									loading: false,
									component: this
								});
								return
							} else {
								this.resetState();
								this.modalPage = 5;
								return this.error(err);
							}
						}

						if (result.data.getOrder && result.data.getOrder.status == 'success') {
							const order = result.data.getOrder;

							this.orderCompleteUpdate(order);


						} else {
							this.resetState();
							this.logSentryError('Order Creation Timeout', { order_id, transaction_hash, user: this.userData });
							this.error({ message: 'ORDER_CREATION_TIMEOUT' });
							this.modalPage = 5;
							await this.getTokenUserData({
								loading: false,
								component: this
							});
						}
					}
				}
			}, 1000);
		},
		resetState() {
			if (this.waitOnOrderInterval) {
				clearInterval(this.waitOnOrderInterval);
				this.waitOnOrderInterval = null;
			}
			if (this.waitOnWalletInterval) {
				clearTimeout(this.waitOnWalletInterval);
				this.waitOnWalletInterval = null;
			}

			this.tradeProcessing = false;
			this.orderCreating = true;


		},
		async subscribe() {
			try {
				if (this.orderSubscription !== null) {
					OrderService.unsubscribe(this.activePortfolio?.eth_address || '', this.orderSubscription);
					this.orderSubscription = null;
				}

				this.orderSubscription = OrderService.subscribeUpdateOrder(this.activePortfolio?.eth_address || '', async data => {
					try {
						if (data?.data?.updateOrder?.data.status == 'success') {

							const orderID = data.data.updateOrder.data.orderId;

							// get the last order from the DB
							const [err, result] = await to(OrderService.getOrder({ id: orderID }));
							if (err) return this.error(err);
							if (result.data.getOrder) {
								const order = result.data.getOrder;
								//commit(SUCCESS, 'POSITION_LIMITS_CANCELLED', ROOT);
								if (order.eth_address === this.activePortfolio?.eth_address) {

									// clear the order timeout interval
									if (this.waitOnOrderInterval) {
										clearInterval(this.waitOnOrderInterval);
										this.waitOnOrderInterval = null;
									}
									if (this.waitOnWalletInterval) {
										clearTimeout(this.waitOnWalletInterval);
										this.waitOnWalletInterval = null;
									}

									this.orderCompleteUpdate(order);
								}
							}
						}

					} catch (err) { }
				});
			} catch (err) {
				//this.logSentryError(err);
			}
		},
		async orderCompleteUpdate(order: any) {

			this.tradeProcessing = false;
			this.orderCreating = false;


			this.order = {
				symbol: order.market.symbol,
				amount: Number(order.open_mph_token_amount) / Math.pow(10, 18),
				price: Number(order.price) / Math.pow(10, 8),
				leverage: Number(order.leverage) / Math.pow(10, 8)
			}

			this.modalPage = 3;

			this.getTokenUserData({
				loading: false,
				component: this
			});

		},
		async processTrade() {
			try {

				if (this.tradeProcessing) {
					return;
				}

				this.tradeProcessing = true;

				this.createOrder();

			} catch (err) {
				this.tradeProcessing = false;
			}
		},
		selectMarket(id: string) {
			const markets = JSON.parse(JSON.stringify(this.watchlist_markets));
			const index = markets.findIndex((mark: Market) => mark.id == id);
			if (index > -1) {
				markets[index].selected = !markets[index].selected;

				if (markets[index].selected) {
					const ind = this.watchlist?.indexOf(id);
					if (ind < 0) {
						this.watchlist?.push(id);
					}
				} else {
					const ind = this.watchlist?.indexOf(id);
					if (ind > -1) {
						this.watchlist?.splice(ind, 1);
					}
				}
			}
			this.watchlist_markets = markets;
		},
		handleScroll(event: any) {
			let el = event.currentTarget;
			const isScrolledToBottom = el.scrollHeight <= el.clientHeight + el.scrollTop;
			const isScrolledlToTop = el.scrollTop === 0;
			if (isScrolledlToTop) {
				this.scrolls.top = false;
				this.scrolls.bottom = true;
			} else if (isScrolledToBottom) {
				this.scrolls.top = true;
				this.scrolls.bottom = false;
			} else {
				this.scrolls.top = true;
				this.scrolls.bottom = true;
			}
		}
	}
});
</script>
<style scoped>
.modal-card {
	min-width: 400px;
}


.modal-title {
	font-weight: 700;
	color: #333;
	font-size: 18px;
	line-height: 24px;
}

.modal-card.terminal.onboard-card {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
	border-radius: 8px;
	height: 410px;
}

.get-mph-header {
	color: #333;
	font-size: 28px;
	font-weight: 700;
	line-height: 2.25rem;
}

.funding-animation {
	width: 200px;
	height: 200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
}

.green-subtitle {
	color: #039954;
	font-size: 1rem;
	line-height: 1.375rem;
}

.market-layer {
	position: absolute;
	width: 100%;
	height: auto;
	top: 0;
	left: 0;
	padding: 20px;
}

.market-backround {
	position: relative;
	height: 370px;
	top: -40px
}

.market-select {
	margin-top: 20px;
	align-items: center;
	justify-content: space-between;

}

.market-select-button {
	cursor: pointer;
}

.order-result {
	border-radius: 8px;
	border: 1px solid var(--border-border-light, #EAECF0);
	padding: 16px;
	margin-top: 16px;
	padding-bottom: 8px;
}

.order-result .info {
	justify-content: space-between;
	border-bottom: 1px solid #EAE9ED;
}

.order-result .info-no-border {
	justify-content: space-between;
}

.order-result .amount-head {
	color: var(--Asphalt-Grey, #686475);
	font-size: 14px;
	font-weight: 400;
}

.order-result .amount {
	color: var(--Dark-Grey, #333);
	font-size: 18px;
	font-weight: 700;
}

.order-result .data {
	color: var(--Dark-Grey, #333);
	font-size: 14px;
	font-weight: 400;
	padding-top: 8px;
	padding-bottom: 8px;
}

.order-result .buy-tag {
	padding: 2px 10px;
	border-radius: 16px;
	background: var(--surface-surface-pastel-green, #DFFBE9);

	color: var(--text-text-green, #039954);
	font-size: 12px;
	font-weight: 700;
}

@media screen and (max-width: 500px) {
	.modal-card.terminal.onboard-card {
		min-width: 100%;
		max-width: inherit;
	}
}
</style>
