<template>
	<div v-if="showBuildInfo" class="build-info">
		<div class="tooltip">
			{{ $t('BUILD_INFO') }}
			<div class="tooltiptext">
				<div class="build-info">
					<p>NODE_ENV: {{ env.NODE_ENV }}</p>
					<p>VUE_APP_MODE: {{ env.VUE_APP_MODE }}</p>
					<p>VUE_APP_REQUEST_ENDPOINT: {{ env.VUE_APP_REQUEST_ENDPOINT }}</p>
					<p>VUE_APP_SOCKET_ENDPOINT: {{ env.VUE_APP_SOCKET_ENDPOINT }}</p>
					<p>VUE_APP_ROOT_HOSTNAME: {{ env.VUE_APP_ROOT_HOSTNAME }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	data() {
		return {
			env: process.env,
			showBuildInfo: false
		};
	},
	mounted() {
		if (
			this.env.VUE_APP_MODE !== 'production' &&
			window.location.hostname !== 'www.morpher.com' &&
			window.location.hostname !== 'morpher.com'
		) {
			this.showBuildInfo = true;
		}
	}
});
</script>

<style scoped>
.build-info {
	margin-top: 20px;
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	background-color: white;
	text-align: center;
	border-radius: 6px;
	text-align: left;
	z-index: 1;
	padding: 10px;
	box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
	max-width: 90vw;
	word-break: break-word;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}
</style>
