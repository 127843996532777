/**
 * Position service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';
import type { FetchResult, Observable } from '@apollo/client/core';
import type { Position } from '../types/schema';
import getPositionValue from '@/graphql/positions/getPositionValue.query.graphql';
import getPositionSplitValue from '@/graphql/positions/getPositionSplitValue.query.graphql';
import getPositionHistory from '@/graphql/positions/getPositionHistory.query.graphql';
import type { getPositionValueVariables, getPositionValue as getPositionValueResponse } from '@/graphql/positions/types/getPositionValue';
import type { getPositionSplitValueVariables, getPositionSplitValue as getPositionSplitValueResponse } from '@/graphql/positions/types/getPositionSplitValue';

import type {
	getPositionHistoryVariables,
	getPositionHistory as getPositionHistoryResponse
} from '@/graphql/positions/types/getPositionHistory';

import resetPositionLimits from '@/graphql/positions/resetPositionLimits.mutation.graphql';
import type {
	resetPositionLimits as resetPositionLimitsMutation,
	resetPositionLimitsVariables
} from '@/graphql/positions/types/resetPositionLimits';
export interface PositionWithSubscription extends Position {
	subscription?: any;
}

const SubscriptionList: any[] = [];

export const PositionService = {
	getPositionValue(variables: getPositionValueVariables) {
		return apollo.mutate<getPositionValueResponse>({
			mutation: getPositionValue,
			variables
		});
	},
	getPositionSplitValue(variables: getPositionSplitValueVariables) {
		return apollo.mutate<getPositionSplitValueResponse>({
			mutation: getPositionSplitValue,
			variables
		});
	},		
	getPositionHistory(variables: getPositionHistoryVariables) {
		return apollo.mutate<getPositionHistoryResponse>({
			mutation: getPositionHistory,
			variables
		});
	},
	resetPositionLimits(variables: resetPositionLimitsVariables) {
		return apollo.mutate<resetPositionLimitsMutation>({
			mutation: resetPositionLimits,
			variables
		});
	},
};
