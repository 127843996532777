<template>
	<div class="field">
		<!-- <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div> -->
		<div class="control is-expanded">
			<button class="button big-outlined-button transition-faster button-spacing loginButton" @click="doLogin"
				data-cy="appleButton" :class="{ 'is-loading': loading || appleProcessing }"
				:disabled="loading || appleProcessing">
				<span class="icon img" v-if="!loading && !appleProcessing">
					<img src="@/assets/wallet/apple_logo.svg" class="buttonIcon" alt="Apple Logo" />
				</span>
				<span class="ml-5px" v-if="signIn">{{ $t('auth.SIGN_UP_APPLE') }} </span>
				<span class="ml-5px" v-else-if="unlock">{{ $t('auth.UNLOCK_APPLE') }} </span>
				<span class="ml-5px" v-else-if="update">{{ $t('auth.UPDATE_APPLE') }} </span>
				<span class="ml-5px" v-else-if="recover">{{ $t('auth.RECOVER_APPLE') }} </span>
				<span class="ml-5px" v-else-if="showText">{{ $t('auth.LOG_IN_APPLE') }} </span>
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid';
import { jwtDecode } from "jwt-decode";
import { defineComponent } from 'vue'
import AuthMixin from '@/mixins/auth.mixin';

const rawNonce = uuid();
const state = uuid();

export default defineComponent({
	name: 'LoginApple',
	props: {
		signIn: {
			type: Boolean,
			required: false,
			default: false
		},
		unlock: {
			type: Boolean,
			required: false,
			default: false
		},
		update: {
			type: Boolean,
			required: false,
			default: false
		},
		recover: {
			type: Boolean,
			required: false,
			default: false
		},
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		showText: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	mixins: [AuthMixin],
	data() {
		return {
			hasRecoveryMethod: false,
			clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
			recoveryTypeId: 6,
			appleProcessing: false
		};
	},
	async mounted() {
		try {
			let urlString = location.protocol + '//' + location.hostname
			if (urlString && urlString[urlString.length - 1] === '/') {
				urlString = urlString.substring(0, urlString.length - 1)
			}

			await window.AppleID.auth.init({
				clientId: this.clientId,
				scope: 'email',
				redirectURI: urlString,
				state: state,
				nonce: rawNonce,
				usePopup: true
			});

			//this.hasRecoveryMethod = await this.hasRecovery(this.recoveryTypeId);
		} catch (err) {
			console.log('init error', err);
		}
	},
	methods: {
		// @vuese
		// Detect if user scrolled down to transform look
		processMethod(data: any) {
			this.$emit('processMethod', data);
			return data;
		},
		async doLogin() {
			try {
				this.appleProcessing = true;
				window.AppleID.auth
					.signIn()
					.then((userData: any) => {
						this.onLogin(userData);
						this.appleProcessing = false;
					})
					.catch((err: any) => {
						this.appleProcessing = false;
						this.onError(err);
					});
			} catch (err) {
				this.appleProcessing = false;
				this.onError(err);
			}
		},
		onError(error: any) {
			this.appleProcessing = false;
			if (error && error.detail && error.detail.error && error.detail.error == 'popup_closed_by_user') {
				return;
			}
			let errorMessage = error.error || error.err || error.message || JSON.stringify(error);
			if (error && error.detail && error.detail.error && error.detail.error) {
				errorMessage = error.detail.error;
			}
			if (errorMessage == 'popup_closed_by_user' || errorMessage == 'user_trigger_new_signin_flow') {
				return;
			}

			this.logSentryError(errorMessage, {
				hasRecoveryMethod: this.hasRecoveryMethod,
				clientId: this.clientId,
				recoveryTypeId: this.recoveryTypeId
			});
			let errorText = error.error || error.err || 'Apple login Error';

			if (String(errorText.toLowerCase()).includes('script not loaded correctly')) {
				errorText = 'apple_script_blocked';
			}

			this.processMethod({
				success: false,
				error: errorText
			});
		},
		async onLogin(appleUser: any) {
			if (appleUser.authorization) appleUser = appleUser.authorization;

			const authorizationCode = appleUser.code || appleUser.authorizationCode;
			const identityToken = appleUser.id_token || appleUser.identityToken;
			const nonce = appleUser.nonce;

			const decoded: any = jwtDecode(identityToken);

			const userID = decoded.sub;
			const email = decoded.email;
			const key = this.clientId + userID;

			this.processMethod({
				success: true,
				type: 'apple',
				userID,
				key,
				token: JSON.stringify({ identityToken, authorizationCode, nonce }),
				recoveryTypeId: this.recoveryTypeId,
				email: email
			});

			return;
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.field {
	width: 100%;
}

.buttonIcon {
	height: 22px !important;
}
</style>
