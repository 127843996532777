#import "../fragments/access.fragment.graphql"

mutation updateUser($id: String!, $username: String!, $email: String!, $transactionSignature: String!) {
    updateUser(
        data: {
            id: $id
            username: $username
            email: $email
            transactionSignature: $transactionSignature
        }
    ) {
        id
        email
        username
        status
        token
        refreshToken
        role
        watchlist
        eth_address
        wallet_type
        withdrawal_blocked
        withdrawal_unblock_date
        withdrawal_block_reason
        access_level {
            ...AccessFragment
        }
        university
        new_user
        payload
        kyc_status
        airdrop_id
        tokens_claimed
        document_country
        email_list {
            subscription
        }
        push_notifications_enabled
        push_notifications_json
    }
}
