<template>
	<div class="wallet-card" :class="{ 'is-eth': mainchain, uncenter: uncenter }">
		<div class="wallet-card-content">
			<!-- Eth Logo & Chain -->
			<div class="columns is-mobile is-vcentered has-text-left">
				<!-- Ethereum Logo -->
				<div class="column is-paddingless is-narrow">
					<img src="@/assets/icons/ethereum_outlined.svg" />
				</div>
				<div class="column is-paddingless is-narrow">
					<p class="has-font-rubik chain-text">{{ mainchain ? $t('ETHEREUM_MAINCHAIN') :
						$t('MORPHER_SIDECHAIN') }}</p>
				</div>
			</div>

			<!-- Balance -->
			<div class="columns is-mobile is-vcentered">
				<div class="column is-paddingless is-narrow padded-lr">
					<p v-if="animateNumbers" class="balance-text">
						{{ animatedNumber }} <span class="notranslate" translate="no">MPH</span>
					</p>
					<p v-else class="balance-text">{{ round(balance, 2) }} <span class="notranslate"
							translate="no">MPH</span></p>
				</div>
			</div>
			<!-- Eth Address and Wallet Type Logo -->
			<div class="columns is-mobile is-vcentered wallet-card-bottom">
				<div class="column is-paddingless has-text-left">
					<b-tooltip :label="$t('COPY_ACCOUNT_NUMBER')" size="is-small" position="is-top" type="is-dark"
						animated>
						<p class="has-font-rubik address-text transition-faster" @click="copyETHAddress">
							{{ formatEthAddress(ethAddress) }}
						</p>
					</b-tooltip>
				</div>
				<div class="column is-paddingless has-text-right">
					<!-- Wallet Type Image Switch -->
					<img v-if="walletType === 'fortmatic'" src="@/assets/icons/fortmatic_icon.svg"
						class="wallet-icon" />
					<img v-if="walletType === 'portis'" src="@/assets/icons/portis_icon.svg" class="wallet-icon" />
					<img v-if="walletType === 'metamask'" src="@/assets/icons/metamask_icon.svg" class="wallet-icon" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { copyToClipboard } from '../helpers/utils';
import { gsap } from 'gsap';
// //vuex
/* @group Components_General */
/*
	<h4> <b> Wallet Card to Display Balance </b> </h4>
*/
export default defineComponent({
	name: 'WalletCard',
	props: {
		walletType: {
			type: String,
			required: true,
			default: ''
		},
		ethAddress: {
			type: String,
			required: true,
			default: ''
		},
		balance: {
			type: Number,
			required: true,
			default: 0
		},
		mainchain: {
			type: Boolean,
			required: false,
			default: false
		},
		animateNumbers: {
			type: Boolean,
			required: false,
			default: false
		},
		uncenter: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			displayedNumber: 0
		};
	},
	computed: {
		animatedNumber() {
			return this.displayedNumber.toLocaleString();
		}
	},
	mounted() {
		// If animateNumbes true, count up
		if (this.animateNumbers) {
			gsap.to(this.$data, { duration: 3, delay: 1.5, ease: 'Power1.easeOut', displayedNumber: this.balance });
		}
	},
	methods: {
		copyETHAddress() {
			copyToClipboard(this.ethAddress, this);
		}
	}
});
</script>

<style scoped>
.wallet-card {
	position: relative;
	width: 320px;
	border-radius: 13px;
	background-image: linear-gradient(134deg, #fdfbfb 0%, #ebedee 100%);
	/* box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 5px 12px 0 rgba(182, 191, 210, 0.2); */
	box-shadow: 0 6px 15px 0 rgba(182, 191, 210, 0.4), 0 2px 6px 0 rgba(0, 0, 0, 0.2);
	padding: 1.5rem 1.75rem;
	margin: 0 auto;
}

.wallet-card.uncenter {
	margin: inherit;
}

.wallet-card.is-eth {
	background-image: linear-gradient(134deg, #ffe9ff 0%, #bde3ff 100%);
	box-shadow: 0 6px 15px 0 rgba(182, 191, 210, 0.4), 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.wallet-card-content {
	background-color: transparent;
	color: #6b6b6b;
}

.chain-text {
	font-size: 0.75rem;
	margin-left: 5px;
	margin-top: -3px;
}

.balance-text {
	font-size: 1.5rem;
	color: #333333;
	margin-top: 3.75rem;
}

.address-text {
	font-size: 1.125rem;
}

.address-text:hover {
	color: #228cdb;
	cursor: pointer;
}

.address-text:active,
.address-text:focus {
	color: #333333;
}

.wallet-icon {
	height: 1.75rem;
}

.wallet-card-bottom {
	margin-top: -20px;
}
</style>
