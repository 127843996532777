<template>
	<section>
		<div v-if="showTrustPilotModal" class="corner-popup" :class="{ 'tp-embed': page == 'happy' }">
			<!-- close button-->
			<button v-show="page !== 'happy'" class="button back-btn close-button" @click="closeCancel">
				<img src="@/assets/icons/times-grey.svg" alt="Close" />
			</button>
			<!-- ask if user is happy-->
			<div v-show="page == 'ask'" class="mt-60">
				<h1 class="has-font-rubik is-size-5 has-text-centered has-text-weight-medium">
					{{ $t('trust.HAPPY_HEADER') }}
				</h1>
				<p class="has-text-darkgrey mt-1">
					{{ $t('trust.HAPPY_TEXT') }}
				</p>
				<div class="section_bottom">
					<div class="section_selection section_selection_left" @click="select('unhappy')">
						<img src="@/assets/icons/sensa_emoji_frown.svg" class="selection_image" />
						<p class="mt-5px">
							{{ $t('trust.SELECTION_UNHAPPY') }}
						</p>
					</div>
					<div class="section_selection" @click="select('happy')">
						<img src="@/assets/icons/sensa_emoji_hearts.svg" class="selection_image" />
						<p class="mt-5px">
							{{ $t('trust.SELECTION_HAPPY') }}
						</p>
					</div>
				</div>
			</div>
			<!-- user happy - ask for trustpilot review-->
			<div v-show="page == 'happy'">
				<!-- No Thanks Link -->
				<p class="mt-2">
					<a @click="noReview()" class="is-size-14 transition-faster">{{ $t('trust.FINISHED') }}</a>
				</p>

				<!-- Embedded iFrame -->
				<div class="mt-2">
					<!-- If Russian, load Otzovik -->
					<iframe v-if="$i18n.locale === 'ru'" class="tp-container"
						src="https://otzovik.com/postreview.php?pid=2392421" />
					<!-- If German, load de.trustpilot -->
					<iframe v-else-if="$i18n.locale === 'de'" class="tp-container"
						src="https://de.trustpilot.com/evaluate/embed/morpher.com" />
					<iframe v-else class="tp-container" src="https://www.trustpilot.com/evaluate/embed/morpher.com" />
				</div>
			</div>
			<!-- user unhappy - ask for support feedback-->
			<div v-show="page == 'unhappy'">
				<div class="mt-30 p-5">
					<p class="has-text-weight-medium">{{ $t('trust.UNHAPPY_TEXT') }}</p>
					<p class="is-size-14 is-line-height-20 has-text-darkgrey">
						{{ $t('trust.UNHAPPY_REVIEW') }}
					</p>
				</div>

				<div class="is-flex is-flex-direction-column pr-5 pl-5">
					<button class="button flat-white-btn" @click="openLink('mailto:contact@help.morpher.com')">
						📧 {{ $t('CONTACT_SUPPORT') }}
					</button>
					<button class="button flat-white-btn mt-15"
						@click="openTypeformLink('https://morpher.typeform.com/to/pKAAh43w')">
						💬 {{ $t('trust.LEAVE_FEEDBACK') }}
					</button>
					<button class="button flat-white-btn mt-15" @click="closeCancel">
						{{ $t('CLOSE_DIALOG') }}
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { UserService } from '@/services/UserService';

export default defineComponent({
	name: 'TrustPilotModal',
	data() {
		return {
			page: 'ask',
			clicked_review: false,
			iframeInterval: null as null | NodeJS.Timeout | number,
			listener: null as any
		};
	},
	computed: {
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useModalsStore, {
			showTrustPilotModal: (state) => state.showTrustPilotModal,
		}),

	},
	watch: {
		user() {
			this.initialize();
		}
	},
	mounted() {
		this.initialize();

		focus();
		this.listener = window.addEventListener('blur', () => {
			if (document.activeElement === document.querySelector('iframe')) {
				window.removeEventListener('blur', this.listener);
				this.review();
			}

		});
	},
	unmounted() {
		if (this.iframeInterval) clearInterval(this.iframeInterval);
		window.removeEventListener('blur', this.listener);
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleTrustPilotModal: 'toggleTrustPilotModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),
		...mapActions(useMarketsStore, {
			getMPHMarket: 'getMPHMarket',
		}),

		close() {
			this.toggleTrustPilotModal(false);
		},
		async closeCancel() {
			if (this.page == 'ask') {
				await UserService.reviewResponse({ request_response: 'cancel', review_response: '' });
				UserService.sendAnalytics({
					event: 'app_review',
					data: {
						review: 'ignored'
					}
				});
			}
			this.close();
		},
		async review() {
			UserService.sendAnalytics({
				event: 'app_review',
				data: {
					review: 'completed'
				}
			});
			await UserService.reviewResponse({ request_response: this.page, review_response: 'Trustplot Started' });
			this.clicked_review = true;
			// log the review selection
			// window.open('https://www.trustpilot.com/evaluate/morpher.com?utm_medium=trustbox&utm_source=TrustBoxReviewCollector', '_blank');
			// this.close();
		},
		async noReview() {
			if (!this.clicked_review) {
				await UserService.reviewResponse({ request_response: this.page, review_response: 'No Review' });
			}
			// log the no review selection
			this.close();
		},
		async select(page: string) {
			UserService.sendAnalytics({
				event: 'app_review',
				data: {
					review: page
				}
			});
			await UserService.reviewResponse({ request_response: page, review_response: '' });
			this.page = page;
		},
		async initialize() {
			// load the trust pilot SDK
			// if (!document.getElementById('script_trust_pilot')) {
			// 	const matiScript = document.createElement('script');
			// 	matiScript.setAttribute('src', 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
			// 	matiScript.setAttribute('id', 'script_trust_pilot');
			// 	document.head.appendChild(matiScript);
			// }
		},
		openLink(url: string) {
			window.open(url, '_blank');
		},
		openTypeformLink(url: string) {
			const eth = '#eth_address=' + this.user?.eth_address;
			const country = '&ip_country=' + this.user?.ip_country;
			const tier = '&tier=' + this.user?.access_level?.tier;
			this.openLink(url + eth + country + tier);
		}
	}
});
</script>
<style scoped>
.corner-popup {
	bottom: 1rem;
	right: 1rem;
	position: fixed;
	z-index: 10001;
	min-height: 320px;
	min-width: 360px;
	max-width: 360px;
	border-radius: 13px;
	background-color: #fff;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px -4px 6px -2px rgba(16, 24, 40, 0.03);
	border: 1px solid #eae9ed;
	overflow-y: auto;
}

.section_bottom {
	border-top: 1px solid #dedede;
	position: absolute;
	bottom: 0;
	display: flex;
	width: 100%;
	border-radius: 0 0 13px 13px;
}

.section_selection_left {
	border-right: 1px solid #dedede;
}

.section_selection {
	width: 50%;
	cursor: pointer;
	padding-top: 30px;
	padding-bottom: 30px;
	transition: background-color 0.25s;
}

.section_selection:hover {
	background-color: #f5f5f5;
	transition: background-color 0.25s;
	border-radius: 0 0 13px 13px;
	cursor: pointer;
}

.popup_close_button {
	position: absolute;
	font-size: 32px;
	line-height: 24px;
	width: 24px;
	height: 24px;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	color: #000;
	top: 0.5rem;
	right: 0.5rem;
	z-index: 1;
	display: block !important;
}

.close-button {
	position: absolute;
	right: 1rem;
	top: 1rem;
}

.selection_image {
	width: 50px;
	height: 50px;
}

.star_image {
	width: 20px;
	height: 20px;
	margin-left: 2px;
	margin-right: 2px;
}

.trust-button-container {
	background-color: transparent;
	z-index: 50;
	cursor: pointer;
	width: 100%;
}

.trust-button {
	z-index: -1;
}

.tp-container {
	height: 600px;
	width: 100%;
}

.corner-popup.tp-embed {
	overflow-y: hidden;
}

@media screen and (max-width: 768px) {
	.corner-popup {
		bottom: 0;
		right: inherit;
		width: 100%;
		min-width: inherit;
		max-width: inherit;
		border-radius: 13px 13px 0 0;
	}

	.corner-popup.tp-embed {
		height: 90vh;
		width: 100%;
	}
}
</style>
