<template>
	<span class="has-font-manrope">
		<SliderRight :active="active">
			<nav class="mobile-menu">
				<!-- Wallet Info -->

				<div class="balance-and-icons">
					<div class="icons-and-x">
						<div v-if="activePortfolio">
							<div v-if="unlockWallet" class="unlock-button" @click="walletUnlock">
								<img src="@/assets/icons/new/unlock.svg" :alt="$t('UNLOCK_WALLET')" />
								<p>
									{{ $t('UNLOCK_WALLET') }}
								</p>
							</div>
							<div v-else class="blockie-username">
								<div class="mr-2">
									<Blockie slot="trigger" role="button" :src="activePortfolio.blockie || ''" />
								</div>
								<div class="username-tier">
									<!-- Username and Email and Tier -->
									<div aria-role="menuitem" class="non-hover-item">
										<p class="username-text">{{ user?.username }}</p>

										<div v-if="(user?.access_level?.tier || 0) < 3" class="account-level">
											<img src="@/assets/icons/new/tier-novice.svg" />
											<p>{{ $t('TIER_NOVICE') }}</p>
										</div>
										<div v-else-if="(user?.access_level?.tier || 0) === 3" class="account-level">
											<img src="@/assets/icons/new/tier-investor.svg" />
											<p>{{ $t('TIER_INVESTOR') }}</p>
										</div>
										<div v-else-if="(user?.access_level?.tier || 0) === 4" class="account-level">
											<img src="@/assets/icons/new/tier-mogul.svg" />
											<p>{{ $t('TIER_MOGUL') }}</p>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div class="close-icon is-clickable" @click="close">
						<img src="@/assets/icons/close-icon.svg" />
					</div>
				</div>


				<!-- Get $100+ Golden Tag -->
				<!-- <div class="tag-centered mt-5px is-clickable" @click="sendToRewards()" v-if="!noBalance">
					<span class="tag is-rainbow-promo has-text-weight-medium has-font-rubik is-size-6 has-text-white">
						{{ $t('APY_11') }}
					</span>
				</div> -->

				<!-- Navigation -->
				<NavigationLinksMobile class="links-menu" @linkclick="close" />

				<!-- Logout -->
				<!-- <a class="regular-link navigation-item navigation-link transition-faster" @click="logout">{{ $t('LOGOUT') }}</a> -->
			</nav>
		</SliderRight>
		<Dimmer :active="active" @click.native="close" />
	</span>
</template>

<script lang="ts">
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { defineComponent } from 'vue'
import NavigationLinksMobile from '@/components/NavigationLinksMobile.vue';
import SliderRight from '@/components/SliderRight.vue';
import WalletInfo from '@/components/WalletInfo.vue';
import Dimmer from './Dimmer.vue';
import Blockie from '@/components/Blockie.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import { MarketService } from '../services/MarketService';
//vuex
import { UserService } from '../services/UserService';
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';

export default defineComponent({
	components: {
		NavigationLinksMobile,
		SliderRight,
		Blockie,
		WalletInfo,
		Dimmer,
		LanguageSelector
	},
	props: {
		active: Boolean
	},
	data() {
		return {
			currencySelector: false,
			currentView: 'MPH',
			newsSubscription: null,
			aiInsightsOn: false,
			portfolioItem: 0,
			unlockWallet: false
		};
	},
	computed: {
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
			isLoggedInWallet: (state) => state.isLoggedInWallet,
		}),
		...mapState(useMarketsStore, {
			aiInsights: (state) => state.aiInsights,
			mph_market: (state) => state.mph_market,
		}),
		...mapState(useUserStore, {
			activePortfolio: (state) => state.activePortfolio,
			user: (state) => state.data,
			payload: (state) => state?.data?.payload,
			impersonating: (state) => state.impersonating,
			valueSum: (state) => state.valueSum,

		}),


		pending() {
			return this.activePortfolio ? Number(this.activePortfolio.pending) : 0;
		},
		noBalance() {
			if (this.activePortfolio?.cash_balance == 0 && this.activePortfolio.current_value == 0) {
				return true;
			} else {
				return false;
			}
		}
	},
	watch: {
		payload(payloadValue) {
			const self = this;
			if (self.currentView !== (payloadValue.currency_view || 'MPH')) {
				self.currentView = payloadValue.currency_view || 'MPH';
				self.currencySelector = this.currentView === 'USD';
				if (self.currentView === 'USD' && !self.mph_market) {
					self.getMPHMarket({
						component: this
					});
				}
			}
		},

		isLoggedInWallet() {
			this.checkWalletLocked();
		},
	},

	async mounted() {

		const self = this;
		this.currentView = self.payload.currency_view || 'MPH';
		this.currencySelector = self.currentView === 'USD';
		if (this.currentView === 'USD' && !self.mph_market) {
			this.getMPHMarket({
				component: this
			});
		}
		this.checkWalletLocked();
	},
	unmounted() {

	},

	methods: {
		close() {
			this.$emit('close');
		},
		...mapActions(useUserStore, {
			logout: 'logout',
			updateUserPayload: 'updateUserPayload',
		}),
		...mapActions(useMarketsStore, {
			getMPHMarket: 'getMPHMarket',
			getAIMarketInsights: 'getAIMarketInsights',
		}),
		...mapActions(useModalsStore, {
			toggleAIInsightsModal: 'toggleAIInsightsModal',
			toggleUnlockWalletModal: 'toggleUnlockWalletModal',
		}),
		...mapActions(useContractStore, {
			startWallet: 'startWallet',
		}),


		...mapActions(useMarketsStore, {
			newAIMarketInsight: 'newAIMarketInsight'
		}),
		localeUpdated(locale: any) {
			setTimeout(() => {
				this.getAIMarketInsights({ component: this });
			}, 1000);
		},
		walletUnlock() {
			this.toggleUnlockWalletModal(true)
		},

		checkWalletLocked() {
			if (this.walletType == 'morpherwallet') {
				if (this.isLoggedInWallet) {
					this.unlockWallet = false
				} else {
					this.unlockWallet = true
				}

			} else {
				this.unlockWallet = false
			}
		},


		changeCurrency() {
			const self = this;
			if (self.currencySelector) {
				self.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'USD' });
				self.currentView = 'USD';
			} else {
				self.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'MPH' });
				self.currentView = 'MPH';
			}
			self.currencySelector = self.currentView === 'USD';

			if (this.currentView === 'USD' && !self.mph_market) {
				self.getMPHMarket({
					component: this
				});
			}
		},

		sendToRewards() {
			try {
				UserService.sendAnalytics({ event: 'nav_rewards_click', data: {} });
			} catch (err) { }

			this.$router.push('/rewards').catch(() => { });
			this.close();
		},
		openVerify() {
			if (this.user?.kyc_status !== 'verified') {
				this.$router.push('/settings').catch(() => { });
				this.close();
			} else {
				this.$router.push('/funds/deposit').catch(() => { });
				this.close();
			}
		}
	}
});
</script>

<style scoped>
.mobile-menu {
	width: 100%;
}

.mobile-wallet-info-container {
	padding-top: 8px;
}

.wallet-info-container {
	height: 48px;
}

.mode-tag {
	background-color: inherit;
	border: 1.25px solid #808080;
	border-radius: 5px;
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.highlighted-mini {
	margin-top: -6px;

	color: var(--text-text-primary, #333);

	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
	text-align: left;
}

.divider {
	display: block;
	position: relative;
	border-top: 1px solid #dbdbdb;
	height: 1px;
	margin: 4px;
	text-align: center;
}

.equity-switch {
	display: block;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 3px;
}

.currency-selector-label {
	color: #4c4c4c;
}

.equity-switch .switch {
	margin-left: 5px;
	margin-right: 0;
}

.equity-switch .help-icon {
	margin-left: 5px;
	color: #ababac;
	margin-top: 2px;
}

.is-clickable {
	cursor: pointer;
}

.icon.switcher-icon {
	color: #ababac;
	align-self: center;
	font-size: 24px;
	margin-left: 2px;
}

.right-offset {
	display: flex;
	justify-content: flex-end;
	margin-right: 20px;
}

.tag-centered {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 20px;
}

.tag.is-gold {
	background: linear-gradient(134deg, #ffd194 0%, #d8a159 100%);
	color: #fff;
	box-shadow: 0 2px 3px 0 rgba(102, 190, 255, 0.05), 0 3px 10px 0 rgba(0, 0, 0, 0.08);
	border-radius: 13px;
}

.tag.is-rainbow-promo {
	background-image: linear-gradient(134deg, #ffa3ff 0%, #34aaff 100%);
	box-shadow: 0 2px 3px 0 rgba(102, 190, 255, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.1);
	border-radius: 13px;
}

.lang-frame {
	padding: 10px;
	background: #fff;
	width: 100%;
	display: flex;
	align-items: flex-end;
}

.lang-frame .language-selector {
	margin: 0;
	margin-left: auto;
}

.verify-button {
	background: #1a6df0;
	border-radius: 8px;
	font-weight: 700;
	font-size: 14px;
	color: #ffffff;
	font-family: 'Manrope';
	padding: 8px 1px;
	margin: 10px;
	cursor: pointer;
}

.balance-and-icons {
	display: flex;
	justify-content: space-between;
	padding: 32px 16px 16px 16px;
}

.icons-and-x {
	display: flex;
	gap: 24px;
	align-items: center;
}

.number-headers {
	color: var(--text-text-secondary, #686475);

	/* Small Text */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
	text-align: right;
}

.balance-and-icons {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: white;
	z-index: 101;
	/* Above the scrollable content */
	padding: 16px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.links-menu {
	margin-top: 80px;
}


.blockie-username {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.username-text {
	white-space: initial;
	word-wrap: break-word;
	width: 170px;

	color: var(--Dark-Grey, #333);

	/* Small Text Bold */
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
	text-align: left;
}

.account-level {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	color: var(--Asphalt-Grey, #686475);

	/* Small Text */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
}

.account-level img {
	height: 14px;
}

.close-icon {
	align-items: center;
	display: flex;
}


.unlock-button {
	display: flex;
	height: 44px;
	padding: 12px 10px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	background: #FFF;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	color: #686475;
	width: 200px;


}

.unlock-button:hover {
	color: #333;

	border: 1px solid #686475;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	cursor: pointer;
}
</style>