<template>
	<section>
		<b-modal :modelValue="showBraveAlertsModal" has-modal-card @close="toggleBraveAlertsModal(false)">
			<div class="modal-card terminal">
				<div class="terminal-content">
					<h1 class="has-font-rubik is-size-5 has-text-centered has-text-weight-medium mb-10">
						{{ $t('NOTIFICATION_SETTINGS_BRAVE') }}
					</h1>
					<img src="@/assets/icons/brave_logo.png" class="brave-logo" alt="Brave browser logo" />

					<div class="has-text-left is-size-14">
						<p class="is-size-14">
							{{ $t('NOTIFICATION_SETTINGS_BRAVE_INTRODUCTION') }}
						</p>

						<ol class="mt-2 ml-3">
							<li>
								<p>{{ $t('NOTIFICATION_SETTINGS_BRAVE_SETTINGS1') }}</p>
								<input v-model="settings_route" class="input is-small is-size-14" type="url" readonly />
							</li>
							<li class="mt-1">{{ $t('NOTIFICATION_SETTINGS_BRAVE_SETTINGS2') }}</li>
							<li class="mt-1">{{ $t('NOTIFICATION_SETTINGS_BRAVE_CONCLUSION') }}</li>
						</ol>
					</div>
					<button class="button big-button is-blue transition-fast mt-20"
						@click="toggleBraveAlertsModal(false)">
						{{ $t('CLOSE_DIALOG') }}
					</button>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNotificationsStore } from '@/store/modules/notifications';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { mapActions, mapState } from 'pinia';
import { ERROR, SUCCESS } from '@/store/mutation-types';
import Spinner from '../Spinner.vue';
import { copyToClipboard } from '@/helpers/utils';
import { useStatusStore } from '@/store/modules/status';

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'BraveAlertsModal',
	components: {
		Spinner
	},
	data() {
		return {
			notification_movement_percent: 0.05,
			page: 0,
			settings_route: 'brave://settings/privacy'
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showBraveAlertsModal: (state) => state.showBraveAlertsModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useNotificationsStore, {
			notifications: (state) => state,
		}),

	},
	watch: {
		showBraveAlertsModal(nv) {
			if (nv) {
				this.notification_movement_percent = this.user?.payload?.notification_movement_percent || 0.05;
				this.page = 0;
			}
		}
	},
	mounted() {
		this.notification_movement_percent = this.user?.payload?.notification_movement_percent || 0.05;
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
			success: SUCCESS

		}),

		...mapActions(useModalsStore, {
			toggleBraveAlertsModal: 'toggleBraveAlertsModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),
		...mapActions(useNotificationsStore, {
			getNotificationToken: 'getNotificationToken',
		}),

		copyLink() {
			copyToClipboard(this.settings_route, this);
		}
	}
});
</script>
<style scoped>
.brave-logo {
	max-height: 64px;
}
</style>
