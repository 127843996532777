mutation updateNotificationTemplate($id: String!, $data: NotificationTemplateInput!) {
    updateNotificationTemplate(id: $id, data: $data) {
        id
        title
        description
        filter
        timestamp
        status
    }
}
