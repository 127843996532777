<template>
	<section>
		<div v-if="showTier4Modal" class="notification-modal has-font-manrope">
			<div class="notification notification-message is-top-right" indefinite="true">
				<button class="button back-btn hover-position" @click="closeModal()">
					<img src="@/assets/icons/close-notification-icon.svg" alt="Close" />
				</button>

				<div class="is-flex is-align-items-flex-start">
					<img class="is-64" src="@/assets/img/tier3_notification.png" alt="Luxury watches on Morpher" />
					<div class="has-text-left is-size-14 is-line-height-20 ml-3">
						<p class="has-text-weight-bold">{{ $t('notification.TIER_MOGUL_HEADER') }}</p>
						<p class="mt-1">{{ $t('notification.TIER_MOGUL_TEXT') }}</p>
						<div class="is-inline-flex has-text-weight-bold is-size-7 mt-1">
							<a class="transition-faster" @click="openPage('/settings')">{{ $t('LEARN_MORE') }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAdminStore } from '@/store/modules/admin';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'Tier4Modal',
	data() {
		return {};
	},
	computed: {
		...mapState(useModalsStore, {
			showTier4Modal: (state) => state.showTier4Modal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),
		...mapState(useAdminStore, {
			config: (state) => state.config,
		}),

	},
	mounted() {
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleTier4Modal: 'toggleTier4Modal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),

		openPage(url: string) {
			this.$router.push(url).catch(() => { });
			this.closeModal();
		},
		closeModal() {
			this.updateUserPayload({ payloadOption: 'notification_tier4', payloadValue: Date.now().toString() });
			this.toggleTier4Modal(false);

		}
	}
});
</script>
<style scoped>
.button.back-btn {
	padding: 0.25rem;
	height: auto;
	border-radius: 100%;
	border: none;
	background: none;
	transition: background-color 0.25s;
}

.button.back-btn:hover {
	background: #f5f5f5;
	transition: background-color 0.25s;
}

.notification-message a {
	color: #1a6df0 !important;
	text-decoration: none !important;
}

.notification-message a:hover {
	color: #333 !important;
	text-decoration: none !important;
}

.notification-message {
	background: #fff;
	border-radius: 8px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
	padding: 20px 16px;
}

.notification-modal {
	display: block;
	position: fixed;
	top: 50px;
	z-index: 19;
}

.notification-modal .is-top-right {
	margin-top: 40px;
}

.is-64 {
	width: 64px;
	height: 64px;
	min-width: 64px;
}

.hover-position {
	position: absolute;
	left: auto;
	right: 0.5rem;
	top: 0.5rem;
}

@media screen and (min-width: 768px) {
	.notification-modal {
		max-width: 300px;
		right: 20px;
	}
}

@media screen and (max-width: 768px) {
	.notification-modal {
		right: 0px;
		width: 100%;
		margin-top: 6px;
	}

	.modal-card {
		min-width: inherit;
	}

	.notification-modal .is-top-right {
		margin-top: 2px;
	}
}
</style>
