<template>
	<div>
		<div class="deposit-modal-spacing has-text-left">
			<!-- Page Headings -->
			<div v-if="process_status === 'processing'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_PROCESSING') }}
			</div>
			<div v-else-if="process_status === 'success'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_SUCCESS') }}
			</div>
			<div v-else-if="process_status === 'error'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_ERROR') }}
			</div>

			<div v-else-if="process_status === 'preview'" class="header-bar-global">
				<button class="button back-btn" @click="closePayment()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.DEPOSIT_PREVIEW') }}
			</div>
			<div v-else class="header-bar-global">
				<button class="button back-btn" @click="openPreview()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.DEPOSIT') + ' ' + currencySymbol + ' ' + (funds.currency || 'USD').toUpperCase() }}
			</div>

			<!-- Deposit Preview - Confirm deposit details and accept lockup -->
			<div v-if="process_status === 'preview' || process_status === 'new'" class="payment-flow_box mt-15">
				<div class="transaction_details_section">
					<div class="transaction_header is-justify-content-space-between"
						:class="{ no_border: !showDetails }">
						<div class="icon_header">
							<img src="@/assets/funds/paypal.svg" class="transaction_icon" alt="payment provider" />
							{{ currencySymbol }}{{ formatAmount(Number(purchase_total)) }}
						</div>
						<a class="dark-link is-size-14 has-text-weight-medium transition-faster"
							@click="showDetails = !showDetails">
							{{ showDetails ? $t('funds.HIDE_DETAILS') : $t('funds.SHOW_DETAILS') }}
						</a>
					</div>

					<div class="transaction_details_lines" v-if="showDetails">
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MPH_CONVERSION') }}
							</p>
							<p>{{ roundFormatter((Number(purchase_cost) * currencyExchange) / Number(purchase_price)) }}
								MPH</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.PRICE') }}
							</p>
							<p>{{ currencySymbol }}{{ roundFormatter(Number(purchase_price) / currencyExchange) }}/MPH
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.PAYPAL_FEES') }}
							</p>
							<p>{{ currencySymbol }}{{ usdFormatter(purchase_fees) }}</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.TOTAL_VALUE') }}
							</p>
							<p>{{ currencySymbol }}{{ usdFormatter(purchase_cost) }}</p>
						</div>
						<div class="transaction_details_line">
							<p class="provider-bonus">
								{{ $t('funds.BONUS_PERCENT') }}
							</p>
							<div>
								<p>+{{ roundFormatter((Number(purchase_cost) * currencyExchange) /
									Number(purchase_price) / 10) }} MPH
								</p>
								<p class="transaction_details_info_extra">+{{ currencySymbol }}{{
									usdFormatter(Number(purchase_cost) / 10) }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="process_status === 'preview'" class="payment-flow_box mt-15">
				<div class="payment_provider_section">
					<div class="lock_image_section">
						<img src="@/assets/funds/lock.svg" class="lock_section_image" alt="payment provider" />
					</div>
					<div class="lock_body_section">
						<div class="provider_header">
							{{ $t('funds.WITHDRAWAL_LOCK_HEADING') }}
						</div>
						<div class="provider_learn_more">
							{{ $t('funds.WITHDRAWAL_LOCK') }}
						</div>
					</div>
				</div>

				<div class="withdrawal_lock_section mt-15">
					{{ $t('funds.WITHDRAWAL_LOCK_UNTIL', { date: withdrawalUnlockDate() }) }}
					<span class="font_normal">{{ $t('funds.WITHDRAWAL_LOCK_LEARN') }}
						<a target="_blank" class="transition-faster" :href="supportArticleLink('DepositPaypal')">{{
							$t('funds.HERE') }}</a></span>
				</div>

				<button data-cy="withdrawFunds" class="button flat-primary-btn xlarge is-fullwidth mt-20"
					@click="startPayment()">
					{{ $t('CONTINUE') }}
				</button>
			</div>
			<!-- New payment - show payment details and paypal payment SDK-->
			<div v-else-if="process_status === 'new'" class="payment-flow_box mt-15" style="max-width: 500px">
				<!-- PayPal -->
				<div id="paypal-button-container" style="position: relative; z-index: 1" />
			</div>

			<!-- payment processing - after submit to paypal -->
			<div v-else-if="process_status === 'processing'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/processing.svg" alt="pending icon" />
				</div>

				<div class="payment_provider_section">
					<img src="@/assets/funds/paypal.svg" class="transaction_icon" alt="payment provider" />

					<div class="provider_text">
						<div class="provider_header">
							{{ $t('funds.PAYPAL') }}
						</div>
						<p class="is-size-14">{{ $t('funds.TRANSACTION_PROCESSING') }} {{ $t('funds.CLOSE_WINDOW') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment success - after payment confirm and deposit by morpher -->
			<div v-else-if="process_status === 'success'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/success.svg" alt="Success checkmark image" />
				</div>

				<div class="transaction_details_section" v-if="transaction_data && transaction_data.payload">
					<div class="transaction_header">
						<img src="@/assets/funds/paypal.svg" class="transaction_icon" alt="payment provider" />
						{{ getCurrencySymbol(transaction_data.payload.source_currency)
						}}{{ usdFormatter(transaction_data.payload.purchase_total_usd) }}
					</div>

					<div class="transaction_details_lines">
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MPH_CONVERSION') }}
							</p>
							<p>{{ roundFormatter(transaction_data.payload.purchase_amount_mph) }} MPH</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.PRICE') }}
							</p>
							<p>
								{{ getCurrencySymbol(transaction_data.payload.source_currency)
								}}{{
									roundFormatter(transaction_data.payload.purchase_price /
										transaction_data.payload.purchase_exchange)
								}}/MPH
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.PAYPAL_FEES') }}
							</p>
							<p>
								{{ getCurrencySymbol(transaction_data.payload.source_currency)
								}}{{ usdFormatter(transaction_data.payload.purchase_fees_usd) }}
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.TOTAL_VALUE') }}
							</p>
							<p>
								{{ getCurrencySymbol(transaction_data.payload.source_currency)
								}}{{ usdFormatter(transaction_data.payload.purchase_total) }}
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="provider-bonus">
								{{ $t('funds.BONUS_PERCENT') }}
							</p>
							<div>
								<p>+{{ roundFormatter(transaction_data.payload.purchase_amount_mph_bonus) }} MPH</p>
								<p class="transaction_details_info_extra">
									+{{ currencySymbol }}{{ usdFormatter(transaction_data.payload.purchase_cost_usd /
										10) }}
								</p>
							</div>
						</div>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment error -->
			<div v-else-if="process_status === 'error'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/error.svg" alt="payment error" />
				</div>

				<div class="payment_provider_section mt-15 mb-15">
					<div class="provider_text">
						<p class="is-size-14">
							{{ $t('funds.ERROR_TEXT') }} <br />
							{{ $t('funds.ERROR_TRY_AGAIN') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.LEARN_MORE_DEPOSITS') }}
							<a target="_blank" :href="supportArticleLink('DepositMethod')">{{
								$t('funds.HERE')
							}}</a>
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="closePayment()">
					{{ $t('BACK') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TransactionService } from '@/services/TransactionService';
import { PortfolioService } from '@/services/PortfolioService';
import PaymentMixin from '@/mixins/payment.mixin';
import { UserService } from '@/services/UserService';

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'PaymentTypePaypal',
	mixins: [PaymentMixin],
	components: {},
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		maxLeft: {
			type: Number,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			paypal_loaded: false,
			showDetails: true
		};
	},
	watch: {
		process_status(nv) {
			if (nv && nv !== 'new') {
				// remove the paypal buttons if this is not
				if (document.getElementById('paypal-button-container')) {
					const buttons = document.getElementById('paypal-button-container');
					if (buttons?.innerHTML) {
						buttons.innerHTML = '';
					}
				}
			}
		}
	},

	async mounted() {
		this.process_status = 'preview';
		this.purchase_price = String(this.balance.mph_rate);
		this.purchase_total = String(this.funds.amount);

		// calculate the fees
		let fees = Number(this.purchase_total) * 0.039 + 0.3;

		fees = Math.round(fees * 100) / 100;
		const purchase_cost = Number(this.purchase_total) - fees;

		this.purchase_fees = String(fees);
		this.purchase_cost = String(purchase_cost);

		this.purchase_amount_mph = String((Number(purchase_cost) * Number(this.currencyExchange || 1)) / Number(this.balance.mph_rate));

		//this.initializePaypalSDK();
	},
	methods: {
		openPreview() {
			this.process_status = 'preview';
		},
		startPayment() {
			this.showDetails = false;

			this.process_status = 'new';
			this.initializePaypalSDK();
		},
		// Switch to default payment for user to avoid Paypal lockup
		// defaultPaymentMethod() {
		// 	this.updateFunds({ provider: this.funds.default_provider, showPaymentType: this.funds.default_provider });
		// },
		// Calculate Withdrawal Date
		withdrawalUnlockDate() {
			const unlockDate = new Date();
			unlockDate.setDate(unlockDate.getDate() + 185);
			const day = unlockDate.getDate();
			const months = [
				this.$t('months.JANUARY'),
				this.$t('months.FEBRUARY'),
				this.$t('months.MARCH'),
				this.$t('months.APRIL'),
				this.$t('months.MAY'),
				this.$t('months.JUNE'),
				this.$t('months.JULY'),
				this.$t('months.AUGUST'),
				this.$t('months.SEPTEMBER'),
				this.$t('months.OCTOBER'),
				this.$t('months.NOVEMBER'),
				this.$t('months.DECEMBER')
			];
			const month = months[unlockDate.getMonth()];
			const year = unlockDate.getFullYear();
			return month + ' ' + day + ', ' + year;
		},
		async loadPaypalButtons() {
			if (this.paypal_loaded) {
				return;
			}
			try {
				if (!document.getElementById('paypal-button-container')) {
					window.setTimeout(this.loadPaypalButtons, 1000);
					return;
				}
				const vue = this;
				if (document.getElementById('paypal-button-container')?.innerHTML) {
					return;
				}
				// Render the PayPal button into #paypal-button-container
				window.paypal
					.Buttons({
						async createOrder(data: any, actions: any) {
							UserService.sendAnalytics({
								event: 'paypal_events',
								data: {
									event: 'started',
									payment_source: data.paymentSource
								}
							});
							try {
								if (vue.order_id) {
									vue.purchase_loading = true;
									// update the backend with the new user balances.
									const result = await TransactionService.purchasePaypalOrderCreate({
										data: {
											eth_address: vue.user?.activePortfolio?.eth_address || '',
											order_id: vue.order_id,
											purchase_price: vue.purchase_price.toString(),
											purchase_cost_usd: vue.purchase_cost.toString(),
											purchase_fees_usd: vue.purchase_fees.toString(),
											purchase_total_usd: vue.purchase_total.toString(),
											purchase_amount_mph: vue.purchase_amount_mph.toString(),
											transaction_id: vue.transaction_id,
											purchase_currency: (vue.funds.currency || 'USD').toUpperCase(),
											payment_source: data.paymentSource
										}
									});

									if (result && result.data?.purchasePaypalOrderCreate) {
										if (
											result.data.purchasePaypalOrderCreate.payload &&
											result.data.purchasePaypalOrderCreate.payload.result === 'error'
										) {
											TransactionService.purchasePaypalOrderCaptureError({
												data: {
													provider: 'paypal',
													total_amount_usd: vue.purchase_total,
													total_amount_mph: vue.purchase_amount_mph,
													country: vue.user?.document_country,
													error_details:
														'Paypal Purchase Order Create Error - ' +
														result.data.purchasePaypalOrderCreate.payload.message,
													eth_address: vue.user?.activePortfolio?.eth_address,
													purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
												}
											});
											vue.logSentryError(
												'Paypal Purchase Order Create Error - ' +
												result.data.purchasePaypalOrderCreate.payload.message
											);
											vue.$buefy.dialog.alert({
												title: vue.$t('PAYMENT_CREATION_ERROR'),
												message:
													result.data.purchasePaypalOrderCreate.payload.message ||
													vue.$t('PAYMENT_CREATION_ERROR_MESSAGE'),
												confirmText: vue.$t('OK')
											});
											vue.purchase_loading = false;

											return;
										}
										vue.transaction_id = result.data.purchasePaypalOrderCreate.id;
										vue.order_id = result.data.purchasePaypalOrderCreate.payload.order_id;
										vue.purchase_loading = false;
										return result.data.purchasePaypalOrderCreate.payload.order_id;
									}
									vue.purchase_loading = false;
									return vue.order_id;
								}
								vue.purchase_loading = true;
								// update the backend with the new user balances.
								const result = await TransactionService.purchasePaypalOrderCreate({
									data: {
										eth_address: vue.user?.activePortfolio?.eth_address || '',
										order_id: null,
										purchase_price: vue.purchase_price.toString(),
										purchase_cost_usd: vue.purchase_cost.toString(),
										purchase_fees_usd: vue.purchase_fees.toString(),
										purchase_total_usd: vue.purchase_total.toString(),
										purchase_amount_mph: vue.purchase_amount_mph.toString(),
										transaction_id: vue.transaction_id,
										purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
									}
								});
								if (result && result?.data?.purchasePaypalOrderCreate) {
									if (
										result.data.purchasePaypalOrderCreate.payload &&
										result.data.purchasePaypalOrderCreate.payload.result === 'error'
									) {
										TransactionService.purchasePaypalOrderCaptureError({
											data: {
												provider: 'paypal',
												total_amount_usd: vue.purchase_total,
												total_amount_mph: vue.purchase_amount_mph,
												country: vue.user?.document_country,
												error_details:
													'Paypal Purchase Order Create Error - ' +
													result.data.purchasePaypalOrderCreate.payload.message,
												eth_address: vue.user?.activePortfolio?.eth_address,
												purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
											}
										});
										vue.logSentryError(
											'Paypal Purchase Order Create Error - ' + result.data.purchasePaypalOrderCreate.payload.message
										);
										vue.$buefy.dialog.alert({
											title: this.$t('PAYMENT_CREATION_ERROR'),
											message:
												result.data.purchasePaypalOrderCreate.payload.message ||
												this.$t('PAYMENT_CREATION_ERROR_MESSAGE'),
											confirmText: this.$t('OK')
										});
										vue.purchase_loading = false;

										return;
									}
									vue.transaction_id = result.data.purchasePaypalOrderCreate.id;
									vue.order_id = result.data.purchasePaypalOrderCreate.payload.order_id;

									return result.data.purchasePaypalOrderCreate.payload.order_id;
								}
								vue.purchase_loading = false;
							} catch (err: any) {
								vue.purchase_loading = false;
								TransactionService.purchasePaypalOrderCaptureError({
									data: {
										provider: 'paypal',
										total_amount_usd: vue.purchase_total,
										total_amount_mph: vue.purchase_amount_mph.toString(),
										country: vue.user?.document_country,
										error_details: 'Paypal Purchase Order Error - ' + err.toString(),
										eth_address: vue.user?.activePortfolio?.eth_address,
										purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
									}
								});
								vue.logSentryError('Paypal Purchase Order Error - ' + err.toString());
								if (err) return vue.error(err);
							}
						},
						onInit(data: any) {
							//console.log('onInit', data)
						},
						onClick(data: any) {
							//console.log('onClick', data)
						},
						onError(err: any) {
							TransactionService.purchasePaypalOrderCaptureError({
								data: {
									provider: 'paypal',
									total_amount_usd: vue.purchase_total,
									total_amount_mph: vue.purchase_amount_mph.toString(),
									country: vue.user?.document_country,
									error_details: 'Paypal Purchase Order Error - ' + err.toString(),
									eth_address: vue.user?.activePortfolio?.eth_address,
									purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
								}
							});
							// Show an error page here, when an error occurs
							vue.logSentryError('Paypal Processing Error - ' + err.toString());
							vue.$buefy.dialog.alert({
								title: vue.$t('PAYMENT_PROCESSING_ERROR'),
								message: err.toString(),
								confirmText: vue.$t('OK')
							});
						},
						onCancel(data: any) {
							//console.log('onCancel', data)
							UserService.sendAnalytics({
								event: 'paypal_events',
								data: {
									event: 'canceled'
								}
							});
						},
						async onApprove(data: any) {
							try {
								//console.log('onApprove', data)
								UserService.sendAnalytics({
									event: 'paypal_events',
									data: {
										event: 'processing'
									}
								});
								vue.purchase_loading = true;
								// update the backend with the new user balances.
								const result = await TransactionService.purchasePaypalOrderApprove({
									data: {
										eth_address: vue.user?.activePortfolio?.eth_address || '',
										order_id: data.orderID,
										purchase_price: vue.purchase_price.toString(),
										purchase_cost_usd: vue.purchase_cost.toString(),
										purchase_fees_usd: vue.purchase_fees.toString(),
										purchase_total_usd: vue.purchase_total.toString(),
										purchase_amount_mph: vue.purchase_amount_mph.toString(),
										transaction_id: vue.transaction_id,
										purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
									}
								});
								if (result && result.data?.purchasePaypalOrderApprove) {
									if (
										result.data.purchasePaypalOrderApprove.payload &&
										result.data.purchasePaypalOrderApprove.payload.result === 'error'
									) {
										TransactionService.purchasePaypalOrderCaptureError({
											data: {
												provider: 'paypal',
												total_amount_usd: vue.purchase_total,
												total_amount_mph: vue.purchase_amount_mph,
												country: vue.user?.document_country,
												error_details:
													'Paypal Purchase Order Create Error - ' +
													result.data.purchasePaypalOrderApprove.payload.message,
												eth_address: vue.user?.activePortfolio?.eth_address,
												purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
											}
										});
										vue.logSentryError(
											'Paypal Purchase Order Create Error - ' + result.data.purchasePaypalOrderApprove.payload.message
										);
										vue.$buefy.dialog.alert({
											title: this.$t('PAYMENT_CREATION_ERROR'),
											message:
												result.data.purchasePaypalOrderApprove.payload.message ||
												this.$t('PAYMENT_CREATION_ERROR_MESSAGE'),
											confirmText: this.$t('OK')
										});
										vue.purchase_loading = false;

										return;
									}
									vue.transaction_id = result.data.purchasePaypalOrderApprove.id;
									vue.order_id = result.data.purchasePaypalOrderApprove.payload.order_id;
									if (result.data.purchasePaypalOrderApprove.payload.paypal_data.status === 'COMPLETED') {
										vue.process_status = 'processing';
										// call getBalance in order to get the wallet balances from the mainchain and sidechain networks
										const balance_result = await PortfolioService.getBalance({
											eth_address: vue.user?.activePortfolio?.eth_address
										});
										// set the balances to the back end return values
										if (balance_result.data.getBalance) {
											await vue.setBalance(balance_result.data.getBalance);
										}
									}
									return result.data.purchasePaypalOrderApprove.payload.paypal_data;
								}
								vue.purchase_loading = false;
							} catch (err: any) {
								vue.purchase_loading = false;
								TransactionService.purchasePaypalOrderCaptureError({
									data: {
										provider: 'paypal',
										total_amount_usd: vue.purchase_total,
										total_amount_mph: vue.purchase_amount_mph,
										country: vue.user?.document_country,
										error_details: 'Paypal Purchase Order Error - ' + err.toString(),
										eth_address: vue.user?.activePortfolio?.eth_address,
										purchase_currency: (vue.funds.currency || 'USD').toUpperCase()
									}
								});
								vue.logSentryError('Paypal Purchase Order Error - ' + err.toString());
								if (err) return vue.error(err);
							}
						}
					})
					.render('#paypal-button-container');

				UserService.sendAnalytics({
					event: 'begin_checkout',
					data: {
						currency: this.funds.currency,
						transaction_id: this.transaction_id,
						value: this.purchase_total.toString(),
						provider: 'paypal'
					}
				});
				this.paypal_loaded = true;
			} catch (err) {
				window.setTimeout(this.loadPaypalButtons, 1000);
			}
		},
		async initializePaypalSDK() {
			const curr = (this.funds.currency || 'USD').toUpperCase();
			this.order_id = '';
			this.transaction_id = '';

			if (document.getElementById('script_paypal')) {
				const script = document.getElementById('script_paypal');
				if (script) document.head.removeChild(script);
			}
			if (document.getElementById('paypal-button-container')) {
				const buttons = document.getElementById('paypal-button-container');
				if (buttons?.innerHTML) {
					buttons.innerHTML = '';
				}
			}

			let locales: any = {
				'en': 'en_US',
				'de': 'de_DE',
				'ru': 'ru_RU',
			}

			const paypalScript = document.createElement('script');
			paypalScript.setAttribute(
				'src',
				'https://www.paypal.com/sdk/js?client-id=' + import.meta.env.VITE_PAYPAL_CLIENT_ID + '&currency=' + curr + '&locale=' + (locales[this.$i18n.locale] || 'en_US')
			);

			paypalScript.setAttribute('id', 'script_paypal');
			document.head.appendChild(paypalScript);

			this.paypal_loaded = false;

			this.createPurchase();
		},
		/**
		 * Submit the purchase request information to the backend
		 */
		async createPurchase() {
			try {
				this.purchase_loading = true;
				// update the backend with the new user balances.
				const result = await TransactionService.purchasePaypalOrderRequest({
					data: {
						eth_address: this.user?.activePortfolio?.eth_address || '',
						order_id: this.order_id,
						purchase_price: this.purchase_price.toString(),
						purchase_cost_usd: this.purchase_cost.toString(),
						purchase_fees_usd: this.purchase_fees.toString(),
						purchase_total_usd: this.purchase_total.toString(),
						purchase_amount_mph: this.purchase_amount_mph.toString(),
						transaction_id: this.transaction_id,
						purchase_currency: (this.funds.currency || 'USD').toUpperCase()
					}
				});
				if (result && result.data?.purchasePaypalOrderRequest) {
					if (
						result.data.purchasePaypalOrderRequest.payload &&
						result.data.purchasePaypalOrderRequest.payload.result === 'error'
					) {
						TransactionService.purchasePaypalOrderCaptureError({
							data: {
								provider: 'paypal',
								total_amount_usd: this.purchase_total.toString(),
								total_amount_mph: this.purchase_amount_mph.toString(),
								country: this.user?.document_country,
								error_details:
									'Paypal Purchase Order Request Error - ' + result.data.purchasePaypalOrderRequest.payload.message,
								eth_address: this.user?.activePortfolio?.eth_address,
								purchase_currency: (this.funds.currency || 'USD').toUpperCase()
							}
						});
						this.logSentryError(
							'Paypal Purchase Order Request Error - ' + result.data.purchasePaypalOrderRequest.payload.message
						);
						this.$buefy.dialog.alert({
							title: this.$t('PAYMENT_CREATION_ERROR'),
							message: result.data.purchasePaypalOrderRequest?.payload.message || this.$t('PAYMENT_CREATION_ERROR_MESSAGE'),
							confirmText: this.$t('OK')
						});
						this.process_status = 'new';
						this.purchase_loading = false;

						return;
					}
					this.transaction_id = result.data.purchasePaypalOrderRequest.id;
					this.order_id = result.data.purchasePaypalOrderRequest.payload.order_id;
					this.purchase_loading = false;
					this.loadPaypalButtons();
				}
				this.purchase_loading = false;
			} catch (err: any) {
				this.purchase_loading = false;
				TransactionService.purchasePaypalOrderCaptureError({
					data: {
						provider: 'paypal',
						total_amount_usd: this.purchase_total,
						total_amount_mph: this.purchase_amount_mph,
						country: this.user?.document_country,
						error_details: 'Paypal Purchase Order Error - ' + err.toString(),
						eth_address: this.user?.activePortfolio?.eth_address,
						purchase_currency: (this.funds.currency || 'USD').toUpperCase()
					}
				});
				this.logSentryError('Paypal Purchase Order Error - ' + err.toString());
				if (err) return this.error(err);
			}
		}
	}
});
</script>
<style scoped src="../../assets/css/payment.css"></style>
<style scoped>
#paypal-button-container {
	margin-bottom: -1rem;
	min-height: 200px;
	overflow-y: auto;
	max-height: calc(100vh - 180px);
}

@media only screen and (max-width: 768px) {
	#paypal-button-container {
		min-width: 100%;
	}
}

.provider-bonus {
	color: #333333;
	padding: 5px 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	background: #fae3c2;
	border-radius: 5px;
	margin-top: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.icon_header {
	display: flex;
	align-items: center;
	gap: 5px;
}
</style>
