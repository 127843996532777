<template>
	<section>
		<b-modal :modelValue="showDepositWithdrawModal" has-modal-card @close="closeModal()"
			:can-cancel="!isMobile && funds.withdraw_page !== 'withdraw_progress' && funds.withdraw_page !== 'withdraw_processing'">
			<div :class="{
				terminal: !isMobile,
				'modal-card': !isMobile,
				'modal-card-mobile': isMobile
			}">
				<div class="has-text-left" v-if="migrationStarted" style="padding: 25px">
					<div class="has-text-centered pending-img">
						<img src="@/assets/funds/lock.svg" width="64px" height="64px" alt="Calender lock icon" />
					</div>

					<p>{{ $t('MIGRATION_STARTED_TRANSACTION') }}</p>

					<div class="has-text-centered mt-20">
						<!-- Back to Funds -->
						<button class="button flat-white-btn large is-fullwidth mt-15" @click="closeModal()">
							{{ $t('funds.CLOSE_PAYMENT') }}
						</button>
					</div>
				</div>

				<div class="has-text-left" v-else-if="funds.showDepositBonus">
					<DepositBonus :currencySymbol="currencySymbol || ''" :currencyExchange="currencyExchange"
						:totalWithdraw="withdraw_all_time" />
				</div>
				<div class="has-text-left" v-else-if="funds.showPurchaseAgreement">
					<PurchaseAgreement />
				</div>
				<div class="has-text-left" v-else-if="funds.showPaymentMethod || funds.showWithdrawMethod">
					<PaymentMethod />
				</div>
				<div class="has-text-left" v-else-if="funds.showWithdrawAddress">
					<WithdrawAddress />
				</div>
				<div class="has-text-left" v-else-if="funds.provider == 'morpher'">
					<PaymentTypeMorpher :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
						:currencyExchange="currencyExchange" />
				</div>
				<div class="has-text-left" v-else-if="funds.showPaymentType == 'sepa'">
					<PaymentTypeSEPA :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
						:currencyExchange="currencyExchange" />
				</div>
				<div class="has-text-left" v-else-if="funds.showPaymentType == 'advcash'">
					<PaymentTypeADVCash :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
						:currencyExchange="currencyExchange" />
				</div>
				<div class="has-text-left" v-else-if="funds.showPaymentType == 'moonpay'">
					<PaymentTypeMoonpay :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
						:currencyExchange="currencyExchange" />
				</div>
				<div class="has-text-left" v-else-if="funds.showPaymentType == 'moonpay_withdraw'">
					<WithdrawTypeMoonpay :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
						:currencyExchange="currencyExchange" />
				</div>
				<div class="has-text-left" v-else-if="funds.showPaymentType == 'paypal'">
					<PaymentTypePaypal :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
						:currencyExchange="currencyExchange" />
				</div>
				<div class="has-text-left" v-else-if="funds.showPaymentType == 'coinbase'">
					<PaymentTypeCoinbase :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
						:currencyExchange="currencyExchange" />
				</div>

				<div class="has-text-left" v-else>
					<!-- tabs -->
					<section class="section header-bar" v-if="!funds.withdraw_page">
						<div class="header-tabs header-tab-seperator"
							:class="{ 'header-tab-selected': funds.type == 'deposit' }" @click="setType('deposit')">
							<p>{{ $t('funds.DEPOSIT') }}</p>
						</div>
						<div class="header-tabs" :class="{ 'header-tab-selected': funds.type == 'withdraw' }"
							@click="setType('withdraw')">
							<p>{{ $t('WITHDRAW_HEADING') }}</p>
						</div>
					</section>

					<!-- deposit -->
					<div v-if="funds.type == 'deposit'">
						<Deposit :currencySymbol="currencySymbol || ''" :maxLeft="calcMaxLeft"
							:currencyExchange="currencyExchange" :balanceLoaded="balance_loaded"
							:totalWithdraw="withdraw_all_time" />
					</div>

					<!-- withdraw -->
					<div v-if="funds.type == 'withdraw'">
						<Withdraw :ethMarket="eth_market || {}" :polMarket="pol_market || {}"
							:balanceLoaded="balance_loaded" />
					</div>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';
import { useFundsStore } from '@/store/modules/funds';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { PortfolioService } from '@/services/PortfolioService';
import { TransactionService } from '@/services/TransactionService';
import { UserService } from '@/services/UserService';
import Deposit from '../funds/Deposit.vue';
import Withdraw from '../funds/Withdraw.vue';
import DepositBonus from '../funds/DepositBonus.vue';
import PurchaseAgreement from '../funds/PurchaseAgreement.vue';
import PaymentMethod from '../funds/PaymentMethod.vue';
import WithdrawAddress from '../funds/WithdrawAddress.vue';
import PaymentTypeMorpher from '../funds/PaymentTypeMorpher.vue';
import PaymentTypeSEPA from '../funds/PaymentTypeSEPA.vue';
import PaymentTypeADVCash from '../funds/PaymentTypeADVCash.vue';
import PaymentTypeMoonpay from '../funds/PaymentTypeMoonpay.vue';
import WithdrawTypeMoonpay from '../funds/WithdrawTypeMoonpay.vue';
import PaymentTypePaypal from '../funds/PaymentTypePaypal.vue';
import PaymentTypeCoinbase from '../funds/PaymentTypeCoinbase.vue';
import { ERROR, MARKETS } from '@/store/mutation-types';
import EventBus from '@/store/event-bus';
import type { Market } from '@/types/schema';
import to from 'await-to-js';
import { useStatusStore } from '@/store/modules/status';



/* @group Components_Modals */
/*
	<h4> <b> Deposit Earn Modal </b> </h4>
	<br> Shows user potential earnings with 10% purchase bonus.
*/
export default defineComponent({
	name: 'DepositWithdrawModal',
	components: {
		Deposit,
		Withdraw,
		PurchaseAgreement,
		PaymentMethod,
		WithdrawAddress,
		PaymentTypeMorpher,
		PaymentTypeADVCash,
		PaymentTypeMoonpay,
		WithdrawTypeMoonpay,
		PaymentTypePaypal,
		PaymentTypeCoinbase,
		DepositBonus,
		PaymentTypeSEPA
	},
	data() {
		return {
			deposit: 1000,
			eur_market: null as Market | null,
			rub_market: null as Market | null,
			gbp_market: null as Market | null,
			ltc_market: null as Market | null,
			btc_market: null as Market | null,
			bch_market: null as Market | null,
			eth_market: null as Market | null,
			pol_market: null as Market | null,
			purchase_update_subscription: null as any,
			isMobile: false,
			balance_loaded: false,
			withdraw_all_time: 0
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showDepositWithdrawModal: (state) => state.showDepositWithdrawModal,
		}),
		...mapState(useUserStore, {
			activePortfolio: (state) => state.activePortfolio,
			user: (state) => state.data,
		}),
		...mapState(useFundsStore, {
			funds: (state) => state,
		}),
		...mapState(useContractStore, {
			balance: (state) => state.balance,
		}),

		currencySymbol() {
			let source_currency: string = this.funds?.currency || '';
			if (!source_currency) {
				return '$';
			}
			source_currency = source_currency.toLowerCase();
			if (source_currency == 'usd') {
				return '$';
			}
			if (source_currency == 'eur') {
				return '€';
			}
			if (source_currency == 'gbp') {
				return '£';
			}

			if (source_currency == 'rub') {
				return '₽';
			}

			if (source_currency == 'sepa') {
				return '€';
			}

			return source_currency;
		},
		currencyExchange() {
			const purchaseCurrency = this.funds?.currency?.toLowerCase() || 'usd';

			if (purchaseCurrency === 'eur') {
				return this.eur_market ? Number(this.eur_market.close) : 1;
			} else if (purchaseCurrency === 'gbp') {
				return this.gbp_market ? Number(this.gbp_market.close) : 1;
			} else if (purchaseCurrency === 'rub') {
				return this.rub_market ? 1 / Number(this.rub_market.close) : 1;
			} else if (purchaseCurrency === 'ltc') {
				return this.ltc_market ? Number(this.ltc_market.close) : 1;
			} else if (purchaseCurrency === 'bch') {
				return this.bch_market ? Number(this.bch_market.close) : 1;
			} else if (purchaseCurrency === 'btc') {
				return this.btc_market ? Number(this.btc_market.close) : 1;
			} else {
				return 1;
			}
		},
		// Determine maximum user can deposit today based on daily/weekly/yearly limits
		calcMaxLeft() {
			const exchange = this.currencyExchange || 1;
			if (!this.balance || !this.balance.purchase_limits) {
				return 0;
			}

			const max_month = (20000 - this.balance.purchase_limits.purchase_month) / exchange;
			const max_day = (5000 - this.balance.purchase_limits.purchase_day) / exchange;
			const max_crypto = (20000 - this.balance.purchase_limits.purchase_crypto) / exchange;

			if (this.funds.provider !== 'coinbase') {
				return Math.min(max_month, max_day);
			} else {
				return Math.round(max_crypto * 1000) / 1000;
			}
		},
		migrationStarted() {
			if (this.user?.payload?.migration_started == true) {
				return true;
			} else {
				return false;
			}
		},
	},
	watch: {
		async showDepositWithdrawModal(nv) {
			if (nv) {
				if (!this.activePortfolio) {
					return;
				}
				this.balance_loaded = false;
				const result = await PortfolioService.getBalance({ eth_address: this.activePortfolio.eth_address, include_mainchain: false });

				// set the balances to the back end return values
				if (result.data.getBalance) {
					await this.setBalance(result.data.getBalance);
					//this.activePortfolio.cash_balance = Number(result.data.getBalance.mph_sidechain) - Number(this.activePortfolio.pending || 0);
					this.balance_loaded = true;
					this.calcWithdrawTotal();
				}
			}
		},
		'funds.showPaymentType'(nv) {
			if (nv) {
				if (nv == 'paypal') {
					//this.startRecording();
				}
			}

		}
	},
	async mounted() {

		this.balance_loaded = false;
		// call getBalance in order to get the wallet balances from the mainchain and sidechain networks
		const result = await PortfolioService.getBalance({ eth_address: this.user?.activePortfolio?.eth_address });

		// set the balances to the back end return values
		if (result.data.getBalance) {
			await this.setBalance(result.data.getBalance);
			this.balance_loaded = true;
		}

		this.loadMarket();

		this.purchase_update_subscription = TransactionService.subscribePurchaseUpdate(
			this.user?.activePortfolio?.eth_address || '',
			async data => {
				EventBus.$emit('payment_update', data?.data?.updateTransaction);
			}
		);

		this.handleResize();

		UserService.sendAnalytics({ event: 'open_deposit_withdraw', data: { type: this.funds.type, source: this.funds.source } })
	},
	unmounted() {
		window.removeEventListener('resize', this.handleResize);
	},
	async created() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeUnmount() {
		if (this.purchase_update_subscription) {
			this.purchase_update_subscription.unsubscribe();
		}
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleDepositWithdrawModal: 'toggleDepositWithdrawModal',
		}),
		...mapActions(useContractStore, {
			setBalance: 'setBalance',
		}),
		...mapActions(useFundsStore, {
			updateFunds: 'updateFunds',
			resetFunds: 'resetFunds',
		}),
		...mapActions(useMarketsStore, {
			getMarketsByIds: 'getMarketsByIds',
		}),
		...mapActions(useStatusStore, {
			error: ERROR,
		}),

		closeModal() {
			if (this.funds.withdraw_page == 'withdraw_progress' || this.funds.withdraw_page == 'withdraw_processing') {
				return;
			}
			if (this.funds.showPaymentType == 'coinbase') {
				this.resetFunds({});
				this.updateFunds({ showPaymentType: null });
			}

			this.toggleDepositWithdrawModal(false);
		},
		findElement(container: any, tagName: string) {
			for (var i = 0; i < container.children.length; i++) {
				const element = container.children[i];
				if (element.tagName.toLowerCase() == tagName.toLowerCase()) {
					return element;
				} else if (element.children && element.children.length > 0) {
					const child_element: any = this.findElement(element, tagName);
					if (child_element) {
						return child_element;
					}
				}
			}
			return null;
		},
		setIframe() {

			let iframes = document.getElementsByTagName('iframe')

			for (var i = 0; i < iframes.length; i++) {
				const iframe = iframes[i];
				if (!iframe.getAttribute('data-hj-allow-iframe')) {
					iframe.setAttribute('data-hj-allow-iframe', 'true');
				}
			}

			if (
				document.getElementById('ramp-network-container') &&
				document.getElementById('ramp-network-container')?.children &&
				(document.getElementById('ramp-network-container')?.children?.length || 0) > 0
			) {
				const container = document.getElementById('ramp-network-container')?.children[0];

				if (container && container.shadowRoot) {
					const iframe = this.findElement(container.shadowRoot, 'IFRAME');

					if (iframe) {
						if (!iframe.getAttribute('data-hj-allow-iframe')) {
							iframe.setAttribute('data-hj-allow-iframe', 'true');
						}
					}
				}
			}

			window.setTimeout(() => {
				this.setIframe();
			}, 1000)

		},
		// startRecording() {


		// 	this.setIframe();

		// 	if (!document.getElementById('script_hotjar') && !document.getElementById('script_hotjar_injected')) {

		// 		if (import.meta.env.VITE_MODE === 'production') {
		// 			const scriptHTML = "     (function(h,o,t,j,a,r){ \n \
		// 				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; \n \
		// 				h._hjSettings={hjid:3058011,hjsv:6}; \n \
		// 				a=o.getElementsByTagName('head')[0]; \n \
		// 				r=o.createElement('script');r.async=1; \n \
		// 				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; \n \
		// 				a.appendChild(r); \n \
		// 			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"


		// 			const hotjarScript = document.createElement('script');
		// 			hotjarScript.setAttribute('id', 'script_hotjar');
		// 			hotjarScript.innerHTML = scriptHTML
		// 			document.head.appendChild(hotjarScript);
		// 		} else {
		// 			const scriptHTML = "     (function(h,o,t,j,a,r){ \n \
		// 				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; \n \
		// 				h._hjSettings={hjid:3067715,hjsv:6}; \n \
		// 				a=o.getElementsByTagName('head')[0]; \n \
		// 				r=o.createElement('script');r.async=1; \n \
		// 				r.id='script_hotjar_injected'; \n \
		// 				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; \n \
		// 				a.appendChild(r); \n \
		// 			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"


		// 			const hotjarScript = document.createElement('script');
		// 			hotjarScript.setAttribute('id', 'script_hotjar');
		// 			hotjarScript.innerHTML = scriptHTML
		// 			document.head.appendChild(hotjarScript);

		// 			window.setTimeout(() => {
		// 				if (window.hj) {
		// 					var userId = this.activePortfolio.eth_address || null; // Replace your_user_id with your own if available.
		// 					window.hj('identify', userId, {});
		// 					console.log('id done')
		// 				}
		// 			}, 1000)
		// 		}
		// 	}


		// },		
		handleResize() {
			if (document.documentElement.clientWidth) {
				const screenWidth = document.documentElement.clientWidth;
				if (screenWidth < 768) {
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			}
		},
		setType(type: 'deposit' | 'withdraw') {
			this.updateFunds({ type });
		},
		async loadMarket() {
			if (!this.eur_market) {
				const market_list = await this.getMarketsByIds({
					variables: {
						ids: ['FOREX_GBP_USD', 'FOREX_EUR_USD', 'FOREX_USD_RUB', 'CRYPTO_BTC', 'CRYPTO_BCH', 'CRYPTO_LTC', 'CRYPTO_ETH', 'CRYPTO_POL'],
						minutely: false
					},
					commitType: MARKETS.DISABLED,
					format: true,
					sort: true,
					component: this
				});



				market_list.forEach((market: Market) => {
					if (market?.name?.toLowerCase() === 'euro') {
						this.eur_market = market;
					}
					if (market?.name?.toLowerCase() === 'british pound') {
						this.gbp_market = market;
					}
					if (market?.name?.toLowerCase() === 'russian ruble') {
						this.rub_market = market;
					}
					if (market?.name?.toLowerCase() === 'litecoin') {
						this.ltc_market = market;
					}
					if (market?.name?.toLowerCase() === 'bitcoin') {
						this.btc_market = market;
					}
					if (market?.name?.toLowerCase() === 'bitcoin cash') {
						this.bch_market = market;
					}

					if (market?.name?.toLowerCase() === 'ethereum') {
						this.eth_market = market;
					}
					if (market?.symbol?.toLowerCase() === 'pol') {
						this.pol_market = market;
					}
				});
			}
			if (!this.eur_market) {
				window.setTimeout(() => {
					this.loadMarket();
				}, 500);
			}
		},
		async calcWithdrawTotal() {
			// Get the sum of withdrawals and claims to calculate eligibility for deposit bonuses
			const [err, result] = await to(PortfolioService.getTransactions({ eth_address: this.activePortfolio?.eth_address || '' }));
			if (err) return this.error(err);
			if (result.data.getTransactions) {
				let txn_data = result.data.getTransactions;

				this.withdraw_all_time = 0;

				const timestamp = Date.now();

				txn_data.forEach((history: any) => {
					if (
						history.type === 'withdrawal' &&
						history.payload &&
						(history.status === 'processing' || history.status === 'success' || history.status === 'requested')
					) {
						this.withdraw_all_time = this.withdraw_all_time + (history.payload.tokens / Math.pow(10, 18) + 100) * history.payload.mph_price;
					}
				});
			}
		}
	}
});
</script>
<style scoped>
.mt-min-top {
	margin-top: -10px;
}

.header-tabs {
	border-bottom: 1px solid #eae9ed;
	text-align: center;
	font-family: 'Rubik', sans-serif;
	font-size: 16px;
	flex: 50%;
	background-color: #fbfbfb;
	transition: background-color 0.25s, color 0.25s;
}

.header-tabs:not(.header-tab-selected):hover {
	background-color: #f5f5f5;
	transition: background-color 0.25s, color 0.25s;
}

.header-tab-selected {
	font-weight: 500;
	background-color: #fff;
}

.header-tab-seperator {
	border-right: 1px solid #eae9ed;
}

.header-bar {
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	cursor: pointer;
	display: flex;
	height: 48px;
	line-height: 48px;
}

.modal-card {
	min-width: 400px;
}

.modal-card-ramp {
	min-width: 500px;
}

.header-tab-disabled {
	background-color: #fbfbfb;
	cursor: not-allowed;
}

@media screen and (max-width: 768px) {
	.modal-card {
		min-width: 100%;
		margin: 0;
		padding: 0;
	}

}

.modal-card-mobile {
	background-color: #fff;
	border: 0;
	border-radius: 0;
	position: absolute;
	min-width: 100%;
	margin: 0;
	padding: 0;

	top: 0px;
	height: 100%;
	overflow: scroll !important;
}

.modal-close-mobile:hover {
	background-color: rgba(10, 10, 10, 0.3);
}

.modal-close-mobile {
	height: 32px;
	max-height: 32px;
	max-width: 32px;
	min-height: 32px;
	min-width: 32px;
	width: 32px;
	background: none;

	color: #fff;
	font-size: 15px;
	background-color: rgba(10, 10, 10, 0.2);
	border: none;
	border-radius: 9999px;
	cursor: pointer;
	pointer-events: auto;
	display: block;
	position: absolute;
	top: 55px;
	left: 10px;
}
</style>
