<template>
	<div class="formcontainer">
		<div class="headerImage">
			<a href="https://www.morpher.com" target="_blank" alt="Morpher Homepage">
				<img class="image" width=60 src="@/assets/icons/exclamation-mark.svg" alt="Morpher Logo" />
			</a>
		</div>

		<div v-if="!config.application_live && $route.query && $route.query.adminoverride !== 'true'" class="authBox">
			{{ $t('MAINTANANCE_MODE') }}
		</div>
		<div class="authBox">
			<div v-show="!initialising">
				<!-- Connect Fortmatic -->
				<div>
					<h2 data-cy="logInTitle" class="authTitle">{{
						$t('auth.TOKEN_LOGIN_ERROR') }}</h2>

					<p data-cy="logInDescription" class="authText">{{ $t('auth.TOKEN_LOGIN_ERROR_DESC') }}
					</p>


					<!-- Signin with email/password -->
					<div>


						<button type="submit" @click="retryLogin" data-cy="submit"
							:class="{ 'is-loading': loginProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('auth.TOKEN_RETRY') }}</span>
						</button>

						<div class="error" v-if="loginError">
							<p data-cy="loginError">
								⚠️ <span v-html="loginError"></span>
							</p>
						</div>

						<p class="loginLink">
							<router-link to="/login" class="login-router transition-faster"><span>&lt;&nbsp;&nbsp; {{
								$t('LOGOUT') }} </span></router-link>
						</p>

					</div>

				</div>
			</div>
		</div>

		<!-- Need help logging in -->
		<!-- <div class="loginHelp"></div>

		<p class="loginLink">
			{{ $t('NEED_HELP') }}
			<a
				:href="supportArticleLink('LoginIssues')"
				class="transition-faster"
				target="_blank"
				>{{ $t('auth.VISIT_SUPPORT') }}</a
			>
		</p> -->

		<!-- <vue-recaptcha
			ref="recaptcha"
			size="invisible"
			:sitekey="recaptchaSiteKey"
			:load-recaptcha-script="true"
			@verify="onCaptchaVerified"
			@error="onCaptchaError"
			@expired="onCaptchaExpired"
			@render="onCaptchaLoaded"
		/> -->
	</div>
</template>

<script lang="ts">
//vuex
import { ERROR } from '@/store/mutation-types';

//import { getDictionaryValue } from '@/helpers/wallet/dictionary';
import { accessTokenName, refreshTokenName, getUserID } from '@/helpers/utils';
import deviceType from '@/helpers/deviceType';
import { UserService } from '@/services/UserService';
import LoginApple from './LoginApple.vue';
import LoginGoogle from './LoginGoogle.vue';

import * as blockies from 'ethereum-blockies-png';
//import RecaptchaMixin from '@/mixins/recaptcha.mixin';
import AuthMixin from '@/mixins/auth.mixin';
//import VueRecaptcha from 'vue-recaptcha';
import Cookie from 'js-cookie';
import { morpherWalletConfig } from '@/helpers/morpherwallet';
import Password from 'vue-simple-password-meter';
import { HFaceBookLogin } from '@healerlab/vue3-facebook-login';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { useUserStore } from '@/store/modules/user';
import { useAdminStore } from '@/store/modules/admin';
import { useContractStore } from '@/store/modules/contract';
import { defineComponent } from 'vue';
import type { TMorpherWalletInit } from '@/types/graphql-global-types';


/* @group Components_Auth */
/*
  <h4> <b>Login Form</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'TokenLoginRetry',
	components: { LoginApple, LoginGoogle, Password, HFaceBookLogin }, // , 'vue-recaptcha': VueRecaptcha
	mixins: [AuthMixin], // RecaptchaMixin
	data() {
		return {
			passwordIsVisible: false,
			confirmPasswordIsVisible: false,
			walletEmail: '',
			walletPassword: '',
			walletError: '',
			walletPasswordConfirm: '',
			loginError: '',
			loginUser: {} as any,
			initialising: true,
			showRecovery: false,
			walletClientlogin: {} as any,
			morpherWalletInit: {} as any,
			loginProcessing: false,
			loginProcessingGoogle: false,
			loginProcessingApple: false,
			facebookClientId: import.meta.env.VITE_FACEBOOK_APP_ID,
			facebook: {
				FB: {},
				model: {},
				scope: {}
			},

			loading: {
				google: false,
				facebook: false,
				vk: false
			},
			passwordChecks: {
				min: '',
				uppercase: '',
				lowercase: '',
				number: '',
				match: ''
			},
			recovery_type: null,
			default_wallet: '',
			showMetamaskHelp: false,
			airdrop_id: '',
			referred_by: '',
			custom_invite: '',
			morpherwallet: null as any,
			isMetamaskSupported: false,
			newEmail: ''

		};
	},
	computed: {
		...mapState(useAdminStore, {
			config: state => state.config,
		}),

		...mapState(useUserStore, {
			user: state => state.data,
		}),
		...mapState(useContractStore, {

			contract: state => state as any,
		})
	},
	watch: {
		walletError(newValue) {
			if (newValue) {
				// If there is a logon error then log it to sentry
				this.logSentryError('Logon Error:' + newValue);
				this.error({ message: 'WALLET_CONNECT_ERROR' });
			}
		},


	},
	async mounted() {

		let accessToken = localStorage.getItem(accessTokenName);
		let refreshToken = localStorage.getItem(refreshTokenName);

		if (!accessToken && !refreshToken) {
			this.logoutUser()
		}


		this.initialising = false
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR
		}),
		...mapActions(useUserStore, {
			loginWallet: 'loginWallet',
			confirmWallet: 'confirmWallet',
			loginToken: 'loginToken',

		}),
		...mapActions(useContractStore, {
			setWalletClient: 'setWalletClient',
			setMorpherWalletProvider: 'setMorpherWalletProvider'
		}),

		checkKeyPress(e: KeyboardEvent) {
			if (e.key == 'Enter') {
				if (this.walletPassword && this.walletPasswordConfirm) {
					this.retryLogin();
				} else {
					// set focus to the password field if it is blanck
					window.setTimeout(() => {
						const passwordElement = (this.$refs.login_password as InstanceType<typeof HTMLInputElement>);
						if (passwordElement) passwordElement.focus();
					}, 100);
				}
			}
		},
		async retryLogin() {
			this.loginProcessing = true;

			let tim = setTimeout(() => {
				this.loginProcessing = false;
			}, 20000);
			try {
				let result = await this.loginToken({
					updatePortfolio: true,
					component: this,
					loading: true
				});
				if (result === true) {

					const logonRoute = localStorage.getItem('logonRoute')
					if (logonRoute) {
						localStorage.removeItem('logonRoute')
						this.$router.push(logonRoute);
					} else {
						this.$router.push('/');
					}
				}
				this.loginProcessing = false;
				clearTimeout(tim)


			} catch (err) {
				console.log('Retry Error', err)

			}

		},

		async logoutUser() {

		},


		async reloadPage(fortmaticNoAccountFound = false) {
			// remove all old cached items
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			if (fortmaticNoAccountFound) return;

			// reload the page if the previous reload was more than 1 hour ago (to avoid refresh loop)
			const reloadDate = localStorage.getItem('reloadDateLogin');
			const timestamp = Date.now();

			if (!reloadDate || Number(reloadDate) < timestamp - 3600000) {
				localStorage.setItem('reloadDateLogin', String(timestamp));
				window.location.reload();
			}
		},








		// Submits login request
		async formAction() { }
	}
});
</script>
<style scoped>
.button-img-primary {
	height: 28px;
	padding-right: 15px;
}

.button-img-alt {
	position: absolute;
	right: calc(50% + 70px);
}

.alt-button {
	padding-left: 46px;
}

.login-help {
	margin-top: -0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.settings-link {
	line-height: 1rem;
	cursor: default;
}



@media only screen and (max-width: 400px) {
	.button-img-alt {
		display: none;
	}

	.alt-button {
		padding-left: initial;
	}
}

.spaced {
	margin-right: 7px;
}

.authBox {
	margin-top: 0px;
}

.formcontainer {
	display: flex;
	flex-direction: column;
	width: 360px;
	margin: auto auto;
}

.headerImage img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.authTitle {
	font-size: 32px;
	font-weight: 700;
	line-height: 38px;
	margin-top: 25px;
	color: #333333;
}

.headerImage {
	width: 100%;
}

.authText {
	font-weight: 400;
	color: #666666;
	margin-top: 12px;
	font-size: 16px;
}

.label {
	margin-top: 20px;
	margin-bottom: 4px;
	text-align: left;
	color: #686475;
	font-weight: 700;
	font-size: 12px;
}

.input {
	padding: 23px 16px;
	background-color: #fff;
	border-radius: 8px !important;
	border-color: #d0d5dd !important;
}

.input::placeholder {
	font-family: 'Manrope';
	line-height: 20px;
	font-size: 14px !important;
	font-weight: 400;
	color: #686475;
}




.loginDivider {
	height: 1px;
	width: 100%;
	margin: 20px 0;
	border-top: 1px solid #eae9ed;
}

.pt-30 {
	padding-top: 30px;
}

.loginHelp {
	height: calc(100vh - 800px);
}

@media only screen and (max-width: 768px) {
	.formcontainer {
		margin: 0 auto;
		height: 100%;
	}
}

.password-toggle {
	position: absolute;
	right: 10px;
	top: 8%;
	background: none;
	border: none;
	cursor: pointer;
}

.confirm-password-toggle {
	position: absolute;
	right: 10px;
	top: 30%;
	background: none;
	border: none;
	cursor: pointer;
}

.password-help {
	font-size: 12px;
	text-align: left;
}

.password-help p {
	margin-bottom: 5px;
}

.items {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 5px;
}

.password-help li {
	margin: 0;
	padding-left: 15px;
	position: relative;
	transition: 200ms;
}

.password-help li::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
	transition: 200ms;
}

.password-help .done {
	color: #00c386;
}

.password-help .done::before {
	border: none;
	border-bottom: 2px solid #00c386;
	border-right: 2px solid #00c386;
	transform: translateY(-50%) rotate(45deg);
	border-radius: 0;
	width: 6px;
	height: 10px;
}

.password-help .fail {
	color: #fc6404;
}

.password-help .fail::before {
	content: '✕';
	border: none;
	border-radius: 0;
	top: auto;
	transform: none;
}
</style>
