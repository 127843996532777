<template>
	<section>
		<transition name="fade">
			<div class="notification is-size-14 has-font-manrope" v-if="showWalletRecoveryModal">
				<div class="text-section has-text-left">
					<div>
						<img src="@/assets/funds/warning-icon.svg" class="mobile-icon" />
					</div>
					<div class="is-line-height-18" @click="addRecovery">
						<span v-html="$t('wallet_recovery.WARNING_TEXT')"></span>
					</div>

					<img src="@/assets/icons/close-icon.svg" class="notification-close-icon" @click="closeModal" />

				</div>
			</div>
		</transition>
	</section>
</template>

<script lang="ts">
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';


/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({

	name: 'WalletRecoveryModal',
	data() {
		return {
			username: '',
			usernameTimeout: null as null | NodeJS.Timeout | number,
			usernameValidation: true,
			usernameMessage: '',
			email: '',
			loading: false,
			warning: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showWalletRecoveryModal: (state) => state.showWalletRecoveryModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
			morpherwallet: (state) => state.morpherwallet,
		}),

	},
	watch: {
		user() {
			this.initialize();
		},
		username() {
			if (this.usernameTimeout) clearTimeout(this.usernameTimeout);
			this.usernameTimeout = setTimeout(() => {
				this.validateUsername();
			}, 1000);
		},
		showWalletRecoveryModal(nv) {
			this.initialize();
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleWalletRecoveryModal: 'toggleWalletRecoveryModal',
		}),
		...mapActions(useUserStore, {
			validateRegistrationField: 'validateRegistrationField',
			updateUserPayload: 'updateUserPayload',
		}),
		validateUsername() {
			// Process the user validation
			if (this.username === '') {
				this.usernameValidation = false;
				this.usernameMessage = this.$t('USERREG_USEREMPTY');
			} else {
				this.validateRegistrationField({
					id: this.user?.id,
					fieldName: 'username',
					inputFieldValue: this.username
				}).then(returnData => {
					this.usernameValidation = returnData.success;
					// Get the error message from the language file
					this.usernameMessage = returnData.returnMessage
						.replace('UserBad', this.$t('USERREG_USERBAD'))
						.replace('UserShort', this.$t('USERREG_USERSHORT'))
						.replace('UserSymbol', this.$t('USERREG_USERSYMBOL'))
						.replace('UsernameAlreadyUsed', this.$t('USERREG_USERNAMEALREADYUSED'));
				});
			}
		},

		async claimAirdrop() {
			this.loading = true;
			await this.updateUserPayload({ payloadOption: 'claim_airdrop', payloadValue: 'false' });

			this.toggleWalletRecoveryModal(false);
			this.loading = false;
		},
		async closeModal() {
			await this.updateUserPayload({ payloadOption: 'security_check', payloadValue: Date.now().toString() });
			this.toggleWalletRecoveryModal(false);
		},

		initialize() { },
		async addRecovery() {
			await this.updateUserPayload({ payloadOption: 'security_check', payloadValue: Date.now().toString() });
			this.toggleWalletRecoveryModal(false);
			if (this.morpherwallet) this.morpherwallet.showWalletRecovery();
		}
	}
});
</script>
<style>
.warning-link {
	color: #1A6DF0;
	cursor: pointer;
}
</style>

<style scoped>
.notification {
	background: #FFF9E3;
	padding: 0.75rem 1.25rem;
	position: fixed;
	left: 0px;
	width: 100%;
	z-index: 2999;
	top: 82px;
	transition: 200ms;
}



.text-section {
	max-width: 1070px;
	margin-left: auto;
	margin-right: auto;

	display: flex;
	align-items: center;
	gap: 0.5rem;
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 1216px) {
	.notification {
		top: 62px;
		z-index: 29;
		padding-left: 40px;
		padding-right: 40px;
	}

	.text-section {
		max-width: unset;
	}
}

/* mobile */
@media screen and (max-width: 768px) {
	.notification {
		top: 60px;
		z-index: 29;
		padding-left: 20px;
		padding-right: 20px;
	}

	.text-section {
		max-width: unset;
	}

	.mobile-icon {
		min-width: 24px;
	}
}

.is-blue:not(:hover) {
	color: #1A6DF0 !important;
}

.notification-close {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.35);
}

.cursor {
	cursor: pointer;
}

.warning-icon {
	height: 24px;
	width: 24px;
	min-width: 24px;
}

.notification-close-icon {
	height: 24px;
	width: 24px;
	min-width: 24px;
	margin-left: auto;
	cursor: pointer;
}
</style>
