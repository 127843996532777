<template>
	<div v-if="!isEmptyObject(user)">
		<!-- <div v-if="activePortfolio && isLoggedInMetamask && walletText === null" class="wallet-info-container">
			<p class="wallet-text">{{ formatEthAddress(user.username) }}</p>
			<Blockie v-if="!loadingPortis" :src="activePortfolio.blockie" />
			<div v-if="loadingPortis && (walletType === 'portis' || walletType === 'fortmatic')" class="loader wallet-loader" />
		</div> -->
		<a v-if="!activePortfolio || !isLoggedInMetamask || walletText !== null" @click="walletTextClick">
			<div class="wallet-info-container">
				<b-icon v-if="walletText && walletText.icon && walletType === 'metamask'" slot="trigger" pack="fas"
					icon="arrow-up" size="is-small" class="custom-arrow vert-move grey-spaced"></b-icon>
				<span class="wallet-text" data-testid="unlock-wallet">{{
					walletText ? walletText.message : mobileDisplay ? $t('UNLOCK_WALLET') : ''
				}}</span>

				<!-- <img v-if="walletType === 'metamask'" class="wallet-image" src="@/assets/icons/metamask_icon.svg" />
				<img v-if="walletType === 'portis' && !loadingPortis" class="wallet-image" src="@/assets/icons/portis_icon.svg" />
				<img v-if="walletType === 'fortmatic' && !loadingPortis" class="wallet-image" src="@/assets/icons/fortmatic_icon.svg" /> -->
				<div v-if="(walletType === 'portis' || walletType === 'fortmatic' || walletType === 'morpherwallet') && loadingPortis"
					class="loader wallet-loader"></div>
			</div>
		</a>
		<div v-else-if="mobileDisplay" class="mobile-container">
			<p class="wallet-text mobile">{{ formatEthAddress(user?.username || '') }}</p>
			<Blockie v-if="!loadingPortis" :src="activePortfolio?.blockie || ''" />
			<div v-if="loadingPortis && (walletType === 'portis' || walletType === 'fortmatic')"
				class="loader wallet-loader">
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { useMarketsStore } from '@/store/modules/markets';
import { useUserStore } from '@/store/modules/user';
import { useStatusStore } from '@/store/modules/status';
import { mapActions, mapState } from 'pinia';
import { useContractStore } from '@/store/modules/contract';
import { defineComponent } from 'vue'
//vuex
import Blockie from '@/components/Blockie.vue';
export default defineComponent({
	name: 'WalletInfo',
	components: {
		Blockie
	},
	props: {
		mobileDisplay: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			isScrolled: false,
			openWalletFlag: false
		};
	},
	computed: {
		...mapState(useContractStore, {
			isLoggedInMetamask: (state) => state.isLoggedInMetamask,
			walletType: (state) => state.walletType,
			loadingPortis: (state) => state.loadingPortis,
			wrongNetwork: (state) => state.wrongNetwork,
		}),
		...mapState(useStatusStore, {
			loading: (state) => state.loading,
			walletText: (state) => state.walletText,

		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
			valueSum: (state) => state.valueSum,
			portfolioLineData: (state) => state.portfolioLineData,
		}),
		...mapState(useMarketsStore, {
			selectedMarket: (state) => state.selected,
		}),

	},
	async created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	async mounted() { },
	methods: {
		...mapActions(useContractStore, {
			startWallet: 'startWallet',
			openPortisWallet: 'openPortisWallet',
		}),

		// @vuese
		// Detect if user scrolled down to transform look
		handleScroll() {
			this.isScrolled = window.scrollY > 20;
		},
		// @vuese
		// Open Portis Wallet - triggers store action
		openPortisWalletHeader() {
			this.openWalletFlag = true;
			this.openPortisWallet();
		},
		walletTextClick() {
			if (this.wrongNetwork) {
				this.startWallet({ order: true, modalShowable: true });
				return;
			}
			if (this.user?.kyc_status && this.user.kyc_status !== 'verified') this.$router.push('/settings').catch(() => { });
			else if (this.walletType === 'portis') this.openPortisWalletHeader();
			else this.startWallet({ order: true, modalShowable: true });
		}
	}
});
</script>
<style>
span.icon.custom-arrow.vert-move.is-small.grey-spaced {
	color: #4c4c4c;
	margin-right: 5px;
}

.wallet-info-container {
	display: flex;
	align-items: center;
}

.mobile-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 140px;
}

.wallet-text {
	font-weight: 500;
	color: #4c4c4c;
	margin-right: 10px;
}

@media screen and (max-width: 768px) {
	.wallet-text:not(.mobile) {
		margin-right: 25px;
	}
}

.wallet-text:hover:not(.mobile) {
	color: #000;
}

.wallet-loader {
	width: 20px !important;
	height: 20px !important;
	border-width: 3px !important;
	border-color: transparent transparent #00c386 #00c386 !important;
	margin-right: 10px;
}

.wallet-image {
	height: 26px;
}
</style>
