/**
 * Wallet login shared functionality
 */
//vuex;
import { mapState, mapActions } from 'pinia'
import { ERROR, SUCCESS } from '@/store/mutation-types';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { useStatusStore } from '@/store/modules/status';
import { defineComponent } from 'vue';


export default defineComponent({
	props: {
		collectWithdrawal: {
			type: Boolean,
			required: false,
			default: false
		},
		closeToHour: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			// Variables from FundAccount Flow

		};
	},
	computed: {
		...mapState(useContractStore, {
			network_id: state => state.network_id,
			balance: state => state.balance,
			walletType: state => state.walletType,
			provider: state => state.provider,
			isLoggedInWallet: state => state.isLoggedInWallet,
		}),
		...mapState(useUserStore, {
			user: state => state.data,
			activePortfolio: state => state.activePortfolio,
			impersonating: state => state.impersonating
		}),
		...mapState(useStatusStore, {
			walletText: state => state.walletText,
		}),
		/**
		 * Calculate the maximum transfer amount for the selected token
		 */
		transfer_max() {
			return 1
		},
		
	},
	watch: {
		
	},
	created() {

	},
	mounted() {
	
	},
	methods: {
		...mapActions(useStatusStore, {error: ERROR,
			success: SUCCESS
		}),
	

		// Resend the registration email if the users registration token has expired.
		async resendUserUnlockEmail() {
		
		},
		
	}
});
