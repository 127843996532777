<template>
	<div>


		<div class="columns">
			<div class="column is-one-third">
				<p class="navigation-item navigation-header">{{ $t('My Assets') }}</p>
				<!-- TODO -->
				<ul>
					<!-- Admin -->
					<li v-if="user && user.role === 'admin'">
						<router-link class="navigation-item admin-tabs navigation-link"
							active-class="navigation-item navigation-link-active" to="/admin/markets"
							@click.native="onLinkClick">{{ $t('ADMIN') }}</router-link>
					</li>
					<!-- Support -->
					<li v-if="user && user.role === 'support'">
						<router-link class="navigation-item  admin-tabs navigation-link"
							active-class="navigation-item navigation-link-active" to="/admin/verification"
							@click.native="onLinkClick">{{ $t('SUPPORT') }}</router-link>
					</li>
					<!-- Marketing -->
					<li v-if="user && user.role === 'marketing'">
						<router-link class="navigation-item admin-tabs navigation-link navigation-last"
							active-class="navigation-item navigation-link-active" to="/admin/templates"
							@click.native="onLinkClick">{{ $t('MARKETING') }}</router-link>
					</li>
					<li>
						<router-link class="navigation-item navigation-link"
							active-class="navigation-item navigation-link-active" to="/portfolio"
							data-testid="portfolio-route" @click.native="onLinkClick">{{ $t('PORTFOLIO')
							}}</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />

					<li>
						<router-link class="navigation-item navigation-link"
							active-class="navigation-item navigation-link-active" to="/savings"
							data-testid="savings-route" data-cy="savingsRoute" @click.native="onLinkClick">{{
								$t('SAVINGS') }}</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link class="navigation-item navigation-link navigation-last"
							active-class="navigation-item navigation-link-active " to="/funds" data-cy="fundsRoute"
							@click.native="onLinkClick">{{ $t('FUNDS') }}</router-link>
					</li>
					<li>
						<router-link class="navigation-item navigation-link navigation-last"
							active-class="navigation-item navigation-link-active " to="/rewards" data-cy="rewards-route"
							@click.native="onLinkClick">{{ $t('REWARDS') }}</router-link>
					</li>
				</ul>
			</div>

			<div class="column is-one-third">
				<p class="navigation-item navigation-header">{{ $t('MARKETS') }}</p>
				<ul>
					<li>
						<router-link class="navigation-item navigation-link"
							active-class="navigation-item navigation-link-active" to="/stock" data-testid="stock-route"
							@click.native="onLinkClick">{{ $t('STOCKS') }}</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link class="navigation-item navigation-link" to="/crypto"
							active-class="navigation-item navigation-link-active" data-cy="cryptoRoute"
							@click.native="onLinkClick">{{ $t('CRYPTOCURRENCIES') }}</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link class="navigation-item navigation-link"
							active-class="navigation-item navigation-link-active" to="/commodity"
							data-testid="commodities-route" @click.native="onLinkClick">{{ $t('COMMODITIES')
							}}</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link class="navigation-item navigation-link"
							active-class="navigation-item navigation-link-active" to="/forex" data-testid="forex-route"
							@click.native="onLinkClick">{{ $t('FOREX') }}</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link class="navigation-item navigation-link"
							active-class="navigation-item navigation-link-active" to="/index"
							data-testid="indices-route" @click.native="onLinkClick">{{ $t('INDEX') }}</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link class="navigation-item navigation-link navigation-last"
							active-class="navigation-item navigation-link-active" to="/unique"
							data-testid="unique-route" @click.native="onLinkClick">{{ $t('UNIQUES') }}
							<!-- <div class="new-tag">
						<img src="@/assets/icons/new/tada.png" class="new-icon" :alt="$t('unique.NEW')" />{{ $t('unique.NEW') }}
					</div> -->
						</router-link>
					</li>
				</ul>
			</div>

			<div class="column is-one-third">
				<p class="navigation-item navigation-header">{{ $t('ACCOUNT') }}</p>
				<ul>
					<li>
						<div
							class="lang-frame navigation-item navigation-link navigation-link regular-link dropdown-text-items transition-faster">
							<LanguageSelectorNavbar />
						</div>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link v-if="walletType !== 'metamask'" to="/wallet" id="wallet-link"
							class="navigation-item navigation-link navigation-link regular-link dropdown-text-items transition-faster"
							@click.native="openCryptoWallet">
							{{ $t('WALLET') }}
						</router-link>
					</li>
					<hr v-if="walletType !== 'metamask'" class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link id="settings-link" data-cy="settingsLink" to="/history" @click.native="onLinkClick"
							class="navigation-item navigation-link navigation-link regular-link dropdown-text-items transition-faster">
							{{ $t('HISTORY') }}
						</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link id="settings-link" data-cy="settingsLink" to="/settings"
							@click.native="onLinkClick"
							class="navigation-item navigation-link navigation-link regular-link dropdown-text-items transition-faster">
							{{ $t('SETTINGS') }}
						</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<router-link id="settings-link" to="/help" @click.native="onLinkClick"
							class="navigation-item navigation-link navigation-link regular-link dropdown-text-items transition-faster">
							{{ $t('HELP') }}
						</router-link>
					</li>
					<hr class="hr-divider-links" style="margin: 5px 20px" />
					<li>
						<a id="logout-link" data-cy="logoutLink"
							class="navigation-item navigation-link navigation-link regular-link dropdown-text-items transition-faster"
							@click="logout">
							{{ $t('LOGOUT') }}
						</a>
					</li>
				</ul>
			</div>
		</div>

		<!-- <li>
				<router-link
					class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active"
					to="/history"
					data-testid="history-route"
					data-cy="historyRoute"
					@click.native="onLinkClick"
					>{{ $t('HISTORY') }}</router-link
				>
			</li> -->

		<!-- <li v-if="user.wallet_type !=='morpherwallet'"> 
				<router-link
					class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active"
					data-testid="history-route"
					to="/migrate"
					@click.native="onLinkClick"
					>{{ $t('migration.MIGRATE') }}
				</router-link>
			</li> -->

	</div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { defineComponent } from 'vue'
//vuex
import LanguageSelectorNavbar from '@/components/LanguageSelectorNavbar.vue';

export default defineComponent({
	components: {
		LanguageSelectorNavbar
	},
	data() {
		return {
		};
	},
	computed: {
		...mapState(useUserStore, {
			user: (state) => state.data,
			payload: (state) => state.data?.payload,
			airdrop: (state) => state.data?.airdrop,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),
		...mapState(useMarketsStore, {
			mph_market: (state) => state.mph_market,
		}),

		portfolioValueUsd() {
			return 0;
		},
		availableClaims() {
			if (this.user?.airdrop?.available_claims && this.user.airdrop.available_claims > 0) {
				return true;
			} else {
				return false;
			}
		}
	},
	watch: {

	},
	async mounted() {

	},
	methods: {
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
			logout: 'logout',
		}),
		...mapActions(useContractStore, {
			openCryptoWallet: 'openCryptoWallet',
		}),
		...mapActions(useMarketsStore, {
			getMPHMarket: 'getMPHMarket',
		}),


		onLinkClick() {
			this.$emit('linkclick');
		},

	}
});
</script>

<style scoped>
.navigation-header {
	color: var(--text-text-secondary, #686475);

	/* Small Text */
	font-family: 'Manrope';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
	padding-top: 16px;
	padding-bottom: 4px;
}

.navigation-item {
	text-align: left;
	word-break: break-word;
}

.navigation-link:after {
	position: absolute;
	left: 0px;
	width: 5px;
	height: 100%;
	background: #00c386;
	content: '';
	opacity: 0;
	bottom: 0px;
	transition: all 0.3s;
}

.navigation-link-active {
	color: #000;
}

.navigation-link-active:after {
	position: absolute;
	left: 0px;
	width: 5px;
	height: 100%;
	background: #00c386;
	content: '';
	opacity: 1;
	bottom: 0px;
	transition: all 0.3s;
}

.navigation-search-container {
	margin-left: 32px;
	margin-right: 32px;
	padding-top: 3px;
	width: 60vw;
}

.new-tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 10px;
	width: 71px;
	height: 24px;
	background: #f9f5ff;
	border-radius: 16px;

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6941c6;
	margin-left: 10px;
}

.new-icon {
	width: 16px;
	height: 16px;
	margin-right: 1px;
}

.green-bubble {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 22px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	background: #dffbe9;
	border-radius: 16px;
	font-weight: 700;
	font-size: 12px;
	color: #039954;
	text-align: center;
	padding-top: 0px;
}

.navigation-link {
	color: var(--text-text-primary, #333);

	/* Normal Text Bold */
	font-family: 'Manrope';
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
	padding-top: 12px;
	padding-bottom: 12px;
}

.navigation-last {
	margin-bottom: 12px;
}


.currency-selector-label {
	color: #4c4c4c;
}



#logout-link {
	color: var(--text-text-red, #D70228);
}

.hr-divider-links {
	height: 1px;
}

.admin-tabs {

	color: blue;
}

.regular-link {
	font-size: 14px !important;
}

.navigation-link:hover {
	color: var(--Dark-Green, #039954);

}

#logout-link:hover {
	color: var(--Dark-Green, #039954);
}
</style>