<template>
	<section class="hero is-fullheight">
		<div class="container">
			<div class="field has-addons header-mb">
				<div div class="control">
					<a href="https://www.morpher.com" target="_blank" :alt="$t('MORPHER_HOMEPAGE')">
						<img src="@/assets/morpher_logo.svg" :alt="$t('MORPHER_LOGO')" />
					</a>
				</div>
			</div>
			<div class="box auth">
				<h1 class="title has-text-centered is-size-4 signup-title">{{ $t('auth.UNLOCK_TITLE') }}</h1>
				<div class="has-text-centered reject-img">
					<img src="../../assets/icons/icon_email.svg" />
				</div>
				<p v-if="!completed && !unlock_failed">{{ $t('auth.UNLOCK_DESCRIPTION') }}</p>

				<p v-if="completed && !unlock_failed">{{ $t('auth.UNLOCK_SUCCESSFUL') }}</p>

				<button v-if="completed" class="button big-outlined-button is-thick transition-faster mt-20"
					@click="startTrading()">
					{{ $t('BACK_APP') }}
				</button>

				<!-- Only render if Verify Email -->
				<div v-show="unlock_failed">
					<hr class="signup-hr" />
					<p class="mt-10">
						{{ $t('auth.UNLOCK_EXPIRED') }}<br />
						<span v-html="$t('auth.UNLOCK_EXPIRED_DESCRIPTION', {
							link: 'mailto:contact@help.morpher.com'
						})
							"></span>
					</p>
				</div>
				<button v-if="unlock_failed && !user_unlock_processing"
					class="button big-outlined-button is-thick transition-faster mt-20" data-testid="resendEmail"
					@click="resendUserUnlockEmail()">
					{{ $t('RESEND_EMAIL') }}
				</button>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
//vuex
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { ERROR } from '@/store/mutation-types';
import { accessTokenName, refreshTokenName } from '@/helpers/utils';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { defineComponent } from 'vue';

/* @group Authentication */
/*
  <h4> <b> Unlock User Account </b> </h4>
  <br> - Unlock the user account so that deposits and withdrawals are woring again.
*/
export default defineComponent({
	name: 'Unlock',

	data() {
		return {
			title: 'Unlock Account',
			unlockInProgress: false,
			unlock_failed: false,
			completed: false,
			user_unlock_processing: false,
			walletError: '',
		};
	},
	computed: {
		...mapState(useUserStore, {
			activePortfolio: (state) => state.activePortfolio,
			user: (state) => state.data,
			impersonating: (state) => state.impersonating,
		}),
		...mapState(useContractStore, {
			eth_address: (state) => state.eth_address,
			walletType: (state) => state.walletType,
		}),

	},
	watch: {
		user() {
			this.initialize();
		}
	},
	mounted() {
		this.initialize();
	},

	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
		}),
		...mapActions(useUserStore, {
			unlockWithdraw: 'unlockWithdraw',
			checkWithdrawUnlock: 'checkWithdrawUnlock',
		}),


		// Resend the unlock email if the users unlock token has expired.
		async resendUserUnlockEmail() {
			this.user_unlock_processing = true;

			// Verify email action
			await this.checkWithdrawUnlock({
				user_id: this.user?.id
			});
		},

		// Unlock the uer account
		async initialize() {
			const user = await this.unlockWithdraw({});

			if (user) {
				this.completed = true;
			} else {
				this.unlock_failed = true;
			}
		},

		// Load the application after the account was unlocked.
		async startTrading() {
			const token = localStorage.getItem(accessTokenName);
			const refreshToken = localStorage.getItem(refreshTokenName);

			if (token === null || refreshToken === null) {
				this.$router.push('/login').catch(() => { });
			} else {
				this.$router.push('/').catch(() => { });
			}
		}
	}
});
</script>

<style scoped>
.is-size-8 {
	font-size: 14px !important;
}

.policies {
	margin: 3rem auto 0.75rem auto;
}

.policies a {
	color: #666;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.policies a:hover {
	color: #000;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media only screen and (max-width: 768px) {
	.policies {
		width: 90%;
	}
}
</style>
