<template>
	<section class="transfer-modal">
		<b-modal :modelValue="showMainchainTransferModal" has-modal-card @close="close">
			<form @submit.prevent="">
				<div class="modal-card terminal send">
					<h2 class="custom-title mb-0">{{ $t('transfer.SEND_TOKENS_TITLE') }}</h2>
					<p>{{ $t('transfer.SEND_TOKENS_DESCRIPTION') }}</p>

					<div class="mt-15 settings-data user-details has-text-left">
						<div :class="{
							'details select-custom': true,
							active: transferCurrency === 'eth'
						}" @click="transferCurrency = 'eth'">
							<div class="circle">
								<div class="inner"></div>
							</div>
							<div class="data">
								<p class="is-text-light has-font-rubik">{{ $t('transfer.ETH_BALANCE') }}</p>
								<p class="has-text-weight-medium is-size-5 mt-1">
									{{ roundFormatter(balance.eth_mainchain / Math.pow(10, 18)) }} ETH
									<span class="usd-balance">${{ getUSDValue(balance.eth_mainchain / Math.pow(10, 18),
										true) }} USD</span>
								</p>
							</div>
						</div>
						<div :class="{
							'details select-custom': true,
							active: transferCurrency === 'mph'
						}" @click="transferCurrency = 'mph'">
							<div class="circle">
								<div class="inner"></div>
							</div>
							<div class="data">
								<p class="is-text-light has-font-rubik">{{ $t('transfer.MPH_BALANCE') }}</p>
								<p class="has-text-weight-medium is-size-5 mt-1">
									{{ roundFormatter(balance.mph_mainchain / Math.pow(10, 18)) }} MPH
									<span class="usd-balance">${{ getUSDValue(balance.mph_mainchain / Math.pow(10, 18))
										}} USD</span>
								</p>
							</div>
						</div>
					</div>

					<p v-if="balance.mph_mainchain && balance.mph_mainchain < Math.pow(10, 15)" class="is-size-7 mt-2">
						{{ $t('transfer.DO_NOT_SEE_FUNDS') }}
						<span class="link transition-faster" @click="goToWithdraw()">{{ $t('transfer.WITHDRAW_FIRST')
							}}</span>
					</p>

					<div class="custom-field mt-10">
						<label class="label"><i class="fas fa-coins is-size-14"></i>
							{{ transferCurrency === 'eth' ? 'ETH' : 'MPH' }}
							{{ $t('AMOUNT') }}
							<a class="transition-faster" @click="setMax()">({{ $t('MAX') }})</a>
							<span class="usd-label">${{ getUSDValue(total_crypto, transferCurrency === 'eth') }}
								USD</span></label>
						<div class="control">
							<b-input ref="usd_amount_input" v-model="total_crypto" inputmode="decimal"
								:disabled="transfer_loading" placeholder="0.00"
								@keypress.native="isNumber($event, total_crypto)" :class="{
									'bad-input': wrong_amount
								}" @focus="wrong_amount = false" />
						</div>
					</div>

					<div class="custom-field mt-10 mb-0">
						<label class="label"><i class="fas fa-paper-plane is-size-14"></i>
							{{ $t('transfer.DESTINATION') }}
							<b-tooltip :label="$t('transfer.SEND_TOOLTIP')" size="is-medium" multilined
								position="is-top" type="is-dark" animated>
								<a class="transition-faster lighter-text">
									<i class="fas fa-question-circle" />
								</a>
							</b-tooltip>
						</label>
						<div class="control">
							<b-input ref="dest_eth_address" v-model="dest_address" inputmode="decimal"
								:disabled="transfer_loading" :placeholder="$t('transfer.SEND_PLACEHOLDER')" :class="{
									'bad-input': wrong_address
								}" @focus="wrong_address = false" />
						</div>
					</div>

					<!-- Unstoppable Domain Lookup -->
					<p class="is-size-14 has-text-left" v-if="dest_address_resolved">
						<i class="fas fa-check is-size-14 has-text-green" />
						{{ formatEthAddress(dest_address_resolved) }}
					</p>

					<div v-if="inputError" class="error mt-10 mb-10">
						<p>⚠️ {{ inputError }}</p>
					</div>
					<div v-else class="mb-15" />

					<div class="is-flex step-btns">
						<button class="button big-button is-buy transition-faster"
							:class="{ 'is-loading': transfer_loading }" @click="confirmTransfer">
							{{ $t('CONTINUE') }}
						</button>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMarketsStore } from '@/store/modules/markets';
import { useUserStore } from '@/store/modules/user';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import EventBus from '@/store/event-bus';
import { copyToClipboard, resolveUnstoppable } from '@/helpers/utils';
import { PortfolioService } from '@/services/PortfolioService';
import { morpherTokenAbi } from '@/contracts/abis';
import { ERROR, SUCCESS } from '@/store/mutation-types';
import { MarketService } from '@/services/MarketService';
import { useStatusStore } from '@/store/modules/status';
import { mapActions, mapState } from 'pinia';
import { isAddress, toHex, type WalletActions, type WalletClient } from 'viem';
import { getChainMain, getMainchainToken } from '@/helpers/viem';
import type { TAddress } from '@/types/graphql-global-types';
/* @group Components_Modals */
/*
  <h4> <b> Modal which shows that ethereum network is wrong </b> </h4>
  <br> Example: https://sketch.cloud/s/49kjZ/52VOwnx
*/
export default defineComponent({
	name: 'MainchainTransferModal',
	data() {
		return {
			sidechainURL: 'https://sidechain.morpher.com',
			transfer_loading: false,
			total_crypto: null as any,
			transferCurrency: 'eth',
			dest_address: null as any,
			dest_address_resolved: null as any,
			lookup_timeout: null as null | NodeJS.Timeout | number,
			inputError: '',
			wrong_address: false,
			wrong_amount: false,
			eth_market: {} as any
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showMainchainTransferModal: (state) => state.showMainchainTransferModal,
		}),
		...mapState(useContractStore, {
			transactOnNetwork: (state) => state.transactOnNetwork,
			balance: (state) => state.balance,
			walletClient: (state) => state.walletClient,
			walletClientMainchain: (state) => state.walletClientMainchain,
			morpherwallet: (state) => state.morpherwallet,
		}),
		...mapState(useUserStore, {
			activePortfolio: (state) => state.activePortfolio,
		}),
		...mapState(useMarketsStore, {
			markets: (state) => state,
			mph_market: (state) => state.mph_market,
		}),

		eth_transfer_cost() {
			if (this.balance && this.balance.gas_price && this.balance.gas_price > 0) {
				return this.balance.gas_price * 21000;
			} else {
				return 0;
			}
		},
		mph_transfer_cost() {
			if (this.balance && this.balance.gas_price && this.balance.gas_price > 0) {
				return this.balance.gas_price * 59000;
			} else {
				return 0;
			}
		}
	},
	watch: {
		showMainchainTransferModal(nv) {
			if (nv) {
				this.inputError = '';
				this.transferCurrency = 'eth';
				this.total_crypto = null;
				this.transfer_loading = false;
				this.dest_address = null;

				if (!this.walletClientMainchain) {
					this.connectToMainchain();
				}
			}
		},
		dest_address(nv) {
			if (nv) {
				if (nv.length > 2 && nv.substring(0, 2) !== '0x' && nv.includes('.')) {
					if (this.lookup_timeout) {
						clearTimeout(this.lookup_timeout);
						this.lookup_timeout = null;
					}
					this.lookup_timeout = setTimeout(async () => {
						let address = null;
						try {
							address = await resolveUnstoppable(nv, 'ETH');
							this.dest_address_resolved = address;
							if (this.inputError === this.$t('transfer.ERROR_UNSTOPPABLE_INVALID') || this.inputError === this.$t('transfer.ERROR_DESTINATION_EMPTY') || this.inputError === this.$t('transfer.ERROR_DESTINATION_INVALID')) {

								this.inputError = '';
							}
						} catch (err) {
							this.dest_address_resolved = null;
						}
					}, 500);

					//
				}
			} else {
				this.dest_address_resolved = null;
			}

			if (nv && nv.includes('0x') && nv.length == 42 && this.inputError === this.$t('transfer.ERROR_UNSTOPPABLE_INVALID') || this.inputError === this.$t('transfer.ERROR_DESTINATION_EMPTY') || this.inputError === this.$t('transfer.ERROR_DESTINATION_INVALID')) {
				const check_address = isAddress(nv);
				if (check_address) {
					this.inputError = '';
				}
			}

		},
		total_crypto(nv) {
			if (this.total_crypto && this.total_crypto > 0 && this.inputError === this.$t('transfer.ERROR_AMOUNT_EMPTY')) {
				this.inputError = '';
			}
		}

	},
	async mounted() {
		this.inputError = '';
		this.transferCurrency = 'eth';
		this.total_crypto = null;
		this.transfer_loading = false;
		this.dest_address = null;

		if (!this.walletClientMainchain) {
			this.connectToMainchain();
		}

		// call getBalance in order to get the wallet balances from the mainchain and sidechain networks
		const result = await PortfolioService.getBalance({ eth_address: this.activePortfolio?.eth_address, include_mainchain: true });

		// set the balances to the back end return values
		if (result && result.data && result.data.getBalance) {
			await this.setBalance(result.data.getBalance);
		}


		// Load the eth market
		const loadMarket = window.setInterval(() => {
			this.markets.data.forEach(market => {
				if (market?.symbol?.toLowerCase() === 'eth' && market?.type?.toLowerCase() === 'crypto') {
					this.eth_market = market;

					this.eth_market.subscribe = () =>
						MarketService.subscribeToMarket(this.eth_market, 'MainchainTransferModal', data => {
							this.eth_market.close = data?.data?.updateMarket.close;
							this.eth_market.change = data?.data?.updateMarket.change;
							this.eth_market.change_percent = data?.data?.updateMarket.change_percent;
						});

					this.eth_market.subscribe();
				}
			});
			if (this.eth_market.close) {
				window.clearInterval(loadMarket);
			}
		}, 500);
	},
	unmounted() {
		if (this.eth_market && this.eth_market.subscription) {
			MarketService.unsubscribeFromMarket(this.eth_market, 'MainchainTransferModal');
		}
	},

	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
			success: SUCCESS

		}),
		...mapActions(useModalsStore, {
			toggleMainchainTransferModal: 'toggleMainchainTransferModal',
		}),
		...mapActions(useContractStore, {
			connectToMainchain: 'connectToMainchain',
			setBalance: 'setBalance',
		}),

		close() {
			EventBus.$emit('resetState');
			this.toggleMainchainTransferModal(false);
		},
		copyTextToClipboard() {
			copyToClipboard(this.sidechainURL, this);
		},
		cancelTransfer() {
			this.transfer_loading = false;
			this.total_crypto = null;
			this.transferCurrency = 'eth';
			this.dest_address = null;
			this.close();
		},
		async waitForMainchain() {
			if (this.walletClientMainchain) {
				this.confirmTransfer();
			} else {
				setTimeout(this.waitForMainchain, 1000);
			}
		},
		async confirmTransfer() {
			if (!this.activePortfolio?.eth_address) {
				return
			}
			this.inputError = '';
			this.wrong_address = false;
			this.wrong_amount = false;
			this.transfer_loading = true;
			if (!this.dest_address) {
				this.inputError = this.$t('transfer.ERROR_DESTINATION_EMPTY');
				this.transfer_loading = false;
				this.wrong_address = true;
				return;
			}

			let destination_address = this.dest_address;

			if (destination_address.length > 2 && destination_address.substring(0, 2) !== '0x' && destination_address.includes('.')) {
				if (this.lookup_timeout) {
					clearTimeout(this.lookup_timeout);
					this.lookup_timeout = null;
				}

				let address = null;
				try {
					address = await resolveUnstoppable(destination_address, 'ETH');
					this.dest_address_resolved = address;
				} catch (err) {
					this.dest_address_resolved = null;

					this.inputError = this.$t('transfer.ERROR_UNSTOPPABLE_INVALID');
					this.transfer_loading = false;
					this.wrong_address = true;
					return;
				}
			} else {
				this.dest_address_resolved = null;
			}

			if (this.dest_address_resolved) {
				destination_address = this.dest_address_resolved;
			}

			if (destination_address.toLowerCase() === this.activePortfolio?.eth_address?.toLowerCase()) {
				this.inputError = this.$t('transfer.ERROR_DESTINATION_ME');
				this.transfer_loading = false;
				this.wrong_address = true;
				return;
			}

			if (!this.total_crypto || this.total_crypto <= 0) {
				this.inputError = this.$t('transfer.ERROR_AMOUNT_EMPTY');
				this.transfer_loading = false;
				this.wrong_amount = true;
				return;
			}

			if (!this.walletClientMainchain) {
				setTimeout(this.waitForMainchain, 1000);
				return;
			}

			const check_address = isAddress(destination_address);
			if (!check_address) {
				this.inputError = this.$t('transfer.ERROR_DESTINATION_INVALID');
				this.transfer_loading = false;
				this.wrong_address = true;
				return;
			}

			// call getBalance in order to get the wallet balances from the mainchain and sidechain networks
			let result = await PortfolioService.getBalance({ eth_address: this.activePortfolio?.eth_address, include_mainchain: true });

			// set the balances to the back end return values
			if (result.data.getBalance) {
				const tokenContract = await getMainchainToken(this.walletClientMainchain);
				await this.setBalance(result.data.getBalance);

				const block = await tokenContract.public_client.getBlock()

				const maxPriorityFeePerGas = BigInt('2000000000')
				const baseFees = block.baseFeePerGas || BigInt(0)
				const maxFeePerGas = (baseFees * BigInt(2)) + BigInt(maxPriorityFeePerGas)


				if (this.transferCurrency === 'eth') {
					const gas = 21000;
					const transfer_amount = this.total_crypto * Math.pow(10, 18);
					if (transfer_amount > result.data.getBalance.eth_mainchain) {
						this.inputError = this.$t('transfer.NOT_ENOUGH_MPH_MAINCHAIN');
						this.transfer_loading = false;
						this.wrong_amount = true;
						return;
					}

					try {

						const maxFeePerGas_calc = (baseFees * BigInt(2)) + BigInt(maxPriorityFeePerGas)

						const balance_calc = BigInt(this.balance.eth_mainchain)
						const gas_calc = BigInt(gas)



						let max = balance_calc - (gas_calc * maxFeePerGas_calc);

						let value = BigInt(Number(this.total_crypto) * 10 ** 18)


						if (value > max) {
							value = max
						}

						const chain = getChainMain()

						await (this.walletClientMainchain as WalletClient).sendTransaction({
							to: destination_address.toLowerCase(),
							account: this.activePortfolio?.eth_address?.toLowerCase() as TAddress,
							value: value,
							gas: gas_calc,
							gasLimit: gas,
							chain,
							type: 'eip1559',
							maxFeePerGas,
							maxPriorityFeePerGas
						});
					} catch (err) {
						console.log('err', err)
						this.inputError = this.$t('SOMETHING_WENT_WRONG');
						this.error({ message: 'ETH_TRANSFER_CANCELLED' });
						this.transfer_loading = false;
						this.cancelTransfer();
						return;
					}

					result = await PortfolioService.getBalance({ eth_address: this.activePortfolio?.eth_address, include_mainchain: true });

					// set the balances to the back end return values
					if (result.data.getBalance) {
						await this.setBalance(result.data.getBalance);
						this.$buefy.dialog.alert({
							title: this.$t('transfer.TRANSFER_COMPLETE'),
							message: this.$t('transfer.TRANSFER_COMPLETE_DESCRIPTION', {
								currency: 'ETH'
							}),
							confirmText: this.$t('OK')
						});
					}

					this.cancelTransfer();
				} else {
					const transfer_amount = this.total_crypto * Math.pow(10, 18);
					if (transfer_amount > result.data.getBalance.mph_mainchain) {
						this.inputError = this.$t('transfer.NOT_ENOUGH_MPH_MAINCHAIN');
						this.transfer_loading = false;
						this.wrong_amount = true;
						return;
					}

					// Process MPH transfer on the mainchain


					// Link to the token contract

					try {

						const gas = 85000;

						const transfer_amount = BigInt(Number(this.total_crypto) * Math.pow(10, 18));

						try {

							let chain = getChainMain()
							const transaction_hash = await tokenContract.contract.write.transfer([destination_address.toLowerCase(),
								transfer_amount],
								{
									account: this.activePortfolio?.eth_address?.toLowerCase() as TAddress,
									//mph_value: transfer_amount,
									gas: BigInt(gas),
									//gasLimit: BigInt(gas),
									chain,
									type: 'eip1559',
									maxFeePerGas,
									maxPriorityFeePerGas
								})

							//console.log('hash', hash);

							let receipt = await tokenContract.public_client.waitForTransactionReceipt({ hash: transaction_hash })

							this.transfer_loading = false;
							result = await PortfolioService.getBalance({ eth_address: this.activePortfolio?.eth_address, include_mainchain: true });

							// set the balances to the back end return values
							if (result.data.getBalance) {
								await this.setBalance(result.data.getBalance);
								this.$buefy.dialog.alert({
									title: this.$t('transfer.TRANSFER_COMPLETE'),
									message: this.$t('transfer.TRANSFER_COMPLETE_DESCRIPTION', {
										currency: 'MPH'
									}),
									confirmText: this.$t('OK')
								});
							}

							this.cancelTransfer();
						} catch (err: any) {

							if (err.message === 'The transaction was cancelled by the user') {
								this.inputError = this.$t('transfer.USER_CANCELED_TRANSFER');
								this.error({ message: 'MPH_TRANSFER_CANCELLED' });
								this.transfer_loading = false;
								this.cancelTransfer();
							} else {
								this.logSentryError('Error transferring token:' + err.message);
								this.inputError = this.$t('SOMETHING_WENT_WRONG');
								this.transfer_loading = false;
							}
						}

					} catch (err) {
						this.error({ message: 'MPH_TRANSFER_CANCELLED' });
						this.transfer_loading = false;
						this.cancelTransfer();
						return;
					}
				}
			} else {
				this.transfer_loading = false;
				return;
			}
		},
		goToWithdraw() {
			this.close();
			this.$router.push('/funds/withdraw').catch(() => { });
		},
		async setMax() {
			let max = 0;

			if (this.transferCurrency === 'eth') {

				const gas = 21000;



				if (this.walletClientMainchain) {
					const tokenContract = await getMainchainToken(this.walletClientMainchain);

					const block = await tokenContract.public_client.getBlock()

					const maxPriorityFeePerGas = toHex('2000000000')
					const baseFees = block.baseFeePerGas || BigInt(0)
					const maxFeePerGas_calc = (baseFees * BigInt(2)) + BigInt(maxPriorityFeePerGas)

					const balance_calc = BigInt(this.balance.eth_mainchain)
					const gas_calc = BigInt(gas)



					let max = balance_calc - (gas_calc * maxFeePerGas_calc);

					this.total_crypto = this.floorRound(Number(max.toString()) / Math.pow(10, 18), 8);
					return

				} else {

					const ethBalance = this.floorRound(
						Number(this.balance.eth_mainchain / Math.pow(10, 18)) - this.eth_transfer_cost / Math.pow(10, 9),
						8
					);
					if (Number(ethBalance) >= 0) {
						max = Number(ethBalance);
					}
				}
			} else {
				const mphBalance = this.floorRound(Number(this.balance.mph_mainchain / Math.pow(10, 18)), 2);

				if (Number(mphBalance) >= 0) {
					max = Number(mphBalance);
				}
			}

			this.total_crypto = max;
		},
		getUSDValue(number: number, isETH = false) {
			let multiplier = this.mph_market ? (this.mph_market.close || 0) : 0;

			if (isETH) multiplier = this.eth_market ? this.eth_market.close : 0;

			const usd_value = number * multiplier;
			return this.usdFormatter(usd_value);
		}
	}
});
</script>
<style lang="scss">
.send {
	padding: 20px;
}

.copy-btn {
	height: 40px;
	width: 3rem;
}

.step-width {
	width: 20%;
}

.input-eth input {
	background-color: inherit;
	font-size: 14px;
	padding-right: 5px;
	font-weight: 500;
}

.usd-field {
	display: flex;
	flex-direction: column;
	/* padding-right: 1rem; */
}

.flex-inputs {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.currency-text {
	position: relative;
	margin-left: -80px;
	font-size: 20px;
	font-family: 'Rubik', sans-serif;
}

.purchase-currency-selector {
	position: absolute;
	right: 0;
	background-color: transparent;
	margin-left: 0;
	margin-right: 18px;
}

.purchase-currency-selector>span {
	background-color: transparent;
}

.purchase-currency-selector>span>select {
	border: 0;
	font-size: 20px;
	font-family: 'Rubik', sans-serif;
	background-color: transparent;
	padding-top: 0;
}

.purchase-currency-selector select {
	padding-right: 0 !important;
	width: 90px;
}

.purchase-currency-selector select option {
	padding: 0 !important;
}

.sprite-container {
	position: relative;
	margin-left: -65px;
	display: flex;
}

.single-sprite {
	margin-left: -35px;
}

.sprite {
	display: flex;
	align-items: center;
	margin-right: 5px;
}

.eth-break-word {
	word-wrap: break-word;
}

.help-text {
	color: #fc6404;
	margin-top: -10px;
	font-weight: 500;
	font-size: 14px;
}

.has-text-green {
	color: #00c386;
}

.info-fields {
	display: flex;
	flex-direction: column;
}

.width-adjust {
	width: calc(100% - 0.25px);
}

.max-btn {
	height: 26px;
	font-size: 14px;
	font-weight: 600;
	background-color: #d3e8f8;
	border-color: #d3e8f8;
	border-radius: 7px;
}

.max-btn:hover {
	background-color: #91c6ed;
	border-color: #91c6ed;
}

.max-btn:active,
.max-btn:focus {
	background-color: #7abae9;
	border-color: #7abae9;
}

.max-btn.is-muted-buy {
	background-color: #b2e6d8;
	border-color: #b2e6d8;
}

.max-btn.is-muted-buy:hover {
	background-color: #80d5c0;
	border-color: #80d5c0;
}

.max-btn.is-muted-buy:active,
.max-btn.is-muted-buy:focus {
	background-color: #65ccb3;
	border-color: #65ccb3;
}

.max-btn.is-blue {
	height: 30px;
}

.max-btn.is-purple {
	height: 30px;
	background-color: #c8b9e8;
	border-color: #c8b9e8;
}

.max-btn.is-purple:hover {
	background-color: #b49fdf;
	border-color: #b49fdf;
}

.max-btn.is-purple:active,
.max-btn.is-purple:focus {
	background-color: #a58cd9;
	border-color: #a58cd9;
}

.transfer-modal {
	font-size: 14px;
}

.custom-title {
	font-weight: 500 !important;
	font-size: 1.4rem !important;
	margin-bottom: 0px !important;
	font-family: 'Rubik', sans-serif;
}

.divider {
	width: 100%;
	height: 2px;
	background-color: #e6e6e6;
	margin: 20px 0;

	&.thick {
		height: 1px;
		margin: 15px 0;
	}

	&.just-space {
		height: 1px;
		background: transparent;
		margin: 0;
		margin-top: 19px;
	}

	&.vertical {
		height: 16px;
		width: 1px;
		margin: 0 10px;
		background-color: #363636;
	}
}

.select-custom {
	position: relative;
	transition: 200ms;
	cursor: pointer;

	.circle {
		content: '';
		position: absolute;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
		width: 14px;
		height: 14px;
		border-radius: 10px;
		border-width: 1px;
		border-style: solid;
		border-color: #979797;
		transition: 200ms;

		.inner {
			width: 10px;
			height: 10px;
			border-radius: 10px;
			margin: 1px;
			background-color: transparent;
			transition: 200ms;
		}
	}

	>.data {
		padding-left: 24px;
	}

	&.active {
		.circle {
			border-color: #228cdb;

			.inner {
				background-color: #228cdb;
			}
		}
	}
}

.settings-data {
	border: 1px solid #eae9ed;
	border-radius: 13px;
	box-shadow: 0px 2px 4px 0px rgba(#363636, 0.1);

	.settings-link {
		font-size: 1.1rem;
		justify-content: center;
		cursor: pointer;
		padding: 15px;

		+.settings-link {
			border-top: 2px solid #eae9ed;
		}

		.text {
			margin-left: 10px;
		}

		.icon {
			margin-left: auto;
		}

		&.email-password {
			justify-content: flex-start;

			.data {
				font-size: 1rem;
				text-align: left;
				justify-content: flex-start;
				margin-right: 10px;

				p {
					line-height: 1rem;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.link {
				margin-left: auto;

				>div {
					>svg {
						display: block;
						font-size: 26px;
					}
				}
			}
		}
	}
}

.user-details {
	.details {
		padding: 15px;

		p {
			line-height: 1rem;
		}

		+.details {
			border-top: 2px solid #eae9ed;
		}
	}

	.copy-icon {
		>svg {
			cursor: pointer;
			color: #ababac;
			font-size: 16px;
			transition: 200ms;

			&:hover {
				color: #363636;
			}

			&:active,
			&:focus {
				color: #363636;
			}
		}
	}
}

.is-text-light {
	color: #6b6b6b;
}

.custom-field {
	text-align: left;

	.label {
		margin-bottom: 5px !important;
		font-size: 0.9rem;
		font-weight: 500;
	}
}

.error {
	text-align: left;
	margin-bottom: 20px;
	font-size: 14px;
}

.important-font {
	font-family: 'Rubik', 'Roboto', sans-serif;
}

.link {
	cursor: pointer;
	color: #228cdb;

	&:hover {
		color: #2c3e50;
	}
}

.usd-balance {
	font-size: 14px;
	font-weight: 400;
}

.usd-label {
	font-weight: 400;
	float: right;
}
</style>
