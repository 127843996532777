<template>
	<div class="has-font-manrope">
		<div class="deposit-content has-text-left">
			<div v-if="isMobile">
				<div style="height: 10px" />
				<button class="button back-btn hover-position" @click="closeModal()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
			</div>

			<div class="deposit-input">
				<div v-if="os !== 'ios'" class="currency-symbol" :style="{ order: currencySide }">{{
					currencySymbol.toUpperCase() }}</div>
				<input v-if="os !== 'ios'" ref="deposit_amount" v-model="deposit_amount" class="deposit-amount"
					v-autowidth="{ maxWidth: currencySide == 1 ? '310px' : '275px', minWidth: '20px', comfortZone: '1ch' }"
					style="order: 2" />
				<input v-else ref="deposit_amount" v-model="deposit_amount" class="deposit-amount"
					style="width: 100% " />
				<button class="button bonus end-pos" @click="showDepositBonus()">
					<img src="@/assets/icons/deposit_gift_icon.svg" />
					<transition name="fade" mode="out-in">
						<div v-if="numBonus > 0" class="badges" key="1">
							{{ numBonus }}
						</div>
					</transition>
				</button>

			</div>

			<div class="deposit-message">
				<p v-if="balanceLoaded && deposit_amount && deposit_amount !== '0' && deposit_amount_number() > maxLeft"
					class="deposit-message-error">
					{{
						$t('funds.DEPOSIT_CANNOT_EXCEED', {
							symbol: currencySymbol,
							amount: formatAmount(maxLeft)
						})
					}}
					<a class="has-text-weight-medium transition-faster" @click="updateUSD(maxLeft)">{{
						$t('funds.DEPOSIT_MAX') }}</a>
				</p>
				<p v-else-if="deposit_error" :class="{ 'deposit-message-error': deposit_error }">
					{{ deposit_error_message }}
				</p>

				<div v-else-if="deposit_amount && deposit_amount !== '0' && deposit_amount_number() > 0">
					{{ roundFormatter(mphValue) }} MPH
					<div v-if="funds.provider == 'paypal'">
						<p class="provider-bonus">
							{{ $t('funds.BONUS_PERCENT') }}
							{{ roundFormatter(mphValue / 10) }} MPH
						</p>
					</div>
				</div>
				<div v-else>
					{{ deposit_message }}
					<div v-if="funds.provider == 'paypal'">
						<p class="provider-bonus">
							<i class="fas fa-gift provider_gift_icon" /> {{ $t('funds.EARN_UP_TO') }} {{ currencySymbol
							}}{{ formatAmount(maxLeft / 10) }}
						</p>
					</div>
				</div>
			</div>




			<div class="deposit_section mt-15">
				<div class="provider-header" @click="showPaymentMethod(true)">
					<div class="centered-flex-gap gap-8" v-if="funds.currency">
						<img v-if="funds.currency"
							:src="imageURL('@/img/funds/tokens/' + funds.currency.toLowerCase() + '.svg')"
							class="token-icon" />

						<div class="ml-5px">
							<p class="">{{ funds.currency.toUpperCase() }}</p>

							<p class="provider-detail">
								{{
									$t('funds.DEPOSIT_CURRENCY')
								}}
							</p>
						</div>
					</div>

					<img src="@/assets/funds/arrow-right.svg" />
				</div>

				<div class="wallet-select" @click="showPaymentMethod(false)">
					<div class="centered-flex-gap gap-8" v-if="funds.provider">
						<img v-if="funds.provider && funds.provider === 'advcash'"
							:src="imageURL(`@/img/funds/volet.svg`)" class="provider-icon" />
						<img v-else-if="funds.provider && funds.provider !== 'moonpay'"
							:src="imageURL(`@/img/funds/${funds.provider}.svg`)" class="provider-icon" />
						<img v-else-if="funds.provider && funds.provider === 'moonpay'"
							:src="imageURL(`@/img/funds/${funds.provider}.png`)" class="provider-icon"
							alt="payment provider" />

						<div class="ml-5px">
							<p class="">{{ $t('providers.' + funds.provider.toUpperCase()) }}</p>
							<p class="provider-detail">
								{{
									$t('funds.PAYMENT_PROVIDER')
								}}
							</p>

						</div>
					</div>

					<img src="@/assets/funds/arrow-right.svg" />
				</div>
			</div>
			<div class="deposit-section no-shadow mt-15">
				<div class="is-flex is-align-items-center">
					<b-checkbox v-model="deposit_consent" class="mr-0" />
					<span>{{ $t('funds.CONSENT_TO') }}
						<a class="transition-faster deposit-link" @click="showPurchaseAgreement()">{{
							$t('funds.TOKEN_PURCHASE_AGREEMENT') }}</a>
					</span>
				</div>
			</div>

			<button data-cy="withdrawFunds" class="button plain-primary-btn is-fullwidth mt-20"
				:disabled="!allowDeposit || depositDisabled" :class="{ 'is-loading': depositWaiting }"
				@click="openPaymentType()">
				{{ $t('DEPOSIT') }}
			</button>
			<div v-if="depositDisabled" class="mt-2 is-flex is-align-items-center">
				<i class="fas fa-exclamation-triangle is-size-6"></i>
				<p class="ml-2 is-size-7">Due to the Ethereum Update, deposits are temporarily disabled.</p>
			</div>
		</div>
	</div>
</template>

<style scoped src="../../assets/css/payment.css"></style>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useFundsStore } from '@/store/modules/funds';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { getCurrencyList, default_provider } from '@/helpers/funding';
import { parse } from '@/helpers/uaparser';

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'Deposit',
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		maxLeft: {
			type: Number,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		},
		totalWithdraw: {
			type: Number,
			required: true
		},
		balanceLoaded: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			deposit_amount: '',
			deposit_message: '',
			deposit_consent: false,
			deposit_error: false,
			number_seperators: {} as {
				decimal: string,
				thousand: string
			},
			deposit_error_message: '',
			isMobile: false,
			os: '',
			depositWaiting: false,
			depositDisabled: false
		};
	},
	computed: {
		...mapState(useContractStore, {
			balance: (state) => state.balance,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useFundsStore, {
			funds: (state) => state,
		}),
		mphValue() {
			if (this.deposit_amount && this.deposit_amount !== '0' && this.deposit_amount_number() > 0) {
				let value = ((this.deposit_amount_number()) * this.currencyExchange) / this.balance.mph_rate
				if (value < 0) {
					value = 0;
				}
				return value;
			} else {
				return 0;
			}
		},
		currencyOffset() {
			if (!this.deposit_amount) {
				if (this.funds.provider !== 'coinbase') {
					return 152 - 14;
				} else {
					return 182 + 14;
				}
			}
			let amount = this.deposit_amount.toString();
			let commacount = amount.split(',').length - 1; //3
			let dotcount = amount.split('.').length - 1; //3

			amount = amount.replace(',', '').replace('.', '');

			if (this.funds.provider !== 'coinbase') {
				const offset = 152 - 14 * amount.length - 5 * (commacount + dotcount);

				return offset;
			} else {
				const offset = 182 + 14 * amount.length + 5 * (commacount + dotcount);

				return offset;
			}
		},
		currencySide() {
			if (this.funds.provider === 'coinbase') {
				return 3;
			} else {
				return 1;
			}
		},
		allowDeposit() {
			if (
				((this.deposit_amount &&
					this.deposit_amount !== '0' &&
					this.deposit_amount_number() > 0) || this.funds.provider == 'sepa') &&
				this.funds.purchaseAgreementConsent
			) {
				if (this.funds.provider == 'paypal' && this.deposit_amount_number() < 50) {
					this.deposit_error = true;
					this.deposit_error_message = this.$t('MINIMUM_DEPOSIT', { amount: 50, symbol: this.currencySymbol });
					return false;
				} else if (this.funds.provider == 'moonpay' && this.deposit_amount_number() < 50) {
					this.deposit_error = true;
					this.deposit_error_message = this.$t('MINIMUM_DEPOSIT', { amount: 50, symbol: this.currencySymbol });
					return false;
				} else {
					this.deposit_error = false;
				}
				return true;
			}

			this.deposit_error = false;
			return false;
		},
		numBonus() {
			const deposit_amount = this.deposit_amount_number() * this.currencyExchange;
			if (deposit_amount && deposit_amount <= this.maxLeft && deposit_amount > (100 + this.totalWithdraw)) {
				return Math.floor((deposit_amount - this.totalWithdraw) / 100)
			}
			return 0
		}
	},
	watch: {
		async deposit_consent(nv) {
			this.updateFunds({ purchaseAgreementConsent: nv });

			if (this.deposit_error) {
				this.checkDepositError();
			}
		},
		deposit_amount() {
			if (!this.deposit_amount) {
				return this.deposit_amount;
			}

			this.deposit_amount = this.formatAmount(this.deposit_amount);

			if (this.deposit_error) {
				this.checkDepositError();
			}


		}
	},
	unmounted() {
		window.removeEventListener('resize', this.handleResize);
	},
	async created() {
		this.getNumberSeparators();
		window.addEventListener('resize', this.handleResize);
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	async mounted() {
		const browser = parse(navigator.userAgent);
		this.os = browser.os.toLowerCase();
		if (this.os == 'ipad' || this.os == 'iphone') {
			this.os = 'ios';
		}

		this.deposit_amount = '';
		this.deposit_amount = String(this.funds.amount || 0);
		this.deposit_consent = this.funds.purchaseAgreementConsent || false;

		setTimeout(() => {
			this.focusDepositAmount();
		}, 50);

		this.setDepositMaxMessage();

		const cash_filtered: any[] = [];
		getCurrencyList().cash.forEach(method => {
			cash_filtered.push(method.symbol.toLowerCase());
		});

		if (this.funds.provider !== 'coinbase' && !cash_filtered.includes(this.funds?.currency?.toLowerCase())) {
			this.setDepositMaxMessage();
		}

		this.handleResize();

		if (!this.funds.currency) this.showPaymentMethod(true);
	},
	methods: {
		...mapActions(useUserStore, {
			getTokenUserData: 'getTokenUserData',
		}),
		...mapActions(useModalsStore, {
			toggleUpgradeTierModal: 'toggleUpgradeTierModal',
			toggleDepositWithdrawModal: 'toggleDepositWithdrawModal',
		}),
		...mapActions(useFundsStore, {
			updateFunds: 'updateFunds',
		}),

		focusDepositAmount() {
			if (this.$refs.deposit_amount) {
				(this.$refs.deposit_amount as InstanceType<typeof HTMLInputElement>).focus();
			} else {
				setTimeout(() => {
					this.focusDepositAmount();
				}, 100);
			}
		},

		formatAmount(deposit_amount: any) {
			let number_unformatted = String(deposit_amount || '0');

			const regex = new RegExp('[^0-9' + +this.number_seperators.thousand + this.number_seperators.decimal + ']', 'g');

			number_unformatted = number_unformatted.replace(regex, '');

			if (this.number_seperators.thousand) {
				var re = new RegExp('[' + this.number_seperators.thousand + ']', 'g');
				number_unformatted = String(number_unformatted).replace(re, '');
			}

			const decimal_split = number_unformatted.split(this.number_seperators.decimal);

			let after_decimal = null;
			let before_decimal = null;

			if (decimal_split.length > 2) {
				after_decimal = decimal_split[decimal_split.length - 1];
				decimal_split.pop();
				before_decimal = decimal_split.join('');
			} else if (decimal_split.length == 2) {
				before_decimal = decimal_split[0];
				after_decimal = decimal_split[1];
			} else {
				before_decimal = number_unformatted;
			}

			if (after_decimal && this.funds.provider !== 'coinbase' && after_decimal.length > 2) {
				after_decimal = after_decimal.substring(0, 2);
			}
			if (before_decimal.length > 6) {
				before_decimal = before_decimal.substring(0, 6);
			}
			return (

				parseFloat(before_decimal).toLocaleString() + (after_decimal == null ? '' : this.number_seperators.decimal + after_decimal)
			);
		},
		updateUSD(maxAmount: number) {
			this.deposit_amount = String(maxAmount);
		},

		showPurchaseAgreement() {
			this.updateFunds({ showPurchaseAgreement: true, amount: this.deposit_amount_number() });
		},
		showDepositBonus() {
			this.updateFunds({ showDepositBonus: true });
		},
		showPaymentMethod(showCurrency: boolean) {

			if (showCurrency) {
				this.updateFunds({ default_currency: this.funds.currency as any, currency: "", showPaymentMethod: true, amount: this.deposit_amount_number() });
			} else {
				this.updateFunds({ showPaymentMethod: true, amount: this.deposit_amount_number() });
			}


		},
		deposit_amount_number() {
			if (!this.deposit_amount) {
				return Number(this.deposit_amount);
			}
			let number_unformatted = this.deposit_amount;

			const regex = new RegExp('[^0-9' + +this.number_seperators.thousand + this.number_seperators.decimal + ']', 'g');

			number_unformatted = String(number_unformatted).replace(regex, '');

			if (this.number_seperators.thousand) {
				var re = new RegExp('[' + this.number_seperators.thousand + ']', 'g');
				number_unformatted = String(number_unformatted).replace(re, '');
			}

			return Number(number_unformatted);
		},
		getNumberSeparators() {
			// default
			var res = {
				decimal: '.',
				thousand: ''
			};

			// convert a number formatted according to locale
			var str = parseFloat('1234.56').toLocaleString();

			// if the resulting number does not contain previous number
			// (i.e. in some Arabic formats), return defaults
			if (!str.match('1')) return res;

			// get decimal and thousand separators
			res.decimal = str.replace(/.*4(.*)5.*/, '$1');
			res.thousand = str.replace(/.*1(.*)2.*/, '$1');

			// return results
			this.number_seperators = res;
		},
		openPaymentType() {

			if (this.user?.access_level?.withdrawal_block_reason == 'portfolio value') {
				this.toggleDepositWithdrawModal(false);
				this.toggleUpgradeTierModal(true)
				return;
			}

			if (!this.balanceLoaded) {
				this.depositWaiting = true;
				setTimeout(() => {
					this.openPaymentType();
				}, 1000);
				return
			}
			this.depositWaiting = false;
			this.checkDepositError();
			if (!this.deposit_error) {
				this.updateFunds({ showPaymentType: this.funds.provider, amount: this.deposit_amount_number() });
			}
		},
		setDepositMaxMessage() {
			let currencySymbol = this.currencySymbol;
			let currencySymbolCrypto = '';
			if (currencySymbol.length > 1) {
				currencySymbolCrypto = currencySymbol;
				currencySymbol = '';
			}
			let message = this.$t('funds.DEPOSIT_UP_TO') + ' ' + currencySymbol + this.formatAmount(this.maxLeft) + currencySymbolCrypto;
			this.deposit_message = message;
		},
		checkDepositError() {
			const deposit_amount = this.deposit_amount_number();

			if ((!deposit_amount || deposit_amount == 0) && this.funds.provider !== 'sepa') {
				this.deposit_error_message = this.$t('funds.NO_DEPOST_AMOUNT');
				this.deposit_error = true;
			} else if (deposit_amount > this.maxLeft) {
				this.deposit_error_message = this.$t('funds.PURCHASE_CONSENT');
				this.deposit_error = true;
			} else if (!this.deposit_consent) {
				this.deposit_error_message = this.$t('funds.PURCHASE_CONSENT');
				this.deposit_error = true;
			} else {
				this.setDepositMaxMessage();
				this.deposit_error = false;
			}
		},
		handleResize() {
			if (document.documentElement.clientWidth) {
				const screenWidth = document.documentElement.clientWidth;
				if (screenWidth < 768) {
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			}
		},
		closeModal() {
			this.toggleDepositWithdrawModal(false);
		}
	}
});
</script>
<style scoped>
.provider-icon {
	height: 20px;
}

.token-icon {
	height: 22px;
}

.payment-currency {
	font-size: 12px;
	line-height: 20px;
	color: #666666;
}

.provider-header {
	font-weight: 700;
	font-size: 14px;
	border-bottom: 1px solid #eae9ed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -1rem;
	margin-bottom: 1rem;
	padding: 1rem;
	transition: background-color 0.25s;
	border-radius: 7px 7px 0 0;
	color: #333333
}

.provider-header:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.centered-flex-gap {
	display: flex;
	align-items: center;
	gap: 5px;
}

.deposit-message {
	color: #666666;
	text-align: center;
	font-size: 14px;
	line-height: 1;
}

.deposit-input {
	max-width: 352px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	margin-top: 24px;
	justify-content: center;
}

.deposit-amount {

	width: 100%;
	border: 0;
	text-align: center;
	color: #333333;
	font-size: 40px;
	font-weight: 400;
	font-family: 'Manrope';
}

.deposit-amount:focus {
	outline: none;
}

.deposit-section {
	font-weight: 400;
	font-size: 12px;
	border: 1px solid #eae9ed;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding: 1rem;
}

.deposit-link {
	color: #1A6DF0;
}

.deposit-section.no-shadow {
	box-shadow: none;
}

.deposit-content {
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 24px;
}

.deposit-message-error {
	color: #fc6404;
}

.currency-symbol {
	font-family: 'Manrope';
	font-size: 20px;
	font-weight: 500;
	color: #333333;
	padding-top: 5px
}

.provider_gift_icon {
	font-size: 14px;
	margin-right: 5px;
}

.provider-bonus {
	color: #333333;
	padding: 5px 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	background: #fae3c2;
	border-radius: 5px;
	margin-top: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.hover-position {
	position: absolute;
	left: 24px;
	right: auto;
	top: 54px;
}

.provider-detail {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	margin-top: 5px;
	color: #686475;
}

.wallet-select {
	font-weight: 700;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -1rem;
	padding: 1rem;
	transition: background-color 0.25s;
	border-radius: 0 0 7px 7px;
	color: #333333
}

.wallet-select:hover {
	background-color: #f5f5f5;
	cursor: pointer;
	transition: background-color 0.25s;
}

.button.bonus {
	font-family: 'Manrope';
	padding: 0.5rem;
	box-shadow: none;
	border: 1px solid #EAE9ED;
	border-radius: 100%;
	background: #FFF;
}

.button.bonus:hover {
	border: 1px solid #EAE9ED;
	background-color: #f5f5f5;
	transition: all 0.25s;
}

.button.bonus.end-pos {
	position: absolute;
	right: 24px;
	left: auto;
}

.button.bonus img {
	height: 24px;
	width: 24px;
}

.badges {
	position: absolute;
	left: 1.7rem;
	bottom: 1.7rem;
	top: auto;
	right: auto;
	color: #FFF;
	background: #1A6DF0;
	border-radius: 100%;
	font-family: 'Manrope';
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0.25rem;
	min-width: 1.0625rem;
}
</style>
