<template>
	<transition name="fade">
		<div v-show="showHeaderNotificationModal" id="header-notification" class="notification is-size-14">
			<div class="text-section">
				{{ $t('TIER_1_NOTE') }}
				<router-link to="/settings" tag="a" class="has-text-weight-semibold is-blue transition-faster">{{
					$t('TIER_UPGRADE')
				}}</router-link>.
			</div>
			<button type="button" class="modal-close notification-close is-medium transition-faster"
				@click="closeNotification" />
		</div>
	</transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'HeaderNotificationModal',
	components: {},
	data() {
		return {
			username: '',
			usernameTimeout: null as null | NodeJS.Timeout | number,
			usernameValidation: true,
			usernameMessage: '',
			email: '',
			loading: false,
			update_loading: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showHeaderNotificationModal: (state) => state.showHeaderNotificationModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),

	},
	watch: {
		user() {
			if (Number(this.user?.access_level?.tier) > 1) {
				this.toggleHeaderNotificationModal(false);
			} else {
				this.initialize();
			}
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleHeaderNotificationModal: 'toggleHeaderNotificationModal',
		}),

		initialize() {
			// if (Number(this.user.access_level.tier) < 2 && BigInt(this.activePortfolio.current_value) > BigInt(800 * Math.pow(10, 18))) {
			// 	const lastSeen = localStorage.getItem('header_notification_seen');
			// 	let showNotification = true;
			// 	// Check if seen less than 7 days ago
			// 	if (lastSeen !== null) {
			// 		if (Date.now() - parseInt(lastSeen) < 604800000) {
			// 			showNotification = false;
			// 		}
			// 	}

			// 	this.toggleHeaderNotificationModal(true);
			// }

		},
		closeNotification() {
			localStorage.setItem('header_notification_seen', Date.now().toString());
			this.toggleHeaderNotificationModal(false);
		}
	}
});
</script>
<style scoped>
.notification {
	background: #ffeab2;
	border-radius: 0;
	padding: 0.75rem 0.5rem;

	position: fixed;
	top: 0;
	width: 100%;
	z-index: 3;
}

.text-section {
	max-width: calc(100% - 36px);
}

.is-blue:not(:hover) {
	color: #228cdb !important;
}

.notification-close {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.35);
}
</style>
