import { defineStore } from 'pinia'

import { FUNDS, ERROR, ROOT } from '../mutation-types';
import to from 'await-to-js';
import Util from '@/services/shared';
import { default_provider, show_paypal, hide_moonpay } from '@/helpers/funding'
import { i18n } from '@/plugins/i18n';
import { getStore } from '..';
let store = {

		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		status:  {} as any,
	
}

export interface FundsState {
	type?: 'deposit'| 'withdraw',
	default_provider?: 'paypal' | 'advcash' | 'coinbase' | 'morpher' | 'moonpay' | 'sepa'
	provider?: 'paypal' | 'advcash' | 'coinbase' | 'morpher' | 'moonpay' | 'sepa'
	amount?: number,
	step?: string | null,
	currency?: '' | 'EUR' | 'GBP' | 'USD' | 'ETH' ,
	default_currency?: '',
	chain?: string,
	showDepositBonus?: boolean,
	showPurchaseAgreement?: boolean,
	showPaymentMethod?: boolean,
	showWithdrawAddress?: boolean,
	purchaseAgreementConsent?: boolean,
	showPaymentType?: any
	current_order?: any
	showWithdrawMethod?: boolean,
	withdraw_amount?: number,
	withdraw_page?: string,
	withdraw_account?: string,
	withdraw_account_name?: string,
	withdraw_currency?: any;
	bookmark_address?: string;
	source?: string;
	withdraw_wallet_type?: string;
	withdraw_wallet_exchange?: string;
	withdraw_conversion?: any;
	network?: string;
}

export const useFundsStore = defineStore('funds', {
  state: () => {
	store = getStore()

    const initialState: FundsState = {
      type: 'deposit',
      provider: 'moonpay',
      default_provider: 'moonpay',
      amount: 0,
      step: null,
      currency: '',
      default_currency: '',
      chain: '',
      showDepositBonus: false,
      showPurchaseAgreement: false,
      showPaymentMethod: false,
      showWithdrawAddress: false,
      purchaseAgreementConsent: false,
      showPaymentType: null,
      current_order: {},
      showWithdrawMethod: false,
      withdraw_amount: 0,
      withdraw_account: '',
      withdraw_page: '',
      withdraw_account_name: '',
      withdraw_currency: {
        chain: 'polygon',
		icon: 'pol.svg',
		name: 'POL',
		symbol: 'POL ' + i18n.t('ON') + ' Polygon'
      },
      bookmark_address: '',
      source: '',
      withdraw_wallet_type: '',
      withdraw_wallet_exchange: '',
      withdraw_conversion: {},
	  network: ''
    } 
    return initialState
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions:  {
		/**  Get all active market by ids */
		async openFunds( params: FundsState) {

			const user:any = store.user.data
			let country = ((user ? user.document_country : '') || '').toUpperCase();
			
			if (user.access_level.kyc === 'required') {
				store.modals.toggleUpgradeTierModal( true );
				return;
			}
			
			if (!country)
			   country = ((user ? user.ip_country : '') || '').toUpperCase();

			if (params.withdraw_account === '') {
				params.withdraw_wallet_type = '';
				params.withdraw_wallet_exchange = '';
			}

			
	 		if (!hide_moonpay.includes(country)) {
				params['default_provider'] = 'moonpay';
				if (!params['provider']) {
					params['provider'] = 'moonpay';	
				}				
			} else if (show_paypal.includes(country)) {
				params['default_provider'] = 'paypal';
				if (!params['provider']) {
					params['provider'] = 'paypal';	
				}
				
			} else {

				params['default_provider'] = 'advcash';
				if (default_provider[country]) {
					params['default_provider'] = default_provider[country]['provider'];
				}

				if (!params['provider']) {

					
					if (default_provider[country]) {
						params['provider'] = default_provider[country]['provider'];
					} else {
						params['provider'] = 'advcash';	
					}

				}
			}

			if (!params['currency']) {
				params['currency'] = '';				
			}

			if (!params['amount']) {
				params['amount'] = 0;
			}


			//toggleDepositWithdrawModal: 'modals/toggleDepositWithdrawModal',
			this[FUNDS.SET_FUNDS](params)
			store.modals.toggleDepositWithdrawModal(true);			
		},

		/**  Get all active market by ids */
		async updateFunds( params: FundsState) {

			if (params.showPaymentType == 'null') {
				params.current_order = {}
			}


			this[FUNDS.SET_FUNDS](params)
		},
		async resetFunds( params: any) {


			this.$reset()

			if (params && Object.keys(params).length > 0) {
				this[FUNDS.SET_FUNDS](params)
			}

		},
		
			[FUNDS.SET_FUNDS]( data: any) {
				const keys = Object.keys(data) 
				keys.forEach((key) => {
					(this as any)[key] = data[key] ;
				})
				
			},
		
			
	},
})