<template>
	<div ref="wrapper">
		<!-- LOGIC FOR THE FULL COMPONENT

			V-IF showAIInsightsModal == all
			-> open Sidebar with list af all ai insights...
			Else
			-> open Sidebar of select market - 'type' out ai insight text
		-->
		<div v-if="showAIInsightsModal">
			<div class="sidebar box">
				<div class="otherdiv" :class="{ 'with-border': sidebarScrolled }">
					<div class="top-buttons">
						<button v-if="showAIInsightsModal !== 'all'" class="button back-btn" @click="onBackSidebar">
							<i class="back-icon"></i>
						</button>
						<div v-else></div>
						<button class="button back-btn" @click="onCloseSidebar">
							<i class="close-icon"></i>
						</button>
					</div>
				</div>


				<div v-if="aiInsights.length > 0" class="insidebar">


					<div class="sidebar-content">


						<div class="ai-logo">
							<!-- img AI Logo-->
							<div class="is-140x140" ref="ailogo"></div>
						</div>


						<!-- OVERVIEW Highlighted, Stocks, Crypto, etc... -->

						<div class="ai-market-components" v-if="showAIInsightsModal == 'all'">

							<div class="arrow-container small-arrow" @mouseover="mouseOver" @mouseout="mouseOut">
								<b-button :class="{ arrow: true, left: true, rounded: true, show: !hideLeftArrow }"
									icon-right="chevron-left" @click="scrollCards('left')" />

								<div class="scrolling-wrapper" ref="scrollWrapper" @scroll="onScroll" :class="{
									'left-scroll-mask': !scrolls.right && scrolls.left,
									'right-scroll-mask': !scrolls.left && scrolls.right,
									'both-scroll-mask': scrolls.left && scrolls.right
								}">
									<div class="market-card" :class="{ 'active-market': selectedMarketType === 'all' }"
										@click="filterAIInsights('all')">
										{{ $t('HIGHLIGHTED') }}
									</div>

									<div class="market-card"
										:class="{ 'active-market': selectedMarketType === 'stock' }"
										@click="filterAIInsights('stock')">
										<p>{{ $t('STOCKS') }}</p>
									</div>
									<div class="market-card"
										:class="{ 'active-market': selectedMarketType === 'crypto' }"
										@click="filterAIInsights('crypto')">
										<p>{{ $t('CRYPTO') }}</p>
									</div>
									<div class="market-card"
										:class="{ 'active-market': selectedMarketType === 'commodity' }"
										@click="filterAIInsights('commodity')">
										<p>{{ $t('COMMODITIES') }}</p>
									</div>
									<div class="market-card"
										:class="{ 'active-market': selectedMarketType === 'index' }"
										@click="filterAIInsights('index')">
										<p>{{ $t('INDICES') }}</p>
									</div>

								</div>
								<b-button :class="{ arrow: true, right: true, rounded: true, show: !hideRightArrow }"
									icon-right="chevron-right" @click="scrollCards('right')" />
							</div>


						</div>
						<div v-if="showAIInsightsModal == 'all' && trendingInsights && trendingInsights.length > 0">
							<!-- TRENDING... -->

							<div class="ai-spotlight">
								<!-- Bullish or Bearish -->
								<p class="spotlight-heading">
									{{ $t('TRENDING') }}
								</p>
							</div>

							<div class="ai-trending-components" v-if="markets && markets.length > 0">
								<!-- First Row: Items 1 and 2 -->
								<div class="columns trending-row" style="display: flex; flex-direction: row;">
									<div v-for="(item, index) in trendingInsights.slice(0, 2)" :key="item.timestamp"
										class="column trending-columns equal-width-column">

										<div class="trending-insight">
											<!-- Card for each insight -->
											<div :class="{ 'bullish-card': item.bullish, 'bearish-card': !item.bullish }"
												class="ai-asset-card-trending" @click="onOpenAsset(item)">
												<div class="asset-image-content">
													<!-- Asset image -->
													<div class="asset-image-overall" v-if="item.market.logo_image"
														:class="{ 'image-asset-crypto': item.market.type === 'crypto' }">
														<img v-if="item.market.logo_image == 'local'"
															:src="imageURL(`@/img/unique/markets/${item.market.symbol.toLowerCase()}.png`)" />
														<img v-else
															:src="'data:image/svg+xml;base64,' + item.market.logo_image" />
													</div>
													<!-- Asset content -->
													<div class="asset-content">
														<div class="asset-info">
															<div class="asset-move-text">
																<p class="left-text">
																	{{ markets?.find(m => Util.formatMarketId(m.type ||
																		'', m.symbol || '') == item.market_id)?.symbol }}

																</p>

															</div>
															<div class="asset-summary">
																<div class="asset-summary">
																	<p class="is-truncated1 has-text-left is-size-14 is-line-height-18"
																		v-if="item.summary">
																		{{ truncateText(markets?.find(m =>
																			Util.formatMarketId(m.type || '', m.symbol ||
																				'') == item.market_id)?.name || '') }}
																	</p>
																</div>

															</div>
														</div>
													</div>
												</div>

												<div class="asset-price">

													<p :class="{
														'change-percent': true,
														'positive-change': parseFloat(item.market.change_percent) >= 0,
														'negative-change': parseFloat(item.market.change_percent) < 0
													}">
														<img class="change-img"
															v-if="parseFloat(item.market.change_percent) >= 0"
															src="@/assets/icons/market_up.svg" />
														<img class="change-img" v-else
															src="@/assets/icons/market_down.svg" />
														{{ parseFloat(Math.abs(markets.find(m =>
															Util.formatMarketId(m.type || '', m.symbol || '') ==
															item.market_id)?.change_percent || 0).toString()).toFixed(2) }}%
													</p>

												</div>
											</div>
										</div>

									</div>
								</div>

								<!-- Second Row: Items 3 and 4 -->
								<div class="columns trending-row" style="display: flex; flex-direction: row;">
									<div v-for="(item, index) in trendingInsights.slice(2, 4)" :key="item.timestamp"
										class="column trending-columns equal-width-column">
										<div class="trending-insight">
											<!-- Card for each insight -->
											<div :class="{ 'bullish-card': item.bullish, 'bearish-card': !item.bullish }"
												class="ai-asset-card-trending" @click="onOpenAsset(item)">
												<div class="asset-image-content">
													<!-- Asset image -->
													<div class="asset-image-overall" v-if="item.market.logo_image"
														:class="{ 'image-asset-crypto': item.market.type === 'crypto' }">
														<img v-if="item.market.logo_image == 'local'"
															:src="imageURL(`@/img/unique/markets/${item.market.symbol.toLowerCase()}.png`)" />
														<img v-else
															:src="'data:image/svg+xml;base64,' + item.market.logo_image" />
													</div>
													<!-- Asset content -->
													<div class="asset-content">
														<div class="asset-info">
															<div class="asset-move-text">
																<p class="left-text">
																	{{ markets.find(m => Util.formatMarketId(m.type ||
																		'', m.symbol || '') == item.market_id)?.symbol }}

																</p>

															</div>
															<div class="asset-summary">
																<p class="is-truncated1 has-text-left is-size-14 is-line-height-18"
																	v-if="item.summary">
																	{{ truncateText(markets.find(m =>
																		Util.formatMarketId(m.type || '', m.symbol || '') ==
																		item.market_id)?.name || '') }}
																</p>
															</div>

														</div>
													</div>
												</div>
												<div class="asset-price">

													<p :class="{
														'change-percent': true,
														'positive-change': parseFloat(item.market.change_percent) >= 0,
														'negative-change': parseFloat(item.market.change_percent) < 0
													}">
														<img class="change-img"
															v-if="parseFloat(item.market.change_percent) >= 0"
															src="@/assets/icons/market_up.svg" />
														<img class="change-img" v-else
															src="@/assets/icons/market_down.svg" />
														{{ parseFloat(Math.abs(markets.find(m =>
															Util.formatMarketId(m.type || '', m.symbol || '') ==
															item.market_id)?.change_percent || 0).toString()).toFixed(2) }}%
													</p>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



						<!-- OVERVIEW CARDS - all ai insights -->
						<div v-if="showAIInsightsModal == 'all'" class="mb-2 mt-6">
							<div class="ai-spotlight">
								<!-- Bullish or Bearish -->
								<p class="spotlight-heading">
									{{ $t('FOR_YOU') }}
								</p>
							</div>

							<div v-if="filteredAIInsights.length > 0 && markets && markets.length > 0">
								<div v-for="item in filteredAIInsights" :key="item.timestamp">
									<div :class="{ 'bullish-card': item.bullish, 'bearish-card': !item.bullish }"
										class="ai-asset-card" @click="onOpenAsset(item)">

										<div class="asset-image-overall" v-if="item.market.logo_image" :class="{
											'image-asset-crypto':
												item.market.type === 'crypto'
										}">
											<img v-if="item.market.logo_image == 'local'"
												:src="imageURL(`@/img/unique/markets/${item.market.symbol.toLowerCase()}.png`)" />

											<img v-else :src="'data:image/svg+xml;base64,' +
												item.market.logo_image
												" />
										</div>
										<div class="asset-content">
											<div class="asset-info">
												<div class="asset-move-text">
													<div class="asset-summary">
														<p class="left-text">
															{{ markets.find(m => Util.formatMarketId(m.type || '',
																m.symbol || '') == item.market_id)?.symbol }}

														</p>
														<p class="is-truncated1 has-text-left is-size-14 is-line-height-18"
															v-if="item.summary">
															{{ markets.find(m => Util.formatMarketId(m.type || '',
																m.symbol || '') == item.market_id)?.name }}
														</p>
													</div>

													<div clss="text-and-time">
														<p :class="{
															'change-percent': true,
															'positive-change': parseFloat(item.market.change_percent) >= 0,
															'negative-change': parseFloat(item.market.change_percent) < 0
														}">
															<img class="change-img"
																v-if="parseFloat(item.market.change_percent) >= 0"
																src="@/assets/icons/market_up.svg" />
															<img class="change-img" v-else
																src="@/assets/icons/market_down.svg" />
															{{ parseFloat(Math.abs(markets.find(m =>
																Util.formatMarketId(m.type || '', m.symbol || '') ==
																item.market_id)?.change_percent || 0).toString()).toFixed(2)
															}}%
														</p>
														<p class="right-text">
															{{ timeElapsed(Number(item.timestamp)) }}
														</p>
													</div>

												</div>
												<!-- <div class="asset-summary">
												<p class="is-truncated1 has-text-left is-size-14 is-line-height-18"
													v-if="item.summary">
													{{ markets.find(m => Util.formatMarketId(m.type,
													m.symbol) == item.market_id).name }}
												</p>
											</div> -->
											</div>
										</div>
									</div>
								</div>
							</div>



							<!-- Empty State - No Insights -->
							<div v-else-if="markets && markets.length > 0" class="insidebar">
								<div class="has-text-centered">
									<img src="@/assets/img/ai_sphere_illustration.svg" />
								</div>
								<p class="mt-3 has-text-centered is-line-height-20 has-text-asphalt">{{
									$t('AI_NOTHING_FOUND') }}
								</p>

							</div>

						</div>



						<!-- END OVERVIEW CARDS-->

						<!-- ASSET CARD - ai insight detail -->

						<div v-if="showAIInsightsModal !== 'all' && selectedItem" class="info-page-ai">


							<div class="asset-move-text">
								<div class="logo-name">


									<div class="asset-image" v-if="selectedItem.market.logo_image">
										<img v-if="selectedItem.market.logo_image == 'local'"
											:src="imageURL(`@/img/unique/markets/${selectedItem.market.symbol.toLowerCase()}.png`)" />
										<img v-else
											:src="'data:image/svg+xml;base64,' + selectedItem.market.logo_image" />
									</div>

									<div class="asset-summary">
										<p class="left-text">
											{{ markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') ==
												selectedItem.market_id)?.symbol }}
										</p>
										<p class="is-truncated1 has-text-left is-size-14 is-line-height-18"
											v-if="selectedItem.summary">
											{{ markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') ==
												selectedItem.market_id)?.name }}
										</p>
									</div>
								</div>

								<div clss="text-and-time">
									<p :class="{
										'change-percent': true,
										'positive-change': (markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') == selectedItem.market_id)?.change_percent || 0) >= 0,
										'negative-change': (markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') == selectedItem.market_id)?.change_percent || 0) < 0
									}">
										<img class="change-img"
											v-if="(markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') == selectedItem.market_id)?.change_percent || 0) >= 0"
											src="@/assets/icons/market_up.svg" />
										<img class="change-img" v-else src="@/assets/icons/market_down.svg" />
										{{ parseFloat(Math.abs(markets.find(m =>
											Util.formatMarketId(m.type || '',
												m.symbol || '') == selectedItem.market_id)?.change_percent ||
											0).toString()).toFixed(2) }}%
									</p>
									<p class="right-text">
										{{ timeElapsed(Number(selectedItem.timestamp)) }}
									</p>
								</div>
							</div>
							<button v-if="showMarketButton" class="button plain-primary-btn is-fullwidth mt-4 "
								@click="openMarket">
								{{ $t('OPEN_MARKET_PAGE') }}
							</button>
							<hr>


							<!-- Bullish or Bearish -->
							<div class="ai-assesment" v-if="selectedItem.bullish">
								<p class="ai-signal">
									{{ $t('AI_SIGNAL') }}: <span class="bullish-color">{{ $t('BULLISH') }}</span>
								</p>
							</div>
							<div class="ai-assesment" v-else>
								<p class="ai-signal">
									{{ $t('AI_SIGNAL') }}: <span class="bearish-color">{{ $t('BEARISH') }}</span>
								</p>
							</div>
							<!-- AI Usage Disclaimer -->
							<div class="has-text-left is-line-height-1">
								<span class="small-disclaimer-text" @click="showDisclaimer = !showDisclaimer">{{
									showDisclaimer ? $t('funds.HIDE_DETAILS') : $t('DISCLAIMER')
								}}</span>
								<p v-show="showDisclaimer" class="is-size-7 has-text-grey">{{ $t('AI_FULL_DISCLAIMER')
									}}</p>
							</div>

							<div class="overall-info" ref="overallInfo">
								<p v-html="introduction"></p>
								<hr>
							</div>
							<div class="ai-summary">
								<!-- Text summary -->
								<span id="typed-content" class="newssummary" />
								<span v-show="!finishedTyping" id="blinking-cursor" class="blinking-cursor" />
							</div>

							<!-- Market News -->

							<p class="title-container page-title spotlight-heading">{{ $t('JUST_NEWS') }}</p>
							<MarketNews :title="$t('MARKET_NEWS')" :news="marketNews" :loading="newsLoading"
								:singleColumn="true" :aiBar="true" />

						</div>
						<!-- END ASSET CARD-->


					</div>



				</div>

				<!-- Empty State - No Insights -->
				<div v-else class="insidebar">
					<div class="has-text-centered">
						<img src="@/assets/img/ai_sphere_illustration.svg" />
					</div>
					<p class="mt-3 has-text-centered is-line-height-20 has-text-asphalt">{{ $t('AI_NOTHING_FOUND') }}
					</p>
					<button class="button plain-secondary-btn is-fullwidth mt-3" @click="onCloseSidebar">
						{{ $t('CLOSE') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { useMarketsStore } from '@/store/modules/markets';
import { ContentLoader } from 'vue-content-loader';
import lottie from 'lottie-web';
import Util from '@/services/shared';
import MarketNews from '../MarketNews.vue';
import { UserService } from '@/services/UserService';
import { MarketService } from '@/services/MarketService';
import { getSegmentMarkets } from '@/helpers/utils';
import { MARKETS } from '@/store/mutation-types';

// Beorn
/* @group Components_General */
/*
<h4> <b> Market Category component </b> </h4>
<br> Example: Watchlist in Homepage / Category view of stocks (Gainers/Losers)
*/
export default defineComponent({
	computed: {
		...mapState(useMarketsStore, {
			selectedMarket: (state) => state.selected,
			aiInsights: (state) => state.aiInsights,
			markets: (state) => state.data,
			segments: (state) => state.segments,
		}),
		...mapState(useModalsStore, {
			showAIInsightsModal: (state) => state.showAIInsightsModal,
		}),
		...mapState(useUserStore, {
			payload: (state) => state.data?.payload,
		}),

		firstFourAiInsights() {
			return this.aiInsights.slice(0, 4);
		},
		isDesktop() {
			return window.innerWidth >= 1200;
		},

		filteredAIInsights() {
			let list = [];

			let insights = this.aiInsights
			insights.forEach(insight => {

				const market = this.markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') == insight.market_id);
				if (market && market.symbol) {
					insight.market = market
				}
			})

			insights = insights.filter(insight => insight.market !== undefined)

			if (this.selectedMarketType === 'all') {
				list = insights
			} else {
				list = insights.filter(item => {
					return item.market && item.market.type === this.selectedMarketType;
				});
			}
			if (list.length > 30) {
				list = list.slice(0, 30)
			}

			return list

		},

		summary() {
			let summaryText = '';
			if (this.selectedItem && this.selectedItem.summary) {
				summaryText = this.selectedItem.summary.trim();
				while (summaryText.substring(0, 1) == '\n') {
					summaryText = summaryText.substring(1).trim();
				}
			}
			return summaryText;
		},


		introduction() {
			let introText = '';
			if (this.selectedItem && this.selectedItem.headline) {
				introText = this.selectedItem.headline
					.replace(/\n/g, '<br>')
					.replace('Introduction:', '')
					.replace('Intro:', '')
					.trim();
				while (introText.substring(0, 4) == '<br>') {
					introText = introText.substring(4).trim();
				}
			}
			return introText;
		}
	},
	name: 'AISidebar',

	components: {
		ContentLoader,
		MarketNews
	},

	data() {
		return {
			isTruncated: true,
			typingIndex: 0,
			typingSpeed: 19,
			typeTextCalled: false,
			sidebarScrolled: false,
			showMarketButton: false,
			selectedItem: null as any,
			selectedMarketType: 'all',
			Util: Util,
			finishedTyping: false,
			marketNews: [] as any[],
			newsLoading: false,
			showDisclaimer: false,
			hideLeftArrow: true,
			hideRightArrow: true,
			scrollAmount: 0,
			scrollIntensity: 300,
			scrolls: {
				left: false,
				right: true
			},
			trendingInsights: [] as any[],
			hoverActive: false


		};
	},

	unmounted() {

	},
	mounted() {
		const logoContainer = this.$refs.ailogo as InstanceType<typeof HTMLDivElement>;
		if (logoContainer) {
			const anim = lottie.loadAnimation({
				container: logoContainer,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				path: window.location.protocol + '//' + window.location.host + '/trade/animation/animation_sphere.json'
			});
		}

		const sidebar = document.querySelector('.sidebar.box');
		if (sidebar) {
			sidebar.addEventListener('scroll', this.handleSidebarScroll);
		}

		this.selectItem();

		UserService.sendAnalytics({ event: 'open_ai_panel', data: { page: this.$route.path } });

		if (this.aiInsights.length > 0) {
			if (!this.payload?.ai_last_seen || (Number(this.payload?.ai_last_seen) !== Number(this.aiInsights[0].timestamp))) {
				this.updateUserPayload({ payloadOption: 'ai_last_seen', payloadValue: String(this.aiInsights[0].timestamp) });
			}
		}

		this.loadTrending();

		this.initialize()
	},
	watch: {
		aiInsights() {
			this.loadTrending();
		},
		markets() {
			this.loadTrending();
		},
		segments() {
			this.loadTrending();
		},
		scrollAmount(val) {

			if (val === 0) this.hideLeftArrow = true;
			else if (val === this.getScrollPosition()) this.hideRightArrow = true;
			else {
				this.hideLeftArrow = false;
				this.hideRightArrow = false;
			}
		},
		showAIInsightsModal(newVal) {
			this.selectItem();
		},
		'$route.path'() {
			this.showMarketButton = false;
			if (this.selectedItem && this.selectedItem.market) {
				if (
					this.$route.path.toUpperCase() !==
					`/${this.selectedItem.market.type.toUpperCase()}/${this.selectedItem.market.symbol.toUpperCase()}`
				) {
					this.showMarketButton = true;
				}
			}
		},
		selectedMarket(nv) {
			if (nv) {
				const symbol = Util.formatMarketId(nv.type, nv.symbol);
				const insight = this.aiInsights.find(item => item.market_id == symbol);
				if (insight) {
					this.toggleAIInsightsModal(symbol);
				}
			}
		}
	},
	beforeUnmount() {
		const sidebar = document.querySelector('.sidebar.box');
		if (sidebar) {
			sidebar.removeEventListener('scroll', this.handleSidebarScroll);
		}
	},

	methods: {
		...mapActions(useMarketsStore, {
			getAIMarketInsights: 'getAIMarketInsights',
			getMarketSegments: 'getMarketSegments',
		}),
		...mapActions(useModalsStore, {
			toggleAIInsightsModal: 'toggleAIInsightsModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),

		truncateText(text: string) {
			return text.length > 5 ? text.substring(0, 4) + '...' : text;
		},
		loadTrending() {
			if (this.markets && this.markets.length > 0 && this.aiInsights && this.aiInsights.length > 0) {
				let ingights = JSON.parse(JSON.stringify(this.aiInsights))
				if (this.segments && this.segments.length > 0) {

					let trending = getSegmentMarkets(this.segments, this.markets, 'trending');
					trending = trending.sort((m1, m2) => {
						if (Math.abs(Number(m1['change_percent'] || 0)) < Math.abs(Number(m2['change_percent'] || 0))) {
							return 1;
						} else {
							return -1;
						}
					})

					let trendingInsights = [];

					trending.forEach(mark => {
						let market_id = Util.formatMarketId(mark.type, mark.symbol);
						ingights.forEach((ins: any) => {
							if (ins.market_id == market_id && trendingInsights.length < 4) {
								trendingInsights.push(ins);
							}
						})
					})

					if (trendingInsights.length == 0) {
						ingights.forEach((ins: any) => {
							const mark = this.markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') == ins.market_id)
							if (mark && mark.market_cap) {
								ins.market_cap = mark.market_cap
							} else {
								ins.market_cap = 0;
							}
						})

						trendingInsights = ingights.sort((m1: any, m2: any) => {
							if (Math.abs(Number(m1['market_cap'] || 0)) < Math.abs(Number(m2['market_cap'] || 0))) {
								return 1;
							} else {
								return -1;
							}
						}).slice(0, 4)

					}
					this.trendingInsights = trendingInsights;


				}

			}


		},
		scrollCards(direction: string) {
			const scrollWrapper = this.$refs.scrollWrapper as InstanceType<typeof HTMLDivElement>;;
			if (scrollWrapper) {


				let newAmount = this.scrollAmount;
				if (direction === 'right') newAmount += this.scrollIntensity;
				else newAmount = scrollWrapper.scrollLeft - this.scrollIntensity;

				const scrollWidth = scrollWrapper.scrollWidth;
				const itemWidth = scrollWrapper.clientWidth;

				if (newAmount <= 0) newAmount = 0;
				if (newAmount >= scrollWidth - itemWidth) newAmount = scrollWidth - itemWidth;

				this.scrollAmount = newAmount;

				scrollWrapper.scrollTo({
					top: 0,
					left: newAmount,
					behavior: 'smooth'
				});
			}
		},
		onScroll(event: any) {
			const el = event.target;
			const scrollWrapper = this.$refs.scrollWrapper as InstanceType<typeof HTMLDivElement>;;
			if (scrollWrapper) {

				const isScrolledToLeft = el.scrollLeft === 0;
				const isScrolledToRight = el.scrollLeft + scrollWrapper.clientWidth === scrollWrapper.scrollWidth;
				if (isScrolledToLeft) {
					this.scrolls.left = false;
					this.scrolls.right = true;
				} else if (isScrolledToRight) {
					this.scrolls.left = true;
					this.scrolls.right = false;
				} else {
					this.scrolls.left = true;
					this.scrolls.right = true;
				}
				this.scrollAmount = el.scrollLeft;
			}
		},
		getScrollPosition() {
			const scrollWrapper = this.$refs.scrollWrapper as InstanceType<typeof HTMLDivElement>;;
			if (scrollWrapper) {
				const scrollWidth = scrollWrapper.scrollWidth;
				const itemWidth = scrollWrapper.clientWidth;

				return scrollWidth - itemWidth;
			} else {
				return 0;
			}
		},
		mouseOver() {
			this.hoverActive = true;

			const scrollWrapper = this.$refs.scrollWrapper as InstanceType<typeof HTMLDivElement>;;

			if (this.hoverActive && scrollWrapper) {
				const scrollWidth = scrollWrapper.scrollWidth;
				const clientWidth = scrollWrapper.clientWidth;
				const scrollLeft = scrollWrapper.scrollLeft;

				if (scrollLeft + clientWidth >= scrollWidth) {
					this.hideRightArrow = true;
				} else {
					this.hideRightArrow = false;
				}

				if (scrollLeft === 0) {
					this.hideLeftArrow = true;
				} else {
					this.hideLeftArrow = false;
				}
			}
		},
		mouseOut() {
			this.hoverActive = false;

			if (!this.hoverActive) {
				this.hideRightArrow = true;
				this.hideLeftArrow = true;
			}
		},
		async initialize() {
			if (!this.segments || this.segments.length < 1) {
				this.getMarketSegments({
					variables: {
						market_id: 'all'
					},
					commitType: MARKETS.SEGMENTS,
					component: this
				});
			}
		},

		/**
		 * Select a specific ai insights atricle if a market was selected
		 */
		selectItem() {
			if (this.showAIInsightsModal && this.showAIInsightsModal !== 'all') {
				this.selectedItem = null;
				this.showMarketButton = false;
				this.aiInsights.forEach(item => {
					if (item.market_id == this.showAIInsightsModal && !this.selectedItem) {
						item.market = this.markets.find(m => Util.formatMarketId(m.type || '', m.symbol || '') == item.market_id);
						this.selectedItem = item;
					}
				});
				this.marketNews = [];
				if (this.selectedItem && this.selectedItem.market) {
					this.loadMarketNews();
					if (
						this.$route.path.toUpperCase() !==
						`/${this.selectedItem.market.type.toUpperCase()}/${this.selectedItem.market.symbol.toUpperCase()}`
					) {
						this.showMarketButton = true;
					}
				}

				if (this.selectedItem) {
					setTimeout(() => {
						let content = document.getElementById('typed-content')
						if (content) content.innerHTML = '';
						this.typeTextCalled = false;
						this.typeText();
					}, 200);
				}
			} else {
				this.showMarketButton = false;
			}
		},
		async loadMarketNews() {
			if (this.marketNews.length > 0 || this.newsLoading) {
				return;
			}

			this.newsLoading = true;
			let params = {
				market_id: Util.formatMarketId(this.selectedItem.market.type, this.selectedItem.market.symbol)
			};

			const result = await MarketService.getMarketNews(params);
			let news = result.data.getMarketNews
			if (news && news.length > 4) {
				news = news.slice(0, 4)
			}
			this.marketNews = news || []
			this.newsLoading = false;

		},

		handleSidebarScroll(event: any) {
			this.sidebarScrolled = event.target.scrollTop > 0;
		},
		filterAIInsights(marketType: any) {
			this.selectedMarketType = marketType;
		},
		setData() {
			const txt = this.summary;
			const outputDiv = document.getElementById('typed-content');
			return [txt, outputDiv];
		},
		/**
		 * Output the ai article text in a "typewriter" style
		 */
		async typeText() {
			if (!this.typeTextCalled) {
				this.finishedTyping = false;
				const res = this.setData();

				let itemRead = false;

				if (this.selectedItem && this.selectedItem.market_id && this.selectedItem.timestamp) {
					const item = this.selectedItem.market_id + '_' + this.selectedItem.timestamp;
					let ai_read: any = await localStorage.getItem('AI_INSIGHTS_READ');

					if (!ai_read) {
						ai_read = {};
					} else {
						ai_read = JSON.parse(ai_read);
					}

					const keys = Object.keys(ai_read);

					keys.forEach(key => {
						if (key == item) {
							itemRead = true;
						}

						if (ai_read[key] < Date.now() - 1000 * 60 * 60 * 24 * 7) {
							delete ai_read[key];
						}
					});

					ai_read[item] = Date.now();

					await localStorage.setItem('AI_INSIGHTS_READ', JSON.stringify(ai_read));
				}

				const txt = (res[0] || '').toString();
				const outputDiv: any = res[1];
				const splittedTxt = txt.split('<np>');
				let pElements = [];
				splittedTxt.forEach((item, index) => {
					// const p = document.createElement('p');
					const p = document.createElement('span');
					outputDiv.appendChild(p);
				});

				// const allParas = outputDiv.querySelectorAll('p');
				const allParas = outputDiv.querySelectorAll('span');
				let i = 0;
				let currentPara = 0;

				if (itemRead) {
					while (currentPara < splittedTxt.length) {
						if (splittedTxt[currentPara].charAt(i) == '\n') {
							allParas[currentPara].innerHTML += '<br>';
						} else {
							allParas[currentPara].innerHTML += splittedTxt[currentPara].charAt(i);
						}

						i++;

						if (i === splittedTxt[currentPara].length) {
							currentPara++;
							i = 0;
						}
					}

					this.finishedTyping = true;
				} else {
					const timerId = setInterval(() => {
						if (splittedTxt[currentPara].charAt(i) == '\n') {
							allParas[currentPara].innerHTML += '<br>';
						} else {
							allParas[currentPara].innerHTML += splittedTxt[currentPara].charAt(i);
						}
						i++;
						if (i === splittedTxt[currentPara].length) {
							currentPara++;
							i = 0;
							if (currentPara === splittedTxt.length) {
								clearInterval(timerId);
								setTimeout(() => (this.finishedTyping = true), 3000);
							}
						}
					}, 8);
				}
				this.typeTextCalled = true;
			}
		},

		onBackSidebar() {
			this.toggleAIInsightsModal('all');
		},
		onOpenAsset(item: any) {
			this.toggleAIInsightsModal(item.market_id);
		},
		onCloseSidebar() {
			UserService.sendAnalytics({ event: 'close_ai_panel', data: { page: this.$route.path } });

			this.toggleAIInsightsModal('');
		},
		/**
		 * close the sidebar and open the market page
		 */
		openMarket() {
			this.$router.push(`/${this.selectedItem.market.type.toUpperCase()}/${this.selectedItem.market.symbol.toUpperCase()}`);
			UserService.sendAnalytics({
				event: 'close_ai_panel',
				data: { page: `/${this.selectedItem.market.type.toUpperCase()}/${this.selectedItem.market.symbol.toUpperCase()}` }
			});

			this.toggleAIInsightsModal('');
		}
	}
});
</script>

<style scoped>
/* overall asset page */
.ai-spotlight {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 27px;
	text-align: left;
	margin: auto;


}

.ai-text-color {
	color: #1a6df0;
}

.asset-summary {
	margin-top: 0px;
}

.bullish-card {
	background-color: #dffbe9;
}

.bearish-card {
	background-color: #fff0f3;
}

.bullish-color {
	color: #039954;
}

.bearish-color {
	color: #d70228;
}

.box {
	padding: 0px;
}

.all-read {
	margin-top: 14px;
	/* Normal Text Bold */

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	text-align: center;
	letter-spacing: -0.01em;

	/* Navy Blue */

	color: #1a6df0;
}

.asset-image {
	margin-right: 12px;
	border-radius: 4px;
	min-width: 40px;
	width: 40px;
	object-fit: cover;
	height: 40px;
}

.asset-image-overall {
	border-radius: 4px;
	width: 40px;
	height: 40px;
	margin-right: 12px;
	align-items: center;
	display: flex;
	padding-left: 8px;
}

.asset-image-overall img {
	border-radius: 4px;
}

.asset-image img {
	width: 40px;
	height: 40px;
	border-radius: 4px;
}

.asset-content {
	flex: 1;
	display: flex;
	align-items: center;
}

.image-asset-crypto img {
	border-radius: 36px !important;
}

.asset-info {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.asset-is-up-down {
	margin-bottom: 8px;
	text-align: left;
}

.headline-asset {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height, or 138% */

	letter-spacing: -0.01em;

	/* Dark Grey */

	color: #333333;
}

/* The whole sidebar component */

.sidebar {
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: 9999;
	overflow: auto;
	background: #ffffff;
	right: 0px;
	top: 0px;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.sidebar-content {
	margin-top: -40px;
}

.is-140x140 {
	height: 140px;
	width: 140px;
	margin: auto;
}

.ai-logo {
	text-align: center;
}

.assetlogo {
	display: flex;
	align-items: center;
	margin-top: 16px;
}

.asset-move-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.left-text {
	color: var(--text-text-primary, #333);

	/* Normal Text Bold */
	font-family: 'Manrope';
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;

	letter-spacing: -0.01em;
	white-space: nowrap;
	text-align: left;

}

.right-text {
	/* margin-right: 20px; */
	text-align: right;

	font-family: 'Manrope';
	font-style: normal;
	font-size: 12px;
	line-height: 18px;
	/* identical to box height, or 150% */

	letter-spacing: -0.01em;

	/* Asphalt Grey */

	color: #686475;
}

.ai-assesment {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 27px;
	text-align: left;
	margin-top: 16px;
}

.overall-info {
	/* Normal Text */

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */

	letter-spacing: -0.01em;
	margin-top: 16px;
	text-align: left;
	align-self: stretch;
}

.ai-summary {
	/* Normal Text */

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */

	letter-spacing: -0.01em;
	margin-top: 12px;
	text-align: left;
	align-self: stretch;
}

.ai-summary .blinking-cursor::after {
	content: '';
	width: 8px;
	height: 14px;
	margin-left: 4px;
	/* Brand Green */
	background: #00c386;
	display: inline-block;
	animation: cursor-blink 0.875s steps(2) infinite;
}

.is-truncated1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-text-secondary, #686475);

	/* Small Text */
	font-family: Manrope;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;
}

@keyframes cursor-blink {
	100% {
		opacity: 20%;
	}
}

.top-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
}

.close-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url('@/assets/icons/times-grey.svg') no-repeat center center;
	cursor: pointer;
	/* set cursor to pointer */
	transition: all 0.25s;
	position: static;
}

.back-button {
	z-index: 2;
	background-color: white;
	border-style: none;
	cursor: pointer;
	/* set cursor to pointer */
	transition: all 0.25s;

	top: 22px;
}

.back-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url('@/assets/icons/Big Icons.svg') no-repeat center center;
	opacity: 70%;
	cursor: pointer;
	/* set cursor to pointer */
	transition: all 0.25s;
}

.market-page-button {
	/* Auto layout */
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 10px;
	gap: 10px;

	width: 100%;

	max-width: 335px;
	min-height: 44px;

	/* Brand Green */

	background: #00c386;
	border-radius: 8px;
	border-style: none;

	/* Inside auto layout */

	flex: none;
	order: 3;
	align-self: stretch;
	flex-grow: 0;
	margin-top: 14px;

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	letter-spacing: -0.01em;

	color: #ffffff;
}

.ai-asset-card {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 14px;
	padding-bottom: 14px;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.25s;

}

.ai-asset-card-trending {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	justify-content: left;
	padding-left: 8px;
	padding-right: 12px;
	padding-top: 14px;
	padding-bottom: 14px;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.25s;

}

.ai-asset-card:hover {
	box-shadow: none;
	transition: all 0.25s;
}

.with-border {
	position: fixed;
	height: 64px;
	background-color: white;
	z-index: 1;

	width: calc(100%);
	position: fixed;
	background: #ffffff;
	right: 0px;
	top: 0px;
	bottom: 16px;
	padding-top: 0px;
	padding-bottom: 0px;

	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

@media screen and (min-width: 1024px) {
	.sidebar {
		height: calc(100% - 32px);
		width: calc(25% - 32px);
		position: fixed;
		background: #ffffff;
		right: 16px;
		top: 16px;
		bottom: 16px;
		z-index: 99999;

		border-radius: 12px;
	}

	.with-border {
		width: calc(25% - 32px);
		right: 16px;
		top: 16px;
		bottom: 16px;
		padding: 20px;
		padding-top: 0px;
		padding-bottom: 0px;

		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
	.sidebar {
		height: calc(100% - 32px);
		width: calc(35% - 32px);
		position: fixed;
		background: #ffffff;
		right: 16px;
		top: 16px;
		bottom: 16px;
		z-index: 99999;

		border-radius: 12px;
	}

	.with-border {
		z-index: 1;
		width: calc(35% - 32px);
		right: 16px;
		top: 16px;
		bottom: 16px;
		padding: 20px;
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
	.insidebar {
		width: 70%;
		margin: auto;
	}
}

.insidebar {
	padding: 20px;
	padding-top: 0;
}

.back-button {
	position: absolute;
	left: 22px;
	text-align: left;
}

.small-disclaimer-text {
	font-size: 12px;
	text-decoration: underline;
	color: #888;
	text-align: left;
	cursor: pointer;
}

.disclaimer {
	position: absolute;
	bottom: 0;
	padding: 4px 20px;
	background: #fff;
	width: 100%;
}




.trending-row {
	display: flex;
	flex-direction: row;
}

.trending-insights {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;
	border-radius: 8px;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.trending-columns {
	padding-left: 8px !important;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-right: 8px;

}

.positive-change {
	color: #039954;
}

.negative-change {
	color: #d70228;
}

.asset-image-content {
	flex-direction: row;
	display: flex;
}

.asset-price {
	align-self: flex-start;
	text-align: left;
	padding-left: 12px;
	padding-top: 12px;
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
}

.change-percent {
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
}

.spotlight-heading {
	color: var(--Dark-Grey, #333);

	/* H5 */
	font-family: Manrope;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 16px;
	margin-top: 8px;
}

.market-card {
	cursor: pointer;
}




.scrolling-wrapper {
	overflow: hidden;
	display: flex;
	white-space: nowrap;
	gap: 5px;
	padding-bottom: 20px;
	font-weight: 700 !important;
	background: linear-gradient(to right, transparent, white 100%);
}

.scrolling-wrapper .market-card {
	border-radius: 20px;
	background: var(--surface-surface-light-grey, #F5F4F5);

	display: flex;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	display: inline-block;
	margin-right: 8px;
	color: var(--text-text-secondary, #686475);

	/* Small Text Bold */
	font-family: "Manrope";
	color: var(--text-text-secondary, #686475);
	font-size: 12px;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 18px;
	/* 150% */
	letter-spacing: -0.12px;

}

.active-market {
	border-radius: 20px;
	background: var(--surface-surface-blue, #1A6DF0) !important;

	color: var(--text-text-white, #FFF) !important;

}

.logo-name {
	flex-direction: row;
	display: flex;
}




.ai-signal {
	color: var(--Dark-Grey, #333);

	/* H5 */
	font-family: Manrope;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}


.left-scroll-mask {
	transition: all 0.25s;
	mask-image: linear-gradient(to right, transparent 0%, black 36px);
}

.right-scroll-mask {
	transition: all 0.25s;
	mask-image: linear-gradient(to left, transparent 0%, black 36px);
}

.both-scroll-mask {
	transition: all 0.25s;
	mask-image: linear-gradient(to right, transparent 0%, black 36px, black calc(100% - 36px), transparent 100%);
}

.page-title {
	padding-top: 12px;
}

.arrow-container {
	position: relative;
}

.rounded {
	width: 40px;
	height: 40px;
	flex: 0 0 auto;
	padding: 0;
	border-radius: 100px;
}

.arrow {
	position: absolute;
	top: 16px;
	transform: translateY(-50%);
	z-index: 1;
	opacity: 0;
	pointer-events: none;
	background: #ffffff !important;
	border: 1px solid #eae9ed !important;
	box-sizing: border-box;
	box-shadow: 0px 0px 10px 1px rgba(182, 191, 210, 0.2) !important;
	width: 52px;
	height: 52px;
	font-size: 22px;
	transition: 200ms;
}

.small-arrow .rounded {
	width: 20px;
	height: 20px;
}

.small-arrow .arrow {
	width: 34px;
	height: 34px;
	font-size: 16px;
}

.arrow:hover {
	background-color: #efefef !important;
}

.arrow.show {
	opacity: 1;
	pointer-events: all;
}

.arrow.left {
	left: -16px;
}

.arrow.right {
	right: -16px;
}

@media screen and (max-width: 900px) {
	.arrow {
		display: none;
	}
}
</style>