<template>
	<div>
		<div class="mobile-header">
			<div class="mobile-header-item">
				<router-link class="logo-container" to="/">
					<img class="logo" src="../assets/morpher_logo.svg" :alt="$t('MORPHER_LOGO')" />
				</router-link>
			</div>

			<div class="mobile-header-item mobile-search-input">
				<SearchInput :mobile-search="true" />
			</div>

			<!-- <div class="mobile-header-item insights-icon">
				<router-link to="/" class="notification-icon">
					<img v-if="aiInsightsOn" src="@/assets/icons/notification-bell-on.svg" />
					<img v-else src="@/assets/icons/notificiation-bell.svg" />
				</router-link>
			</div> -->

			<div class="mobile-header-item insights-icon" @click="toggleAIInsightsModal('all')">
				<img v-if="aiInsightsOn" src="@/assets/icons/ai_icon_on.svg" />
				<img v-else src="@/assets/icons/ai_icon_off.svg" />
			</div>


			<div class="mobile-header-item" @click="menuActive = true">
				<img src="@/assets/icons/hamburger-menu.svg" />
			</div>
			<div class="mobile-header-item" @click="menuActive = true"></div>
		</div>
		<HeaderMigrationModal :is-mobile="true" />

		<MobileMenu :active="menuActive" @close="menuActive = false" />

		<MobileTryAppModal :is-mobile="true" />
		<WalletRecoveryModal />

		<!-- <MobileMenuProfile :active="menuActiveProfile" @close="menuActiveProfile = false" /> -->
	</div>
</template>

<script lang="ts">
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { defineComponent } from 'vue'
import MobileMenu from './MobileMenu.vue';
import SearchInput from './SearchInput.vue';
import Blockie from '@/components/Blockie.vue';
import HeaderMigrationModal from './modals/HeaderMigrationModal.vue';
import WalletRecoveryModal from './modals/WalletRecoveryModal.vue';
import MobileTryAppModal from './modals/MobileTryAppModal.vue';

//vuex
import { MarketService } from '../services/MarketService';
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';

export default defineComponent({
	components: {
		MobileMenu,
		Blockie,
		SearchInput,
		HeaderMigrationModal,
		MobileTryAppModal,
		WalletRecoveryModal
	},
	data() {
		return {
			menuActive: false,
			menuActiveProfile: false,
			newsSubscription: null,
			aiInsightsOn: false
		};
	},
	watch: {
		payload(payloadValue) {
			this.updateAILastSeen();
		},
		aiInsights(nv) {
			this.updateAILastSeen();
		},
		app_lang(nv) {
			this.localeUpdated(nv);
		}
	},
	async mounted() {
		if (this.aiInsights.length == 0) {
			this.getAIMarketInsights({ component: this });
		}
		this.aiNewsSubscribe();
	},
	unmounted() {

		this.aiNewsUnSubscribe();
	},
	computed: {
		...mapState(useMarketsStore, {
			aiInsights: (state) => state.aiInsights,
		}),
		...mapState(useUserStore, {
			payload: (state) => state.data?.payload,
			app_lang: (state) => state.data?.payload?.app_lang,
		}),


	},
	methods: {
		...mapActions(useMarketsStore, {
			getAIMarketInsights: 'getAIMarketInsights',
		}),
		...mapActions(useModalsStore, {
			toggleAIInsightsModal: 'toggleAIInsightsModal',
		}),

		...mapActions(useMarketsStore, {
			newAIMarketInsight: 'newAIMarketInsight'
		}),
		localeUpdated(locale: string) {
			setTimeout(() => {
				this.getAIMarketInsights({ component: this });
			}, 1000);
		},
		updateAILastSeen() {
			if (this.aiInsights.length > 0 && this.payload) {
				if (this.payload?.ai_last_seen) {
					const fil = this.aiInsights.filter((ai) => Number(ai.timestamp) > Number(this.payload?.ai_last_seen));

					if (fil.length > 0) {
						this.aiInsightsOn = true;
					} else {
						this.aiInsightsOn = false;
					}
				} else {
					this.aiInsightsOn = true;
				}
			} else {
				this.aiInsightsOn = false;
			}
		},
		async aiNewsSubscribe() {
			if (this.newsSubscription) {
				await MarketService.unsubscribeFromAINews(this.newsSubscription);
				this.newsSubscription = null;
			}

			this.newsSubscription = await MarketService.subscribeToAINews('AI_INSIGHTS', (data) => {
				if (data) {
					try {
						const ai_news = data.data.updateNews;

						if (ai_news) {
							const summary = ai_news.summary.split('Assessment:');
							let intro_text = '';
							let summary_text = '';

							if (summary.length == 2) {
								intro_text = summary[0];
								summary_text = summary[1];
							} else {
								const summary2 = ai_news.summary.split('\n');

								if (summary2.length > 1) {
									intro_text = summary2.shift();
									summary_text = summary2.join('\n');
								} else {
									intro_text = '';
									summary_text = ai_news.summary;
								}
							}

							const ai_data = {
								market_id: ai_news.market_id,
								timestamp: ai_news.timestamp,
								headline: intro_text,
								source: 'ai',
								summary: summary_text,
								image_url: null,
								bullish: ai_news.has_paywall,
								change_percent: 0,
								url: null
							};

							if (
								!this.aiInsights.find((item) => item.market_id == ai_news.market_id && item.timestamp == ai_news.timestamp)
							) {
								this.newAIMarketInsight(ai_data);
							}
						}
					} catch (err) { }
				}
			});
		},
		async aiNewsUnSubscribe() {
			if (this.newsSubscription) {
				await MarketService.unsubscribeFromAINews(this.newsSubscription);
				this.newsSubscription = null;
			}
		}
	}
});
</script>

<style scoped>
.mobile-header {
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
	min-height: 60px;
	position: fixed;
	width: 100%;
	background-color: white;
	z-index: 30;
}

.mobile-header-item {
	display: flex;
	align-items: center;

	cursor: pointer;
	margin-left: 16px;
}

.logo-container {
	display: flex;
}

.logo {
	margin-left: 0;
	margin-right: 0;
}

.burger-icon {
	font-size: 24px;
}

.insights-icon {
	cursor: pointer;
}

.insights-icon img {
	min-width: 24px;

}

.notification-icon img {
	padding-top: 3px;
}

.logo {
	width: 38px;
}
</style>