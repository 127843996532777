<template>
	<div>
		<div class="deposit-modal-spacing has-text-left">
			<div v-if="process_status === 'processing'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_PROCESSING') }}
			</div>
			<div v-else-if="process_status === 'success'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_SUCCESS') }}
			</div>
			<div v-else-if="process_status === 'error'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_ERROR') }}
			</div>

			<div v-else class="header-bar-global">
				<button class="button back-btn" @click="closePayment()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.MOONPAY_PROCESSING') }}
			</div>

			<!-- payment requested - after submit to moonpay -->
			<div v-if="process_status === 'new'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/continue.svg" />
				</div>

				<div class="payment_provider_section">
					<img src="@/assets/funds/moonpay.png" class="transaction_icon" />

					<div class="provider_text">
						<div class="provider_header">{{ $t('funds.MOONPAY') }}</div>
						<p class="is-size-14 has-text-weight-medium">
							{{ $t('funds.CONTINUE_POPUP_WINDOW') }} <br />
							{{ $t('funds.MOONPAY_NOT_WORKING') }}
							<a class="transition-faster" @click="openMoonpayWindow()">{{ $t('funds.MOONPAY_LAUNCH')
								}}</a>
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>
			</div>

			<!-- payment processing - after moonapy completed - waiting for deposit -->
			<div v-else-if="process_status === 'processing' || process_status === 'requested'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/processing.svg" alt="pending icon" />
				</div>

				<div class="payment_provider_section">
					<div>
						<img src="@/assets/funds/moonpay.png" class="transaction_icon" />
					</div>

					<div class="provider_text">
						<div class="provider_header">
							{{ $t('funds.MOONPAY') }}
						</div>
						<p class="is-size-14">{{ $t('funds.TRANSACTION_PROCESSING') }} {{ $t('funds.CLOSE_WINDOW') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment success - after deposit completed -->
			<div v-else-if="process_status === 'success'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/success.svg" alt="Success checkmark image" />
				</div>

				<div class="transaction_details_section" v-if="transaction_data && transaction_data.payload">
					<div class="transaction_header">
						<img src="@/assets/funds/moonpay.png" class="transaction_icon" alt="payment provider" />
						{{ getCurrencySymbol(transaction_data.payload.source_currency)
						}}{{ formatAmount(transaction_data.payload.purchase_cost) }}
					</div>

					<div class="transaction_details_lines">
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MPH_CONVERSION') }}
							</p>
							<p>{{ roundFormatter(transaction_data.payload.purchase_amount_mph) }} MPH</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MOONPAY_FEES') }}
							</p>
							<p>
								{{ getCurrencySymbol(transaction_data.payload.source_currency)
								}}{{ formatAmount(transaction_data.payload.purchase_fees) }}
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.TOTAL_VALUE') }}
							</p>
							<p>
								{{ getCurrencySymbol(transaction_data.payload.source_currency)
								}}{{ formatAmount(transaction_data.payload.purchase_total) }}
							</p>
						</div>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment error -->
			<div v-else-if="process_status === 'error'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/error.svg" alt="payment error" />
				</div>

				<div class="payment_provider_section">
					<div class="provider_text">
						<p class="is-size-14">
							{{ $t('funds.ERROR_TEXT') }}
							{{ $t('funds.ERROR_TRY_AGAIN') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.LEARN_MORE_DEPOSITS') }}
							<a target="_blank" :href="supportArticleLink('DepositMethod')">{{
								$t('funds.HERE')
							}}</a>.
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="closePayment()">
					{{ $t('BACK') }}
				</button>
			</div>


		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PaymentMixin from '@/mixins/payment.mixin';
import { TransactionService } from '@/services/TransactionService';
import { UserService } from '@/services/UserService';

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'PaymentTypeMoonpay',
	mixins: [PaymentMixin],
	components: {},
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		maxLeft: {
			type: Number,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			moonpay_data: null
		};
	},
	async mounted() {
		if (this.user?.payload.moonpaySurvey == undefined || (this.user.payload.moonpaySurvey !== false && this.user.payload.moonpaySurvey !== 'false')) {
			this.updateUserPayload({ payloadOption: 'moonpaySurvey', payloadValue: 'true' });
		}

		this.purchase_price = String(this.balance.mph_rate);
		this.purchase_cost = String(this.funds.amount);
		this.purchase_fees = '0';
		this.purchase_total = String(this.funds.amount);
		this.purchase_amount_mph = String(Number(this.funds.amount) / Number(this.balance.mph_rate));
		this.process_status = 'new';
		this.createPurchase();

		if (!this.deposit_address || !this.deposit_address.deposit_eth_address) {
			await this.createDepositAddress();
		}
	},

	methods: {
		async openMoonpayWindow() {
			// const redirect = import.meta.env.VITE_ROOT_HOSTNAME + '/funds';

			// const environment = import.meta.env.VITE_MODE;
			// let url = `https://buy.moonpay.com?apiKey=${import.meta.env.VITE_MOONPAY_API_KEY}`;

			// if (environment === 'development' || environment === 'staging') {
			// 	url = `https://buy.moonpay.com?apiKey=${import.meta.env.VITE_MOONPAY_API_KEY}`;
			// }

			// url += '&walletAddress=' + this.deposit_address.deposit_eth_address;
			// url += '&baseCurrencyCode=' + (this.funds.current_order.payload.source_currency || 'usd').toLowerCase();
			// url += '&baseCurrencyAmount=' + this.funds.current_order.payload.purchase_total;
			// url += '&externalTransactionId=' + this.funds.current_order.id;
			// url += '&externalCustomerId=' + this.user.activePortfolio.eth_address;
			// url += '&currencyCode=' + (import.meta.env.VITE_MODE === 'production' ? 'USDC_POLYGON' : 'USDC').toLowerCase()
			// url += '&redirectURL=' +  redirect;

			let url = this.funds.current_order.payload.url


			window.open(url, '_blank');

		},
		async createPurchase() {
			try {
				this.purchase_loading = true;
				if (this.funds.current_order && this.funds.current_order.eth_address) {
					this.process_status = 'new';
					this.purchase_loading = false;
					this.openMoonpayWindow();
					return false;
				}

				if (!this.funds.amount) {
					this.resetFunds({});
					return;
				}
				// update the backend with the new user balances.
				const result = await TransactionService.purchaseMoonpayOrderRequest({
					data: {
						eth_address: this.user?.activePortfolio?.eth_address || '',
						order_id: this.order_id,
						purchase_price: this.balance.mph_rate.toString(),
						purchase_cost: this.purchase_cost.toString(),
						purchase_fees: this.purchase_fees.toString(),
						purchase_total: this.purchase_total.toString(),
						purchase_amount_mph: this.purchase_amount_mph.toString(),
						transaction_id: this.transaction_id,
						purchase_currency: (this.funds.currency || 'USD').toUpperCase()
					}
				});

				const data = result.data?.purchaseMoonpayOrderRequest;


				this.updateFunds({ current_order: result.data?.purchaseMoonpayOrderRequest });

				this.openMoonpayWindow();

				UserService.sendAnalytics({
					event: 'begin_checkout',
					data: {
						currency: this.funds.currency,
						transaction_id: data.id,
						value: data.payload.purchase_total,
						provider: 'moonpay',

					}
				});

				this.process_status = 'new';
				this.purchase_loading = false;
			} catch (err) {
				this.purchase_loading = false;

				if (err) return this.error(err);
			}
		}
	}
});
</script>
<style scoped src="../../assets/css/payment.css"></style>
