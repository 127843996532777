<template>
	<img v-if="src" :class="{ 'blockie': !large, 'blockie-large': large }" :src="src" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	props: {
		src: {
			type: String,
			required: true
		},
		large: {
			type: Boolean,
			required: false,
			default: false
		}
	}
});
</script>

<style>
.blockie {
	box-shadow: 0 4px 10px 0 rgba(182, 191, 210, 0.5);
	border-radius: 32px;
	vertical-align: middle;
	min-width: 30px;
	min-height: 30px;
}

.blockie-large {
	box-shadow: 0 4px 10px 0 rgba(182, 191, 210, 0.5);
	border-radius: 48px;
	vertical-align: middle;
	min-width: 46px;
	min-height: 46px;
}
</style>
