<template>
	<div id="token-adding">
		<b-modal :modelValue="showETHBalanceModal" has-modal-card @close="toggleETHBalanceModal(false)">
			<form @submit.prevent="modalAction">
				<div class="modal-card terminal">
					<!-- Metamask Mobile users - gas problem tutorial -->
					<div v-if="metamask_mobile" class="terminal-content has-text-left">
						<h1 class="has-font-rubik is-size-5 has-text-weight-medium mb-20 has-text-centered">
							{{ $t('funds.LOW_ETH_BALANCE') }}
						</h1>

						<!-- Metamask Icon -->
						<div class="has-text-centered">
							<img src="@/assets/icons/metamask_icon.svg" class="metamask-icon" />
						</div>

						<p class="mt-10" v-html="$t('funds.LOW_ETH_BALANCE_DESCRIPTION', {
							link: supportArticleLink('TradingGas')
						})
							"></p>

						<hr class="trade-hr mt-20 mb-20" />

						<div>
							<p class="has-text-weight-semibold">{{ $t('funds.LOW_ETH_BALANCE_STEPS') }}</p>
							<p class="mt-5px">{{ $t('funds.LOW_ETH_BALANCE_STEP_1') }}</p>
							<p class="mt-5px">{{ $t('funds.LOW_ETH_BALANCE_STEP_2') }}</p>
							<p class="mt-5px" v-html="$t('funds.LOW_ETH_BALANCE_STEP_3', {
								link: supportArticleLink('MetamaskGas')
							})
								"></p>
						</div>

						<button class="button big-button is-buy transition-fast mt-20" @click="modalAction">
							{{ $t('OK_UNDERSTAND') }}
						</button>
					</div>

					<!-- Everyone Else - Don't Send Virtual ETH -->
					<div v-else class="terminal-content has-text-left">
						<h1 class="has-font-rubik is-size-5 has-text-weight-medium mb-10 has-text-centered">
							{{ $t('funds.LOW_ETH_BALANCE') }}
						</h1>

						<!-- Warning Icon -->
						<p class="is-size-1 has-text-centered">⛔️ 🤯</p>

						<p v-if="walletType !== 'metamask'" class="has-text-weight-semibold is-size-18">
							{{ $t('funds.DO_NOT_SEND_ETH') }}
						</p>
						<p v-else class="has-text-weight-semibold is-size-18">
							{{ $t('funds.DO_NOT_SEND_ETH_METAMASK') }}
						</p>

						<p class="mt-10" v-html="$t('funds.VIRTUAL_ETH_NO_VALUE', {
							link: supportArticleLink('TradingGas')
						})
							"></p>

						<hr class="trade-hr mt-20 mb-20" />

						<p v-html="$t('funds.VIRTUAL_UNABLE_TRADE')"></p>

						<button class="button big-button is-buy transition-fast mt-20" @click="modalAction">
							{{ $t('OK_UNDERSTAND') }}
						</button>
					</div>
				</div>
			</form>
		</b-modal>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useModalsStore } from '@/store/modules/modals';
import { isMobile } from 'mobile-device-detect';
/* @group Components_Modals */
/*
  <h4> <b> Modal which shows that token was added into Metamask </b> </h4>
  <br> Example: https://sketch.cloud/s/49kjZ/52VOwnx
*/
export default defineComponent({
	name: 'ETHBalanceModal',
	data() {
		return {
			metamask_mobile: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showETHBalanceModal: (state) => state.showETHBalanceModal,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),

	},
	async mounted() {
		// Check if using Metamask mobile app
		if (isMobile && this.walletType === 'metamask') this.metamask_mobile = true;
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleETHBalanceModal: 'toggleETHBalanceModal',
		}),

		modalAction() {
			this.toggleETHBalanceModal(false);
		}
	}
});
</script>
<style scoped>
.metamask-icon {
	height: 3.5rem;
}
</style>
