<template>
	<div class="formcontainer">
		<div class="headerImage">
			<a href="https://www.morpher.com" target="_blank" alt="Morpher Homepage">
				<img class="image" v-if="$route.params.status == 'success'" src="@/assets/auth/morpher-and-metamask.svg"
					alt="Morpher Logo" />

				<img class="image" v-else src="@/assets/auth/morpher-logo.svg" alt="Morpher Logo" />
			</a>
		</div>


		<div v-if="!config.application_live && $route.query && $route.query.adminoverride !== 'true'" class="authBox">
			{{ $t('MAINTANANCE_MODE') }}
		</div>
		<div class="authBox">
			<div v-show="!initialising">
				<!-- Connect Fortmatic -->
				<div>
					<!-- Page title for all states -->
					<h2 data-cy="logInTitle" v-if="loginVerifying" class="authTitle">{{
						$t('auth.VERIFYING_YOUR_ACCOUNT_TITLE') }}</h2>
					<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'email'" class="authTitle">{{
						wallet_type == 'walletconnect' ? $t('auth.SIGNUP_WALLETCONNECT_HEADER') :
							$t('auth.SIGNUP_METAMASK_HEADER') }}</h2>
					<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'verify'" class="authTitle">{{
						$t('auth.VERIFY_EMAIL_TITLE') }}</h2>
					<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'success' && verifyError"
						class="authTitle">{{ $t('auth.SET_UP_METAMASK_ERR') }}</h2>
					<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'success'" class="authTitle">{{
						$t('auth.SET_UP_METAMASK_HEAD') }}</h2>
					<h2 data-cy="logInTitle" v-else-if="$route.params.status == 'rejected'" class="authTitle">{{
						$t('auth.ACCOUNT_REJECTED_TITLE') }}</h2>
					<h2 data-cy="logInTitle" v-else-if="geoBlocked" class="authTitle">{{
						$t('auth.UNSUPPORTED_COUNTRY_TITLE') }}</h2>
					<h2 data-cy="logInTitle" v-else-if="twoFA" class="authTitle">{{ $t('auth.2FA_TITLE') }}</h2>
					<h2 data-cy="logInTitle" v-else class="authTitle">{{ $t('auth.SIGN_UP_NEW_HEAD') }}</h2>

					<!-- Page text for all states -->
					<div v-if="loginVerifying">
						<p ref="verifiction_text" data-cy="logInDescription" class="authText fade">
							{{ $t('auth.VERIFYING_YOUR_ACCOUNT_DESCRIPTION_1') }}</p>
					</div>


					<p data-cy="logInDescription"
						v-else-if="$route.params.status == 'email' && metamaskAlreadyRegistered" class="authText">{{
							$t('auth.METAMASK_ALREADY_REGISTERED') }}</p>
					<p data-cy="logInDescription" v-else-if="$route.params.status == 'email'" class="authText">{{
						$t('auth.SIGNUP_METAMASK_TEXT') }}</p>
					<p data-cy="logInDescription"
						v-else-if="$route.params.status == 'success' && verifyError == 'verify'" class="authText">{{
							$t('auth.SET_UP_METAMASK_ERR_VERIFY') }}</p>
					<p data-cy="logInDescription"
						v-else-if="$route.params.status == 'success' && verifyError == 'metamask'" class="authText">{{
							$t('auth.SET_UP_METAMASK_CHAIN_1') }}</p>
					<p data-cy="logInDescription" v-else-if="$route.params.status == 'verify'" class="authText">{{
						$t('auth.VERIFY_EMAIL_DESCRIPTION') }}</p>
					<p data-cy="logInDescription" v-else-if="$route.params.status == 'success'" class="authText">{{
						$t('auth.SET_UP_METAMASK_TEXT') }}</p>
					<p data-cy="logInDescription" v-else-if="$route.params.status == 'rejected'" class="authText"
						v-html="$t('auth.ACCOUNT_REJECTED_DESCRIPTION')"></p>
					<p data-cy="logInDescription" v-else-if="geoBlocked" class="authText">{{ $t('MORPHER_NOT_AVAILABLE')
						}}</p>
					<p data-cy="logInDescription" v-else-if="twoFA" class="authText">{{ $t('auth.2FA_DESCRIPTION') }}
					</p>
					<p data-cy="logInDescription" v-else-if="!passwordSignup" class="authText">{{
						$t('auth.SIGN_UP_NEW_TITLE') }}</p>
					<p data-cy="logInDescription" v-else class="authText">{{ $t('auth.SIGN_UP_NEW') }}</p>
					<p data-cy="logInDescription" v-if="$route.params.status == 'success' && verifyError == 'metamask'"
						class="authText" v-html="$t('auth.SET_UP_METAMASK_CHAIN_2')"></p>

					<div v-if="signupDisabled" style="color: #FF0000; margin-top: 30px;">

						Signups are temporarily disabled. Check back in 30 minutes.
					</div>
					<div v-else>
						<!-- Signup / Login Processing  -->
						<div v-if="loginVerifying" class="pt-30">
							<p class="p">
								<img class="loader-icon" src="@/assets/icons/spinner.svg" />
							</p>

							<p class="loginLink">
								<span>{{ $t('auth.ALREADY_HAVE_ACCOUNT') }}&nbsp;</span>
								<a @click="loginVerifying = false" class="login-router transition-faster">
									<span data-cy="signUpButton">
										{{ $t('auth.LOGIN') }}
									</span>
								</a>
							</p>

						</div>
						<!-- Resend metamask signup email  -->
						<div v-else-if="$route.params.status == 'success' && verifyError == 'verify'"
							class="login-buttons">

							<p class="loginLink">
								<span>{{ $t('auth.SET_UP_METAMASK_ERR_RETRY') }}</span>
								<a @click="verifyResend" class="login-router transition-faster" v-if="!codeResend">
									<span data-cy="signUpButton">
										&nbsp;{{ $t('auth.SEND_AGAIN') }}
									</span>
								</a>
							</p>

							<p class="loginLink">
								<router-link to="/login" class="login-router transition-faster"><span>{{
									$t('auth.CANCEL') }}</span></router-link>
							</p>
						</div>

						<!-- metamask sidechain manual configuration  -->
						<div v-else-if="$route.params.status == 'success' && verifyError == 'metamask'"
							class="login-buttons">
							<p class="has-text-weight-medium mt-1">{{ $t('network.RPC_URL') }}</p>
							<div class="field is-grouped">
								<div class="control is-expanded">
									<b-input v-model="sidechainURL" type="url" readonly />
								</div>
								<div class="control">
									<button type="button" class="button is-info transition-faster copy-btn"
										@click="copyTextToClipboard">
										<b-icon icon="fas fa-copy" pack="fas" size="is-small" />
									</button>
								</div>
							</div>
							<button type="submit" @click="loginMetamask" data-cy="submit"
								:class="{ 'is-loading': loginProcessingMetamask }"
								class="button is-login transition-faster mt-15 is-fullwidth-mobile">
								<span class="text">{{ $t('CONTINUE') }}</span>
							</button>

							<p class="loginLink">
								<router-link to="/login" class="login-router transition-faster"><span>{{
									$t('auth.CANCEL') }}</span></router-link>
							</p>
						</div>

						<!-- metamask auto configuration processing  -->
						<div v-else-if="$route.params.status == 'success'" class="login-buttons">
							<p>
								<img class="loader-icon" src="@/assets/icons/loader.svg" style="width: 50px" />
							</p>
							<p class="is-size-14" v-if="verifyStatus == 'email'">
								{{ $t('auth.SET_UP_METAMASK_VERIFY') }}
							</p>
						</div>

						<!-- account rejected / blocked  -->
						<div v-else-if="$route.params.status == 'rejected'" class="login-buttons">
							<p class="is-size-14 has-text-left">
								{{ $t('auth.ACCOUNT_REJECTED_DESCRIPTION_DETAIL_1') }}
							<ul>
								<li>
									{{ $t('auth.ACCOUNT_REJECTED_DESCRIPTION_DETAIL_2') }}
								</li>
								<li>
									{{ $t('auth.ACCOUNT_REJECTED_DESCRIPTION_DETAIL_3') }}
								</li>
								<li>
									{{ $t('auth.ACCOUNT_REJECTED_DESCRIPTION_DETAIL_4') }}
								</li>
								<li>
									{{ $t('auth.ACCOUNT_REJECTED_DESCRIPTION_DETAIL_5') }}
								</li>
							</ul>
							</p>




							<p class="is-size-14 has-text-left"
								v-html="$t('auth.ACCOUNT_REJECTED_DESCRIPTION_DETAIL_6')"></p>

							<p class="loginLink">
								<span>{{ $t('auth.ALREADY_HAVE_ACCOUNT') }}&nbsp;</span>
								<router-link to="/login" class="login-router transition-faster">
									<span data-cy="signUpButton">
										{{ $t('auth.LOGIN') }}
									</span>
								</router-link>
							</p>
						</div>

						<!-- Verify email address  -->
						<div v-else-if="$route.params.status == 'verify'" class="login-buttons">
							<div class="columns is-vcentered is-centered mt-15 wallet-box">
								<div class="column is-narrow is-hidden-mobile" />
								<div class="column is-narrow">
									<img src="@/assets/auth/sign-up-email-icon.svg" class="buttonIcon"
										alt="Email Logo" />
								</div>

								<div class="column has-text-left">
									<p class="is-size-14">
										<b>{{ $t('EMAIL_HEADER') }}</b>
									</p>

									<p class="is-size-14">
										{{ verifyEmail }}
									</p>
								</div>
							</div>

							<p class="loginLink">
								<span>{{ $t('auth.DIDNT_GET_EMAIL') }}</span>
								<a @click="verifyResend" class="login-router transition-faster" v-if="!codeResend">
									<span data-cy="signUpButton">
										&nbsp;{{ $t('auth.SEND_AGAIN') }}
									</span>
								</a>
							</p>

							<p class="loginLink">
								<router-link to="/login" class="login-router transition-faster"><span>{{
									$t('auth.CANCEL') }}</span></router-link>
							</p>
						</div>

						<!-- Metamask email address input  -->
						<div v-else-if="$route.params.status == 'email'" class="login-buttons">

							<div class="columns is-vcentered is-centered mt-15 wallet-box">
								<div class="column is-narrow is-hidden-mobile" />
								<div class="column is-narrow">
									<img v-if="blockie" class="blockie" :src="blockie" />
								</div>
								<div class="column has-text-left">
									<p class="is-size-14">
										<b>{{ wallet_type == 'walletconnect' ? $t('WALLETCONNECT_WALLET') :
											$t('METAMASK_WALLET') }}</b>
									</p>

									<p class="is-size-14">
										{{ formatEthAddressLong(metamaskEthAddress) }}
									</p>
								</div>
							</div>

							<div class="field">
								<div v-if="metamaskAlreadyRegistered">

									<button
										class="button big-outlined-button transition-faster button-spacing loginButton mt-10"
										@click="loginMetamask" :class="{ 'is-loading': loginProcessingMetamask }"
										data-cy="appleButton" :disabled="!isMetamaskSupported">
										<span class="icon img">
											<img src="@/assets/wallet/metamask_logo.svg" class="buttonIcon"
												alt="Metamask Logo" />
										</span>
										<span class="ml-5px">{{ $t('auth.LOG_IN_METAMASK') }} </span>
									</button>
								</div>
								<div v-else>
									<label class="label">{{ $t('auth.EMAIL') }}</label>

									<div class="control">
										<input data-sentry-unmask type="email" class="input sentry-unmask"
											ref="metamask_email" data-cy="walletEmail"
											:placeholder="$t('auth.ENTER_EMAIL')" name="walletEmail"
											:class="{ 'email-error': !emailValidation }" v-model="walletEmail" />
									</div>
									<div class="error" v-if="!emailValidation">
										<p data-cy="emailError">
											{{ emailMessage }}
										</p>
									</div>

									<div class="is-size-14">
										<div class="mt-15 checkBoxContainer">
											<div>
												<b-checkbox type="is-success" v-model="consent_email"
													class="checkBox" />
											</div>
											<div class="consent-text">
												<p>{{ $t('auth.RECEIVE_EMAIL_UPDATES') }}</p>
											</div>
										</div>
										<div class="mt-15 checkBoxContainer">
											<div>
												<b-checkbox type="is-success" v-model="consent_approve"
													class="checkBox" />
											</div>
											<div class="consent-text" v-html="$t('auth.OVER_18_TOS', {
												privacyPolicyLink: 'https://www.morpher.com/privacy',
												tosLink: 'https://www.morpher.com/terms-of-use'
											})
												"></div>
										</div>
									</div>

									<button type="submit" @click="submitMetamask" data-cy="submit"
										:disabled="!walletEmail || !consent_approve || !emailValidation"
										:class="{ 'is-loading': loginProcessingMetamask }"
										class="button is-login transition-faster mt-15 is-fullwidth-mobile">
										<span class="text">{{ $t('CONTINUE') }}</span>
									</button>
								</div>

								<p class="loginLink">
									<a @click="cancelMetamask" class="login-router transition-faster">
										<span data-cy="signUpButton">
											{{ $t('CANCEL') }}
										</span>
									</a>
								</p>

								<div class="error" v-if="logonError">
									<p data-cy="loginError">
										⚠️ <span v-html="logonError"></span>
										<router-link v-if="showRecovery" to="/recovery"
											class="login-router transition-faster">
											<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
										</router-link>
									</p>
								</div>
							</div>
						</div>

						<!-- Geoblocked IP country -->
						<div v-else-if="geoBlocked">

							<div class="columns is-vcentered is-centered mt-15px">
								<div class="column is-narrow is-hidden-mobile" />
								<div class="column is-narrow">
									<span
										:class="'flag-icon fi fis fi-' + geoLocation.country_code?.toLowerCase()"></span>
								</div>
								<div class="column has-text-left">
									<p class="is-size-14">
										<b>{{ $t('auth.USING_VPN') }}</b> {{ geoLocation.country_code || '' }}
									</p>

									<p class="is-size-14" v-html="$t('auth.USING_VPN_DESCRIPTION', {
										link: supportArticleLink('SignupCountries')
									})
										"></p>
								</div>
							</div>


							<p class="loginLink">
								<span>{{ $t('auth.ALREADY_HAVE_ACCOUNT') }}&nbsp;</span>
								<router-link to="/login" class="login-router transition-faster">
									<span data-cy="signUpButton">
										{{ $t('auth.LOGIN') }}
									</span>
								</router-link>
							</p>
						</div>

						<!-- 2fa code -->
						<div v-else-if="twoFA">
							<div class="field">
								<label class="label">{{ $t('auth.EMAIL_CODE') }}</label>
								<div class="control">
									<input type="email" class="input" ref="login_2fa" data-cy="twofaCode"
										:placeholder="$t('auth.EMAIL_CODE')" name="walletEmail" v-model="twofaCode" />
								</div>
								<button type="submit" @click="submit2fa" data-cy="submit"
									:class="{ 'is-loading': loginProcessing }"
									class="button is-login transition-faster mt-15 is-fullwidth-mobile">
									<span class="text">{{ $t('auth.SUBMIT') }}</span>
								</button>

								<div class="error" v-if="logonError">
									<p data-cy="loginError">
										⚠️ <span v-html="logonError"></span>
										<router-link v-if="showRecovery" to="/recovery"
											class="login-router transition-faster">
											<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
										</router-link>
									</p>
								</div>
							</div>
						</div>

						<!-- Signup - Options Google/apple/metamask/email   -->
						<div v-else-if="!passwordSignup" class="login-buttons">

							<LoginGoogle @processMethod="processMethod" :signIn="true" :loading="loginProcessingGoogle">
							</LoginGoogle>

							<button type="submit" @click="passwordSignup = true" data-cy="submit"
								:class="{ 'is-loading': loginProcessing }"
								class="button big-outlined-button transition-faster button-spacing loginButton">
								<span class="icon img">
									<img src="@/assets/auth/sign-up-email-icon.svg" class="buttonIcon"
										alt="Metamask Logo" />
								</span>
								<span class="ml-5px">{{ $t('auth.SIGN_UP_EMAIL') }}</span>
							</button>

							
							<div class="loginDiv">
								<div class="loginDivider"></div>
								{{ $t('auth.OR') }}
								<div class="loginDivider"></div>
							</div>
							
							<div class="loginOptions" :style="{'justify-content': !walletConnectEnabled ? 'center' : 'space-between'}">
								
								<LoginApple class="icon-only"  @processMethod="processMethod" :loading="loginProcessingApple"></LoginApple>
								<button
									class="button big-outlined-button transition-faster button-spacing loginButton icon-only"
									:class="{ 'is-loading': loginProcessingWalletConnect }" @click="signupWalletConnect"
									data-cy="metamaskButton" v-if="walletConnectEnabled">
									<span class="icon img">
										<img v-show="!loginProcessingWalletConnect" src="@/assets/wallet/walletconnect.svg" class="buttonIcon"
											alt="Metamask Logo" />
									</span>
								</button> 

								<button
								class="button big-outlined-button transition-faster button-spacing loginButton  icon-only"
								:class="{ 'is-loading': loginProcessingMetamask }" @click="signupMetamask"
								data-cy="metamaskButton">
								<span class="icon img">
									<img v-show="!loginProcessingMetamask" src="@/assets/wallet/metamask_logo.svg" class="buttonIcon"
										alt="Metamask Logo" />
								</span>
								
							</button>


								<!-- <LoginApple @processMethod="processMethod" :signIn="false" :show-text="false" :loading="loginProcessingApple">
								</LoginApple> -->
							</div>
							

							<div class="error" v-if="logonError">
								<p data-cy="loginError">
									⚠️ <span v-html="logonError"></span>
									<router-link v-if="showRecovery" to="/recovery"
										class="login-router transition-faster">
										<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
									</router-link>
								</p>
							</div>

							<p class="loginLink">
								<span>{{ $t('auth.ALREADY_HAVE_ACCOUNT') }}&nbsp;</span>
								<router-link to="/login" class="login-router transition-faster">
									<span data-cy="signUpButton">
										{{ $t('auth.LOGIN') }}
									</span>
								</router-link>
							</p>

						</div>
						<!-- Email / password signup   -->
						<div v-else>
							<div class="field">
								<label class="label">{{ $t('auth.EMAIL') }}</label>
								<div class="control">
									<input type="email" class="input sentry-unmask" ref="login_email"
										@keydown="checkKeyPress" data-cy="walletEmail"
										:placeholder="$t('auth.ENTER_EMAIL')" name="walletEmail"
										:class="{ 'email-error': !emailValidation }" data-sentry-unmask
										v-model="walletEmail" />
									<div class="error" v-if="!emailValidation">
										<p data-cy="emailError">
											{{ emailMessage }}
										</p>
									</div>
								</div>

								<div class="field">
									<label class="label">{{ $t('auth.PASSWORD') }}</label>

									<div class="control">
										<input type="password" class="input" ref="login_password"
											@keydown="checkKeyPress" data-cy="walletPassword"
											:placeholder="$t('auth.ENTER_PASSWORD')" name="walletPassword"
											v-model="walletPassword" />
										<button class="password-toggle" v-on:click="togglePasswordVisibility"
											data-cy="password-toggle-button" tabindex="-1">
											<img v-if="passwordIsVisible" class="image"
												src="@/assets/auth/password-visible-icon.svg" alt="Visible Button" />
											<img v-if="!passwordIsVisible" class="image" tabindex="-1"
												src="@/assets/auth/password-invisible-icon.svg"
												alt="Invisible Button" />
										</button>
										<password v-model="walletPassword" :strength-meter-only="true"
											:secure-length="8" style="max-width: initial" />
										<div class="password-help">
											<p>{{ $t('auth.REQUIREMENTS') }}</p>
											<ul class="items">
												<li :class="{
													done: passwordChecks.min === 'pass',
													fail: passwordChecks.min === 'fail'
												}">
													{{ $t('auth.MIN_CHARACTERS') }}
												</li>
												<li :class="{
													done: passwordChecks.lowercase === 'pass',
													fail: passwordChecks.lowercase === 'fail'
												}">
													{{ $t('auth.LOWERCASE_LETTER') }}
												</li>
												<li :class="{
													done: passwordChecks.uppercase === 'pass',
													fail: passwordChecks.uppercase === 'fail'
												}">
													{{ $t('auth.UPPERCASE_LETTER') }}
												</li>
												<li :class="{
													done: passwordChecks.number === 'pass',
													fail: passwordChecks.number === 'fail'
												}">
													{{ $t('auth.NUMBER') }}
												</li>
												<li :class="{
													done: passwordChecks.match === 'pass',
													fail: passwordChecks.match === 'fail'
												}">
													{{ $t('auth.PASSWORD_MATCH') }}
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="field">
									<label class="label">{{ $t('auth.PASSWORD_CONFIRM') }}</label>

									<div class="control">
										<input type="password" class="input" ref="login_password_confirm"
											@keydown="checkKeyPress" data-cy="walletPasswordConfirm"
											name="walletPasswordConfirm" v-model="walletPasswordConfirm" />
										<button class="confirm-password-toggle"
											v-on:click="toggleConfirmPasswordVisibility"
											data-cy="password-toggle-button" tabindex="-1">
											<img v-if="confirmPasswordIsVisible" class="image"
												src="@/assets/auth/password-visible-icon.svg" alt="Visible Button" />
											<img v-if="!confirmPasswordIsVisible" tabindex="-1" class="image"
												src="@/assets/auth/password-invisible-icon.svg"
												alt="Invisible Button" />
										</button>
									</div>
								</div>

								<div class="is-size-14 mt-20">
									<div class="mt-15 checkBoxContainer">
										<div>
											<b-checkbox v-model="consent_email" class="checkBox" />
										</div>
										<div class="consent-text">
											<p>{{ $t('auth.RECEIVE_EMAIL_UPDATES') }}</p>
										</div>
									</div>
									<div class="mt-15 checkBoxContainer">
										<div>
											<b-checkbox v-model="consent_approve" class="checkBox" />
										</div>
										<div class="consent-text" v-html="$t('auth.OVER_18_TOS', {
											privacyPolicyLink: 'https://www.morpher.com/privacy',
											tosLink: 'https://www.morpher.com/terms-of-use'
										})
											"></div>
									</div>
								</div>

								<button type="submit" @click="signup('email')" data-cy="submit"
									:disabled="!consent_approve || passwordChecks.min !== 'pass' || passwordChecks.uppercase !== 'pass' || passwordChecks.lowercase !== 'pass' || passwordChecks.number !== 'pass' || passwordChecks.match !== 'pass' || !emailValidation"
									:class="{ 'is-loading': loginProcessing }"
									class="button is-login transition-faster mt-15 is-fullwidth-mobile">
									<span class="text">{{ $t('auth.CREATE_WALLET') }}</span>
								</button>

								<div class="error" v-if="logonError">
									<p data-cy="loginError">
										⚠️ <span v-html="logonError"></span>
										<router-link v-if="showRecovery" to="/recovery"
											class="login-router transition-faster">
											<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
										</router-link>
									</p>
								</div>

								<p class="loginLink">
									<span>{{ $t('auth.ALREADY_HAVE_ACCOUNT') }}&nbsp;</span>
									<router-link to="/login" class="login-router transition-faster">
										<span data-cy="signUpButton">
											{{ $t('auth.LOGIN') }}
										</span>
									</router-link>
								</p>

								<div @click="passwordSignup = false" class="login-router transition-faster mt-15">
									<span data-cy="backUpButton">
										&lt; {{ $t('auth.BACK') }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <vue-recaptcha
			ref="recaptcha"
			size="invisible"
			:sitekey="recaptchaSiteKey"
			:load-recaptcha-script="true"
			@verify="onCaptchaVerified"
			@error="onCaptchaError"
			@expired="onCaptchaExpired"
			@render="onCaptchaLoaded"
		/> -->
	</div>
</template>

<script lang="ts">
//vuex
import { useAdminStore } from '@/store/modules/admin';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { ERROR } from '@/store/mutation-types';

//import { getDictionaryValue } from '@/helpers/wallet/dictionary';
import { accessTokenName, refreshTokenName, getUserID, copyToClipboard } from '@/helpers/utils';
import deviceType from '@/helpers/deviceType';
import { UserService } from '@/services/UserService';
import LoginApple from './LoginApple.vue';
import LoginGoogle from './LoginGoogle.vue';
import * as blockies from 'ethereum-blockies-png';
//import RecaptchaMixin from '@/mixins/recaptcha.mixin';
import AuthMixin from '@/mixins/auth.mixin';
//import VueRecaptcha from 'vue-recaptcha';
import Cookie from 'js-cookie';
import { morpherWalletConfig } from '@/helpers/morpherwallet';
import Password from 'vue-simple-password-meter';
import util from '@/services/shared';
import MetaMaskOnboarding from '@metamask/onboarding';
import { morpherTokenAbi } from '@/contracts/abis';
import EventBus from '@/store/event-bus';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import * as Sentry from '@sentry/vue'
// import { WalletConnectModal } from '@walletconnect/modal'

// import UniversalProvider from '@walletconnect/universal-provider'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { defineComponent } from 'vue';
import { toHex, type WalletClient } from 'viem';
import { ESideChainTransaction, getContract, getWalletClient } from '@/helpers/viem';
import type { TAddress, TMorpherWalletInit } from '@/types/graphql-global-types';
import LoginMetamask from './LoginMetamask.vue';



const sendBingTrackingEvent = async () => {
	(function (w: any, d, t, r, u) {
		var f: any, n: any, i: any;
		(w[u] = w[u] || []),
			(f = function () {
				var o: any = { ti: ' 136021220' };
				(o.q = w[u]), (w[u] = new w.UET(o)), w[u].push('pageLoad');
			}),
			(n = d.createElement(t)),
			(n.src = r),
			(n.async = 1),
			(n.onload = n.onreadystatechange =
				function () {
					var s = this.readyState;
					(s && s !== 'loaded' && s !== 'complete') || (f(), (n.onload = n.onreadystatechange = null));
				}),
			(i = d.getElementsByTagName(t)[0]),
			i.parentNode.insertBefore(n, i);
	})(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');

	window.uetq = window.uetq || [];
	window.uetq.push('event', 'signup', {});
};

/* @group Components_Auth */
/*
  <h4> <b>Login Form</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'WalletSignup',
	components: { Password, LoginApple, LoginGoogle, LoginMetamask }, //, 'vue-recaptcha': VueRecaptcha
	mixins: [AuthMixin], // RecaptchaMixin
	data() {
		return {
			passwordIsVisible: false,
			confirmPasswordIsVisible: false,
			twoFA: false,
			walletEmail: '',
			walletPassword: '',
			walletPasswordConfirm: '',
			walletError: '',
			logonError: '',
			loginUser: {} as any,
			initialising: true,
			loginProcessing: false,
			loginProcessingGoogle: false,
			loginProcessingApple: false,
			loginProcessingMetamask: false,
			loginProcessingWalletConnect: false,
			showRecovery: false,
			walletClientlogin: {} as any,
			morpherWalletInit: {} as any,
			consent_approve: false,
			consent_email: false,
			passwordSignup: false,
			sidechainURL: import.meta.env.VITE_SIDECHAIN,
			chainId: import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID,
			twofaCode: '',
			metamaskEthAddress: '',
			blockie: '',
			verifyEmail: '',
			loginVerifying: false,
			verifycount: 0,
			passwordChecks: {
				min: '',
				uppercase: '',
				lowercase: '',
				number: '',
				match: ''
			},
			loading: {
				fortmatic: false,
				portis: false,
				metamask: false,
				morpherwallet: false
			},
			loaded: {
				fortmatic: false,
				fortmaticInterval: 0,
				fortmaticLoggedIn: false,
				morpherWalletLoggedIn: false
			},
			geoBlocked: false,
			geoLocation: { country_code: '', country: '' } as { country_code?: string, country?: string, Error?: any },
			codeResend: false,
			verifyStatus: '',
			verifyError: '',
			userData: '' as any,
			isMetamaskSupported: false,
			walletTimer: null as null | NodeJS.Timeout | number,
			emailValidation: true,
			emailMessage: '',
			emailTimeout: null as null | NodeJS.Timeout | number,
			interval: null as null | NodeJS.Timeout | number,
			metamaskAlreadyRegistered: false,
			walletConnectUri: '',
			walletconnectProvider: null as any,
			wallet_type: '',
			walletConnectEnabled: import.meta.env.VITE_MODE !== 'production',
			default_wallet: '',
			showMetamaskHelp: false,
			airdrop_id: '',
			referred_by: '',
			custom_invite: '',
			morpherwallet: null as any,
			eth_address: '',
			signupDisabled: false
		};
	},
	computed: {
		...mapState(useAdminStore, {
			config: (state) => state.config,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useContractStore, {
			contract: (state) => state as any,
		}),
		...mapState(useStatusStore, {
			sentry_recording: (state) => state.sentry_recording,
		}),

	},
	watch: {
		loginVerifying(newValue) {

			if (newValue) {
				this.verifycount = 1;

				if (this.interval) {
					clearInterval(this.interval)
					this.interval = null;
				}
				this.interval = setInterval(() => {
					this.verifycount += 1;
					if (this.verifycount == 3) {
						if (this.$refs.verifiction_text) {
							(this.$refs.verifiction_text as InstanceType<typeof HTMLParagraphElement>).className = 'authText fade2'
						}
						if (this.interval)
							clearInterval(this.interval)
						this.interval = null;

					}
					if (this.$refs.verifiction_text) {

						(this.$refs.verifiction_text as InstanceType<typeof HTMLParagraphElement>).innerText = this.$t('auth.VERIFYING_YOUR_ACCOUNT_DESCRIPTION_' + this.verifycount);
					}


				}, 3000)
			} else {
				if (this.interval) {
					clearInterval(this.interval)
					this.interval = null;
				}
			}
		},
		walletError(newValue) {
			if (newValue) {
				// If there is a logon error then log it to sentry
				this.logSentryError('Logon Error:' + newValue);
				this.error({ message: 'WALLET_CONNECT_ERROR' });
			}
		},

		walletPassword(newValue) {
			this.passwordChecks = this.checkPassword(newValue, false, this.passwordChecks, this.walletPasswordConfirm);
		},

		walletPasswordConfirm(newValue) {
			this.passwordChecks = this.checkPassword(this.walletPassword, false, this.passwordChecks, newValue, true);
		},
		twofaCode(nv) {
			if (nv && nv.length == 6) {
				this.submit2fa();
			}
		},
		walletEmail() {
			this.emailValidation = true;
			this.emailMessage = '';

			if (this.emailTimeout) {
				clearTimeout(this.emailTimeout);
				this.emailTimeout = null;
			}
			this.emailTimeout = setTimeout(() => {
				this.emailTimeout = null;
				this.validateEmail();
			}, 1000);
		},
	},
	async mounted() {
		// Fill emailOrUsernameStorage from url 'account' query parameter
		if (this.$route.query.account !== undefined) {
			localStorage.setItem('emailOrUsernameStorage', this.$route.query.account)
		}

		if (localStorage.getItem('walletType')) {
			this.default_wallet = localStorage.getItem('walletType') || '';
		}

		// browsers that support metamask
		const browserMetamask = ['chrome', 'brave', 'opera', 'yandex', 'firefox', 'edge'];

		// return only the first word od the browser name
		const browser = navigator.sayswho.replace(/ .*/, '').toLowerCase();

		if (browserMetamask.includes(browser) || deviceType.isAnyMobile()) {
			this.isMetamaskSupported = true;
			this.showMetamaskHelp = false;
		}

		this.airdrop_id = this.$route.query.airdrop_id ? this.$route.query.airdrop_id : null;
		this.referred_by = this.$route.query.referred_by ? this.$route.query.referred_by : null;
		this.custom_invite = this.$route.query.custom_invite ? this.$route.query.custom_invite : null;

		if (this.referred_by) localStorage.setItem('airdrop_referred_by', this.referred_by);
		else this.referred_by = localStorage.getItem('airdrop_referred_by') || '';

		if (this.custom_invite) localStorage.setItem('airdrop_custom_invite', this.custom_invite);
		else this.custom_invite = localStorage.getItem('airdrop_custom_invite') || '';

		this.initialising = false;

		this.initMorpherWallet();
		if (this.$route.params.status == 'email' && !this.metamaskEthAddress) {
			await this.$router.push('/register').catch(() => { });
		}


		this.verifyEmail = localStorage.getItem('emailOrUsernameStorage') || '';

		if (this.$route.params.status == 'verify' && !this.verifyEmail) {
			await this.$router.push('/login').catch(() => { });
		}

		if (this.$route.params.status == 'success') {
			if (!this.$route.query.token) {
				await this.$router.push('/login').catch(() => { });
			} else {
				this.verifyEmailExecute();
			}
		}

		this.checkSentryRecord();

		this.initWalletConnect();

	},
	beforeUnmount() {
		if (this.morpherwallet) {
			this.morpherwallet.onLogin(() => { });
		}
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
		}),
		...mapActions(useUserStore, {
			loginWallet: 'loginWallet',
			confirmWallet: 'confirmWallet',
			checkGeoblock: 'checkGeoblock',
			resendVerifyEmail: 'resendVerifyEmail',
			verifyEmailAction: 'verifyEmail',
			validateRegistrationField: 'validateRegistrationField',
		}),
		...mapActions(useContractStore, {
			setWalletClient: 'setWalletClient',
			setMorpherWalletProvider: 'setMorpherWalletProvider',
		}),
		...mapActions(useStatusStore, {
			setRecording: 'setRecording',
		}),
		checkMetamask() {
			const browserMetamask = ['chrome', 'brave', 'opera', 'yandex', 'firefox', 'edge'];

			// return only the first word od the browser name
			const browser = navigator.sayswho.replace(/ .*/, '').toLowerCase();

			if (!browserMetamask.includes(browser) && !deviceType.isAnyMobile()) {
				this.showMetamaskHelp = true;
			} else {
				if (!window.ethereum || !MetaMaskOnboarding.isMetaMaskInstalled()) {
					this.showMetamaskHelp = true;
				}
			}

		},
		async initWalletConnect() {
			try {

				this.walletconnectProvider = await EthereumProvider.init({
					projectId: '4823d29af138b15b9c4cec762fb1916e',
					metadata: {
						name: 'Morpher',
						description: 'Morpher Trading Web Application',
						url: 'https://www.morpher.com', // origin must match your domain & subdomain
						icons: ['https://www.morpher.com/favicon.ico']
					},
					showQrModal: true,
					//chains: [210],

					optionalChains: [137],

					/*Optional - Add custom RPCs for each supported chain*/
					rpcMap: {
						137: 'http://ec2-3-68-113-82.eu-central-1.compute.amazonaws.com:8546'

					}
				})



				//  Initialize the provider
				// const provider = await UniversalProvider.init({
				// projectId: '4823d29af138b15b9c4cec762fb1916e',
				// metadata: {
				// 	name: 'React App',
				// 	description: 'React App for WalletConnect',
				// 	url: 'https://walletconnect.com/',
				// 	icons: ['https://avatars.githubusercontent.com/u/37784886']
				// },
				// client: undefined // optional instance of @walletconnect/sign-client
				// })

				this.walletconnectProvider.on('display_uri', (uri: any) => {
					this.walletConnectUri = uri
				})

				this.walletconnectProvider.on('accountsChanged', (account: any) => {
					console.log('account', account)
				})

				this.walletconnectProvider.on("connect", (accounts: any) => {
					console.log('accounts', accounts)

					console.info('connect', this.walletconnectProvider.accounts)
					//modal.closeModal()
				});



				//  create sub providers for each namespace/chain
				// await provider.connect({
				// 	optionalNamespaces: {
				// 		eip155: {
				// 		methods: [
				// 			'eth_sendTransaction',
				// 			'eth_signTransaction',
				// 			'eth_sign',
				// 			'personal_sign',
				// 			'eth_signTypedData'
				// 		],
				// 		chains: ['eip155:80002', 'eip155:210'],
				// 		events: ['chainChanged', 'accountsChanged'],
				// 		rpcMap: {
				// 			80002:
				// 			'https://polygon-amoy.infura.io/v3/598403d89a3243edb17eacce90925614',
				// 			210: 'https://sidechain-test.morpher.com'

				// 		}
				// 		}
				// 	},
				// 	// pairingTopic: '<123...topic>', // optional topic to connect to
				// 	skipPairing: false // optional to skip pairing ( later it can be resumed by invoking .pair())
				// })


			} catch (err: any) {
				console.log('error initializing wallet - ' + err.toString())
			}
		},

		async checkSentryRecord() {
			try {
				let country_list = ['CH', 'AT', 'DE', 'CA', 'FR', 'GB', 'NL', 'BE', 'RO', 'PL', 'AU', 'IT'];
				if (import.meta.env.VITE_MODE === 'production') {
					this.geoLocation = await util.getLocation();
					const geoLoc = await this.checkGeoblock({ location: this.geoLocation ? this.geoLocation.country_code : '' });
					if (geoLoc && geoLoc.country_code && country_list.includes(geoLoc.country_code.toUpperCase())) {
						await Sentry.setTag("session_recording_reason", "eu_signup");

						if (!this.sentry_recording) {
							this.setRecording()

							import("@sentry/vue").then((lazyLoadedSentry) => {


								Sentry.addIntegration(lazyLoadedSentry.replayIntegration(
									{
										maskAllText: false,
										blockAllMedia: true,
									})
								);
							});

						}


					}
				}
			} catch (err: any) {
				console.log('error setting up sentry:' + err.toString())
			}
		},
		// validate the email input data
		async validateEmail() {

			// Process the email validation
			if (this.walletEmail === '') {
				this.emailValidation = true;
				this.emailMessage = '';
			} else {

				//this.$t('USERREG_EMAILEMPTY')
				this.validateRegistrationField({
					id: null,
					fieldName: 'email',
					inputFieldValue: this.walletEmail
				}).then(returnData => {
					this.emailValidation = returnData.success;
					// Get the error message from the language file
					this.emailMessage = returnData.returnMessage
						.replace('EmailFormat', this.$t('USERREG_EMAILFORMAT'))
						.replace('EmailMXRecord', this.$t('USERREG_EMAILMX'))
						.replace('EmailBlacklisted', this.$t('USERREG_EMAILBLACKLIST'))
						.replace('EmailAlreadyUsed', this.$t('USERREG_EMAILALREADYUSED'));
				});
			}
		},
		togglePasswordVisibility() {
			this.passwordIsVisible = !this.passwordIsVisible;
			(this.$refs.login_password as InstanceType<typeof HTMLInputElement>).type = this.passwordIsVisible ? 'text' : 'password';
		},
		toggleConfirmPasswordVisibility() {
			this.confirmPasswordIsVisible = !this.confirmPasswordIsVisible;
			(this.$refs.login_password_confirm as InstanceType<typeof HTMLInputElement>).type = this.confirmPasswordIsVisible ? 'text' : 'password';
		},
		async verifyEmailExecute() {
			this.verifyStatus = 'email';
			const user = await this.verifyEmailAction({ token: this.$route.query.token });
			if (!user || !user.id || user.status !== 'confirmed') {
				this.verifyStatus = 'error';
				this.verifyError = 'verify';
			} else {
				this.userData = user;
				this.metamaskSetup();
			}

		},
		getNetworkParams() {
			const chainRPC = import.meta.env.VITE_SIDECHAIN;
			const scan = import.meta.env.VITE_ETHERSCAN_URL;
			const environment = import.meta.env.VITE_MODE;
			let chainId = '0x15'
			if (import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID) {
				chainId = toHex(Number(import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID))
			}

			return {
				chainId: chainId,
				chainName: 'Morpher Sidechain' + (environment === 'development' || environment === 'staging' ? ' - Test' : ''),
				nativeCurrency: { name: 'GAS', symbol: 'GAS', decimals: 18 },
				rpcUrls: [chainRPC],
				blockExplorerUrls: [scan]
			};
		},
		copyTextToClipboard() {
			copyToClipboard(this.sidechainURL, this);
		},
		async metamaskSetup() {
			try {
				const walletClientinstance = getWalletClient(window.ethereum);;
				this.walletClientlogin = walletClientinstance;
				this.setWalletClient(walletClientinstance);
				this.verifyStatus = 'metamask';
				const onboarding = new MetaMaskOnboarding();

				// if metamask is not installed the display the insall metamask message
				if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
					if (deviceType.isAnyMobile()) {
						if (import.meta.env.VITE_METAMASK_DEEP_LINK_REGISTRATION)
							window.location.href = import.meta.env.VITE_METAMASK_DEEP_LINK_REGISTRATION;
						return;
					}

					onboarding.startOnboarding();
					this.verifyError = 'metamask';

					return;
				}

				const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });

				if (!addresses || addresses.length == 0 || addresses[0].toLowerCase() !== this.userData.eth_address) {
					this.verifyError = 'metamask';
					return;
				}

				const network_result = await window.ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [this.getNetworkParams()]
				})

				const wasAdded = await window.ethereum.request({
					method: 'wallet_watchAsset',
					params: {
						type: 'ERC20',
						options: {
							address: import.meta.env.VITE_MORPHER_TOKEN_SIDECHAIN,
							symbol: 'MPH',
							decimals: 18,
							image: 'https://assets.coingecko.com/coins/images/12619/small/morpher_200_200.png?1601524084'
						}
					}
				});

				const result = await window.ethereum.request({ method: 'eth_chainId' });


				const isWrongNetwork = Number(result) !== Number(import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID);

				if (isWrongNetwork) {
					this.verifyError = 'metamask';
					return;
				}


				const tokenContract = await getContract(ESideChainTransaction.TOKEN, undefined, walletClientinstance);

				const symbol = await tokenContract.contract.read.symbol();

				if (symbol === 'MPH') {
					this.loginMetamask();
				} else {
					this.verifyError = 'metamask';
					return;
				}
			} catch (err) {
				this.verifyError = 'metamask';
			}
		},
		async checkLocation() {
			this.geoLocation = await util.getLocation();
			const geoLoc = await this.checkGeoblock({ location: this.geoLocation ? this.geoLocation.country_code : '' });

			if (geoLoc && geoLoc.blocked) {
				this.geoBlocked = true;
				this.geoLocation.country_code = geoLoc.country_code || this.geoLocation.country_code;
				this.geoLocation.country = geoLoc.country_code || this.geoLocation.country;

				await this.$router.push('/register/geoblock').catch(() => { });
			} else {
				this.geoBlocked = false;
			}
		},
		async initMorpherWallet() {
			if (this.contract && this.contract.morpherwallet) {
				this.morpherWalletInit = {
					morpherwallet: this.contract.morpherwallet,
					provider: this.contract.provider,
					walletClient: this.contract.walletClient
				};
			} else {
				this.morpherWalletInit = await morpherWalletConfig();
				this.morpherWalletInit.morpherwallet.setLanguage(this.$i18n?.locale || 'en');

				setTimeout(async () => {
					const isLoggedIn = await this.morpherWalletInit.morpherwallet.isLoggedIn();
					if (isLoggedIn && isLoggedIn.isLoggedIn) {
						this.morpherWalletInit.morpherwallet.logout()
					}
				}, 2000)
			}
		},

		async submit2fa() {
			if (!this.morpherWalletInit) {
				await this.initMorpherWallet();
			}
			this.loginProcessing = true;
			this.morpherwallet = this.morpherWalletInit.morpherwallet;
			this.walletClientlogin = this.morpherWalletInit.walletClient;
			this.setWalletClient(this.walletClientlogin);

			this.morpherwallet.loginWallet2fa(this.twofaCode);
		},

		async clearStorage() {
			localStorage.removeItem(accessTokenName);
			localStorage.removeItem(refreshTokenName);
			localStorage.removeItem('emailOrUsernameStorage');
			localStorage.removeItem('morpher-role');
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('tokenStorage');
			localStorage.removeItem('eth_address');
		},

		async recaptchaError() {
			//this.loginProcessing = false;
		},
		async reloadPage(fortmaticNoAccountFound = false) {
			// remove all old cached items
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			if (fortmaticNoAccountFound) return;

			// reload the page if the previous reload was more than 1 hour ago (to avoid refresh loop)
			const reloadDate = localStorage.getItem('reloadDateLogin');
			const timestamp = Date.now();

			if (!reloadDate || Number(reloadDate) < timestamp - 3600000) {
				localStorage.setItem('reloadDateLogin', String(timestamp));
				window.location.reload();
			}
		},

		recaptchaComplete() {
			this.signup('');
		},

		async signupWalletConnect() {
			// block if login is already executing
			if (this.loginProcessing || this.loginProcessingGoogle || this.loginProcessingApple || this.loginProcessingMetamask || this.loginProcessingWalletConnect) {
				return;
			}

			this.loginProcessingWalletConnect = true;

			await this.checkLocation()

			if (this.geoBlocked) {
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;
				this.loginProcessingMetamask = false;

				return;
			}

			try {
				const walletClientinstance = getWalletClient(this.walletconnectProvider);
				await this.walletconnectProvider.disconnect()

				const accounts = await this.walletconnectProvider.enable()

				if (!accounts || accounts.length == 0) {
					console.log('no account selected')
					return
				}

				this.setWalletClient(walletClientinstance);
				this.walletClientlogin = walletClientinstance;


				// metamask is installed and connected to the correct network so go ahead with getting the account data from metamask
				this.loading.metamask = true;

				let eth_address = '';

				// Get the users Ethereum address

				eth_address = accounts[0].toLowerCase();

				await this.walletConfirm('walletconnect', eth_address, null);



			} catch (err: any) {
				console.log('Error connecting to WalletConnect:', err.message);
				this.logSentryError('Error connecting to WalletConnect:' + err.message);
			}
		},

		async signupMetamask() {
			// block if login is already executing
			if (this.loginProcessing || this.loginProcessingGoogle || this.loginProcessingApple || this.loginProcessingMetamask) {
				return;
			}

			this.loginProcessingMetamask = true;

			await this.checkLocation()
			if (this.geoBlocked) {
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;
				this.loginProcessingMetamask = false;

				return;
			}

			try {
				const onboarding = new MetaMaskOnboarding();

				// if metamask is not installed the display the insall metamask message
				if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
					if (deviceType.isAnyMobile()) {
						if (import.meta.env.VITE_METAMASK_DEEP_LINK_REGISTRATION)
							window.location.href = import.meta.env.VITE_METAMASK_DEEP_LINK_REGISTRATION;
						return;
					}

					onboarding.startOnboarding();
					return;
				}

				const walletClientinstance = getWalletClient(window.ethereum);
				this.walletClientlogin = walletClientinstance;
				this.setWalletClient(walletClientinstance);

				// metamask is installed and connected to the correct network so go ahead with getting the account data from metamask
				this.loading.metamask = true;

				let eth_address = '';

				// Get the users Ethereum address
				if (MetaMaskOnboarding.isMetaMaskInstalled() && window.ethereum) {
					const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });

					eth_address = addresses[0] ? addresses[0].toLowerCase() : '';

					await this.walletConfirm('metamask', eth_address, null);
				} else {
					this.loading.metamask = false;
				}


			} catch (err: any) {
				console.log('Error connecting to MetaMask:', err.message);
				this.logSentryError('Error connecting to MetaMask:' + err.message);
			}
		},
		/*
		 * Connect to morpher wallet and log on the user using the wallet address
		 */
		async signup(method: any) {
			// block if login is already executing
			if (this.loginProcessing || this.loginProcessingGoogle || this.loginProcessingApple || this.loginProcessingMetamask) {
				return;
			}
			if (method == 'email') this.loginProcessing = true;
			else if (method.type == 'google') this.loginProcessingGoogle = true;
			else if (method.type == 'apple') this.loginProcessingApple = true;

			if (method == 'email') {
				if (this.walletEmail === '') {
					this.emailValidation = false;
					this.emailMessage = this.$t('USERREG_EMAILEMPTY');
				} else {

					const returnData = await this.validateRegistrationField({
						id: null,
						fieldName: 'email',
						inputFieldValue: this.walletEmail
					})

					this.emailValidation = returnData.success;
					// Get the error message from the language file
					this.emailMessage = returnData.returnMessage
						.replace('EmailFormat', this.$t('USERREG_EMAILFORMAT'))
						.replace('EmailMXRecord', this.$t('USERREG_EMAILMX'))
						.replace('EmailBlacklisted', this.$t('USERREG_EMAILBLACKLIST'))
						.replace('EmailAlreadyUsed', this.$t('USERREG_EMAILALREADYUSED'));

				}

				if (!this.emailValidation) {
					this.loginProcessing = false;
					return;
				}

			}

			await this.checkLocation()
			if (this.geoBlocked) {
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;

				return;
			}

			if (!method) method = 'email';

			if (!this.morpherWalletInit) {
				await this.initMorpherWallet();
			}

			this.morpherwallet = this.morpherWalletInit.morpherwallet;
			this.walletClientlogin = this.morpherWalletInit.walletClient;
			this.setWalletClient(this.walletClientlogin);

			let eth_address = '';
			this.setMorpherWalletProvider(this.morpherWalletInit);

			// if (
			// 	!this.recaptchaToken &&
			// 	(!localStorage.getItem('recaptcha_date') || Number(localStorage.getItem('recaptcha_date')) < Date.now() - 1000 * 60 * 8)
			// )
			// 	return this.executeRecaptcha(() => {
			// 		this.signup(method);
			// 	});




			this.logonError = '';
			let email = this.walletEmail;
			let password = this.walletPassword;
			let passwordRepeat = this.walletPasswordConfirm;
			//let recaptchaToken = this.recaptchaToken;

			this.morpherwallet.onClose(async () => {
				this.stopTimer();
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;
			});

			this.morpherwallet.on2FA(async () => {
				this.stopTimer();
				this.loginProcessing = false;
				this.twoFA = true;
			});

			this.morpherwallet.onError((error: any) => {
				this.stopTimer();
				if (error) {
					this.loginProcessing = false;
					this.loginProcessingGoogle = false;
					this.loginProcessingApple = false;

					if (error.toString() === 'invalid password') {
						this.twoFA = false;
					}

					this.logonError = this.$t('errors.' + error)
				}

			})

			this.morpherwallet.onLoginError(async (email: any, error: any) => {
				this.stopTimer();
				if (error === 'USER_NOT_FOUND') {
					const userData = await UserService.getUserData({ email });
					if (userData && userData.data.getUserData) {
						const user = userData.data.getUserData;
						if (user && user.wallet_type && user.wallet_type !== 'morpherwallet') {
							this.loginProcessing = false;
							this.loginProcessingGoogle = false;
							this.loginProcessingApple = false;

							this.morpherwallet.logout();
							this.morpherwallet.hideWallet();
							this.wallet_type = user.wallet_type;
							return;
						}
					}
				}
			});

			this.morpherwallet.onLogin(async (walletAddress: any, walletEmail: any, recovery_type: any) => {
				this.stopTimer();
				this.morpherwallet.onLogin(() => { });
				const eth_address = walletAddress.toLowerCase();
				await this.walletConfirm('morpherwallet', eth_address, walletEmail, recovery_type);
			});
			this.morpherwallet.onLogout(() => { });

			this.stopTimer();

			this.walletTimer = window.setTimeout(() => {
				this.morpherwallet.showWallet()
				this.logonError = this.$t('errors.WALLET_LOGIN_TIMEOUT')
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;


			}, 20000);

			this.morpherwallet.signupWalletHidden(method, email, password, passwordRepeat, JSON.parse(JSON.stringify(this.loginUser)));
		},
		stopTimer() {
			if (this.walletTimer) {
				window.clearTimeout(this.walletTimer)
				this.walletTimer = null;
			}
		},
		processMethod(data: any) {
			this.logonError = '';

			if (data.success) {
				this.signup(data);
			} else {
				if (data.error === 'popup_closed_by_user') {
					this.logonError = this.$t('GOOGLE_COOKIES_BLOCKED');
				} else if (data.error === 'google_script_blocked') {
					this.logonError = this.$t('GOOGLE_SCRIPT_BLOCKED');
				} else {
					this.logonError = this.$t(data.error);
				}
			}
		},
		executeSignatureTimeout() {
			this.$buefy.toast.open({
				duration: 10000,
				message: this.$t('auth.METAMAS_SIGNATURE_ERROR'),
				type: 'is-warning'
			});

		},
		async loginMetamask() {
			this.$emit('checkMetamask');
			this.walletError = '';
			this.wallet_type = 'metamask';
			try {
				EventBus.$off('signatureTimeout', this.executeSignatureTimeout);
				EventBus.$on('signatureTimeout', this.executeSignatureTimeout);

				const onboarding = new MetaMaskOnboarding();

				if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
					if (deviceType.isAnyMobile()) {
						if (import.meta.env.VITE_METAMASK_DEEP_LINK) window.location.href = import.meta.env.VITE_METAMASK_DEEP_LINK;
						return;
					}

					onboarding.startOnboarding();

					this.showMetamaskHelp = true;

					this.$router.push('/login').catch(() => { });

				} else {
					this.loading.metamask = true;
					this.loginProcessingMetamask = true;


					const walletClientinstance = getWalletClient(window.ethereum);

					this.walletClientlogin = walletClientinstance;
					this.setWalletClient(walletClientinstance);

					let eth_address = '';

					// Get the users Ethereum address
					if (window.ethereum) {
						window.ethereum.autoRefreshOnNetworkChange = false;
						const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });


						eth_address = addresses[0] ? addresses[0].toLowerCase() : '';

						if (eth_address) {
							this.eth_address = eth_address;
							this.blockie = blockies.createDataURL({
								seed: eth_address.toLowerCase()
							});
						}
					}

					const user_id = await getUserID(eth_address, 'metamask');

					this.clearStorage();
					localStorage.setItem('walletType', 'metamask');

					const result = await this.loginWallet({
						variables: {
							eth_address,
							wallet_type: 'metamask',
							user_id,
							hash: ''
						},
						isTokenLogin: false,
						email: '',
						walletClient: this.walletClientlogin
					});

					if (result && result.error) {
						this.$router.push('/login').catch(() => { });
					}

					// Display the email address updated notification if the email address was updated
					if (result && result.old_address) {
						this.$buefy.dialog.alert({
							title: this.$t('auth.EMAIL_UPDATED'),
							message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
								newEmail: result.email,
								oldEmail: result.old_address
							}),
							confirmText: this.$t('OK')
						});
					}

					// if the user is not registered then register the user
					if (!result) {
						this.loginProcessingMetamask = false;
						this.loading.metamask = false;
					}
					// store the email for defaulting next tme the user logs in
					if (result) {
						localStorage.setItem('emailOrUsernameStorage', result.email)
						this.verifyEmail = result.email
					}
					this.loginProcessingMetamask = false;
					this.loading.metamask = false;
				}
			} catch (err: any) {
				this.walletError = err.message;
				this.loginProcessingMetamask = false;
				this.loading.metamask = false;
			}
		},
		async verifyResend() {
			this.codeResend = true;

			await this.resendVerifyEmail({
				username: undefined,
				email: this.verifyEmail
			});

			setTimeout(() => {
				this.codeResend = false;
			}, 30000)
		},

		async signupReddit(email_address: any, user_id: any) {
			try {
				// load the reddit pixel SDK
				if (!document.getElementById('script_reddit_pixel') && email_address && import.meta.env.VITE_MODE === 'production') {
					var normalizeEmail = function (email: any) {
						let localPartAndDomain = email.split('@');

						if (localPartAndDomain.length != 2) { return ''; }
						let localPart = localPartAndDomain[0];
						localPartAndDomain[0] = localPart.replace(/\./g, '').split('+')[0];
						return localPartAndDomain.join('@').toLowerCase();
					};

					let reddt_email = normalizeEmail(email_address)
					if (!reddt_email) {
						reddt_email = email_address.toLowerCase();
					}

					const pixelScript = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_en0sdd3j80ju', { email: '${reddt_email}',  externalId: '${user_id}' });rdt('track', 'PageVisit'); rdt('track', 'SignUp'); `

					const scriptElement = document.createElement('script');
					scriptElement.setAttribute('id', 'script_reddit_pixel');
					scriptElement.type = 'text/javascript';
					scriptElement.text = pixelScript;
					document.head.appendChild(scriptElement);
				}
			} catch (err) {
				console.log('err', err)
			}
		},
		resetLoading() {
			this.loading.fortmatic = false;
			this.loading.portis = false;
			this.loading.metamask = false;
			this.loading.morpherwallet = false;
		},
		async walletConfirm(wallet_type: any, eth_address: any, email: any, recovery_type: any = null) {
			try {
				this.wallet_type = wallet_type
				if (!eth_address) {
					return;
				}
				if (!email) {
					this.loginProcessingMetamask = false;
					this.resetLoading();
					localStorage.setItem('walletType', wallet_type);
					localStorage.setItem('eth_address', eth_address);
					this.metamaskEthAddress = eth_address;
					this.blockie = blockies.createDataURL({
						seed: eth_address.toLowerCase()
					});

					this.metamaskAlreadyRegistered = false;
					const userData = await UserService.getUserData({ eth_address: eth_address.toLowerCase() });

					if (userData && userData.data.getUserData) {
						const user = userData.data.getUserData;

						if (user && user.wallet_type && user.wallet_type) {
							this.metamaskAlreadyRegistered = true;
						}
					}

					await this.$router.push('/register/email').catch(() => { });
					this.$forceUpdate();
					return;
				} else {

					const hash: string = (await this.walletClientlogin?.signMessage({ account: eth_address as TAddress, message: 'Morpher Registration' })) || '';

					this.loginVerifying = true;

					this.verifyEmail = email
					// Confirm the wallet address in the database
					const result = await this.confirmWallet({
						email: email,
						walletClient: this.walletClientlogin,
						recovery_type: recovery_type,
						variables: {
							wallet_type,
							eth_address,
							email,
							university: this.$route.query.uni ? this.$route.query.uni : null,
							competition: this.$route.query.comp ? this.$route.query.comp : null,
							walletEmail: true,
							referred_by: this.referred_by ? this.referred_by : null,
							custom_invite: this.custom_invite ? this.custom_invite : null,
							hash,
							user_id: await getUserID(eth_address, wallet_type),
							app_lang: this.$i18n.locale
						}
					});

					// bing user signup goal
					try {
						// if (window.posthog) {
						// 	window.posthog.capture('user_signed_up');
						// }

						sendBingTrackingEvent();
					} catch (err) { }

					// yandex user signup goal
					try {
						if (window.ym) {
							window.ym(import.meta.env.VITE_YANDEX_API_KEY, 'reachGoal', 'user-signup');
						}
					} catch (err) { }



					if (result && (result.status === 'confirmed' || result.status === 'email')) {
						await localStorage.setItem('emailOrUsernameStorage', email)
						await localStorage.setItem('walletType', result.wallet_type);
						await localStorage.setItem('eth_address', result.eth_address);

						if (result.status === 'email') {
							this.loginVerifying = false;
							this.verifyEmail = email
							this.$router.push('/register/verify').catch(() => { });
						}

						this.signupReddit(result.email, result.id);
					} else {
						this.loginProcessingMetamask = false;
						this.loginVerifying = false;
						this.resetLoading();
						this.loginProcessingMetamask = false;

						if (result.status) {
							localStorage.setItem('tokenStorage', result.token);
							await this.$router.push('/register/' + result.status).catch(() => { });
						} else if (result.error) {
							this.logonError = this.$t(result.error)
							this.$emit('updateError', result.error);
						}
						this.$forceUpdate();

						return;
					}
				}
			} catch (err: any) {
				this.loginProcessingMetamask = false;
				this.loginProcessing = false;
				this.loginProcessingGoogle = false;
				this.loginProcessingApple = false;

				this.resetLoading();
				this.$emit('updateError', err.message);
			}
		},
		async disconnectWallet(goToLogin = false) {
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			this.clearStorage();

			if (this.morpherwallet && this.morpherwallet.logout) this.morpherwallet.logout();

			if (goToLogin) this.$router.push('/login').catch(() => { });

			window.location.reload();
		},
		generateWalletName(wallet_type: string) {
			let name = '';

			if (wallet_type === 'metamask') {
				name = 'Metamask';
			} else if (wallet_type === 'portis') {
				name = 'Portis';
			} else if (wallet_type === 'fortmatic') {
				name = 'Fortmatic';
			} else if (wallet_type === 'morpherwallet') {
				name = 'Morpher Wallet';
			}

			return name;
		},
		async cancelMetamask() {
			await this.$router.push('/register').catch(() => { });
		},
		async submitMetamask() {
			let email = this.walletEmail;

			this.loginProcessingMetamask = true;

			if (this.emailTimeout) {
				clearTimeout(this.emailTimeout);
				this.emailTimeout = null;
			}


			// Process the email validation
			if (this.walletEmail === '') {
				this.emailValidation = false;
				this.emailMessage = this.$t('USERREG_EMAILEMPTY');
			} else {

				const returnData = await this.validateRegistrationField({
					id: null,
					fieldName: 'email',
					inputFieldValue: this.walletEmail
				})

				this.emailValidation = returnData.success;
				// Get the error message from the language file
				this.emailMessage = returnData.returnMessage
					.replace('EmailFormat', this.$t('USERREG_EMAILFORMAT'))
					.replace('EmailMXRecord', this.$t('USERREG_EMAILMX'))
					.replace('EmailBlacklisted', this.$t('USERREG_EMAILBLACKLIST'))
					.replace('EmailAlreadyUsed', this.$t('USERREG_EMAILALREADYUSED'));

			}

			if (!this.emailValidation) {
				this.loginProcessingMetamask = false;
				return;
			}

			if (this.wallet_type == 'walletconnect') {
				this.walletConfirm('walletconnect', this.metamaskEthAddress, email)
			} else {

				const walletClientinstance = getWalletClient(window.ethereum);

				this.walletClientlogin = walletClientinstance;
				this.setWalletClient(walletClientinstance);

				this.walletConfirm('metamask', this.metamaskEthAddress, email)

			}


		},

		checkKeyPress(e: KeyboardEvent) {
			if (e.key == 'Enter') {
				const disabled = !this.consent_approve || this.passwordChecks.min !== 'pass' || this.passwordChecks.uppercase !== 'pass' || this.passwordChecks.lowercase !== 'pass' || this.passwordChecks.number !== 'pass' || this.passwordChecks.match !== 'pass' || !this.emailValidation
				if (!disabled && this.walletPassword && this.walletPasswordConfirm) {
					this.signup('email');
				} else {
					if (!this.walletPassword) {
						// set focus to the password field if it is blank
						window.setTimeout(() => {
							const passwordElement = this.$refs.login_password as InstanceType<typeof HTMLInputElement>;
							if (passwordElement) passwordElement.focus();
						}, 100);
					} else if (!this.walletPasswordConfirm) {
						// set focus to the password confirm field if it is blank
						window.setTimeout(() => {
							const passwordElement = this.$refs.login_password_confirm as InstanceType<typeof HTMLInputElement>;
							if (passwordElement) passwordElement.focus();
						}, 100);
					}


				}
			}
		},

		// Submits login request
		async formAction() { },

		checkPassword(newValue: any, checkErrors: any, oldChecks: any, comparePassword: any, checkRepeatOnly = false) {
			let updatedChecks = checkRepeatOnly
				? oldChecks
				: {
					min: '',
					uppercase: '',
					lowercase: '',
					number: '',
					match: ''
				};

			if (checkErrors) {
				updatedChecks = {
					min: 'fail',
					uppercase: 'fail',
					lowercase: 'fail',
					number: 'fail',
					match: 'fail'
				};
			}

			if (newValue) {
				if (!checkRepeatOnly) {
					if (newValue.length >= 8) {
						updatedChecks.min = 'pass';
					} else if (checkErrors) updatedChecks.min = 'fail';

					if (/[A-Z]/.test(newValue)) {
						updatedChecks.uppercase = 'pass';
					} else if (checkErrors) updatedChecks.uppercase = 'fail';

					if (/[a-z]/.test(newValue)) {
						updatedChecks.lowercase = 'pass';
					} else if (checkErrors) updatedChecks.lowercase = 'fail';

					if (/[0-9]/.test(newValue)) {
						updatedChecks.number = 'pass';
					} else if (checkErrors) updatedChecks.number = 'fail';
				}

				if (comparePassword) {
					if (newValue === comparePassword) {
						updatedChecks.match = 'pass';
					} else updatedChecks.match = 'fail';
				} else {
					if (checkErrors) updatedChecks.match = 'fail';
					else updatedChecks.match = '';
				}
			}

			return updatedChecks;
		}
	}
});
</script>
<style scoped>
.button-img-primary {
	height: 28px;
	padding-right: 15px;
}

.button-img-alt {
	position: absolute;
	right: calc(50% + 70px);
}

.alt-button {
	padding-left: 46px;
}

.login-help {
	margin-top: -0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.settings-link {
	line-height: 1rem;
	cursor: default;
}

@media only screen and (max-width: 400px) {
	.button-img-alt {
		display: none;
	}

	.alt-button {
		padding-left: initial;
	}

	.formcontainer {
		width: 100%!important;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.spaced {
	margin-right: 7px;
}

.authBox {
	margin-top: 0px;
}

.formcontainer {
	display: flex;
	flex-direction: column;
	margin: auto auto;
	width: 360px;
}

.headerImage img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.authTitle {
	font-size: 28px;
	font-weight: 700;
	line-height: 38px;
	margin-top: 25px;
	color: #333333;
}

.headerImage {
	width: 100%;
}

.password-toggle {
	position: absolute;
	right: 10px;
	top: 8%;
	background: none;
	border: none;
	cursor: pointer;
}

.confirm-password-toggle {
	position: absolute;
	right: 10px;
	top: 30%;
	background: none;
	border: none;
	cursor: pointer;
}

.authText {
	font-weight: 400;
	color: #686475;
	margin-top: 12px;
	font-size: 16px;
}

.authText.fade {
	height: 50px;
	-webkit-animation: fadeinout 3s linear forwards;
	animation: fadeinout 3s linear forwards;
	opacity: 0;
	animation-iteration-count: infinite;
}

.authText.fade2 {
	height: 50px;
	-webkit-animation: fadeIn 2s linear forwards;
	animation: fadeIn 2s linear forwards;
	opacity: 0;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeinout {
	50% {
		opacity: 1;
	}
}

@keyframes fadeinout {
	50% {
		opacity: 1;
	}

}

.label {
	margin-top: 20px;
	margin-bottom: 4px;
	text-align: left;
	color: #686475;
	font-weight: 700;
	font-size: 12px;
}

.checkBoxContainer {
	display: flex;
	flex-direction: row;
	font-size: 12px;
}

.input {
	padding: 23px 16px;
	background-color: #fff;
	border-radius: 8px !important;
	border-color: #d0d5dd !important;
}

.input.email-error {
	border-color: #D70228 !important;
}

.input::placeholder {
	font-family: 'Manrope';
	line-height: 20px;
	font-size: 14px !important;
	font-weight: 400;
	color: #686475;
}



.columns {
	margin-top: 0px;
}


.loginDivider {
	height: 1px;
	width: 100%;
	margin: 20px 0;
	border-top: 1px solid #eae9ed;
}

.loginHelp {
	height: calc(100vh - 800px);
}

.consent-text {
	text-align: left;
	color: #363636;
}


.password-help {
	font-size: 12px;
	text-align: left;
}

.password-help p {
	margin-bottom: 5px;
}

.items {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 5px;
}

.password-help li {
	margin: 0;
	padding-left: 15px;
	position: relative;
	transition: 200ms;
}

.password-help li::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
	transition: 200ms;
}

.password-help .done {
	color: #00c386;
}

.password-help .done::before {
	border: none;
	border-bottom: 2px solid #00c386;
	border-right: 2px solid #00c386;
	transform: translateY(-50%) rotate(45deg);
	border-radius: 0;
	width: 6px;
	height: 10px;
}
.loginDiv {
	color: #686475;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px; /* 150% */
	letter-spacing: -0.12px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
	text-transform: uppercase;
	padding-left: 24px;
	padding-right: 24px;
}


.loginOptions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
	
}

.password-help .fail {
	color: #fc6404;
}

.password-help .fail::before {
	content: '✕';
	border: none;
	border-radius: 0;
	top: auto;
	transform: none;
}

.error {
	color: #D70228;
	font-size: 14px;
	font-weight: 400;
	padding-top: 5px;
}

.flag-icon {
	width: 24px !important;
	height: 24px !important;
	border-radius: 12px !important;

}

.wallet-box {
	border-radius: 8px;
	border: 1px solid #EAE9ED;
}

.login-buttons {
	padding-top: 32px;
}

.login-buttons ul {
	list-style-type: disc;
	margin-top: 5px;
	margin-bottom: 8px;
	margin-left: 19px;
}

.login-buttons li {
	margin-bottom: 5px;
}
.icon-only {
	max-width: 109px;
}
.pt-30 {
	padding-top: 30px;
}
</style>
