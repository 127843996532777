
mutation updateEmailSubscriptions($subscriptions: JSON!) {
    updateEmailSubscriptions(
        data: {
            subscriptions: $subscriptions
        }
    ) {
        email_list{
            subscription
        }
    }
}
