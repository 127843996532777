<template>
	<div>
		<div id="fortmaticOverlayContainer" />
		<div id="fortmaticOverlayTextClaim">
			<div v-if="walletState === 'approve'">
				<p class="has-font-rubik has-text-weight-medium is-size-4">{{ $t('fortmatic.APPROVE_TOKEN') }}</p>
				<p class="mt-10">{{ $t('fortmatic.APPROVE_TOKEN_DESCRIPTION') }}</p>
				<p class="mt-10" v-html="$t('fortmatic.APPROVE_TOKEN_TRANSFER', {
					walletAmount
				})
					"></p>
				<div class="mt-20" />
				<p class="help">
					{{ $t('fortmatic.CANT_SEE_BUTTON') }}
					<a id="closeOverlayLink" class="has-text-weight-medium transition-faster" @click="closeOverlay">{{
						$t('fortmatic.CLOSE_TEXT_OVERLAY')
					}}</a>
				</p>
			</div>
			<div v-else>
				<p class="has-font-rubik has-text-weight-medium is-size-4">{{ $t('fortmatic.TOKEN_CLAIM_COMPLETE') }}
				</p>
				<p class="mt-10" v-html="$t('fortmatic.TOKEN_CLAIM_COMPLETE_DESCRIPTION', {
					walletAmount
				})
					"></p>
				<p class="mt-10">{{ $t('fortmatic.RETURN_TO_APP') }}</p>
				<div class="mt-20" />
				<p class="help">
					{{ $t('fortmatic.CANT_SEE_BUTTON') }}
					<a id="closeOverlayLink" class="has-text-weight-medium transition-faster" @click="closeOverlay">{{
						$t('fortmatic.CLOSE_TEXT_OVERLAY')
					}}</a>
				</p>
			</div>
		</div>

		<div id="fortmaticOverlayTextLimit">
			<div v-if="walletState === 'approve'">
				<p class="has-font-rubik has-text-weight-medium is-size-4">{{ $t('fortmatic.APPROVE_LIMIT_ORDER') }}</p>
				<p class="mt-10">{{ $t('fortmatic.APPROVE_LIMIT_ORDER_DESCRIPTION') }}</p>
				<p class="mt-10" v-html="$t('fortmatic.APPROVE_LIMIT_ORDER_CONFIRMATION', {
					walletMarket
				})
					"></p>
				<div class="mt-20" />
				<p class="help">
					{{ $t('fortmatic.CANT_SEE_BUTTON') }}
					<a id="closeOverlayLink" class="has-text-weight-medium transition-faster" @click="closeOverlay">{{
						$t('fortmatic.CLOSE_TEXT_OVERLAY')
					}}</a>
				</p>
			</div>
			<div v-else>
				<p class="has-font-rubik has-text-weight-medium is-size-4">{{ $t('fortmatic.LIMIT_ORDER_COMPLETE') }}
				</p>
				<p class="mt-10" v-html="$t('fortmatic.LIMIT_ORDER_DESCRIPTION', {
					walletMarket
				})
					"></p>
				<p class="mt-10">{{ $t('fortmatic.RETURN_TO_APP') }}</p>
				<div class="mt-20" />
				<p class="help">
					{{ $t('fortmatic.CANT_SEE_BUTTON') }}
					<a id="closeOverlayLink" class="has-text-weight-medium transition-faster" @click="closeOverlay">{{
						$t('fortmatic.CLOSE_TEXT_OVERLAY')
					}}</a>
				</p>
			</div>
		</div>

		<div id="fortmaticOverlayTextTrade">
			<div v-if="walletState === 'approve'">
				<p class="has-font-rubik has-text-weight-medium is-size-4">{{ $t('fortmatic.APPROVE_TRADE') }}</p>
				<p class="mt-10">{{ $t('fortmatic.APPROVE_TRADE_DESCRIPTION') }}</p>
				<p class="mt-10" v-html="$t('fortmatic.APPROVE_TRADE_CONFIRMATION', {
					walletMarket
				})
					"></p>
				<div class="mt-20" />
				<p class="help">
					{{ $t('fortmatic.CANT_SEE_BUTTON') }}
					<a id="closeOverlayLink" class="has-text-weight-medium transition-faster" @click="closeOverlay">{{
						$t('fortmatic.CLOSE_TEXT_OVERLAY')
					}}</a>
				</p>
			</div>
			<div v-else>
				<p class="has-font-rubik has-text-weight-medium is-size-4">{{ $t('fortmatic.TRADE_COMPLETE') }}</p>
				<p class="mt-10" v-html="$t('fortmatic.TRADE_COMPLETE_DESCRIPTION', {
					walletMarket
				})
					"></p>
				<p class="mt-10">{{ $t('fortmatic.RETURN_TO_APP') }}</p>
				<div class="mt-20" />
				<p class="help">
					{{ $t('fortmatic.CANT_SEE_BUTTON') }}
					<a id="closeOverlayLink" class="has-text-weight-medium transition-faster" @click="closeOverlay">{{
						$t('fortmatic.CLOSE_TEXT_OVERLAY')
					}}</a>
				</p>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { clickedToCloseOverlay } from '@/helpers/fortmatic';

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'FortmaticOverlayModal',
	data() {
		return {};
	},
	computed: {
		...mapState(useContractStore, {
			walletAmount: (state) => state.walletAmount,
			walletState: (state) => state.walletState,
			walletMarket: (state) => state.walletMarket,
		}),

	},
	mounted() {
		this.initialize();
	},
	methods: {

		initialize() { },
		closeOverlay() {
			clickedToCloseOverlay();
		}
	}
});
</script>
<style scoped>
#fortmaticOverlayContainer {
	position: fixed;
	left: 0px;
	top: 0px;
	display: none;
	width: 100%;
	height: 100%;
}

#fortmaticOverlayTextClaim,
#fortmaticOverlayTextLimit,
#fortmaticOverlayTextTrade {
	display: none;
	position: fixed;
	overflow: hidden;
	background-color: #fff;
	z-index: 214748364;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 13px;
	opacity: 0.97;
	padding-left: 1rem;
	padding-right: 1rem;
	justify-content: center;
	align-items: center;

	/* Regular Desktop Settings */
	width: 350px;
	min-height: 220px;
	margin-top: -35px;
}
</style>
