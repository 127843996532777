<template>
	<div ref="wrapper" style="padding: 11px">

		<b-modal :modelValue="showDrillDown" has-modal-card @close="close" :can-cancel="['escape', 'outside']">
			<div class="modal-card terminal">
				<div class="terminal-content has-text-left has-font-manrope">
					<button class="button back-btn close-button" @click="close">
						<img src="@/assets/icons/times-grey.svg" alt="Close" />
					</button>
					<div class="card-text has-text-asphalt mt-10 p-1" v-html="drilldown_text"></div>
				</div>
			</div>
		</b-modal>
		<div v-if="loading">
			<div class="container">
				<div class="columns is-desktop">
					<div class="column">
						<div class="columns is-multiline">
							<div v-for="index in 2" :key="index" class="column is-full-mobile is-full-tablet newscard"
								:id="singleColumn ? 'newscard_single' : 'newscard'"
								:class="{ 'is-12-desktop': singleColumn, 'is-6-desktop': !singleColumn }">
								<article class="media" style="max-height: 140px">
									<ContentLoader>
										<rect x="0" y="0" rx="3" ry="3" width="80" height="60" />
										<rect x="90" y="0" rx="3" ry="3" width="300" height="14" />

										<rect x="90" y="20" rx="3" ry="3" width="260" height="10" />
										<rect x="90" y="35" rx="3" ry="3" width="260" height="10" />
										<rect x="90" y="50" rx="3" ry="3" width="260" height="10" />
									</ContentLoader>
								</article>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<!-- Market News Container -->
			<div class="container">
				<div class="columns is-desktop">
					<div class="column">
						<div class="columns is-multiline">

							<div v-for="article in news" v-bind:key="article.id"
								:id="singleColumn ? 'newscard_single' : 'newscard'"
								:class="{ 'is-full': singleColumn, 'is-full-mobile': !singleColumn, 'is-full-tablet': !singleColumn }"
								class="column newscard" @click="openNews(article)">
								<!-- Mobile View -->
								<div v-if="isMobile || aiBar">
									<article class="media">
										<figure class="media-left">
											<p class="image">
												<img :src="article.image_url || imageURL('@/img/img_empty-news.svg')"
													@error="setAltImg" class="news-image" />
											</p>
										</figure>

										<div class="media-content">
											<div class="content">
												<div>
													<p :title="article.headline" id="newsheading" class="truncated3">
														{{ article.headline }}
													</p>
												</div>
											</div>
										</div>
									</article>
									<p id="newssummary" class="mt-2 truncated3">
										summary
										{{ parseHTML(article.summary) }}
									</p>
									<div id="newsmetadata" class="mt-4">
										<div v-show="!aiBar" class="market-tags is-align-items-center">
											<div class="market-tags" v-if="markets_loaded">
												<div class="market-tag"
													@click.stop="openMarket(market.type, market.symbol)"
													:class="{ 'positive': market.change_percent >= 0, 'negative': market.change_percent < 0 }"
													v-for="market in getLinkedMarkets(article.market_list)"
													:key="market.id">
													<span>
														{{ market.symbol }}
													</span>
													<span>
														<img v-if="Number(market.change_percent) >= 0"
															src="@/assets/icons/deep_green_arrow_icon.svg"
															class="indicator-icon" />
														<img v-else src="@/assets/icons/deep_red_arrow_icon.svg"
															class="indicator-icon" />
													</span>
													<span :class="{
														'return-value-green': market.change_percent >= 0,
														'return-value-red': market.change_percent < 0
													}">
														{{
															parseFloat(Math.abs(market.change_percent ||
																0).toString()).toFixed(2)
														}}%
													</span>
												</div>
											</div>
										</div>
										<div class="market-tags">
											<p class="is-size-7">
												{{ article.source }}
											</p>
											<p class="is-size-7">
												{{ moment(Number(article.timestamp)).format('DD/MM/YY HH:mm') }}
											</p>
										</div>
									</div>
								</div>

								<!-- Desktop Cards -->
								<article v-else class="media">
									<figure class="media-left">
										<p class="image">
											<img :src="article.image_url || imageURL('@/img/img_empty-news.svg')"
												@error="setAltImg" class="news-image is-140" />
										</p>
									</figure>

									<div class="media-content">
										<div class="content">
											<div class="mb-3">
												<p :title="article.headline" id="newsheading" class="truncated2">
													{{ article.headline }}
												</p>
												<p id="newssummary" class="truncated3">
													{{ parseHTML(article.summary) }}
												</p>
											</div>
											<div id="newsmetadata">
												<div class="market-tags is-align-items-center">
													<div class="market-tags" v-if="markets_loaded">
														<div class="market-tag"
															@click.stop="openMarket(market.type, market.symbol)"
															:class="{ 'positive': market.change_percent >= 0, 'negative': market.change_percent < 0 }"
															v-for="market in getLinkedMarkets(article.market_list)"
															:key="market.id">
															<span>
																{{ market.symbol }}
															</span>
															<span>
																<img v-if="Number(market.change_percent) >= 0"
																	src="@/assets/icons/deep_green_arrow_icon.svg"
																	class="indicator-icon" />
																<img v-else src="@/assets/icons/deep_red_arrow_icon.svg"
																	class="indicator-icon" />
															</span>
															<span :class="{
																'return-value-green': market.change_percent >= 0,
																'return-value-red': market.change_percent < 0
															}">
																{{
																	parseFloat(Math.abs(market.change_percent ||
																		0).toString()).toFixed(2)
																}}%
															</span>
														</div>
													</div>
													<p class="is-size-7">
														{{ article.source }}
													</p>
												</div>

												<p class="is-size-7 mr-4">
													{{ moment(Number(article.timestamp)).format('DD/MM/YY HH:mm') }}
												</p>
											</div>
										</div>
									</div>
								</article>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- END Market News Container -->
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// Beorn
// TODO - create a news card component for displaying the news articles
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';
import { ContentLoader } from 'vue-content-loader';
import { UserService } from '../services/UserService';
import Util from '../services/shared';
import { isMobile } from 'mobile-device-detect';

// Beorn
/* @group Components_General */
/*
<h4> <b> Market Category component </b> </h4>
<br> Example: Watchlist in Homepage / Category view of stocks (Gainers/Losers)
*/
export default defineComponent({
	name: 'MarketNews',
	components: {
		ContentLoader
	},
	props: {
		// Message shown when no market provided
		news: {
			type: Array<any>,
			default: () => {
				return [];
			}
		},
		loading: {
			type: Boolean,
			default: true
		},
		singleColumn: {
			type: Boolean,
			default: false
		},
		aiBar: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			showAll: true,
			numOfCards: 0,
			scrollCardsWidth: 690,
			display: [],
			displayMarkets: [],
			sparkLineLoading: true,
			scrollAmount: 0,
			hideLeftArrow: true,
			hideRightArrow: true,
			scrollIntensity: 600,
			hoverActive: false,
			showDrillDown: false,
			drilldown_header: '',
			drilldown_text: '',
			isMobile: false
		};
	},
	computed: {
		...mapState(useMarketsStore, {
			markets: (state) => state.data,
		}),

		markets_loaded() {
			if (this.markets && this.markets.length > 10) {
				return true
			} else {
				return false
			}

		},
		isDesktop() {
			return window.innerWidth >= 1200;
		}
	},
	watch: {
		scrollAmount(val) {
			if (val === 0) this.hideLeftArrow = true;
			else if (val === this.getScrollPosition()) this.hideRightArrow = true;
			else {
				this.hideLeftArrow = false;
				this.hideRightArrow = false;
			}
		}
	},
	unmounted() {
		window.removeEventListener('resize', this.handleResize);
	},
	async mounted() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},
	methods: {
		...mapActions(useMarketsStore, {
			getMinutelyForSparkLine: 'getMinutelyForSparkLine',
		}),

		handleResize() {
			this.numOfCards = Math.floor(((this.$refs.wrapper as InstanceType<typeof HTMLDivElement>).clientWidth + 40) / 160);

			if (document.documentElement.clientWidth) {
				const screenWidth = document.documentElement.clientWidth;
				if (screenWidth < 500) {
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			}
		},
		setAltImg(event: any) {
			event.target.src = this.imageURL('@/img/img_empty-news.svg');
		},
		scrollCards(direction: string) {
			let scrollTo;
			const container = document.getElementById('scroll');
			if (!container) return;
			const items = container.children;

			const positions = container.getBoundingClientRect();

			for (let i = 0; i < items.length; i++) {
				const position = items[i].getBoundingClientRect();

				if (direction === 'right') {
					if (position.left >= positions.right) break;
				} else {
					if (positions.left < position.left) break;
				}

				scrollTo = position.left;
			}

			let newAmount = scrollTo || 0;
			if (direction === 'right') newAmount += container.scrollLeft - 225;
			else newAmount = container.scrollLeft - (positions.left - (scrollTo || 0)) - positions.width + 225;

			const scrollWidth = container.scrollWidth;
			const itemWidth = container.clientWidth;

			if (newAmount <= 0) newAmount = 0;
			if (newAmount >= scrollWidth - itemWidth) newAmount = scrollWidth - itemWidth;

			this.scrollAmount = newAmount;

			container.scrollTo({
				top: 0,
				left: newAmount,
				behavior: 'smooth'
			});
		},

		getScrollPosition() {
			const scrollWidth = (this.$refs.cards as any)?.scrollWidth || 0;
			const itemWidth = (this.$refs.cards as any)?.clientWidth || 0;

			return scrollWidth - itemWidth;
		},

		openMarket(type: string, symbol: string) {

			this.$router.push('/' + type + '/' + this.formatMarketSymbol(symbol)).catch(() => { });

		},

		getLinkedMarkets(markets: any[]) {
			let linked_markets: any[] = []
			if (!markets || markets.length == 0) {
				return linked_markets
			}
			markets.forEach(market_id => {


				const market_data = this.markets.find((market_find) => Util.formatMarketId(market_find?.type || '', market_find?.symbol || '') === market_id);
				if (market_data) {
					let data = {
						id: market_id,
						type: market_data.type,
						symbol: market_data.symbol,
						change_percent: market_data.change_percent,
					}
					linked_markets.push(data)
				}

				//if (index >= 0) {

			})
			const marketid = this.$route?.params?.type?.toUpperCase() + '_' + this.$route?.params?.symbol?.toUpperCase();

			if (linked_markets && linked_markets.length > 0) {
				linked_markets = linked_markets.sort((m1, m2) => {
					let comparison = 0;
					let cp1 = m1.change_percent;
					let cp2 = m2.change_percent;

					if (marketid && marketid == m1.id) {
						cp1 = 1000000;
					}

					if (marketid && marketid == m2.id) {
						cp2 = 1000000;
					}

					if (Math.abs(cp1) < Math.abs(cp2)) {
						comparison = 1;
					} else if (Math.abs(cp1) > Math.abs(cp2)) {
						comparison = -1;
					}
					return comparison;
				})
			}

			if (linked_markets && linked_markets.length > 2) {
				linked_markets = linked_markets.slice(0, 2)
			}

			return linked_markets
		},

		mouseOver() {
			this.hoverActive = true;

			if (this.hoverActive) {
				const scrollWidth = (this.$refs.cards as any)?.scrollWidth;
				const clientWidth = (this.$refs.cards as any)?.clientWidth;
				const scrollLeft = (this.$refs.cards as any)?.scrollLeft;

				if (scrollLeft + clientWidth >= scrollWidth) {
					this.hideRightArrow = true;
				} else {
					this.hideRightArrow = false;
				}

				if (scrollLeft === 0) {
					this.hideLeftArrow = true;
				} else {
					this.hideLeftArrow = false;
				}
			}
		},
		mouseOut() {
			this.hoverActive = false;

			if (!this.hoverActive) {
				this.hideRightArrow = true;
				this.hideLeftArrow = true;
			}
		},

		//On click for entire div
		openNews(article: any) {
			UserService.sendAnalytics({
				event: 'news_click',
				data: {
					page: this.$route.path,
					news_source: article.source
				}
			});
			if (article.source === 'Trading Economics') {
				this.drilldown(article.summary);
			} else {
				window.open(article.url, '_blank');
			}
		},

		// Parse HTML in case summary is in HTML
		parseHTML(description: string) {
			if (!description || !description.replace) {
				return description;
			}
			let str = description.replace(/<[^>]+>/gi, '');
			str = str.replace(/&lsquo;/gi, '‘');
			str = str.replace(/&rsquo;/gi, '’');
			str = str.replace(/&sbquo;/gi, '‚');
			str = str.replace(/&ldquo;/gi, '“');
			str = str.replace(/&rdquo;/gi, '”');
			str = str.replace(/&bdquo;/gi, '„');
			str = str.replace(/&mdash;/gi, '—');
			str = str.replace(/&ndash;/gi, '–');
			str = str.replace(/&sim;/gi, '∼');
			str = str.replace(/&circ;/gi, 'ˆ');
			str = str.replace(/&tilde;/gi, '˜');
			return str;
		},

		// Open and close drilldown modal if reading Trading Economics summary
		drilldown(body: string) {
			this.drilldown_text = body;
			this.showDrillDown = true;
		},
		close() {
			this.showDrillDown = false;
		}
	}
});
</script>

<style scoped>
.close-button {
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
}

.news-image {
	width: 80px;
	height: 80px;
	object-fit: cover;
	border-radius: 8px;
}

.news-image .is-140 {
	width: 140px;
	height: 140px;
}

#newssummary {
	flex-grow: 1;
}

#newsheading {
	font-size: 16px;
	text-align: left;
	/* Large Text Semibold */

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	/* or 138% */

	letter-spacing: -0.01em;

	/* Dark Gray */

	color: #333333;
	margin-top: -3px;
}

#newssummary {
	font-size: 14px;
	text-align: left;
	/* Normal Text */

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */

	letter-spacing: -0.01em;

	/* Asphalt Gray */

	color: #686475;

	/* Flex */
	flex-grow: 1;
}

#newsmetadata {
	font-size: 12px;
	text-align: left;

	/* Small Text Bold */

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 18px;
	/* identical to box height, or 150% */

	letter-spacing: -0.01em;

	/* Asphalt Gray */

	color: #686475;
	margin-top: -5px;

	/* Wrap on mobile */
	display: flex;
	flex-direction: row;

	align-items: center;
	gap: 8px;
	justify-content: space-between;

	flex-wrap: wrap;
}

.newscard {
	box-sizing: border-box;

	/* Auto layout */
	width: 100%;
	margin-right: 20px;
	margin-bottom: 16px;
	padding: 20px;

	background: #ffffff;
	border: 1px solid #eae9ed;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
	border-radius: 8px;

	cursor: pointer;
	/* set cursor to pointer */
	transition: all 0.35s;
	/* z-index: 20 */
}

.newscard:hover {
	box-shadow: none;
	/* remove box shadow on hover */
	background-color: #f5f5f5;
	transition: all 0.35s;
}

.truncated2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncated3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*Scaling of the news components at higher pixels*/

.media {
	display: flex;
}

.media-left {
	flex: 0 0 auto;
}

.media-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	padding-left: 10px;
	flex-grow: 1;
	/* added */
}

.media-content .min-140 {
	min-height: 140px;
}

.content p:not(:last-child) {
	margin-bottom: 12px;
}

.media {
	display: flex;
}

.content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	/* added */
	justify-content: space-between;
}

#newssummary {
	flex-grow: 1;
}

.newscard {
	display: flex;
	/* added */
	flex-direction: column;
	/* added */
	height: 100%;
	/* added */
}

@media screen and (min-width: 1300px) {
	#newscard {
		box-sizing: border-box;

		width: calc((100% / 2) - 20px);
		/* calculate width based on the number of columns */
		padding: 20px;
		gap: 12px;

		background: #ffffff;
		border: 1px solid #eae9ed;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
		border-radius: 8px;
	}
}

@media screen and (min-width: 500px) {
	#newssummary {
		max-height: 45px;
	}
}

.market-tags {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}

.market-tag {
	/* z-index: 30; */
	cursor: pointer;
	display: flex;
	padding: 4px 12px;
	align-items: center;
	gap: 4px;
	border-radius: 20px;
	display: block;

	color: #333;
	font-size: 14px;
	font-weight: 700;
	white-space: nowrap;
	transition: all 0.25s;

}

.market-tag.positive {
	background: #DFFBE9;
}

.market-tag.positive:hover {
	background: #74D6B8;
	transition: all 0.25s;
}

.market-tag.negative {
	background: #FFF0F3;
}

.market-tag.negative:hover {
	background: #ED8A9D;
	transition: all 0.25s;
}

.return-value-green {
	color: #039954;

}

.return-value-red {
	color: #D70228;

}

.indicator-icon {
	position: relative;
	top: -1px;
}
</style>
