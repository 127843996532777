fragment TransactionFragment on Transaction {
    id
    type
    eth_tx_id
    timestamp
    type
    amount
    status
    eth_address
    payload
    payment_status
}
