<template>
	<div>
		<div class="loginContainer info is-hidden-mobile">
			<div class="has-font-manrope register-section">
				<div>
					<h1 class="title">{{ $t('auth.TRADERS') }}</h1>
					<div class="arrow-container is-justify-content-space-between" @mouseover="mouseOver"
						@mouseout="mouseOut">
						<b-button :class="{ show: !hideLeftArrow }" class="arrow left rounded" icon-right="chevron-left"
							@click="scrollCards('left')" />
						<div id="info-scroll" ref="info_cards" class="cards-container" :class="{
							'left-scroll-mask': !scrolls.right && scrolls.left,
							'right-scroll-mask': !scrolls.left && scrolls.right,
							'both-scroll-mask': scrolls.left && scrolls.right
						}" @scroll="onScroll">
							<TrustpilotCard :name="$t('auth.REVIEW_NAME_1')" :text="$t('auth.REVIEW_TEXT_1')">
							</TrustpilotCard>
							<TrustpilotCard :name="$t('auth.REVIEW_NAME_2')" :text="$t('auth.REVIEW_TEXT_2')">
							</TrustpilotCard>
							<TrustpilotCard :name="$t('auth.REVIEW_NAME_3')" :text="$t('auth.REVIEW_TEXT_3')">
							</TrustpilotCard>
						</div>
						<b-button class="arrow right rounded" :class="{ show: !hideRightArrow }" icon-right="chevron-right"
							@click="scrollCards('right')" />

					</div>
				</div>
				<img src="@/assets/auth/screenshot.png" class="screenshot" :alt="$t('auth.SCREENSHOT')">
				<div class="comment-row">
					<div class="comment">
						<p class="head">{{  $t('auth.COMMENT_HEAD_1') }}</p>
						<p class="text">{{  $t('auth.COMMENT_TEXT_1') }}</p>
					</div>
					<div class="comment">
						<p class="head">{{  $t('auth.COMMENT_HEAD_2') }}</p>
						<p class="text">{{  $t('auth.COMMENT_TEXT_2') }}</p>
					</div>
					<div class="comment">
						<p class="head">{{  $t('auth.COMMENT_HEAD_3') }}</p>
						<p class="text">{{  $t('auth.COMMENT_TEXT_3') }}</p>
					</div>

				</div>
			</div>
		</div>
		<div class="is-hidden-desktop" style="margin-top: 64px;">
			<img src="@/assets/auth/tp_coatofarms.svg" >
			<div class="star-section">
				<div>
					<img src="@/assets/auth/tpstar-whole.svg" >
					<img src="@/assets/auth/tpstar-whole.svg" >
					<img src="@/assets/auth/tpstar-whole.svg" >
					<img src="@/assets/auth/tpstar-whole.svg" >
					<img src="@/assets/auth/tpstar-half.svg" >
				</div>
				<div class="text">
					{{  $t('auth.TP_EXCELLENT') }}
				</div>
			</div>
			<div class="review-text">
				{{  $t('auth.TP_REVIEW') }}
			</div>

		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TrustpilotCard from './TrustpilotCard.vue';

//vuex

/* @group Components_Auth */
/*
  <h4> <b>Account Deleted Page</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'RegisterSide1',
	data() {
		return {
			hideLeftArrow: true,
			hideRightArrow: true,
			scrollAmount: 0,
			scrollIntensity: 450,
			scrolls: {
				left: false,
				right: true
			},
			hoverActive: false,

		};
	},
	computed: {

	},
	components: {
		TrustpilotCard
	},
	async mounted() { },
	methods: {

		// Submits login request
		async formAction() { },
		scrollCards(direction: string) {
			const container = document.getElementById('info-scroll');
			if (!container) {
				return
			}
			let newAmount = this.scrollAmount;
			if (direction === 'right') newAmount += this.scrollIntensity;
			else newAmount = container.scrollLeft - this.scrollIntensity;

			const scrollWidth = container.scrollWidth;
			const itemWidth = container.clientWidth;

			if (newAmount <= 0) newAmount = 0;
			if (newAmount >= scrollWidth - itemWidth) newAmount = scrollWidth - itemWidth;

			this.scrollAmount = newAmount;

			container.scrollTo({
				top: 0,
				left: newAmount,
				behavior: 'smooth'
			});
		},
		mouseOver() {
			this.hoverActive = true;

			if (this.hoverActive) {
				const scrollWidth = (this.$refs.info_cards as any).scrollWidth;
				const clientWidth = (this.$refs.info_cards as any).clientWidth;
				const scrollLeft = (this.$refs.info_cards as any).scrollLeft;

				if (scrollLeft + clientWidth >= scrollWidth) {
					this.hideRightArrow = true;
				} else {
					this.hideRightArrow = false;
				}

				if (scrollLeft === 0) {
					this.hideLeftArrow = true;
				} else {
					this.hideLeftArrow = false;
				}
			}
		},
		mouseOut() {
			this.hoverActive = false;

			if (!this.hoverActive) {
				this.hideRightArrow = true;
				this.hideLeftArrow = true;
			}
		},
		onScroll(event: any) {
			const el = event.target;
			const container = document.getElementById('info-scroll');
			if (!container) {
				return
			}
			const isScrolledToLeft = el.scrollLeft === 0;
			const isScrolledToRight = el.scrollLeft + container.clientWidth === container.scrollWidth;
			if (isScrolledToLeft) {
				this.scrolls.left = false;
				this.scrolls.right = true;
			} else if (isScrolledToRight) {
				this.scrolls.left = true;
				this.scrolls.right = false;
			} else {
				this.scrolls.left = true;
				this.scrolls.right = true;
			}
			this.scrollAmount = el.scrollLeft;
		},
	}
});
</script>
<style scoped>
.title {
	margin-top: 36px;
	text-align: center;
	font-size: 32px;
	font-weight: 400;
	line-height: 40px; /* 125% */
	letter-spacing: -0.64px;
}
.arrow-container {
	position: relative;
	padding-left: 40px;
	padding-right: 40px;
}
.register-section {
	width: 100%;
	display: flex;
    flex-direction: column;
    margin: auto auto;
}
.auth-image-register {
	width: 100%;
}
.loginContainer.info {
	background-color: #dffbe9;
	max-width: 50vw;
}
.screenshot {
	max-width: 720px;
	width: 100%;
	padding-left: 48px;
	padding-right: 48px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 36px;
}
.tp-slider {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	overflow: hidden;
}
.cards-container  {
	gap:24px
}

.arrow {
	z-index: 1;
	opacity: 0;
	pointer-events: none;
	background: #ffffff !important;
	border: 0 !important;
	box-sizing: border-box;
	box-shadow: 0px 0px 10px 1px rgba(182, 191, 210, 0.2) !important;
	width: 36px;
	height: 36px;
	font-size: 22px;
	transition: 200ms;
}

.arrow:hover {
	background-color: #efefef !important;
}

.arrow.show {
	opacity: 1;
	pointer-events: all;
}

.arrow.left {
	position: absolute;
	left: 26px;
	top: 50%;
	transform: translateY(-50%);
}

.arrow.right {
	position: absolute;
	right: 26px;
	top: 50%;
	transform: translateY(-50%);
}

.rounded {
	width: 36px;
	height: 36px;
	flex: 0 0 auto;
	padding: 0;
	border-radius: 100px;
}


.comment-row {
	display: flex;
    flex-wrap: wrap;
    justify-content: center;
	gap: 24px;
	margin-top: 36px;
	margin-bottom: 36px;
	margin-left: 40px;
	margin-right: 40px;
}
.comment {
	width: 197px;
	max-width: 197px;
	text-align: left;

}
.comment .head {
	color: #333;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px; /* 137.5% */
	letter-spacing: -0.16px;
}
.comment .text {
	color: #686475;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.14px;
	margin-top: 8px;
}



@media screen and (max-width: 800px) {
	.comment {
		width: 100%;
		max-width: 100%;

	}
}
@media screen and (min-width: 2140px) {
	.cards-container  {
		justify-content: center;
	}

}
.star-section {
	display: flex;
	justify-content: center;
	align-items: center;
}

.star-section .text {
	color: #039954;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.14px;
	margin-left: 12px;
}
.review-text {
	color: #333;
	font-size: 12px;
	font-weight: 700;
	line-height: 18px; /* 150% */
	letter-spacing: -0.12px;
}

</style>
