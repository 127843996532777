<template>
	<div>
		<div class="deposit-modal-spacing has-text-left">
			<div v-if="process_status === 'processing'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_PROCESSING') }}
			</div>
			<div v-else-if="process_status === 'success'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_SUCCESS') }}
			</div>
			<div v-else-if="process_status === 'error'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_ERROR') }}
			</div>

			<div v-else class="header-bar-global">
				<button class="button back-btn" @click="closePayment()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.ADV_PROCESSING') }}
			</div>

			<!-- payment requested - after submit to advcash -->
			<div v-if="process_status === 'requested'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/continue.svg" />
				</div>

				<div class="payment_provider_section">
					<img src="@/assets/funds/volet.svg" class="transaction_icon" />

					<div class="provider_text">
						<div class="provider_header">{{ $t('funds.ADVCASH') }}</div>
						<p class="is-size-14 has-text-weight-medium">
							{{ $t('funds.CONTINUE_POPUP_WINDOW') }} <br />
							{{ $t('funds.ADVCASH_NOT_WORKING') }}
							<a class="transition-faster" @click="openADVWindow()">{{ $t('funds.ADVCASH_LAUNCH') }}</a>
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>
			</div>

			<!-- payment processing - after volet completed - waiting for deposit -->
			<div v-else-if="process_status === 'processing'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/processing.svg" alt="pending icon" />
				</div>

				<div class="payment_provider_section">
					<div>
						<img src="@/assets/funds/volet.svg" class="transaction_icon" />
					</div>

					<div class="provider_text">
						<div class="provider_header">
							{{ $t('funds.ADVCASH') }}
						</div>
						<p class="is-size-14">{{ $t('funds.TRANSACTION_PROCESSING') }} {{ $t('funds.CLOSE_WINDOW') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment success - after deposit completed -->
			<div v-else-if="process_status === 'success'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/success.svg" alt="Success checkmark image" />
				</div>

				<div class="transaction_details_section" v-if="transaction_data && transaction_data.payload">
					<div class="transaction_header">
						<img src="@/assets/funds/volet.svg" class="transaction_icon" alt="payment provider" />
						{{ getCurrencySymbol(transaction_data.payload.source_currency)
						}}{{ formatAmount(transaction_data.payload.purchase_cost) }}
					</div>

					<div class="transaction_details_lines">
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MPH_CONVERSION') }}
							</p>
							<p>{{ roundFormatter(transaction_data.payload.purchase_amount_mph) }} MPH</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.ADV_FEES') }}
							</p>
							<p>
								{{ getCurrencySymbol(transaction_data.payload.source_currency)
								}}{{ formatAmount(transaction_data.payload.purchase_fees) }}
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.TOTAL_VALUE') }}
							</p>
							<p>
								{{ getCurrencySymbol(transaction_data.payload.source_currency)
								}}{{ formatAmount(transaction_data.payload.purchase_total) }}
							</p>
						</div>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment error -->
			<div v-else-if="process_status === 'error'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/error.svg" alt="payment error" />
				</div>

				<div class="payment_provider_section">
					<div class="provider_text">
						<p class="is-size-14">
							{{ $t('funds.ERROR_TEXT') }}
							{{ $t('funds.ERROR_TRY_AGAIN') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.LEARN_MORE_DEPOSITS') }}
							<a target="_blank" :href="supportArticleLink('DepositMethod')">{{
								$t('funds.HERE')
							}}</a>.
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="closePayment()">
					{{ $t('BACK') }}
				</button>
			</div>

			<form id="adv_form" ref="adv_form" method="post" style="visibility: hidden; display: none" target="_blank"
				action="https://account.volet.com/sci/">
				<input id="ac_account_email" type="hidden" name="ac_account_email" value="" />
				<input id="ac_sci_name" type="hidden" name="ac_sci_name" value="" />
				<input id="ac_amount" type="text" name="ac_amount" value="" />
				<input id="ac_currency" type="text" name="ac_currency" value="" />
				<input id="ac_order_id" type="text" name="ac_order_id" value="" />
				<input id="ac_sign" type="text" name="ac_sign" value="" />
				<!-- Optional Fields -->
				<input id="ac_success_url" type="hidden" name="ac_success_url" value="" />
				<input id="ac_success_url_method" type="hidden" name="ac_success_url_method" value="POST" />
				<input id="ac_fail_url" type="hidden" name="ac_fail_url" value="" />
				<input id="ac_fail_url_method" type="hidden" name="ac_fail_url_method" value="POST" />
				<input id="ac_status_url" type="hidden" name="ac_status_url" value="" />
				<input id="ac_status_url_method" type="hidden" name="ac_status_url_method" value="POST" />
				<input id="ac_comments" type="text" name="ac_comments" value="" />
				<input id="ac_client_lang" type="text" name="ac_client_lang" value="en" />

				<input type="submit" />
			</form>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PaymentMixin from '@/mixins/payment.mixin';
import { TransactionService } from '@/services/TransactionService';
import { UserService } from '@/services/UserService';

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'PaymentTypeADVCash',
	mixins: [PaymentMixin],
	components: {},
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		maxLeft: {
			type: Number,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		}
	},
	data() {
		return {};
	},
	async mounted() {
		if (this.user?.payload.advSurvey == undefined || (this.user.payload.advSurvey !== false && this.user.payload.advSurvey !== 'false')) {
			this.updateUserPayload({ payloadOption: 'advSurvey', payloadValue: 'true' });
		}

		this.purchase_price = String(this.balance.mph_rate);
		this.purchase_cost = String(this.funds.amount);
		this.purchase_fees = '0';
		this.purchase_total = String(this.funds.amount);
		this.purchase_amount_mph = String(Number(this.funds.amount) / Number(this.balance.mph_rate));
		this.process_status = 'requested';
		this.createPurchase();
	},

	methods: {
		async openADVWindow() {
			const form = this.$refs.adv_form as any;

			const data = this.funds.current_order;
			const url = import.meta.env.VITE_API_ROOT + '/';

			form.elements['ac_account_email'].value = 'martin@morpher.com';
			form.elements['ac_sign'].value = data.payload.ac_sign;
			form.elements['ac_sci_name'].value = data.payload.ac_sci_name;
			form.elements['ac_order_id'].value = data.id;
			form.elements['ac_amount'].value = data.payload.purchase_total;
			form.elements['ac_currency'].value = (data.payload.source_currency || 'USD').toUpperCase();
			form.elements['ac_success_url'].value = url + 'v1/payment/webhooks/advCashSuccess'; //url + '/success'
			form.elements['ac_success_url_method'].value = 'POST';
			form.elements['ac_fail_url'].value = url + 'v1/payment/webhooks/advCashFail'; ///fail'
			form.elements['ac_fail_url_method'].value = 'POST';
			form.elements['ac_status_url'].value = url + 'v1/payment/webhooks/advCashStatus';
			form.elements['ac_status_url_method'].value = 'POST';
			form.elements['ac_comments'].value = 'Purchase MPH tokens - ' + this.user?.activePortfolio?.eth_address;
			form.elements['ac_client_lang'].value = this.$i18n.locale || 'en'

			try {
				form.submit();
			} catch (err) {
				form.target = '_self';
				form.submit();
			}
		},
		async createPurchase() {
			try {
				this.purchase_loading = true;
				if (this.funds.current_order && this.funds.current_order.eth_address) {
					this.process_status = 'requested';
					this.purchase_loading = false;
					return false;
				}

				if (!this.funds.amount) {
					this.resetFunds({});
					return;
				}
				// update the backend with the new user balances.
				const result = await TransactionService.purchaseADVOrderRequest({
					data: {
						eth_address: this.user?.activePortfolio?.eth_address || '',
						order_id: this.order_id,
						purchase_price: this.balance.mph_rate.toString(),
						purchase_cost: this.purchase_cost.toString(),
						purchase_fees: this.purchase_fees.toString(),
						purchase_total: this.purchase_total.toString(),
						purchase_amount_mph: this.purchase_amount_mph.toString(),
						transaction_id: this.transaction_id,
						purchase_currency: (this.funds.currency || 'USD').toUpperCase()
					}
				});

				const form = this.$refs.adv_form as any;
				const data = result?.data?.purchaseADVOrderRequest;

				this.updateFunds({ current_order: result?.data?.purchaseADVOrderRequest });

				const url = import.meta.env.VITE_API_ROOT + '/';

				form.elements['ac_account_email'].value = 'martin@morpher.com';
				form.elements['ac_sign'].value = data.payload.ac_sign;
				form.elements['ac_sci_name'].value = data.payload.ac_sci_name;
				form.elements['ac_order_id'].value = data.id;
				form.elements['ac_amount'].value = data.payload.purchase_total;
				form.elements['ac_currency'].value = (data.payload.source_currency || 'USD').toUpperCase();
				form.elements['ac_success_url'].value = url + 'v1/payment/webhooks/advCashSuccess'; //url + '/success'
				form.elements['ac_success_url_method'].value = 'POST';
				form.elements['ac_fail_url'].value = url + 'v1/payment/webhooks/advCashFail'; ///fail'
				form.elements['ac_fail_url_method'].value = 'POST';
				form.elements['ac_status_url'].value = url + 'v1/payment/webhooks/advCashStatus';
				form.elements['ac_status_url_method'].value = 'POST';
				form.elements['ac_comments'].value = 'Purchase MPH tokens - ' + this.user?.activePortfolio?.eth_address;

				try {
					form.submit();
				} catch (err) {
					form.target = '_self';
					form.submit();
				}

				UserService.sendAnalytics({
					event: 'begin_checkout',
					data: {
						currency: this.funds.currency,
						transaction_id: data.id,
						value: data.payload.purchase_total,
						provider: 'advcash',

					}
				});

				this.process_status = 'requested';
				this.purchase_loading = false;
			} catch (err) {
				this.purchase_loading = false;

				if (err) return this.error(err);
			}
		}
	}
});
</script>
<style scoped src="../../assets/css/payment.css"></style>
