<template>
	<div>
		<div class="deposit-modal-spacing has-text-left">
			<div v-if="process_status === 'processing'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_PROCESSING') }}
			</div>
			<div v-else-if="process_status === 'success'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_SUCCESS') }}
			</div>
			<div v-else-if="process_status === 'error'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_ERROR') }}
			</div>

			<div v-else class="header-bar-global">
				<button class="button back-btn" @click="toggleDepositWithdrawModal(false);">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.COINBASE_PROCESSING') }}
			</div>

			<!-- payment requested - process payment in coinbase window -->
			<div v-if="process_status === 'requested'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img v-if="showLoader" class="loader-icon is-64" src="@/assets/icons/loader.svg" />
					<img v-else src="@/assets/funds/continue.svg" alt="external window" />
				</div>

				<div class="payment_provider_section">
					<img src="@/assets/funds/coinbase.svg" class="transaction_icon" alt="payment provider" />

					<div class="provider_text">
						<div class="provider_header">
							{{ $t('funds.COINBASE') }}
						</div>
						<p class="is-size-14 has-text-weight-medium">
							{{ $t('funds.CONTINUE_POPUP_WINDOW') }} <br />
							{{ $t('funds.MOONPAY_NOT_WORKING') }}
							<a class="transition-faster" @click="openCoinbaseWindow()">{{ $t('funds.MOONPAY_LAUNCH')
								}}</a>
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>
				<div id="coinbase-sdk" ref="continue_button">
					<a class="donate-with-crypto" id="coinbase-button-container"
						href="https://commerce.coinbase.com/checkout/6da189f179bc31">
						<span>Open Coinbase</span>
					</a>
				</div>
			</div>

			<!-- payment processing - after coinbase submitted - waiting for deposit -->
			<div v-else-if="process_status === 'processing'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/processing.svg" alt="pending icon" />
				</div>

				<div class="payment_provider_section">
					<div>
						<img src="@/assets/funds/coinbase.svg" class="transaction_icon" alt="payment provider" />
					</div>

					<div class="provider_text">
						<div class="provider_header">
							{{ $t('funds.COINBASE') }}
						</div>
						<p>{{ $t('funds.TRANSACTION_PROCESSING') }} {{ $t('funds.CLOSE_WINDOW') }}</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment success - after deposit completed -->
			<div v-else-if="process_status === 'success'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/success.svg" alt="Success checkmark image" />
				</div>

				<div class="transaction_details_section" v-if="transaction_data && transaction_data.payload">
					<div class="transaction_header">
						<img src="@/assets/funds/coinbase.svg" class="transaction_icon" alt="payment provider" />
						{{ roundFormatter(transaction_data.payload.payment_crypto_amount) }}
						{{ transaction_data.payload.payment_crypto_currency }}
					</div>

					<div class="transaction_details_lines">
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MPH_CONVERSION') }}
							</p>
							<p>{{ roundFormatter(transaction_data.payload.purchase_amount_mph) }} MPH</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.COINBASE_FEES') }}
							</p>
							<p>${{ formatAmount(transaction_data.payload.purchase_fees_usd) }}</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.TOTAL_VALUE') }}
							</p>
							<p>${{ formatAmount(transaction_data.payload.purchase_total_usd) }}</p>
						</div>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment error -->
			<div v-else-if="process_status === 'error'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/error.svg" alt="payment error" />
				</div>

				<div class="payment_provider_section">
					<div class="provider_text">
						<p class="is-size-14">
							{{ $t('funds.ERROR_TEXT') }}
							{{ $t('funds.ERROR_TRY_AGAIN') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.LEARN_MORE_DEPOSITS') }}
							<a target="_blank" :href="supportArticleLink('DepositMethod')">{{
								$t('funds.HERE')
							}}</a>.
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15"
					@click="toggleDepositWithdrawModal(false);">
					{{ $t('BACK') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PaymentMixin from '@/mixins/payment.mixin';
import { TransactionService } from '@/services/TransactionService';
import { UserService } from '@/services/UserService';
/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'PaymentTypeCoinbase',
	mixins: [PaymentMixin],
	components: {},
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		maxLeft: {
			type: Number,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			showLoader: true
		};
	},
	async mounted() {
		window.setTimeout(() => {
			this.showLoader = false;
		}, 3000);

		this.process_status = 'requested';
		this.purchase_price = String(this.balance.mph_rate);
		this.purchase_cost = String(this.funds.amount);
		this.purchase_fees = '0';
		this.purchase_total = String(this.funds.amount);
		this.purchase_amount_mph = String(Number(this.funds.amount) / Number(this.balance.mph_rate));
		this.createPurchase();
	},
	methods: {

		async openCoinbaseWindow() {


			let url = this.funds.current_order.payload.hosted_url


			window.open(url, '_blank');

		},
		showCoinbase() {
			const frame = document.getElementsByClassName('bwc-modal-container');
			if (frame && frame.length > 0) {
				//frame[0].style.display = 'block';
				document.getElementById('coinbase-button-container')?.click();
			} else {
				window.setTimeout(() => {
					this.showCoinbase();
				}, 100);
			}
		},
		addEventsToCoinbase() {
			this.showCoinbase();
			if (!window.BuyWithCrypto) return;

			window.BuyWithCrypto.registerCallback('onSuccess', (e: any) => {
				//console.log(e);
				this.process_status = 'processing';
			});

			window.BuyWithCrypto.registerCallback('onFailure', (e: any) => {
				//console.log(e);
				this.process_status = 'error';
			});

			window.BuyWithCrypto.registerCallback('onPaymentDetected', (e: any) => {
				//console.log(e);
				// Payment has been detected but not yet confirmed
				this.process_status = 'processing';
			});
		},
		async createPurchase() {
			try {
				this.purchase_loading = true;
				// update the backend with the new user balances.
				const result = await TransactionService.purchaseCoinbaseOrderRequest({
					data: {
						eth_address: this.user?.activePortfolio?.eth_address || '',
						order_id: this.order_id,
						transaction_id: this.transaction_id
					}
				});
				if (result && result.data?.purchaseCoinbaseOrderRequest) {
					if (
						result.data.purchaseCoinbaseOrderRequest.payload &&
						result.data.purchaseCoinbaseOrderRequest.payload.result === 'error'
					) {
						TransactionService.purchasePaypalOrderCaptureError({
							data: {
								provider: 'coinbase',
								total_amount_usd: '',
								total_amount_mph: '',
								country: this.user?.document_country,
								error_details:
									'Coinbase Purchase Order Request Error - ' + result.data.purchaseCoinbaseOrderRequest.payload.message,
								eth_address: this.user?.activePortfolio?.eth_address
							}
						});
						this.logSentryError(
							'Coinbase Purchase Order Request Error - ' + result.data.purchaseCoinbaseOrderRequest.payload.message
						);
						this.$buefy.dialog.alert({
							title: this.$t('PAYMENT_CREATION_ERROR'),
							message: result.data.purchaseCoinbaseOrderRequest.payload.message || this.$t('PAYMENT_CREATION_ERROR_MESSAGE'),
							confirmText: this.$t('OK')
						});
						this.$router.push('/funds/purchase/coinbase-error').catch(() => { });
						this.purchase_loading = false;

						return;
					}


					this.updateFunds({ current_order: result.data.purchaseCoinbaseOrderRequest });

					this.openCoinbaseWindow();


					this.transaction_id = result.data.purchaseCoinbaseOrderRequest.id;
					this.order_id = result.data.purchaseCoinbaseOrderRequest.payload.order_id;

					UserService.sendAnalytics({
						event: 'begin_checkout',
						data: {
							currency: this.funds.currency,
							transaction_id: this.transaction_id,
							value: 0,
							provider: 'coinbase',

						}
					});
					this.purchase_loading = false;
				}
				this.purchase_loading = false;
			} catch (err: any) {
				this.purchase_loading = false;
				this.$router.push('/funds/purchase/coinbase-error').catch(() => { });
				TransactionService.purchasePaypalOrderCaptureError({
					data: {
						provider: 'coinbase',
						total_amount_usd: '',
						total_amount_mph: '',
						country: this.user?.document_country,
						error_details: 'Coinbase Purchase Order Error - ' + err.toString(),
						eth_address: this.user?.activePortfolio?.eth_address
					}
				});
				this.logSentryError('Coinbase Purchase Order Error - ' + err.toString());
				if (err) return this.error(err);
			}
		}
	}
});
</script>
<style scoped src="../../assets/css/payment.css"></style>
<style>
.donate-with-crypto {
	display: none;
}
</style>
<style scoped>
#coinbase-button-container {
	min-width: 4px;
	color: #fff;
	background-color: #fff !important;
	margin-bottom: -1rem;
	display: none;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	position: absolute;
}

.spinner {
	width: 200px;
}

#coinbase-button-container .spinner {
	top: 10px;
	display: none !important;
}

@media only screen and (max-width: 768px) {
	#coinbase-button-container {
		min-width: 100%;
	}
}

.coinbase_frame {
	width: 100%;
	min-height: 500px;
}

.is-64 {
	width: 64px;
}
</style>
