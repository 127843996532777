<template>
	<div class="mobileappbanner js_mobileappbanner"
		:class="{ hidden: os == 'unknown' || closed, 'mobileappbanner-ios': os == 'ios', 'mobileappbanner-android': os == 'android' }">
		<a href="#" class="mobileappbanner_exit js_mobileappbanner_exit" aria-label="Close" @click="closed = true"></a>
		<div class="mobileappbanner_icon" style="background-image: url(./img/mobile_icon.png);"></div>
		<div class="mobileappbanner_info has-text-left has-font-manrope">
			<div>
				<div class="mobileappbanner_info_title">{{ $t('mobile.MORPHER_APP') }}</div>
				<div class="mobileappbanner_info_author">{{ $t('mobile.TAGLINE') }}</div>
				<div v-if="os == 'ios'" class="mobileappbanner_info_price">{{ $t('mobile.FREE_APP_STORE') }}</div>
				<div v-else class="mobileappbanner_info_price">{{ $t('mobile.FREE_GOOGLE_PLAY') }}</div>
			</div>
		</div>
		<a :href="link" class="mobileappbanner_button js_mobileappbanner_button" rel="noopener" aria-label="view">
			<span class="mobileappbanner_button_label">{{ $t('mobile.VIEW') }}</span>
		</a>
	</div>
</template>
<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { useContractStore } from '@/store/modules/contract';
import EventBus from '@/store/event-bus';
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'MobileAppBanner',
	components: {},
	data: () => {
		return {
			os: 'unknown',
			link: '',
			closed: false,
			default_wallet: ''
		};
	},
	computed: {
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
		}),

	},
	async mounted() {
		EventBus.$off('AppBannerShow', this.showAppBanner);
		if (this.walletType) {
			this.default_wallet = this.walletType;
		} else if (localStorage.getItem('walletType')) {
			this.default_wallet = localStorage.getItem('walletType') || '';
		}
		if (this.default_wallet == 'morpherwallet' || this.default_wallet == '') {
			EventBus.$on('AppBannerShow', this.showAppBanner);
		}
	},
	methods: {
		showAppBanner(data: string) {
			const os = this.getMobileOperatingSystem();

			this.link = data;
			this.os = os;
		},

		getMobileOperatingSystem() {
			var userAgent = window.navigator.userAgent || window.navigator.vendor || (window.window as any).opera;
			if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
				return 'ios';
			} else if (userAgent.match(/Android/i)) {
				return 'android';
			} else {
				return 'unknown';
			}
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hidden {
	display: none;
}

.mobileappbanner {
	overflow-x: hidden;
	width: 100%;
	height: 84px;
	font-family: 'Manrope', sans, sans-serif;
	background: rgba(255, 255, 255, 0.95);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.mobileappbanner_exit {
	position: relative;
	top: calc(50% - 6px);
	left: 9px;
	display: block !important;
	margin: 0;
	width: 12px;
	height: 12px;
	border: 0;
	text-align: center;
}

.mobileappbanner_exit::before,
.mobileappbanner_exit::after {
	position: absolute;
	width: 1px;
	height: 12px;
	background: #767676;
	content: ' ';
}

.mobileappbanner_exit::before {
	transform: rotate(45deg);
}

.mobileappbanner_exit::after {
	transform: rotate(-45deg);
}

.mobileappbanner_icon {
	position: absolute;
	top: 10px;
	left: 30px;
	width: 64px;
	height: 64px;
	border-radius: 15px;
	background-size: 64px 64px;
}

.mobileappbanner_info {
	position: absolute;
	top: 10px;
	left: 104px;
	display: flex;
	overflow-y: hidden;
	width: 60%;
	height: 64px;
	align-items: center;
	color: #000;
}

.mobileappbanner_info_title {
	font-size: 16px;
	font-weight: 600;
}

.mobileappbanner_info_author,
.mobileappbanner_info_price {
	font-size: 12px;
	font-weight: 500;
}

.mobileappbanner_button {
	position: absolute;
	top: 32px;
	right: 10px;
	z-index: 1;
	display: block !important;
	padding: 0 12px;
	min-width: 10%;
	border-radius: 20px;
	background: #00c386;
	color: #fff;
	font-size: 18px;
	text-align: center;
	text-decoration: none;
	font-weight: 700;
}

.mobileappbanner_button_label {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.mobileappbanner {
		display: none;
	}
}

/* .mobileappbanner.mobileappbanner-android {
	background: #3d3d3d url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7');
	box-shadow: inset 0 4px 0 #88b131;
} */

/* .mobileappbanner.mobileappbanner-android .mobileappbanner_exit {
	left: 6px;
	margin-right: 7px;
	width: 17px;
	height: 17px;
	border-radius: 14px;
	background: #1c1e21;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);
	color: #b1b1b3;
	font-family: 'ArialRoundedMTBold', Arial;
	font-size: 20px;
	line-height: 17px;
	text-shadow: 0 1px 1px #000;
} */

/* .mobileappbanner.mobileappbanner-android .mobileappbanner_exit::before,
.mobileappbanner.mobileappbanner-android .mobileappbanner_exit::after {
	top: 3px;
	left: 8px;
	width: 2px;
	height: 11px;
	background: #b1b1b3;
}

.mobileappbanner.mobileappbanner-android .mobileappbanner_exit:active,
.mobileappbanner.mobileappbanner-android .mobileappbanner_exit:hover {
	color: #eee;
}

.mobileappbanner.mobileappbanner-android .mobileappbanner_icon {
	background-color: transparent;
	box-shadow: none;
}

.mobileappbanner.mobileappbanner-android .mobileappbanner_info {
	color: #ccc;
	text-shadow: 0 1px 2px #000;
}

.mobileappbanner.mobileappbanner-android .mobileappbanner_info_title {
	color: #fff;
	font-weight: bold;
} */

/* .mobileappbanner.mobileappbanner-android .mobileappbanner_button {
	top: 30px;
	right: 20px;
	padding: 0;
	min-width: 12%;
	border-radius: 0;
	background: none;
	box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
	color: #d1d1d1;
	font-size: 14px;
	font-weight: bold;
}

.mobileappbanner.mobileappbanner-android .mobileappbanner_button:active,
.mobileappbanner.mobileappbanner-android .mobileappbanner_button:hover {
	background: none;
}

.mobileappbanner.mobileappbanner-android .mobileappbanner_button_label {
	display: block;
	padding: 0 10px;
	background: #42b6c9;
	background: linear-gradient(to bottom, #42b6c9, #39a9bb);
	box-shadow: none;
	line-height: 24px;
	text-align: center;
	text-shadow: none;
	text-transform: none;
}

.mobileappbanner.mobileappbanner-android .mobileappbanner_button_label:active,
.mobileappbanner.mobileappbanner-android .mobileappbanner_button_label:hover {
	background: #2ac7e1;
} */
</style>
