<template>
	<div>
		<div class="mobile-header">
			<div class="mobile-header-item ml-24">
				<router-link class="logo-container" to="/">
					<img class="logo" src="../assets/morpher_logo.svg" :alt="$t('MORPHER_LOGO')" />
				</router-link>
			</div>

			<!-- Search Bar -->
			<div class="mobile-header-item">
				<SearchInput />
			</div>

			<!-- Account and Settings -->
			<div class="header-right-container">
				<!-- Portfolio Data -->
				<div v-show="activePortfolio && unlockOrNull" class="header-portfolio-info">
					<div v-if="!noBalance">
						<div>
							<!-- Cash Balance -->
							<div v-show="portfolioItem === 0" class="has-text-right">
								<p class="number-headers">{{ $t('BALANCE') }}:</p>
								<p v-if="currentView === 'MPH'" class="highlighted-mini">
									{{ floorRound((activePortfolio?.cash_balance || 0) / Math.pow(10, 18), 2) }} MPH
								</p>
								<p v-else-if="currentView === 'USD' && mph_market" class="highlighted-mini">
									$ {{ usdFormatter(((activePortfolio?.cash_balance || 0) / Math.pow(10, 18)) *
										(mph_market?.close || 0))
									}}
								</p>
							</div>
						</div>
					</div>
				</div>

				<!-- TODO - Add correct images with and without bell -->
				<router-link to="/rewards" class="insights-icon">
					<img v-if="aiInsightsOn" src="@/assets/icons/reward-icon-on.svg" />
					<img v-else src="@/assets/icons/reward-icon.svg" />
				</router-link>

				<div class="insights-icon" @click="toggleAIInsightsModal('all')">
					<img v-if="rewardOn" src="@/assets/icons/ai_icon_on.svg" />
					<img v-else src="@/assets/icons/ai_icon_off.svg" />
				</div>

				<!-- <router-link to="/" class="insights-icon">
					<img v-if="aiInsightsOn" src="@/assets/icons/notification-bell-on.svg" />
					<img v-else src="@/assets/icons/notificiation-bell.svg" />
				</router-link> -->


				<div class="mobile-header-item mr-24" @click="menuActive = !menuActive">
					<img src="@/assets/icons/hamburger-menu.svg" />
				</div>
			</div>
		</div>
		<HeaderMigrationModal />
		<HeaderTryAppModal />
		<WalletRecoveryModal />

		<TabletMenu :active="menuActive" @close="menuActive = false" />
		<!-- <MobileMenuProfile :active="menuActiveProfile" @close="menuActiveProfile = false" /> -->
	</div>
</template>

<script lang="ts">
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { defineComponent } from 'vue'
import TabletMenu from './TabletMenu.vue';
import SearchInput from './SearchInput.vue';
import Blockie from '@/components/Blockie.vue';
import HeaderMigrationModal from './modals/HeaderMigrationModal.vue';
import HeaderTryAppModal from './modals/HeaderTryAppModal.vue';
import WalletRecoveryModal from './modals/WalletRecoveryModal.vue';

//vuex
import { MarketService } from '../services/MarketService';
import { mapActions, mapState } from 'pinia';
import { useMarketsStore } from '@/store/modules/markets';
import { useStatusStore } from '@/store/modules/status';

export default defineComponent({
	components: {
		TabletMenu,

		Blockie,
		SearchInput,
		HeaderMigrationModal,
		HeaderTryAppModal,
		WalletRecoveryModal
	},
	data() {
		return {
			menuActive: false,
			menuActiveProfile: false,
			newsSubscription: null,
			aiInsightsOn: false,
			rewardOn: false,
			currentView: 'MPH',
			currencySelector: false,
			portfolioItem: 0,


		};
	},
	watch: {
		payload(payloadValue) {
			this.updateAILastSeen();
			if (this.currentView !== (payloadValue.currency_view || 'MPH')) {
				this.currentView = payloadValue.currency_view || 'MPH';
				this.currencySelector = this.currentView === 'USD';
				if (this.currentView === 'USD' && !this.mph_market) {
					this.getMPHMarket({
						component: this
					});
				}
			}
		},
		aiInsights(nv) {
			this.updateAILastSeen();
		},
		app_lang(nv) {
			this.localeUpdated(nv);
		}
	},
	async mounted() {
		if (this.aiInsights.length == 0) {
			this.getAIMarketInsights({ component: this });
		}
		this.aiNewsSubscribe();

		this.currentView = this.payload?.currency_view || 'MPH';
		this.currencySelector = this.currentView === 'USD';
		if (this.currentView === 'USD' && !this.mph_market) {
			this.getMPHMarket({
				component: this
			});
		}
	},
	unmounted() {

		this.aiNewsUnSubscribe();
	},
	computed: {
		...mapState(useMarketsStore, {
			aiInsights: (state) => state.aiInsights,
			mph_market: (state) => state.mph_market,
		}),
		...mapState(useUserStore, {
			payload: (state) => state.data?.payload,
			activePortfolio: (state) => state.activePortfolio,
			app_lang: (state) => state.data?.payload?.app_lang,
			valueSum: (state) => state.valueSum,
			portfolioLineData: (state) => state.portfolioLineData,
		}),
		...mapState(useStatusStore, {
			walletText: (state) => state.walletText,
		}),
		unlockOrNull() {
			if (this.walletText) {
				return this.walletText.message === null || this.walletText.message.toLowerCase().indexOf('unlock') > -1;
			} else {
				return true;
			}
		},
		pending() {
			return this.activePortfolio ? Number(this.activePortfolio.pending) : 0;
		},
		noBalance() {
			if (this.activePortfolio?.cash_balance == 0 && this.activePortfolio.current_value == 0) {
				return true;
			} else {
				return false;
			}
		},
		portfolioValueUsd() {
			return 0;
		}
	},
	methods: {
		...mapActions(useMarketsStore, {
			getAIMarketInsights: 'getAIMarketInsights',
			getMPHMarket: 'getMPHMarket',
		}),
		...mapActions(useModalsStore, {
			toggleAIInsightsModal: 'toggleAIInsightsModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),
		...mapActions(useMarketsStore, {
			newAIMarketInsight: 'newAIMarketInsight'
		}),
		localeUpdated(locale: string) {
			setTimeout(() => {
				this.getAIMarketInsights({ component: this });
			}, 1000);
		},
		updateAILastSeen() {
			if (this.aiInsights.length > 0 && this.payload) {
				if (this.payload?.ai_last_seen) {
					const fil = this.aiInsights.filter((ai) => Number(ai.timestamp) > Number(this.payload?.ai_last_seen));

					if (fil.length > 0) {
						this.aiInsightsOn = true;
					} else {
						this.aiInsightsOn = false;
					}
				} else {
					this.aiInsightsOn = true;
				}
			} else {
				this.aiInsightsOn = false;
			}
		},
		changeCurrency() {
			if (this.currencySelector) {
				this.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'USD' });
				this.currentView = 'USD';
			} else {
				this.updateUserPayload({ payloadOption: 'currency_view', payloadValue: 'MPH' });
				this.currentView = 'MPH';
			}
			this.currencySelector = this.currentView === 'USD';

			if (this.currentView === 'USD' && !this.mph_market) {
				this.getMPHMarket({
					component: this
				});
			}
		},
		switchPortfolioItem() {
			if (this.portfolioItem > 2) {
				this.portfolioItem = 0;
			} else if (this.portfolioItem === 2 && (!this.activePortfolio?.stake_value || Number(this.activePortfolio?.stake_value) === 0)) {
				this.portfolioItem = 0;
			} else if (this.portfolioItem === 1) {
				// Prevent showing infinite return
				this.portfolioItem = 0;
			} else {
				this.portfolioItem += 1;
			}
		},
		async aiNewsSubscribe() {
			if (this.newsSubscription) {
				await MarketService.unsubscribeFromAINews(this.newsSubscription);
				this.newsSubscription = null;
			}

			this.newsSubscription = await MarketService.subscribeToAINews('AI_INSIGHTS', (data) => {
				if (data) {
					try {
						const ai_news = data.data.updateNews;

						if (ai_news) {
							const summary = ai_news.summary.split('Assessment:');
							let intro_text = '';
							let summary_text = '';

							if (summary.length == 2) {
								intro_text = summary[0];
								summary_text = summary[1];
							} else {
								const summary2 = ai_news.summary.split('\n');

								if (summary2.length > 1) {
									intro_text = summary2.shift();
									summary_text = summary2.join('\n');
								} else {
									intro_text = '';
									summary_text = ai_news.summary;
								}
							}

							const ai_data = {
								market_id: ai_news.market_id,
								timestamp: ai_news.timestamp,
								headline: intro_text,
								source: 'ai',
								summary: summary_text,
								image_url: null,
								bullish: ai_news.has_paywall,
								change_percent: 0,
								url: null
							};

							if (
								!this.aiInsights.find((item) => item.market_id == ai_news.market_id && item.timestamp == ai_news.timestamp)
							) {
								this.newAIMarketInsight(ai_data);
							}
						}
					} catch (err) { }
				}
			});
		},
		async aiNewsUnSubscribe() {
			if (this.newsSubscription) {
				await MarketService.unsubscribeFromAINews(this.newsSubscription);
				this.newsSubscription = null;
			}
		}
	}
});
</script>

<style scoped>
.mobile-header {
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
	position: fixed;
	width: calc(100% - 10px);
	background-color: white;
	z-index: 30;
	min-height: 62px;
	top: 0px;
	left: 0px;

	margin: auto;

	width: 100%;
}

.mobile-header-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* padding-right: 16px;
	padding-left: 16px; */
	cursor: pointer;
}

.logo-container {
	display: flex;
}

.burger-icon {
	font-size: 24px;
}

.insights-icon {
	cursor: pointer;
}

.insights-icon img {
	min-width: 24px;

}

.notification-icon img {
	padding-top: 3px;
}

.logo {
	width: 38px;
}




.header-right-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
}

.highlighted-mini {
	font-weight: 500;
	color: var(--text-text-primary, #333);

	/* Large Text Bold */
	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: -0.16px;
}

.number-headers {
	color: var(--text-text-secondary, #686475);

	/* Normal Text */
	font-family: Manrope;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.14px;
}
</style>