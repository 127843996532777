<template>
	<div class="formcontainer">
		<div class="headerImage">
			<a href="https://www.morpher.com" target="_blank" alt="Morpher Homepage">
				<img class="image" src="@/assets/auth/morpher-logo.svg" alt="Morpher Logo" />
			</a>
		</div>

		<div v-if="!config.application_live && $route.query && $route.query.adminoverride !== 'true'" class="authBox">
			{{ $t('MAINTANANCE_MODE') }}
		</div>
		<div class="authBox">
			<div v-show="!initialising">
				<!-- Connect Fortmatic -->
				<div>
					<h2 data-cy="logInTitle" class="authTitle">{{ $t('auth.2_STEP_VERIFICATION') }}</h2>
					<p data-cy="logInDescription" v-if="authenticator_2fa" class="authText">
						{{ $t('auth.2FA_TITLE_AUTH') }}
					</p>
					<p data-cy="logInDescription" v-else class="authText">{{ $t('auth.2FA_TITLE_EMAIL') }}</p>

					<!-- Signin with email/password -->
					<div>
						<div class="field" v-if="authenticator_2fa">
							<label class="label">{{ $t('auth.AUTH_CODE') }}</label>
							<div class="control">
								<input type="email" class="input" data-cy="walletEmail"
									:placeholder="$t('auth.ENTER_CODE')" name="walletEmail" v-model="walletEmail" />
							</div>
						</div>

						<div v-else class="field">
							<label class="label">{{ $t('auth.EMAIL_CODE') }}</label>

							<div class="control">
								<input type="password" class="input" data-cy="walletPassword"
									:placeholder="$t('auth.ENTER_CODE')" name="walletPassword"
									v-model="walletPassword" />
							</div>
						</div>

						<div class="error" v-if="logonError">
							<p data-cy="loginError">
								⚠️ <span v-html="logonError"></span>
								<router-link v-if="showRecovery" to="/recovery"
									class="login-router transition-faster"><span class="ml-1">{{
										$t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span></router-link>
							</p>
						</div>

						<button type="submit" @click="submitCode" data-cy="submit"
							:class="{ 'is-loading': loginProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('auth.SUBMIT') }}</span>
						</button>

						<p class="loginLink">
							<router-link to="/login" class="login-router transition-faster"><span>{{ $t('auth.CANCEL')
									}}</span></router-link>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 
		<vue-recaptcha
			ref="recaptcha"
			size="invisible"
			:sitekey="recaptchaSiteKey"
			:load-recaptcha-script="true"
			@verify="onCaptchaVerified"
			@error="onCaptchaError"
			@expired="onCaptchaExpired"
			@render="onCaptchaLoaded"
		/> -->
	</div>
</template>

<script lang="ts">
//vuex
import { useAdminStore } from '@/store/modules/admin';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { ERROR } from '@/store/mutation-types';

//import { getDictionaryValue } from '@/helpers/wallet/dictionary';
import { accessTokenName, refreshTokenName, getUserID } from '@/helpers/utils';
import deviceType from '@/helpers/deviceType';
import { UserService } from '@/services/UserService';
import LoginApple from './LoginApple.vue';
import LoginGoogle from './LoginGoogle.vue';

import * as blockies from 'ethereum-blockies-png';
//import RecaptchaMixin from '@/mixins/recaptcha.mixin';
import AuthMixin from '@/mixins/auth.mixin';
//import VueRecaptcha from 'vue-recaptcha';
import Cookie from 'js-cookie';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { defineComponent } from 'vue';
import { morpherWalletConfig } from '@/helpers/morpherwallet';
import type { TMorpherWalletInit } from '@/types/graphql-global-types';

/* @group Components_Auth */
/*
  <h4> <b>Login Form</b> </h4>
  <br> Functionality:
  <br> Default: auth
  <br> Error: Shows server error message as snack in under right corner
  <br> Success: / -> Default application view
*/
export default defineComponent({
	name: 'WalletLogin2fa',
	components: { LoginApple, LoginGoogle }, // , 'vue-recaptcha': VueRecaptcha
	mixins: [AuthMixin], // RecaptchaMixin
	data() {
		return {
			walletEmail: '',
			walletPassword: '',
			walletError: '',
			logonError: '',
			loginUser: {},
			initialising: true,
			loginProcessing: false,
			showRecovery: false,
			walletClientlogin: {},
			morpherWalletInit: {} as any,
			default_wallet: '',
			showMetamaskHelp: false,
			airdrop_id: '',
			referred_by: '',
			custom_invite: '',
			morpherwallet: null as any,
			isMetamaskSupported: false,

			emailAddress: '',

			email_2fa: false,
			authenticator_2fa: false,
			recovery_types: null as any
		};
	},
	computed: {
		...mapState(useAdminStore, {
			config: (state) => state.config,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useContractStore, {
			contract: state => state as any,
		})


	},
	watch: {
		walletError(newValue) {
			if (newValue) {
				// If there is a logon error then log it to sentry
				this.logSentryError('Logon Error:' + newValue);
				this.error({ message: 'WALLET_CONNECT_ERROR' });
			}
		}
	},
	async mounted() {
		// Fill emailOrUsernameStorage from url 'account' query parameter
		if (this.$route.query.account !== undefined) {
			localStorage.setItem('emailOrUsernameStorage', this.$route.query.account)
		}

		if (localStorage.getItem('walletType')) {
			this.default_wallet = localStorage.getItem('walletType') || '';
		}

		// browsers that support metamask
		const browserMetamask = ['chrome', 'brave', 'opera', 'yandex', 'firefox', 'edge'];

		// return only the first word od the browser name
		const browser = navigator.sayswho.replace(/ .*/, '').toLowerCase();

		if (browserMetamask.includes(browser) || deviceType.isAnyMobile()) {
			this.isMetamaskSupported = true;
			this.showMetamaskHelp = false;
		}

		this.airdrop_id = this.$route.query.airdrop_id ? this.$route.query.airdrop_id : null;
		this.referred_by = this.$route.query.referred_by ? this.$route.query.referred_by : null;
		this.custom_invite = this.$route.query.custom_invite ? this.$route.query.custom_invite : null;

		if (this.referred_by) localStorage.setItem('airdrop_referred_by', this.referred_by);
		else this.referred_by = localStorage.getItem('airdrop_referred_by') || '';

		if (this.custom_invite) localStorage.setItem('airdrop_custom_invite', this.custom_invite);
		else this.custom_invite = localStorage.getItem('airdrop_custom_invite') || '';

		this.initialising = false;

		this.initMorpherWallet();
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
		}),
		...mapActions(useUserStore, {
			loginWallet: 'loginWallet',
		}),
		...mapActions(useContractStore, {

			setMorpherWalletProvider: 'setMorpherWalletProvider',
		}),

		async initMorpherWallet() {
			if (this.contract && this.contract.morpherwallet) {
				this.morpherWalletInit = {
					morpherwallet: this.contract.morpherwallet,
					provider: this.contract.provider,
					walletClient: this.contract.walletClient
				};
			} else {
				this.morpherWalletInit = await morpherWalletConfig();
				this.morpherWalletInit.morpherwallet.setLanguage(this.$i18n.locale);
			}
		},

		submitCode() {
			console.log('submitCode')
		},

		async clearStorage() {
			localStorage.removeItem(accessTokenName);
			localStorage.removeItem(refreshTokenName);
			localStorage.removeItem('emailOrUsernameStorage');
			localStorage.removeItem('morpher-role');
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('tokenStorage');
			localStorage.removeItem('eth_address');
		},

		async recaptchaError() {
			//this.loginProcessing = false;
		},
		async reloadPage(fortmaticNoAccountFound = false) {
			// remove all old cached items
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			if (fortmaticNoAccountFound) return;

			// reload the page if the previous reload was more than 1 hour ago (to avoid refresh loop)
			const reloadDate = localStorage.getItem('reloadDateLogin');
			const timestamp = Date.now();

			if (!reloadDate || Number(reloadDate) < timestamp - 3600000) {
				localStorage.setItem('reloadDateLogin', String(timestamp));
				window.location.reload();
			}
		},

		recaptchaComplete() {
		},
		async refreshWalletData() {
			const isLoggedIn = await this.morpherwallet.isLoggedIn();
			if (isLoggedIn && isLoggedIn.isLoggedIn == true) {
				this.emailAddress = isLoggedIn.walletEmail;

				this.email_2fa = isLoggedIn.twoFaRequired.email;
				this.authenticator_2fa = isLoggedIn.twoFaRequired.authenticator;
				this.recovery_types = isLoggedIn.recoveryMethods;
			}
		},


		async disconnectWallet(goToLogin = false) {
			caches.keys().then(cacheNames => {
				cacheNames.forEach(cacheName => {
					caches.delete(cacheName);
				});
			});

			this.clearStorage();

			if (this.morpherwallet && this.morpherwallet.logout) this.morpherwallet.logout();

			if (goToLogin) this.$router.push('/login').catch(() => { });

			window.location.reload();
		},
		generateWalletName(wallet_type: string) {
			let name = '';

			if (wallet_type === 'metamask') {
				name = 'Metamask';
			} else if (wallet_type === 'portis') {
				name = 'Portis';
			} else if (wallet_type === 'fortmatic') {
				name = 'Fortmatic';
			} else if (wallet_type === 'morpherwallet') {
				name = 'Morpher Wallet';
			}

			return name;
		},

		// Submits login request
		async formAction() { }
	}
});
</script>
<style scoped>
.button-img-primary {
	height: 28px;
	padding-right: 15px;
}

.button-img-alt {
	position: absolute;
	right: calc(50% + 70px);
}

.alt-button {
	padding-left: 46px;
}

.login-help {
	margin-top: -0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.settings-link {
	line-height: 1rem;
	cursor: default;
}

@media only screen and (max-width: 400px) {
	.button-img-alt {
		display: none;
	}

	.alt-button {
		padding-left: initial;
	}
}

.spaced {
	margin-right: 7px;
}

.formcontainer {
	display: flex;
	flex-direction: column;
	margin: auto auto;
	width: 360px;
}

.headerImage img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.authTitle {
	font-size: 28px;
	font-weight: 700;
	line-height: 38px;
	margin-top: 25px;
	color: #333333;
}

.headerImage {
	width: 100%;
}

.authText {
	font-weight: 400;
	color: #686475;
	margin-top: 12px;
	font-size: 16px;
}

.label {
	margin-top: 20px;
	margin-bottom: 4px;
	text-align: left;
	color: #686475;
	font-weight: 700;
	font-size: 12px;
}

.input {
	padding: 23px 16px;
	background-color: #fff;
	border-radius: 8px !important;
	border-color: #d0d5dd !important;
}

.input::placeholder {
	font-family: 'Manrope';
	line-height: 20px;
	font-size: 14px !important;
	font-weight: 400;
	color: #686475;
}



.loginLink a span {
	color: #039954;
	font-weight: 700;
}



.loginDivider {
	height: 1px;
	width: 100%;
	margin: 20px 0;
	border-top: 1px solid #eae9ed;
}

.loginDescription {
	max-width: 280px;
}

.loginHelp {
	height: calc(100vh - 500px);
}

@media only screen and (max-width: 768px) {
	.formcontainer {
		margin: 0 auto;
		height: 100%;
	}
}
</style>
