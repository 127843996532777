import { useAdminStore } from './modules/admin'
import { useContractStore } from './modules/contract'
import { useFundsStore } from './modules/funds'
import { useMarketsStore } from './modules/markets'
import { useModalsStore } from './modules/modals'
import { useNotificationsStore } from './modules/notifications'
import { usePortfoliosStore } from './modules/portfolios'
import { usePositionsStore } from './modules/positions'
import { useUserStore } from './modules/user'
import { useWatchlistStore } from './modules/watchlist'
import { useStatusStore } from './modules/status'

export const getStore = () => {

    const admin = useAdminStore();
    const contract = useContractStore();
    const funds = useFundsStore();
    const markets = useMarketsStore();
    const modals = useModalsStore();
    const notifications = useNotificationsStore();
    const portfolios = usePortfoliosStore();
    const positions = usePositionsStore()
    const user = useUserStore();
    const watchlist = useWatchlistStore();
    const status = useStatusStore();
    
    const store = { admin, contract, funds, markets, modals, notifications, portfolios, positions, user, watchlist, status }
    
    return store
}
