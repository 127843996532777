<template>
	<div v-show="!initialising">
		<div :class="{ 'is-hidden': kyc_in_progress }" class="has-text-centered">
			<button :disabled="kycDisabled"
				:class="{ 'button plain-primary-btn is-fullwidth mt-2': green, 'button plain-secondary-btn is-fullwidth mt-20': !green }"
				@click="toggleKYCCaptureModal(true)">
				<!-- <b-icon icon="fas fa-clipboard-list" pack="fas" size="is-small" /> -->
				<span>{{ required ? $t('COMPLETE_VERIFICATION_BUTTON') : $t('kyc.COMPLETE_VERIFICATION') }}</span>
			</button>

			<p v-if="kycError" class="is-size-14 has-text-danger has-text-weight-medium mt-2">
				{{ $t('KYC_INCOMPLETE') }}
			</p>
			<div v-else-if="kycDisabled" class="is-size-14 mt-10 has-text-centered">
				<b-icon pack="fas" icon="clock" size="is-small" style="color: #228cdb" />
				{{ $t('KYC_CHECK_AGAIN') }}
			</div>
			<!-- <div v-else class="is-size-12 is-weight-400 is-line-height-18 mt-10 has-text-left">
				<b-icon icon="fas fa-lock" pack="fas" size="is-small" />
				{{ $t('KYC_DATA_PROTECTED') }}
			</div> -->

			<div v-if="!required"
				class="is-flex is-align-items-self-start is-size-12 is-weight-400 is-line-height-18 mt-3 has-text-left">
				<img class="mr-1" src="@/assets/icons/new/info-empty.svg" />
				<span>
					{{ $t('TIER_MOGUL_VERIFICATION') }}
				</span>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { ERROR } from '@/store/mutation-types';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { useStatusStore } from '@/store/modules/status';

/* @group Components_Auth */
/*
  <h4> <b> KYC Mati Button </b> </h4>
  <br> Loads Mati to process user KYC.
*/
export default defineComponent({
	name: 'KYCButton',

	data() {
		return {
			initialising: true,
			loading: false,
			kyc_in_progress: false,
			kycError: '',
			kycDisabled: false
		};
	},
	props: {
		// Message shown when no market provided
		required: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
		green: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
	},
	computed: {
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useModalsStore, {
			showKYCCaptureModal: (state) => state.showKYCCaptureModal
		})

	},
	watch: {
		user(nv) {
			if (nv.allow_kyc !== true) {
				this.kycDisabled = true;
			}
		}
	},
	async created() {
		if (this.user?.allow_kyc !== true) {
			this.kycDisabled = true;

		}
	},
	async mounted() {
		this.initialising = false;
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR
		}),
		...mapActions(useUserStore, {
			startKYC: 'startKYC',
			getTokenUserData: 'getTokenUserData',
			loginWallet: 'loginWallet',
		}),
		...mapActions(useModalsStore, {
			toggleKYCCaptureModal: 'toggleKYCCaptureModal'
		})
	}
});
</script>

<style scoped>
.checkbox-col1 {
	margin-bottom: 0 !important;
}

.signup-checkbox.b-checkbox.checkbox {
	margin-top: 5px;
	margin-right: -22px;
}

.mati-addon {
	justify-content: center;
}

.is-mati-pholder1,
.is-mati-pholder2 {
	height: 43px;
}

.is-mati-pholder1 {
	width: 54px;
}

.is-mati-pholder1 img {
	width: 86%;
}

.is-mati-pholder2 {
	width: 150px;
}

.mati-button {
	cursor: not-allowed;
}
</style>
