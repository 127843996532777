<template>
	<div class="has-font-manrope">
		<!-- Disabled Market tooltip for admins -->
		<b-tooltip v-if="!isEmptyObject(market) && market.is_enabled !== true && user && user.role === 'admin'"
			style="position: absolute" type="is-danger" :label="market.is_paused ? 'Paused Market' : 'Disabled Market'"
			always></b-tooltip>

		<div class="card box chart-card">
			<div class="transition frame">
				<div class="chart-head-section">
					<img v-if="market.logo_image && market.logo_image == 'local'"
						:src="imageURL(`@/img/unique/markets/${market.symbol.toLowerCase()}.png`)"
						:class="{ 'market-logo-image': market.type !== 'crypto', 'market-logo-image-crypto': market.type === 'crypto' }" />
					<img v-else-if="market.logo_image" :src="'data:image/svg+xml;base64,' + market.logo_image"
						:class="{ 'market-logo-image': market.type !== 'crypto', 'market-logo-image-crypto': market.type === 'crypto' }" />
					<div>
						<div class="title">
							{{ market.symbol }}
						</div>
						<div v-if="market.type != 'sector'" class="market-name">
							{{ market.name }}
						</div>
					</div>
				</div>

				<div class="card-numbers">
					<p v-if="market.type != 'sector'" class="market-price">{{ roundFormatter(market.close) }}</p>
					<p :class="{
						'change-percent': true,
						'positive-change': parseFloat(market.change_percent) >= 0,
						'negative-change': parseFloat(market.change_percent) < 0
					}">
						<img class="change-img" v-if="parseFloat(market.change_percent) >= 0"
							src="@/assets/icons/market_up.svg" />
						<img class="change-img" v-else src="@/assets/icons/market_down.svg" />
						{{ parseFloat(Math.abs(market?.change_percent || 0).toString()).toFixed(2) }}%
					</p>
				</div>
			</div>
		</div>

	</div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';
import { useWatchlistStore } from '@/store/modules/watchlist';
import { ContentLoader } from 'vue-content-loader';
import ChartSparkLine from './ChartSparkLine.vue';
import Util from '@/services/shared';
import { MarketService } from '@/services/MarketService';
import WatchButton from '../WatchButton.vue';
import { UserService } from '@/services/UserService';
import { useMarketsStore } from '@/store/modules/markets';

/* @group Components_Chart */
/*
  <h4> <b>Chart Card Component</b> </h4>
  <br> - Receives market as prop
  <br> - Gradient change based on market change_percent value
  <br> - Subscribe on mounted hook
  <br> - Unsubscribe on destroyed hook
  <br>
  <br> Contains:
  <br> - Sparkline png for showing price 24h range
  <br>
  <br> Example: https://sketch.cloud/s/epJ2z/EL4wjJq
*/
export default defineComponent({
	name: 'ChartCardOnboarding',
	components: {
		ContentLoader,
		ChartSparkLine,
		WatchButton
	},
	props: {
		// Market object from redis collection
		market: {
			type: Object as PropType<any>,
			required: true,
			default: () => {
				return {};
			}
		},
		segmentName: {
			type: String,
			required: false,
			default: () => ''
		},

	},
	data() {
		return {
			showAll: false,
			numOfCards: 0,
			scrollCardsWidth: 650,
			Util,
			subscription: null as any
		};
	},
	computed: {
		...mapState(useUserStore, {
			user: (state) => state.data,
			impersonating: (state) => state.impersonating,
		}),
		...mapState(useWatchlistStore, {
			watchlist: (state) => state.data,
			watchlistLoading: (state) => state.loading,
		}),

	},
	watch: {
		async market(nv) {
			this.unsubscribe();
			this.subscribe();
		}
	},
	mounted() {
		this.subscribe();
	},
	unmounted() {
		this.unsubscribe();
	},
	methods: {
		...mapActions(useWatchlistStore, {
			handleWatchlist: 'handleWatchlist',
		}),
		...mapActions(useMarketsStore, {
			updateMarket: 'updateMarket',
		}),

		async subscribe() {
			if (this.market) {
				MarketService.subscribeToMarket(this.market, 'ChartCardOnboarding', data => {
					try {
						if (!data?.data?.updateMarket.newValue) {
							this.market.change_percent = data?.data?.updateMarket.change_percent;
							this.market.change = data?.data?.updateMarket.change;
							this.market.close = data?.data?.updateMarket.close;
							this.updateMarket({
								market: this.market,
								group: 'data'
							});
						}
					} catch (err) { }
				});
				this.subscription = this.market;
			}
		},
		async unsubscribe() {
			if (this.subscription) {
				await MarketService.unsubscribeFromMarket(this.subscription, 'ChartCardOnboarding');
				//this.subscription = null;
			}
		},
		discoveryClickEvent(market: any) {
			if (market.discovery_source) {
				UserService.sendAnalytics({
					event: 'discovery_click',
					data: {
						market_id: Util.formatMarketId(market.type, market.symbol),
						rec_source: market.discovery_source
					}
				});
			}
		},
		generateSymbolBackground(type: string) {
			let color = '';

			if (type === 'stock') {
				color = '#56D5AD';
			} else if (type === 'crypto') {
				color = '#F0A370';
			} else if (type === 'commodity') {
				color = '#F9DE6E';
			} else if (type === 'forex') {
				color = '#996CFC';
			} else if (type === 'index') {
				color = '#0A92FD';
			}

			return color;
		}
	}
});
</script>

<style scoped>
.market-logo-image {
	box-sizing: border-box;
	border-radius: 8px;
	/* border: 1px solid #eae9ed; */
	/* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(16, 24, 40, 0.06); */
	width: 36px;
	height: 36px;
	margin-right: 8px;
}

.market-logo-image-crypto {
	box-sizing: border-box;
	border: 0;
	width: 36px;
	height: 36px;
	margin-right: 8px;
}

.chart-head-section {
	display: flex;
	align-items: top;
	gap: 1px;
	align-self: stretch;
}

.chart-card {
	cursor: pointer;
	width: 168px !important;
	height: 148px !important;

	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	background: #ffffff;

	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04);

	border-radius: 8px;
	text-align: left;
	padding: 1.25rem 1rem;
	/* padding-bottom: 25px; */
	overflow: hidden;
	color: #333333;
}

.chart-card:hover {
	background-color: #f5f5f5;
	box-shadow: 0px 0px 1px 1px rgba(51, 51, 51, 0.05);
}

.chart-card .title {
	font-size: 18px !important;
	font-weight: 700;
	margin: 0;
	display: inline-block;
}

.chart-card .load {
	background: transparent !important;
	padding: 0 !important;
	border: none !important;
}

.chart-card .sparkline {
	margin-top: -7px;
	max-width: 75px !important;
	max-height: 38px !important;
}

.chart-card .market-name {
	/* margin-top: 2px; */
	text-align: left !important;
	font-weight: 400;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	overflow: hidden;
	font-size: 12px;
	line-height: 16px;
	color: #686475;
}

.chart-card .card-numbers {
	margin-top: auto;
}

.chart-card .frame {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: flex-start;
}

.chart-card .change-percent {
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	display: flex;
	padding: 2px 8px;
	border-radius: 12px;


}

.chart-card .change-img {
	margin-right: 4px;
}

.chart-card .change-percent.positive-change {
	color: #039954;
	background: var(--surface-surface-pastel-green, #dffbe9);
}

.chart-card .change-percent.negative-change {
	color: #d70228;
	background: var(--surface-surface-pastel-red, #FFF0F3);
}

.chart-card .watch {
	position: absolute;
	right: 15px;
	top: 15px;
	width: 20px;
	height: 20px;
}

.chart-card .watch button {
	width: 100%;
	height: 100%;
}

.chart-card .line {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 10px;
	background: #ccc;
}

.chart-card .market-price {
	font-size: 18px;
	font-weight: 700;
}
</style>
