<template>
	<div>
		<b-modal :modelValue="moreRewardsModal" has-modal-card @close="moreRewardsModal = false">
			<div class="modal-card terminal">
				<img class="claim-image" src="@/assets/img/rewards/more_rewards_modal.svg" />

				<div class="info-box-header has-text-centered">
					{{ $t('MORE_REWARDS_HEADER') }}
				</div>

				<div class="info-box-text has-text-centered">
					{{ $t('MORE_REWARDS_DETAILS', { amount: formatUSDEstimate(100000, mphMarketClose, 50) }) }}
				</div>

				<div class="link-input-section">
					<input v-model="shareLinkShort" class="link-input" type="url" readonly />
					<div class="link-copy" @click="copyTextToClipboard">
						<img src="@/assets/img/rewards/copy.svg" />
					</div>
				</div>
				<div class="share-button-section">
					<button class="button share-button x" @click="openSocialMediaWindow('x_ad')">
						<img class="social-image" src="@/assets/img/rewards/social-x.svg" />
					</button>
					<button class="button share-button facebook" @click="openSocialMediaWindow('facebook')">
						<img class="social-image" src="@/assets/img/rewards/social-fb.svg" />
					</button>
					<button class="button share-button telegram" @click="openSocialMediaWindow('telegram')">
						<img class="social-image" src="@/assets/img/rewards/social-telegram.svg" />
					</button>
					<button class="button share-button whatsapp" @click="openSocialMediaWindow('whatsapp')">
						<img class="social-image" src="@/assets/img/rewards/social-whatsapp.svg" />
					</button>
				</div>
			</div>
		</b-modal>

		<b-modal :modelValue="showRewardModal" has-modal-card @close="showRewardModal = false">
			<div class="modal-card terminal">
				<img class="claim-image" src="@/assets/img/rewards/claim_reward_modal.svg" />

				<div class="is-vcentered has-text-centered mt-4 mb-4">
					<p class="info-amount">
						<span class="pl-10">${{ animatedNumber }}</span>
					</p>

					<p class="info-amount-mph">
						<span class="pl-10"> {{ $t('AIRDROP_REWARDS_WON') }} {{ airdropClaimAmount }} MPH</span>
					</p>

					<p class="info-claim-text mt-4 mb-4">
						{{ $t('AIRDROP_REWARDS_DETAILS') }}
					</p>

					<!-- Redeem Button -->
					<div class="has-text-centered ml-20px mr-20">
						<button :class="{ 'is-loading': claimInProgress }" :disabled="impersonating"
							class="button plain-primary-btn is-fullwidth mb-2" @click="claim()">
							{{ airdropData.available_claims > 0 ? $t('AIRDROP_REWARDS_CLAIM_ANOTHER') : $t('DONE') }}
						</button>
					</div>

					<p v-if="airdropData.available_claims > 0" class="info-remaining-text">
						{{ airdropData.available_claims }} {{ $t('AIRDROP_REWARDS_REMAINING') }}
					</p>
				</div>
			</div>
		</b-modal>

		<!-- Airdrop Status when KYC Completed -->
		<div v-show="!claim_confirm" class="claim_box" :class="{ 'terminal-content claim-box-modal': modal }">
			<!-- Variable title if first claim -->
			<div class="info-box-header info-box-header-expanded">
				{{ $t('AIRDROP_REWARDS_HEADER') }}
			</div>

			<div>
				<div class="is-vcentered has-text-centered mt-20">
					<div v-if="!airdropData || !airdropData.approved_amount" class="loader mini-loader" />
					<p v-else class="info-amount">
						<img class="mph_token_icon" src="@/assets/img/rewards/mph_token_icon.svg" />
						<span class="pl-10">{{ roundFormatter(airdropData.approved_amount -
							airdropData.tokens_available) }}</span>
					</p>
					<p class="info-amount-text" v-text="$t('ad_stat_earned')" />
				</div>

				<div class="is-vcentered has-text-centered mt-20 mb-20">
					<div v-if="!airdropData || !airdropData.approved_amount" class="loader mini-loader" />
					<p v-else class="info-amount">
						<img class="mph_token_icon" src="@/assets/img/rewards/rewards_icon.svg" />
						<span class="pl-10">{{ Math.round(airdropData.available_claims + (airdropData.available_claims
							== 0 && airdropData.tokens_available > 0 ? 1 : 0)) }}</span>
					</p>
					<p class="info-amount-text" v-text="$t('AIRDROP_REWARDS_CLAIM')" />
				</div>

				<!-- Redeem Button -->
				<div v-if="airdropData && airdropData.approved_amount && (airdropData.available_claims || airdropData.tokens_available > 0)"
					class="has-text-centered ml-20px mr-20 mb-20">
					<button :class="{ 'is-loading': claimInProgress }"
						:disabled="(impersonating || airdropData.available_claims < 1 || airdropData.claim_limit_reached == true)"
						class="button plain-primary-btn is-fullwidth" @click="claim()">
						{{ $t('REDEEM_TOKENS') }}
					</button>
					<p class=" mb-10 deposit-link"
						v-if="(user?.access_level?.tier || 0) < 3 && airdropData.claim_limit_reached && airdropData.available_claims > 0"
						@click="$router.push('/funds/deposit').catch(() => { })">
						{{ $t('DEPOSIT_TO_CLAIM_REWARD') }}
					</p>
					<p v-else class=" mb-10"></p>
				</div>

				<div class="is-flex claim-count">

					<div v-if="(user?.access_level?.tier || 0) == 1">
						{{ $t('TIER_NOVICE') }} {{ $t('ACCOUNT') }}
					</div>
					<div v-else-if="(user?.access_level?.tier || 0) < 4">
						{{ $t('TIER_INVESTOR') }} {{ $t('ACCOUNT') }}
					</div>
					<div v-else>
						{{ $t('TIER_MOGUL') }} {{ $t('ACCOUNT') }}
					</div>

					<div style="margin-left: auto;">
						{{ airdropData.claim_count }} {{ (user?.access_level?.tier || 0) == 1 ? '/10' :
							(user?.access_level?.tier || 0) < 4 ? '/50' : '' }} {{ $t('CLAIMED').toLowerCase() }} </div>
					</div>


				</div>
				<!-- <div v-else class="is-vcentered has-text-centered mt-20">
				<div>
					<img class="mph_token_icon" src="@/assets/img/rewards/rewards_disabled.svg" />
				</div>
				<p class="ml-20px mr-20 mb-20 is-size-14" v-html="$t('NO_REWARDS_VERIFY')"></p>
			</div> -->
			</div>

			<!-- Claim completed confirmation (only displayed on first claim) -->
			<!-- <div v-show="claim_confirm && modal" class="terminal-content claim-box-modal">
			<h1 class="title title-font has-text-centered is-size-4">{{ $t('airdrop.ACCOUNT_FOUNDED') }}</h1>
			<div class="mt-30" />
			<WalletCard
				:wallet-type="walletType"
				:eth-address="eth_address"
				:balance="Number(airdropData.available_claims)"
				animate-numbers
			/>
			<div class="button-max-width">
				<button class="button plain-primary-btn is-fullwidth mt-30 mb-10" @click="startTrading()">
					{{ $t('START_TRADING') }}
				</button>
			</div>
		</div> -->
			<!-- Confetti Canvas -->
			<canvas id="my-canvas" />
		</div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { useMarketsStore } from '@/store/modules/markets';
import { useModalsStore } from '@/store/modules/modals';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { i18n } from '@/plugins/i18n';
import { ERROR } from '@/store/mutation-types';
import { morpherAirdropAbi } from '@/contracts//abis';
import { processOverlay, closeFortmaticOverlay } from '../helpers/fortmatic';
import WalletCard from '@/components/WalletCard.vue';
import { copyToClipboard, openSocialMediaWindow } from '../helpers/utils';
import { gsap } from 'gsap';
import * as confetti from 'canvas-confetti';
import EventBus from '@/store/event-bus';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { getPublicClient } from '@/helpers/viem';

export default defineComponent({
	name: 'AirdropClaim',
	components: { WalletCard },
	props: {
		modal: {
			type: Boolean,
			default: false,
			required: false
		},
		airdropData: {
			type: Object as PropType<any>,
			default: () => {
				return {};
			},
			required: false
		}
	},
	data() {
		return {
			title: '',
			issueText: '',
			kycIssue: false,
			supportSection: false,
			showGuideLinks: false,
			duplicateEmail: false,
			disclaimerApprove: false,
			notCompleteAD: false,
			notCompleteOther: false,
			amountNow: '',
			priceNow: '',
			priceLater: '',
			tokensEarned: '',
			referralAmount: '',
			referralPrice: '',
			shareLink: '',
			shareLinkShort: '',
			kyc_in_progress: false,
			matiClientId: import.meta.env.VITE_MATI_CLIENT,
			emailResent: false,
			chromeVersion: 999,
			eth_transfer_complete: false,
			eth_balance_check: null as null | NodeJS.Timeout | number,
			eth_balance_count: 0,
			claimInProgress: false,
			virtual_eth_not_transfer: false,
			claim_confirm: false,
			first_claim: false,
			showRewardModal: false,
			moreRewardsModal: false,
			airdropClaimAmount: 0,
			displayedNumber: 0,
			confettiFired: false,
			initialising: false
		};
	},
	computed: {
		...mapState(useUserStore, {
			activePortfolio: (state) => state.activePortfolio,
			user: (state) => state.data,
			impersonating: (state) => state.impersonating,
		}),
		...mapState(useContractStore, {
			eth_address: (state) => state.eth_address,
			walletType: (state) => state.walletType,
			walletClient: (state) => state.walletClient as any,
			isLoggedInWallet: (state) => state.isLoggedInWallet,
		}),
		...mapState(useMarketsStore, {
			mph_market: (state) => state.mph_market,
		}),

		mphMarketClose() {
			if (this.mph_market && this.mph_market.close) {
				return Number(this.mph_market.close);
			} else {
				return 0;
			}
		},
		animatedNumber() {
			if (this.mph_market && this.mph_market.close) {
				return this.usdFormatter(this.displayedNumber * this.mph_market.close);
			} else {
				return 0;
			}
		}
	},
	watch: {
		airdropClaimAmount(nv) {
			if (nv) {
				gsap.to(this.$data, { duration: 2.5, delay: 0.25, ease: 'power3.out', displayedNumber: nv });
			}
		},
		airdropData(nv) {
			if (nv) {
				if (this.airdropData && this.airdropData.referral_id) {
					this.shareLinkShort = import.meta.env.VITE_MORPHER_LANDING_PAGE + '/invite/' + this.airdropData.referral_id;
					this.shareLink = 'https://' + import.meta.env.VITE_MORPHER_LANDING_PAGE + '/invite/' + this.airdropData.referral_id;
				}
			}
		}
	},

	async created() {
		// extrach the chrome browser version from the useragent (if this is not chrome then it will default to 999)
		const chromeVersion = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
		this.chromeVersion = chromeVersion ? parseInt(chromeVersion[2], 10) : 999;
	},
	beforeUnmount() {
		EventBus.$off('claimReward', this.claim);
	},

	async mounted() {
		if (Number(this.user?.tokens_claimed) > 0) this.first_claim = false;
		else this.first_claim = true;

		this.initialising = false;
		this.$forceUpdate();

		if (this.airdropData && this.airdropData.referral_id) {
			this.shareLinkShort = import.meta.env.VITE_MORPHER_LANDING_PAGE + '/invite/' + this.airdropData.referral_id;
			this.shareLink = 'https://' + import.meta.env.VITE_MORPHER_LANDING_PAGE + '/invite/' + this.airdropData.referral_id;
		}
		this.airdropClaimAmount = 0;

		EventBus.$off('claimReward', this.claim);
		EventBus.$on('claimReward', this.claim);
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR
		}),
		...mapActions(useUserStore, {
			claimAirdrop: 'claimAirdrop',
			getAirdropStatus: 'getAirdropStatus',
		}),
		...mapActions(useContractStore, {
			setOverlayState: 'setOverlayState',
			startWallet: 'startWallet',
		}),
		...mapActions(useModalsStore, {
			toggleClaimAirdropModal: 'toggleClaimAirdropModal',
		}),

		reloadAirdropStatus() {
			this.$emit('reloadAirdropStatus');
		},
		async checkEthBalance() {
			try {
				if (this.eth_balance_check) clearTimeout(this.eth_balance_check);

				this.walletClient?.getAddresses().then(async (accounts: any) => {

					const publicClient = getPublicClient(this.user?.blockchain_info || undefined)

					publicClient.getBalance({ address: accounts[0] }).then(async (balance: BigInt) => {
						if (Number(balance) > 10000000000000) {
							this.eth_transfer_complete = true;
							this.claim();
						} else {
							this.eth_balance_count += 1;
							if (this.eth_balance_count >= 30) {
								this.claimInProgress = false;
								this.virtual_eth_not_transfer = true;
								this.logSentryError('Virtual ETH not transfetrred');
							} else {
								this.eth_balance_check = setTimeout(this.checkEthBalance, 1000);
							}
						}
					});
				});
			} catch (err: any) {
				this.claimInProgress = false;
				this.logSentryError('Error checking eth balance:' + err.message);
			}
		},
		async startTrading() {
			this.toggleClaimAirdropModal(false);
		},
		copyTextToClipboard() {
			// Copy the link to the clipboard
			copyToClipboard(this.shareLinkShort, this);
		},
		openSocialMediaWindow(network: string) {
			// Open the selected social media page and share the link
			const message = i18n.t('ad_stat_h1') + this.formatUSDEstimate(100000, this.mphMarketClose, 50) + i18n.t('ad_stat_share1a');
			openSocialMediaWindow(network, this.shareLink, message);
		},
		async claim() {
			try {

				this.airdropClaimAmount = 0;

				if (this.airdropData.tokens_available == 0 && this.airdropData.available_claims == 0) {
					this.claimInProgress = false;
					this.showRewardModal = false;
					this.moreRewardsModal = true;
					return;
				}
				this.claimInProgress = true;

				this.claimInProgress = true;

				const claimResult = await this.claimAirdrop({
					user_id: this.user?.id
				});

				this.reloadAirdropStatus();
				const data = await this.getAirdropStatus(true);

				if (Number(data.last_claim_amount) / Math.pow(10, 18) == this.airdropClaimAmount) {
					this.airdropClaimAmount = 0;
					window.setTimeout(() => {
						this.airdropClaimAmount = Number(data.last_claim_amount) / Math.pow(10, 18);
					}, 2000)
				} else {
					this.airdropClaimAmount = Number(data.last_claim_amount) / Math.pow(10, 18);
				}

				EventBus.$emit('loadAirdropData', data);
				this.claimInProgress = false;
				this.showRewardModal = true;
				if (!this.confettiFired) {
					// Only fire confetti once - prevent confetti armageddon if user claiming multiple times
					this.fireConfetti();
					this.confettiFired = true;
				}

				this.claimInProgress = false;
			} catch (err: any) {
				this.claimInProgress = false;
				// If there is a logon error then log it to sentry
				this.logSentryError('Error claiming airdrop:' + err.message);
			}
		},
		async fire(particleRatio: number, opts: any) {
			var count = 250;
			var defaults = {};

			var canvas = document.getElementById('my-canvas') as HTMLCanvasElement;
			if (canvas) {
				canvas.style.zIndex = "10000";
				confetti.create(canvas, { resize: true, useWorker: true })(
					Object.assign({}, defaults, opts, {
						particleCount: Math.floor(count * particleRatio)
					})
				);
			}
		},
		async fireConfetti() {
			this.fire(0.25, {
				spread: 26,
				startVelocity: 55
			});
			this.fire(0.2, {
				spread: 60
			});
			this.fire(0.35, {
				spread: 100,
				decay: 0.91
			});
			this.fire(0.1, {
				spread: 120,
				startVelocity: 25,
				decay: 0.92
			});
			this.fire(0.1, {
				spread: 120,
				startVelocity: 45
			});
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.claim-box {
	margin: 0 auto;
}

.claim-box-modal {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.mb-remove {
	margin-bottom: -30px;
}

.info-box-header {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #333333;
	padding: 15px;
}

.info-box-header-expanded {
	border-bottom: 1px solid #eaecf0;
}

.info-amount {
	display: flex;
	font-weight: 700;
	font-size: 28px;
	justify-content: center;
	align-items: center;
	line-height: 36px;
}

.info-amount-mph {
	display: flex;
	font-weight: 400;
	font-size: 16px;
	justify-content: center;
	align-items: center;
	color: #039954;
}

.info-claim-text {
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 400;
	font-size: 14px;
	color: #333333;
}

.info-remaining-text {
	font-size: 12px;
	color: #333333;
	line-height: 18px;
}

.info-amount-text {
	font-weight: 400;
	font-size: 14px;
	color: #333333;
}

.rotate-image {
	transform: scaleY(-1);
}

.claim-image {
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 20px;
}

.info-box-text {
	padding-left: 20px;
	padding-right: 20px;
	font-size: 14px;
}

.link-input-section {
	border: 1px solid #d9dce5;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	height: 48px;
	margin: 20px;
	margin-bottom: 0.75rem;
	padding-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.link-copy {
	display: flex;
	align-items: center;
	padding-right: 1rem;
	cursor: pointer;
}

.link-input {
	border: 0;
	color: #686475;
	background-color: transparent;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	height: 38px;
	width: 85%;
}

.link-input:focus {
	outline: none;
}

.share-button {
	border-radius: 8px;
	width: 64px;
	height: 48px;
	border-color: transparent !important;
	transition: all 0.25s;
}

.share-button-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.75rem;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
}

.share-button.facebook {
	background: #4a66ad;
}

.share-button.x {
	background: #333;
}

.share-button.telegram {
	background: #0088cc;
}

.share-button.whatsapp {
	background: #65d072;
}

.button.share-button:hover {
	filter: brightness(85%);
	transition: all 0.25s;
}

.mini-loader {
	margin: 0.75rem auto;
	width: 28px;
	height: 28px;
	border-width: 3px;
	border-color: transparent transparent #e5e4e7 #e5e4e7;
}

#my-canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.claim-count {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
}

.deposit-link {
	color: #1A6DF0;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;

}
</style>
