<template>
	<div class="field">
		<!-- <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div> -->
		<div class="control is-expanded">
			<button class="button big-outlined-button transition-faster button-spacing loginButton"
				@click="loginMetamask" :class="{ 'is-loading': loading.metamask }" data-cy="appleButton"
				:disabled="!isMetamaskSupported">
				<span class="icon img" v-if="!loading.metamask">
					<img v-if="!loading.metamask" src="@/assets/wallet/metamask_logo.svg" class="buttonIcon"
						alt="Metamask Logo" />
				</span>
				<span class="ml-5px" v-if="showText">{{ $t('auth.LOG_IN_METAMASK') }} </span>
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EventBus from '@/store/event-bus';
import MetaMaskOnboarding from '@metamask/onboarding';
import * as blockies from 'ethereum-blockies-png';
import { accessTokenName, refreshTokenName, getUserID } from '@/helpers/utils';
import { useContractStore } from '@/store/modules/contract';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';
import deviceType from '@/helpers/deviceType';
import type { WalletClient } from 'viem';
import { getWalletClient } from '@/helpers/viem';

export default defineComponent({
	name: 'LoginMetamask',
	props: {
		isMetamaskSupported: {
			type: Boolean,
			required: false,
			default: false
		},
		showText: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			walletClientlogin: undefined as any | undefined,
			walletError: '',
			wallet_type: '',
			showMetamaskHelp: false,
			currentPage: '',
			blockie: '',
			eth_address: '',
			loading: {
				metamask: false
			}
		};
	},
	methods: {
		...mapActions(useContractStore, {
			setWalletClient: 'setWalletClient',
		}),
		...mapActions(useUserStore, {
			loginWallet: 'loginWallet',
		}),
		async clearStorage() {
			localStorage.removeItem(accessTokenName);
			localStorage.removeItem(refreshTokenName);
			localStorage.removeItem('emailOrUsernameStorage');
			localStorage.removeItem('morpher-role');
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('tokenStorage');
			localStorage.removeItem('eth_address');
		},
		async reloadPage() {
			// remove all old cached items
			caches.keys().then((cacheNames) => {
				cacheNames.forEach((cacheName) => {
					caches.delete(cacheName);
				});
			});

			// reload the page if the previous reload was more than 1 hour ago (to avoid refresh loop)
			const reloadDate = localStorage.getItem('reloadDateLogin');
			const timestamp = Date.now();

			if (!reloadDate || Number(reloadDate) < timestamp - 3600000) {
				localStorage.setItem('reloadDateLogin', String(timestamp));
				window.location.reload();
			}
		},
		executeTimeout() {
			this.$buefy.toast.open({
				duration: 10000,
				message: this.$t('auth.METAMAS_SIGNATURE_ERROR'),
				type: 'is-warning'
			});

		},
		async loginMetamask() {
			this.$emit('checkMetamask');
			this.walletError = '';
			this.wallet_type = 'metamask';
			try {
				EventBus.$off('signatureTimeout', this.executeTimeout);
				EventBus.$on('signatureTimeout', this.executeTimeout);

				const onboarding = new MetaMaskOnboarding();

				if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
					if (deviceType.isAnyMobile()) {
						if (import.meta.env.VITE_METAMASK_DEEP_LINK) window.location.href = import.meta.env.VITE_METAMASK_DEEP_LINK;
						return;
					}

					onboarding.startOnboarding();

					this.showMetamaskHelp = true;
				} else {
					this.loading.metamask = true;

					let eth_address = '';

					// Get the users Ethereum address
					if (window.ethereum) {
						window.ethereum.autoRefreshOnNetworkChange = false;
						const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });

						const walletClient = getWalletClient(window.ethereum)
						this.walletClientlogin = walletClient;
						this.setWalletClient(walletClient);

						eth_address = addresses[0] ? addresses[0].toLowerCase() : '';

						if (eth_address) {
							this.eth_address = eth_address;
							this.blockie = blockies.createDataURL({
								seed: eth_address.toLowerCase()
							});
						}
					}

					const user_id = await getUserID(eth_address, 'metamask');

					this.clearStorage();
					localStorage.setItem('walletType', 'metamask');

					const result = await this.loginWallet({
						variables: {
							eth_address,
							wallet_type: 'metamask',
							user_id,
							hash: ''
						},
						isTokenLogin: false,
						email: '',
						walletClient: this.walletClientlogin
					});

					if (result && result.error) {
						let accountNotFound = false;

						if (
							result.error.graphQLErrors &&
							result.error.graphQLErrors[0] &&
							result.error.graphQLErrors[0].message === 'LOGIN_WRONG_CREDENTIALS'
						) {
							accountNotFound = true;
						}

						if (accountNotFound) {
							this.currentPage = 'new_wallet';
							return;
						}

						setTimeout(() => {
							this.reloadPage();
						}, 2000);
					}

					// Display the email address updated notification if the email address was updated
					if (result && result.old_address) {
						this.$buefy.dialog.alert({
							title: this.$t('auth.EMAIL_UPDATED'),
							message: this.$t('auth.EMAIL_UPDATED_DESCRIPTION', {
								newEmail: result.email,
								oldEmail: result.old_address
							}),
							confirmText: this.$t('OK')
						});
					}

					// if the user is not registered then register the user
					if (!result) {
						this.loading.metamask = false;
					}
					// store the email for defaulting next tme the user logs in
					if (result) localStorage.setItem('emailOrUsernameStorage', result.email);
					this.loading.metamask = false;
				}
			} catch (err: any) {
				console.log(err);
				this.walletError = err.message;
				this.loading.metamask = false;
			}
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.field {
	width: 100%;
}

.buttonIcon {
	height: 24px !important;
}
</style>
