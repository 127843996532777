import { i18n } from '../plugins/i18n';

const errorFilter = [
	'User denied account access',
	'User denied transaction',
	'NavigationDuplicated',
	'ACCESS_DENIED',
	'MARKET_NOT_EXIST',
	'Missing credentials in config',
	'Response not successful: Received status code 400',
	'Network error: Failed to fetch',
	'Fortmatic: User denied transaction',
	'ORDER_CREATION_COOLDOWN',
	'400 (Bad Request)',
	'USER_BLOCKED',
	'USER_NOT_FOUND'
];

/*
 * used to filter out known errors so that they are not logged to sentry
 */
export const checkErrorFilter = (error: string): boolean => {
	const filterErr = errorFilter.find(filter => error.toLowerCase().includes(filter.toLowerCase()));
	if (filterErr) {
		return false;
	} else {
		return true;
	}
};

export const sentryErrorMessage = (error: any, sentryerror:string = '', default_message:string ='ERROR_DEFAULT' ) => {
	let message = '';
	// if this is a graphql error then get the graphql error message
	if (error.graphQLErrors && error.graphQLErrors.length) {
		// if this is not a standard error then lofg the error to sentry and diplsy the standard 'GRAPHQL_ERROR' message
		if (error.graphQLErrors[0].message && !error.graphQLErrors[0].message.includes('_')) {
			sentryerror = error.graphQLErrors[0].message;
			message = 'GRAPHQL_ERROR';
		} else {
			message = error.graphQLErrors[0].message;
		}
		// get the redis error message
	} else if (error.message && error.message.includes('REDIS Error:')) {
		sentryerror = error.message;
		message = 'REDIS_ERROR';
		// if this is not a standard error then lofg the error to sentry and diplsy the standard 'ERROR_DEFAULT' message
	} else if (error.message && !error.message.includes('_')) {
		sentryerror = error.message;
		message = default_message;
	} else {
		sentryerror = error.message;
		message = error.message;
	}
	if (sentryerror) {
		if (!checkErrorFilter(sentryerror)) {
			sentryerror = '';
		}
	}
	message = i18n.t(message).toString();
	return message;
}