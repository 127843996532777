<template>
	<section class="has-font-manrope">
		<b-modal :modelValue="showWalletMigrationModal" has-modal-card @close="closeModal()">
			<div class="modal-card terminal">

				<div class="deposit-modal-spacing">
					<div class="header-bar-global">
						{{ $t('migration.MIGRATE_WALLET_HEADER') }}
					</div>
					<div class="has-text-centered mt-4">
						<img style="max-width: 228px" src="@/assets/img/migration/mobile-app-screens.png"
							:alt="$t('migration.MIGRATE_WALLET_HEADER')" />
					</div>

					<p class="has-text-left is-size-14 is-line-height-20 mt-4">
						{{ $t('migration.MIGRATE_WALLET_LONGTIME', { wallet_name: firstLetterUppercase(walletType) }) }}
					</p>

					<p class="has-text-left has-text-weight-bold mt-4">
						{{ $t('migration.MIGRATE_WALLET_SUBHEAD') }}
					</p>
					<div class="migration-bullet-points">
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.UPGRADE_REASON2') }}</p>
						</div>
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT2') }}</p>
						</div>
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT3') }}</p>
						</div>
					</div>

					<button class="button plain-primary-btn large is-fullwidth mt-2" @click="startMigration">
						{{ $t('migration.START_MIGRATION') }}
					</button>
				</div>

				<!-- Just wallet migration design -->
				<!-- <div class="deposit-modal-spacing">
					<div class="header-bar-global">
						{{ $t('migration.MIGRATE_WALLET_HEADER') }}
					</div>
					<div class="has-text-centered mt-30">
						<img src="@/assets/img/migration/wallet-migration.svg" :alt="$t('migration.MIGRATE_WALLET_HEADER')" />
					</div>

					<div class="mt-10 has-text-left has-text-darkgrey has-text-weight-bold">
						<p>
							{{ $t('migration.MIGRATE_WALLET_SUBHEAD') }}
						</p>

					</div>
					<div class="migration-bullet-points">
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT1') }}</p>
						</div>
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT2') }}</p>
						</div>
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT3') }}</p>
						</div>
					</div>

					<button class="button flat-primary-btn xlarge is-fullwidth mt-20" @click="startMigration">
						{{ $t('migration.START_MIGRATION') }}
					</button>

					<div class="mt-15">
						<a class="is-size-6 has-text-weight-medium transition-faster" @click="closeModal">{{
							$t('migration.BTN_MIGRATE_LATER')
						}}</a>
					</div>
				</div> -->
			</div>
		</b-modal>
		<canvas id="my-canvas" class="custom-canvas"></canvas>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'WalletMigrationModal',
	data() {
		return {
			username: '',
			usernameTimeout: null as null | NodeJS.Timeout | number,
			usernameValidation: true,
			usernameMessage: '',
			email: '',
			loading: false,
			warning: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showWalletMigrationModal: (state) => state.showWalletMigrationModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
			morpherwallet: (state) => state.morpherwallet,
		}),

	},
	watch: {
		user() {
			this.initialize();
		},
		username() {
			if (this.usernameTimeout) clearTimeout(this.usernameTimeout);
			this.usernameTimeout = setTimeout(() => {
				this.validateUsername();
			}, 1000);
		},
		showWalletMigrationModal(nv) {
			this.initialize();
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleWalletMigrationModal: 'toggleWalletMigrationModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),


		validateUsername() {

		},
		async closeModal() {
			localStorage.setItem('migrate_notification', Date.now().toString());

			this.toggleWalletMigrationModal(false);
		},

		initialize() {
			// Hide modal if user already on Migration page (like if sent from email or mobile app)
			if (this.$route.path.toLowerCase().indexOf('/migrate') !== -1) {
				this.toggleWalletMigrationModal(false);
			}
		},
		async startMigration() {
			localStorage.setItem('migrate_notification', Date.now().toString());
			this.toggleWalletMigrationModal(false);
			this.$router.push('/migrate').catch(() => { });
		}
	}
});
</script>

<style scoped>
.modal-card {
	min-width: 400px;
}

.custom-canvas {
	display: none;
}

@media screen and (max-width: 768px) {
	.modal-card {
		min-width: inherit;
	}
}

.modal_intro {
	font-size: 16px;
	font-weight: normal;
	color: #666666;
}

.modal_icon {
	position: absolute;
}

.modal_header {
	font-size: 16px;
	font-weight: 500;
	color: #333333;
	padding-left: 35px;
}

.modal_body {
	font-size: 14px;
	font-weight: 400;
	color: #666666;
	padding-left: 35px;
}

.modal_section {
	margin-top: 15px;
}

.is-64 {
	height: 64px;
}

.payment_provider_section {
	border: 1px solid #eae9ed;
	border-radius: 8px;
	padding: 1rem;
	display: flex;
	gap: 0.5rem;
}

.provider_text {
	display: flex;
	flex-direction: column;
	gap: 5px;
	color: #666666;
	text-align: left;
}

.transaction_icon {
	height: 20px;
	min-width: 20px;
}

.provider_header {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	line-height: 1;
}

.warning-confirm {
	background: #f7f5fc;
	border: 1px solid #eae9ed;
	border-radius: 8px;
	line-height: 20px;
	padding: 16px;
}

.migration-bullet-points {
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-top: 16px;
	align-self: stretch;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */

	letter-spacing: -0.01em;

	/* Dark Gray */

	color: #333333;
}

.bullet-point {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.bullet-point img {
	margin-right: 10px;
}
</style>
