<template>
	<section>
		<b-modal :modelValue="showKYCStatusModal" has-modal-card @close="closeModal()">
			<form @submit.prevent="closeModal()">
				<div class="modal-card terminal">
					<div class="deposit-modal-spacing has-text-left">
						<div v-if="kyc_status == 'verified'">
							<div class="is-flex is-align-items-center is-justify-content-center mt-30">
								<img class="big-icon" src="@/assets/kyc/success-icon.svg" alt="success checkmark" />
							</div>
							<div class="has-text-centered mt-10">
								<p class="is-size-4 has-text-weight-medium">{{ $t('SUCCESS') }}</p>
								<p class="is-size-14 has-text-darkgrey">{{ $t('kyc.ACCOUNT_TO_TIER2') }}</p>
							</div>

							<div class="card-section mt-20">
								<div class="inner-card">
									<div class="is-flex is-align-items-center">
										<img class="helpful-icon" src="@/assets/kyc/card-unlocked-icon.svg" />
										<p class="has-text-weight-medium ml-2">{{ $t('kyc.DEPOSITS_UNLOCKED') }}</p>
									</div>
									<div>
										<p class="is-size-14 has-text-darkgrey is-line-height-1">{{
											$t('kyc.TIME_TO_FUND') }}</p>
									</div>
								</div>
							</div>

							<button class="button flat-primary-btn xlarge is-fullwidth mt-20" @click="openFunds()">
								{{ $t('kyc.FUND_YOUR_ACCOUNT') }}
							</button>
						</div>

						<div v-if="kyc_status == 'processing'">
							<div class="is-flex is-align-items-center is-justify-content-center mt-30">
								<img class="big-icon" src="@/assets/kyc/pending-icon.svg" alt="pending clock icon" />
							</div>
							<div class="has-text-centered mt-10">
								<p class="is-size-4 has-text-weight-medium">{{ $t('kyc.PROCESSING') }}</p>
								<p class="is-size-14 has-text-darkgrey">{{ $t('kyc.CHECKS_IN_PROGRESS') }}</p>
							</div>

							<div class="card-section mt-20">
								<div class="inner-card">
									<p class="is-size-14 has-text-darkgrey is-line-height-1">{{
										$t('kyc.PROCESSING_VERIFICATION') }}</p>
								</div>
							</div>

							<button class="button flat-white-btn large is-fullwidth mt-20" @click="closeModal()">
								{{ $t('CLOSE_DIALOG') }}
							</button>
						</div>

						<div v-if="kyc_status == 'review'">
							<div class="is-flex is-align-items-center is-justify-content-center mt-30">
								<img class="big-icon" src="@/assets/kyc/review-icon.svg"
									alt="review magnifying glass icon" />
							</div>
							<div class="has-text-centered mt-10">
								<p class="is-size-4 has-text-weight-medium">{{ $t('WAITING_REVIEW') }}</p>
								<p class="is-size-14 has-text-darkgrey">{{ $t('kyc.UNABLE_TO_VERIFY') }}</p>
							</div>

							<div class="card-section mt-20">
								<div class="inner-card">
									<p class="has-text-weight-medium">{{ $t('kyc.MANUAL_REVIEW_REQUIRED') }}</p>
									<p class="is-size-14 has-text-darkgrey is-line-height-1">{{
										$t('kyc.MANUAL_REVIEW_DESC') }}</p>
								</div>
							</div>

							<div
								class="card-section highlighted inner-card mt-20 is-flex is-align-items-center is-flex-direction-row">
								<img class="helpful-icon" src="@/assets/kyc/hourglass-icon.svg" />
								<p class="is-size-14 ml-1">{{ $t('kyc.PATIENT_2_WEEKS_REVIEW') }}</p>
							</div>

							<button class="button flat-white-btn large is-fullwidth mt-20" @click="closeModal()">
								{{ $t('CLOSE_DIALOG') }}
							</button>
						</div>

						<div v-if="kyc_status == 'rejected'">
							<div class="is-flex is-align-items-center is-justify-content-center mt-30">
								<img class="big-icon" src="@/assets/kyc/rejected-icon.svg"
									alt="rejected warning icon" />
							</div>
							<div class="has-text-centered mt-10">
								<p class="is-size-4 has-text-weight-medium">{{ $t('kyc.KYC_REJECTED') }}</p>
								<!-- <p class="is-size-14 has-text-darkgrey">{{ $t('kyc.VERIFICATION_REJECTED') }}</p> -->
							</div>

							<div class="card-section mt-20">
								<div class="inner-card">
									<p class="has-text-weight-medium">{{ $t('kyc.VERIFICATION_FAILED') }}</p>
									<p class="is-size-14 has-text-darkgrey is-line-height-1">{{
										$t('kyc.VERIFICATION_FAILED_DESC') }}</p>
								</div>
							</div>

							<div class="card-section highlighted inner-card mt-20">
								<p class="is-size-14" v-html="$t('kyc.MISTAKE_CONTACT_SUPPORT', {
									supportLink:
										'mailto:contact@help.morpher.com?subject=Rejected%20KYC&body=I%20would%20like%20to%20request%20a%20review%20of%20KYC%20information...'
								})
									" />
							</div>

							<button class="button flat-white-btn large is-fullwidth mt-20" @click="closeModal()">
								{{ $t('CLOSE_DIALOG') }}
							</button>
						</div>

						<!-- <header class="trade-tabs">
							<p>{{ $t('kyc.ACCOUNT_STATUS_UPDATE') }}</p>
						</header>
						<figure class="has-text-centered">
							<img class="clock-img" src="@/assets/icons/clock.svg" :alt="$t('CLOCK_REVIEW_PENDING_ICON')" />
						</figure>
						<p class="has-text-left">
							{{ $t('kyc.ACCOUNT_STATUS_UPDATE_DESCRIPTION') }}
						</p>
						<button class="button big-outlined-button is-thick is-size-6 transition-faster mt-20">
							{{ $t('CLOSE_DIALOG') }}
						</button> -->
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
  <h4> <b> New portfolio detected </b> </h4>
  <br> Airdrop message - 10k MPH added to wallet
*/
export default defineComponent({
	name: 'KYCStatusModal',
	data() {
		return {

		};
	},
	computed: {
		...mapState(useModalsStore, {
			showKYCStatusModal: (state) => state.showKYCStatusModal,
		}),
		...mapState(useUserStore, {
			kyc_status: (state) => state.data?.kyc_status,
		}),

	},
	methods: {
		...mapActions(useModalsStore, {
			toggleKYCStatusModal: 'toggleKYCStatusModal',
		}),

		async closeModal() {
			this.toggleKYCStatusModal(false);
		},
		openFunds() {
			this.$router.push('/funds/deposit').catch(() => { });
			this.closeModal();
		}
	}
});
</script>

<style scoped>
.clock-img {
	height: 180px;
	margin-bottom: -1.25rem;
	margin-top: 10px;
}

.big-icon {
	height: 80px;
	width: 80px;
}

.helpful-icon {
	height: 16px;
	min-height: 16px;
}

.modal-card {
	min-width: 400px;
}

@media screen and (max-width: 768px) {
	.modal-card {
		min-width: 100%;
		margin: 0;
		padding: 0;
	}
}

.modal-card-mobile {
	background-color: #fff;
	border: 0;
	border-radius: 0;
	position: absolute;
	min-width: 100%;
	margin: 0;
	padding: 0;
	top: 0px;
	height: 100%;
}

.card-section {
	border: 1px solid #eae9ed;
	border-radius: 12px;
}

.inner-card {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	padding: 1rem;
}

.top-border {
	border-top: 1px solid #eae9ed;
}

.highlighted {
	background-color: #f8f9fd;
	box-shadow: none;
}

.copy-link-section {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
}

.break-the-word {
	word-break: break-all;
}
</style>
