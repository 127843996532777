query getEmailSendList($id: String) {
    getEmailSendList(id: $id) {
        id
        email_template_id
        email
        date_added
        date_submitted
        status
        date_sent
        error
    }
}

