<template>
	<section class="has-font-manrope">
		<b-modal :modelValue="showUnlockWalletModal" has-modal-card @close="closeModal()">
			<div class="modal-card terminal">



				<div class="deposit-modal-spacing">
					<div class="has-text-centered mt-4">
						<Blockie :large="true" :src="activePortfolio?.blockie || ''" />
					</div>
					<p class="as-text-centered is-size-12 is-line-height-18 mt-4">
						{{ walletEmail }}
					</p>
					<div class="header-bar-global">
						{{ $t('auth.UNLOCK_WALLET_TITLE') }}
					</div>
					<p class="as-text-centered is-size-14 is-line-height-20 mt-4">
						{{ $t('auth.UNLOCK_WALLET_TEXT') }}
					</p>

					<!-- 2fa required -->
					<div v-if="twoFA">
						<div class="field">
							<label class="label">{{ twoFAAuthenticator ? $t('auth.AUTH_CODE') : $t('auth.EMAIL_CODE')
								}}</label>
							<div class="control">
								<input type="email" class="input" ref="twofa_code" data-cy="twofaCode"
									:placeholder="twoFAAuthenticator ? $t('auth.ENTER_CODE') : $t('auth.EMAIL_CODE')"
									name="walletEmail" v-model="twofaCode" />
							</div>

							<button type="submit" @click="submit2fa" data-cy="submit"
								:class="{ 'is-loading': loginProcessing }"
								class="button is-login transition-faster mt-15 is-fullwidth-mobile">
								<span class="text">{{ $t('auth.SUBMIT') }}</span>
							</button>

							<div class="error" v-if="loginError">
								<p data-cy="loginError">
									⚠️ <span v-html="loginError"></span>
								<div v-if="showRecovery" @click="openRecovery" class="login-router transition-faster">
									<span class="ml-1">{{ $t('auth.RECOVER_YOUR_WALLET_QUESTION') }}</span>
								</div>
								</p>
							</div>

							<p class="loginLink" v-if="twoFAEmail">
								<span>{{ $t('auth.DIDNT_GET_CODE') }}</span>
								<a @click="twoFACodeResend" class="login-router transition-faster" v-if="!twoFASending">
									<span data-cy="signUpButton">
										{{ $t('auth.SEND_AGAIN') }}
									</span>
								</a>
							</p>

							<p class="loginLink">
								<a @click="twoFA = false" class="login-router transition-faster">
									<span data-cy="signUpButton">
										{{ $t('CANCEL') }}
									</span>
								</a>
							</p>

						</div>
					</div>

					<LoginGoogle class="mt-16" :showText="true" v-else-if="loginMethod == 'google'"
						@processMethod="processMethod" :loading="loginProcessingGoogle"></LoginGoogle>

					<div v-else-if="loginMethod !== 'apple'">
						<div class="field">
							<label class="label">{{ $t('auth.PASSWORD') }}</label>
							<div class="control">
								<input type="password" class="input" ref="login_password" @keydown="checkKeyPress"
									data-cy="walletPassword" name="walletPassword"
									:placeholder="$t('auth.ENTER_PASSWORD')" v-model="walletPassword" />
								<button class="password-toggle" v-on:click="togglePasswordVisibility"
									data-cy="password-toggle-button">
									<img v-if="passwordIsVisible" class="image"
										src="@/assets/auth/password-visible-icon.svg" alt="Visible Button" />
									<img v-if="!passwordIsVisible" class="image"
										src="@/assets/auth/password-invisible-icon.svg" alt="Invisible Button" />
								</button>
							</div>
						</div>


						<button type="submit" @click="() => {
							login('email');
						}
							" data-cy="submit" :disabled="!walletEmail || !walletPassword" :class="{ 'is-loading': loginProcessing }"
							class="button is-login transition-faster mt-15 is-fullwidth-mobile">
							<span class="text">{{ $t('auth.LOGIN') }}</span>
						</button>



						<p class="loginLink">
							<span>{{ $t('auth.FORGOT_PASSWORD') }}</span>
							<span @click="openRecovery" class="login-router transition-faster">
								{{ $t('auth.RECOVER_YOUR_WALLET') }}
							</span>
						</p>
					</div>

					<LoginApple class="mt-16" :showText="true" v-show="loginMethod == 'apple'"
					@processMethod="processMethod" :loading="loginProcessingApple"></LoginApple>

				</div>

				<div class="auth-footer">
					<div class="is-flex is-align-items-center" style="justify-content: center;">
						<img src="@/assets/auth/secure.svg">

						<p>{{ $t('auth.WALLET_SECURED') }}</p>
					</div>
					<p>
						<a href="https://www.morpher.com/privacy" target="_blank">{{ $t('PRIVACY_POLICY') }}</a>

					</p>

				</div>

				<!-- Just wallet migration design -->
				<!-- <div class="deposit-modal-spacing">
					<div class="header-bar-global">
						{{ $t('migration.MIGRATE_WALLET_HEADER') }}
					</div>
					<div class="has-text-centered mt-30">
						<img src="@/assets/img/migration/wallet-migration.svg" :alt="$t('migration.MIGRATE_WALLET_HEADER')" />
					</div>

					<div class="mt-10 has-text-left has-text-darkgrey has-text-weight-bold">
						<p>
							{{ $t('migration.MIGRATE_WALLET_SUBHEAD') }}
						</p>

					</div>
					<div class="migration-bullet-points">
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT1') }}</p>
						</div>
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT2') }}</p>
						</div>
						<div class="bullet-point">
							<img src="@/assets/icons/check-bulletpoint.svg" />
							<p>{{ $t('migration.MIGRATE_WALLET_PONT3') }}</p>
						</div>
					</div>

					<button class="button flat-primary-btn xlarge is-fullwidth mt-20" @click="startMigration">
						{{ $t('migration.START_MIGRATION') }}
					</button>

					<div class="mt-15">
						<a class="is-size-6 has-text-weight-medium transition-faster" @click="closeModal">{{
							$t('migration.BTN_MIGRATE_LATER')
						}}</a>
					</div>
				</div> -->
			</div>
		</b-modal>
		<canvas id="my-canvas" class="custom-canvas"></canvas>
	</section>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import Blockie from '../Blockie.vue';
import LoginApple from '../auth/LoginApple.vue';
import LoginGoogle from '../auth/LoginGoogle.vue';
import { parse } from '@/helpers/uaparser';

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'UnlockWalletModal',
	components: {
		Blockie,
		LoginApple,
		LoginGoogle

	},
	data() {
		return {

			email: '',
			loading: false,
			warning: false,
			passwordIsVisible: false,
			walletEmail: '',
			walletPassword: '',
			loginMethod: '',
			loginProcessing: false,
			loginProcessingGoogle: false,
			loginProcessingApple: false,
			loginTimeout: null as null | NodeJS.Timeout | number,
			twoFA: false,
			twoFAAuthenticator: false,
			twoFAEmail: false,
			twofaCode: '',
			twoFASending: false,
			loginError: '',
			showRecovery: false

		};
	},
	computed: {
		...mapState(useModalsStore, {
			showUnlockWalletModal: (state) => state.showUnlockWalletModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.activePortfolio,
		}),
		...mapState(useContractStore, {
			walletType: (state) => state.walletType,
			morpherwallet: (state) => state.morpherwallet,
			isLoggedInWallet: (state) => state.isLoggedInWallet,
		}),

	},
	watch: {
		user() {
			this.initialize();
		},

		showUnlockWalletModal(nv) {
			if (nv) {
				this.initialize();

				if (!this.loginMethod || !this.walletEmail) {
					this.morpherwallet.showWallet()
					this.closeModal();

				}
			}
		},
		isLoggedInWallet() {
			if (this.isLoggedInWallet) {
				this.closeModal()
			}
		},
		twofaCode(nv) {
			if (nv && nv.length == 6) {
				this.submit2fa();
			}
		}
	},
	mounted() {
		this.initialize();

		const browser = parse(navigator.userAgent);

		if (browser.name == 'safari' && this.loginMethod == 'apple') {
			this.morpherwallet.showWallet()
			this.closeModal();

		}

	},
	methods: {
		...mapActions(useModalsStore, {
			toggleUnlockWalletModal: 'toggleUnlockWalletModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),


		async closeModal() {

			this.loginProcessing = false;
			this.loginProcessingGoogle = false
			this.loginProcessingApple = false;

			if (this.loginTimeout) {
				clearTimeout(this.loginTimeout)
				this.loginTimeout = null
			}

			this.toggleUnlockWalletModal(false);
		},

		processMethod(data: any) {
			this.loginError = '';

			if (data.success) {
				this.login(data);
			} else {
				if (data.error === 'popup_closed_by_user') {
					this.loginError = this.$t('GOOGLE_COOKIES_BLOCKED');
				} else if (data.error === 'google_script_blocked') {
					this.loginError = this.$t('GOOGLE_SCRIPT_BLOCKED');
				} else {
					this.loginError = this.$t(data.error);
				}
			}
		},
		openRecovery() {
			this.closeModal();
			this.morpherwallet.showWalletRecovery();

		},
		async login(method: any) {
			// block if login is already executing
			if (this.loginProcessing || this.loginProcessingGoogle || this.loginProcessingApple) {
				return;
			}

			if (method == 'email') this.loginProcessing = true;
			else if (method.type == 'google') this.loginProcessingGoogle = true;
			else if (method.type == 'apple') this.loginProcessingApple = true;

			let email = this.walletEmail;
			let password = this.walletPassword;

			this.loginTimeout = setTimeout(() => {
				this.morpherwallet.showWallet()

				this.closeModal();

			}, 15000)


			this.morpherwallet.onError(async (error: any) => {
				this.morpherwallet.showWallet()
				this.closeModal();
			})

			this.morpherwallet.on2FA(async (data: any) => {
				if (this.loginTimeout) {
					clearTimeout(this.loginTimeout)
					this.loginTimeout = null
				}
				this.loginProcessing = false;
				this.twoFA = true;
				this.twoFAAuthenticator = data.authenticator;
				this.twoFAEmail = data.email;
				this.twofaCode = ''
			});

			this.morpherwallet.loginWalletHidden(method, email, password);

		},

		async submit2fa() {

			this.loginProcessing = true;
			this.loginError = '';

			this.loginTimeout = setTimeout(() => {
				this.morpherwallet.showWallet()
				this.closeModal();

			}, 15000)

			this.morpherwallet.loginWallet2fa(this.twofaCode);
		},

		initialize() {
			this.loginMethod = localStorage.getItem('walletLoginMethod') || '';
			this.walletEmail = localStorage.getItem('emailOrUsernameStorage') || '';

			this.loginProcessing = false;
			this.loginError = '';
			this.twoFA = false;
			this.twofaCode = '';
			this.walletPassword = ''
		},

		togglePasswordVisibility() {
			this.passwordIsVisible = !this.passwordIsVisible;
			(this.$refs.login_password as InstanceType<typeof HTMLInputElement>).type = this.passwordIsVisible ? 'text' : 'password';
		},

		checkKeyPress(e: KeyboardEvent) {
			if (e.key == 'Enter') {
				if (this.walletPassword) {
					this.login('email');
				} else {
					// set focus to the password field if it is blanck
					window.setTimeout(() => {
						const passwordElement = (this.$refs.login_password as InstanceType<typeof HTMLInputElement>);
						if (passwordElement) passwordElement.focus();
					}, 100);
				}
			}
		},
		twoFACodeResend() {
			this.twoFASending = true;
			window.setTimeout(() => {
				this.twoFASending = false;
			}, 30000)
			this.morpherwallet.loginWallet2faSend();
		},

	}
});
</script>

<style scoped>
.modal-card {
	min-width: 400px;
}

.custom-canvas {
	display: none;
}

@media screen and (max-width: 768px) {
	.modal-card {
		min-width: inherit;
	}
}

.modal_intro {
	font-size: 16px;
	font-weight: normal;
	color: #666666;
}

.modal_icon {
	position: absolute;
}

.modal_header {
	font-size: 16px;
	font-weight: 500;
	color: #333333;
	padding-left: 35px;
}

.modal_body {
	font-size: 14px;
	font-weight: 400;
	color: #666666;
	padding-left: 35px;
}

.modal_section {
	margin-top: 15px;
}

.is-64 {
	height: 64px;
}

.payment_provider_section {
	border: 1px solid #eae9ed;
	border-radius: 8px;
	padding: 1rem;
	display: flex;
	gap: 0.5rem;
}

.provider_text {
	display: flex;
	flex-direction: column;
	gap: 5px;
	color: #666666;
	text-align: left;
}

.transaction_icon {
	height: 20px;
	min-width: 20px;
}

.provider_header {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	line-height: 1;
}

.warning-confirm {
	background: #f7f5fc;
	border: 1px solid #eae9ed;
	border-radius: 8px;
	line-height: 20px;
	padding: 16px;
}

.migration-bullet-points {
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-top: 16px;
	align-self: stretch;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* or 143% */

	letter-spacing: -0.01em;

	/* Dark Gray */

	color: #333333;
}

.bullet-point {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.bullet-point img {
	margin-right: 10px;
}

.auth-footer {
	background: #F5F4F5;
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 12px;
	font-weight: 400;
	margin-top: 16px;
}

.label {
	margin-top: 20px;
	margin-bottom: 4px;
	text-align: left;
	color: #686475;
	font-weight: 700;
	font-size: 12px;
}

.input {
	padding: 23px 16px;
	background-color: #fff;
	border-radius: 8px !important;
	border-color: #d0d5dd !important;
}

.input::placeholder {
	font-family: 'Manrope';
	line-height: 20px;
	font-size: 14px !important;
	font-weight: 400;
	color: #686475;
}

.password-toggle {
	position: absolute;
	right: 10px;
	top: 30%;
	background: none;
	border: none;
	cursor: pointer;
}
</style>
