<template>
	<div>





		<p class="navigation-item navigation-header">{{ $t('MARKETS') }}</p>
		<ul>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/stock" data-testid="stock-route"
					@click.native="onLinkClick">{{ $t('STOCKS') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link" to="/crypto"
					active-class="navigation-item navigation-link-active" data-cy="cryptoRoute"
					@click.native="onLinkClick">{{ $t('CRYPTOCURRENCIES') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/commodity"
					data-testid="commodities-route" @click.native="onLinkClick">{{ $t('COMMODITIES') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/forex" data-testid="forex-route"
					@click.native="onLinkClick">{{ $t('FOREX') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/index" data-testid="indices-route"
					@click.native="onLinkClick">{{ $t('INDEX') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/unique" data-testid="unique-route"
					@click.native="onLinkClick">{{ $t('UNIQUES') }}
					<!-- <div class="new-tag">
						<img src="@/assets/icons/new/tada.png" class="new-icon" :alt="$t('unique.NEW')" />{{ $t('unique.NEW') }}
					</div> -->
				</router-link>
			</li>
		</ul>



		<p>
			<router-link class="navigation-item navigation-link" active-class="navigation-item navigation-link-active"
				to="/savings" data-testid="savings-route" data-cy="savingsRoute" @click.native="onLinkClick">{{
					$t('SAVINGS') }}</router-link>
		</p>

		<p>
			<router-link class="navigation-item navigation-link" active-class="navigation-item navigation-link-active"
				data-testid="rewards-route" to="/rewards" data-cy="rewardsRoute" @click.native="onLinkClick">{{
					$t('REWARDS') }}
				<!-- Number of Rewards to Claim -->
				<div v-if="availableClaims" class="green-bubble ml-2">
					{{ user?.airdrop?.available_claims }}
				</div>
			</router-link>
		</p>


		<p class="navigation-item navigation-header">{{ $t('ACCOUNT') }}</p>
		<ul>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/portfolio" data-testid="portfolio-route"
					@click.native="onLinkClick">{{ $t('PORTFOLIO') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/funds" data-cy="fundsRoute"
					@click.native="onLinkClick">{{ $t('FUNDS') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/history" data-testid="history-route"
					data-cy="historyRoute" @click.native="onLinkClick">{{ $t('HISTORY') }}</router-link>
			</li>

			<li v-if="user?.wallet_type === 'fortmatic' || user?.wallet_type === 'portis'">
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" data-testid="history-route" to="/migrate"
					@click.native="onLinkClick">{{ $t('migration.MIGRATE') }}
				</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/settings" data-testid="settings-route"
					@click.native="onLinkClick">{{ $t('SETTINGS') }}</router-link>
			</li>
			<li>
				<router-link class="navigation-item navigation-link"
					active-class="navigation-item navigation-link-active" to="/help" data-testid="settings-route"
					@click.native="onLinkClick">{{ $t('HELP') }}</router-link>
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
//vuex
import { mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';

export default defineComponent({
	computed: {
		...mapState(useUserStore, {
			user: state => state.data,
			airdrop: state => state.data?.airdrop
		}),
		availableClaims() {
			const self = this as any;
			if (self.user.airdrop.available_claims && self.user.airdrop.available_claims > 0) {
				return true;
			} else {
				return false;
			}
		}
	},
	methods: {
		onLinkClick() {
			console.log('onLinkClick')
			this.$emit('linkclick');
		}
	}
});
</script>

<style scoped>
.navigation-header {
	font-family: 'Rubik', sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	color: grey;
	padding-top: 25px;
	letter-spacing: 1px;
}

.navigation-item {
	text-align: left;
	word-break: break-word;
}

.navigation-link:after {
	position: absolute;
	left: 0px;
	width: 5px;
	height: 100%;
	background: #00c386;
	content: '';
	opacity: 0;
	bottom: 0px;
	transition: all 0.3s;
}

.navigation-link-active {
	color: #000;
}

.navigation-link-active:after {
	position: absolute;
	left: 0px;
	width: 5px;
	height: 100%;
	background: #00c386;
	content: '';
	opacity: 1;
	bottom: 0px;
	transition: all 0.3s;
}

.navigation-search-container {
	margin-left: 32px;
	margin-right: 32px;
	padding-top: 3px;
	width: 60vw;
}

.new-tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 10px;
	width: 71px;
	height: 24px;
	background: #f9f5ff;
	border-radius: 16px;

	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6941c6;
	margin-left: 10px;
}

.new-icon {
	width: 16px;
	height: 16px;
	margin-right: 1px;
}

.green-bubble {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 22px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	background: #dffbe9;
	border-radius: 16px;
	font-weight: 700;
	font-size: 12px;
	color: #039954;
	text-align: center;
	padding-top: 0px;
}
</style>