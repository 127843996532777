<template>
	<section>
		<b-modal :modelValue="showFAQModal" has-modal-card @close="close">
			<div class="modal-card terminal has-font-manrope">
				<section class="section columns p-t-none" style="margin: 0; padding: 0">
					<div class="column is-5" style="padding: 0">
						<!-- header image -->

						<div class="header-image-section">
							<img v-if="marketType == 'sneaker'" class="image"
								src="@/assets/unique/sneakers-faq-card.png"
								alt="$t('unique.ABOUT_SNEAKER_COLLECTIONS')" />

							<img v-else-if="marketType == 'watch'" class="image"
								src="@/assets/unique/watches-faq-card.png" alt="$t('unique.ABOUT_WATCH_COLLECTIONS')" />

							<img v-else-if="marketType == 'football'" class="image"
								src="@/assets/unique/football-card.png" alt="$t('unique.ABOUT_FOOTBALL_MARKETS')" />

							<img v-else-if="marketType == 'elections'" class="image"
								src="@/assets/unique/elections-card.png" alt="$t('unique.ABOUT_ELECTION_MARKETS')" />

							<img v-else class="image" src="@/assets/unique/nft-faq-card.png"
								alt="$t('unique.ABOUT_NFT_COLLECTIONS')" />
						</div>

						<!-- Sneakers FAQ -->
						<div v-if="marketType == 'sneaker'" class="terminal-content has-text-left">
							<p class="has-text-weight-bold is-size-5 mb-1">
								{{ $t('unique.ABOUT_SNEAKER_COLLECTIONS') }}
							</p>
							<!-- Question Sections -->
							<div class="faq-section" @click="mobileFAQClose('sneaker_what_is')">
								<p class="is-size-14"
									:class="{ 'faq-selected': !FAQPage || FAQPage == 'sneaker_what_is' }">
									{{ $t('unique.FAQ_SNEAKER_WHAT_IS_HEADING') }}
								</p>
								<img v-if="!isMobile && (!FAQPage || FAQPage == 'sneaker_what_is')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (!FAQPage || FAQPage == 'sneaker_what_is')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'sneaker_what_is'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'sneaker_what_is')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_SNEAKER_WHAT_IS_DETAIL', {
									link: 'https://www.axios.com/2021/05/10/pandemic-sneaker-trading-resale-stockx'
								})
									"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('sneaker_trading')"
								:class="{ 'faq-selected': FAQPage == 'sneaker_trading' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_TRADING_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'sneaker_trading'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'sneaker_trading'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'sneaker_trading'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'sneaker_trading')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_TRADING_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('sneaker_short')"
								:class="{ 'faq-selected': FAQPage == 'sneaker_short' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_SHORT_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'sneaker_short'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'sneaker_short'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'sneaker_short'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'sneaker_short')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_SHORT_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('sneaker_leverage')"
								:class="{ 'faq-selected': FAQPage == 'sneaker_leverage' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_LEVERAGE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'sneaker_leverage'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'sneaker_leverage'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'sneaker_leverage'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'sneaker_leverage')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_LEVERAGE_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('sneaker_price')"
								:class="{ 'faq-selected': FAQPage == 'sneaker_price' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_PRICE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'sneaker_price'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'sneaker_price'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'sneaker_price'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'sneaker_price')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_SNEAKER_PRICE_DETAIL')"></p>
							</div>
						</div>

						<!-- Football FAQ -->
						<div v-else-if="marketType == 'football'" class="terminal-content has-text-left">
							<p class="has-text-weight-bold is-size-5 mb-1">
								{{ $t('unique.ABOUT_FOOTBALL_MARKETS') }}
							</p>
							<!-- Question Sections -->
							<div class="faq-section" @click="mobileFAQClose('football_what_is')">
								<p class="is-size-14"
									:class="{ 'faq-selected': !FAQPage || FAQPage == 'football_what_is' }">
									{{ $t('unique.FAQ_FOOTBALL_WHAT_IS_HEADING') }}
								</p>
								<img v-if="!isMobile && (!FAQPage || FAQPage == 'football_what_is')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (!FAQPage || FAQPage == 'football_what_is')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'football_what_is'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'football_what_is')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_FOOTBALL_WHAT_IS_DETAIL', {
									link: 'https://www.forbes.com/lists/soccer-valuations/?sh=7c6c984f198b'
								})
									"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('football_missing_match')">
								<p class="is-size-14"
									:class="{ 'faq-selected': !FAQPage || FAQPage == 'football_missing_match' }">
									{{ $t('unique.FAQ_FOOTBALL_MATCH_MISSING_HEADING') }}
								</p>
								<img v-if="!isMobile && (!FAQPage || FAQPage == 'football_missing_match')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (!FAQPage || FAQPage == 'football_missing_match')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'football_missing_match'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'football_missing_match')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_FOOTBALL_MATCH_MISSING_DETAIL')
									"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('football_why_price')">
								<p class="is-size-14"
									:class="{ 'faq-selected': !FAQPage || FAQPage == 'football_why_price' }">
									{{ $t('unique.FAQ_FOOTBALL_WHY_PRICE_HEADING') }}
								</p>
								<img v-if="!isMobile && (!FAQPage || FAQPage == 'football_why_price')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (!FAQPage || FAQPage == 'football_why_price')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'football_why_price'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'football_why_price')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_FOOTBALL_WHY_PRICE_DETAIL')
									"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('football_trading')"
								:class="{ 'faq-selected': FAQPage == 'football_trading' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_TRADING_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'football_trading'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'football_trading'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'football_trading'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'football_trading')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_FOOTBALL_TRADING_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('football_short')"
								:class="{ 'faq-selected': FAQPage == 'football_short' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_FOOTBALL_SHORT_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'football_short'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'football_short'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'football_short'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'football_short')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_FOOTBALL_SHORT_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('football_leverage')"
								:class="{ 'faq-selected': FAQPage == 'football_leverage' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_FOOTBALL_LEVERAGE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'football_leverage'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'football_leverage'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'football_leverage'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'football_leverage')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_LEVERAGE_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('football_price')"
								:class="{ 'faq-selected': FAQPage == 'football_price' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_FOOTBALL_PRICE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'football_price'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'football_price'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'football_price'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'football_price')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_FOOTBALL_PRICE_DETAIL')"></p>
							</div>
						</div>
						<!-- Elections FAQ -->
						<div v-if="marketType == 'elections'" class="terminal-content has-text-left">
							<p class="has-text-weight-bold is-size-5 mb-1">
								{{ $t('unique.ABOUT_ELECTION_MARKETS') }}
							</p>
							<!-- Question Sections -->
							<div class="faq-section" @click="mobileFAQClose('elections_what_is')">
								<p class="is-size-14"
									:class="{ 'faq-selected': !FAQPage || FAQPage == 'elections_what_is' }">
									{{ $t('unique.FAQ_ELECTION_WHAT_IS_HEADING') }}
								</p>
								<img v-if="!isMobile && (!FAQPage || FAQPage == 'elections_what_is')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (!FAQPage || FAQPage == 'elections_what_is')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'elections_what_is'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'elections_what_is')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_ELECTION_WHAT_IS_DETAIL')
									"></p>
							</div>
							<hr class="section-divider" />

							<div class="faq-section" @click="mobileFAQClose('elections_price')">
								<p class="is-size-14" :class="{ 'faq-selected': FAQPage == 'elections_price' }">
									{{ $t('unique.FAQ_ELECTION_PRICE_HEADING') }}
								</p>
								<img v-if="!isMobile && (FAQPage == 'elections_price')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (FAQPage == 'elections_price')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'elections_price'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (FAQPage == 'elections_price')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_ELECTION_PRICE_DETAIL')
									"></p>
							</div>
							<hr class="section-divider" />

							<div class="faq-section" @click="mobileFAQClose('elections_end')">
								<p class="is-size-14" :class="{ 'faq-selected': FAQPage == 'elections_end' }">
									{{ $t('unique.FAQ_ELECTION_END_HEADING') }}
								</p>
								<img v-if="!isMobile && (FAQPage == 'elections_end')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (FAQPage == 'elections_end')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'elections_end'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (FAQPage == 'elections_end')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_ELECTION_END_DETAIL')
									"></p>
							</div>
							<hr class="section-divider" />

							<div class="faq-section" @click="mobileFAQClose('elections_markets')">
								<p class="is-size-14" :class="{ 'faq-selected': FAQPage == 'elections_markets' }">
									{{ $t('unique.FAQ_ELECTION_MARKETS_HEADING') }}
								</p>
								<img v-if="!isMobile && (FAQPage == 'elections_markets')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (FAQPage == 'elections_markets')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'elections_markets'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (FAQPage == 'elections_markets')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_ELECTION_MARKETS_DETAIL')
									"></p>
							</div>
							<hr class="section-divider" />


						</div>

						<!-- watches FAQ-->
						<div v-else-if="marketType == 'watch'" class="terminal-content has-text-left">
							<p class="has-text-weight-bold is-size-5 mb-1">
								{{ $t('unique.ABOUT_WATCH_COLLECTIONS') }}
							</p>
							<!-- Question Sections -->
							<div class="faq-section" @click="mobileFAQClose('watch_what_is')">
								<p class="is-size-14"
									:class="{ 'faq-selected': !FAQPage || FAQPage == 'watch_what_is' }">
									{{ $t('unique.FAQ_WATCH_WHAT_IS_HEADING') }}
								</p>
								<img v-if="!isMobile && (!FAQPage || FAQPage == 'watch_what_is')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (!FAQPage || FAQPage == 'watch_what_is')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'watch_what_is'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'watch_what_is')">
								<p class="has-text-weight-normal detail-text-mobile" v-html="$t('unique.FAQ_WATCH_WHAT_IS_DETAIL', {
									link: 'https://www.businessinsider.com/investments-in-rolex-watches-beats-houses-gold-dow-jones-average-2022-2'
								})
									"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('watch_trading')"
								:class="{ 'faq-selected': FAQPage == 'watch_trading' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_TRADING_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'watch_trading'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'watch_trading'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'watch_trading'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'watch_trading')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_TRADING_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('watch_short')"
								:class="{ 'faq-selected': FAQPage == 'watch_short' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_SHORT_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'watch_short'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'watch_short'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'watch_short'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'watch_short')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_SHORT_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('watch_leverage')"
								:class="{ 'faq-selected': FAQPage == 'watch_leverage' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_LEVERAGE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'watch_leverage'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'watch_leverage'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'watch_leverage'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'watch_leverage')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_LEVERAGE_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('watch_price')"
								:class="{ 'faq-selected': FAQPage == 'watch_price' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WATCH_PRICE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'watch_price'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'watch_price'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'watch_price'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'watch_price')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WATCH_PRICE_DETAIL')"></p>
							</div>
						</div>

						<!-- nft's FAQ-->
						<div v-else-if="marketType == 'nft'" class="terminal-content has-text-left">
							<p class="has-text-weight-bold is-size-5 mb-1">
								{{ $t('unique.ABOUT_NFT_COLLECTIONS') }}
							</p>
							<!-- Question Sections -->
							<div class="faq-section" @click="mobileFAQClose('nft_what_is')">
								<p class="is-size-14" :class="{ 'faq-selected': !FAQPage || FAQPage == 'nft_what_is' }">
									{{ $t('unique.FAQ_WHAT_IS_HEADING') }}
								</p>
								<img v-if="!isMobile && (!FAQPage || FAQPage == 'nft_what_is')"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && (!FAQPage || FAQPage == 'nft_what_is')"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'nft_what_is'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'nft_what_is')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WHAT_IS_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('nft_wait')"
								:class="{ 'faq-selected': FAQPage == 'nft_wait' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_WAIT_TIME_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'nft_wait'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'nft_wait'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'nft_wait'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'nft_wait')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_WAIT_TIME_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('nft_short')"
								:class="{ 'faq-selected': FAQPage == 'nft_short' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_SHORT_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'nft_short'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'nft_short'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'nft_short'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'nft_short')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_SHORT_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('nft_leverage')"
								:class="{ 'faq-selected': FAQPage == 'nft_leverage' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_LEVERAGE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'nft_leverage'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'nft_leverage'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'nft_leverage'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'nft_leverage')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_LEVERAGE_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('nft_price')"
								:class="{ 'faq-selected': FAQPage == 'nft_price' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_PRICE_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'nft_price'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'nft_price'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'nft_price'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'nft_price')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_PRICE_DETAIL')"></p>
							</div>
							<hr class="section-divider" />
							<div class="faq-section" @click="mobileFAQClose('nft_drops')"
								:class="{ 'faq-selected': FAQPage == 'nft_drops' }">
								<p class="is-size-14">
									{{ $t('unique.FAQ_DROPS_HEADING') }}
								</p>
								<img v-if="!isMobile && FAQPage == 'nft_drops'"
									src="@/assets/icons/new/arrow-right-green.svg" />
								<img v-else-if="isMobile && FAQPage == 'nft_drops'"
									src="@/assets/icons/new/arrow-up-green.svg" />
								<img v-else-if="isMobile && FAQPage !== 'nft_drops'"
									src="@/assets/icons/new/arrow-down-grey.svg" />
								<img v-else src="@/assets/icons/new/arrow-right-grey.svg" />
							</div>
							<div v-if="isMobile && (!FAQPage || FAQPage == 'nft_drops')">
								<p class="has-text-weight-normal detail-text-mobile"
									v-html="$t('unique.FAQ_DROPS_DETAIL')"></p>
							</div>
						</div>
					</div>

					<!-- Sneaker FAQ Details (desktop) -->
					<div v-if="marketType == 'sneaker' && !isMobile" class="column is-7 detail-section">
						<!-- Detail Sections -->
						<div v-if="!FAQPage || FAQPage == 'sneaker_what_is'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_SNEAKER_WHAT_IS_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_SNEAKER_WHAT_IS_DETAIL', {
								link: 'https://www.axios.com/2021/05/10/pandemic-sneaker-trading-resale-stockx'
							})
								"></p>
						</div>

						<div v-else-if="FAQPage == 'sneaker_trading'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_TRADING_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_WATCH_TRADING_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'sneaker_short'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_SHORT_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_WATCH_SHORT_DETAIL')">
							</p>
						</div>

						<div v-else-if="FAQPage == 'sneaker_leverage'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_LEVERAGE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_WATCH_LEVERAGE_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'sneaker_price'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_PRICE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_SNEAKER_PRICE_DETAIL')"></p>
						</div>
					</div>

					<!-- Football FAQ Details (desktop) -->
					<div v-else-if="marketType == 'football' && !isMobile" class="column is-7 detail-section">
						<!-- Detail Sections -->
						<div v-if="!FAQPage || FAQPage == 'football_what_is'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_FOOTBALL_WHAT_IS_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_FOOTBALL_WHAT_IS_DETAIL', {
								link: 'https://www.forbes.com/lists/soccer-valuations/?sh=7c6c984f198b'
							})
								"></p>
						</div>

						<div v-else-if="FAQPage == 'football_missing_match'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_FOOTBALL_MATCH_MISSING_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_FOOTBALL_MATCH_MISSING_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'football_why_price'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_FOOTBALL_WHY_PRICE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_FOOTBALL_WHY_PRICE_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'football_trading'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_FOOTBALL_TRADING_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_FOOTBALL_TRADING_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'football_short'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_FOOTBALL_SHORT_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_FOOTBALL_SHORT_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'football_leverage'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_FOOTBALL_LEVERAGE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_LEVERAGE_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'football_price'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_FOOTBALL_PRICE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_FOOTBALL_PRICE_DETAIL')"></p>
						</div>
					</div>

					<!-- Elections FAQ Details (desktop) -->
					<div v-if="marketType == 'elections' && !isMobile" class="column is-7 detail-section">
						<!-- Detail Sections -->
						<div v-if="!FAQPage || FAQPage == 'elections_what_is'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_ELECTION_WHAT_IS_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_ELECTION_WHAT_IS_DETAIL')
								"></p>
						</div>
						<div v-else-if="FAQPage == 'elections_price'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_ELECTION_PRICE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_ELECTION_PRICE_DETAIL',)
								"></p>
						</div>
						<div v-else-if="FAQPage == 'elections_end'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_ELECTION_END_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_ELECTION_END_DETAIL')
								"></p>
						</div>
						<div v-else-if="FAQPage == 'elections_markets'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_ELECTION_MARKETS_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_ELECTION_MARKETS_DETAIL')
								"></p>
						</div>
					</div>
					<!-- watch faq details (desktop) -->
					<div v-else-if="marketType == 'watch' && !isMobile" class="column is-7 detail-section">
						<!-- Detail Sections -->
						<div v-if="!FAQPage || FAQPage == 'watch_what_is'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_WHAT_IS_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_WATCH_WHAT_IS_DETAIL', {
								link: 'https://www.businessinsider.com/investments-in-rolex-watches-beats-houses-gold-dow-jones-average-2022-2'
							})
								"></p>
						</div>

						<div v-else-if="FAQPage == 'watch_trading'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_TRADING_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_WATCH_TRADING_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'watch_short'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_SHORT_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_WATCH_SHORT_DETAIL')">
							</p>
						</div>

						<div v-else-if="FAQPage == 'watch_leverage'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_LEVERAGE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text"
								v-html="$t('unique.FAQ_WATCH_LEVERAGE_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'watch_price'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WATCH_PRICE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_WATCH_PRICE_DETAIL')">
							</p>
						</div>
					</div>

					<!-- nft faq details (desktop) -->
					<div v-else-if="marketType == 'nft' && !isMobile" class="column is-7 detail-section">
						<!-- Detail Sections -->
						<div v-if="!FAQPage || FAQPage == 'nft_what_is'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WHAT_IS_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_WHAT_IS_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'nft_wait'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_WAIT_TIME_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_WAIT_TIME_DETAIL')">
							</p>
						</div>

						<div v-else-if="FAQPage == 'nft_short'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_SHORT_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_SHORT_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'nft_leverage'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_LEVERAGE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_LEVERAGE_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'nft_price'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_PRICE_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_PRICE_DETAIL')"></p>
						</div>

						<div v-else-if="FAQPage == 'nft_drops'" class="has-text-left">
							<p class="has-text-weight-bold is-size-18 detail-heading">
								{{ $t('unique.FAQ_DROPS_HEADING') }}
							</p>
							<p class="has-text-weight-normal detail-text" v-html="$t('unique.FAQ_DROPS_DETAIL')"></p>
						</div>
					</div>
				</section>
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalsStore } from '@/store/modules/modals';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
  <h4> <b> Modal which shows faq questions and answers about nft's
*/
export default defineComponent({
	name: 'FAQModal',
	data() {
		return {
			isMobile: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showFAQModal: (state) => state.showFAQModal,
			FAQPage: (state) => state.FAQPage,
		}),

		marketType() {
			if (this.FAQPage && this.FAQPage.startsWith('sneaker_')) {
				return 'sneaker';
			}
			if (this.FAQPage && this.FAQPage.startsWith('watch_')) {
				return 'watch';
			}
			if (this.FAQPage && this.FAQPage.startsWith('nft_')) {
				return 'nft';
			}
			if (this.FAQPage && this.FAQPage.startsWith('football_')) {
				return 'football';
			}
			if (this.FAQPage && this.FAQPage.startsWith('elections_')) {
				return 'elections';
			}
		}
	},
	mounted() {
		this.handleResize();
	},
	unmounted() {
		window.removeEventListener('resize', this.handleResize);
	},
	created() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		handleResize() {
			if (document.documentElement.clientWidth) {
				const screenWidth = document.documentElement.clientWidth;
				if (screenWidth < 768) {
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			}
		},
		...mapActions(useModalsStore, {
			toggleFAQModal: 'toggleFAQModal',
			setFAQModalPage: 'setFAQModalPage',
		}),

		close() {
			this.toggleFAQModal(false);
		},
		mobileFAQClose(type: string) {
			// Close if user tapping current FAQ item on mobile
			if (this.isMobile && this.FAQPage === type) {
				this.setFAQModalPage(this.marketType + '_empty');
			} else {
				this.setFAQModalPage(type);
			}
		}
	}
});
</script>
<style scoped>
.terminal {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.header-image-section {
	height: 135px;
	width: 100%;
	overflow: hidden;
	text-align: center;
	object-fit: cover;
}

.section-divider {
	margin: 0;
	background-color: #e6e6e6;
	height: 1px;
}

.faq-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0rem;
	font-weight: 600;
	transition: color 0.25s;
}

.faq-section:hover {
	cursor: pointer;
	transition: color 0.25s;
	color: #039954;
}

.modal-card {
	max-width: 724px !important;
	width: 724px !important;
}

/* .detail-heading {
	padding-top: 20px;
	padding-bottom: 20px;
} */

.detail-text {
	font-size: 14px;
	line-height: 20px;
	margin-top: 1rem;
}

.detail-text-mobile {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0.75rem;
}

.detail-section {
	padding: 2rem 1.5rem;
	background-color: #fafafd;
	border-left: 1px solid #eceef3;
}

.faq-selected {
	color: #039954;
	font-weight: 600;
}

@media only screen and (max-width: 768px) {
	.modal-card {
		max-height: 100vh;
		max-width: 90vw !important;
	}
}
</style>
