<template>
	<section>
		<b-modal :modelValue="showResetMetamaskModal" has-modal-card @close="close">
			<form @submit.prevent="close">
				<div class="modal-card terminal">
					<div class="terminal-content has-text-left">
						<h1 class="has-font-rubik is-size-5 has-text-centered has-text-weight-medium mb-10">
							{{ $t('METAMASK_RESET_TITLE') }}
						</h1>

						<div>
							<p class=" mb-10">
								{{ $t('METAMASK_RESET_DESCRIPTION') }}
							</p>

							<ol style="margin-left: 1rem">
								<li v-html="$t('METAMASK_RESET_STEP_1')"></li>
								<li v-html="$t('METAMASK_RESET_STEP_2')"></li>
								<li v-html="$t('METAMASK_RESET_STEP_3')"></li>
								<li v-html="$t('METAMASK_RESET_STEP_4')"></li>
							</ol>

							<p class="mt-10 is-size-14">
								{{ $t('METAMASK_RESET_TIP') }}
								<a :href="supportArticleLink('MetamaskReset')" target="_blank"
									class="transition-faster has-text-weight-medium">{{ $t('METAMASK_RESET_HOW_TO')
									}}</a>
							</p>
							<figure class="image mt-10 mb-20">
								<img src="@/assets/metamask_reset.gif" :alt="$t('METAMASK_RESET_ACCOUNT')" />
							</figure>
						</div>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useModalsStore } from '@/store/modules/modals';
import EventBus from '@/store/event-bus';
import { copyToClipboard } from '@/helpers/utils';
/* @group Components_Modals */
/*
  <h4> <b> Modal which shows that ethereum network is wrong </b> </h4>
  <br> Example: https://sketch.cloud/s/49kjZ/52VOwnx
*/
export default defineComponent({
	name: 'ResetMetamaskModal',
	data() {
		return {
			sidechainURL: 'https://sidechain.morpher.com'
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showResetMetamaskModal: (state) => state.showResetMetamaskModal,
		}),
		...mapState(useContractStore, {
			transactOnNetwork: (state) => state.transactOnNetwork,
		}),

	},
	methods: {
		...mapActions(useModalsStore, {
			toggleResetMetamaskModal: 'toggleResetMetamaskModal',
		}),

		close() {
			EventBus.$emit('resetState');
			this.toggleResetMetamaskModal(false);
		},
		copyTextToClipboard() {
			copyToClipboard(this.sidechainURL, this);
		}
	}
});
</script>
<style scoped>
.copy-btn {
	height: 40px;
	width: 3rem;
}
</style>
