<template>
	<transition name="fade">
		<div class="notification is-size-14 has-font-manrope" v-show="migrationStarted">
			<div class="text-section has-text-left">
				<div>
					<img src="@/assets/funds/warning-icon.svg" class="warning-icon" />
				</div>
				<div class="is-line-height-18">
					<span>{{ $t('MIGRATION_STARTED_BANNER') }}</span>
					<span class="router-link has-text-weight-semibold is-blue transition-faster cursor"
						@click="goToMigrate">
						{{ $t('migration.BTN_CONTINUE_MIGRATION') }}
					</span>
				</div>
			</div>
			<!-- <button type="button" class="modal-close notification-close is-medium transition-faster" @click="closeNotification" /> -->
		</div>
	</transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'HeaderMigrationModal',
	components: {},
	data() {
		return {
		};
	},
	computed: {
		...mapState(useUserStore, {
			payload: (state) => state.data?.payload,
			user: (state) => state.data
		}),

		migrationStarted() {
			const migrationRoute = this.$route.path.toLowerCase().indexOf('/migrate') === -1;
			if (this.user?.payload?.migration_started == true && migrationRoute) {
				return true;
			} else {
				return false;
			}
		},
	},
	// watch: {
	// 	$route(nv) {
	// 		if (nv.path.toLowerCase().indexOf('/migrate') !== -1) {
	// 			this.showModal = false;
	// 		} else if (!this.showModal) {
	// 			this.checkModal();
	// 		}
	// 	}
	// },
	mounted() {
	},
	methods: {

		goToMigrate() {
			this.$router.push('/migrate').catch(() => { });
		}
	}
});
</script>
<style scoped>
.notification {
	background: #FFF9E3;
	padding: 0.75rem 1.25rem;
	position: fixed;
	left: 0px;
	width: 100%;
	z-index: 2999;
	top: 82px;
	transition: 200ms;
}



.text-section {
	max-width: 1070px;
	margin-left: auto;
	margin-right: auto;

	display: flex;
	align-items: center;
	gap: 0.5rem;
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 1216px) {
	.notification {
		top: 62px;
		z-index: 29;
		padding-left: 40px;
		padding-right: 40px;
	}

	.text-section {
		max-width: unset;
	}
}

/* mobile */
@media screen and (max-width: 768px) {
	.notification {
		top: 60px;
		z-index: 29;
		padding-left: 20px;
		padding-right: 20px;
	}

	.text-section {
		max-width: unset;
	}
}

.is-blue:not(:hover) {
	color: #1A6DF0 !important;
}

.notification-close {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.35);
}

.cursor {
	cursor: pointer;
}

.warning-icon {
	height: 24px;
	width: 24px;
	min-width: 24px;
}
</style>
