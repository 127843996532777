<template>
	<section>
		<b-modal :modelValue="showBrowserAlertsModal" has-modal-card @close="toggleBrowserAlertsModal(false)">
			<div class="modal-card terminal">
				<div v-if="page === 0" class="terminal-content">
					<h1 class="has-font-rubik is-size-5 has-text-centered has-text-weight-medium mb-10">
						{{ $t('MARKET_NOTIFICATIONS') }}
					</h1>

					<img src="@/assets/market_alerts.svg" alt="Market alerts notifications" />

					<h1 class="is-size-6 has-text-centered has-text-weight-medium mb-1">
						{{ $t('PICK_LEVEL') }}
					</h1>
					<div class="field has-addons percent-selector has-text-centered">
						<div class="percent-btn transition-fast" :class="{
							'is-active': notification_movement_percent == 0.05,
							'div-disabled': !notifications.isSupported || notifications.isBlocked
						}" @click="updateNotificationMovements(0.05)">
							5%
						</div>
						<div class="percent-btn transition-fast" :class="{
							'is-active': notification_movement_percent == 0.1,
							'div-disabled': !notifications.isSupported || notifications.isBlocked
						}" @click="updateNotificationMovements(0.1)">
							10%
						</div>
						<div class="percent-btn transition-fast" :class="{
							'is-active': notification_movement_percent == 0.2,
							'div-disabled': !notifications.isSupported || notifications.isBlocked
						}" @click="updateNotificationMovements(0.2)">
							20%
						</div>
					</div>
					<p class="is-size-14 mt-min-5 has-text-left">
						{{ $t('MARKET_NOTIFICATIONS_BEST') }}
					</p>
					<p class="is-size-14 mt-3 has-text-left">
						{{
							$t('GET_NOTIFIED_MOVEMENTS', {
								movementPercent: notification_movement_percent * 100 || 5
							})
						}}
					</p>
					<button class="button big-button is-buy transition-fast mt-20" @click="updateNotifications()">
						{{ $t('ENABLE') }}
					</button>
				</div>
				<div v-if="page === 1" class="terminal-content">
					<h1 class="has-font-rubik is-size-5 has-text-centered has-text-weight-medium mb-10">
						{{ $t('MARKET_NOTIFICATIONS') }}
					</h1>
					<Spinner />
					<p class="is-size-14 mt-min-5 has-text-left">
						{{ $t('ENABLING_NOTIFICATIONS') }}
					</p>
					<!-- <button
						class="button big-button is-buy transition-fast mt-20"
						:disabled="!notifications.isSupported || notifications.isBlocked"
						@click="updateNotifications"
					>
						Done
					</button> -->
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNotificationsStore } from '@/store/modules/notifications';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';
import { mapActions, mapState } from 'pinia';
import { ERROR, SUCCESS } from '@/store/mutation-types';
import Spinner from '../Spinner.vue';
import { useStatusStore } from '@/store/modules/status';

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'BrowserAlertsModal',
	components: {
		Spinner
	},
	data() {
		return {
			notification_movement_percent: 0.1,
			page: 0
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showBrowserAlertsModal: (state) => state.showBrowserAlertsModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useNotificationsStore, {
			notifications: (state) => state,
		}),

	},
	watch: {
		showBrowserAlertsModal(nv) {
			if (nv) {
				this.notification_movement_percent = this.user?.payload?.notification_movement_percent || 0.1;
				this.page = 0;
			}
		}
	},
	mounted() {
		this.notification_movement_percent = this.user?.payload?.notification_movement_percent || 0.1;
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
			success: SUCCESS

		}),

		...mapActions(useModalsStore, {
			toggleBrowserAlertsModal: 'toggleBrowserAlertsModal',
		}),
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
		}),
		...mapActions(useNotificationsStore, {
			getNotificationToken: 'getNotificationToken',
		}),

		async updateNotifications() {
			if (!this.notifications.isSubscribed || this.notifications.isBlocked) {
				this.page = 1;
			}

			this.subscribeUser();
		},
		async subscribeUser() {
			this.getNotificationToken({ suppressMessage: false, subscribe: true });
		},
		async updateNotificationMovements(newMovementPercent: number) {
			const oldNotificationPercent = this.notification_movement_percent;

			this.notification_movement_percent = newMovementPercent;
			const result = await this.updateUserPayload({
				payloadOption: 'notification_movement_percent',
				payloadValue: newMovementPercent.toString()
			});

			if (!result) {
				this.notification_movement_percent = oldNotificationPercent;
			}
		}
	}
});
</script>
<style scoped>
.percent-selector {
	font-size: 12px;
	font-weight: 700;
	border-radius: 5px;
	border-color: #ededed;
	background-color: #ededed;
	height: 24px;
	width: 144px;
	margin: 0 auto;
}

.percent-btn {
	font-size: 12px;
	font-weight: 500;
	font-family: 'Rubik', sans-serif;
	cursor: pointer;
	margin-top: 2px;
	margin-left: 1px;
	margin-right: 1px;
	width: 48px;
	color: #4c4c4c;
}

.percent-btn:hover {
	color: #000;
}

.percent-btn.is-active {
	background-color: #fff;
	color: #000;
	height: 20px;
	margin-top: 2px;
	border-radius: 5px;
	cursor: default;
	box-shadow: rgba(0, 0, 0, 0.05) 0 3px 4px 0, rgba(0, 0, 0, 0.05) 0 1px 1px 0;
}
</style>
