const deviceType = {
	Android: (): boolean => {
		return navigator.userAgent.match(/Android/i) ? true : false;
	},
	BlackBerry: (): boolean => {
		return navigator.userAgent.match(/BlackBerry/i) ? true : false;
	},
	iOS: (): boolean => {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
	},
	OperaMobile: (): boolean => {
		return navigator.userAgent.match(/Opera Mini/i) ? true : false;
	},
	WindowsMobile: (): boolean => {
		return navigator.userAgent.match(/IEMobile/i) ? true : false || navigator.userAgent.match(/WPDesktop/i) ? true : false;
	},
	isAnyMobile: (): boolean => {
		return (
			deviceType.Android() || deviceType.BlackBerry() || deviceType.iOS() || deviceType.OperaMobile() || deviceType.WindowsMobile()
		);
	}
};

export default deviceType;
