export const morpherOracleAbi = [
	{
	  inputs: [
		{
		  internalType: 'address',
		  name: '_tradeEngineAddress',
		  type: 'address',
		},
		{
		  internalType: 'address',
		  name: '_morpherState',
		  type: 'address',
		},
		{
		  internalType: 'address',
		  name: '_callBackAddress',
		  type: 'address',
		},
		{
		  internalType: 'address payable',
		  name: '_gasCollectionAddress',
		  type: 'address',
		},
		{
		  internalType: 'uint256',
		  name: '_gasForCallback',
		  type: 'uint256',
		},
		{
		  internalType: 'address',
		  name: '_coldStorageOwnerAddress',
		  type: 'address',
		},
	  ],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'constructor',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'AddressBlackListed',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'AddressWhiteListed',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_closeSharesAmount',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_openMPHTokenAmount',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'bool',
		  name: '_tradeDirection',
		  type: 'bool',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_orderLeverage',
		  type: 'uint256',
		},
	  ],
	  name: 'AdminLiquidationOrderCreated',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'CallBackCollectionAddressChange',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'CallbackAddressDisabled',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'CallbackAddressEnabled',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'LinkMorpherState',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'LinkTradeEngine',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  indexed: false,
		  internalType: 'address',
		  name: '_sender',
		  type: 'address',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
	  ],
	  name: 'LiquidationOrderCreated',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'bool',
		  name: '_paused',
		  type: 'bool',
		},
	  ],
	  name: 'OraclePaused',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_sender',
		  type: 'address',
		},
	  ],
	  name: 'OrderCancellationRequestedEvent',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_sender',
		  type: 'address',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_oracleAddress',
		  type: 'address',
		},
	  ],
	  name: 'OrderCancelled',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_closeSharesAmount',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_openMPHTokenAmount',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'bool',
		  name: '_tradeDirection',
		  type: 'bool',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_orderLeverage',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_onlyIfPriceBelow',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_onlyIfPriceAbove',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_goodFrom',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_goodUntil',
		  type: 'uint256',
		},
	  ],
	  name: 'OrderCreated',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_closeSharesAmount',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_openMPHTokenAmount',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'bool',
		  name: '_tradeDirection',
		  type: 'bool',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_orderLeverage',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_onlyIfPriceBelow',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_onlyIfPriceAbove',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_goodFrom',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_goodUntil',
		  type: 'uint256',
		},
	  ],
	  name: 'OrderFailed',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_price',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_unadjustedMarketPrice',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_spread',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_positionLiquidationTimestamp',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_timeStamp',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_newLongShares',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_newShortShares',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_newMeanEntry',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_newMeanSprad',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_newMeanLeverage',
		  type: 'uint256',
		},
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_liquidationPrice',
		  type: 'uint256',
		},
	  ],
	  name: 'OrderProcessed',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: true,
		  internalType: 'address',
		  name: 'previousOwner',
		  type: 'address',
		},
		{
		  indexed: true,
		  internalType: 'address',
		  name: 'newOwner',
		  type: 'address',
		},
	  ],
	  name: 'OwnershipTransferred',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'uint256',
		  name: '_gasForCallback',
		  type: 'uint256',
		},
	  ],
	  name: 'SetGasForCallback',
	  type: 'event',
	},
	{
	  anonymous: false,
	  inputs: [
		{
		  indexed: false,
		  internalType: 'bool',
		  name: '_useWhiteList',
		  type: 'bool',
		},
	  ],
	  name: 'SetUseWhiteList',
	  type: 'event',
	},
	{
	  constant: true,
	  inputs: [],
	  name: '_owner',
	  outputs: [
		{
		  internalType: 'address',
		  name: '',
		  type: 'address',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'address',
		  name: '',
		  type: 'address',
		},
	  ],
	  name: 'callBackAddress',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [],
	  name: 'callBackCollectionAddress',
	  outputs: [
		{
		  internalType: 'address payable',
		  name: '',
		  type: 'address',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [],
	  name: 'gasForCallback',
	  outputs: [
		{
		  internalType: 'uint256',
		  name: '',
		  type: 'uint256',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '',
		  type: 'bytes32',
		},
	  ],
	  name: 'goodFrom',
	  outputs: [
		{
		  internalType: 'uint256',
		  name: '',
		  type: 'uint256',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '',
		  type: 'bytes32',
		},
	  ],
	  name: 'goodUntil',
	  outputs: [
		{
		  internalType: 'uint256',
		  name: '',
		  type: 'uint256',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [],
	  name: 'isOwner',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '',
		  type: 'bytes32',
		},
	  ],
	  name: 'orderCancellationRequested',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [],
	  name: 'owner',
	  outputs: [
		{
		  internalType: 'address',
		  name: '',
		  type: 'address',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [],
	  name: 'paused',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '',
		  type: 'bytes32',
		},
	  ],
	  name: 'priceAbove',
	  outputs: [
		{
		  internalType: 'uint256',
		  name: '',
		  type: 'uint256',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '',
		  type: 'bytes32',
		},
	  ],
	  name: 'priceBelow',
	  outputs: [
		{
		  internalType: 'uint256',
		  name: '',
		  type: 'uint256',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [],
	  name: 'renounceOwnership',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_tokenAddress',
		  type: 'address',
		},
		{
		  internalType: 'uint256',
		  name: '_tokens',
		  type: 'uint256',
		},
	  ],
	  name: 'transferAnyERC20Token',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '_success',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: 'newOwner',
		  type: 'address',
		},
	  ],
	  name: 'transferOwnership',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [],
	  name: 'useWhiteList',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'address',
		  name: '',
		  type: 'address',
		},
	  ],
	  name: 'whiteList',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'setTradeEngineAddress',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'setStateAddress',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'uint256',
		  name: '_gasForCallback',
		  type: 'uint256',
		},
	  ],
	  name: 'overrideGasForCallback',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'enableCallbackAddress',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'disableCallbackAddress',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'isCallbackAddress',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '_isCallBackAddress',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address payable',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'setCallbackCollectionAddress',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [],
	  name: 'getAdministrator',
	  outputs: [
		{
		  internalType: 'address',
		  name: '_administrator',
		  type: 'address',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'bool',
		  name: '_useWhiteList',
		  type: 'bool',
		},
	  ],
	  name: 'setUseWhiteList',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_whiteList',
		  type: 'address',
		},
	  ],
	  name: 'setWhiteList',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_blackList',
		  type: 'address',
		},
	  ],
	  name: 'setBlackList',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
	  ],
	  name: 'isWhiteListed',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '_whiteListed',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
		{
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
		{
		  internalType: 'uint256',
		  name: '_closeSharesAmount',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_openMPHTokenAmount',
		  type: 'uint256',
		},
		{
		  internalType: 'bool',
		  name: '_tradeDirection',
		  type: 'bool',
		},
		{
		  internalType: 'uint256',
		  name: '_orderLeverage',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_onlyIfPriceBelow',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_onlyIfPriceAbove',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_goodFrom',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_goodUntil',
		  type: 'uint256',
		},
	  ],
	  name: 'emitOrderFailed',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
		{
		  internalType: 'uint256',
		  name: '_closeSharesAmount',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_openMPHTokenAmount',
		  type: 'uint256',
		},
		{
		  internalType: 'bool',
		  name: '_tradeDirection',
		  type: 'bool',
		},
		{
		  internalType: 'uint256',
		  name: '_orderLeverage',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_onlyIfPriceAbove',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_onlyIfPriceBelow',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_goodUntil',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_goodFrom',
		  type: 'uint256',
		},
	  ],
	  name: 'createOrder',
	  outputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
	  ],
	  payable: true,
	  stateMutability: 'payable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
	  ],
	  name: 'initiateCancelOrder',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
	  ],
	  name: 'cancelOrder',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  internalType: 'uint256',
		  name: '_price',
		  type: 'uint256',
		},
	  ],
	  name: 'checkOrderConditions',
	  outputs: [
		{
		  internalType: 'bool',
		  name: '_conditionsMet',
		  type: 'bool',
		},
	  ],
	  payable: false,
	  stateMutability: 'view',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [],
	  name: 'pauseOracle',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [],
	  name: 'unpauseOracle',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
		{
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
	  ],
	  name: 'createLiquidationOrder',
	  outputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
	  ],
	  payable: true,
	  stateMutability: 'payable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
		{
		  internalType: 'uint256',
		  name: '_price',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_unadjustedMarketPrice',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_spread',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_liquidationTimestamp',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_timeStamp',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_gasForNextCallback',
		  type: 'uint256',
		},
	  ],
	  name: '__callback',
	  outputs: [
		{
		  internalType: 'uint256',
		  name: '_newLongShares',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_newShortShares',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_newMeanEntry',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_newMeanSpread',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_newMeanLeverage',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_liquidationPrice',
		  type: 'uint256',
		},
	  ],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
		{
		  internalType: 'uint256',
		  name: '_fromIx',
		  type: 'uint256',
		},
		{
		  internalType: 'uint256',
		  name: '_toIx',
		  type: 'uint256',
		},
	  ],
	  name: 'delistMarket',
	  outputs: [],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: false,
	  inputs: [
		{
		  internalType: 'address',
		  name: '_address',
		  type: 'address',
		},
		{
		  internalType: 'bytes32',
		  name: '_marketId',
		  type: 'bytes32',
		},
	  ],
	  name: 'adminLiquidationOrder',
	  outputs: [
		{
		  internalType: 'bytes32',
		  name: '_orderId',
		  type: 'bytes32',
		},
	  ],
	  payable: false,
	  stateMutability: 'nonpayable',
	  type: 'function',
	},
	{
	  constant: true,
	  inputs: [
		{
		  internalType: 'string',
		  name: '_source',
		  type: 'string',
		},
	  ],
	  name: 'stringToHash',
	  outputs: [
		{
		  internalType: 'bytes32',
		  name: '_result',
		  type: 'bytes32',
		},
	  ],
	  payable: false,
	  stateMutability: 'pure',
	  type: 'function',
	},
  ];

export const morpherTradeEngineAbi = [
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "bytes32",
		  "name": "orderId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "escrowAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "EscrowPaid",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "bytes32",
		  "name": "orderId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "escrowAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "EscrowReturned",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint8",
		  "name": "version",
		  "type": "uint8"
		}
	  ],
	  "name": "Initialized",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "interestRate",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "validFromTimestamp",
		  "type": "uint256"
		}
	  ],
	  "name": "LeverageInterestRateAdded",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "name": "LinkState",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_price",
		  "type": "uint256"
		}
	  ],
	  "name": "LockedPriceForClosingPositions",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "name": "OrderCancelled",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_closeSharesAmount",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_openMPHTokenAmount",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "bool",
		  "name": "_tradeDirection",
		  "type": "bool"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_orderLeverage",
		  "type": "uint256"
		}
	  ],
	  "name": "OrderIdRequested",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_marketPrice",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_marketSpread",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_liquidationTimestamp",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_timeStamp",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newLongShares",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newShortShares",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newAverageEntry",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newAverageSpread",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newAverageLeverage",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_liquidationPrice",
		  "type": "uint256"
		}
	  ],
	  "name": "OrderProcessed",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "bool",
		  "name": "_longPosition",
		  "type": "bool"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_timeStamp",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_marketPrice",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_marketSpread",
		  "type": "uint256"
		}
	  ],
	  "name": "PositionLiquidated",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "_userId",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_timeStamp",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newLongShares",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newShortShares",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newMeanEntryPrice",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newMeanEntrySpread",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newMeanEntryLeverage",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_newLiquidationPrice",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_mint",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "_burn",
		  "type": "uint256"
		}
	  ],
	  "name": "PositionUpdated",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "oldTimestamp",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "newTimestamp",
		  "type": "uint256"
		}
	  ],
	  "name": "ResetTimestampInOrder",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "newInterestRate",
		  "type": "uint256"
		}
	  ],
	  "name": "SetLeverageInterestRate",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "positionHash",
		  "type": "bytes32"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "sender",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "marketId",
		  "type": "bytes32"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "timeStamp",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "longShares",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "shortShares",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "meanEntryPrice",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "meanEntrySpread",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "meanEntryLeverage",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "liquidationPrice",
		  "type": "uint256"
		}
	  ],
	  "name": "SetPosition",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "ADMINISTRATOR_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "DOMAIN_TYPE_HASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "ORACLE_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "POSITIONADMIN_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "POSITION_TYPE_HASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "_HASHED_NAME",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "_HASHED_VERSION",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "deployedTimeStamp",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "escrowOpenOrderEnabled",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "name": "exposureByMarket",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "maxMappingIndex",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "name": "interestRates",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "validFrom",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "rate",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "lastOrderId",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "morpherState",
	  "outputs": [
		{
		  "internalType": "contract MorpherState",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "numInterestRates",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "orderNonce",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "name": "orders",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "userId",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "closeSharesAmount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "openMPHTokenAmount",
		  "type": "uint256"
		},
		{
		  "internalType": "bool",
		  "name": "tradeDirection",
		  "type": "bool"
		},
		{
		  "internalType": "uint256",
		  "name": "liquidationTimestamp",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "marketPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "marketSpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "orderLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "timeStamp",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "orderEscrowAmount",
		  "type": "uint256"
		},
		{
		  "components": [
			{
			  "internalType": "uint256",
			  "name": "longSharesOrder",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "shortSharesOrder",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "balanceDown",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "balanceUp",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "newLongShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "newShortShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "newMeanEntryPrice",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "newMeanEntrySpread",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "newMeanEntryLeverage",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "newLiquidationPrice",
			  "type": "uint256"
			}
		  ],
		  "internalType": "struct MorpherTradeEngine.OrderModifier",
		  "name": "modifyPosition",
		  "type": "tuple"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "name": "portfolio",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "lastUpdated",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "longShares",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "shortShares",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "meanEntryPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "meanEntrySpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "meanEntryLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "liquidationPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes32",
		  "name": "positionHash",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "name": "priceLockDeactivatedMarket",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "lockedPrice",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_stateAddress",
		  "type": "address"
		},
		{
		  "internalType": "bool",
		  "name": "_escrowOpenOrderEnabled",
		  "type": "bool"
		},
		{
		  "internalType": "uint256",
		  "name": "_deployedTimestampOverride",
		  "type": "uint256"
		}
	  ],
	  "name": "initialize",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_stateAddress",
		  "type": "address"
		}
	  ],
	  "name": "setMorpherState",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bool",
		  "name": "_isEnabled",
		  "type": "bool"
		}
	  ],
	  "name": "setEscrowOpenOrderEnabled",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_interestRate",
		  "type": "uint256"
		}
	  ],
	  "name": "setLeverageInterestRate",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "interestRate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_rate",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_validFrom",
		  "type": "uint256"
		}
	  ],
	  "name": "addInterestRate",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_positionTimestamp",
		  "type": "uint256"
		}
	  ],
	  "name": "getInterestRate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "_closeSharesAmount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_openMPHTokenAmount",
		  "type": "uint256"
		},
		{
		  "internalType": "bool",
		  "name": "_tradeDirection",
		  "type": "bool"
		},
		{
		  "internalType": "uint256",
		  "name": "_orderLeverage",
		  "type": "uint256"
		}
	  ],
	  "name": "requestOrderId",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		}
	  ],
	  "name": "getOrder",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "_userId",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "_closeSharesAmount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_openMPHTokenAmount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketSpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_orderLeverage",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "_price",
		  "type": "uint256"
		}
	  ],
	  "name": "setDeactivatedMarketPrice",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		}
	  ],
	  "name": "getDeactivatedMarketPrice",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketSpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_liquidationTimestamp",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_timeStampInMS",
		  "type": "uint256"
		}
	  ],
	  "name": "processOrder",
	  "outputs": [
		{
		  "components": [
			{
			  "internalType": "uint256",
			  "name": "lastUpdated",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "longShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "shortShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntryPrice",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntrySpread",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntryLeverage",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "liquidationPrice",
			  "type": "uint256"
			},
			{
			  "internalType": "bytes32",
			  "name": "positionHash",
			  "type": "bytes32"
			}
		  ],
		  "internalType": "struct MorpherTradeEngine.position",
		  "name": "",
		  "type": "tuple"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "name": "cancelOrder",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_positionAveragePrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_positionAverageLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_positionTimeStampInMs",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketSpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_orderLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "bool",
		  "name": "_sell",
		  "type": "bool"
		}
	  ],
	  "name": "shortShareValue",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_shareValue",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_positionAveragePrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_positionAverageLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_positionTimeStampInMs",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_marketSpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_orderLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "bool",
		  "name": "_sell",
		  "type": "bool"
		}
	  ],
	  "name": "longShareValue",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_shareValue",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_averagePrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_averageLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_positionTimeStampInMs",
		  "type": "uint256"
		}
	  ],
	  "name": "calculateMarginInterest",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_orderId",
		  "type": "bytes32"
		}
	  ],
	  "name": "computeLiquidationPrice",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_liquidationPrice",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_newMeanEntryPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_newMeanEntryLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "bool",
		  "name": "_long",
		  "type": "bool"
		},
		{
		  "internalType": "uint256",
		  "name": "_positionTimestampInMs",
		  "type": "uint256"
		}
	  ],
	  "name": "getLiquidationPrice",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "marketId",
		  "type": "bytes32"
		},
		{
		  "components": [
			{
			  "internalType": "uint256",
			  "name": "lastUpdated",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "longShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "shortShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntryPrice",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntrySpread",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntryLeverage",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "liquidationPrice",
			  "type": "uint256"
			},
			{
			  "internalType": "bytes32",
			  "name": "positionHash",
			  "type": "bytes32"
			}
		  ],
		  "internalType": "struct MorpherTradeEngine.position",
		  "name": "_position",
		  "type": "tuple"
		},
		{
		  "components": [
			{
			  "internalType": "bytes32",
			  "name": "r",
			  "type": "bytes32"
			},
			{
			  "internalType": "bytes32",
			  "name": "s",
			  "type": "bytes32"
			},
			{
			  "internalType": "uint8",
			  "name": "v",
			  "type": "uint8"
			},
			{
			  "internalType": "uint256",
			  "name": "deadline",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "signer",
			  "type": "address"
			}
		  ],
		  "internalType": "struct MorpherTradeEngine.EIP712Signature",
		  "name": "ownerSignature",
		  "type": "tuple"
		},
		{
		  "components": [
			{
			  "internalType": "bytes32",
			  "name": "r",
			  "type": "bytes32"
			},
			{
			  "internalType": "bytes32",
			  "name": "s",
			  "type": "bytes32"
			},
			{
			  "internalType": "uint8",
			  "name": "v",
			  "type": "uint8"
			},
			{
			  "internalType": "uint256",
			  "name": "deadline",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "signer",
			  "type": "address"
			}
		  ],
		  "internalType": "struct MorpherTradeEngine.EIP712Signature",
		  "name": "positionAdminSignature",
		  "type": "tuple"
		}
	  ],
	  "name": "setPositionWithSignature",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		}
	  ],
	  "name": "nonces",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "DOMAIN_SEPARATOR",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "_timeStamp",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_longShares",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_shortShares",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_meanEntryPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_meanEntrySpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_meanEntryLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_liquidationPrice",
		  "type": "uint256"
		}
	  ],
	  "name": "setPosition",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		}
	  ],
	  "name": "getPosition",
	  "outputs": [
		{
		  "components": [
			{
			  "internalType": "uint256",
			  "name": "lastUpdated",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "longShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "shortShares",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntryPrice",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntrySpread",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "meanEntryLeverage",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "liquidationPrice",
			  "type": "uint256"
			},
			{
			  "internalType": "bytes32",
			  "name": "positionHash",
			  "type": "bytes32"
			}
		  ],
		  "internalType": "struct MorpherTradeEngine.position",
		  "name": "",
		  "type": "tuple"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "_timeStamp",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_longShares",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_shortShares",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_meanEntryPrice",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_meanEntrySpread",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_meanEntryLeverage",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_liquidationPrice",
		  "type": "uint256"
		}
	  ],
	  "name": "getPositionHash",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "_hash",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "pure",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		}
	  ],
	  "name": "getMaxMappingIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_maxMappingIndex",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "name": "getExposureMappingIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_mappingIndex",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_marketId",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "_mappingIndex",
		  "type": "uint256"
		}
	  ],
	  "name": "getExposureMappingAddress",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	}
]

export const morpherStateAbi = [
	{
		inputs: [
			{
				internalType: 'bool',
				name: '_mainChain',
				type: 'bool'
			},
			{
				internalType: 'address',
				name: '_sideChainOperator',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_morpherTreasury',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'administratorAddress',
				type: 'address'
			}
		],
		name: 'AdministratorChange',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: '_bridgeAddress',
				type: 'address'
			}
		],
		name: 'BridgeChange',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'recipient',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'totalToken',
				type: 'uint256'
			}
		],
		name: 'Burn',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [],
		name: 'FastWithdrawsDisabled',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'governanceAddress',
				type: 'address'
			}
		],
		name: 'GovernanceChange',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: '_periodLength',
				type: 'uint256'
			}
		],
		name: 'InactivityPeriodUpdated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [],
		name: 'Last24HoursAmountWithdrawnReset',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'rewardsTime',
				type: 'uint256'
			}
		],
		name: 'LastRewardTime',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [],
		name: 'LastWithdrawAt',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'mainChainWithdrawLimit24',
				type: 'uint256'
			}
		],
		name: 'MainChainWithdrawLimitUpdate',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'activateMarket',
				type: 'bytes32'
			}
		],
		name: 'MarketActivated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'deActivateMarket',
				type: 'bytes32'
			}
		],
		name: 'MarketDeActivated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'maxLeverage',
				type: 'uint256'
			}
		],
		name: 'MaximumLeverageChange',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'recipient',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'totalToken',
				type: 'uint256'
			}
		],
		name: 'Mint',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: '_transferNonce',
				type: 'uint256'
			}
		],
		name: 'NewBridgeNonce',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'sideChainOperator',
				type: 'address'
			}
		],
		name: 'NewSideChainOperator',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'newTotalOnOtherChain',
				type: 'uint256'
			}
		],
		name: 'NewTotalInPositions',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'newTotalOnOtherChain',
				type: 'uint256'
			}
		],
		name: 'NewTotalOnOtherChain',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'newTotalSupply',
				type: 'uint256'
			}
		],
		name: 'NewTotalSupply',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: '_numberOfRequests',
				type: 'uint256'
			}
		],
		name: 'NumberOfRequestsLimitUpdate',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'recipient',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'OperatingRewardMinted',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'oracleContract',
				type: 'address'
			}
		],
		name: 'OracleChange',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'rewardsAddress',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'rewardsBasisPoints',
				type: 'uint256'
			}
		],
		name: 'RewardsChange',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: '_last24HoursAmountWithdrawn',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_lastWithdrawLimitReductionTime',
				type: 'uint256'
			}
		],
		name: 'RollingWithdrawnAmountUpdated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'spender',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'tokens',
				type: 'uint256'
			}
		],
		name: 'SetAllowance',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'positionHash',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'marketId',
				type: 'bytes32'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'timeStamp',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'longShares',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'shortShares',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'meanEntryPrice',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'meanEntrySpread',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'meanEntryLeverage',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'liquidationPrice',
				type: 'uint256'
			}
		],
		name: 'SetPosition',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'sideChainMerkleRoot',
				type: 'bytes32'
			}
		],
		name: 'SideChainMerkleRootUpdate',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'blackList',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'blockNumber',
				type: 'uint256'
			}
		],
		name: 'StateAccessDenied',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'whiteList',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'blockNumber',
				type: 'uint256'
			}
		],
		name: 'StateAccessGranted',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'administrator',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'bool',
				name: '_paused',
				type: 'bool'
			}
		],
		name: 'StatePaused',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'tokenAddress',
				type: 'address'
			}
		],
		name: 'TokenChange',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		name: 'TokenSentToLinkedChain',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'recipient',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'Transfer',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		name: 'TransferredTokenClaimed',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'blackList',
				type: 'address'
			}
		],
		name: 'TransfersDisabled',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'whiteList',
				type: 'address'
			}
		],
		name: 'TransfersEnabled',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'WithdrawLimitUpdated',
		type: 'event'
	},
	{
		constant: true,
		inputs: [],
		name: '_owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'administrator',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'fastTransfersEnabled',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'inactivityPeriod',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'isOwner',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'last24HoursAmountWithdrawn',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'lastRewardTime',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'lastWithdrawLimitReductionTime',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'mainChain',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'mainChainWithdrawLimit24',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'maximumLeverage',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'morpherBridge',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'morpherGovernance',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'morpherRewards',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'morpherToken',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'numberOfRequestsLimit',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'oracleContract',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'paused',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'rewardBasisPoints',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'sideChainMerkleRoot',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'sideChainMerkleRootWrittenAtTime',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'sideChainOperator',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'totalInPositions',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'totalOnOtherChain',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'totalSupply',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'totalToken',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_tokenAddress',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_tokens',
				type: 'uint256'
			}
		],
		name: 'transferAnyERC20Token',
		outputs: [
			{
				internalType: 'bool',
				name: '_success',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'transferNonce',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'withdrawLimit24Hours',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getMaxMappingIndex',
		outputs: [
			{
				internalType: 'uint256',
				name: '_maxMappingIndex',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getExposureMappingIndex',
		outputs: [
			{
				internalType: 'uint256',
				name: '_mappingIndex',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'uint256',
				name: '_mappingIndex',
				type: 'uint256'
			}
		],
		name: 'getExposureMappingAddress',
		outputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'uint256',
				name: '_maxMappingIndex',
				type: 'uint256'
			}
		],
		name: 'setMaxMappingIndex',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_index',
				type: 'uint256'
			}
		],
		name: 'setExposureMapping',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_index',
				type: 'uint256'
			}
		],
		name: 'setExposureMappingIndex',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_index',
				type: 'uint256'
			}
		],
		name: 'setExposureMappingAddress',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		name: 'setTokenClaimedOnThisChain',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getTokenClaimedOnThisChain',
		outputs: [
			{
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		name: 'setTokenSentToLinkedChain',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getTokenSentToLinkedChain',
		outputs: [
			{
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getTokenSentToLinkedChainTime',
		outputs: [
			{
				internalType: 'uint256',
				name: '_timeStamp',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'add24HoursWithdrawn',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'update24HoursWithdrawLimit',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_limit',
				type: 'uint256'
			}
		],
		name: 'set24HourWithdrawLimit',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'resetLast24HoursAmountWithdrawn',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_periodLength',
				type: 'uint256'
			}
		],
		name: 'setInactivityPeriod',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'getBridgeNonce',
		outputs: [
			{
				internalType: 'uint256',
				name: '_nonce',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'disableFastWithdraws',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_positionHash',
				type: 'bytes32'
			}
		],
		name: 'setPositionClaimedOnMainChain',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_positionHash',
				type: 'bytes32'
			}
		],
		name: 'getPositionClaimedOnMainChain',
		outputs: [
			{
				internalType: 'bool',
				name: '_alreadyClaimed',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'setLastRequestBlock',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getLastRequestBlock',
		outputs: [
			{
				internalType: 'uint256',
				name: '_lastRequestBlock',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_numberOfRequests',
				type: 'uint256'
			}
		],
		name: 'setNumberOfRequests',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'increaseNumberOfRequests',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getNumberOfRequests',
		outputs: [
			{
				internalType: 'uint256',
				name: '_numberOfRequests',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_numberOfRequestsLimit',
				type: 'uint256'
			}
		],
		name: 'setNumberOfRequestsLimit',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getNumberOfRequestsLimit',
		outputs: [
			{
				internalType: 'uint256',
				name: '_numberOfRequestsLimit',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_mainChainWithdrawLimit24',
				type: 'uint256'
			}
		],
		name: 'setMainChainWithdrawLimit',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getMainChainWithdrawLimit',
		outputs: [
			{
				internalType: 'uint256',
				name: '_mainChainWithdrawLimit24',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'grantAccess',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'denyAccess',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getStateAccess',
		outputs: [
			{
				internalType: 'bool',
				name: '_hasAccess',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'enableTransfers',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'disableTransfers',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'getCanTransfer',
		outputs: [
			{
				internalType: 'bool',
				name: '_hasAccess',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_to',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		name: 'transfer',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		name: 'mint',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_token',
				type: 'uint256'
			}
		],
		name: 'burn',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_totalInPositions',
				type: 'uint256'
			}
		],
		name: 'setTotalInPositions',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_newTotalOnOtherChain',
				type: 'uint256'
			}
		],
		name: 'setTotalOnOtherChain',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_tokenOwner',
				type: 'address'
			}
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: 'balance',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_spender',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_tokens',
				type: 'uint256'
			}
		],
		name: 'setAllowance',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_tokenOwner',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'spender',
				type: 'address'
			}
		],
		name: 'getAllowance',
		outputs: [
			{
				internalType: 'uint256',
				name: 'remaining',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_newGovernanceContractAddress',
				type: 'address'
			}
		],
		name: 'setGovernanceContract',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getGovernance',
		outputs: [
			{
				internalType: 'address',
				name: '_governanceContract',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_newBridge',
				type: 'address'
			}
		],
		name: 'setMorpherBridge',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getMorpherBridge',
		outputs: [
			{
				internalType: 'address',
				name: '_currentBridge',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_newOracleContract',
				type: 'address'
			}
		],
		name: 'setOracleContract',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getOracleContract',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_newTokenContract',
				type: 'address'
			}
		],
		name: 'setTokenContract',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getTokenContract',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_newAdministrator',
				type: 'address'
			}
		],
		name: 'setAdministrator',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getAdministrator',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_newRewardsAddress',
				type: 'address'
			}
		],
		name: 'setRewardAddress',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_newRewardBasisPoints',
				type: 'uint256'
			}
		],
		name: 'setRewardBasisPoints',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_activateMarket',
				type: 'bytes32'
			}
		],
		name: 'activateMarket',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_deActivateMarket',
				type: 'bytes32'
			}
		],
		name: 'deActivateMarket',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getMarketActive',
		outputs: [
			{
				internalType: 'bool',
				name: '_active',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_newMaximumLeverage',
				type: 'uint256'
			}
		],
		name: 'setMaximumLeverage',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getMaximumLeverage',
		outputs: [
			{
				internalType: 'uint256',
				name: '_maxLeverage',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'pauseState',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'unPauseState',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_sideChainMerkleRoot',
				type: 'bytes32'
			}
		],
		name: 'setSideChainMerkleRoot',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getSideChainMerkleRoot',
		outputs: [
			{
				internalType: 'bytes32',
				name: '_sideChainMerkleRoot',
				type: 'bytes32'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'setSideChainOperator',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getSideChainOperator',
		outputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getSideChainMerkleRootWrittenAtTime',
		outputs: [
			{
				internalType: 'uint256',
				name: '_sideChainMerkleRoot',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'uint256',
				name: '_timeStamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_longShares',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_shortShares',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntryPrice',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntrySpread',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntryLeverage',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_liquidationPrice',
				type: 'uint256'
			}
		],
		name: 'setPosition',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getPosition',
		outputs: [
			{
				internalType: 'uint256',
				name: '_longShares',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_shortShares',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntryPrice',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntrySpread',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntryLeverage',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_liquidationPrice',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				internalType: 'uint256',
				name: '_timeStamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_longShares',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_shortShares',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntryPrice',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntrySpread',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_meanEntryLeverage',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_liquidationPrice',
				type: 'uint256'
			}
		],
		name: 'getPositionHash',
		outputs: [
			{
				internalType: 'bytes32',
				name: '_hash',
				type: 'bytes32'
			}
		],
		payable: false,
		stateMutability: 'pure',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_balance',
				type: 'uint256'
			}
		],
		name: 'getBalanceHash',
		outputs: [
			{
				internalType: 'bytes32',
				name: '_hash',
				type: 'bytes32'
			}
		],
		payable: false,
		stateMutability: 'pure',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getLastUpdated',
		outputs: [
			{
				internalType: 'uint256',
				name: '_lastUpdated',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getLongShares',
		outputs: [
			{
				internalType: 'uint256',
				name: '_longShares',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getShortShares',
		outputs: [
			{
				internalType: 'uint256',
				name: '_shortShares',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getMeanEntryPrice',
		outputs: [
			{
				internalType: 'uint256',
				name: '_meanEntryPrice',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getMeanEntrySpread',
		outputs: [
			{
				internalType: 'uint256',
				name: '_meanEntrySpread',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getMeanEntryLeverage',
		outputs: [
			{
				internalType: 'uint256',
				name: '_meanEntryLeverage',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			},
			{
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			}
		],
		name: 'getLiquidationPrice',
		outputs: [
			{
				internalType: 'uint256',
				name: '_liquidationPrice',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'payOperatingReward',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	}
]

export const morpherAirdropAbi = [
	{
		inputs: [
			{
				internalType: 'address',
				name: '_airdropAdminAddress',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_morpherToken',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_coldStorageOwnerAddress',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: '_operator',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: '_recipient',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_amountClaimed',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_amountAuthorized',
				type: 'uint256'
			}
		],
		name: 'AirdropSent',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: '_recipient',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_amountClaimed',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_amountAuthorized',
				type: 'uint256'
			}
		],
		name: 'SetAirdropAuthorized',
		type: 'event'
	},
	{
		payable: true,
		stateMutability: 'payable',
		type: 'fallback'
	},
	{
		constant: true,
		inputs: [],
		name: '_owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'airdropAdmin',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'isOwner',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'morpherToken',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'totalAirdropAuthorized',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'totalAirdropClaimed',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_tokenAddress',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_tokens',
				type: 'uint256'
			}
		],
		name: 'transferAnyERC20Token',
		outputs: [
			{
				internalType: 'bool',
				name: '_success',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'setAirdropAdmin',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_address',
				type: 'address'
			}
		],
		name: 'setMorpherTokenAddress',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_userAddress',
				type: 'address'
			}
		],
		name: 'getAirdropClaimed',
		outputs: [
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_userAddress',
				type: 'address'
			}
		],
		name: 'getAirdropAuthorized',
		outputs: [
			{
				internalType: 'uint256',
				name: '_balance',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '_userAddress',
				type: 'address'
			}
		],
		name: 'getAirdrop',
		outputs: [
			{
				internalType: 'uint256',
				name: '_claimed',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_authorized',
				type: 'uint256'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_userAddress',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_authorized',
				type: 'uint256'
			}
		],
		name: 'setAirdropAuthorized',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'claimAirdrop',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'claimSomeAirdrop',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_recipient',
				type: 'address'
			}
		],
		name: 'adminSendAirdrop',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_recipient',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'adminSendSomeAirdrop',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_recipient',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'adminAuthorizeAndSend',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	}
];

export const morpherBridgeAbi = [
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "tokens",
          "type": "uint256"
        }
      ],
      "name": "ClaimFailedTransferToSidechain",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "address",
          "name": "_address",
          "type": "address"
        }
      ],
      "name": "LinkState",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "tokens",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "sidechainTransactionHash",
          "type": "bytes32"
        }
      ],
      "name": "OperatorChainTransfer",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "positionHash",
          "type": "bytes32"
        }
      ],
      "name": "PositionRecoveryFromSideChain",
      "type": "event"
    },
    {
		anonymous: false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_rootHash",
          "type": "bytes32"
        }
      ],
      "name": "SideChainMerkleRootUpdated",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "positionHash",
          "type": "bytes32"
        }
      ],
      "name": "TokenRecoveryFromSideChain",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "tokens",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "totalTokenSent",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "timeStamp",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "transferNonce",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "targetChainId",
          "type": "uint256"
        },
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "transferHash",
          "type": "bytes32"
        }
      ],
      "name": "TransferToLinkedChain",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "tokens",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "totalTokenSent",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "timeStamp",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "transferNonce",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "targetChainId",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "destinationAddress",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bytes",
          "name": "userSigature",
          "type": "bytes"
        },
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "transferHash",
          "type": "bytes32"
        }
      ],
      "name": "TransferToLinkedChainAndWithdrawTo",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "tokens",
          "type": "uint256"
        }
      ],
      "name": "TrustlessWithdrawFromSideChain",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_withdrawLimit",
          "type": "uint256"
        }
      ],
      "name": "WithdrawLimitChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldLimit",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newLimit",
          "type": "uint256"
        }
      ],
      "name": "WithdrawLimitDailyGlobalChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldLimit",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newLimit",
          "type": "uint256"
        }
      ],
      "name": "WithdrawLimitDailyPerUserChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldLimit",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newLimit",
          "type": "uint256"
        }
      ],
      "name": "WithdrawLimitMonthlyGlobalChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldLimit",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newLimit",
          "type": "uint256"
        }
      ],
      "name": "WithdrawLimitMonthlyPerUserChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [],
      "name": "WithdrawLimitReset",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldLimit",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newLimit",
          "type": "uint256"
        }
      ],
      "name": "WithdrawLimitYearlyGlobalChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldLimit",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newLimit",
          "type": "uint256"
        }
      ],
      "name": "WithdrawLimitYearlyPerUserChanged",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "ADMINISTRATOR_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "SIDECHAINOPERATOR_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "bridgeNonce",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "name": "claimFromInactivity",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "inactivityPeriod",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "poolFee",
      "outputs": [
        {
          "internalType": "uint24",
          "name": "",
          "type": "uint24"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "recoveryEnabled",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "swapRouter",
      "outputs": [
        {
          "internalType": "contract ISwapRouter",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "tokenClaimedOnThisChain",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "lastTransferAt",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "tokenSentToLinkedChain",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "lastTransferAt",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "withdrawalData",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "merkleRoot",
          "type": "bytes32"
        },
        {
          "internalType": "uint256",
          "name": "lastUpdatedAt",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "withdrawalLimitGlobalDaily",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "withdrawalLimitGlobalMonthly",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "withdrawalLimitGlobalYearly",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "withdrawalLimitPerUserDaily",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "withdrawalLimitPerUserMonthly",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "withdrawalLimitPerUserYearly",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "withdrawalPerUserPerDay",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "withdrawalPerUserPerMonth",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "withdrawalPerUserPerYear",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "withdrawalsGlobalDaily",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "withdrawalsGlobalMonthly",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "withdrawalsGlobalYearly",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "stateMutability": "payable",
      "type": "receive",
      "payable": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_stateAddress",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "_recoveryEnabled",
          "type": "bool"
        },
        {
          "internalType": "contract ISwapRouter",
          "name": "_swapRouter",
          "type": "address"
        }
      ],
      "name": "initialize",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_stateAddress",
          "type": "address"
        }
      ],
      "name": "setMorpherState",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "contract ISwapRouter",
          "name": "_swapRouter",
          "type": "address"
        }
      ],
      "name": "updateSwapRouter",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_periodInSeconds",
          "type": "uint256"
        }
      ],
      "name": "setInactivityPeriod",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_rootHash",
          "type": "bytes32"
        }
      ],
      "name": "updateSideChainMerkleRoot",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_withdrawLimit",
          "type": "uint256"
        }
      ],
      "name": "updateWithdrawLimitPerUserDaily",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_withdrawLimit",
          "type": "uint256"
        }
      ],
      "name": "updateWithdrawLimitPerUserMonthly",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_withdrawLimit",
          "type": "uint256"
        }
      ],
      "name": "updateWithdrawLimitPerUserYearly",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_withdrawLimit",
          "type": "uint256"
        }
      ],
      "name": "updateWithdrawLimitGlobalDaily",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_withdrawLimit",
          "type": "uint256"
        }
      ],
      "name": "updateWithdrawLimitGlobalMonthly",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_withdrawLimit",
          "type": "uint256"
        }
      ],
      "name": "updateWithdrawLimitGlobalYearly",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_usr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "isNotDailyLimitExceeding",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_usr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "isNotMonthlyLimitExceeding",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_usr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "isNotYearlyLimitExceeding",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_usr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "verifyUpdateDailyLimit",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_usr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "verifyUpdateMonthlyLimit",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_usr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "verifyUpdateYearlyLimit",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokens",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_targetChainId",
          "type": "uint256"
        }
      ],
      "name": "stageTokensForTransfer",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokens",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_targetChainId",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "_autoWithdrawalAddressTo",
          "type": "address"
        },
        {
          "internalType": "bytes",
          "name": "_signature",
          "type": "bytes"
        }
      ],
      "name": "stageTokensForTransfer",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_numOfToken",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_claimLimit",
          "type": "uint256"
        },
        {
          "internalType": "bytes32[]",
          "name": "_proof",
          "type": "bytes32[]"
        }
      ],
      "name": "claimStagedTokens",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_numOfToken",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_claimLimit",
          "type": "uint256"
        },
        {
          "internalType": "bytes32[]",
          "name": "_proof",
          "type": "bytes32[]"
        },
        {
          "internalType": "address payable",
          "name": "_finalOutput",
          "type": "address"
        }
      ],
      "name": "claimStagedTokensConvertAndSend",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_usrAddr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_numOfToken",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "fee",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "feeRecipient",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_claimLimit",
          "type": "uint256"
        },
        {
          "internalType": "bytes32[]",
          "name": "_proof",
          "type": "bytes32[]"
        },
        {
          "internalType": "address payable",
          "name": "_finalOutput",
          "type": "address"
        },
        {
          "internalType": "bytes32",
          "name": "_rootHash",
          "type": "bytes32"
        },
        {
          "internalType": "bytes",
          "name": "_userConfirmationSignature",
          "type": "bytes"
        }
      ],
      "name": "claimStagedTokensConvertAndSendForUser",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_wrongSideChainBalance",
          "type": "uint256"
        },
        {
          "internalType": "bytes32[]",
          "name": "_proof",
          "type": "bytes32[]"
        },
        {
          "internalType": "uint256",
          "name": "_targetChainId",
          "type": "uint256"
        }
      ],
      "name": "claimFailedTransferToSidechain",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32[]",
          "name": "_proof",
          "type": "bytes32[]"
        },
        {
          "internalType": "bytes32",
          "name": "_leaf",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "_marketId",
          "type": "bytes32"
        },
        {
          "internalType": "uint256",
          "name": "_timeStamp",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_longShares",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_shortShares",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_meanEntryPrice",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_meanEntrySpread",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_meanEntryLeverage",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_liquidationPrice",
          "type": "uint256"
        }
      ],
      "name": "recoverPositionFromInactivity",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32[]",
          "name": "_proof",
          "type": "bytes32[]"
        },
        {
          "internalType": "bytes32",
          "name": "_leaf",
          "type": "bytes32"
        },
        {
          "internalType": "uint256",
          "name": "_balance",
          "type": "uint256"
        }
      ],
      "name": "recoverTokenFromInactivity",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32[]",
          "name": "_proof",
          "type": "bytes32[]"
        },
        {
          "internalType": "bytes32",
          "name": "_leaf",
          "type": "bytes32"
        }
      ],
      "name": "mProof",
      "outputs": [
        {
          "internalType": "bool",
          "name": "_isTrue",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_balance",
          "type": "uint256"
        }
      ],
      "name": "getBalanceHash",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "_hash",
          "type": "bytes32"
        }
      ],
      "stateMutability": "pure",
      "type": "function",
      "constant": true
    }
  ];
export const morpherTokenAbi = [
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint8",
          "name": "version",
          "type": "uint8"
        }
      ],
      "name": "Initialized",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "Paused",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_oldValue",
          "type": "bool"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "_newValue",
          "type": "bool"
        }
      ],
      "name": "SetRestrictTransfers",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldValue",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newValue",
          "type": "uint256"
        }
      ],
      "name": "SetTotalTokensInPositions",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_oldValue",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_newValue",
          "type": "uint256"
        }
      ],
      "name": "SetTotalTokensOnOtherChain",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "Unpaused",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "ADMINISTRATOR_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "BURNER_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "MINTER_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "PAUSER_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "POLYGONMINTER_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "TOKENUPDATER_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "TRANSFERBLOCKED_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "TRANSFER_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "decimals",
      "outputs": [
        {
          "internalType": "uint8",
          "name": "",
          "type": "uint8"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "subtractedValue",
          "type": "uint256"
        }
      ],
      "name": "decreaseAllowance",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "addedValue",
          "type": "uint256"
        }
      ],
      "name": "increaseAllowance",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "morpherAccessControl",
      "outputs": [
        {
          "internalType": "contract MorpherAccessControl",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "paused",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_morpherAccessControl",
          "type": "address"
        }
      ],
      "name": "initialize",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bool",
          "name": "restrictTransfers",
          "type": "bool"
        }
      ],
      "name": "setRestrictTransfers",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getRestrictTransfers",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "totalOnOtherChain",
          "type": "uint256"
        }
      ],
      "name": "setTotalTokensOnOtherChain",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getTotalTokensOnOtherChain",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "totalTokensInPositions",
          "type": "uint256"
        }
      ],
      "name": "setTotalInPositions",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getTotalTokensInPositions",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "internalType": "bytes",
          "name": "depositData",
          "type": "bytes"
        }
      ],
      "name": "deposit",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "mint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "burn",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "pause",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "unpause",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "deadline",
          "type": "uint256"
        },
        {
          "internalType": "uint8",
          "name": "v",
          "type": "uint8"
        },
        {
          "internalType": "bytes32",
          "name": "r",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "s",
          "type": "bytes32"
        }
      ],
      "name": "permit",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "nonces",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [],
      "name": "DOMAIN_SEPARATOR",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    }
  ];

export const morpherMigrationAbi = [
	{
		inputs: [
			{
				internalType: 'address',
				name: '_morpherTokenAddress',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_morpherStateAddress',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'bytes32',
				name: '_marketId',
				type: 'bytes32'
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_timestamp',
				type: 'uint256'
			}
		],
		name: 'MarketMigrationComplete',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_timestamp',
				type: 'uint256'
			}
		],
		name: 'MigrationComplete',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_timestamp',
				type: 'uint256'
			}
		],
		name: 'MigrationIncomplete',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_timestamp',
				type: 'uint256'
			}
		],
		name: 'MigrationPermissionGiven',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_timestamp',
				type: 'uint256'
			}
		],
		name: 'TokenMigrationComplete',
		type: 'event'
	},
	{
		constant: true,
		inputs: [],
		name: '_owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'destinationAddressAllowsOverwriting',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'isOwner',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'ownerSetMigrationAllowance',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'sourceAddressAllowedToOverwriteTo',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'sourceAddressMigrationFinished',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'sourceAddressMigrationStarted',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_tokenAddress',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_tokens',
				type: 'uint256'
			}
		],
		name: 'transferAnyERC20Token',
		outputs: [
			{
				internalType: 'bool',
				name: '_success',
				type: 'bool'
			}
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32[]',
				name: '_marketHashes',
				type: 'bytes32[]'
			}
		],
		name: 'addMarketHashes',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_from',
				type: 'address'
			}
		],
		name: 'allowMigrationFrom',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_from',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_to',
				type: 'address'
			}
		],
		name: 'ownerConfirmMigrationAddresses',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'address',
				name: '_to',
				type: 'address'
			}
		],
		name: 'startMigrate',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	}
];

export const morpherStakingABI = [
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_morpherState",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "_stakingAdmin",
		  "type": "address"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "constructor"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "stateAddress",
		  "type": "address"
		}
	  ],
	  "name": "LinkState",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "lastReward",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "poolShareValue",
		  "type": "uint256"
		}
	  ],
	  "name": "PoolShareValueUpdated",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "newInterestRate",
		  "type": "uint256"
		}
	  ],
	  "name": "SetInterestRate",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "newLockupPeriod",
		  "type": "uint256"
		}
	  ],
	  "name": "SetLockupPeriod",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "newMinimumStake",
		  "type": "uint256"
		}
	  ],
	  "name": "SetMinimumStake",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "stakingAdmin",
		  "type": "address"
		}
	  ],
	  "name": "SetStakingAdmin",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "userAddress",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "poolShares",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "lockedUntil",
		  "type": "uint256"
		}
	  ],
	  "name": "Staked",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "lastReward",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "delta",
		  "type": "uint256"
		}
	  ],
	  "name": "StakingRewardsMinted",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "userAddress",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "poolShares",
		  "type": "uint256"
		}
	  ],
	  "name": "Unstaked",
	  "type": "event"
	},
	{
	  "payable": true,
	  "stateMutability": "payable",
	  "type": "fallback"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "_owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "interestRate",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "isOwner",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "lastReward",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "lockupPeriod",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "marketIdStakingMPH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "minimumStake",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "poolShareValue",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "stakingAddress",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "stakingAdmin",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "totalShares",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_tokenAddress",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "_tokens",
		  "type": "uint256"
		}
	  ],
	  "name": "transferAnyERC20Token",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "_success",
		  "type": "bool"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [],
	  "name": "updatePoolShareValue",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_newPoolShareValue",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_amount",
		  "type": "uint256"
		}
	  ],
	  "name": "stake",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_poolShares",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_numOfShares",
		  "type": "uint256"
		}
	  ],
	  "name": "unstake",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_amount",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "name": "setStakingAdmin",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_stateAddress",
		  "type": "address"
		}
	  ],
	  "name": "setMorpherStateAddress",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_interestRate",
		  "type": "uint256"
		}
	  ],
	  "name": "setInterestRate",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_lockupPeriod",
		  "type": "uint256"
		}
	  ],
	  "name": "setLockupPeriodRate",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": false,
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_minimumStake",
		  "type": "uint256"
		}
	  ],
	  "name": "setMinimumStake",
	  "outputs": [],
	  "payable": false,
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [],
	  "name": "getTotalPooledValue",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_totalPooled",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "name": "getStake",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_poolShares",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "constant": true,
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_address",
		  "type": "address"
		}
	  ],
	  "name": "getStakeValue",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "_value",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_lastUpdate",
		  "type": "uint256"
		}
	  ],
	  "payable": false,
	  "stateMutability": "view",
	  "type": "function"
	}
  ];

export const entrypointAbi = [{
	"inputs":[
	   {
		  "internalType":"uint256",
		  "name":"preOpGas",
		  "type":"uint256"
	   },
	   {
		  "internalType":"uint256",
		  "name":"paid",
		  "type":"uint256"
	   },
	   {
		  "internalType":"uint48",
		  "name":"validAfter",
		  "type":"uint48"
	   },
	   {
		  "internalType":"uint48",
		  "name":"validUntil",
		  "type":"uint48"
	   },
	   {
		  "internalType":"bool",
		  "name":"targetSuccess",
		  "type":"bool"
	   },
	   {
		  "internalType":"bytes",
		  "name":"targetResult",
		  "type":"bytes"
	   }
	],
	"name":"ExecutionResult",
	"type":"error"
 },
 {
	"inputs":[
	   {
		  "internalType":"uint256",
		  "name":"opIndex",
		  "type":"uint256"
	   },
	   {
		  "internalType":"string",
		  "name":"reason",
		  "type":"string"
	   }
	],
	"name":"FailedOp",
	"type":"error"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"sender",
		  "type":"address"
	   }
	],
	"name":"SenderAddressResult",
	"type":"error"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"aggregator",
		  "type":"address"
	   }
	],
	"name":"SignatureValidationFailed",
	"type":"error"
 },
 {
	"inputs":[
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"preOpGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"prefund",
				"type":"uint256"
			 },
			 {
				"internalType":"bool",
				"name":"sigFailed",
				"type":"bool"
			 },
			 {
				"internalType":"uint48",
				"name":"validAfter",
				"type":"uint48"
			 },
			 {
				"internalType":"uint48",
				"name":"validUntil",
				"type":"uint48"
			 },
			 {
				"internalType":"bytes",
				"name":"paymasterContext",
				"type":"bytes"
			 }
		  ],
		  "internalType":"struct IEntryPoint.ReturnInfo",
		  "name":"returnInfo",
		  "type":"tuple"
	   },
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"stake",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"unstakeDelaySec",
				"type":"uint256"
			 }
		  ],
		  "internalType":"struct IStakeManager.StakeInfo",
		  "name":"senderInfo",
		  "type":"tuple"
	   },
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"stake",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"unstakeDelaySec",
				"type":"uint256"
			 }
		  ],
		  "internalType":"struct IStakeManager.StakeInfo",
		  "name":"factoryInfo",
		  "type":"tuple"
	   },
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"stake",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"unstakeDelaySec",
				"type":"uint256"
			 }
		  ],
		  "internalType":"struct IStakeManager.StakeInfo",
		  "name":"paymasterInfo",
		  "type":"tuple"
	   }
	],
	"name":"ValidationResult",
	"type":"error"
 },
 {
	"inputs":[
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"preOpGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"prefund",
				"type":"uint256"
			 },
			 {
				"internalType":"bool",
				"name":"sigFailed",
				"type":"bool"
			 },
			 {
				"internalType":"uint48",
				"name":"validAfter",
				"type":"uint48"
			 },
			 {
				"internalType":"uint48",
				"name":"validUntil",
				"type":"uint48"
			 },
			 {
				"internalType":"bytes",
				"name":"paymasterContext",
				"type":"bytes"
			 }
		  ],
		  "internalType":"struct IEntryPoint.ReturnInfo",
		  "name":"returnInfo",
		  "type":"tuple"
	   },
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"stake",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"unstakeDelaySec",
				"type":"uint256"
			 }
		  ],
		  "internalType":"struct IStakeManager.StakeInfo",
		  "name":"senderInfo",
		  "type":"tuple"
	   },
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"stake",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"unstakeDelaySec",
				"type":"uint256"
			 }
		  ],
		  "internalType":"struct IStakeManager.StakeInfo",
		  "name":"factoryInfo",
		  "type":"tuple"
	   },
	   {
		  "components":[
			 {
				"internalType":"uint256",
				"name":"stake",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"unstakeDelaySec",
				"type":"uint256"
			 }
		  ],
		  "internalType":"struct IStakeManager.StakeInfo",
		  "name":"paymasterInfo",
		  "type":"tuple"
	   },
	   {
		  "components":[
			 {
				"internalType":"address",
				"name":"aggregator",
				"type":"address"
			 },
			 {
				"components":[
				   {
					  "internalType":"uint256",
					  "name":"stake",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"unstakeDelaySec",
					  "type":"uint256"
				   }
				],
				"internalType":"struct IStakeManager.StakeInfo",
				"name":"stakeInfo",
				"type":"tuple"
			 }
		  ],
		  "internalType":"struct IEntryPoint.AggregatorStakeInfo",
		  "name":"aggregatorInfo",
		  "type":"tuple"
	   }
	],
	"name":"ValidationResultWithAggregation",
	"type":"error"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"bytes32",
		  "name":"userOpHash",
		  "type":"bytes32"
	   },
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"sender",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"address",
		  "name":"factory",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"address",
		  "name":"paymaster",
		  "type":"address"
	   }
	],
	"name":"AccountDeployed",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   
	],
	"name":"BeforeExecution",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"totalDeposit",
		  "type":"uint256"
	   }
	],
	"name":"Deposited",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"aggregator",
		  "type":"address"
	   }
	],
	"name":"SignatureAggregatorChanged",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"totalStaked",
		  "type":"uint256"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"unstakeDelaySec",
		  "type":"uint256"
	   }
	],
	"name":"StakeLocked",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"withdrawTime",
		  "type":"uint256"
	   }
	],
	"name":"StakeUnlocked",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"address",
		  "name":"withdrawAddress",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"amount",
		  "type":"uint256"
	   }
	],
	"name":"StakeWithdrawn",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"bytes32",
		  "name":"userOpHash",
		  "type":"bytes32"
	   },
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"sender",
		  "type":"address"
	   },
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"paymaster",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"nonce",
		  "type":"uint256"
	   },
	   {
		  "indexed":false,
		  "internalType":"bool",
		  "name":"success",
		  "type":"bool"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"actualGasCost",
		  "type":"uint256"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"actualGasUsed",
		  "type":"uint256"
	   }
	],
	"name":"UserOperationEvent",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"bytes32",
		  "name":"userOpHash",
		  "type":"bytes32"
	   },
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"sender",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"nonce",
		  "type":"uint256"
	   },
	   {
		  "indexed":false,
		  "internalType":"bytes",
		  "name":"revertReason",
		  "type":"bytes"
	   }
	],
	"name":"UserOperationRevertReason",
	"type":"event"
 },
 {
	"anonymous":false,
	"inputs":[
	   {
		  "indexed":true,
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"address",
		  "name":"withdrawAddress",
		  "type":"address"
	   },
	   {
		  "indexed":false,
		  "internalType":"uint256",
		  "name":"amount",
		  "type":"uint256"
	   }
	],
	"name":"Withdrawn",
	"type":"event"
 },
 {
	"inputs":[
	   
	],
	"name":"SIG_VALIDATION_FAILED",
	"outputs":[
	   {
		  "internalType":"uint256",
		  "name":"",
		  "type":"uint256"
	   }
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"bytes",
		  "name":"initCode",
		  "type":"bytes"
	   },
	   {
		  "internalType":"address",
		  "name":"sender",
		  "type":"address"
	   },
	   {
		  "internalType":"bytes",
		  "name":"paymasterAndData",
		  "type":"bytes"
	   }
	],
	"name":"_validateSenderAndPaymaster",
	"outputs":[
	   
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"uint32",
		  "name":"unstakeDelaySec",
		  "type":"uint32"
	   }
	],
	"name":"addStake",
	"outputs":[
	   
	],
	"stateMutability":"payable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   }
	],
	"name":"balanceOf",
	"outputs":[
	   {
		  "internalType":"uint256",
		  "name":"",
		  "type":"uint256"
	   }
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   }
	],
	"name":"depositTo",
	"outputs":[
	   
	],
	"stateMutability":"payable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"",
		  "type":"address"
	   }
	],
	"name":"deposits",
	"outputs":[
	   {
		  "internalType":"uint112",
		  "name":"deposit",
		  "type":"uint112"
	   },
	   {
		  "internalType":"bool",
		  "name":"staked",
		  "type":"bool"
	   },
	   {
		  "internalType":"uint112",
		  "name":"stake",
		  "type":"uint112"
	   },
	   {
		  "internalType":"uint32",
		  "name":"unstakeDelaySec",
		  "type":"uint32"
	   },
	   {
		  "internalType":"uint48",
		  "name":"withdrawTime",
		  "type":"uint48"
	   }
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"account",
		  "type":"address"
	   }
	],
	"name":"getDepositInfo",
	"outputs":[
	   {
		  "components":[
			 {
				"internalType":"uint112",
				"name":"deposit",
				"type":"uint112"
			 },
			 {
				"internalType":"bool",
				"name":"staked",
				"type":"bool"
			 },
			 {
				"internalType":"uint112",
				"name":"stake",
				"type":"uint112"
			 },
			 {
				"internalType":"uint32",
				"name":"unstakeDelaySec",
				"type":"uint32"
			 },
			 {
				"internalType":"uint48",
				"name":"withdrawTime",
				"type":"uint48"
			 }
		  ],
		  "internalType":"struct IStakeManager.DepositInfo",
		  "name":"info",
		  "type":"tuple"
	   }
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"sender",
		  "type":"address"
	   },
	   {
		  "internalType":"uint192",
		  "name":"key",
		  "type":"uint192"
	   }
	],
	"name":"getNonce",
	"outputs":[
	   {
		  "internalType":"uint256",
		  "name":"nonce",
		  "type":"uint256"
	   }
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"bytes",
		  "name":"initCode",
		  "type":"bytes"
	   }
	],
	"name":"getSenderAddress",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "components":[
			 {
				"internalType":"address",
				"name":"sender",
				"type":"address"
			 },
			 {
				"internalType":"uint256",
				"name":"nonce",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"initCode",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"callData",
				"type":"bytes"
			 },
			 {
				"internalType":"uint256",
				"name":"callGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"verificationGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"preVerificationGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxPriorityFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"paymasterAndData",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"signature",
				"type":"bytes"
			 }
		  ],
		  "internalType":"struct UserOperation",
		  "name":"userOp",
		  "type":"tuple"
	   }
	],
	"name":"getUserOpHash",
	"outputs":[
	   {
		  "internalType":"bytes32",
		  "name":"",
		  "type":"bytes32"
	   }
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "components":[
			 {
				"components":[
				   {
					  "internalType":"address",
					  "name":"sender",
					  "type":"address"
				   },
				   {
					  "internalType":"uint256",
					  "name":"nonce",
					  "type":"uint256"
				   },
				   {
					  "internalType":"bytes",
					  "name":"initCode",
					  "type":"bytes"
				   },
				   {
					  "internalType":"bytes",
					  "name":"callData",
					  "type":"bytes"
				   },
				   {
					  "internalType":"uint256",
					  "name":"callGasLimit",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"verificationGasLimit",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"preVerificationGas",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"maxFeePerGas",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"maxPriorityFeePerGas",
					  "type":"uint256"
				   },
				   {
					  "internalType":"bytes",
					  "name":"paymasterAndData",
					  "type":"bytes"
				   },
				   {
					  "internalType":"bytes",
					  "name":"signature",
					  "type":"bytes"
				   }
				],
				"internalType":"struct UserOperation[]",
				"name":"userOps",
				"type":"tuple[]"
			 },
			 {
				"internalType":"contract IAggregator",
				"name":"aggregator",
				"type":"address"
			 },
			 {
				"internalType":"bytes",
				"name":"signature",
				"type":"bytes"
			 }
		  ],
		  "internalType":"struct IEntryPoint.UserOpsPerAggregator[]",
		  "name":"opsPerAggregator",
		  "type":"tuple[]"
	   },
	   {
		  "internalType":"address payable",
		  "name":"beneficiary",
		  "type":"address"
	   }
	],
	"name":"handleAggregatedOps",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "components":[
			 {
				"internalType":"address",
				"name":"sender",
				"type":"address"
			 },
			 {
				"internalType":"uint256",
				"name":"nonce",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"initCode",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"callData",
				"type":"bytes"
			 },
			 {
				"internalType":"uint256",
				"name":"callGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"verificationGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"preVerificationGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxPriorityFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"paymasterAndData",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"signature",
				"type":"bytes"
			 }
		  ],
		  "internalType":"struct UserOperation[]",
		  "name":"ops",
		  "type":"tuple[]"
	   },
	   {
		  "internalType":"address payable",
		  "name":"beneficiary",
		  "type":"address"
	   }
	],
	"name":"handleOps",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"uint192",
		  "name":"key",
		  "type":"uint192"
	   }
	],
	"name":"incrementNonce",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"bytes",
		  "name":"callData",
		  "type":"bytes"
	   },
	   {
		  "components":[
			 {
				"components":[
				   {
					  "internalType":"address",
					  "name":"sender",
					  "type":"address"
				   },
				   {
					  "internalType":"uint256",
					  "name":"nonce",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"callGasLimit",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"verificationGasLimit",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"preVerificationGas",
					  "type":"uint256"
				   },
				   {
					  "internalType":"address",
					  "name":"paymaster",
					  "type":"address"
				   },
				   {
					  "internalType":"uint256",
					  "name":"maxFeePerGas",
					  "type":"uint256"
				   },
				   {
					  "internalType":"uint256",
					  "name":"maxPriorityFeePerGas",
					  "type":"uint256"
				   }
				],
				"internalType":"struct EntryPoint.MemoryUserOp",
				"name":"mUserOp",
				"type":"tuple"
			 },
			 {
				"internalType":"bytes32",
				"name":"userOpHash",
				"type":"bytes32"
			 },
			 {
				"internalType":"uint256",
				"name":"prefund",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"contextOffset",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"preOpGas",
				"type":"uint256"
			 }
		  ],
		  "internalType":"struct EntryPoint.UserOpInfo",
		  "name":"opInfo",
		  "type":"tuple"
	   },
	   {
		  "internalType":"bytes",
		  "name":"context",
		  "type":"bytes"
	   }
	],
	"name":"innerHandleOp",
	"outputs":[
	   {
		  "internalType":"uint256",
		  "name":"actualGasCost",
		  "type":"uint256"
	   }
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address",
		  "name":"",
		  "type":"address"
	   },
	   {
		  "internalType":"uint192",
		  "name":"",
		  "type":"uint192"
	   }
	],
	"name":"nonceSequenceNumber",
	"outputs":[
	   {
		  "internalType":"uint256",
		  "name":"",
		  "type":"uint256"
	   }
	],
	"stateMutability":"view",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "components":[
			 {
				"internalType":"address",
				"name":"sender",
				"type":"address"
			 },
			 {
				"internalType":"uint256",
				"name":"nonce",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"initCode",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"callData",
				"type":"bytes"
			 },
			 {
				"internalType":"uint256",
				"name":"callGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"verificationGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"preVerificationGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxPriorityFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"paymasterAndData",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"signature",
				"type":"bytes"
			 }
		  ],
		  "internalType":"struct UserOperation",
		  "name":"op",
		  "type":"tuple"
	   },
	   {
		  "internalType":"address",
		  "name":"target",
		  "type":"address"
	   },
	   {
		  "internalType":"bytes",
		  "name":"targetCallData",
		  "type":"bytes"
	   }
	],
	"name":"simulateHandleOp",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "components":[
			 {
				"internalType":"address",
				"name":"sender",
				"type":"address"
			 },
			 {
				"internalType":"uint256",
				"name":"nonce",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"initCode",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"callData",
				"type":"bytes"
			 },
			 {
				"internalType":"uint256",
				"name":"callGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"verificationGasLimit",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"preVerificationGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"uint256",
				"name":"maxPriorityFeePerGas",
				"type":"uint256"
			 },
			 {
				"internalType":"bytes",
				"name":"paymasterAndData",
				"type":"bytes"
			 },
			 {
				"internalType":"bytes",
				"name":"signature",
				"type":"bytes"
			 }
		  ],
		  "internalType":"struct UserOperation",
		  "name":"userOp",
		  "type":"tuple"
	   }
	],
	"name":"simulateValidation",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   
	],
	"name":"unlockStake",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address payable",
		  "name":"withdrawAddress",
		  "type":"address"
	   }
	],
	"name":"withdrawStake",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"inputs":[
	   {
		  "internalType":"address payable",
		  "name":"withdrawAddress",
		  "type":"address"
	   },
	   {
		  "internalType":"uint256",
		  "name":"withdrawAmount",
		  "type":"uint256"
	   }
	],
	"name":"withdrawTo",
	"outputs":[
	   
	],
	"stateMutability":"nonpayable",
	"type":"function"
 },
 {
	"stateMutability":"payable",
	"type":"receive"
 }];
