/**
 * User service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';

//registration

import resendVerifyEmail from '@/graphql/auth/resendVerifyEmail.mutation.graphql';
import verifyEmail from '@/graphql/auth/verifyEmail.mutation.graphql';
import checkWithdrawUnlock from '@/graphql/auth/checkWithdrawUnlock.mutation.graphql';
import checkDeleteUnlock from '@/graphql/auth/checkDeleteUnlock.mutation.graphql';
import unlockWithdraw from '@/graphql/auth/unlockWithdraw.mutation.graphql';

//import registrationStatus from '@/graphql/auth/registrationStatus.mutation.graphql';
//import registrationComplete from '@/graphql/auth/registrationComplete.mutation.graphql';
import confirmWallet from '@/graphql/auth/confirmWallet.mutation.graphql';
import confirmMigrationWallet from '@/graphql/auth/confirmMigrationWallet.mutation.graphql';
import migrationStart from '@/graphql/auth/migrationStart.mutation.graphql';
import migrationComplete from '@/graphql/auth/migrationComplete.mutation.graphql';
import migrationUpdatePositions from '@/graphql/auth/migrationUpdatePositions.mutation.graphql';
import startKYC from '@/graphql/auth/startKYC.mutation.graphql';
import createAbstractionAccount from '@/graphql/user/createAbstractionAccount.mutation.graphql';


import claimAirdrop from '@/graphql/auth/claimAirdrop.mutation.graphql';
import reviewResponse from '@/graphql/user/reviewResponse.mutation.graphql';

import type { resendVerifyEmailVariables, resendVerifyEmail as resendVerifyEmailResponse } from '@/graphql/auth/types/resendVerifyEmail';
import type { verifyEmailVariables, verifyEmail as verifyEmailResponse } from '@/graphql/auth/types/verifyEmail';
import type { checkWithdrawUnlockVariables, checkWithdrawUnlock as checkWithdrawUnlockResponse } from '@/graphql/auth/types/checkWithdrawUnlock';
import type { checkDeleteUnlockVariables, checkDeleteUnlock as checkDeleteUnlockResponse } from '@/graphql/auth/types/checkDeleteUnlock';
import type { unlockWithdrawVariables, unlockWithdraw as unlockWithdrawResponse } from '@/graphql/auth/types/unlockWithdraw';

//import type { registrationStatusVariables, registrationStatus as registrationStatusResponse } from '@/graphql/auth/types/registrationStatus';
//import {registrationCompleteVariables,registrationComplete as registrationCompleteResponse} from '@/graphql/auth/types/registrationComplete';
import type { confirmWalletVariables, confirmWallet as confirmWalletResponse } from '@/graphql/auth/types/confirmWallet';
import type { confirmMigrationWalletVariables, confirmMigrationWallet as confirmMigrationWalletResponse } from '@/graphql/auth/types/confirmMigrationWallet';
import type { migrationStartVariables, migrationStart as migrationStartResponse } from '@/graphql/auth/types/migrationStart';
import type { migrationCompleteVariables, migrationComplete as migrationCompleteResponse } from '@/graphql/auth/types/migrationComplete';
import type { migrationUpdatePositionsVariables, migrationUpdatePositions as migrationUpdatePositionsResponse } from '@/graphql/auth/types/migrationUpdatePositions';

import type { startKYCVariables, startKYC as startKYCResponse } from '@/graphql/auth/types/startKYC';
import type { createAbstractionAccountVariables, createAbstractionAccount as createAbstractionAccountResponse } from '@/graphql/user/types/createAbstractionAccount';


import type { claimAirdropVariables, claimAirdrop as claimAirdropResponse } from '@/graphql/auth/types/claimAirdrop';
import type { reviewResponseVariables, reviewResponse as reviewResponseResponse } from '@/graphql/user/types/reviewResponse';


import updateUser from '@/graphql/user/updateUser.mutation.graphql';
import updateUserPayload from '@/graphql/user/updateUserPayload.mutation.graphql';
import updateEmailSubscriptions from '@/graphql/user/updateEmailSubscriptions.mutation.graphql';
import sendAnalytics from '@/graphql/user/sendAnalytics.mutation.graphql';

import updateUserNotifications from '@/graphql/user/updateUserNotifications.mutation.graphql';

import loginWallet from '@/graphql/auth/loginWallet.query.graphql';
import loginImpersonate from '@/graphql/auth/loginImpersonate.query.graphql';
import loginToken from '@/graphql/auth/loginToken.query.graphql';
import getTokenUserData from '@/graphql/auth/getTokenUserData.query.graphql';
import claimAirdropSignup from '@/graphql/auth/claimAirdropSignup.mutation.graphql';
import getUserLastDeposit from '@/graphql/auth/getUserLastDeposit.query.graphql';

import logout from '@/graphql/auth/logout.mutation.graphql';
import checkGeoblock from '@/graphql/auth/checkGeoblock.query.graphql';
import refreshToken from '@/graphql/auth/refreshToken.mutation.graphql';
import resetPassword from '@/graphql/auth/resetPassword.mutation.graphql';
import sendPasswordResetEmail from '@/graphql/auth/sendPasswordResetEmail.mutation.graphql';
import deleteUser from '@/graphql/user/deleteUser.mutation.graphql';
import userAccountDelete from '@/graphql/user/userAccountDelete.mutation.graphql';
import blockUser from '@/graphql/user/blockUser.mutation.graphql';
import unblockUser from '@/graphql/user/unblockUser.mutation.graphql';
import validateRegistrationField from '@/graphql/auth/validateRegistrationField.mutation.graphql';
import getUniversityData from '@/graphql/user/getUniversityData.query.graphql';
import getMigrationData from '@/graphql/user/getMigrationData.query.graphql';
import getChainMigrationData from '@/graphql/user/getChainMigrationData.query.graphql';
import migratePosition from '@/graphql/user/migratePosition.query.graphql';

import getUserData from '@/graphql/user/getUserData.query.graphql';

import getAirdropStatus from '@/graphql/airdrop_list/getAirdropStatus.query.graphql';
import getStakes from '@/graphql/user/getStakes.query.graphql';
import type { getStakesVariables, getStakes as getStakesResposne } from '@/graphql/user/types/getStakes';
import type { getDailyStakesVariables, getDailyStakes as getDailyStakesResposne } from '@/graphql/user/types/getDailyStakes';

import getDailyStakes from '@/graphql/user/getDailyStakes.query.graphql';

import getWalletFundsAddresses from '@/graphql/user/getWalletFundsAddresses.query.graphql';
import type { getWalletFundsAddressesVariables } from '@/graphql/user/types/getWalletFundsAddresses';

import createWalletFundsAddress from '@/graphql/user/createWalletFundsAddress.mutation.graphql';
import type { createWalletFundsAddressVariables } from '@/graphql/user/types/createWalletFundsAddress';

import deleteWalletFundsAddress from '@/graphql/user/deleteWalletFundsAddress.mutation.graphql';
import type { deleteWalletFundsAddressVariables } from '@/graphql/user/types/deleteWalletFundsAddress';

import updateWalletFundsAddress from '@/graphql/user/updateWalletFundsAddress.mutation.graphql';
import type { updateWalletFundsAddressVariables } from '@/graphql/user/types/updateWalletFundsAddress';

import getDepositAddress from '@/graphql/user/getDepositAddress.query.graphql';
import getDepositReference from '@/graphql/user/getDepositReference.query.graphql';
import createDepositAddress from '@/graphql/user/createDepositAddress.mutation.graphql';

import type { updateUserVariables, updateUser as updateUserResposne } from '@/graphql/user/types/updateUser';
import type { updateUserNotificationsVariables, updateUserNotifications as updateUserNotificationsResponse } from '@/graphql/user/types/updateUserNotifications';
import type { updateUserPayloadVariables, updateUserPayload as updateUserPayloadResposne } from '@/graphql/user/types/updateUserPayload';
import type { sendAnalyticsVariables, sendAnalytics as sendAnalyticsResposne } from '@/graphql/user/types/sendAnalytics';

import type {
	updateEmailSubscriptionsVariables,
	updateEmailSubscriptions as updateEmailSubscriptionsResponse
} from '@/graphql/user/types/updateEmailSubscriptions';
import type { loginToken as loginTokenResponse } from '@/graphql/auth/types/loginToken';
import type { getTokenUserData as getTokenUserDataResponse } from '@/graphql/auth/types/getTokenUserData';
import type { claimAirdropSignup as claimAirdropSignupResponse } from '@/graphql/auth/types/claimAirdropSignup';
import type { getUserLastDepositVariables, getUserLastDeposit as getUserLastDepositResponse } from '@/graphql/auth/types/getUserLastDeposit';

import type { loginWallet as loginWalletResponse, loginWalletVariables } from '@/graphql/auth/types/loginWallet';
import type {
	loginImpersonate as loginImpersonateResponse,
	loginImpersonateVariables
} from '@/graphql/auth/types/loginImpersonate';
import type { checkGeoblock as checkGeoblockResponse, checkGeoblockVariables } from '@/graphql/auth/types/checkGeoblock';

import type { refreshTokenVariables, refreshToken as refreshTokenResponse } from '@/graphql/auth/types/refreshToken';
import type { logoutVariables, logout as logoutResponse } from '@/graphql/auth/types/logout';
import type { resetPasswordVariables, resetPassword as resetPasswordResponse } from '@/graphql/auth/types/resetPassword';
import type { deleteUserVariables, deleteUser as deleteUserResponse } from '@/graphql/user/types/deleteUser';
import type { userAccountDeleteVariables, userAccountDelete as userAccountDeleteResponse } from '@/graphql/user/types/userAccountDelete';

import type { blockUserVariables, blockUser as blockUserResponse } from '@/graphql/user/types/blockUser';
import type { unblockUserVariables, unblockUser as unblockUserResponse } from '@/graphql/user/types/unblockUser';
import type {
	sendPasswordResetEmailVariables,
	sendPasswordResetEmail as sendPasswordResetEmailResponse
} from '@/graphql/auth/types/sendPasswordResetEmail';
import type {
	validateRegistrationFieldVariables,
	validateRegistrationField as validateRegistrationFieldResponse
} from '@/graphql/auth/types/validateRegistrationField';
import type { getUniversityDataVariables, getUniversityData as getUniversityDataResponse } from '@/graphql/user/types/getUniversityData';
import type { getMigrationDataVariables, getMigrationData as getMigrationDataResponse } from '@/graphql/user/types/getMigrationData';
import type { getChainMigrationDataVariables, getChainMigrationData as getChainMigrationDataResponse } from '@/graphql/user/types/getChainMigrationData';
import type { migratePositionVariables, migratePosition as migratePositionResponse } from '@/graphql/user/types/migratePosition';



import type { getUserDataVariables, getUserData as getUserDataResponse } from '@/graphql/user/types/getUserData';

import type { getAirdropStatusVariables, getAirdropStatus as getAirdropStatusResponse } from '@/graphql/airdrop_list/types/getAirdropStatus';

import type { updateUserStatus as updateUserStatusResult } from '@/graphql/user/types/updateUserStatus';
import updateUserStatus from '@/graphql/user/updateUserStatus.subscription.graphql';
let SubscriptionList: any[] = [];
let SubscriptionListStakes: any[] = [];
import type { updateDailyStakes as updateDailyStakesResult } from '@/graphql/user/types/updateDailyStakes';
import updateDailyStakes from '@/graphql/user/updateDailyStakes.subscription.graphql';
import { accessTokenName, refreshTokenName } from '../helpers/utils';
import Bowser from "bowser";
import type { FetchResult, throwServerError } from '@apollo/client/core';
import util from './shared'
import { ungzip } from 'node-gzip';


export const UserService = {
	resendVerifyEmail(variables: resendVerifyEmailVariables) {
		return  util.formatReturnData(apollo.mutate<resendVerifyEmailResponse>({
			mutation: resendVerifyEmail,
			variables
		}));
	},

	async verifyEmail(variables: verifyEmailVariables) {
		const data:any = await  util.formatReturnData(apollo.mutate<verifyEmailResponse>({
			mutation: verifyEmail,
			variables
		}));
		
		const data_compressed = data.data.verifyEmail;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.verifyEmail = JSON.parse(data_decompressed.toString());

		return data;
	},
	checkWithdrawUnlock(variables: checkWithdrawUnlockVariables) {
		return  util.formatReturnData(apollo.mutate<checkWithdrawUnlockResponse>({
			mutation: checkWithdrawUnlock,
			variables
		}));
	},
	checkDeleteUnlock(variables: checkDeleteUnlockVariables) {
		return  util.formatReturnData(apollo.mutate<checkDeleteUnlockResponse>({
			mutation: checkDeleteUnlock,
			variables
		}));
	},	
	

	unlockWithdraw(variables: unlockWithdrawVariables) {
		return  util.formatReturnData(apollo.mutate<unlockWithdrawResponse>({
			mutation: unlockWithdraw,
			variables
		}));
	},

	startKYC(variables: startKYCVariables) {
		return  util.formatReturnData(apollo.mutate<startKYCResponse>({
			mutation: startKYC,
			variables
		}));
	},

	createAbstractionAccount(variables: createAbstractionAccountVariables) {
		return apollo.mutate<createAbstractionAccountResponse>({
			mutation: createAbstractionAccount,
			variables
		});
	},

	async claimAirdrop(variables: claimAirdropVariables) {
		const data:any = await  util.formatReturnData(apollo.mutate<claimAirdropResponse>({
			mutation: claimAirdrop,
			variables
		}));

		const data_compressed = data.data.claimAirdrop;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.claimAirdrop = JSON.parse(data_decompressed.toString());

		return data;
	},

	reviewResponse(variables: reviewResponseVariables) {
		return  util.formatReturnData(apollo.mutate<reviewResponseResponse>({
			mutation: reviewResponse,
			variables
		}));
	},

	async confirmWallet(variables: confirmWalletVariables) {
		const data:any = await   util.formatReturnData(apollo.mutate<confirmWalletResponse>({
			mutation: confirmWallet,
			variables
		}));

		const data_compressed = data.data.confirmWallet;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.confirmWallet = JSON.parse(data_decompressed.toString());

		return data;

	},
	async confirmMigrationWallet(variables: confirmMigrationWalletVariables) {
		const data:any = await   util.formatReturnData(apollo.mutate<confirmMigrationWalletResponse>({
			mutation: confirmMigrationWallet,
			variables
		}));

				
		const data_compressed = data.data.confirmMigrationWallet;

		const buf = Buffer.from(data_compressed, 'base64');
 
		const data_decompressed = await ungzip(buf);

		data.data.confirmMigrationWallet = JSON.parse(data_decompressed.toString());

		return data;		
	},

	async migrationStart(variables: migrationStartVariables) {
		const data = await  util.formatReturnData(apollo.mutate<migrationStartResponse>({
			mutation: migrationStart,
			variables
		}));


		const data_compressed = data.data.migrationStart;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.migrationStart = JSON.parse(data_decompressed.toString());

		return data;
	},

	async migrationComplete(variables: migrationCompleteVariables) {
	    const data = await  util.formatReturnData(apollo.mutate<migrationCompleteResponse>({
			mutation: migrationComplete,
			variables
		}));

		const data_compressed = data.data.migrationComplete;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.migrationComplete = JSON.parse(data_decompressed.toString());

		return data;		
	},

	migrationUpdatePositions(variables: migrationUpdatePositionsVariables) {
		return  util.formatReturnData(apollo.mutate<migrationUpdatePositionsResponse>({
			mutation: migrationUpdatePositions,
			variables
		}));
	},
	
	updateUser(variables: updateUserVariables) {
		return  util.formatReturnData(apollo.mutate<updateUserResposne>({
			mutation: updateUser,
			variables,
			errorPolicy: 'all'
		}));
	},
	updateUserPayload(variables: updateUserPayloadVariables) {
		return  util.formatReturnData(apollo.mutate<updateUserPayloadResposne>({
			mutation: updateUserPayload,
			variables
		}));
	},
	sendAnalytics(variables: sendAnalyticsVariables) {
		try {

		const accessToken = localStorage.getItem(accessTokenName);
		const refreshToken = localStorage.getItem(refreshTokenName);
		// skip if logged out
		if (!accessToken || !refreshToken) {
			return;
		}

		const affiliate_id = localStorage.getItem('affiliate_id');
		const at_gd = localStorage.getItem('at_gd');

		const ref = localStorage.getItem('ref');

        if (affiliate_id || at_gd) {
			const affiliate_campaign = localStorage.getItem('affiliate_campaign') || '';
			const affiliate_referrer = localStorage.getItem('affiliate_referrer') || '';
			const affiliate_utm_source = localStorage.getItem('affiliate_utm_source') || '';
			const affiliate_utm_medium = localStorage.getItem('affiliate_utm_medium') || '';
			const affiliate_utm_campaign = localStorage.getItem('affiliate_utm_campaign') || '';
			const affiliate_path = localStorage.getItem('affiliate_path') || '';
			const at_gd = localStorage.getItem('at_gd') || '';
			
			const affiliate_language = localStorage.getItem("affiliate_language");
			let affiliate_browser_language = localStorage.getItem("affiliate_browser_language");
	
			if (!affiliate_browser_language) {
				const browser_language = navigator.language || (navigator as any).userLanguage; //no ?s necessary
				if (browser_language) {
					affiliate_browser_language = browser_language
				}
			}

			if (!variables.data) {
				variables.data = {};
			}

			variables.data['affiliate_data'] = {affiliate_id, affiliate_campaign, affiliate_referrer, affiliate_utm_source, affiliate_utm_medium, affiliate_utm_campaign, affiliate_path, at_gd, affiliate_language, affiliate_browser_language }
				
        }

		if (ref) {
			const source_id = localStorage.getItem('source_id');
			const affiliate_referrer = localStorage.removeItem('affiliate_referrer');
			variables.data['ref_data'] = {ref, source_id, affiliate_referrer, landing_page: window.location.href, user_agent: window.navigator.userAgent }
		}
		let data = null;
		try {
			if (!variables.data) {
				variables.data = {};
			}
			const bowser = Bowser.getParser(window.navigator.userAgent);

			variables.data['OS'] = bowser.getOSName();
			variables.data['Platform'] = 'Web';

			data =  util.formatReturnData(apollo.mutate<sendAnalyticsResposne>({
				mutation: sendAnalytics,
				variables
			})).catch((err) => {
				console.log('error in mutate sendAnalytics ' + err.toString());
				return null
			});
		} catch(err) {
			return null

		}

		if (affiliate_id || at_gd) {
			localStorage.removeItem('affiliate_id');
			localStorage.removeItem('affiliate_campaign');
			localStorage.removeItem('affiliate_referrer');
			localStorage.removeItem('affiliate_utm_source');
			localStorage.removeItem('affiliate_utm_medium');
			localStorage.removeItem('affiliate_utm_campaign');
			localStorage.removeItem('affiliate_language');
			localStorage.removeItem('affiliate_browser_language');
			localStorage.removeItem('affiliate_path');
			localStorage.removeItem('at_gd')
		}

		if (ref) {
			localStorage.removeItem('ref');
			localStorage.removeItem('source_id');
			localStorage.removeItem('affiliate_referrer');

		}
	} catch (err: any) {
		console.log('error in sendAnalytics:' + err.toString() )
	}
	},

	updateEmailSubscriptions(variables: updateEmailSubscriptionsVariables) {
		return  util.formatReturnData(apollo.mutate<updateEmailSubscriptionsResponse>({
			mutation: updateEmailSubscriptions,
			variables
		}));
	},

	async loginWithToken() {
		const variables = {
			locale_country: ''
		};
		const browserLocale =  (navigator.languages && navigator.languages[0]) || navigator.language || ''
		if (browserLocale && browserLocale.indexOf('-') > 0) {
			variables.locale_country = browserLocale.split('-')[1]
		}
		const data:any = await  util.formatReturnData(apollo.query<loginTokenResponse>({
			query: loginToken,
			variables
		}));

		const data_compressed = data.data.loginToken;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.loginToken = JSON.parse(data_decompressed.toString());

		return data;
	},
	async getTokenUserData() {
		const variables = {
			locale_country: ''
		};
		const browserLocale =  (navigator.languages && navigator.languages[0]) || navigator.language || ''
		if (browserLocale && browserLocale.indexOf('-') > 0) {
			variables.locale_country = browserLocale.split('-')[1]
		}
		const data:any = await  util.formatReturnData(apollo.query<getTokenUserDataResponse>({
			query: getTokenUserData,
			variables
		}));

		const data_compressed = data.data.getTokenUserData;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.getTokenUserData = JSON.parse(data_decompressed.toString());

		return data;
	},
	async claimAirdropSignup() {


		const data:any = await  util.formatReturnData(apollo.mutate<claimAirdropSignupResponse>({
			mutation: claimAirdropSignup,
		}));

		const data_compressed = data.data.claimAirdropSignup;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.claimAirdropSignup = JSON.parse(data_decompressed.toString());

		return data;
	},	
	getAirdropStatus(variables: getAirdropStatusVariables) {
		return  util.formatReturnData(apollo.query<getAirdropStatusResponse>({
			query: getAirdropStatus,
			variables
		}));
	},
	async loginWallet(variables: loginWalletVariables) {

		const browserLocale =  (navigator.languages && navigator.languages[0]) || navigator.language || ''
		if (browserLocale && browserLocale.indexOf('-') > 0) {
			variables.locale_country = browserLocale.split('-')[1]
		}
		const data:any = await  util.formatReturnData(apollo.query<loginWalletResponse>({
			query: loginWallet,
			variables
		}));

		const data_compressed = data.data.loginWallet;
		

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);


		data.data.loginWallet = JSON.parse(data_decompressed.toString());

		return data;

	},
	async loginImpersonate(variables: loginImpersonateVariables) {
		const data:any = await  util.formatReturnData(apollo.query<loginImpersonateResponse>({
			query: loginImpersonate,
			variables
		}));
		
		const data_compressed = data.data.loginImpersonate;

		const buf = Buffer.from(data_compressed, 'base64');

		const data_decompressed = await ungzip(buf);

		data.data.loginImpersonate = JSON.parse(data_decompressed.toString());

		return data;

	},
	checkGeoblock(variables: checkGeoblockVariables) {
		return  util.formatReturnData(apollo.query<checkGeoblockResponse>({
			query: checkGeoblock,
			variables
		}));
	},
	refreshToken(variables: refreshTokenVariables) {
		return  util.formatReturnData(apollo.mutate<refreshTokenResponse>({
			mutation: refreshToken,
			variables
		}));
	},
	logout(variables: logoutVariables) {
		return  util.formatReturnData(apollo.mutate<logoutResponse>({
			mutation: logout,
			variables
		}));
	},
	resetPassword(variables: resetPasswordVariables) {
		return  util.formatReturnData(apollo.mutate<resetPasswordResponse>({
			mutation: resetPassword,
			variables
		}));
	},
	deleteUser(variables: deleteUserVariables) {
		return  util.formatReturnData(apollo.mutate<deleteUserResponse>({
			mutation: deleteUser,
			variables
		}));
	},
	userAccountDelete(variables: userAccountDeleteVariables) {
		return  util.formatReturnData(apollo.mutate<userAccountDeleteResponse>({
			mutation: userAccountDelete,
			variables
		}));
	},
	blockUser(variables: blockUserVariables) {
		return  util.formatReturnData(apollo.mutate<blockUserResponse>({
			mutation: blockUser,
			variables
		}));
	},
	unblockUser(variables: unblockUserVariables) {
		return  util.formatReturnData(apollo.mutate<unblockUserResponse>({
			mutation: unblockUser,
			variables
		}));
	},

	sendPasswordResetEmail(variables: sendPasswordResetEmailVariables) {
		return  util.formatReturnData(apollo.mutate<sendPasswordResetEmailResponse>({
			mutation: sendPasswordResetEmail,
			variables
		}));
	},
	validateRegistrationField(variables: validateRegistrationFieldVariables) {
		return  util.formatReturnData(apollo.mutate<validateRegistrationFieldResponse>({
			mutation: validateRegistrationField,
			variables
		}));
	},
	getUniversityData(variables: getUniversityDataVariables) {
		return  util.formatReturnData(apollo.query<getUniversityDataResponse>({
			query: getUniversityData,
			variables
		}));
	},
	getMigrationData(variables: getMigrationDataVariables) {
		return  util.formatReturnData(apollo.query<getMigrationDataResponse>({
			query: getMigrationData,
			variables
		}));
	},	
	getChainMigrationData(variables: getChainMigrationDataVariables) {
		return apollo.query<getChainMigrationDataResponse>({
			query: getChainMigrationData,
			variables
		});
	},	

	migratePosition(variables: migratePositionVariables) {
		return apollo.query<migratePositionResponse>({
			query: migratePosition,
			variables
		});
	},	
	
	
	
	getUserData(variables: getUserDataVariables) {
		return  util.formatReturnData(apollo.query<getUserDataResponse>({
			query: getUserData,
			variables
		}));
	},
	getUserLastDeposit(variables: getUserLastDepositVariables) {
		return  util.formatReturnData(apollo.query<getUserLastDepositResponse>({
			query: getUserLastDeposit,
			variables
		}));
	},

	subscribeUpdateUserStatus(user_id: string, callback: (data: FetchResult<updateUserStatusResult>) => void) {
		const existingSubscription = SubscriptionList.find(sub => sub.id === user_id);
		if (existingSubscription) {
			this.unsubscribeUpdateUserStatus(user_id, existingSubscription.sub);
		}

		const subscription = apollo
			.subscribe({
				query: updateUserStatus,
				variables: {
					event: 'USER_UPDATE_' + user_id
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeUpdateUserStatus', err)
				}
			});

		SubscriptionList.push({ id: user_id, sub: subscription });
		return subscription;
	},
	unsubscribeUpdateUserStatus(user_id: string, subscription: any) {
		if (subscription) {
			subscription.unsubscribe();
			SubscriptionList = SubscriptionList.filter(sub => sub.id !== user_id);
		}
	},
	updateUserNotifications(variables: updateUserNotificationsVariables) {
		return  util.formatReturnData(apollo.mutate<updateUserNotificationsResponse>({
			mutation: updateUserNotifications,
			variables
		}));
	},
	getStakes(variables: getStakesVariables) {
		return  util.formatReturnData(apollo.query({
			query: getStakes,
			variables
		}));
	},
	getDailyStakes(variables: getDailyStakesVariables) {
		return  util.formatReturnData(apollo.query({
			query: getDailyStakes,
			variables
		}));
	},
	getWalletFundsAddresses(variables: getWalletFundsAddressesVariables) {
		return  util.formatReturnData(apollo.query({
			query: getWalletFundsAddresses,
			variables
		}));
	},
	createWalletFundsAddress(variables: createWalletFundsAddressVariables) {
		return  util.formatReturnData(apollo.mutate({
			mutation: createWalletFundsAddress,
			variables
		}));
	},
	deleteWalletFundsAddress(variables: deleteWalletFundsAddressVariables) {
		return  util.formatReturnData(apollo.mutate({
			mutation: deleteWalletFundsAddress,
			variables
		}));
	},
	updateWalletFundsAddress(variables: updateWalletFundsAddressVariables) {
		return  util.formatReturnData(apollo.mutate({
			mutation: updateWalletFundsAddress,
			variables
		}))
	},
	getDepositAddress() {
		return  util.formatReturnData(apollo.query({
			query: getDepositAddress,
			variables: {}
		}));
	},
	getDepositReference() {
		return  util.formatReturnData(apollo.query({
			query: getDepositReference,
			variables: {}
		}));
	},
	createDepositAddress() {
		return  util.formatReturnData(apollo.mutate({
			mutation: createDepositAddress,
			variables: {}
		}));
	},
	subscribeUpdateDailyStakes(eth_address: string, callback: (data: FetchResult<updateDailyStakesResult>) => void) {
		const existingSubscription = SubscriptionListStakes.find(sub => sub.id === eth_address);

		if (existingSubscription) {
			this.unsubscribeUpdateDailyStakes(eth_address, existingSubscription.sub);
		}

		const subscription = apollo
			.subscribe({
				query: updateDailyStakes,
				variables: {
					event: 'STAKE_EVENT_' + eth_address
				}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},error(err) {
					//console.log('error in subscribeUpdateDailyStakes', err)
				}
			});

			SubscriptionListStakes.push({ id: eth_address, sub: subscription });
		return subscription;
	},
	unsubscribeUpdateDailyStakes(eth_address: string, subscription: any) {
		if (subscription) {
			subscription.unsubscribe();
			SubscriptionListStakes = SubscriptionListStakes.filter(sub => sub.id !== eth_address);
		}
	},
};
