/**
 * Trades service which wraps all GraphQL queries for this model
 */
import apollo from '../apollo';
import enableMarket from '@/graphql/market/enableMarket.mutation.graphql';
import enableMainchainMarket from '@/graphql/market/enableMainchainMarket.mutation.graphql';
import pauseMarket from '@/graphql/market/pauseMarket.mutation.graphql';
import setPurchaseDate from '@/graphql/transaction/setPurchaseDate.mutation.graphql';
import updateMarketData from '@/graphql/market/updateMarketData.mutation.graphql';

import createMarket from '@/graphql/market/createMarket.mutation.graphql';
import deleteMarket from '@/graphql/market/deleteMarket.mutation.graphql';
import delistMarket from '@/graphql/market/delistMarket.mutation.graphql';
import createMarketSplit from '@/graphql/market/createMarketSplit.mutation.graphql';
import editMarketSplit from '@/graphql/market/editMarketSplit.mutation.graphql';
import deleteMarketSplit from '@/graphql/market/deleteMarketSplit.mutation.graphql';
import updateFetchingWorker from '@/graphql/maintenance/updateFetchingWorker.mutation.graphql';
import updateProcessingWorker from '@/graphql/maintenance/updateProcessingWorker.mutation.graphql';
import updatePositionsWorker from '@/graphql/maintenance/updatePositionsWorker.mutation.graphql';

import getTemplates from '@/graphql/email_template/getTemplates.query.graphql';
import getUsers from '@/graphql/user/getUsers.query.graphql';
import getPositions from '@/graphql/positions/getPositions.query.graphql';
import lockPosition from '@/graphql/positions/lockPosition.mutation.graphql';
import approvePosition from '@/graphql/positions/approvePosition.mutation.graphql';
import getPurchaseTransaction from '@/graphql/transaction/getPurchaseTransaction.query.graphql';
import getWithdrawTransaction from '@/graphql/transaction/getWithdrawTransaction.query.graphql';
import getDepositTransaction from '@/graphql/transaction/getDepositTransaction.query.graphql';
import getAirdropClaims from '@/graphql/airdrop_list/getAirdropClaims.query.graphql';
import getAirdropBonus from '@/graphql/airdrop_list/getAirdropBonus.query.graphql';
import getAirdropClaimDetails from '@/graphql/airdrop_list/getAirdropClaimDetails.query.graphql';
import getTemplate from '@/graphql/email_template/getTemplate.query.graphql';
import getTemplateStats from '@/graphql/email_template/getTemplateStats.query.graphql';
import getInfoStream from '@/graphql/maintenance/getInfoStream.query.graphql';

import getEmailSendList from '@/graphql/email_template/getEmailSendList.query.graphql';
import getEmailSendSummary from '@/graphql/email_template/getEmailSendSummary.query.graphql';

import deleteTemplate from '@/graphql/email_template/deleteTemplate.mutation.graphql';
import testTemplate from '@/graphql/email_template/testTemplate.mutation.graphql';
import sendTemplate from '@/graphql/email_template/sendTemplate.mutation.graphql';
import cancelSendTemplate from '@/graphql/email_template/cancelSendTemplate.mutation.graphql';

import createNotificationTemplate from '@/graphql/email_template/createNotificationTemplate.mutation.graphql';
import deleteNotificationTemplate from '@/graphql/email_template/deleteNotificationTemplate.mutation.graphql';
import sendNotificationTemplate from '@/graphql/email_template/sendNotificationTemplate.mutation.graphql';
import testNotificationTemplate from '@/graphql/email_template/testNotificationTemplate.mutation.graphql';
import updateNotificationTemplate from '@/graphql/email_template/updateNotificationTemplate.mutation.graphql';
import getNotificationTemplate from '@/graphql/email_template/getNotificationTemplate.query.graphql';
import getNotificationTemplates from '@/graphql/email_template/getNotificationTemplates.query.graphql';
import getNotificationTemplateStats from '@/graphql/email_template/getNotificationTemplateStats.query.graphql';

import clearTemplateUsers from '@/graphql/email_template/clearTemplateUsers.mutation.graphql';
import updateTemplateUsers from '@/graphql/email_template/updateTemplateUsers.mutation.graphql';

import getEmails from '@/graphql/email_list/getEmails.query.graphql';
import deleteEmail from '@/graphql/email_list/deleteEmail.mutation.graphql';
import insertEmails from '@/graphql/email_list/insertEmails.mutation.graphql';
import updateEmail from '@/graphql/email_list/updateEmail.mutation.graphql';

import getAirdropList from '@/graphql/airdrop_list/getAirdropList.query.graphql';
import getInvite from '@/graphql/airdrop_list/getInvite.query.graphql';
import getVerification from '@/graphql/airdrop_list/getVerification.query.graphql';
import getFaceImage from '@/graphql/airdrop_list/getFaceImage.query.graphql';
import getBlockList from '@/graphql/airdrop_list/getBlockList.query.graphql';
import updateAirdropList from '@/graphql/airdrop_list/updateAirdropList.mutation.graphql';
import updateInvite from '@/graphql/airdrop_list/updateInvite.mutation.graphql';
import verificationImageRotate from '@/graphql/airdrop_list/verificationImageRotate.mutation.graphql';
import verificationUpdateStatus from '@/graphql/airdrop_list/verificationUpdateStatus.mutation.graphql';
import verificationUpdate from '@/graphql/airdrop_list/verificationUpdate.mutation.graphql';
import updateFaceMatch from '@/graphql/airdrop_list/updateFaceMatch.mutation.graphql';
import updateAirdropLink from '@/graphql/airdrop_list/updateAirdropLink.mutation.graphql';
import addAirdropBonus from '@/graphql/airdrop_list/addAirdropBonus.mutation.graphql';

import updateApplicationLive from '@/graphql/maintenance/updateApplicationLive.mutation.graphql';
import updateGlobalMessage from '@/graphql/maintenance/updateGlobalMessage.mutation.graphql';
import updateConfig from '@/graphql/maintenance/updateMaintenance.subscription.graphql';
import infoStreamUpdates from '@/graphql/maintenance/infoStreamUpdates.subscription.graphql';

import getLastData from '@/graphql/user/getLastData.query.graphql';
import getAdminInfo from '@/graphql/user/getAdminInfo.query.graphql';
import getWorkersStatus from '@/graphql/user/getWorkersStatus.query.graphql';

import axios from 'axios';

import type { enableMarketVariables } from '@/graphql/market/types/enableMarket';
import type { enableMainchainMarketVariables } from '@/graphql/market/types/enableMainchainMarket';
import type { pauseMarketVariables } from '@/graphql/market/types/pauseMarket';
import type { setPurchaseDateVariables } from '@/graphql/transaction/types/setPurchaseDate';

import type { updateMarketDataVariables } from '@/graphql/market/types/updateMarketData';

import type { createMarket as createMarketResponse, createMarketVariables } from '@/graphql/market/types/createMarket';
import type { deleteMarket as deleteMarketResponse, deleteMarketVariables } from '@/graphql/market/types/deleteMarket';
import type { delistMarket as delistMarketResponse, delistMarketVariables } from '@/graphql/market/types/delistMarket';
import type { getVerification as getVerificationResponse, getVerificationVariables } from '@/graphql/airdrop_list/types/getVerification';


import type { createMarketSplit as createMarketSplitResponse, createMarketSplitVariables } from '@/graphql/market/types/createMarketSplit';
import type { editMarketSplit as editMarketSplitResponse, editMarketSplitVariables } from '@/graphql/market/types/editMarketSplit';
import type { deleteMarketSplit as deleteMarketSplitResponse, deleteMarketSplitVariables } from '@/graphql/market/types/deleteMarketSplit';
import type {
	updateFetchingWorker as updateFetchingWorkerResponse,
	updateFetchingWorkerVariables
} from '@/graphql/maintenance/types/updateFetchingWorker';
import type {
	updateProcessingWorker as updateProcessingWorkerResponse,
	updateProcessingWorkerVariables
} from '@/graphql/maintenance/types/updateProcessingWorker';
import type { updatePositionsWorker as updatePositionsWorkerRespone, updatePositionsWorkerVariables } from '@/graphql/maintenance/types/updatePositionsWorker';

import type { deleteTemplate as deleteTampleteResponse, deleteTemplateVariables } from '@/graphql/email_template/types/deleteTemplate';
import type { testTemplate as testTemplateResponse, testTemplateVariables } from '@/graphql/email_template/types/testTemplate';
import type { sendTemplate as sendTemplateResponse, sendTemplateVariables } from '@/graphql/email_template/types/sendTemplate';
import type {
	clearTemplateUsers as clearTemplateUsersResponse,
	clearTemplateUsersVariables
} from '@/graphql/email_template/types/clearTemplateUsers';
import type {
	updateTemplateUsers as updateTemplateUsersResponse,
	updateTemplateUsersVariables
} from '@/graphql/email_template/types/updateTemplateUsers';
import type { updateEmail as updateEmailResponse, updateEmailVariables } from '@/graphql/email_list/types/updateEmail';
import type { deleteEmail as deleteEmailResponse, deleteEmailVariables } from '@/graphql/email_list/types/deleteEmail';
import type {
	updateApplicationLive as updateApplicationLiveResponse,
	updateApplicationLiveVariables
} from '@/graphql/maintenance/types/updateApplicationLive';
import type {
	updateGlobalMessage as updateGlobalMessageResponse,
	updateGlobalMessageVariables
} from '@/graphql/maintenance/types/updateGlobalMessage';
import type { getTemplatesVariables, getTemplates as getTemplatesResponse } from '@/graphql/email_template/types/getTemplates';
import type { getUsersVariables, getUsers as getUsersResponse } from '@/graphql/user/types/getUsers';
import type { getPositionsVariables, getPositions as getPositionsResponse } from '@/graphql/positions/types/getPositions';
import type { lockPositionVariables, lockPosition as lockPositionResponse } from '@/graphql/positions/types/lockPosition';
import type { approvePositionVariables, approvePosition as approvePositionResponse } from '@/graphql/positions/types/approvePosition';
import type {
	getPurchaseTransactionVariables,
	getPurchaseTransaction as getPurchaseTransactionResponse
} from '@/graphql/transaction/types/getPurchaseTransaction';

import type {
	getWithdrawTransactionVariables,
	getWithdrawTransaction as getWithdrawTransactionResponse
} from '@/graphql/transaction/types/getWithdrawTransaction';
import type {
	getDepositTransactionVariables,
	getDepositTransaction as getDepositTransactionResponse
} from '@/graphql/transaction/types/getDepositTransaction';

import type {
	getAirdropClaimsVariables,
	getAirdropClaims as getAirdropClaimsResponse
} from '@/graphql/airdrop_list/types/getAirdropClaims';
import type {
	getAirdropBonusVariables,
	getAirdropBonus as getAirdropBonusResponse
} from '@/graphql/airdrop_list/types/getAirdropBonus';

import type {
	getAirdropClaimDetailsVariables,
	getAirdropClaimDetails as getAirdropClaimDetailsResponse
} from '@/graphql/airdrop_list/types/getAirdropClaimDetails';
import type { getEmailSendListVariables, getEmailSendList as getEmailSendListResponse } from '@/graphql/email_template/types/getEmailSendList';
import type {
	getEmailSendSummaryVariables,
	getEmailSendSummary as getEmailSendSummaryResponse
} from '@/graphql/email_template/types/getEmailSendSummary';

import type { getTemplateVariables, getTemplate as getTemplateResponse } from '@/graphql/email_template/types/getTemplate';
import type { getTemplateStatsVariables, getTemplateStats as getTemplateStatsResponse } from '@/graphql/email_template/types/getTemplateStats';
import type { getInfoStream as getInfoStreamResponse } from '@/graphql/maintenance/types/getInfoStream';

import type { insertEmailsVariables, insertEmails as insertEmailsResponse } from '@/graphql/email_list/types/insertEmails';
import type { getEmailsVariables, getEmails as getEmailsResponse } from '@/graphql/email_list/types/getEmails';
import type { getAirdropListVariables, getAirdropList as getAirdropListResponse } from '@/graphql/airdrop_list/types/getAirdropList';
import type { getInviteVariables, getInvite as getInviteResponse } from '@/graphql/airdrop_list/types/getInvite';
import type { getBlockListVariables, getBlockList as getBlockListResponse } from '@/graphql/airdrop_list/types/getBlockList';
import type {
	updateAirdropListVariables,
	updateAirdropList as updateAirdropListResponse
} from '@/graphql/airdrop_list/types/updateAirdropList';

import type { updateInviteVariables,	updateInvite as updateInviteResponse} from '@/graphql/airdrop_list/types/updateInvite';
import type { verificationImageRotateVariables,	verificationImageRotate as verificationImageRotateResponse} from '@/graphql/airdrop_list/types/verificationImageRotate';
import type { verificationUpdateStatusVariables,	verificationUpdateStatus as verificationUpdateStatusResponse} from '@/graphql/airdrop_list/types/verificationUpdateStatus';
import type { verificationUpdateVariables,	verificationUpdate as verificationUpdateResponse} from '@/graphql/airdrop_list/types/verificationUpdate';

import type {
	updateAirdropLinkVariables,
	updateAirdropLink as updateAirdropLinkResponse
} from '@/graphql/airdrop_list/types/updateAirdropLink';

import type {
	addAirdropBonusVariables,
	addAirdropBonus as addAirdropBonusResponse
} from '@/graphql/airdrop_list/types/addAirdropBonus';


import type { getLastData as getLastDataResponse, getLastDataVariables } from '@/graphql/user/types/getLastData';
import type { getAdminInfo as getAdminInfoResponse } from '@/graphql/user/types/getAdminInfo';

import type { getWorkersStatus as getWorkersStatusResponse, getWorkersStatusVariables } from '@/graphql/user/types/getWorkersStatus';


import type { updateMaintenance } from '@/graphql/maintenance/types/updateMaintenance';

import type { createNotificationTemplate as createNotificationTemplateResponse, createNotificationTemplateVariables } from '@/graphql/email_template/types/createNotificationTemplate';
import type { deleteNotificationTemplate as deleteNotificationTemplateResponse, deleteNotificationTemplateVariables } from '@/graphql/email_template/types/deleteNotificationTemplate';
import type { getNotificationTemplate as getNotificationTemplateResponse, getNotificationTemplateVariables } from '@/graphql/email_template/types/getNotificationTemplate';
import type { getNotificationTemplates as getNotificationTemplatesResponse, getNotificationTemplatesVariables } from '@/graphql/email_template/types/getNotificationTemplates';
import type { getNotificationTemplateStats as getNotificationTemplateStatsResponse, getNotificationTemplateStatsVariables } from '@/graphql/email_template/types/getNotificationTemplateStats';
import type { sendNotificationTemplate as sendNotificationTemplateResponse, sendNotificationTemplateVariables } from '@/graphql/email_template/types/sendNotificationTemplate';
import type { testNotificationTemplate as testNotificationTemplateResponse, testNotificationTemplateVariables } from '@/graphql/email_template/types/testNotificationTemplate';
import type { updateNotificationTemplate as updateNotificationTemplateResponse, updateNotificationTemplateVariables } from '@/graphql/email_template/types/updateNotificationTemplate';
import type { FetchResult } from '@apollo/client/core';
import util from './shared'

export interface Config {
	application_live?: boolean;
	global_message?: string;
	show_global_message?: boolean;
	subscription?: any;
	notification_icon?: string;
	notification_link?: string;
	notification_link_text?: string;
	notification_message?: string;
	purchase_reset_date?: string;
	show_notification?: boolean;
	spread_factor?: string;
	migrate_fortmatic?: boolean;
	force_migration_date?: string;
}

export interface InfoStream {
	subscription?: any;
}

export const AdminService = {
	enableMarket(variables: enableMarketVariables) {
		return util.formatReturnData(apollo.mutate({
			mutation: enableMarket,
			variables
		}));
	},
	enableMainchainMarket(variables: enableMainchainMarketVariables) {
		return util.formatReturnData(apollo.mutate({
			mutation: enableMainchainMarket,
			variables
		}));
	},
	pauseMarket(variables: pauseMarketVariables) {
		return util.formatReturnData(apollo.mutate({
			mutation: pauseMarket,
			variables
		}));
	},
	setPurchaseDate(variables: setPurchaseDateVariables) {
		return util.formatReturnData(apollo.mutate({
			mutation: setPurchaseDate,
			variables
		}));
	},

	updateMarketData(variables: updateMarketDataVariables) {
		return util.formatReturnData(apollo.mutate({
			mutation: updateMarketData,
			variables
		}));
	},

	createMarket(variables: createMarketVariables) {
		return util.formatReturnData(apollo.mutate<createMarketResponse>({
			mutation: createMarket,
			variables
		}));
	},
	deleteMarket(variables: deleteMarketVariables) {
		return util.formatReturnData( apollo.mutate<deleteMarketResponse>({
			mutation: deleteMarket,
			variables
		}));
	},
	delistMarket(variables: delistMarketVariables) {
		return util.formatReturnData(apollo.mutate<delistMarketResponse>({
			mutation: delistMarket,
			variables
		}));
	},	
	
	createMarketSplit(variables: createMarketSplitVariables) {
		return util.formatReturnData(apollo.mutate<createMarketSplitResponse>({
			mutation: createMarketSplit,
			variables
		}));
	},
	editMarketSplit(variables: editMarketSplitVariables) {
		return util.formatReturnData(apollo.mutate<editMarketSplitResponse>({
			mutation: editMarketSplit,
			variables
		}));
	},
	deleteMarketSplit(variables: deleteMarketSplitVariables) {
		return util.formatReturnData(apollo.mutate<deleteMarketSplitResponse>({
			mutation: deleteMarketSplit,
			variables
		}));
	},
	updateFetchingWorker(variables: updateFetchingWorkerVariables) {
		return util.formatReturnData(apollo.mutate<updateFetchingWorkerResponse>({
			mutation: updateFetchingWorker,
			variables
		}));
	},
	updateProcessingWorker(variables: updateProcessingWorkerVariables) {
		return util.formatReturnData(apollo.mutate<updateProcessingWorkerResponse>({
			mutation: updateProcessingWorker,
			variables
		}));
	},
	updatePositionsWorker(variables: updatePositionsWorkerVariables) {
		return util.formatReturnData(apollo.mutate<updatePositionsWorkerRespone>({
			mutation: updatePositionsWorker,
			variables
		}));
	},
	getTemplates(data: getTemplatesVariables) {
		return util.formatReturnData(apollo.query<getTemplatesResponse>({
			query: getTemplates,
			variables: 
				data
		}));
	},
	getNotificationTemplate(data: getNotificationTemplateVariables) {
		return util.formatReturnData(apollo.query<getNotificationTemplateResponse>({
			query: getNotificationTemplate,
			variables: 
				data
		}));
	},
	getNotificationTemplates(data: getNotificationTemplatesVariables) {
		return util.formatReturnData(apollo.query<getNotificationTemplatesResponse>({
			query: getNotificationTemplates,
			variables: 
				data
		}));
	},
	getNotificationTemplateStats(data: getNotificationTemplateStatsVariables) {
		return util.formatReturnData(apollo.query<getNotificationTemplateStatsResponse>({
			query: getNotificationTemplateStats,
			variables: 
				data
		}));
	},			
	getUsers(data: getUsersVariables) {
		return util.formatReturnData(apollo.query<getUsersResponse>({
			query: getUsers,
			variables: data
		}));
	},
	getPositions(data: getPositionsVariables) {
		return util.formatReturnData(apollo.query<getPositionsResponse>({
			query: getPositions,
			variables: data
		}));
	},
	lockPosition(data: lockPositionVariables) {
		return util.formatReturnData(apollo.mutate<lockPositionResponse>({
			mutation: lockPosition,
			variables: data
		}));
	},
	approvePosition(data: approvePositionVariables) {
		return util.formatReturnData(apollo.mutate<approvePositionResponse>({
			mutation: approvePosition,
			variables: data
		}));
	},
	getPurchaseTransaction(data: getPurchaseTransactionVariables) {
		return util.formatReturnData(apollo.query<getPurchaseTransactionResponse>({
			query: getPurchaseTransaction,
			variables: data
		}));
	},
	getWithdrawTransaction(data: getWithdrawTransactionVariables) {
		return util.formatReturnData(apollo.query<getWithdrawTransactionResponse>({
			query: getWithdrawTransaction,
			variables: data
		}));
	},
	getDepositTransaction(data: getDepositTransactionVariables) {
		return util.formatReturnData(apollo.query<getDepositTransactionResponse>({
			query: getDepositTransaction,
			variables: data
		}));
	},

	getAirdropClaims(data: getAirdropClaimsVariables) {
		return util.formatReturnData(apollo.query<getAirdropClaimsResponse>({
			query: getAirdropClaims,
			variables: data
		}));
	},
	getAirdropBonus(data: getAirdropBonusVariables) {
		return util.formatReturnData(apollo.query<getAirdropBonusResponse>({
			query: getAirdropBonus,
			variables: data
		}));
	},	
	getAirdropClaimDetails(data: getAirdropClaimDetailsVariables) {
		return util.formatReturnData(apollo.query<getAirdropClaimDetailsResponse>({
			query: getAirdropClaimDetails,
			variables: data
		}));
	},
	getEmailSendList(data: getEmailSendListVariables) {
		return util.formatReturnData(apollo.query<getEmailSendListResponse>({
			query: getEmailSendList,
			variables: data
		}));
	},
	getEmailSendSummary(data: getEmailSendSummaryVariables) {
		return util.formatReturnData(apollo.query<getEmailSendSummaryResponse>({
			query: getEmailSendSummary,
			variables: data
		}));
	},
	getTemplate(data: getTemplateVariables) {
		return util.formatReturnData(apollo.query<getTemplateResponse>({
			query: getTemplate,
			variables: data
		}));
	},
	getTemplateStats(data: getTemplateStatsVariables) {
		return util.formatReturnData(apollo.query<getTemplateStatsResponse>({
			query: getTemplateStats,
			variables: data
		}));
	},
	getInfoStream() {
		return util.formatReturnData(apollo.query<getInfoStreamResponse>({
			query: getInfoStream
		}));
	},
	createNotificationTemplate(variables: createNotificationTemplateVariables) {
		return apollo.mutate<createNotificationTemplateResponse>({
			mutation: createNotificationTemplate,
			variables,
		});
	},
	deleteNotificationTemplate(variables: deleteNotificationTemplateVariables) {
		return apollo.mutate<deleteNotificationTemplateResponse>({
			mutation: deleteNotificationTemplate,
			variables
		});
	},
	sendNotificationTemplate(variables: sendNotificationTemplateVariables) {
		return apollo.mutate<sendNotificationTemplateResponse>({
			mutation: sendNotificationTemplate,
			variables,
		});
	},
	testNotificationTemplate(variables: testNotificationTemplateVariables) {
		return apollo.mutate<testNotificationTemplateResponse>({
			mutation: testNotificationTemplate,
			variables,
		});
	},
	updateNotificationTemplate(variables: updateNotificationTemplateVariables) {
		return apollo.mutate<updateNotificationTemplateResponse>({
			mutation: updateNotificationTemplate,
			variables,
		});
	},		


	deleteTemplate(variables: deleteTemplateVariables) {
		return util.formatReturnData(apollo.mutate<deleteTampleteResponse>({
			mutation: deleteTemplate,
			variables
		}));
	},
	testTemplate(variables: testTemplateVariables) {
		return util.formatReturnData(apollo.mutate<testTemplateResponse>({
			mutation: testTemplate,
			variables
		}));
	},
	sendTemplate(variables: sendTemplateVariables) {
		return util.formatReturnData(apollo.mutate<sendTemplateResponse>({
			mutation: sendTemplate,
			variables
		}));
	},
	cancelSendTemplate(variables: sendTemplateVariables) {
		return util.formatReturnData(apollo.mutate<any>({
			mutation: cancelSendTemplate,
			variables
		}));
	},
	clearTemplateUsers(variables: clearTemplateUsersVariables) {
		return util.formatReturnData(apollo.mutate<clearTemplateUsersResponse>({
			mutation: clearTemplateUsers,
			variables
		}));
	},
	updateTemplateUsers(variables: updateTemplateUsersVariables) {
		return util.formatReturnData(apollo.mutate<updateTemplateUsersResponse>({
			mutation: updateTemplateUsers,
			variables
		}));
	},

	getEmails(data: getEmailsVariables) {
		return util.formatReturnData(apollo.query<getEmailsResponse>({
			query: getEmails,
			variables: {
				data
			}
		}));
	},

	getAirdropList(data: getAirdropListVariables) {
		return util.formatReturnData(apollo.query<getAirdropListResponse>({
			query: getAirdropList,
			variables: {
				email: data.email,
				startDate: data.startDate,
				endDate: data.endDate,
				blocked: data.blocked
			}
		}));
	},

	getInvite(data: getInviteVariables) {
		return util.formatReturnData(apollo.query<getInviteResponse>({
			query: getInvite,
			variables:  data 
		}));
	},

	getVerification(data: getVerificationVariables) {
		return util.formatReturnData(apollo.query<getVerificationResponse>({
			query: getVerification,
			variables:  data 
		}));
	},

	getFaceImage(data: any) {
		return util.formatReturnData(apollo.query<any>({
			query: getFaceImage,
			variables:  data 
		}));
	},

	

	getBlockList(data: getBlockListVariables) {
		return util.formatReturnData(apollo.query<getBlockListResponse>({
			query: getBlockList,
			variables: {
				blocked: data.blocked,
				unblock_uuid: data.unblock_uuid || ''
			}
		}));
	},
	updateAirdropLink(variables: updateAirdropLinkVariables) {
		return util.formatReturnData(apollo.mutate<updateAirdropLinkResponse>({
			mutation: updateAirdropLink,
			variables
		}));
	},
	addAirdropBonus(variables: addAirdropBonusVariables) {
		return util.formatReturnData(apollo.mutate<addAirdropBonusResponse>({
			mutation: addAirdropBonus,
			variables
		}));
	},	
	updateAirdrop(variables: updateAirdropListVariables) {
		return util.formatReturnData(apollo.mutate<updateAirdropListResponse>({
			mutation: updateAirdropList,
			variables
		}));
	},
	updateInvite(variables: updateInviteVariables) {
		return util.formatReturnData(apollo.mutate<updateInviteResponse>({
			mutation: updateInvite,
			variables
		}));
	},
	verificationImageRotate(variables: verificationImageRotateVariables) {
		return util.formatReturnData(apollo.mutate<verificationImageRotateResponse>({
			mutation: verificationImageRotate,
			variables
		}));
	},
	verificationUpdateStatus(variables: verificationUpdateStatusVariables) {
		return util.formatReturnData(apollo.mutate<verificationUpdateStatusResponse>({
			mutation: verificationUpdateStatus,
			variables
		}));
	},
	verificationUpdate(variables: verificationUpdateVariables) {
		return util.formatReturnData(apollo.mutate<verificationUpdateResponse>({
			mutation: verificationUpdate,
			variables
		}));
	},			
	updateFaceMatch(variables: any) {
		return util.formatReturnData(apollo.mutate<any>({
			mutation: updateFaceMatch,
			variables
		}));
	},
	insertEmails(data: insertEmailsVariables) {
		return util.formatReturnData(apollo.mutate<insertEmailsResponse>({
			mutation: insertEmails,
			variables: {
				data
			}
		}));
	},
	updateEmail(variables: updateEmailVariables) {
		return util.formatReturnData(apollo.mutate<updateEmailResponse>({
			mutation: updateEmail,
			variables
		}));
	},
	deleteEmail(variables: deleteEmailVariables) {
		return util.formatReturnData(apollo.mutate<deleteEmailResponse>({
			mutation: deleteEmail,
			variables
		}));
	},
	/** Maintenance */
	updateApplicationLive(variables: updateApplicationLiveVariables) {
		return util.formatReturnData(apollo.mutate<updateApplicationLiveResponse>({
			mutation: updateApplicationLive,
			variables
		}));
	},
	updateGlobalMessage(variables: updateGlobalMessageVariables) {
		return util.formatReturnData(apollo.mutate<updateGlobalMessageResponse>({
			mutation: updateGlobalMessage,
			variables
		}));
	},
	getConfig() {
		return axios.get<Config>(import.meta.env.VITE_CONFIG_ENDPOINT);
	},
	unsubscribeConfig(config: Config) {
		if (config.subscription) config.subscription.unsubscribe();
		config.subscription = undefined;
	},
	subscribeConfig(config: Config, callback: (data: FetchResult<updateMaintenance>) => void) {
		if (config.subscription !== undefined) config.subscription = undefined;
		config.subscription = apollo
			.subscribe({
				query: updateConfig,
				variables: {}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},
				error(err) {
					//console.log('error in subscribeConfig', err)
				}
			});
	},
	unsubscribeInfoStreamUpdates(config: InfoStream) {
		if (config.subscription) config.subscription.unsubscribe();
		config.subscription = undefined;
	},
	subscribeInfoStreamUpdates(config: InfoStream, callback: (data: FetchResult<any>) => void) {
		if (config.subscription !== undefined) {
			config.subscription.unsubscribe();
			config.subscription = undefined;
		}
		config.subscription = apollo
			.subscribe({
				query: infoStreamUpdates,
				variables: {}, errorPolicy: 'ignore'
			})
			.subscribe({
				next(data) {
					try {
						callback(data);
					} catch {}
				},
				error(err) {
					//console.log('error in subscribeInfoStreamUpdates', err)
				}
			});
	},
	getLastData(variables: getLastDataVariables) {
		return util.formatReturnData(apollo.mutate<getLastDataResponse>({
			mutation: getLastData,
			variables
		}));
	},
	getAdminInfo() {
		return util.formatReturnData(apollo.query<getAdminInfoResponse>({
			query: getAdminInfo
		}));
	},
	
	getWorkersStatus(variables: getWorkersStatusVariables) {
		return util.formatReturnData(apollo.mutate<getWorkersStatusResponse>({
			mutation: getWorkersStatus,
			variables
		}));
	}
};
