import { defineStore } from 'pinia'
import { WatchlistService } from '@/services/WatchlistService';
import Util from '@/services/shared';
import { UserService } from '@/services/UserService';
import { WATCHLIST, ERROR, ROOT } from '../mutation-types';
import to from 'await-to-js';
import type { Market } from '@/types/schema';
import { getStore } from '..';
let store = {
		status:  {} as any,
		markets:  {} as any,
	
}

export interface WatchlistState {
	loading: boolean;
	data: (string | null)[];
	markets: Market[];
}




export const useWatchlistStore = defineStore('watchlist', {
  state: () => {
	store = getStore()

    const initialState: WatchlistState =  {
      loading: true,
      data: [],
      markets: []
    };
    return initialState
  },
  actions: {
      [WATCHLIST.LOADING](loading: boolean) {
        this.loading = loading;
      },
      [WATCHLIST.DATA]( data: (string | null)[]) {
        this.data = data;
      },
      [WATCHLIST.MARKETS]( markets: Market[]) {
        this.markets = markets;
      },
      
    
    reset() {
      this.$reset();
    },

		/**
		 * Get watchlist market by symbols
		 * @param commit
		 * @param state
		 * @param rootState
		 * @returns {Promise<*>}
		 */
		async getWatchlist() {
			this[WATCHLIST.LOADING](true);

			if (this.data && this.data.length > 0) {
				// Should be prevented on backend from happening
				const watchlist = [...this.data].filter(watch => watch !== 'UNDEFINED_UNDEFINED');
			
				const markets: any[] = []

				if (store.markets.data && store.markets.data.length > 0) {
					
					watchlist.forEach(watch => {
						const market = store.markets.data.find((mark: any) => Util.formatMarketId(mark.type || '', mark.symbol || '') == watch)
						if (market) {
							markets.push(market);
						}
					})
				}

				this[WATCHLIST.MARKETS](markets);
			} else {
				this[WATCHLIST.MARKETS]([]);
			}
			this[WATCHLIST.LOADING](false);
		},
		/**
		 * Call add or remove from watchlist based on "watched" property
		 * @param dispatch
		 * @param commit
		 * @param market
		 */
		handleWatchlist(market: any) {
			UserService.sendAnalytics({
				event: 'edit_watchlist',
				data: {
					market_id: Util.formatMarketId(market.type, market.symbol), 
					action: market.watched ? 'add' : 'remove',
					market_source: market.watchSource
				}
			});	
			
			this[WATCHLIST.LOADING](true);
			market.watched = !market.watched;
			const id = Util.formatMarketId(market.type, market.symbol);
      if (market.watched) {
        this.addToWatchlist(id);
      } else {
        this.removeFromWatchlist(id);
      }
		},
		/**
		 * Add new symbol to user watchlist
		 * @param commit
		 * @param id
		 * @returns {Promise<*>}
		 */
		async addToWatchlist(id: string) {
			const [err, result] = await to(WatchlistService.addToWatchlist({ id }));
			if (err) return store.status[ERROR](err);
			this[WATCHLIST.LOADING](false);
      if (result?.data?.addToWatchlist)
			this[WATCHLIST.DATA](result?.data?.addToWatchlist);
			this.getWatchlist();
		},
		/**
		 * Remove symbol to user watchlist
		 * @param commit
		 * @param id
		 * @returns {Promise<*>}
		 */
		async removeFromWatchlist(id: string) {
			const [err, result] = await to(WatchlistService.removeFromWatchlist({ id }));
			if (err) return store.status[ERROR](err);
			this[WATCHLIST.LOADING](false);
      if (result?.data?.removeFromWatchlist)
			  this[WATCHLIST.DATA](result.data.removeFromWatchlist);
			this.getWatchlist();
		},
		/**
		 * Toggle loading for watchlist
		 * @param commit
		 * @param toggle
		 */
		toggleWatchlistLoading(toggle: boolean) {
			this[WATCHLIST.LOADING](toggle);
		},
	},
})