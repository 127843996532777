import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
	{
		path: '/admin',
		name: 'Admin',
		component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Main.vue'),
		children: [
			{
				name: 'AdminMarkets',
				path: 'markets',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Markets.vue')
			},
			{
				name: 'AdminSplits',
				path: 'splits',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Splits.vue')
			},
			{
				name: 'AdminPositions',
				path: 'positions',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Positions.vue')
			},
			{
				name: 'AdminEmailTemplates',
				path: 'templates',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Email_Templates.vue')
			},
			{
				name: 'AdminEmailLists',
				path: 'emails',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Email_Lists.vue')
			},
			{
				name: 'AdminMaintenance',
				path: 'maintenance',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Maintenance.vue')
			},
			{
				name: 'AdminReward',
				path: 'rewards',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Rewards.vue')
			},
			{
				name: 'AdminUsers',
				path: 'users',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Users.vue')
			},
			{
				name: 'AdminInvites',
				path: 'invite',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Invite_List.vue')
			},
			{
				name: 'AdminFaceMatch',
				path: 'verification',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Verifications.vue')
			},
			{
				name: 'AdminPurchase',
				path: 'purchase',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Purchases.vue')
			},
			{
				name: 'AdminWithdrawals',
				path: 'withdrawals',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Withdrawals.vue')
			},
			{
				name: 'AdminDeposits',
				path: 'deposits',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Deposits.vue')
			},
			{
				name: 'AdminTrades',
				path: 'trades',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Trades.vue')
			},
			{
				name: 'AdminNotificationTemplates',
				path: 'notifications',
				component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Notification_Templates.vue')
			}
		]
	},
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
    },
	{
		path: '/applogin',
		name: 'AppLogin',
		component: () => import(/* webpackChunkName: "AppLogin" */ '../views/AppLogin.vue')
	},	
	{
		path: '/portfolio',
		name: 'Portfolio',
		component: () => import(/* webpackChunkName: "Portfolio" */ '../views/Portfolio.vue')
	},
	{
		path: '/funds',
		name: 'PortfolioFunds',
		component: () => import(/* webpackChunkName: "PortfolioFunds" */ '../views/PortfolioFunds.vue')
	},
	{
		path: '/funds/purchase/:status?',
		name: 'Purchase',
		component: () => import(/* webpackChunkName: "PortfolioFunds" */ '../views/PortfolioFunds.vue')
	},
	{
		path: '/funds/:type/:page',
		name: 'DepositCollectMPH',
		component: () => import(/* webpackChunkName: "PortfolioFunds" */ '../views/PortfolioFunds.vue')
	},
	{
		path: '/funds/:type',
		name: 'FundAccount',
		component: () => import(/* webpackChunkName: "PortfolioFunds" */ '../views/PortfolioFunds.vue')
	},
	{
		path: '/history',
		name: 'PortfolioHistory',
		component: () => import(/* webpackChunkName: "PortfolioHistory" */ '../views/PortfolioHistory.vue')
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings.vue')
	},
	{
		path: '/help',
		name: 'Help',
		component: () => import(/* webpackChunkName: "Settings" */ '../views/Help.vue')
	},
	{
		path: '/airdrop',
		redirect: '/rewards'
	},
	{
		path: '/rewards',
		name: 'Rewards',
		component: () => import(/* webpackChunkName: "Rewards" */ '../views/Rewards.vue')
	},
	{
		path: '/rewards/savings',
		redirect: '/savings'
	},
	{
		path: '/token/burn',
		name: 'Burn',
		component: () => import(/* webpackChunkName: "Burn" */ '../views/Burn.vue')
	},
	{
		path: '/savings',
		name: 'Saving',
		component: () => import(/* webpackChunkName: "Rewards" */ '../views/Savings.vue')
	},
	{
		path: '/tradingView/:type/:symbol',
		name: 'TradingView',
		beforeEnter: (to, from, next) => {
			if (
				!['stock', 'crypto', 'commodity', 'forex', 'index', 'unique'].includes((to.params.type as string).toLowerCase()) ||
				// if symbol has any special characters like .$%-` etc
				(to.params.symbol as string).search(/[^a-zA-Z0-9_]/g) > -1
			)
				next({
					path: '/404'
				});
			next();
		},
		component: () => import(/* webpackChunkName: "TradingView" */ '@/components/charts/TVChartContainer.vue')
	},
	{
		path: '/migrate',
		name: 'Migrate',
		component: () => import(/* webpackChunkName: "Migrate" */ '../views/Migrate.vue')
	},
	{
		path: '/migrateChain',
		name: 'MigrateBlockchain',
		component: () => import(/* webpackChunkName: "MigrateChain" */ '../views/MigrateChain.vue')
	},
	{
		path: '/login/:status?',
		name: 'Login',
    	component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth.vue')
	},
	{
		path: '/register/:status?',
		name: 'Register',
    	component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth.vue')
	},
	{
		path: '/email',
		name: 'Email',
    	component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth.vue')
	},
	{
		path: '/inviteregister/:status?',
		redirect: '/register/:status?'
	},
	{
		path: '/unlock',
		name: 'Unlock',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth.vue')
	},	
	{
		path: '/404',
		name: '404',
		component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
	},
	{
		path: '/list/:type',
		name: 'MarketSegment',
		beforeEnter: (to, from, next) => {
			if (['index.html'].includes((to.params.type as string).toLowerCase())) next({ name: 'Home' });
			next();
		},
		component: () => import(/* webpackChunkName: "HomeCategory" */ '../views/MarketSegment.vue')
	},
	{
		path: '/unique',
		name: 'MarketTypeUnique',
		component: () => import(/* webpackChunkName: "MarketType" */ '../views/MarketTypeUnique.vue')
	},
	{
		path: '/:type',
		name: 'MarketType',
		beforeEnter: (to, from, next) => {
			if (!['stock', 'crypto', 'commodity', 'forex', 'index', 'unique'].includes((to.params.type as string).toLowerCase()))
				next({
					path: '/404'
				});
			if (['index.html'].includes((to.params.type as string).toLowerCase())) next({ name: 'Home' });
			next();
		},
		component: () => import(/* webpackChunkName: "MarketType" */ '../views/MarketType.vue')
	},
	{
		path: '/unique/:symbol',
		name: 'UniqueMarket',
		component: () => import(/* webpackChunkName: "UniqueMarket" */ '../views/UniqueMarket.vue')
	},
	{
		path: '/:type/:symbol',
		name: 'Market',
		beforeEnter: (to, from, next) => {
			if (
				!['stock', 'crypto', 'commodity', 'forex', 'index', 'unique'].includes((to.params.type as string).toLowerCase()) ||
				// if symbol has any special characters like .$%-` etc
				(to.params.symbol as string).search(/[^a-zA-Z0-9_]/g) > -1
			)
				next({
					path: '/404'
				});
			next();
		},
		component: () => import(/* webpackChunkName: "Market" */ '../views/Market.vue')
	},
	{
		path: "/:catchAll(.*)",
		redirect: '/404',
		// path: "*",
		name: "NotFound",
	}
  ]
})

export default router
