<template>
	<div>
		<div class="deposit-modal-spacing has-text-left">
			<div v-if="process_status === 'processing'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_PROCESSING') }}
			</div>
			<div v-else-if="process_status === 'success'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_SUCCESS') }}
			</div>
			<div v-else-if="process_status === 'error'" class="header-bar-global">
				{{ $t('funds.DEPOSIT_ERROR') }}
			</div>
			<div v-else-if="process_status === 'requested'" class="header-bar-global">
				{{ $t('funds.MORPHER_DEPOSIT_REQUESTED') }}
			</div>

			<div v-else class="header-bar-global">
				<button class="button back-btn" @click="closePayment()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.DEPOSIT') + ' ' + funds?.currency?.toUpperCase() }}
			</div>

			<div v-if="depositDisabled">
				Due to the Ethereum Merge, crypto deposits are temporarily disabled.
			</div>
			<!-- payment requested - after submit to blockchain -->
			<div v-else-if="process_status === 'requested'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/block_search.svg" alt="pending icon" />
				</div>

				<div class="payment_provider_section">
					<div v-if="transaction_data && transaction_data.payload">
						<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')
							" class="transaction_icon" />
					</div>

					<div class="provider_text">
						<div class="provider_header" v-if="transaction_data && transaction_data.payload">
							{{ transaction_data.payload.purchase_crypto_currency.toUpperCase() }}
						</div>
						<div class="provider_header" v-else>
							{{ $t('funds.MORPHER') }}
						</div>
						<p class="is-size-14">{{ $t('funds.CRYPTO_TRANSACTION_RECEIVED') }}</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment processing - after crypto deposit completed - waiting for deposit -->
			<div v-else-if="process_status === 'processing'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/processing.svg" alt="pending icon" />
				</div>

				<div class="payment_provider_section">
					<div v-if="transaction_data && transaction_data.payload">
						<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')
							" class="transaction_icon" />
					</div>

					<div class="provider_text">
						<div class="provider_header" v-if="transaction_data && transaction_data.payload">
							{{ transaction_data.payload.purchase_crypto_currency.toUpperCase() }}
						</div>
						<div class="provider_header" v-else>
							{{ $t('funds.MORPHER') }}
						</div>
						<p class="is-size-14">{{ $t('funds.TRANSACTION_PROCESSING') }} {{ $t('funds.CLOSE_WINDOW') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment success - after deposit completed -->
			<div v-else-if="process_status === 'success'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/success.svg" alt="Success checkmark image" />
				</div>

				<div class="transaction_details_section" v-if="transaction_data && transaction_data.payload">
					<div class="transaction_header">
						<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')
							" class="transaction_icon" />
						{{ roundFormatter(transaction_data.payload.purchase_total) }}
						{{ transaction_data.payload.purchase_crypto_currency }}
					</div>

					<div class="transaction_details_lines">
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MPH_CONVERSION') }}
							</p>
							<p>{{ roundFormatter(transaction_data.payload.purchase_amount_mph) }} MPH</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.MORPHER_FEES') }}
							</p>
							<p>
								{{ transaction_data.payload.purchase_fees == 0 ? '✨' : ''
								}}{{ formatAmount(transaction_data.payload.purchase_fees) }}
							</p>
						</div>
						<div class="transaction_details_line">
							<p class="has-text-weight-medium">
								{{ $t('funds.TOTAL_VALUE') }}
							</p>
							<p>${{ formatAmount(transaction_data.payload.purchase_total_usd) }}</p>
						</div>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>

			<!-- payment error -->
			<div v-else-if="process_status === 'error'" class="payment-flow_box">
				<div class="has-text-centered pending-img">
					<img src="@/assets/funds/error.svg" alt="payment error" />
				</div>

				<div class="payment_provider_section">
					<div class="provider_text">
						<p class="is-size-14">
							{{ $t('funds.ERROR_TEXT') }}
							{{ $t('funds.ERROR_TRY_AGAIN') }}
						</p>
						<p class="is-size-7">
							{{ $t('funds.LEARN_MORE_DEPOSITS') }}
							<a target="_blank" :href="supportArticleLink('DepositMethod')">{{
								$t('funds.HERE')
							}}</a>.
						</p>
					</div>
				</div>

				<!-- Back to Funds -->
				<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="closePayment()">
					{{ $t('BACK') }}
				</button>
			</div>

			<!-- new crypto payment  -->
			<div v-else>
				<div v-if="deposit_address" class="has-text-centered">
					<VueQrcode :value="deposit_address.deposit_eth_address" :options="{ width: 200 }" />
				</div>

				<div class="deposit_section">
					<div class="provider-header" @click="showCurrency()">
						<div class="centered-flex-gap">
							<img v-if="currency.icon" :src="imageURL('@/img/funds/tokens/' + currency.icon)"
								class="provider-icon" />
							<div class="has-text-grey-darker">
								{{ currency.name }}
							</div>
							<div class="payment-currency">
								{{ currency.symbol }}
							</div>
						</div>

						<img src="@/assets/funds/arrow-right.svg" />
					</div>

					<div class="wallet-details mt-15">
						<div class="wallets">
							<p class="wallet-headers has-text-darkgrey">
								{{ $t('funds.WALLET_ADDRESS') }}
							</p>
							<p class="wallet-address" v-if="deposit_address">
								{{ deposit_address.deposit_eth_address }}
							</p>
						</div>

						<button class="button back-btn ml-1" @click="copyAddress()">
							<img src="@/assets/funds/copy.svg" class="copy-icon" />
						</button>
					</div>
					<div class="provider_learn_more is-flex is-align-items-center has-text-darkgrey">
						<span class="ellipse" :class="{
							ellipse_eth: currency.chain == 'eth',
							ellipse_polygon: currency.chain == 'polygon',
							ellipse_bnb: currency.chain == 'bnb'
						}"></span>
						<p class="ml-5px">
							{{ $t('funds.DEOSIT_NETWORK', {
								network: $t('chains.' + currency?.chain?.toUpperCase() + '')
							}) }}
						</p>
					</div>
				</div>
				<div class="deposit_section no_shadow consent_section mt-15">
					<p>
						{{ $t('funds.CONSENT_TOKEN') }}
						<a class="transition-faster" @click="showPurchaseAgreement()">{{
							$t('funds.TOKEN_PURCHASE_AGREEMENT') }}</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import PaymentMixin from '@/mixins/payment.mixin';
import { TransactionService } from '@/services/TransactionService';
import { getCurrencyList } from '@/helpers/funding';
import { UserService } from '@/services/UserService';
import type { FundsState } from '@/store/modules/funds';

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'PaymentTypeMorpher',
	mixins: [PaymentMixin],
	components: {
		VueQrcode
	},
	props: {
		currencySymbol: {
			type: String,
			required: true
		},
		maxLeft: {
			type: Number,
			required: true
		},
		currencyExchange: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			depositDisabled: false
		};
	},
	computed: {
		currency() {
			if (!this.funds.chain || !this.funds.currency) {
				return {};
			}
			const currency: any = getCurrencyList().crypto.find(
				crypto =>
					crypto.chain == this.funds.chain &&
					crypto.provider == 'morpher' &&
					crypto.id.toUpperCase() == this.funds?.currency?.toUpperCase()
			) || {};


			return currency;
		}
	},

	async mounted() {
		this.process_status = 'new';

		await this.loadDepositAddress();

		if (!this.deposit_address || !this.deposit_address.deposit_eth_address) {
			await this.createDepositAddress();
		}

		UserService.sendAnalytics({
			event: 'begin_checkout',
			data: {
				currency: this.funds.currency,
				network: this.funds.chain,
				transaction_id: '',
				value: 0,
				provider: 'morpher',
			}
		});

	},
	methods: {
		showCurrency() {
			this.openFunds({
				type: 'deposit',
				showPaymentMethod: false,
				step: null,
				showPaymentType: null,
				withdraw_amount: 0,
				showWithdrawAddress: false,
				showWithdrawMethod: false,
				withdraw_page: '',
				withdraw_account: '',
				withdraw_account_name: '',
				source: ''
			} as FundsState);
		}
	}
});
</script>
<style>
.deposit_section a {
	color: #1A6DF0 !important;
}
</style>
<style scoped src="../../assets/css/payment.css"></style>
<style scoped>
.provider-header {
	font-size: 16px;
	font-weight: 500;
	border-bottom: 1px solid #eae9ed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -1rem;
	margin-bottom: 1rem;
	padding: 1rem;
	transition: background-color 0.25s;
	border-radius: 7px 7px 0 0;
}

.provider-header:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.provider-icon {
	height: 20px;
}

.payment-currency {
	font-size: 12px;
	line-height: 20px;
	color: #666666;
}

.centered-flex-gap {
	display: flex;
	align-items: center;
	gap: 5px;
}

.token_header {
	font-size: 16px;
	font-weight: bold;
	border-bottom: 1px solid #eae9ed;
	padding: 15px;
	cursor: pointer;
}

.token_icon_image {
	position: relative;
	top: 4px;
}

.token_address_copy_icon {
	float: right;
	display: block;
	width: 25px;
	position: relative;
	top: -60px;
}
</style>
