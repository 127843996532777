<template>
	<div>
		<div :class="{ modal: mobileModal, 'is-active': mobileModal, 'search-modal': mobileModal }">
			<b-field v-click-outside-element="clickAway" id="search_input" class="search-input-custom"
				:class="{ 'search-field-active': searchActive }">
				<div class="clearableInput">

					<div class="custom-search">


						<div v-if="mobileSearch && !mobileModal" @click="activateSearch" class="fake-search">
							<img src="@/assets/icons/search-icon.svg" />
							<span class="ml-2">{{ $t('SEARCH') }}</span>
						</div>
						<div v-else-if="mobileModal" class="mobile-search-header">
							<a class="search-logo-container router-link-exact-active router-link-active"
								@click="closeMobileModal">
								<img class="logo" src="../assets/morpher_logo.svg" :alt="$t('MORPHER_LOGO')" />
							</a>
							<div class="search-input-frame-mobile">
								<img src="@/assets/icons/market-search.svg" />
								<input id="search-input" ref="search" v-model="search" :placeholder="$t('SEARCH')"
									class="input input-mobile" autocomplete="off" @focus="searchActive = true"
									@keydown="submit($event)" @input="onInput" @change="onChange" />
								<div v-if="search" class="clear" @click="clearSearch">{{ $t('CLEAR') }}</div>

								<img class="close-icon" @click="closeMobileModal" src="@/assets/icons/close-icon.svg" />

							</div>
						</div>
						<div v-else class="search-input-frame" :class="{ full: !mobileModal }">
							<img src="@/assets/icons/market-search.svg" />
							<input id="search-input" ref="search" v-model="search" :placeholder="$t('SEARCH')"
								class="input" autocomplete="off" @focus="searchActive = true" @keydown="submit($event)"
								@input="onInput" @change="onChange" />
							<div v-if="search" class="clear" @click="clearSearch">{{ $t('CLEAR') }}</div>
						</div>

						<div v-if="searchActive" :class="{ items: true, 'items-mobile': mobileModal }">
							<div v-if="searchResult.length > 0">
								<div v-for="group in searchResult" :key="group.id" class="group">
									<div class="item-dropdown">
										<p>
											<b>{{ group.name }}</b>
										</p>
									</div>
									<div v-for="market in group.markets" :key="market.id" class="item-dropdown item"
										@click="selectMarket($event, market)">
										<div class="symbol">
											{{ market.symbol }}
										</div>
										<div class="name">
											<span>{{ market.name }}</span>
										</div>
										<WatchButton :handle-watchlist="handleWatchlist" :watchlist="watchlist"
											:market="market" :disabled="impersonating" watchSource="search"
											data-testid="watch-button" :expanded-area="true" />
									</div>
								</div>
							</div>
							<div v-if="!markets_loaded" class="item-dropdown has-text-centered">
								{{ $t('MARKET_DATA_LOADING') }}
								<br />
								<br />
								<img class="loader-icon" src="@/assets/icons/loader.svg" style="width: 50px" />

							</div>
							<div v-else-if="searchResult.length < 1" class="item-dropdown has-text-centered">
								{{ $t('CANNOT_FIND_MARKET') }}
								<br />
								<a :href="'https://morpher.typeform.com/new-markets#email=' + user?.email"
									target="_blank">{{
										$t('REQUEST_HERE')
									}}</a>.
							</div>
						</div>
					</div>
				</div>
				<!-- <button v-if="mobileModal" type="button" @click="closeMobileModal" class="modal-close is-large" style=""></button> -->
			</b-field>
		</div>
	</div>
</template>

<script lang="ts">
//vuex
import { useUserStore } from '@/store/modules/user';
import { useMarketsStore } from '@/store/modules/markets';
import { mapActions, mapState } from 'pinia';
import { useWatchlistStore } from '@/store/modules/watchlist';
import EventBus from '@/store/event-bus';
import Util from '../services/shared';
import { trimWhiteSpace } from '../helpers/utils';
import { directive } from "vue3-click-away";

import WatchButton from './WatchButton.vue';
import { UserService } from '../services/UserService';
import { defineComponent } from 'vue';
/* @group Components_General */
/*
  <h4> <b> Search input in the header </b> </h4>
  <br> - Search trough market on the client side
  <br> - Focus on input at the beginning
  <br> - On select action -> opens separate page for that market
  <br> - If one market left in search -> By enter press it opens that market
  <br>
  <br> - Search algorithm:
  <br> Example is best to explain: The Walt Disney Company
  <br> This array is formed:
  <br>
  <br> `[
  <br> "The", "The Walt", "The Walt Disney", "The Walt Disney",
  <br> "Walt", "Walt Disney", "Walt Disney Company",
  <br> "Disney", "Disney Company",
  <br> "Company"
  <br> ]`
  <br> If search string is matched by any starting substring of any element of this array it will show that market as a match
  <br> Examples: 'the walt' -> works, 'he wall' -> doesn't, 'walt disney' -> works, 'lt disney' -> doesn't
  <br>
  <br> Search works for symbols too, but everything with 'start with' approach, means: 'app' -> works for Apple, 'ppl' doesn't
*/
export default defineComponent({
	name: 'SearchInput',
	components: {
		WatchButton
	},
	props: {
		mobileSearch: {
			type: Boolean,
			default: false
		}
	},
	directives: {
		ClickAway: directive

	},
	data() {
		return {
			selected: null,
			search: '',
			types: ['commodity', 'stock', 'index'],
			loadingIndex: null,
			searchActive: false,
			windowWidth: window.innerWidth - (this.small ? 600 : 0),
			mobileModal: false,
			Util,
			initialized: false,
			searchResult: [] as any[],
			clear: false
		};
	},
	computed: {
		...mapState(useMarketsStore, {
			markets: (state) => state.data,
		}),
		...mapState(useWatchlistStore, {
			watchlist: (state) => state.data,
			watchlistLoading: (state) => state.loading,
		}),
		...mapState(useUserStore, {
			impersonating: (state) => state.impersonating,
			user: (state) => state.data,
		}),

		markets_loaded() {
			if (this.markets && this.markets.length > 10) {
				return true
			} else {
				return false
			}

		},

	},
	watch: {
		selected(newValue) {
			if (newValue !== null && newValue !== '') {
				EventBus.$emit('resetState');
				this.$router.push('/' + newValue.type + '/' + this.formatMarketSymbol(newValue.symbol)).catch(() => { });

				this.$nextTick(() => {
					this.selected = null;
					this.search = '';
					this.searchActive = false;
					this.mobileModal = false;
				});
			}
		},
		watchlistLoading(nv) {
			if (nv === false) this.loadingIndex = null;
		},
		watchlist() { },

		searchActive(nv) {
			if (this.windowWidth < 785 && nv) {
				this.mobileModal = true;
			}
		},
		search() {
			this.updateSearchResults()
		},
		markets_loaded() {
			this.updateSearchResults()
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
		this.updateSearchResults()
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions(useWatchlistStore, {
			handleWatchlist: 'handleWatchlist',
		}),

		updateSearchResults() {

			if (!this.markets_loaded) {
				this.searchResult = []
			}
			const searchValue = trimWhiteSpace(this.search.toLowerCase());
			const symbolMatch = JSON.parse(JSON.stringify(this.markets.filter((market) => {
				if ((this.user?.role === 'user' && market.is_enabled !== true) || !market?.symbol) {
					return false;
				}

				return market?.symbol?.toLowerCase().startsWith(searchValue);
			})));

			const nameMatch = JSON.parse(JSON.stringify(this.markets.filter((market) => {
				if (this.user?.role === 'user' && market.is_enabled !== true) {
					return false;
				}
				if (market.name) {
					const words = market.name.split(' ');
					let combination = '';
					const final = [];
					while (words.length) {
						for (let i = 0; i < words.length; i++) {
							combination += (i !== 0 ? ' ' : '') + words[i];
							final.push(combination);
						}
						words.shift();
						combination = '';
					}

					return (
						final.some((e) => e.toLowerCase().startsWith(searchValue)) && !market?.symbol?.toLowerCase().startsWith(searchValue)
					);
				}
			})));
			const result: any[] = symbolMatch.concat(nameMatch);

			const commodities: any[] = [];
			const indices: any[] = [];
			const crypto: any[] = [];
			const stocks: any[] = [];
			const forex: any[] = [];
			const unique: any[] = [];

			const output = [];

			result.forEach((market: any) => {
				if ((market.watched === undefined || !this.initialized) && this.watchlist?.length > 0) {
					market.watched = this.watchlist?.includes(Util.formatMarketId(market.type?.toString() || '', market.symbol?.toString() || ''));
				}

				if (market.type === 'stock') {
					stocks.push(market);
				}
				if (market.type === 'index') {
					indices.push(market);
				}
				if (market.type === 'forex') {
					forex.push(market);
				}
				if (market.type === 'crypto') {
					crypto.push(market);
				}
				if (market.type === 'commodity') {
					commodities.push(market);
				}
				if (market.type === 'unique') {
					unique.push(market);
				}
			});

			if (commodities.length > 0) {
				output.push({ id: 'commodity', name: this.$t('COMMODITIES'), markets: commodities });
			}
			if (indices.length > 0) {
				output.push({ id: 'index', name: this.$t('INDEX'), markets: indices });
			}
			if (crypto.length > 0) {
				output.push({ id: 'crypto', name: this.$t('CRYPTOCURRENCIES'), markets: crypto });
			}
			if (stocks.length > 0) {
				output.push({ id: 'stock', name: this.$t('STOCKS'), markets: stocks });
			}
			if (forex.length > 0) {
				output.push({ id: 'forex', name: this.$t('FOREX'), markets: forex });
			}
			if (unique.length > 0) {
				output.push({ id: 'unique', name: this.$t('UNIQUES'), markets: unique });
			}

			this.searchResult = output;
		},

		/**
		 * @vuese
		 * On Enter event
		 * In case only one market in dropdown - visit that market
		 * @arg event
		 */
		submit(event: any) {
			if (event.key === 'Enter' && this.searchResult.length === 1) {
				if (this.searchResult[0].markets.length === 1) {
					// Reset search on submit
					const market = this.searchResult[0].markets[0];

					EventBus.$emit('resetState');
					this.selected = null;
					this.search = '';
					this.searchActive = false;
					(this.$refs.search as any).blur();
					this.$router.push('/' + market.type + '/' + market.symbol).catch(() => { });
				}
			}
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		clearSearch() {
			this.clear = true
			this.searchActive = true;
			this.search = '';
			this.$nextTick(() => {
				(this.$refs.search as any).focus();
			})

		},
		clickAway() {

			if (this.clear) {
				this.clear = false;
			} else {
				this.searchActive = false;
			}

		},
		watchlistMarket(market: any) {
			this.loadingIndex = market.index;
			market['watchSource'] = 'search';
			this.handleWatchlist(market);
		},
		selectMarket(e: any, market: any) {
			try {
				if (this.search)
					UserService.sendAnalytics({
						event: 'search',
						data: {
							search_term: this.search
						}
					});
			} catch (err) { }

			e.stopPropagation();
			this.selected = market;
		},
		closeMobileModal() {
			this.search = '';
			this.searchActive = false;
			this.mobileModal = false;
		},
		onInput(event: any) {
			this.search = event.target.value;
		},
		onChange(event: any) {
			this.search = event.target.value;
		},
		async activateSearch() {
			this.mobileModal = true;
			await this.$nextTick();
			this.clearSearch();
		},

	}
});
</script>

<style scoped>
td {
	height: inherit !important;
}

.search-table {
	width: 100%;
	table-layout: fixed;
	margin-left: 10px;
}

.search-table td:first-letter {
	text-transform: capitalize;
}

.search-table td:nth-child(1) {
	width: 70px;
	/* width: 30%; */
}

.search-table td:nth-child(2) {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 130px;
	/* width: 50%; */
}

.search-table td:nth-child(3) {
	padding-left: 12px !important;
}

.search-input-custom {
	transition: 200ms;
	-webkit-transition: 200ms;
	-moz-transition: 200ms;
	width: 300px;
	margin-left: 40px;
}

.search-field-active {
	width: 300px;
	max-width: 300px;
}

input {
	width: 100%;
	color: #686475;

	font-family: 'Manrope' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.14px;
}

input::placeholder {
	color: #686475;
	;
}

.mobile-conainer {
	margin: 10px;
	position: absolute;
	top: 20px;
	width: calc(100% - 50px);
}

.animation-content {
	width: 100% !important;
}

#search_input input {
	background: #F3F2F6;
}
</style>

<style>
#search_input .dropdown-menu .dropdown-content {
	max-height: 400px !important;
}

#search_input_mobile .dropdown-menu {
	position: fixed;
	/* Stay in place */
	z-index: 1;
	/* Sit on top */
	left: 10px;
	top: 75px;
	width: calc(100% - 20px);
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
}

#search_input_mobile .dropdown-menu .dropdown-content {
	height: calc(100% - 100px);
	max-height: 100% !important;
}

.animation-content {
	width: 100% !important;
}

.clearableInput {
	position: relative;
}

.clear {
	position: absolute;
	right: 46px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 0.75rem;
	text-transform: uppercase;
	cursor: pointer;
	transition: 200ms;
	font-weight: 500;
	z-index: 2;
	color: #686475;
}

.clear:hover {
	text-decoration: underline;
}

.close-icon {
	position: absolute;
	right: 16px;
	cursor: pointer;
	z-index: 1;
	left: unset !important;
}

.clearableInput input {
	padding-right: 65px;
}



.no-hover-background {
	background: #fff !important;
	margin: -0.375rem -1rem;
}

@media only screen and (max-width: 768px) {
	.search-input-custom {
		position: relative !important;
		left: 0 !important;
		top: 0 !important;
		transform: none !important;
		width: 100% !important;
		margin-left: 0px !important;
	}

	.search-input-frame {
		margin: 10px;
		margin-right: 50px;
		height: 42px;
	}

	.search-input-frame-mobile {
		height: 42px;
		position: relative;
		z-index: 10;
		margin-bottom: 12px;
	}

	.search-input-frame.full {
		margin-right: 0;
	}

	.search-field-active {
		width: 100%;
		max-width: 100% !important;
	}

	.search-input-frame {
		margin: 20px;
		margin-right: 50px;
	}

	.search-modal .modal-close {
		top: 24px !important;
	}
}

.custom-search .items {
	position: absolute;
	left: 0;
	text-align: left;
	width: 100%;
	margin-top: 10px;
	max-height: 55vh;
	overflow: hidden;
	overflow-y: auto;
	background-color: white;
	border-radius: 10px;
	-webkit-box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
}

.custom-search .item-dropdown {
	padding: 0.375rem 1rem;
}

.custom-search .items-mobile {
	position: relative;
	max-height: none;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	flex: 1;
}

.custom-search .items-mobile .group+.group {
	margin-top: 10px;
}

.custom-search .item-dropdown.item {
	cursor: pointer;
	transition: 200ms;
	padding: 0.5rem 1rem;
	display: flex;
	position: relative;
}

.custom-search .item-dropdown.item:hover {
	background-color: #f5f3f8;
}

.custom-search .item-dropdown.item .symbol {
	flex: 0 0 auto;
	width: 60px;
	margin-right: 30px;
	font-weight: 500;
}

.custom-search .item-dropdown.item .name {
	margin-right: 10px;
	overflow: hidden;
	padding-right: 50px;
}

.custom-search .item-dropdown.item .name span {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.custom-search .item-dropdown.item .watch {
	flex: 0 0 auto;
	width: 50px;
	text-align: right;
	padding: 5px;
	position: absolute;
	right: 1rem;
	height: 100%;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-search .item-dropdown.item>div {
	display: flex;
	align-items: center;
	flex: 1;
}

.search-input-mobile {
	margin: 0 !important;
}

.search-input-mobile .items {
	position: relative;
	max-height: none;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	flex: 1;
}

.search-input-mobile .items .group+.group {
	margin-top: 10px;
}

.mobile-search-header {
	display: flex;
	margin-top: 1rem;
	z-index: 2;
	padding-bottom: 4px;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.search-modal {
	background: #fff;
	width: 100%;
	height: 100%;
	top: auto !important;
	left: auto !important;
	z-index: 101 !important;
}

.search-modal .field {
	height: 100%;
}

.search-modal .clearableInput {
	height: 100%;
}

.search-modal .custom-search {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.search-modal .modal-close {
	right: 10px;
	top: 18px;
}

.search-input-frame {
	position: relative;
	z-index: 10;
}

.search-modal .animation-content {
	height: 100% !important;
}

.search-modal .modal-background {
	background: #fff;
}

.search-input-frame img {
	position: absolute;
	z-index: 2;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	color: #ababac;
	font-size: 18px;
}

.search-input-frame-mobile img {
	position: absolute;
	z-index: 2;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	color: #ababac;
	font-size: 18px;
}

.fake-search {
	width: 100%;
	border-radius: 8px;
	height: 40px;
	font-size: 14px;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 12px;
	color: #686475;
	font-family: 'Manrope';
	cursor: pointer;
	background: var(--surface-surface-subtle-grey, #F9F9F9);
	;
}

.search-logo-container {
	margin: 0 1rem;
}

.logo {
	width: 40px !important;
	height: 40px !important;
}

.input-mobile {
	width: 100% !important
}
</style>
