import { defineStore } from 'pinia'
import { UserService } from '@/services/UserService';
import { PositionService } from '@/services/PositionService';
import { MarketService } from '@/services/MarketService';
import type { MarketWithSubscription }from '@/services/MarketService';


import { OrderService } from '@/services/OrderService';
import { PortfolioService } from '@/services/PortfolioService';
import { CONTRACT, ERROR, LOADING, SUCCESS, USER, WATCHLIST, AUTH } from '../mutation-types';
import to from 'await-to-js';
import router from '../../router/';
import EventBus from  '../event-bus';
import type { Portfolio, User as UserType, User, Position, Market } from '@/types/schema';
import type { loginWalletVariables } from '@/graphql/auth/types/loginWallet';
import type { loginImpersonateVariables } from '@/graphql/auth/types/loginImpersonate';
import type { checkGeoblockVariables } from '@/graphql/auth/types/checkGeoblock';
import type { getPortfolioHistoryVariables, getPortfolioHistory_getPortfolioHistory } from '@/graphql/portfolio/types/getPortfolioHistory';
import type { verifyEmailVariables } from '@/graphql/auth/types/verifyEmail';
import type { unlockWithdrawVariables } from '@/graphql/auth/types/unlockWithdraw';

import type { validateRegistrationFieldVariables } from '@/graphql/auth/types/validateRegistrationField';
import type { updateUserVariables } from '@/graphql/user/types/updateUser';
import type { updateEmailSubscriptionsVariables } from '@/graphql/user/types/updateEmailSubscriptions';
import type { updateUserPayloadVariables } from '@/graphql/user/types/updateUserPayload';
import type { updateUserNotificationsVariables } from '@/graphql/user/types/updateUserNotifications';
import type { sendPasswordResetEmailVariables } from '@/graphql/auth/types/sendPasswordResetEmail';
import type { deleteUserVariables } from '@/graphql/user/types/deleteUser';
import type { resetPasswordVariables } from '@/graphql/auth/types/resetPassword';
import type { resendVerifyEmailVariables } from '@/graphql/auth/types/resendVerifyEmail';
import type { checkWithdrawUnlockVariables } from '@/graphql/auth/types/checkWithdrawUnlock';
import type { checkDeleteUnlockVariables } from '@/graphql/auth/types/checkDeleteUnlock';

import { accessTokenName, refreshTokenName } from '@/helpers/utils';
import type { startKYCVariables } from '@/graphql/auth/types/startKYC';
import type { claimAirdropVariables } from '@/graphql/auth/types/claimAirdrop';
import type { confirmWalletVariables } from '@/graphql/auth/types/confirmWallet';
import type { confirmMigrationWalletVariables } from '@/graphql/auth/types/confirmMigrationWallet';
import type { migrationStartVariables } from '@/graphql/auth/types/migrationStart';
import type { migrationCompleteVariables } from '@/graphql/auth/types/migrationComplete';
//import { registrationCompleteVariables } from '@/graphql/auth/types/registrationComplete';
//import { registrationStatusVariables } from '@/graphql/auth/types/registrationStatus';

import type { getPositionValueVariables } from '@/graphql/positions/types/getPositionValue';
import { SnackbarProgrammatic } from 'buefy';
import { getCurrentInstance } from 'vue';
import { i18n } from '@/plugins/i18n';
import * as Sentry from '@sentry/vue';
import Bowser from "bowser";
import { getLivePortfolioPoint } from '@/helpers/returns';
import { getStore } from '..';
import type { WalletClient } from 'viem';
import type { TAddress } from '@/types/graphql-global-types';
import { getPublicClient } from '@/helpers/viem';
import util from '@/services/shared';
let store = {
		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		status:  {} as any,
		markets:  {} as any,
		watchlist:  {} as any,
		admin:  {} as any,
		notifications:  {} as any,
}

export interface EnhancedPortfolio extends Portfolio {
	history: getPortfolioHistory_getPortfolioHistory | undefined;
	historyDaily: getPortfolioHistory_getPortfolioHistory;
	pending?: Number 
}

export interface UserState {
	data: UserType | null;
	refreshing: boolean;
	creatingPortfolio: boolean;
	activePortfolio: EnhancedPortfolio | null;
	wallet_type: string | null;
	universityData: string | null;
	airdrop_data: any;
	airdrop_data_timestamp: number;
	impersonation: UserType | null;
	impersonating: boolean;
	updateUserStatusSubscription: any;
	orderSubscription: any;
	amplitude_device_id: string | null;
	stakes: any[];
	stake_data: any;
	daily_stakes: any[];
	wallet_addresses: any[];
	wallet_addresses_balances: boolean;
	deposit_address: any | null,
	deposit_reference: any | null,
	last_refresh: number| null;
	livePoint: any,
	liveTimeout: number| null
	lastUpdate: any,
	subscriptions: any[]

}

export const useUserStore = defineStore('user', {
  state: () => {
	store = getStore()

    const initialState : UserState = {
      data: null,
      refreshing: false,
      creatingPortfolio: false,
      activePortfolio: null,
      universityData: null,
      wallet_type: null,
      impersonation: null,
      airdrop_data: null,
      airdrop_data_timestamp: 0,
      impersonating: false,
      updateUserStatusSubscription: null,
      orderSubscription: null,
      amplitude_device_id: null,
      stakes: [],
      stake_data: {},
      daily_stakes: [],
      wallet_addresses: [],
      deposit_address: null,
	  deposit_reference: null,
      wallet_addresses_balances: false,
      last_refresh: null,
      livePoint: {},
      liveTimeout: null,
      lastUpdate: null,
      subscriptions: []
    };
    return initialState
  },
  getters: {
/**
		 * Portfolio Line Data - Highcharts formatted data for
		 * Header Sparkline and Big History Line on Portfolio Page
		 * @param state
		 * @param getters
		 * @returns {Array}
		 */
      portfolioLineData: (state) => {
        /** Format history for portfolio line */
        if (state.activePortfolio && state.activePortfolio.history && state.activePortfolio.history.points && state.activePortfolio.history.points.length > 0) {
          const formattedHistory: Array<any> = [];
          state.activePortfolio.history.points.forEach(e => {
            formattedHistory.push([e.timestamp, e.total]);
          });

          if (state.livePoint && state.livePoint.total) {
            formattedHistory.push([new Date().getTime(), state.livePoint.total]);
          }

          return formattedHistory;
        }

        return [];
      },
      portfolioLineDataDaily: (state) => {
        /** Format history for portfolio line */
        if (state.activePortfolio && state.activePortfolio.historyDaily && state.activePortfolio.historyDaily.points && state.activePortfolio.historyDaily.points.length > 0) {
          const formattedHistory = [];
          state.activePortfolio.historyDaily.points.forEach(e => {
            formattedHistory.push([e.timestamp, e.total]);
          });
  
          if (state.livePoint && state.livePoint.total) {
            formattedHistory.push([new Date().getTime(), state.livePoint.total]);
          }
  
          return formattedHistory;
        }
        return [];
      },
      portfolioLineEquityData: (state) => {
        /** Format history for portfolio line */
        if (state.activePortfolio && state.activePortfolio.history && state.activePortfolio.history.points && state.activePortfolio.history.points.length > 0) {
          const formattedHistory: Array<any> = [];
  
          state.activePortfolio.history.points.forEach(e => {
            formattedHistory.push([e.timestamp, Number(e.returns)]);
          });
          if (formattedHistory.length === 1) {
            formattedHistory.push([new Date().getTime(), formattedHistory[0][1]]);
          }
  
          if (state.livePoint && state.livePoint.returns) {
            formattedHistory.push([new Date().getTime(), state.livePoint.returns || 0]);
          }
  
          return formattedHistory;
        }
        return [];
      },
      portfolioLineEquityDataDaily: (state) => {
        /** Format history for portfolio line */
        if (state.activePortfolio && state.activePortfolio.historyDaily && state.activePortfolio.historyDaily.points && state.activePortfolio.historyDaily.points.length > 0) {
  
          const formattedHistory: Array<any> = [];
  
          state.activePortfolio.historyDaily.points.forEach(e => {
            
            formattedHistory.push([e.timestamp, e.returns]);
          });
  
          
          if (state.livePoint) {
            formattedHistory.push([new Date().getTime(), state.livePoint.returns || 0]);
          }
  
  
          return formattedHistory;
        }
        return [];
      },
      /**
       * Sum of all values from opened positions
       * Displayed in header before portfolio sparkline
       * @param state
       * @returns {number}
       */
      valueSum: state => {
        const portfolio = state.activePortfolio;
        return portfolio && portfolio.positions && Object.values(portfolio.positions).length > 0
          ? Object.values(portfolio.positions).reduce((total: any, position: any) => {
              return Number(total) + Number(position.value);
            }, 0)
          : 0;
      }
  },
  
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
		async subscribeOrder(params: { eth_address: string }) {
			// subscribe to order updates

			if (this.orderSubscription !== null) {
				OrderService.unsubscribe(params.eth_address, this.orderSubscription);
				this.orderSubscription = null;
			}

			this.orderSubscription = OrderService.subscribeUpdateOrder(params.eth_address, async data => {
				try {


					if (!data.data) {
						return
					}
					if (data.data.updateOrder.data.status === 'minting_limiter') {
						return;
					}

					const orderID = data.data.updateOrder.data.orderId;
					const orderStatus = data.data.updateOrder.data.status;

					// dont check order status on the market page - the buy sell card does it own order feedback
					if (router.currentRoute.value.name === 'Market') {
						return;
					}

					// get the last order from the DB
					const [err, result] = await to(OrderService.getOrder({ id: orderID }));
					if (err) return store.status[ERROR](err);
					if (result.data.getOrder) {
						const order = result.data.getOrder;

						if (order.status === 'success') {
							await this.getTokenUserData({
								loading: false,
								component: this,
								updatePortfolio: true
							});
						}


						if (
							((order.price_above && Number(order.price_above) > 0) ||
								(order.price_below && Number(order.price_below) > 0)) &&
							order.status === 'success'
						) {
							store.status[SUCCESS](i18n.t('POSITION_CLOSED', {market: order?.market?.name}));
						} else if ((order.type == 'market_order' || order.type == 'delayed_order') && order.status === 'success') {
							store.status[SUCCESS](i18n.t('ORDER_EXECUTED', {market: order?.market?.name}));
						}
					}
				} catch (err) {}
			});
		},

		async unsubscribeOrder(params: { eth_address: string }) {
			// subscribe to order updates

			if (this.orderSubscription !== null) {
				OrderService.unsubscribe(params.eth_address, this.orderSubscription);
				this.orderSubscription = null;
			}
		},

		/**
		 * Login user to the Morpher using the users wallet login.
		 * @param commit
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async loginWallet(
			params: {
				variables: loginWalletVariables;
				loading?: boolean;
				isTokenLogin: boolean;
				updatePortfolio?: boolean;
				email: string;
				walletClient?: WalletClient;
				recovery_type?: number
			}
		) {
			if (params.updatePortfolio === undefined) params.updatePortfolio = true;
			if (params.loading === undefined) params.loading = true;

			if (params.loading !== false) store.status[LOADING](true);
				 

			if (params.email) {
				params.variables.email = params.email;
			}

			let user: UserType;
			try {
				params.variables.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			} catch {
				params.variables.timezone = null;
			}

			// Wallet auth
			if (!params.isTokenLogin) {
				const [err, result] = await to(UserService.loginWallet(params.variables));

				if (err) {
					store.status[ERROR](err);
					
					return { error: err };
				} else {
					if (
						result.data.loginWallet.id === '00000000-0000-0000-0000-000000000000' &&
						result.data.loginWallet.payload.check_hash &&
						result.data.loginWallet.payload.nonce
					) {
						const timeOut = setTimeout(() => {
							if (params.variables.wallet_type === 'metamask') {
								EventBus.$emit('signatureTimeout');
							}
						}, 10000);

						const hash: string = (await params.walletClient?.signMessage({ account: params.variables.eth_address as TAddress, message: 'Morpher Login\nNonce:' + result.data.loginWallet.payload.nonce })) || '';

						clearTimeout(timeOut);

						if (!hash) {
							store.status[ERROR]('LOGIN_SIGNATURE_CANCELLED')
							return { error: 'Login signature cancelled' };
						}

						params.variables.hash = hash;

						const [err2, result2] = await to(UserService.loginWallet(params.variables));
						if (err2) {
							store.status[ERROR](err2);
							return { error: err2 };
						}
						user = result2.data.loginWallet;
					} else {
						user = result.data.loginWallet;
					}
				}

				//updateUser: 'user/updateUser',
			}
			// Token auth
			else {
				const [err, result] = await to(UserService.loginWithToken());
				if (err) {
					const message: string = (err as any)?.message?.toString() || err.toString();

					if (message.includes('ACCESS_DENIED') || message.includes('TOKEN_EXPIRED') || message.includes('MAINTENANCE_MESSAGE')) {
						localStorage.removeItem(accessTokenName);
						localStorage.removeItem(refreshTokenName);
						localStorage.removeItem('morpher-role');
						return router.push('/login').catch(() => {});
					} else {
						store.status[ERROR](err);
						localStorage.setItem('logonRoute', router.currentRoute.value.fullPath);
						return router.push('/login/retry').catch(() => {});
					}

					
				}
				user = result.data.loginToken;
				user.token = localStorage.getItem(accessTokenName);
				user.refreshToken = localStorage.getItem(refreshTokenName);
			}

			if (user.status === 'email updated' || user.status === 'email') {
				localStorage.setItem('emailOrUsernameStorage', user.email || '');

				router.push('/register/verify').catch(() => {});

				return;
			}

			if (user.status === 'migrating') {
				await router.push('/login/migrating?type=' +  user.migrate_from_wallet_type).catch(() => { });
				store.status[LOADING](false);
				return;
			}

			if (user.status === 'migrated') {
				await router.push('/login/migrated').catch(() => { });
				store.status[LOADING](false);
				return;
			}

			if (user.status !== 'confirmed' && user.status !== 'blocked'  && user.status !== 'kyc') {
				router.push('/register?token=' + user.token).catch(() => {});
				return;
			}

			if (
				user.kyc_status === 'duplicate id' ||
				user.kyc_status === 'duplicate name' ||
				user.kyc_status === 'duplicate email' ||
				user.kyc_status === 'country' ||
				user.kyc_status === 'under age'
			) {
				await router.push('/register/' + user.kyc_status.replace(/ /g, '')).catch(() => {});
				return;
			}
			if (user.status === 'blocked') {
				if (user.withdrawal_block_reason == 'deleted') {
					await router.push('/login/accountdeleted').catch(() => {});
				} else {
					await router.push('/register/rejected').catch(() => {});
				}
				
				return;
			}

			
			Sentry.getCurrentScope().setUser({ id: user.eth_address || '', email: user.email || '', username: user.username || '' })


			if (import.meta.env.VITE_MODE === 'production' && user.payload.web_session_record) {
				try {
					await Sentry.setTag("session_recording_reason",  user.payload.web_session_record);
				

					Sentry.replayIntegration({
						maskAllText: false,
						blockAllMedia: true,
					  });
				} catch (err: any) {
					console.log('error setting up sentry:' + err.toString())
				}
			}

			store.status[LOADING](false);
			this.setUserData(user);

			if (user.kyc_status !== 'verified') {
				if (this.updateUserStatusSubscription) {
					await UserService.unsubscribeUpdateUserStatus(user.id || '', this.updateUserStatusSubscription);
				}
				
				this.updateUserStatusSubscription = UserService.subscribeUpdateUserStatus(user.id || '', async data => {

					await this.getTokenUserData({
						loading: false,
						component: this,
						updatePortfolio: true
					});

				
					this.setUserData(data.data?.updateUserStatus);

					const kyc_status = data.data?.updateUserStatus?.kyc_status;
					if (
						kyc_status === 'duplicate id' ||
						kyc_status === 'duplicate name' ||
						kyc_status === 'duplicate email' ||
						kyc_status === 'country' ||
						kyc_status === 'under age' 
					) {
						UserService.unsubscribeUpdateUserStatus(user.id || '', this.updateUserStatusSubscription);
						this.updateUserStatusSubscription = null;

						if (localStorage.getItem(refreshTokenName) !== null && localStorage.getItem(refreshTokenName) !== 'null') {
							const [err] = await to(
								UserService.logout({
									refreshToken: localStorage.getItem(refreshTokenName) || ''
								})
							);
							if (err) return store.status[ERROR](err);
							this.$reset()
							store.status.clearStore();
						}
						localStorage.removeItem(accessTokenName);
						localStorage.removeItem(refreshTokenName);

						await router.push('/register/' + kyc_status.replace(/ /g, '')).catch(() => {});

						return;
					} else {
						if (kyc_status === 'review' || kyc_status === 'face check' || kyc_status === 'rejected') {
							await store.modals.closeAllModals(false);
							await store.modals.toggleKYCStatusModal(true);
						}
						if (kyc_status === 'verified') {
							await store.modals.toggleKYCStatusModal(false);
						}
					}
				});
				
			} else {
				if (this.updateUserStatusSubscription) {
					UserService.unsubscribeUpdateUserStatus(user.id || '', this.updateUserStatusSubscription);
					this.updateUserStatusSubscription = null;
				}
			}
			
			if (user.status === 'kyc') {
				localStorage.setItem(accessTokenName, this.data?.token || '');
				localStorage.setItem(refreshTokenName, this.data?.refreshToken || '');
				localStorage.setItem('morpher-role', this.data?.role || '');
	
				await router.push('/login/kyc').catch(() => {});
				return;
			}

			if (user.university) {
				const [err, result] = await to(UserService.getUniversityData({ university: this.data?.university || '' }));
				if (err) return store.status[ERROR](err);
				const universityData = JSON.parse(result.data.getUniversityData);
				this[USER.UNIVERSITY_DATA](universityData);
			}

			localStorage.setItem(accessTokenName, this.data?.token || '');
			localStorage.setItem(refreshTokenName, this.data?.refreshToken || '');
			localStorage.setItem('morpher-role', this.data?.role || '');

			if (!user.tokens_claimed || Number(user.tokens_claimed) == 0) {
				this.claimAirdropSignup({
			  		loading: false,
			 		component: this,
					updatePortfolio: true
			 	});
			}

			 if (user && user.payload) {
				if (params.recovery_type && String(user.payload.morpher_login_method) !== String(params.recovery_type)) {
					this.updateUserPayload({
						payloadOption: 'morpher_login_method',
						payloadValue: String(params.recovery_type)
				  	});
				}
			
				if (user.payload.app_version !== import.meta.env.VITE_RELEASE_VERSION) {
					this.updateUserPayload({
						payloadOption: 'app_version',
						payloadValue: import.meta.env.VITE_RELEASE_VERSION
				  	});
				}

				const bowser = Bowser.getParser(window.navigator.userAgent);
				
			 	if (user.payload.last_platform !== 'web-' + bowser.getBrowser().name) {
					this.updateUserPayload( {
						payloadOption: 'last_platform',
						payloadValue: 'web-' + bowser.getBrowser().name
				  	});					
			 	}

				let user_list: any =  localStorage.getItem("user_list")
				if (user_list) {
					user_list = JSON.parse(user_list);
					const keys = Object.keys(user_list)

					if (keys.length > 2) {
						if (user.payload.user_list !== JSON.stringify(user_list)) {
							this.updateUserPayload({
								payloadOption: 'user_list',
								payloadValue: JSON.stringify(user_list)
							});
						}
					}
				}
			}			

			// update the user email if it was changed
			if (
				params.email &&
				(params.variables?.wallet_type?.toLowerCase() === 'fortmatic' ||
					params.variables?.wallet_type?.toLowerCase() === 'morpherwallet') &&
				user.email?.toLowerCase() !== params.email.toLowerCase()
			) {
				const updateParams: updateUserVariables = {
					id: user.id || '',
					username: user.username?.toLowerCase() || '',
					email: params.email,
					transactionSignature: ''
				};
				let [updateError, updateResult]:any = await to(UserService.updateUser(updateParams));

				if (updateError || (updateResult.errors && updateResult.errors.length > 0)) {

					if (!updateError) {
						updateError = updateResult.errors[0].message;
					}
					store.status[ERROR](updateError);
					
				} else {
					if (
						updateResult &&
						updateResult.data.updateUser.id === '00000000-0000-0000-0000-000000000000' &&
						updateResult.data.updateUser.payload.check_hash &&
						updateResult.data.updateUser.payload.nonce
					) {

						const hash: string = (await params.walletClient?.signMessage({ account: params.variables.eth_address as TAddress, message: 'Morpher User Update\nNonce:' + updateResult.data.updateUser.payload.nonce })) || '';
						
						updateParams.transactionSignature = hash;
						[updateError, updateResult] = await to(UserService.updateUser(updateParams));
					}

					if (updateError || (updateResult.errors && updateResult.errors.length > 0)) {
						if (!updateError) {
							updateError = updateResult.errors[0].message;
						}
						store.status[ERROR](updateError);
						
					} else {
						user['old_address'] = user.email || '';
	
						user.email = params.email;
						this.setUserData(user);
					}
	

				}
			}

			// Don't update portfolio if 'updatePortfolio' equals false
			if (params.updatePortfolio !== false && user.activePortfolio) {
				this[USER.ACTIVE_PORTFOLIO](user.activePortfolio as any);
				this.formatActivePortfolio();
			}

			if (params.variables?.wallet_type?.toLowerCase() === 'fortmatic' && store.admin.config && store.admin.config.migrate_fortmatic) {
				let showMigrate = true;
				const lastSeen = localStorage.getItem('migrate_later');
				// Check if seen less than 2 days ago
				if (lastSeen !== null) {
					if (Date.now() - parseInt(lastSeen) < 172800000) {
						showMigrate = false;
					}
				}
				if (showMigrate) {
					router.push('/migrate').catch(() => {});;
				} else {
					const logonRoute = localStorage.getItem('logonRoute')
					if (logonRoute) {
						localStorage.removeItem('logonRoute')
						router.push(logonRoute);
					} else {
						router.push('/');
					}
				}
			} else {
				const logonRoute = localStorage.getItem('logonRoute')
				if (logonRoute) {
					localStorage.removeItem('logonRoute')
					router.push(logonRoute);
				} else {
					router.push('/');
				}
			}

			// Handle watchlist
			store.watchlist[WATCHLIST.DATA](this.data?.watchlist);

			// Start Portis
			store.contract.startWallet(
				{
					load: true,
					configPortis: {
						registerPageByDefault: this.data?.portfolios?.length === 0
					}
				}
			);

			// remove referred by from storage
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('airdrop_custom_invite');



			// Check if the user claim airdrop modal should be shown
			if (user.payload && user.payload.claim_airdrop && !user.new_user) {
				// check that there is a claim amount available before showing the popup
				const tokens = Number(user.airdrop?.approved_amount) - Number(user.tokens_claimed);
				if (tokens > 0) {
					store.modals.showClaimAirdropModal = true;
				}
			}

			if (!store.modals.showClaimAirdropModal) {
				// make sure we are on the correct network before checking the balance
				if (store.contract.walletType === 'metamask') {
					store.contract.networkCheck({});
				}

				const publicClient = getPublicClient(user.payload.blockchain_info)

				

				if (!store.contract.wrongNetwork) {
					const eth_balance = await publicClient.getBalance({ address: params.variables.eth_address as TAddress});
					if (Number(eth_balance) / Math.pow(10, 18) < 0.02) {
						if (user.payload && user.payload.last_topup_wait) {
							this.updateUserPayload({
								payloadOption: 'last_topup_wait',
								payloadValue: String(false)
							  });							
							store.modals.showETHBalanceModal = true;
						}
					}
				}
			}

			this.subscribeOrder({ eth_address: user.eth_address || '' });

			store.notifications.handleNotifications();

			// if this is a new user then shw the welcome message and the username modal
			if (user.new_user) {
				store.status[SUCCESS]('WELCOME_TO_MORPHER');
			}

			return user;
		},
		/**
		 * impersonate a user by loggin is using a logon token.
		 * @param commit
		 * @param state
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async impersonateUser(
			params: {
				token: string;
			}
		) {
			store.status[LOADING](true);

			// Form auth

			const adminUser = this.impersonation ? this.impersonation : this.data;

			if (adminUser) {

				const [err, result] = await to(UserService.loginImpersonate(params));
				if (err) return store.status[ERROR](err);
				const user: UserType = result.data.loginImpersonate;

				this.impersonation = adminUser;

				if (adminUser.id !== user.id) {
					this.impersonating = true;
				} else {
					this.impersonating = false;
				}

				user.role = adminUser.role;
				store.status[LOADING](false);
				this.setUserData(user);

				if (user.university) {
					const [err, result] = await to(UserService.getUniversityData({ university: this.data?.university || '' }));
					if (err) return store.status[ERROR](err);
					const universityData = JSON.parse(result.data.getUniversityData);
					this[USER.UNIVERSITY_DATA](universityData);
				}

				// Don't update portfolio if 'updatePortfolio' equals false

				if (user.activePortfolio)
					this[USER.ACTIVE_PORTFOLIO](user.activePortfolio as any);
				this.formatActivePortfolio();

				// Handle watchlist
				store.watchlist[WATCHLIST.DATA](result.data.watchlist)
				

				// redirect to the home page
				router.push('/').catch(() => {});
			}
		},
		/**
		 * Login user to the Morpher. Manipulation user data possible. Setting user in the store(l-207).
		 * @param commit
		 * @param state
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async loginToken(
			params: {
				loading: boolean;
				updatePortfolio: boolean;
				component: any;
			}
		) {
			try {
				// if (router.currentRoute.name === 'Register') {
				// 	return;
				// }
				if (!localStorage.getItem(accessTokenName)) {
					return;
				}
				if (params.loading !== false) store.status[LOADING](true);

				// Form auth

				const [err, result] = await to(UserService.loginWithToken());

				if (err) {
					const message: string = (err as any)?.message?.toString() || err.toString();

					if (message.includes('ACCESS_DENIED') || message.includes('TOKEN_EXPIRED') || message.includes('MAINTENANCE_MESSAGE')) {
						localStorage.removeItem(accessTokenName);
						localStorage.removeItem(refreshTokenName);
						localStorage.removeItem('morpher-role');
						return router.push('/login').catch(() => {});
					} else {
						if (router.currentRoute.value.name !== "Login") {
							localStorage.setItem('logonRoute', router.currentRoute.value.fullPath);
							router.push('/login/retry').catch(() => {});
						}

					}
					return 'Token Login Error - ' + err.toString()
					
				}

				const user: UserType = result.data.loginToken;
				user.refreshToken = localStorage.getItem(refreshTokenName);

				if (user.status === 'email updated') {
					router.push('/register/verify').catch(() => {});
					return;
				}

				if (user.status === 'migrating') {
					await router.push('/login/migrating?type=' +  user.migrate_from_wallet_type).catch(() => { });
					return;
				}
	
				if (user.status === 'migrated') {
					await router.push('/login/migrated').catch(() => { });
					return;
				}				

				if (user.status === 'blocked') {
					if (user.withdrawal_block_reason == 'deleted') {
						await router.push('/login/accountdeleted').catch(() => {});
					} else {
						await router.push('/register/rejected').catch(() => {});
					}
					return;
				}

				if (user.status !== 'confirmed' && user.status !== 'kyc') {
					router.push('/register?token=' + user.token).catch(() => {});
					return;
				}

				if (user.wallet_type?.toLowerCase() === 'fortmatic'  && store.admin.config && store.admin.config.migrate_fortmatic) {
					let showMigrate = true;
					const lastSeen = localStorage.getItem('migrate_later');
					// Check if seen less than 2 days ago
					if (lastSeen !== null) {
						if (Date.now() - parseInt(lastSeen) < 172800000) {
							showMigrate = false;
						}
					}
					if (showMigrate) {
						router.push('/migrate').catch(() => {});;
					}
				} 

				Sentry.setUser({ id: user.eth_address || '', email: user.email|| '', username: user.username|| '' });
				

				if (import.meta.env.VITE_MODE === 'production' && user.payload.web_session_record) {
					try {
						await Sentry.setTag("session_recording_reason",  user.payload.web_session_record);
						Sentry.replayIntegration({
							maskAllText: false,
							blockAllMedia: true,
						  });
					} catch (err: any) {
						console.log('error setting up sentry:' + err.toString())
					}
				}
	

				if (
					user.kyc_status === 'duplicate id' ||
					user.kyc_status === 'duplicate name' ||
					user.kyc_status === 'duplicate email' ||
					user.kyc_status === 'country' ||
					user.kyc_status === 'under age'
				) {
					await router.push('/register/' + user.kyc_status.replace(/ /g, '')).catch(() => {});
					return;
				}

				store.status[LOADING](false);
				this.setUserData(user);

				if (user.kyc_status !== 'verified') {
					if (this.updateUserStatusSubscription) {
						await UserService.unsubscribeUpdateUserStatus(user.id || '', this.updateUserStatusSubscription);
					}

					this.updateUserStatusSubscription = UserService.subscribeUpdateUserStatus(user.id|| '', async data => {
						await this.getTokenUserData({
							loading: false,
							component: this,
							updatePortfolio: true
						});

						this.setUserData(data.data?.updateUserStatus);

						const kyc_status = data.data?.updateUserStatus?.kyc_status;
						if (
							kyc_status === 'duplicate id' ||
							kyc_status === 'duplicate name' ||
							kyc_status === 'country' ||
							kyc_status === 'under age'
						) {
							UserService.unsubscribeUpdateUserStatus(user.id || '', this.updateUserStatusSubscription);
							this.updateUserStatusSubscription = null;

							if (localStorage.getItem(refreshTokenName) !== null && localStorage.getItem(refreshTokenName) !== 'null') {
								const [err] = await to(
									UserService.logout({
										refreshToken: localStorage.getItem(refreshTokenName) || ''
									})
								);
								if (err) return store.status[ERROR](err);
								this.$reset()
								store.status.clearStore();
							}
							localStorage.removeItem(accessTokenName);
							localStorage.removeItem(refreshTokenName);

							await router.push('/register/' + kyc_status.replace(/ /g, '')).catch(() => {});

							return;
						} else {
							if (kyc_status === 'review' || kyc_status === 'face check' || kyc_status === 'rejected') {
								await store.modals.closeAllModals(false);
								await store.modals.toggleKYCStatusModal(true);
							}
							if (kyc_status === 'verified') {
								await store.modals.toggleKYCStatusModal(false);
							}
						}
					});
					
				} else {
					if (this.updateUserStatusSubscription) {
						UserService.unsubscribeUpdateUserStatus(user.id|| '', this.updateUserStatusSubscription);
						this.updateUserStatusSubscription = null;
					}
				}

				if (user.status === 'kyc') {
					if (user.token) {
						localStorage.setItem(accessTokenName, user.token);
					} else {
						localStorage.setItem(accessTokenName, this.data?.token|| '');
					}
					localStorage.setItem(refreshTokenName, this.data?.refreshToken|| '');
					localStorage.setItem('morpher-role', this.data?.role|| '');
		
					await router.push('/login/kyc').catch(() => {});
					return;
				}

				if (user.university) {
					const [err, result] = await to(UserService.getUniversityData({ university: this.data?.university || '' }));
					store.status[ERROR](err);

					if (err) return 'Token Login Error - ' + err.toString()
					const universityData = JSON.parse(result.data.getUniversityData);
					this[USER.UNIVERSITY_DATA]( universityData);
				}

				if (user.token) {
					localStorage.setItem(accessTokenName, user.token);
				} else {
					localStorage.setItem(accessTokenName, this.data?.token|| '');
				}

				localStorage.setItem(refreshTokenName, this.data?.refreshToken|| '');
				localStorage.setItem('morpher-role', this.data?.role|| '');

				if (!user.tokens_claimed || Number(user.tokens_claimed) == 0) {
					this.claimAirdropSignup({
						  loading: false,
						 component: this,
						 updatePortfolio: true
					 });
				}
	
				// Don't update portfolio if 'updatePortfolio' equals false
				if (params.updatePortfolio !== false && user.activePortfolio) {
					this[USER.ACTIVE_PORTFOLIO]( user.activePortfolio as any);
					this.formatActivePortfolio();
				}


				// Check if the user claim airdrop modal should be shown
				if (user.payload && user.payload.claim_airdrop && !user.new_user) {
					// check that there is a claim amount available before showing the popup
					const tokens = Number(user.airdrop?.approved_amount) - Number(user.tokens_claimed);
					if (tokens > 0) {
						store.modals.showClaimAirdropModal = true;
					}
				}

				// Handle watchlist
				if (this.data?.watchlist)
					store.watchlist[WATCHLIST.DATA](this.data?.watchlist)

				// Start Portis
				store.contract.startWallet(
					{
						load: true,
						configPortis: {
							registerPageByDefault: this.data?.portfolios?.length === 0
						}
					}
				);

				
				this.subscribeOrder({ eth_address: user.eth_address || '' });

				store.notifications.handleNotifications();

				// remove referred by from storage
				localStorage.removeItem('airdrop_referred_by');
				localStorage.removeItem('airdrop_custom_invite');

			
				EventBus.$emit('page_view_login', router.currentRoute.value);

				return true;

			} catch (err: any) {
				if (params?.component?.logSentryError) {
					params.component.logSentryError(err);
				}
				store.status[ERROR](err);
				return 'Token login error - ' + err.toString();
			}
		},
		
		/**
		 * Claim the user signup airdrop and
		 * @param commit
		 * @param state
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async claimAirdropSignup(
			params: {
				loading: boolean;
				updatePortfolio: boolean;
				component: any;
			}
		) {
			try {
				const start = Date.now();
				if (!localStorage.getItem(accessTokenName)) {
					return;
				}
				if (params.loading !== false) store.status[LOADING](true);

				// Form auth
				const [err, result] = await to(UserService.claimAirdropSignup());
				if (err) return store.status[ERROR](err);

				const user: UserType = result.data.claimAirdropSignup;
				user.token = localStorage.getItem(accessTokenName);
				user.refreshToken = localStorage.getItem(refreshTokenName);

				Sentry.setUser({ id: user.eth_address || '', email: user.email || '', username: user.username || '' });
				

				store.status[LOADING](false);
				this.setUserData(user);

				localStorage.setItem(accessTokenName, this.data?.token || '');
				localStorage.setItem(refreshTokenName, this.data?.refreshToken || '');
				localStorage.setItem('morpher-role', this.data?.role || '');

				// Don't update portfolio if 'updatePortfolio' equals false
				if (params.updatePortfolio !== false && user.activePortfolio) {
					this[USER.ACTIVE_PORTFOLIO](user.activePortfolio as any);
					this.formatActivePortfolio();
				}

				// Handle watchlist
				store.watchlist[WATCHLIST.DATA](this.data?.watchlist);

				return user;
			} catch (err : any) {
				if (params?.component?.logSentryError) {
					params.component.logSentryError(err);
				}

				const message: string = (err as any)?.message?.toString() || err.toString();

				if (message.includes('ACCESS_DENIED') || message.includes('TOKEN_EXPIRED') || message.includes('MAINTENANCE_MESSAGE')) {
					EventBus.$emit('refreshToken');
				}
				store.status[ERROR](err);
				return false;
			}
		},

		/**
		 * Refresh the user data using the user logon token.
		 * @param commit
		 * @param state
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async getTokenUserData(
			params: {
				loading: boolean;
				updatePortfolio?: boolean;
				component: any;
			}
		) {
			try {
				if (!localStorage.getItem(accessTokenName)) {
					return;
				}
				if (params.loading !== false) store.status[LOADING](true);

				// Form auth
				const [err, result] = await to(UserService.getTokenUserData());
				if (err) return store.status[ERROR](err);

				const user: UserType = result.data.getTokenUserData;
				user.token = localStorage.getItem(accessTokenName);
				user.refreshToken = localStorage.getItem(refreshTokenName);

				Sentry.setUser({ id: user.eth_address || '', email: user.email || '', username: user.username || '' });
				

				store.status[LOADING](false);
				this.setUserData( user);

				localStorage.setItem(accessTokenName, this.data?.token || '');
				localStorage.setItem(refreshTokenName, this.data?.refreshToken || '');
				localStorage.setItem('morpher-role', this.data?.role || '');

				// Don't update portfolio if 'updatePortfolio' equals false
				if (params.updatePortfolio !== false && user.activePortfolio) {
					this[USER.ACTIVE_PORTFOLIO]( user.activePortfolio as any);
					this.formatActivePortfolio();
				}

				// Handle watchlist
				store.watchlist[WATCHLIST.DATA](this.data?.watchlist);

				return user;
			} catch (err: any) {
				if (params?.component?.logSentryError) {
					params.component.logSentryError(err);
				}

				const message: string = (err as any)?.message?.toString() || err.toString();

				if (message.includes('ACCESS_DENIED') || message.includes('TOKEN_EXPIRED') || message.includes('MAINTENANCE_MESSAGE')) {
					EventBus.$emit('refreshToken');
				}
				store.status[ERROR](err);
				return false;
			}
		},

		/**
		 * Logout - Clears localStorage and store along with modules
		 * @param commit
		 
		 * @returns {Promise<*>}
		 */
		async logout(parameters: any = {}) {
			// log the user out from their wallet
			if (store.user.data && store.user.data.wallet_type === 'fortmatic') {
				if (store.contract.fortmatic) {
					await store.contract.fortmatic.user.logout();
				}
			}

			if (store.user.data && store.user.data.eth_address) {
				this.unsubscribeOrder({ eth_address: store.user.data.eth_address });
				if (store.user.data.wallet_type === 'morpherwallet') {
					if (store.contract.morpherwallet) {
						await store.contract.morpherwallet.logout();
					}
				}
			}

			if (localStorage.getItem(refreshTokenName) !== null && localStorage.getItem(refreshTokenName) !== 'null') {
				const [err] = await to(
					UserService.logout({
						refreshToken: localStorage.getItem(refreshTokenName) || ''
					})
				);
			}
			this.$reset()
			store.status.clearStore();

			localStorage.removeItem(accessTokenName);
			localStorage.removeItem(refreshTokenName);
			if (parameters && parameters.accountDeleted) {
				router.push('/login/accountdeleted').catch(() => {});
			} else {
				router.push('/login').catch(() => {});
			}
		},

		/**
		 * Refresh token
		 * @param commit
		 * @param state
		 * @param refreshToken
		 * @returns {Promise<*>}
		 */
		async refreshToken(
			params: {
				refreshToken: string;
				component: any;
			}
		) {
			let a = SnackbarProgrammatic as any
			let app = getCurrentInstance()
			const Snackbar = new a(app)
			
			try {
				const mobileApp = localStorage.getItem('mobileApp')
				if (mobileApp == 'true') {
					return
				}

				if (params.refreshToken && params.refreshToken !== 'null' && params.refreshToken !== null) {
					if (!this.refreshing) {
						this.refreshing = true;
						const result = await UserService.refreshToken({ refreshToken: params.refreshToken });

						this.refreshing = false;

						if (result.data.refreshToken) {
							localStorage.setItem(accessTokenName, result.data.refreshToken.accessToken);
							localStorage.setItem(refreshTokenName, result.data.refreshToken.refreshToken);
							this.refreshing = false;
						} else {
							if (params?.component?.logSentryError) {
								params.component.logSentryError(' no data returned by token refresh - ' + (result ? JSON.stringify(result) : ''));
							}
							// Show error Snack
							Snackbar.open({
								duration: 5000,
								message: String(i18n.t('TOKEN_EXPIRED') || ''),
								type: 'is-danger',
								position: 'is-bottom-left',
								actionText: 'OK',
								queue: true,
								onAction: () => {}
							});
							this.refreshing = false;
							return
						}
					}
				} else {
					if (params?.component?.logSentryError) {
						params.component.logSentryError(' no refresh token passed to token refresh function');
					}
					// Show error Snack
					Snackbar.open({
						duration: 5000,
						message: String(i18n.t('TOKEN_EXPIRED') || ''),
						type: 'is-danger',
						position: 'is-bottom-left',
						actionText: 'OK',
						queue: true,
						onAction: () => {}
					});
					this.refreshing = false;
				}
				return true;
			} catch (err: any) {
				this.refreshing = false;
				const message: string = (err as any)?.message?.toString() || err.toString();


				if (message.includes('ACCESS_DENIED') || message.includes('TOKEN_EXPIRED') || message.includes('MAINTENANCE_MESSAGE')) {
					const msg =
						message.includes('ACCESS_DENIED') || message.includes('TOKEN_EXPIRED') ? 'TOKEN_EXPIRED' : 'MAINTENANCE_MESSAGE';

					// Show error Snack
					Snackbar.open({
						duration: 5000,
						message: String(i18n.t(msg) || ''),
						type: 'is-danger',
						position: 'is-bottom-left',
						actionText: 'OK',
						queue: true,
						onAction: () => {}
					});
					return this.logout({});
				} else {
					if (params?.component?.logSentryError) {
						params.component.logSentryError('token refresh error ' + err.toString());
					}
				}
				store.status[ERROR]('REFRESH_TOKEN_ERROR');
				return false;
			}
		},

		/**
		 * Sets provided portfolio object as 'activePortfolio' which is used overall in the application
		 * @param commit
		 
		 * @param portfolio
		 * @returns {Promise<void>}
		 */
		async setActivePortfolio(portfolio: any) {
			this[USER.ACTIVE_PORTFOLIO](portfolio);
			this.formatActivePortfolio();
			EventBus.$emit('resetPortfolioLine');
		},

		/**
		 * Resend Verify email
		 * @param commit
		 * @param params
		 * @returns {Promise<*>}
		 */
		async resendVerifyEmail( params: resendVerifyEmailVariables) {
			const [err, result] = await to(UserService.resendVerifyEmail(params));
			if (err) return store.status[ERROR](err);
			if (result.data.resendVerifyEmail) {
				router.push('/register/verify').catch(() => {});
				store.status[SUCCESS]('EMAIL_RESEND_SUCCESS');
			} else {
				router.push('/register/error').catch(() => {});
				return store.status[ERROR]('EMAIL_RESEND_FAILED');
				
			}
		},

		/**
		 * Resend Unlock User email
		 * @param commit
		 * @param params
		 * @returns {Promise<*>}
		 */
		async checkWithdrawUnlock( params: checkWithdrawUnlockVariables) {
			const [err, result] = await to(UserService.checkWithdrawUnlock(params));
			if (err) return store.status[ERROR](err);
			if (result.data.checkWithdrawUnlock) {
				return result.data.checkWithdrawUnlock;
			} else {
				return store.status[ERROR]({ message: 'EMAIL_RESEND_FAILED' });
				
			}
		},

		async checkDeleteUnlock( params: checkDeleteUnlockVariables) {
			const [err, result] = await to(UserService.checkDeleteUnlock(params));
			if (err) return store.status[ERROR](err);
			if (result.data.checkDeleteUnlock) {
				return result.data.checkDeleteUnlock;
			} else {
				return store.status[ERROR]({ message: 'EMAIL_RESEND_FAILED' });
			}
		},		

		/**
		 * Validate the registration.
		 * @param commit
		 * @param params
		 * @returns {Promise<*>}
		 */
		async validateRegistrationField( params: validateRegistrationFieldVariables) {
			const [err, result] = await to(UserService.validateRegistrationField(params));
			if (err) return store.status[ERROR](err);
			else {
				return result.data.validateRegistrationField;
			}
		},


		/**
		 * Verify email - Verification of the token, if successful user can proceed with registration
		 * @param commit
		 
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async verifyEmail(params: verifyEmailVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.verifyEmail(params));

			if (err) return store.status[ERROR](err);

			// Login user automatically
			const user = result.data.verifyEmail;

			this.setUserData( user);

			store.status[LOADING](false);

			return user;
		},

		/**
		 * Unlock user - Verification of the token, if successful user can proceed with logon
		 * @param commit
		 
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async unlockWithdraw(params: unlockWithdrawVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.unlockWithdraw(params));

			if (err) return store.status[ERROR](err);

			// Login user automatically
			const unlock = result.data.unlockWithdraw;

			return unlock;
		},

		/**
		 * save the wallet information to the database for the wallet linked by the user
		 * - Shows 'Welcome to Morpher' message
		 * @param commit
		 
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async confirmMigrationWallet(params: confirmMigrationWalletVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.confirmMigrationWallet(params));
			if (err) {
				// pass the error back to the migrate component
				throw err;
					//return store.status[ERROR](err);

			}
			// Login user automatically
			const user = result.data.confirmMigrationWallet;

			this.setUserData( user);
			store.status[LOADING](false);

			return user;
		},

		/**
		 * save the wallet information to the database for the wallet linked by the user
		 * - Shows 'Welcome to Morpher' message
		 * @param commit
		 
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async migrationStart( params: migrationStartVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.migrationStart(params));
			if (err) {

					return store.status[ERROR](err);

			}
			// Login user automatically
			const user = result.data.migrationStart;

			this.setUserData( user);
			store.status[LOADING](false);

			return user;
		},


		/**
		 * save the wallet information to the database for the wallet linked by the user
		 * - Shows 'Welcome to Morpher' message
		 * @param commit
		 
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async migrationComplete( params: migrationCompleteVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.migrationComplete(params));
			if (err) {

					return store.status[ERROR](err);

			}
			// Login user automatically
			const user = result.data.migrationComplete;

			this.setUserData(user);
			store.status[LOADING](false);

			return user;
		},		


		async startKYC( params: startKYCVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.startKYC(params));
			if (err) return store.status[ERROR](err);

			// ininitialioze the user kyc url and qr code in the backend
			const kyc_data = result.data.startKYC;

			store.status[LOADING](false);

			return kyc_data;
		},

		
		/**
		 * Submit the kyc confirmation code to the back end so that the kyc data can be saved to the database
		 * @param commit
		 
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async claimAirdrop(params: claimAirdropVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.claimAirdrop(params));
			if (err) return store.status[ERROR](err);

			// Login user automatically
			const user = result.data.claimAirdrop;

			store.status[LOADING](false);

			this.setUserData(user);

			this[USER.ACTIVE_PORTFOLIO](user.activePortfolio);

			return user;
		},

		async checkGeoblock( params: checkGeoblockVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.checkGeoblock(params));
			if (err) return store.status[ERROR](err);

			// Login user automatically
			const returnResult = result.data.checkGeoblock;

			store.status[LOADING](false);

			return returnResult;
		},
		/**
		 * save the wallet information to the database for the wallet linked by the user
		 * - Shows 'Welcome to Morpher' message
		 * @param commit
		 
		 * @param state
		 * @param params
		 * @returns {Promise<*>}
		 */
		async confirmWallet(
			params: {
				variables: confirmWalletVariables;
				email: string;
				walletClient?: WalletClient;
				recovery_type?: number
			}) {
				let payload_option = '';
				let payload_value = '';
			store.status[LOADING](true);
			if (params.variables.consent_email !== false) {
				params.variables.consent_email = true;
			}
			const [err, result] = await to(UserService.confirmWallet(params.variables));

			if (err) {
				
				if (err.message.includes('AIRDROP_ALREADY_CLAIMED')) {
					return { error: 'AIRDROP_ALREADY_CLAIMED'};
				} else if (err.message.includes('AIRDROP_LIST_NOT_EXIST')) {
					return { error: 'AIRDROP_LIST_NOT_EXIST'};
				} else if (err.message.includes('USERREG_EMAILTEMP') || err.message.includes('USERREG_EMAILBLACKLIST')) {
					return { error: 'USERREG_EMAILTEMP'};
				} else if (err.message.includes('AIRDROP_LIST_NOT_APPROVED') || err.message.includes('AIRDROP_LIST_NOT_KYC')) {
					return { error: 'AIRDROP_LIST_NOT_APPROVED'};
				} else {
					return { error: err.message};
				}
				
			}

			// Login user automatically
			const user = result.data.confirmWallet;

			if (user.id) {
				
				const utm_source = localStorage.getItem('utm_source') || router.currentRoute.value.query.utm_source;
				const utm_medium = localStorage.getItem('utm_medium') || router.currentRoute.value.query.utm_medium;
				const utm_campaign = localStorage.getItem('utm_campaign') || router.currentRoute.value.query.utm_campaign;
				const utm_term = localStorage.getItem('utm_term') || router.currentRoute.value.query.utm_term;
				const utm_content = localStorage.getItem('utm_content') || router.currentRoute.value.query.utm_content;
				const utm_referrer = localStorage.getItem('utm_referrer');
				const at_gd = localStorage.getItem('at_gd') || router.currentRoute.value.query.at_gd;

				if (utm_referrer || utm_source || utm_medium || utm_campaign || utm_term || utm_content || at_gd) {
					localStorage.setItem('initial_utm_source', utm_source ? utm_source.toString() : '');
					localStorage.setItem('initial_utm_medium', utm_medium ? utm_medium.toString() : '');
					localStorage.setItem('initial_utm_campaign', utm_campaign ? utm_campaign.toString() : '');
					localStorage.setItem('initial_utm_term', utm_term ? utm_term.toString() : '');
					localStorage.setItem('initial_utm_content', utm_content ? utm_content.toString() : '');
					localStorage.setItem('initial_utm_referrer', utm_referrer ? utm_referrer.toString() : '');
					

					localStorage.removeItem('utm_source');
					localStorage.removeItem('utm_medium');
					localStorage.removeItem('utm_campaign');
					localStorage.removeItem('utm_term');
					localStorage.removeItem('utm_content');
					localStorage.removeItem('utm_referrer');
					
				}
			}

			if (user.status === 'email updated' || user.status === 'email') {
				localStorage.setItem('emailOrUsernameStorage', user.email);

				router.push('/register/verify').catch(() => {});

				return;
			}

			if (user.status === 'migrating') {
				await router.push('/login/migrating?type=' +  user.migrate_from_wallet_type).catch(() => { });
				store.status[LOADING](false);
				return;
			}

			if (user.status === 'migrated') {
				await router.push('/login/migrated').catch(() => { });
				store.status[LOADING](false);
				return;
			}

			if (user.status !== 'confirmed' && user.status !== 'blocked'  && user.status !== 'kyc') {
				router.push('/register?token=' + user.token).catch(() => {});
				return;
			}

			if (
				user.kyc_status === 'duplicate id' ||
				user.kyc_status === 'duplicate name' ||
				user.kyc_status === 'duplicate email' ||
				user.kyc_status === 'country' ||
				user.kyc_status === 'under age'
			) {
				await router.push('/register/' + user.kyc_status.replace(/ /g, '')).catch(() => {});
				return;
			}
			if (user.status === 'blocked') {
				if (user.withdrawal_block_reason == 'deleted') {
					await router.push('/login/accountdeleted').catch(() => {});
				} else {
					await router.push('/register/rejected').catch(() => {});
				}
				return;
			}

			Sentry.setUser({ id: user.eth_address || '', email: user.email|| '', username: user.username || ''});
	

			store.status[LOADING](false);
			this.setUserData( user);

			if (user.kyc_status !== 'verified') {
				if (this.updateUserStatusSubscription) {
					await UserService.unsubscribeUpdateUserStatus(user.id, this.updateUserStatusSubscription);
				}
				
				this.updateUserStatusSubscription = UserService.subscribeUpdateUserStatus(user.id, async data => {

					await this.getTokenUserData({
						loading: false,
						component: this,
						updatePortfolio: true
					});

				
					this.setUserData(data.data?.updateUserStatus);

					const kyc_status = data.data?.updateUserStatus.kyc_status;
					if (
						kyc_status === 'duplicate id' ||
						kyc_status === 'duplicate name' ||
						kyc_status === 'duplicate email' ||
						kyc_status === 'country' ||
						kyc_status === 'under age' 
					) {
						UserService.unsubscribeUpdateUserStatus(user.id, this.updateUserStatusSubscription);
						this.updateUserStatusSubscription = null;

						if (localStorage.getItem(refreshTokenName) !== null && localStorage.getItem(refreshTokenName) !== 'null') {
							const [err] = await to(
								UserService.logout({
									refreshToken: localStorage.getItem(refreshTokenName) || ''
								})
							);
							if (err) return store.status[ERROR](err);
							this.$reset()
							store.status.clearStore();
						}
						localStorage.removeItem(accessTokenName);
						localStorage.removeItem(refreshTokenName);

						await router.push('/register/' + kyc_status.replace(/ /g, '')).catch(() => {});

						return;
					} else {
						if (kyc_status === 'review' || kyc_status === 'face check' || kyc_status === 'rejected') {
							await store.modals.closeAllModals(false);
							await store.modals.toggleKYCStatusModal(true);
						}
						if (kyc_status === 'verified') {
							await store.modals.toggleKYCStatusModal(false);
						}
					}
				});
				
			} else {
				if (this.updateUserStatusSubscription) {
					UserService.unsubscribeUpdateUserStatus(user.id, this.updateUserStatusSubscription);
					this.updateUserStatusSubscription = null;
				}
			}
			
			if (user.status === 'kyc') {
				localStorage.setItem(accessTokenName, this.data?.token || '');
				localStorage.setItem(refreshTokenName, this.data?.refreshToken || '');
				localStorage.setItem('morpher-role', this.data?.role || '');
	
				await router.push('/login/kyc').catch(() => {});
				return;
			}

			if (user.university) {
				const [err, result] = await to(UserService.getUniversityData({ university: this.data?.university || '' }));
				if (err) return store.status[ERROR](err);
				const universityData = JSON.parse(result.data.getUniversityData);
				this[USER.UNIVERSITY_DATA](universityData);
			}

			localStorage.setItem(accessTokenName, this.data?.token || '');
			localStorage.setItem(refreshTokenName, this.data?.refreshToken || '');
			localStorage.setItem('morpher-role', this.data?.role || '');

			if (!user.tokens_claimed || Number(user.tokens_claimed) == 0) {
				this.claimAirdropSignup({
			  		loading: false,
			 		component: this,
					updatePortfolio: true
			 	});
			}

			 if (user && user.payload) {
				if (params.recovery_type && String(user.payload.morpher_login_method) !== String(params.recovery_type)) {
					payload_option = (payload_option ? payload_option + '||' : '') + 'morpher_login_method'
					payload_value = (payload_value ? payload_value + '||' : '') + String(params.recovery_type)
				}
			
				if (user.payload.app_version !== import.meta.env.VITE_RELEASE_VERSION) {
					payload_option = (payload_option ? payload_option + '||' : '') + 'app_version'
					payload_value = (payload_value ? payload_value + '||' : '') + import.meta.env.VITE_RELEASE_VERSION

				}

				const bowser = Bowser.getParser(window.navigator.userAgent);
				
			 	if (user.payload.last_platform !== 'web-' + bowser.getBrowser().name) {
					payload_option = (payload_option ? payload_option + '||' : '') + 'last_platform'
					payload_value = (payload_value ? payload_value + '||' : '') + bowser.getBrowser().name
			 	}

				let user_list: any =  localStorage.getItem("user_list")
				if (user_list) {
					user_list = JSON.parse(user_list);
				 	const keys = Object.keys(user_list)

				 	if (keys.length > 2) {
						if (user.payload.user_list !== JSON.stringify(user_list)) {
							payload_option = (payload_option ? payload_option + '||' : '') + 'user_list'
							payload_value = (payload_value ? payload_value + '||' : '') + JSON.stringify(user_list)

						}
				 	}
				}

			}	
			
			this[USER.ACTIVE_PORTFOLIO](user.activePortfolio);
			this.formatActivePortfolio();
			
			if (params?.variables?.wallet_type?.toLowerCase() === 'fortmatic' && store.admin.config && store.admin.config.migrate_fortmatic) {
				let showMigrate = true;
				const lastSeen = localStorage.getItem('migrate_later');
				// Check if seen less than 2 days ago
				if (lastSeen !== null) {
					if (Date.now() - parseInt(lastSeen) < 172800000) {
						showMigrate = false;
					}
				}
				if (showMigrate) {
					router.push('/migrate').catch(() => {});;
				} else {
					const logonRoute = localStorage.getItem('logonRoute')
					if (logonRoute) {
						localStorage.removeItem('logonRoute')
						router.push(logonRoute);
					} else {
						router.push('/');
					}
				}
			} else {
				const logonRoute = localStorage.getItem('logonRoute')
				if (logonRoute) {
					localStorage.removeItem('logonRoute')
					router.push(logonRoute);
				} else {
					router.push('/');
				}
			}
			
			// Handle watchlist
			store.watchlist[WATCHLIST.DATA](this.data?.watchlist);

			// Start Portis
			store.contract.startWallet(
				{
					load: true,
					configPortis: {
						registerPageByDefault: this.data?.portfolios?.length === 0
					}
				}
			);

			// remove referred by from storage
			localStorage.removeItem('airdrop_referred_by');
			localStorage.removeItem('airdrop_custom_invite');



			// Check if the user claim airdrop modal should be shown
			if (user.payload && user.payload.claim_airdrop && !user.new_user) {
				// check that there is a claim amount available before showing the popup
				const tokens = Number(user.airdrop.approved_amount) - Number(user.tokens_claimed);
				if (tokens > 0) {
					store.modals.showClaimAirdropModal = true;
				}
			}

			if (!store.modals.showClaimAirdropModal) {
				// make sure we are on the correct network before checking the balance
				if (store.contract.walletType === 'metamask') {
					await store.contract.networkCheck({});
				}

				const publicClient = getPublicClient(user.payload.blockchain_info)

				if (!store.contract.wrongNetwork) {
					const eth_balance = await publicClient.getBalance({ address: params.variables.eth_address as TAddress});
					if (Number(eth_balance) / Math.pow(10, 18) < 0.02) {
						if (user.payload && user.payload.last_topup_wait) {
							payload_option = (payload_option ? payload_option + '||' : '') + 'last_topup_wait'
							payload_value = (payload_value ? payload_value + '||' : '') +  String(false)
							store.modals.showETHBalanceModal = true;
						}
					}
				}
			}

			if (payload_option && payload_value) {
				this.updateUserPayload({
					payloadOption: payload_option,
					payloadValue: payload_value,

				});							
			}

							

			this.subscribeOrder({ eth_address: user.eth_address });

			await store.notifications.handleNotifications();

			// if this is a new user then shw the welcome message and the username modal
			if (user.new_user) {
				store.status[SUCCESS]('WELCOME_TO_MORPHER');
			}
			
			

			return user;
		},

		/**
		 * Update The user name User - Called from the username welcome popup when the user first logs into the system
		 * @param commit
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async updateUserName( params: updateUserVariables) {
			params.id = store.user?.data?.id || '';

			try {
				// Execute the suer update
				const [err, result] = await to(UserService.updateUser(params));
				store.status[LOADING](false);

				if (err) return store.status[ERROR](err);
				else if (store.user.data)
					store.user.data.username = params?.username.toLowerCase() ;

				return result.data.updateUser;
			} catch (err) {
				return store.status[ERROR]('Error updateing user name');
			}
		},

		/**
		 * Update User - Action dispatched in Settings Page
		 * @param commit
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async updateUserPayload(params: updateUserPayloadVariables) {
			try {
				store.status[LOADING](true);

				// Execute the suer update
				const [err, result] = await to(UserService.updateUserPayload(params));
				store.status[LOADING](false);

				if (err) return store.status[ERROR](err);
				if (result.data.updateUserPayload && this.data) {
					this.data.payload = result.data.updateUserPayload;
				}
				// if the update was successfull the logout the user
				return result.data.updateUserPayload;
			} catch (err) {
				return store.status[ERROR]('Error signing update using wallet');
			}
		},

		/**
		 * Update User - Action dispatched in Settings Page
		 * @param commit
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async updateUser(params: updateUserVariables) {
			
			params.id = store.user?.data?.id || '';
			

			try {
				store.status[LOADING](true);

				// Execute the suer update
				const [err, result] = await to(UserService.updateUser(params));
				store.status[LOADING](false);

				if (err) return store.status[ERROR](err);
				if (
					result.data.updateUser &&
					result.data.updateUser.id &&
					result.data.updateUser.id !== '00000000-0000-0000-0000-000000000000'
				) {
					// if the update was successfull the logout the user
					store.status[SUCCESS]('SUCCESS_UPDATE');
				}

				return result.data.updateUser;
			} catch (err) {
				return store.status[ERROR]('Error signing update using wallet');
			}
		},

		async updateEmailSubscriptions(params: updateEmailSubscriptionsVariables) {
			try {
				store.status[LOADING](true);

				// Execute the suer update
				const [err, result] = await to(UserService.updateEmailSubscriptions(params));
				store.status[LOADING](false);

				if (err) return store.status[ERROR](err);

				// if the update was successfull the logout the user
				store.status[SUCCESS]('SUCCESS_EMAIL_SUBSCRIPTION_UPDATE');

				return result.data.updateEmailSubscriptions;
			} catch (err) {
				return store.status[ERROR]('Error signing update using wallet');
			}
		},

		/**
		 * Reset Password Email
		 * @param commit
		 * @param params
		 * @returns {Promise<*>}
		 */
		async sendPasswordResetEmail( params: sendPasswordResetEmailVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.sendPasswordResetEmail(params));
			if (err) return store.status[ERROR](err);
			store.status[LOADING](false);
			if (result.data.sendPasswordResetEmail) {
				router.push('/reset/verify').catch(() => {});
				return store.status[SUCCESS]('RESET_EMAIL_SUCCESS');
			} else {
				router.push('/reset/error').catch(() => {});
				return store.status[ERROR]({ message: 'RESET_EMAIL_ERROR' });
			}
		},

		/**
		 * Delete User
		 * @param commit
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async deleteUser( params: deleteUserVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.deleteUser(params));
			if (err) return store.status[ERROR](err);
			store.status[LOADING](false);
			if (result.data.deleteUser) {
				this.logout({});
				return store.status[SUCCESS]('CLOSED_ACCOUNT');
			}
			store.status[ERROR]('SOMETHING_WENT_WRONG');
		},

		/**
		 * Delete User
		 * @param commit
		 
		 * @param params
		 * @returns {Promise<*>}
		 */
		async getPositionValue( params: getPositionValueVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(PositionService.getPositionValue(params));
			if (err) {
				store.status[ERROR](err);
				return { error: err.toString() };
			}
			store.status[LOADING](false);
			if (result.data?.getPositionValue) {
				return result.data.getPositionValue;
			}
			store.status[ERROR]('SOMETHING_WENT_WRONG');
		},

		/**
		 * Reset Password
		 * @param commit
		 * @param params
		 * @returns {Promise<*>}
		 */
		async resetPassword( params: resetPasswordVariables) {
			store.status[LOADING](true);
			const [err, result] = await to(UserService.resetPassword(params));
			if (err) return store.status[ERROR](err);
			store.status[LOADING](false);
			if (result.data.resetPassword) {
				router.push('/login').catch(() => {});
				return store.status[SUCCESS]('CHANGED_PASSWORD');
			} else {
				router.push('/reset/error').catch(() => {});
			}
		},

		async updateActivePortfolio(markets: Market[]) {
			if (this.data && this.activePortfolio && !util.isEmptyObject(this.data)) {
				if (this.activePortfolio.positions ) {
					const positions: any[]  = this.activePortfolio.positions;
					positions.forEach(pos => {
						let mark = markets.find(mark => util.formatMarketId(mark.type || '', mark.symbol || '') == pos.market.id)
						if (mark) {
							pos.market = mark
						}
					})
				}
			}
		},
		/**
		 * Formatting data for active portfolio
		 * - Positions formatted and subscribed for updates
		 * - Started Portfolio Line Interval
		 * - Portfolio marked as 'lastActive' in database
		 * @param commit
		 * @param state
		 * @param getters
		 * @returns {Promise<*>}
		 */
		async formatActivePortfolio() {
			
			if (this.data && this.activePortfolio && !util.isEmptyObject(this.data)) {
				/** Format positions and create subscriptions */
				if (this.activePortfolio.positions &&  this.activePortfolio.history  ) {
					const positions: any[]  = this.activePortfolio.positions;

					const current = this.activePortfolio.history?.current

					if (current?.portfolio) {

						const market_data = store.markets.data
						const keys = Object.keys(current?.portfolio)

						let loaded = true
						keys.forEach(id => {

							const market = market_data.find((market: any) => util.formatMarketId(market.type || '', market.symbol || '') === id );

							current.portfolio[id].close = Number(market?.close || current.portfolio[id].close)
							if (!current.portfolio[id].close) {
								loaded = false
							}
	
						})

						if (!loaded) {
							return
						}

						const liveReturnPoint = getLivePortfolioPoint(current)

						this[USER.LIVE_PORTFOLIO]({liveReturnPoint, portfolio: current.portfolio});

						let subscriptions = this.subscriptions


						if (subscriptions) {
							subscriptions.forEach((subscription:any) => {
								if (subscription && subscription.unsubscribe) {
									subscription.unsubscribe()
								}
							})
						}

						subscriptions = []

						positions.forEach(position => {
							// Check long and short shares amount
							if ((position.long_shares > 0 || position.short_shares > 0) && position.market) {
								// Set value and value_weight
								position.value_weight = (Number(position.value) / this.valueSum) * 100;

								const market = position.market

								// // Call subscribe
								const subscription = MarketService.subscribeToMarketPosition(market, data => {
									try {
										const value = data?.data?.updateMarket?.close


										
										if (current.portfolio[market.id]) {


											if (value) {
												current.portfolio[market.id].close = value
												const liveReturnPoint = getLivePortfolioPoint(current)

												this[USER.LIVE_PORTFOLIO]({liveReturnPoint, portfolio: current.portfolio});
											}


										}
									} catch (err) {}
								});

								 subscriptions.push(subscription)

							}
						});

						this.subscriptions = subscriptions
					}

				}

			}

			if (!this.activePortfolio?.history) {
				this.getPortfolioHistory({eth_address: this.activePortfolio?.eth_address, range: '1W'})
			}
			
		},

		async getPortfolioHistory(params: getPortfolioHistoryVariables) {
			const [err, result] = await to(
				PortfolioService.getPortfolioHistory({
					eth_address: this.activePortfolio?.eth_address,
					range: params.range
				})
			);
			if (err) return store.status[ERROR](err);
			if (params.range == '1D' || params.range == '1W') {
				this[USER.HISTORY](result.data.getPortfolioHistory);
				if (params.range == '1W') {
					this.formatActivePortfolio();
				}
			} else {
				this[USER.HISTORY_DAILY](result.data.getPortfolioHistory);				
			}
			
		},
		async getPortfolioLineDataDaily() {
			/** Format history for portfolio line */
			
			if (this.activePortfolio && this.activePortfolio.historyDaily && this.activePortfolio?.historyDaily?.points && this.activePortfolio?.historyDaily?.points?.length > 0) {
				const formattedHistory: Array<any> = [];
				this.activePortfolio?.historyDaily?.points?.forEach(e => {
					formattedHistory.push([e.timestamp, e.total]);
				});

				return formattedHistory;
			}
			return [];
		},
		async getPortfolioLineEquityDataDaily() {
			/** Format history for portfolio line */
			if (this.activePortfolio && this.activePortfolio.historyDaily && this.activePortfolio.historyDaily.points && this.activePortfolio.historyDaily.points.length > 0) {
				const formattedHistory: Array<any> = [];
				this.activePortfolio.historyDaily.points.forEach(e => {
					formattedHistory.push([e.timestamp, e.returns]);
				});

				return formattedHistory;
			}
			return [];
		},

		/**
		 * Get the airdrop status
		 * @param commit
		 * @param state
		 
		 */
		async getAirdropStatus( refresh: boolean = false) {
			try {
				if (this.airdrop_data !== null && this.airdrop_data_timestamp > Date.now() - 1000 * 60 * 30 && refresh !== true) {
					return this.airdrop_data;
				}
				let airdrop_id;
				if (this.data && this.data.airdrop_id) {
					airdrop_id = this.data.airdrop_id;
				} else {
					airdrop_id = '';
				}

				const airdrop_data = await UserService.getAirdropStatus({ airdrop_id });

				this[USER.AIRDROP_DATA](airdrop_data.data.getAirdropStatus);

				return airdrop_data.data.getAirdropStatus;
			} catch (err) {
				return store.status[ERROR](err);
			}
		},
		async setDeviceId(device_id: string) {
			if (device_id) {
				device_id = device_id + '|Web|' + import.meta.env.VITE_RELEASE_VERSION;
			}
			this.amplitude_device_id = device_id;
		},
		async updateUserNotifications(params: any) {
			if (!params.supressMessage) store.status[LOADING](true);
			const [err, result] = await to(UserService.updateUserNotifications(params));
			if (err) return store.status[ERROR](err);
			store.status[LOADING](false);
			if (result.data.updateUserNotifications) {
				this[USER.NOTIFICATION_DATA](result.data.updateUserNotifications);
				if (params.notificationBoolean && !params.suppressMessage)
					store.status[SUCCESS](i18n.t('PUSH_NOTIFICATIONS_UPDATED_SUCCESSFULLY'));
				return;
			}
			store.status[ERROR]('SOMETHING_WENT_WRONG');
		},
		async loadAllStakes() {
			const [err, result] = await to(UserService.getStakes({ eth_address: this.activePortfolio?.eth_address || '' }));
			if (err) return;
			this[USER.STAKES](result.data.getStakes);
		},
		async loadAllDailyStakes() {
			const [err, result] = await to(UserService.getDailyStakes({ eth_address: this.activePortfolio?.eth_address || '' }));

			if (err) return;

			UserService.subscribeUpdateDailyStakes(this.data?.eth_address?.toLowerCase() || '', (data: any) => {
				if (data && data.data && data.data.updateDailyStakes) {
					this[USER.UPDATE_DAILY_STAKES](data.data.updateDailyStakes);
				}
			});

			this[USER.DAILY_STAKES](result.data.getDailyStakes);
		},
		async loadAllWalletFundsAddresses( params: any) {
			const [err, result] = await to(
				UserService.getWalletFundsAddresses({ eth_address: this.activePortfolio?.eth_address || '', type: '', balances: params })
			);
			if (err) return;
			
			this[USER.WALLET_ADDRESSES_BALANCES]( params);
				this[USER.WALLET_ADDRESSES](JSON.parse(JSON.stringify(result.data.getWalletFundsAddresses)));
		},
		async createWalletFundsAddress( params: any) {
			try {
				const [err, result] = await to(
					UserService.createWalletFundsAddress({
						eth_address: params.wallet_eth_address,
						type: params.type,
						tag: params.tag,
						network: params.network,
						address_resolved: params.address_resolved,
					})
				);

				if (err)
					return {
						success: false,
						error: err.message.split(':')[1]
					};

					this[USER.ADD_WALLET_ADDRESSES](result.data.createWalletFundsAddress);

				return {
					success: true
				};
			} catch (e) {
				return {
					success: false,
					error: 'SOMETHING_WENT_WRONG'
				};
			}
		},
		async updateWalletFundsAddress( params: any) {
			try {
				const [err, result] = await to(
					UserService.updateWalletFundsAddress({
						id: params.id,
						eth_address: params.wallet_eth_address,
						tag: params.tag,
						address_resolved: params.address_resolved,
						network: params.network
					})
				);

				if (!result.data.updateWalletFundsAddress) return false;

				this[USER.UPDATE_WALLET_ADDRESSES]( {
					id: params.id,
					eth_address: params.wallet_eth_address,
					tag: params.tag,
					network: params.network
				});

				return true;
			} catch (e) {
				return false;
			}
		},
		async deleteWalletFundsAddress( params: any) {
			try {
				const [err, result] = await to(
					UserService.deleteWalletFundsAddress({
						id: params.id
					})
				);

				if (!result.data.deleteWalletFundsAddress) return false;

				this[USER.DELETE_WALLET_ADDRESSES]( {
					id: params.id
				});

				return true;
			} catch (e) {
				return false;
			}
		},
		async loadDepositReference() {
			const [err, result] = await to(UserService.getDepositReference());

			if (err) return;

			if (result?.data?.getDepositReference?.deposit_reference) {
				this.deposit_reference = result?.data?.getDepositReference
			}
			
		},
		async loadDepositAddress() {
			const [err, result] = await to(UserService.getDepositAddress());

			if (err) return;

			this[USER.DEPOSIT_ADDRESS](result.data.getDepositAddress);
		},
		async createDepositAddress() {
			const [err, result] = await to(UserService.createDepositAddress());

			if (err) return;

			this[USER.DEPOSIT_ADDRESS](result.data.createDepositAddress);
		},
		setUserData( data: UserType) {
			if (data?.blockchain_info?.chain_name == 'sidechain') {
				data.blockchain_info.rpc = import.meta.env.VITE_SIDECHAIN
			}
			

			this.data = data;
			this.last_refresh = Date.now();
		},
			
			[USER.AIRDROP_DATA]( data: UserType) {
				this.airdrop_data_timestamp = Date.now();
				this.airdrop_data = data;
			},
	
			[USER.LIVE_PORTFOLIO]( data: any) {
				if (!this.liveTimeout) {
	
					if (this.stake_data && this.stake_data?.poolSharesValue) {
	
						if (Number(this.stake_data?.poolSharesValue) / 10**18 > data.liveReturnPoint.stake) {
							data.liveReturnPoint.stake = Number(this.stake_data?.poolSharesValue) / 10**18
						}
					}
	
					this.liveTimeout = window.setTimeout(() => {
						if (this.lastUpdate) {
	
							window.clearTimeout(this.liveTimeout || 0)
							this.liveTimeout = null;
							const commit = this.lastUpdate.commit
	
							if (commit) {
								this[USER.LIVE_PORTFOLIO](this.lastUpdate);
							}
							
							
							this.lastUpdate = null;
	
						} else {
							window.clearTimeout(this.liveTimeout || 0)
							this.liveTimeout = null;
						}
						
						
					}, 5000);

					if (data.portfolio && this.activePortfolio) {
						let activePortfolio = JSON.parse(JSON.stringify(this.activePortfolio))
						const keys = Object.keys(data.portfolio)
						keys.forEach(key => {
							
							const positionId = activePortfolio.positions?.findIndex(
								(position: any) => util.formatMarketId(position?.market?.type || '', position?.market?.symbol|| '') === key
							);

							
	
							if (positionId >= 0) {
								if (activePortfolio && activePortfolio.positions && activePortfolio.positions[positionId]) {
										const position: any = activePortfolio.positions[positionId]
								
										position.value = data.portfolio[key].value;
										position.total_return = data.portfolio[key].total_return || position.total_return;
										position.total_return_percent = data.portfolio[key].total_return_percent || position.total_return_percent;

										activePortfolio.positions[positionId] = position
								
								}
							}
						})
						this.activePortfolio = activePortfolio
	
	
					}
					if (data.liveReturnPoint) {
						this.livePoint = data.liveReturnPoint
					}
				} else {
					this.lastUpdate = data
				}
				
	
	
			},
	

			[USER.ACTIVE_PORTFOLIO]( portfolio: EnhancedPortfolio) {
				if (portfolio.history) {
					const hist:any = portfolio.history 
					if (hist && hist.length > 0 && hist[0].timestamp) {
						if (portfolio)
						portfolio.history = undefined
					}
				}
	
				let total = 0
	
				portfolio.positions?.forEach(position => {
					total = total + Number(position?.value) / 10**18
				}) 
	
				portfolio.positions?.forEach(position => {
					const weight = ((Number(position?.value) / 10**18) / total ) * 100;
	
					if (position)
					position.value_weight = weight
				}) 
	
				
				this.activePortfolio = portfolio;
			},
			[USER.UNIVERSITY_DATA]( universityData: any) {
				this.universityData = universityData;
			},
			[USER.NOTIFICATION_DATA]( notificationData: any) {
				if (this.data)
				this.data.push_notifications_json = notificationData;
			},
			[USER.STAKES]( data: any) {
				this.stakes = data.stakes;
				this.stake_data = data.stake_data;
			},
			[USER.DAILY_STAKES]( data: any[]) {
				this.daily_stakes = data;
			},
			[USER.HISTORY]( data: any) {
				const activePortfolio = JSON.parse(JSON.stringify(this.activePortfolio))
				if (activePortfolio) {
	
					activePortfolio.history = JSON.parse(JSON.stringify(data))
	
					this.activePortfolio = activePortfolio;
				}
	
				
			},
			[USER.HISTORY_DAILY]( data: any) {
				const activePortfolio = JSON.parse(JSON.stringify(this.activePortfolio)) 
				activePortfolio.historyDaily = JSON.parse(JSON.stringify(data))
	
				this.activePortfolio = activePortfolio;
	
			},		
	
			[USER.UPDATE_DAILY_STAKES]( data: any) {
				const sameDateIndex = this.daily_stakes.findIndex(stake => parseInt(stake.timestamp) === parseInt(data.timestamp));
	
				if (sameDateIndex > -1) {
					this.daily_stakes = [...this.daily_stakes.slice(0, sameDateIndex), data, ...this.daily_stakes.slice(sameDateIndex + 1)];
				} else {
					this.daily_stakes = [...this.daily_stakes, data];
				}
			},
			[USER.WALLET_ADDRESSES]( data: any) {
				this.wallet_addresses = data;
			},
			[USER.WALLET_ADDRESSES_BALANCES]( data: any) {
				this.wallet_addresses_balances = data;
			},
			
			[USER.ADD_WALLET_ADDRESSES]( data: any) {
				this.wallet_addresses = [data, ...this.wallet_addresses];
			},
			[USER.UPDATE_WALLET_ADDRESSES]( data: any) {
				const index = this.wallet_addresses.findIndex(wallet => wallet.id === data.id);
	
				if (index > -1) {
					this.wallet_addresses = [
						...this.wallet_addresses.slice(0, index),
						{
							...this.wallet_addresses[index],
							...data
						},
						...this.wallet_addresses.slice(index + 1)
					];
				}
			},
			[USER.DELETE_WALLET_ADDRESSES]( data: any) {
				const index = this.wallet_addresses.findIndex(wallet => wallet.id === data.id);
			
				if (index > -1) {
					this.wallet_addresses = [
						...this.wallet_addresses.slice(0, index),
						...this.wallet_addresses.slice(index + 1)
					];
				}
			},
			[USER.DEPOSIT_ADDRESS]( data: any) {
				this.deposit_address = data;
			}
		
	},
})