import MorpherWallet from 'morpherwallet-sdk';
import Cookie from 'js-cookie';
import { getWalletClient } from './viem';

const morpherWalletConfig = async function() {
	const config = {
		show_transaction: false,
		confirm_transaction: false,
		show_message: false,
		confirm_message: false,
		env: import.meta.env.VITE_MORPHER_WALLET_ENV,
		locale: Cookie.get('locale')
	};

	const morpherwallet = new MorpherWallet(
		import.meta.env.VITE_MORPHER_WALLET_ADDRESS,
		Number(import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID),
		config
	);
	const provider = await morpherwallet.getProvider();

	const walletClient = getWalletClient(provider)


	return {
		morpherwallet,
		provider,
		walletClient
	};
};

export { morpherWalletConfig };
