<template>
	<div class="has-font-manrope">

		<transition name="fade">
			<div v-if="showModal && !notificationClosed" class="modal-back">
				<div class="notification is-size-14 has-font-manrope">
					<img src="@/assets/img/mobile/mobile_app_push.svg" class="mobile-icon" />
					<img src="@/assets/icons/close-icon.svg" class="notification-close-icon"
						@click="closeNotification" />
					<div class="header-text has-text-centered mb-3">
						{{ $t('TRY_APP_BUTTON') }}
					</div>

					<div class="has-text-centered is-size-14 is-line-height-18 mb-4">
						{{ $t('TRY_APP_MOBILE_TEXT') }}
					</div>

					<button class="button plain-primary-btn left-align is-fullwidth mb-4" @click="downloadAppLink()">
						{{ $t('DOWNLOAD_APP_BUTTON') }}
						<img src="@/assets/img/rewards/caret-right-white.svg" class="icon-right" />
					</button>
				</div>
			</div>
		</transition>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { parse } from '@/helpers/uaparser';
import { useUserStore } from '@/store/modules/user';
import { useModalsStore } from '@/store/modules/modals';

/* @group Components_Modals */
/*
	<h4> <b> Username Modal </b> </h4>
	<br> Autogenerates username via dictionary, let's user change it.
*/
export default defineComponent({
	name: 'MobileTryAppModal',
	components: {},
	data() {
		return {
			notificationClosed: true,
			installAppModal: false,
			os: ''
		};
	},
	computed: {
		...mapState(useUserStore, {
			payload: (state) => state.data?.payload,
			user: (state) => state.data
		}),

		showModal() {
			if (this.$route.path.toLowerCase().indexOf('/migrate') !== -1) {
				return false;
			}


			if (this.user?.payload?.migration_started !== true) {

				if (this.user?.wallet_type === 'morpherwallet' && !this.payload?.mobile_os && Number(this.user?.timestamp || 0) < Date.now() - 1000 * 60 * 5) {
					return true;
				} else {
					return false;
				}


			} else {
				return false;
			}
		},
	},
	mounted() {


		const browser = parse(navigator.userAgent);
		this.os = browser.os.toLowerCase();
		if (this.os == 'ipad' || this.os == 'iphone') {
			this.os = 'ios';
		}

		if (this.os && (this.os == 'ios' || this.os == 'android')) {
			const lastSeen = localStorage.getItem('app_try_notification_seen');

			if (lastSeen && Number(lastSeen) >= Date.now() - 1000 * 60 * 60 * 24 * 7)
				this.notificationClosed = true;
			else
				this.notificationClosed = false;
		} else {
			this.notificationClosed = true;
		}


	},
	methods: {


		downloadAppLink() {
			localStorage.setItem('app_try_notification_seen', Date.now().toString())
			this.notificationClosed = true;
			const app_link = 'https://www.morpher.com/app_link';
			window.open(app_link, '_blank');

		},
		closeNotification() {
			localStorage.setItem('app_try_notification_seen', Date.now().toString())
			this.notificationClosed = true;
		}
	}
});
</script>
<style scoped>
.modal-back {
	background: rgba(0, 0, 0, .3);
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;

}

.notification {
	background: #FFF;
	padding: 0.75rem 1.25rem;
	position: fixed;
	bottom: 0px;
	width: 100%;
	z-index: 2999;
	transition: 200ms;
	border-radius: 0;
}

.text-section {
	font-size: 14px;
	font-weight: 400;
	margin-top: 12px;
	margin-left: 56px;
	margin-right: 56px;
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 1216px) {
	.notification {
		z-index: 29;
		padding-left: 40px;
		padding-left: 40px;
	}

	.text-section {
		max-width: unset;
	}
}

/* mobile */
@media screen and (max-width: 768px) {
	.notification {
		z-index: 29;
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.text-section {
		max-width: unset;
	}
}


.is-blue:not(:hover) {
	color: #1A6DF0 !important;
}

.notification-close {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.35);
}

.cursor {
	cursor: pointer;
}

.mobile-icon {
	margin-top: 20px;
}

.notification-close-icon {
	height: 24px;
	width: 24px;
	position: absolute;
	right: 20px;
	cursor: pointer;
}

.modal-card {
	padding: 20px;
	max-width: 400px !important;

}

.helper-header {
	font-size: 28px;
	font-weight: 700;

}

.qr {
	max-width: 188px;
}

.text {
	font-size: 14px;
	font-weight: 400;
}

.helper-image {
	margin-top: 16px;
}

.header-text {
	font-size: 20px;
	font-weight: 500;
	margin-top: 20px;
	line-height: normal;
}
</style>
