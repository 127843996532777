import { entrypointAbi, morpherBridgeAbi, morpherMigrationAbi, morpherOracleAbi, morpherStakingABI, morpherTokenAbi } from '@/contracts/abis'
import type { TAddress, TBlockchainInfo } from '@/types/graphql-global-types'
import type { Blockchian_Info } from '@/types/schema'
import { createPublicClient, createWalletClient, custom, defineChain, http, keccak256, toHex, getContract as getViemContract, type WalletClient, } from 'viem'

export const soliditySha3 = (data: string) => {
  const return_data = keccak256(toHex(data))
  return return_data
}

export const getChainMain = () => {
  const chain = defineChain({
    id: Number(import.meta.env.VITE_MAINCHAIN_ID),
    name: 'ETH',
    network: 'Ethereum',
    nativeCurrency: {
      decimals: 18,
      name: 'ETH',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: {
        http: [import.meta.env.VITE_MAINCHAIN_GETH_ENDPOINT],
      },
      public: {
        http: [import.meta.env.VITE_MAINCHAIN_GETH_ENDPOINT],
      },
    },
  });
  return chain
}

export const getChain = (blockchain_info?: Blockchian_Info) => {
  const chain = defineChain({
    id: Number(blockchain_info?.chain_id || import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID),
    name: 'Morpher',
    network: 'morpher',
    nativeCurrency: {
      decimals: 18,
      name: 'ETH',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: {
        http: [blockchain_info?.rpc || import.meta.env.VITE_SIDECHAIN],
      },
      public: {
        http: [blockchain_info?.rpc || import.meta.env.VITE_SIDECHAIN],
      },
    },
  });
  return chain
}

export const getWalletClient = (provider: any, blockchain_info?: TBlockchainInfo) => {
  const walletClient: any = createWalletClient({
    chain: getChain(blockchain_info),
    transport: custom(provider),
    cacheTime: 10_000,
    
  });

  return walletClient
}

export enum ESideChainTransaction {
  TRADE = 'TRADE',
  STAKE = 'STAKE',
  TOKEN = 'TOKEN',
  ENTRYPOINT = 'ENTRYPOINT',
  MIGRATION = 'MIGRATION',
  BRIDGE = 'BRIDGE',

}

const getAbi = (type: ESideChainTransaction) => {
  switch (type) {
    case ESideChainTransaction.STAKE:
      return morpherStakingABI;
    case ESideChainTransaction.TOKEN:
      return morpherTokenAbi;      
    case ESideChainTransaction.ENTRYPOINT:
      return entrypointAbi;            
    case ESideChainTransaction.MIGRATION:
      return morpherMigrationAbi;      
    case ESideChainTransaction.BRIDGE:
      return morpherBridgeAbi;                        
    case ESideChainTransaction.TRADE:
    default:
      return morpherOracleAbi;
  }
};

export const getSideChainAddress = (type: ESideChainTransaction, blockchain_info?: Blockchian_Info) => {
  switch (type) {
    case ESideChainTransaction.STAKE:
      if (blockchain_info?.staking) return blockchain_info.staking;
      return import.meta.env.VITE_MORPHER_STAKING_SIDECHAIN;

    case ESideChainTransaction.MIGRATION:
      if (blockchain_info?.migration) return blockchain_info?.migration;
      return import.meta.env.VITE_MORPHER_MIGRATION_SIDECHAIN;      

    case ESideChainTransaction.TOKEN:
      if (blockchain_info?.token) return blockchain_info.token;
      return import.meta.env.VITE_MORPHER_TOKEN_SIDECHAIN;
    
    case ESideChainTransaction.BRIDGE:
      if (blockchain_info?.bridge) return blockchain_info.bridge;
      return import.meta.env.VITE_MORPHER_BRIDGE_SIDECHAIN;

    case ESideChainTransaction.ENTRYPOINT:
      //if (blockchain_info?.entry_point) return blockchain_info.entry_point;
      return import.meta.env.VITE_ENTRYPOINT_ADDRESS;      
  
    case ESideChainTransaction.TRADE:
    default:
      if (blockchain_info?.oracle) return blockchain_info.oracle;
      return import.meta.env.VITE_MORPHER_ORACLE_SIDECHAIN;
  }
};


export const getPublicClient = (blockchain_info?: Blockchian_Info) => {
  const chain = getChain(blockchain_info);
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });


  return public_client as any
}

export const getTokenContract = async (blockchain_info: Blockchian_Info | undefined, walletClient: WalletClient, address: TAddress) => {
  const chain = getChain(blockchain_info);
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

  const abi = getAbi(ESideChainTransaction.TOKEN);
  const contract = getViemContract({
    abi: abi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });

  return { contract, public_client };
}

export const getMainchainBridge = async (walletClient: WalletClient) => {
  const chain = getChainMain();
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

  const address = import.meta.env.VITE_MORPHER_BRIDGE_MAINCHAIN;
  const abi = getAbi(ESideChainTransaction.BRIDGE);
  const contract = getViemContract({
    abi: abi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });

  return { contract, public_client };
}


export const getOracleContract = async (
  blockchain_info: Blockchian_Info | undefined,
  walletClient: WalletClient
) => {
  const chain = getChain(blockchain_info);
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

   

  const address = blockchain_info?.oracle ? blockchain_info.oracle : import.meta.env.VITE_MORPHER_ORACLE_SIDECHAIN;
  
  const contract = getViemContract({
    abi: morpherOracleAbi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });


  return { contract, public_client };
};


export const getBridgeContract = async (
  blockchain_info: Blockchian_Info | undefined,
  walletClient: WalletClient
) => {
  const chain = getChain(blockchain_info);
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

   

  const address = blockchain_info?.bridge ? blockchain_info.bridge : import.meta.env.VITE_MORPHER_BRIDGE_SIDECHAIN;
  
  const contract = getViemContract({
    abi: morpherBridgeAbi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });



  return { contract, public_client };
};

export const getContract = async (
  type: ESideChainTransaction,
  blockchain_info: Blockchian_Info | undefined,
  walletClient: WalletClient
) => {
  const chain = getChain(blockchain_info);
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

  const address = getSideChainAddress(type, blockchain_info);
  const abi = getAbi(type);
  const contract = getViemContract({
    abi: abi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });

  return { contract, public_client };
};

export const getMainchainToken = async (walletClient: WalletClient) => {
  const chain = getChainMain();
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

  const address = import.meta.env.VITE_MORPHER_TOKEN_MAINCHAIN;
  const abi = getAbi(ESideChainTransaction.TOKEN);
  const contract = getViemContract({
    abi: abi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });

  return { contract, public_client };
}

export const getERC20TokenContract = async (walletClient: WalletClient) => {
  const erc20_interface = (await import('@uniswap/v2-periphery/build/ERC20.json')).default;

  const chain = getChainMain();
  const public_client = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

  const address = import.meta.env.VITE_MORPHER_TOKEN_MAINCHAIN;
  const abi = erc20_interface.abi;
  const contract = getViemContract({
    abi: abi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });

  return { contract, public_client };
} 
export const getUniswapContract = async (walletClient: WalletClient) => {

  const uniswap_router = (await import('@uniswap/v2-periphery/build/UniswapV2Router02.json')).default;


  const chain = getChainMain();
  const public_client: any = createPublicClient({
    chain: chain,
    transport: http(),
    cacheTime: 10_000,
  });

  const address = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
  const abi = uniswap_router.abi;
  const contract: any = getViemContract({
    abi: abi,
    address: address as TAddress,
    client: { public: public_client, wallet: walletClient },
  });

  return { contract, public_client };
} 