export const ADMIN = {
  CONFIG: 'configMutation',
  INVITATIONS: 'invitationsMutation',
  EMAILS: 'emailsMutation',
  TEMPLATES: 'templatesMutation',
  USERS: 'usersMutation',
  POSITIONS: 'positionsMutation',
  PURCHASE: 'purchaseMutation',
  AIRDROP: 'airdrop_listMutation',
  BLOCKLIST: 'airdrop_blockMutation',
  KYC: 'kyc_dataMutation'
} as const

export const LOADING = 'loadingFunction' as const
export const SUCCESS = 'successFunction' as const
export const ERROR = 'errorFunction' as const
export const ROOT = {
  root: true
} as const

export const AUTH = {
  MOBILE_APP_MODAL: 'showMobileAppModal',
  MPH_REWARD_MODAL: 'showMPGRewardModal',
  USER_INTERVIEW_MODAL: 'showInterviewModal',
  NEW_PORTFOLIO_MODAL: 'showNewPortfolioModal',
  TIER_3_MODAL: 'showTier3Modal',
  TIER_4_MODAL: 'showTier4Modal',
  ALL_DONE_MODAL: 'showAllDoneModal',
  PORTFOLIO_IN_USE_MODAL: 'showPortfolioInUseModal',
  ONBOARD_MODAL: 'showOnboardModal',
  CLAIM_AIRDROP_MODAL: 'showClaimAirdropModal',
  WALLET_RECOVERY_MODAL: 'showWalletRecoveryModal',
  WALLET_MIGRATION_MODAL: 'showWalletMigrationModal',
  UNLOCK_WALLET_MODAL: 'showUnlockWalletModal',
  RESET_METAMASK_MODAL: 'showResetMetamaskModal',
  MAINCHAIN_TRANSFER_MODAL: 'showMainchainTransferModal',
  NOTIFICATION_MODAL: 'showNotificationModal',
  NOTIFICATION_REWARD_MODAL: 'showNotificationRewardModal',
  KYC_STATUS_MODAL: 'showKYCStatusModal',
  KYC_CAPTURE_MODAL: 'showKYCCaptureModal',
  NETWORK_METAMASK_MODAL: 'showNetworkMetamaskModal',
  ADD_MPH_METAMASK_MODAL: 'showAddMPHMetamaskModal',
  ETH_BALANCE_MODAL: 'showEthBalance',
  TOKEN_RECEIVED_MODAL: 'showTokenReceived',
  DEPOSIT_WITHDRAW_MODAL: 'showDepositWithdrawModal',
  UPGRADE_TIER_MODAL: 'showUpgradeTierModal',
  HEADER_NOTIFICATION_MODAL: 'showHeaderNotificationModal',
  HEADER_FIREBASE_MODAL: 'showHeaderFirebaseModal',
  BROWSER_ALERTS_MODAL: 'showBrowserAlertsModal',
  BRAVE_ALERTS_MODAL: 'showBraveAlertsModal',
  TRUST_PILOT_MODAL: 'showTrustPilotModal',
  FAQ_MODAL: 'showFAQModal',
  FAQ_MODAL_PAGE: 'showFAQModalPage',
  CLOSE_ALL_MODALS: 'closeAllModals',
  AI_INSIGHTS_MODAL: 'showAIInsightsModal',
  SET_SUPPORT_MODAL: 'setSupportModal'
} as const

export const FUNDS = {
  SET_FUNDS: 'setFundsState'
} as const

export const NOTIFICATIONS = {
  SET_DEVICE: 'setNotificationDeviceMutation',
  SET_TOKEN: 'setNotificationTokenMutation',
  REGISTER_SERVICE_WORKER: 'registerServiceWorkerMutation',
  TOGGLE_BLOCKED: 'toggleBlockedMutation',
  TOGGLE_BLOCKED_BRAVE: 'toggleBlockedBraveMutation',
  TOGGLE_SUPPORTED: 'toggleSupportedMutation',
  TOGGLE_SUBSCRIBED: 'toggleSubscribedMutation',
  SET_INITIALIZED: 'setNotificationInitializedMutation'
} as const

export const ORDER = {
  DATA: [] as any
} as const

export const WATCHLIST = {
  LOADING: 'watchlistLoadingMutation',
  DATA: 'watchlistMutation',
  MARKETS: 'marketsMutation'
} as const

export const USER = {
  DATA: 'userMutation',
  RESET_STATE: 'resetUserStateMutation',
  METAMASK_ADDRESS: 'metamaskEthAddressMutation',
  ACTIVE_PORTFOLIO: 'activePortfolioMutation',
  UNIVERSITY_DATA: 'universityDataMutation',
  NOTIFICATION_DATA: 'notificationDataMutation',
  SUBSCRIBE_ORDER: 'subscribeOrderMutation',
  UNSUBSCRIBE_ORDER: 'unsubscribeOrderMutation',
  AIRDROP_DATA: 'setAirdropDataMutation',
  LIVE_PORTFOLIO: 'setLivePortfolioPointMutation',
  STAKES: 'setStakeMutations',
  DAILY_STAKES: 'setDailyStakesMutation',
  UPDATE_DAILY_STAKES: 'updateDailyStakesMutation',
  WALLET_ADDRESSES: 'setWalletAddressesMutation',
  WALLET_ADDRESSES_BALANCES: 'setWalletAddressesBalancesMutation',
  ADD_WALLET_ADDRESSES: 'addWalletAddressMutation',
  UPDATE_WALLET_ADDRESSES: 'updateWalletAddressMutation',
  DELETE_WALLET_ADDRESSES: 'deleteWalletAddressMutation',
  DEPOSIT_ADDRESS: 'setDepositAddressMutation',
  HISTORY: 'setHistoryMutation',
  HISTORY_DAILY: 'setHistoryDailyMutation'
} as const

export const PORTFOLIO = {
  LOADING: 'portfolioLoading',
  ALL: 'allPortfolios',
  HOME: 'homePortfolios',
  STUDENT: 'studentPortfolios',
  STUDENT_LOADING: 'studentPortfoliosLoading'
} as const

export const CONTRACT = {
  STARTED_WALLET: 'startedWalletMutation',
  IS_LOGGED_IN_METAMASK: 'isLoggedInMetamaskMutation',
  IS_LOGGED_IN_WALLET: 'isLoggedInWalletMutation',
  HAS_SOCIAL_RECOVERY: 'socialRecoveryMutation',
  PLATFORM: 'setMorpherPlatformMutation',
  ALPHA: 'setMorpherAlphaMutation',
  ORACLE: 'setMorpherOracleMutation',
  BALANCE: 'setBalanceMutation',
  BALANCE_DATA: 'setBalanceMutation',
  LOADING_FREE_TEST_ETHER: 'loadingFreeTestEtherMutation',
  WALLET_TYPE: 'walletTypeMutation',
  ETH_ADDRESS: 'setEthAddressMutation',
  DEPOSIT_TRANSACTION_HASH: 'setDepositTransactionHashMutation',
  NEW_PORTIS_WALLET: 'newPortisWalletMutation',
  LOADING_PORTIS: 'loadingPortisMutation',
  WRONG_WALLET: 'wrongWalletMutation',
  LAST_TRANSACTION_DATE: 'lastTransactionDateMutation',
  PROVIDER: 'walletProviderMutation',
  WALLETCLIENT_MAINCHAIN: 'walletClientMainchainMutation',
  ALLOW_MAINCHAIN: 'allowMainchainMutation',
  NETWORK_ID: 'network_idMutation',
  TRANSACT_ON_NETWORK: 'transactOnNetworkMutation',
  CURRENT_CHAIN_VALID: 'currentChainValidMutation',
  CONFIRMATION_TIMEOUT: 'confirmationTimeoutMutation',
  ORDER_COMPLETE_MESSAGE: 'orderCompleteMEssageMutation'
} as const

export const MARKETS = {
  ALL: 'allMarketsMutation',
  SELECTED: 'selectedMutation',
  UPDATE: 'updateMarketMutation',
  MORPHER: 'getMPHMarketMutation',

  SEGMENTS: 'segmentsMutation',
  SEGMENTS_LOADING: 'segmentsLoadingMutation',
  SPARKLINE_LOADING: 'sparklineLoadingMutation',
  CATEGORY: 'categoryMutation',
  CATEGORY_LOADING: 'categoryLoadingMutation',
  DISABLED: 'disabledMarketsMutation',
  NEWS: 'newsMutation',
  NEWS_LOADING: 'newsLoadingMutation',
  AI_INSIGHTS_LOADING: 'aiInsightsLoadingMutation',
  AI_INSIGHTS: 'aiInsightsMutation',
  TWEETS: 'tweetsMutation',
  TWEETS_LOADING: 'tweetsLoadingMutation',
  LOGOS: 'logosMutation'
} as const
