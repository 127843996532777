// Simple hashing function to get unique device information from the browser
// Seems like native unique device id is not supported, probably because of privacy reasons
// https://stackoverflow.com/questions/63506374/unique-deviceid-on-javascript-web-applicationangular-react

import { soliditySha3 } from './viem'

// The closest non native browser app to be found https://www.deviceinfo.me/
export function parseDevice() {
  let deviceHash = ''

  // Input any unique device information you can find
  deviceHash += screen.availHeight + ' '
  deviceHash += screen.availWidth + ' '
  deviceHash += screen.pixelDepth + ' '
  deviceHash += screen.colorDepth + ' '
  deviceHash += navigator.hardwareConcurrency + ' '
  deviceHash += navigator.language || ''

  deviceHash = soliditySha3(deviceHash)

  return deviceHash.slice(-8)
}
