<template>

	<div class="deposit-modal-spacing has-text-left has-font-manrope">
		<div v-if="funds.withdraw_page" class="header-bar-global">
			<button v-if="
				funds.withdraw_page !== 'withdraw_processing' &&
				funds.withdraw_page !== 'withdraw_processing_moonpay' &&
				funds.withdraw_page !== 'withdraw_complete' &&
				funds.withdraw_page !== 'withdraw_error' &&
				funds.withdraw_page !== 'withdraw_progress'
			" class="button back-btn" @click="updatePageStatus('')">
				<img src="@/assets/funds/arrow-left.svg" alt="Back" />
			</button>
			{{
				$t('withdraw_page.' + funds.withdraw_page.toUpperCase(), {
					dest_amount: roundFormatter(destination_amount),
					symbol: funds.withdraw_currency.name
				})
			}}
		</div>

		<!-- Withdrawal Lock - Paypal -->
		<div v-if="funds.withdraw_page === 'withdraw_block_paypal'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/lock.svg" width="64px" height="64px" alt="Calender lock icon" />
			</div>

			<div class="payment_provider_section mt-15">
				<div>
					<img src="@/assets/funds/gift.svg" class="transaction_icon" />
				</div>

				<div class="provider_text">
					<div class="provider_header">
						{{ $t('BONUS_LOCK') }}
					</div>
					<p class="is-size-14-spaced">{{ $t('BONUS_EARNED_DESC') }}</p>
				</div>
			</div>

			<div v-if="user?.access_level?.withdrawal_unblock_date" class="withdrawal_lock_section mt-15">
				{{ $t('funds.WITHDRAWAL_LOCK_UNTIL', { date: withdrawalUnlockDate }) }}
				<span class="font_normal">{{ $t('funds.WITHDRAWAL_LOCK_LEARN') }}
					<a target="_blank" class="transition-faster" :href="supportArticleLink('DepositPaypal')">{{
						$t('funds.HERE') }}</a></span>
			</div>

			<div class="has-text-centered mt-20">
				<!-- Back to Funds -->
				<button class="button flat-white-btn large is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>
		</div>

		<!-- Withdrawal Lock - any other reason -->
		<div v-else-if="funds.withdraw_page === 'withdraw_block_other'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/lock.svg" width="64px" height="64px" alt="Calender lock icon" />
			</div>

			<div class="payment_provider_section mt-15">
				<div>
					<img src="@/assets/funds/lock-icon.svg" class="transaction_icon" />
				</div>
				<div class="provider_text">
					<p class="is-size-14-spaced">{{ $t('WITHDRAWALS_LOCKED_DESC') }}</p>
					<p v-if="user?.access_level?.withdrawal_block_reason" class="is-size-14">
						<span class="has-text-weight-medium">{{ $t('LOCK_REASON') }}</span>
						{{ user.access_level.withdrawal_block_reason }}
					</p>
				</div>
			</div>

			<div v-if="user?.access_level?.withdrawal_unblock_date" class="withdrawal_lock_section mt-15">
				{{ $t('funds.WITHDRAWAL_LOCK_UNTIL', { date: withdrawalUnlockDate }) }}
			</div>

			<div class="has-text-centered mt-20">
				<!-- Back to Funds -->
				<button class="button flat-white-btn large is-fullwidth mt-15" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>
		</div>

		<!-- withdrawal unlock code -->
		<div v-else-if="funds.withdraw_page === 'withdraw_code'">
			<div class="has-text-centered mt-30">
				<img src="@/assets/funds/email.svg" alt="email icon" />
			</div>

			<p class="provider-detail mt-20px" v-html="$t('funds.WITHDRAW_UNLOCK_CODE', { email: user?.email })">
			</p>

			<div class="mt-15">
				<b-input v-model="withdraw_unlock_code" class="history-search xlarge"
					:placeholder="$t('funds.WITHDRAW_2FA')" data-cy="withdrawAmount" :disabled="transfer_loading" />
			</div>
			<div class="user-notification is-size-7 provider-detail mt-5px">
				<img src="@/assets/funds/info-wallet.svg" alt="info icon" />
				<p>
					{{ $t('funds.WITHDRAW_2FA_RECEIVE') }}&nbsp;<a v-if="!resend_email" @click="resendEmail()">{{
						$t('funds.HERE') }}</a>&nbsp;{{ $t('funds.WITHDRAW_2FA_RESEND') }}
				</p>
			</div>

			<button class="button plain-red-btn is-fullwidth mt-15" :class="{ 'is-loading': unlock_processing }"
				@click="unlockWithdrawal()">
				{{ $t('SUBMIT') }}
			</button>
		</div>

		<!-- confirm sending to exchange wallet -->
		<div v-else-if="funds.withdraw_page === 'withdraw_exchange_check'">
			<!-- Destination Card -->
			<div class="transaction_details_section mt-15">
				<div class="transaction_header gap-8 no-border" v-if="funds.withdraw_currency">
					<img v-if="funds.withdraw_currency.icon" :src="imageURL('@/img/funds/tokens/destination.svg')"
						class="transaction_icon" />
					<div>
						<p>{{ $t('funds.DESTINATION') }}</p>
						<p class="is-size-12 has-text-weight-normal provider-detail">
							{{ formatEthAddressLong(funds.withdraw_account) }}
						</p>
					</div>
				</div>
			</div>

			<div class="mt-15">
				<p class="is-size-6 has-text-weight-medium is-line-height-20">{{ $t('funds.WITHDRAW_EXCHANGE') }}</p>
				<p class="is-size-14-spaced provider-detail">{{ $t('funds.WITHDRAW_EXCHANGE_EXAMPLES') }}</p>
				<div class="mt-5px">
					<div class="toggle-selector">
						<div class="toggle-control">
							<button class="toggle-button first-button" :class="{ 'is-active': active_btn.yes }"
								@click="active_btn = { yes: true, no: false }">
								{{ $t('funds.YES') }}
							</button>
						</div>
						<div class="toggle-control  last-button">
							<button class="toggle-button" :class="{ 'is-active': active_btn.no }"
								@click="active_btn = { yes: false, no: true }">
								{{ $t('funds.NO') }}
							</button>
						</div>
					</div>
				</div>
			</div>

			<!-- If yes and not sending POL, show warning -->
			<div v-if="active_btn.yes && funds.withdraw_currency.name === 'MPH'" class="wallet-exchange-error mt-15">
				<div class="is-flex is-align-items-center gap-8" v-if="funds.withdraw_currency">
					<div>
						<img src="@/assets/funds/warning-icon.svg" class="transaction_icon" />
					</div>
					<div class="is-size-14">
						<p class="is-line-height-1">
							<span class="provider-detail" v-html="$t('funds.WITHDRAW_MPH_NO_EXCHANGE')" />
						</p>
						<div class="mt-1">
							<a @click="switchPOL()" class="transition-faster is-size-12">{{ $t('funds.SWITCH_POL')
								}}</a>
						</div>
					</div>
				</div>
			</div>

			<!-- If yes and using POL, pick the exchange -->
			<div v-else-if="active_btn.yes" class="mt-15">
				<div>
					<p class="has-text-left has-text-weight-medium is-size-14 mb-1">{{ $t('funds.EXCHANGE_NAME') }}</p>
					<b-dropdown ref="wallet_exchange" v-model="wallet_exchange" aria-role="list" :mobile-modal="false"
						class="type-dropdown left-normal" expanded scrollable max-height="140px">
						<template #trigger="{ active }">
							<div
								class="is-flex is-align-items-center is-justify-content-space-between type-trigger transition-fast">
								<p v-if="!wallet_exchange" class="has-text-dark-grey">{{ $t('funds.WALLET_EXCHANGE') }}
								</p>
								<p v-if="wallet_exchange === 'binance'">Binance</p>
								<p v-if="wallet_exchange === 'coinbase'">Coinbase</p>
								<p v-if="wallet_exchange === 'kucoin'">KuCoin</p>
								<p v-if="wallet_exchange === 'kraken'">Kraken</p>
								<p v-if="wallet_exchange === 'gate.io'">Gate.io</p>
								<p v-if="wallet_exchange === 'bybit'">ByBit</p>
								<p v-if="wallet_exchange === 'other'">{{ $t('OTHER') }}</p>
								<img src="@/assets/funds/chevron-down-feather.svg" class="toggle-arrow-icon" :class="{
									'rotate-arrow': active
								}" />
							</div>
						</template>
						<b-dropdown-item value="binance" aria-role="listitem">Binance</b-dropdown-item>
						<b-dropdown-item value="coinbase" aria-role="listitem">Coinbase</b-dropdown-item>
						<b-dropdown-item value="kucoin" aria-role="listitem">KuCoin</b-dropdown-item>
						<b-dropdown-item value="kraken" aria-role="listitem">Kraken</b-dropdown-item>
						<b-dropdown-item value="gate.io" aria-role="listitem">Gate.io</b-dropdown-item>
						<b-dropdown-item value="bybit" aria-role="listitem">ByBit</b-dropdown-item>
						<b-dropdown-item value="other" aria-role="listitem">{{ $t('OTHER') }}</b-dropdown-item>
					</b-dropdown>
				</div>

				<!-- If "other" exchange, show disclaimer -->
				<div v-if="wallet_exchange == 'other'" class="mt-15 address-confirm">
					<span>{{ $t('funds.OTHER_EXCHANGE_WARNING') + ' ' }}</span>
					<a href="https://docs.polygon.technology/docs/develop/network-details/gas-token/"
						class="transition-faster" target="_blank" v-html="$t('funds.HERE')" />.
				</div>
				<div v-else style="height: 60px">
					<!-- Fixed spacing so dropdown doesn't go beyond bottom of modal -->
				</div>
			</div>

			<!-- If not exchange wallet, warn user -->
			<div v-else-if="active_btn.no" class="mt-15 address-confirm">
				<p>{{ $t('funds.PERSONAL_CONFIRM') }}</p>
			</div>

			<!-- Continue Button -->
			<button :disabled="(!active_btn.yes && !active_btn.no) ||
				(active_btn.yes && funds.withdraw_currency.name === 'MPH') ||
				(active_btn.yes && wallet_exchange == '')
				" class="button plain-red-btn is-fullwidth mt-20" @click="exchangeCheck()">
				{{ $t('CONTINUE') }}
			</button>
		</div>

		<!-- Withdraw Preview Screen -->
		<div v-else-if="funds.withdraw_page === 'withdraw_confirm'">
			<div class="transaction_details_section is-size-14 mt-15">
				<div class="transaction_header gap-8" v-if="funds.withdraw_currency">
					<img v-if="funds.withdraw_currency.icon"
						:src="imageURL('@/img/funds/tokens/' + funds.withdraw_currency.icon)" class="provider-icon" />
					<div>{{ roundFormatter(destination_amount) }} {{ funds.withdraw_currency.name }}</div>
				</div>

				<div class="transaction_details_lines">
					<div class="transaction_details_line transaction_details_line-border">
						<p class="has-text-weight-medium">
							{{ $t('funds.PRICE') }}
						</p>
						<p>${{ usdFormatter(balance.mph_rate) }} / MPH</p>
					</div>

					<div class="transaction_details_line transaction_details_line-border">
						<p class="has-text-weight-medium">
							{{ $t('funds.WITHDRAW_AMOUNT') }}
						</p>
						<p>${{ withdrawal_amount }}</p>
					</div>
					<div class="transaction_details_line transaction_details_line-border">
						<p class="has-text-weight-medium">
							{{ $t('funds.WITHDRAWAL_FEE') }}
							<b-tooltip :label="$t('funds.WITHDRAWAL_FEE_DESC')" size="is-medium" position="is-right"
								type="is-dark" class="ToolTipText" multilined animated>
								<a class="dark-hover transition-faster is-size-14">
									<b-icon icon="question-circle" pack="fas" size="is-small" />
								</a>
							</b-tooltip>
						</p>
						<p>${{ usdFormatter((network_fee + 100) * balance.mph_rate) }}</p>
					</div>

					<div class="transaction_details_line">
						<p class="has-text-weight-medium">
							{{ $t('funds.TOTAL_VALUE') }}
						</p>
						<div class="has-text-right">
							<p>${{ floorRound((withdrawal_amount_total - (network_fee + 100)) * balance.mph_rate,
								2) }}</p>
							<p class="transaction_details_info_extra">
								{{ roundFormatter(withdrawal_amount_total - (network_fee + 100)) }} MPH
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="transaction_details_section mt-15">
				<div class="transaction_header gap-8" v-if="funds.withdraw_currency">
					<img v-if="funds.withdraw_currency.icon" :src="imageURL('@/img/funds/tokens/destination.svg')"
						class="transaction_icon" />
					<div>
						<p class="">{{ $t('funds.DESTINATION') }}</p>
						<p class="is-size-12 has-text-weight-normal provider-detail">
							<span v-if="funds.withdraw_wallet_type == 'exchange'">
								{{ $t('funds.WALLET_TYPE_EXCHANGE') }} -
							</span>
							{{ formatEthAddressLong(funds.withdraw_account) }}
						</p>
					</div>
				</div>

				<div class="transaction_details_lines">
					<p>
						{{
							$t('funds.WITHHDRAW_DESTINATION_DESCRIPTION', {
								token: funds.withdraw_currency.name,
								network: $t('chains.' + funds.withdraw_currency.chain.toUpperCase())
							})
						}}
						{{ $t('funds.LEARN_MORE_WITHDRAWALS') }}
						<a target="_blank" :href="supportArticleLink('Withdraw')">{{ $t('funds.HERE') }}</a>.
					</p>
				</div>
			</div>


			<!-- Back/Continue Buttons -->
			<button class="button plain-red-btn is-fullwidth mt-20" :class="{ 'is-loading': transfer_loading }"
				data-cy="finalizeWithdraw"
				:disabled="Number(withdrawal_amount) > Number((activePortfolio?.cash_balance || 0) / Math.pow(10, 18)) || impersonating"
				@click="withdrawStart()">
				{{ $t('CONTINUE') }}
			</button>
		</div>

		<!-- withdraw in progress -->
		<div v-else-if="funds.withdraw_page === 'withdraw_processing'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/processing.svg" alt="pending icon" />
			</div>

			<div class="payment_provider_section">
				<div
					v-if="transaction_data && transaction_data?.payload && transaction_data?.payload?.purchase_crypto_currency">
					<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')"
						class="transaction_icon" />
				</div>

				<div class="provider_text">
					<div class="provider_header">
						{{ $t('chains.' + funds.withdraw_currency.chain.toUpperCase() + '') + ' ' + $t('WITHDRAWAL') }}
					</div>
					<p class="is-size-14-spaced">{{ $t('funds.WITHDRAWAL_TRANSACTION_PROCESSING') }} {{
						$t('funds.CLOSE_WINDOW')
					}}</p>
					<p class="is-size-7">
						{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS') }}</a>
					</p>
				</div>
			</div>

			<!-- Back to Funds -->
			<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
				{{ $t('funds.CLOSE_PAYMENT') }}
			</button>
		</div>

		<!-- moonpay withdraw in progress -->
		<div v-else-if="funds.withdraw_page === 'withdraw_processing_moonpay'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/processing.svg" alt="pending icon" />
			</div>
			<div class="payment_provider_section">
				<div
					v-if="transaction_data && transaction_data.payload && transaction_data.payload.purchase_crypto_currency">
					<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')"
						class="transaction_icon" />
				</div>
				<div class="is-flex">
					<img src="@/assets/funds/success-checkmark.svg" alt="success checkmark" class="success-check" />

					<div class="provider_text">

						<p class="is-size-12">{{ $t('funds.WITHDRAWAL_TRANSACTION_PROCESSING_MOONPAY') }} {{
							$t('funds.CLOSE_WINDOW') }}</p>
						<p class="is-size-7">
							{{ $t('funds.TRANSACTION_DETAILS') }} <a @click="cancelPurchase()">{{ $t('funds.FUNDS')
								}}</a>
						</p>
					</div>
				</div>
			</div>

			<!-- Back to Funds -->
			<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
				{{ $t('funds.CLOSE_PAYMENT') }}
			</button>
		</div>


		<!-- withdraw error -->
		<div v-else-if="funds.withdraw_page === 'withdraw_error'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/withdraw_error.svg" alt="pending icon" />
			</div>

			<div class="payment_provider_section">
				<div
					v-if="transaction_data && transaction_data.payload && transaction_data.payload.purchase_crypto_currency">
					<img :src="imageURL('@/img/funds/tokens/' + transaction_data.payload.purchase_crypto_currency.toLowerCase() + '.svg')"
						class="transaction_icon" />
				</div>

				<div class="provider_text">
					<p class="is-size-14-spaced">
						{{ $t('funds.WITHDRAW_ERROR_DETAILS') }}
					</p>

					<p class="is-size-7 provider-detail">
						{{ $t('funds.LEARN_MORE_WITHDRAWALS') }}
						<a target="_blank" :href="supportArticleLink('Withdraw')">{{ $t('funds.HERE') }}</a>.
					</p>
				</div>
			</div>

			<!-- Back to Funds -->
			<button class="button flat-white-btn xlarge is-fullwidth mt-15" @click="cancelPurchase()">
				{{ $t('funds.CLOSE_PAYMENT') }}
			</button>
		</div>

		<!-- withdraw in progress - morpher wallet -->
		<div v-else-if="funds.withdraw_page === 'withdraw_progress' && walletType == 'morpherwallet'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/morpher-transfer.svg" alt="pending icon" />
			</div>

			<div class="payment_provider_section">
				<div>
					<img src="../../assets/funds/wallet-search.svg" class="transaction_icon" alt="wallet icon" />
				</div>

				<div class="provider_text">
					<p class="has-text-weight-medium is-line-height-20">
						{{ $t('funds.WALLET_TRANSFER') }}
					</p>
					<p class="is-size-14-spaced">
						{{ $t('funds.WALLET_TRANSFER_DESC') }}
					</p>
				</div>
			</div>

			<!-- Back/Continue Buttons -->
			<div class="has-text-centered mt-20">
				<button class="button plain-red-btn is-fullwidth mt-20" :class="{ 'is-loading': transfer_loading }"
					data-cy="finalizeWithdraw"
					:disabled="Number(withdrawal_amount) > Number((activePortfolio?.cash_balance || 0) / Math.pow(10, 18)) || impersonating"
					@click="withdrawRequest()">
					{{ $t('WITHDRAW_NOW') }}
				</button>
			</div>

			<!-- Back to Funds -->
			<button class="button flat-white-btn large is-fullwidth mt-15" @click="cancelPurchase()">
				{{ $t('CANCEL') }}
			</button>
		</div>

		<!-- withdraw in progress - any other wallet -->
		<div v-else-if="funds.withdraw_page === 'withdraw_progress' && walletType !== 'morpherwallet'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/morpher-transfer.svg" alt="pending icon" />
			</div>

			<div class="regular-section">
				<div class="regular-header" v-if="funds.withdraw_currency">
					<img v-if="withdraw_progress_step == 1" class="step_icon" src="@/assets/icons/loader.svg" />
					<img v-else class="step_icon" src="../../assets/funds/selected.svg" />
					<div>
						<p class="has-text-weight-medium">{{ $t('funds.WITHDRAW_PROGRESS_STEP_1') }}</p>
						<p v-if="withdraw_progress_step == 1" class="provider-detail ">
							{{ $t('funds.WITHDRAW_PROGRESS_CHECK_WALLET') }}
						</p>
					</div>
				</div>

				<div class="regular-header bottom-none" v-if="funds.withdraw_currency">
					<img v-if="withdraw_progress_step == 1" class="step_icon"
						src="../../assets/funds/not-selected.svg" />
					<img v-else class="step_icon" src="@/assets/icons/loader.svg" />
					<div class="is-size-6 has-text-weight-medium">
						<p class="has-text-weight-medium ">{{ $t('funds.WITHDRAW_PROGRESS_STEP_2') }}</p>
						<p v-if="withdraw_progress_step == 2" class="provider-detail">
							{{ $t('funds.WITHDRAW_PROGRESS_CHECK_WALLET') }}
						</p>
					</div>
				</div>
			</div>

			<div class="payment_provider_section mt-15 is-align-items-center">
				<img src="../../assets/funds/gas.svg" class="transaction_icon mr-5px" />
				<div class="provider_text">
					<p class="is-size-12 is-line-height-1">
						{{ $t('funds.WALLET_TRANSFER_GAS') }}
					</p>
				</div>
			</div>

			<!-- Back to Funds -->
			<button class="button flat-white-btn large is-fullwidth mt-20" @click="cancelPurchase()">
				{{ $t('CANCEL') }}
			</button>
		</div>

		<!-- successful withdrawal -->
		<div v-else-if="funds.withdraw_page === 'withdraw_complete'">
			<div class="has-text-centered pending-img">
				<img src="@/assets/funds/success.svg" alt="success checkmark" />
			</div>

			<div class="transaction_details_section" v-if="transaction_data">
				<div class="transaction_header gap-8" v-if="transaction_data.payload.currency">
					<img v-if="transaction_data.payload.currency"
						:src="imageURL('@/img/funds/tokens/' + transaction_data.payload.currency.toLowerCase() + '.svg')"
						class="provider-icon" />
					<div class="">
						{{ roundFormatter(transaction_data.payload.destination_amount / Math.pow(10, 18)) }}
						{{ transaction_data.payload.currency }}
					</div>
				</div>

				<div class="transaction_details_lines">
					<div class="transaction_details_line transaction_details_line-border">
						<p class="has-text-weight-medium">
							{{ $t('funds.PRICE') }}
						</p>
						<p>${{ usdFormatter(transaction_data.payload.mph_price) }} / MPH</p>
					</div>

					<div class="transaction_details_line transaction_details_line-border">
						<p class="has-text-weight-medium">
							{{ $t('funds.WITHDRAW_AMOUNT') }}
						</p>
						<p>
							${{
								usdFormatter(
									(transaction_data.payload.tokens / Math.pow(10, 18) + 100) *
									transaction_data.payload.mph_price
								)
							}}
						</p>
					</div>
					<div class="transaction_details_line transaction_details_line-border">
						<p class="has-text-weight-medium">
							{{ $t('funds.WITHDRAWAL_FEE') }}
							<b-tooltip :label="$t('funds.WITHDRAWAL_FEE_DESC')" size="is-small" position="is-right"
								type="is-dark" class="ToolTipText" multilined animated>
								<a class="dark-hover transition-faster is-size-14">
									<b-icon icon="question-circle" pack="fas" size="is-small" />
								</a>
							</b-tooltip>
						</p>
						<p>
							${{
								usdFormatter(
									((transaction_data.payload.conversion_fee || 0) / Math.pow(10, 18) + 100) *
									transaction_data.payload.mph_price
								)
							}}
						</p>
					</div>

					<div class="transaction_details_line">
						<p class="has-text-weight-medium">
							{{ $t('funds.TOTAL_VALUE') }}
						</p>
						<div class="has-text-right">
							<p>
								${{
									usdFormatter(
										(transaction_data.payload.tokens / Math.pow(10, 18) -
											(transaction_data.payload.conversion_fee || 0) / Math.pow(10, 18)) *
										transaction_data.payload.mph_price
									)
								}}
							</p>
							<p class="transaction_details_info_extra">
								{{
									roundFormatter(
										transaction_data.payload.tokens / Math.pow(10, 18) -
										(transaction_data.payload.conversion_fee || 0) / Math.pow(10, 18)
									)
								}}
								MPH
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="deposit_section no_shadow mt-15">
				<div class="wallet-details"
					v-if="transaction_data && transaction_data.payload && funds.withdraw_currency">
					<div class="centered-flex-gap gap-8">
						<img v-if="funds.withdraw_currency.icon" :src="imageURL('@/img/funds/tokens/destination.svg')"
							class="transaction_icon" />
						<div class="is-line-height-20">
							<p class="has-text-weight-medium  is-size-6">
								{{ $t('funds.DESTINATION') }}
							</p>
							<p class="is-size-14 provider-detail">
								<span v-if="transaction_data.payload.withdraw_wallet_type == 'exchange'">
									{{ $t('funds.WALLET_TYPE_EXCHANGE') }} -
								</span>
								{{ formatEthAddressLong(transaction_data.payload.destinationAddress) }}
							</p>
						</div>
					</div>
					<button v-if="new_address" class="button back-btn ml-1"
						@click="bookmarkAddress(transaction_data.payload.destinationAddress)"
						:title="$t('funds.ADD_WALLET')">
						<img src="@/assets/funds/bookmark.svg" class="bookmark-icon" />
					</button>
				</div>
			</div>

			<!-- Back/Continue Buttons -->
			<div class="has-text-centered mt-20">
				<!-- Back to Funds -->
				<button class="button flat-white-btn large is-fullwidth mt-20" @click="cancelPurchase()">
					{{ $t('funds.CLOSE_PAYMENT') }}
				</button>
			</div>
		</div>

		<!-- default withdrawal screen - select amount -->
		<div v-else>
			<div v-if="isMobile">
				<div style="height: 10px" />
				<button class="button back-btn hover-position" @click="closeModal()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
			</div>

			<div class="withdraw-input">
				<div v-if="os !== 'ios'" class="currency-symbol" :style="{ order: 1 }">$</div>
				<input v-if="os !== 'ios'" ref="withdraw_amount" v-model="withdrawal_amount" class="withdraw-amount"
					v-autowidth="{ maxWidth: '310px', minWidth: '20px', comfortZone: '1ch', }" style="order: 2" />
				<input v-else ref="withdraw_amount" v-model="withdrawal_amount" class="withdraw-amount"
					style="width: 100%" />
			</div>

			<div class="withdraw-message" v-if="balanceLoaded">
				<div :class="{ 'withdraw-message-error': withdraw_limit_exceeded || withdraw_amount_fees }">
					{{ withdrawal_message }}
					<a v-if="withdraw_limit_exceeded" class="has-text-weight-medium transition-faster"
						@click="withdrawMax()">{{
							$t('funds.WITHDRAW_MAX')
						}}</a>

					<div class="pending-rewards-tag" v-if="withdraw_limit_exceeded && airdropBlocked > 0">
						{{ $t('PENDING_REWARDS') }}: ${{ usdFormatter(airdropBlocked * balance.mph_rate) }}
						<img src="@/assets/icons/new/help.svg" class="info-icon ml-1" @click="openWithdrawLimit()" />
					</div>
				</div>
				<!-- If user is exceeding withdrawal limits, show helpful info link -->
				<div class="user-notification is-size-7 provider-detail mt-5px is-justify-content-center" v-if="
					withdrawal_amount_total > withdraw_limit &&
					withdrawal_amount_total < Number((activePortfolio?.cash_balance || 0) / Math.pow(10, 18)) &&
					airdropBlocked == 0
				">
					<img src="@/assets/funds/info-wallet.svg" alt="info icon" />
					<p>
						{{ $t('LIMITS_INFO_LINK') }}
						<a target="_blank" :href="supportArticleLink('WithdrawLimit')">{{
							$t('funds.HERE')
						}}</a>.
					</p>
				</div>
			</div>

			<div class="amount-percentage-selector mt-15">
				<div class="amount-percentage-control">
					<button class="amount-percentage-button transition-faster" @click="setDefaultAmount(25)">
						25%
					</button>
				</div>
				<div class="amount-percentage-control">
					<button class="amount-percentage-button transition-faster" @click="setDefaultAmount(50)">
						50%
					</button>
				</div>
				<div class="amount-percentage-control">
					<button class="amount-percentage-button transition-faster" @click="setDefaultAmount(75)">
						75%
					</button>
				</div>
				<div class="amount-percentage-control">
					<button class="amount-percentage-button transition-faster last-button"
						@click="setDefaultAmount(100)">
						100%
					</button>
				</div>
			</div>

			<div class="deposit_section mt-15">
				<div class="provider-header" @click="showWithdrawMethod()">
					<div class="centered-flex-gap gap-8" v-if="funds.withdraw_currency">
						<img v-if="funds.withdraw_currency.icon"
							:src="imageURL('@/img/funds/tokens/' + funds.withdraw_currency.icon)"
							class="provider-icon" />
						<div>
							<p>{{ funds.withdraw_currency.name }}</p>
							<p class="provider-detail" v-if="funds.withdraw_currency.chain === 'moonpay'">
								{{ $t('ESTIMATE') }}: {{ withdrawal_estimate }}
							</p>
							<p class="provider-detail" v-else>
								{{
									$t('funds.WITHDRAW_NETWORK', {
										network: $t('chains.' + funds.withdraw_currency.chain.toUpperCase() + '')
									})
								}}
							</p>
						</div>
					</div>

					<img src="@/assets/funds/arrow-right.svg" />
				</div>

				<div class="deposit-section" v-if="funds.withdraw_currency.chain === 'moonpay'">
					<div class="top-flex-gap gap-8" v-if="funds.provider && funds.provider === 'moonpay'">
						<img v-if="funds.provider && funds.provider === 'moonpay'"
							:src="imageURL(`@/img/funds/${funds.provider}.png`)" class="provider-icon"
							alt="payment provider" />

						<div class="ml-5px">
							<p class="has-text-weight-bold">{{ $t('funds.MOONAPAY_OFFRAMP') }}</p>
							<p class="provider-detail">
								{{ $t('funds.MOONAPAY_WITHDRAWAL_METHODS') }}
							</p>
							<p class="provider-detail">
								{{ $t('funds.MOONAPAY_FUNDS') }}
								<a class="has-text-weight-bold" target="_blank"
									:href="supportArticleLink('WithdrawBankAccount')">{{ $t('LEARN_MORE') }}</a>
							</p>
						</div>

					</div>

					<div class="centered-flex-gap gap-8" v-else-if="funds?.provider">
						<img :src="imageURL(`@/img/funds/${funds.provider}.svg`)" class="provider-icon" />

						<div class="ml-5px">
							<p class="">{{ $t('providers.' + funds?.provider?.toUpperCase()) }}</p>
							<p class="provider-detail">
								{{
									$t('funds.PAYMENT_PROVIDER')
								}}
							</p>

						</div>
					</div>

					<img v-if="funds.provider !== 'moonpay'" src="@/assets/funds/arrow-right.svg" />
				</div>

				<div v-else class="wallet-select" :class="{ 'address-invalid': address_invalid }"
					@click="showWithdrawAddress()">
					<div class="centered-flex-gap gap-8" v-if="funds.withdraw_currency">
						<img v-if="funds.withdraw_currency.icon" src="@/assets/funds/tokens/destination.svg"
							class="provider-icon" />

						<div>
							<p>{{ funds.withdraw_account_name || $t('funds.DESTINATION') }}</p>

							<span
								v-if="funds.withdraw_wallet_type == 'exchange' && funds.withdraw_currency.name === 'MPH'">
								<p class="withdraw-message-error is-size-14-spaced is-weight-400">
									{{ $t('funds.WITHDRAW_EXCHANGE_ERROR') }}
								</p>
							</span>
							<span v-else>
								<p v-if="funds.withdraw_account && funds.withdraw_wallet_type" class="provider-detail">
									<span v-if="funds.withdraw_wallet_type == 'exchange'">
										{{ $t('funds.WALLET_TYPE_EXCHANGE') }} -
									</span>
									{{ formatEthAddressLong(funds.withdraw_account) }}
								</p>
								<p v-else class="provider-detail">
									{{ $t('funds.WALLET_ADDRESS_ENTER') }}
								</p>
							</span>
						</div>


					</div>

					<img src="@/assets/funds/arrow-right.svg" />
				</div>
			</div>

			<div class="wallet-exchange-error mt-15" v-if="funds.withdraw_currency.name === 'MPH'">
				<div class="is-flex is-align-items-center gap-8" v-if="funds.withdraw_currency">
					<div>
						<img src="@/assets/funds/warning-icon.svg" class="transaction_icon" />
					</div>
					<div class="is-size-14">
						<p class="is-line-height-1">
							<span class="provider-detail" v-html="$t('funds.WITHDRAW_MPH_NO_EXCHANGE')" />
						</p>
						<div class="mt-1">
							<a @click="switchPOL()" class="transition-faster is-size-12">{{ $t('funds.SWITCH_POL')
								}}</a>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="funds?.withdraw_currency?.chain !== 'moonpay'" class="deposit_section no_shadow mt-15">
				<div>
					<div class="centered-flex-gap gap-8" v-if="funds.withdraw_currency">
						<img v-if="funds.withdraw_currency.icon" :src="imageURL('@/img/funds/tokens/binance.svg')"
							class="provider-icon" />
						<p class="is-line-height-1 provider-detail"
							v-html="$t('funds.WITHDRAW_FEES_LEARN_MORE', { link: supportArticleLink('WithdrawExchange') })">
						</p>
					</div>
				</div>
			</div>
			<!-- Back/Continue Buttons -->
			<button class="button plain-red-btn is-fullwidth mt-15" data-cy="continueWithdraw"
				:class="{ 'is-loading': withdrawWaiting }" :disabled="withdrawDisabled ||
					withdraw_limit_exceeded ||
					withdraw_amount_fees ||
					!withdrawal_amount ||
					withdrawal_amount_number() == 0 ||
					(funds.withdraw_currency.chain !== 'moonpay' && !funds.withdraw_account) ||
					impersonating ||
					(funds.withdraw_wallet_type == 'exchange' && funds.withdraw_currency.name === 'MPH')
					" @click="checkAmount">
				{{ $t('WITHDRAW') }}
			</button>
			<div v-if="withdrawDisabled" class="mt-2 is-flex is-align-items-center">
				<i class="fas fa-exclamation-triangle is-size-6"></i>
				<p class="ml-2 is-size-7">Due to the Ethereum Update, withdrawals are temporarily disabled.</p>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { useMarketsStore } from '@/store/modules/markets';
import { useFundsStore, type FundsState } from '@/store/modules/funds';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import VueQrcode from '@chenfengyuan/vue-qrcode';
//vuex
import { TransactionService } from '@/services/TransactionService';
import { ERROR, SUCCESS } from '@/store/mutation-types';
import EventBus from '@/store/event-bus';
import { PortfolioService } from '@/services/PortfolioService';
import { LOADING } from '@/store/mutation-types';
import { mapActions, mapState } from 'pinia';
import { useStatusStore } from '@/store/modules/status';
import { parse } from '@/helpers/uaparser';
import type { Market } from '@/types/schema';
import { encodePacked, isAddress, keccak256, parseEventLogs } from 'viem';
import type { TAddress } from '@/types/graphql-global-types';
import { getBridgeContract } from '@/helpers/viem';
import { morpherBridgeAbi } from '@/contracts/abis';


/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'Withdraw',
	components: {
		VueQrcode
	},
	props: {
		ethMarket: {
			type: Object as PropType<any>,
			required: false
		},
		polMarket: {
			type: Object as PropType<any>,
			required: false
		},
		balanceLoaded: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			isMobile: false,
			withdrawal_limit_day: 200000,
			withdrawal_limit_month: 1000000,
			withdrawal_per_day: true,
			withdrawal_per_month: true,
			bonus_string: '10%',
			withdrawal_amount: '0',
			transfer_loading: false,
			withdraw_account: '',
			address_invalid: false,
			w_approvals: [false, false, false],
			withdraw_network: 'polygon',
			withdraw_unlock_code: '',
			unlock_processing: false,
			number_seperators: {} as {
				decimal: string,
				thousand: string
			},
			withdraw_limit_exceeded: false,
			withdraw_amount_fees: false,
			resend_email: false,
			transaction_data: null as any,
			new_address: false,
			waitOnMetamaskInterval: null as null | NodeJS.Timeout | number,
			withdraw_progress_step: 1,
			os: '',
			active_btn: { yes: false, no: false },
			exchange_confirm_status: '',
			wallet_exchange: '',
			withdrawDisabled: false,
			withdrawWaiting: false,
			eur_market: null as Market | null,
			gbp_market: null as Market | null,
			rub_market: null as Market | null,
			pol_market: null as Market | null,
			withdraw_amount_mph: null as any
		};
	},
	watch: {
		withdrawal_amount() {
			if (!this.withdrawal_amount) {
				return this.withdrawal_amount;
			}

			this.withdrawal_amount = this.formatAmount(this.withdrawal_amount);
			this.updateFunds({ withdraw_amount: Number(this.withdrawal_amount) });
		},

	},
	computed: {
		...mapState(useContractStore, {
			balance: (state) => state.balance,
			walletClient: (state) => state.walletClient,
			provider: (state) => state.provider,
			network_id: (state) => state.network_id,
			walletType: (state) => state.walletType,
			morpherwallet: (state) => state.morpherwallet,
			morpherOracleLoaded: (state) => state.morpherOracleLoaded,
			wrongNetwork: (state) => state.wrongNetwork,
			wrongWallet: (state) => state.wrongWallet,
			isLoggedInWallet: (state) => state.isLoggedInWallet,
			morpherBridge: (state) => state.morpherBridge,
			eth_address: state => state.eth_address,


		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			activePortfolio: (state) => state.data?.activePortfolio,
			impersonating: state => state.impersonating

		}),
		...mapState(useFundsStore, {
			funds: (state) => state,
		}),
		...mapState(useMarketsStore, {
			markets: (state) => state.data,
		}),
		withdrawal_estimate() {
			try {
				if (this.funds.withdraw_currency.chain !== 'moonpay') {
					return this.currencySymbol(this.funds.withdraw_currency.name) + '0'
				}

				let amount_usd = this.withdrawal_amount_number()
				if (!amount_usd || amount_usd == 0) {
					return this.currencySymbol(this.funds.withdraw_currency.name) + '0'
				}

				let pol_exchange = this.currencyExchange('pol')
				let currency_conversion = this.funds.withdraw_conversion[this.funds.withdraw_currency.name]

				// use the exact conversion from moonpay if we have it
				if (pol_exchange > 0 && currency_conversion && currency_conversion > 0) {
					let amount_pol = Number(amount_usd) * pol_exchange;

					let amount_curr = amount_pol * currency_conversion

					let fees = amount_curr * .01
					if (fees < 3.99) {
						fees = 3.99
					}
					if (amount_curr > fees) {
						amount_curr -= fees
					} else {
						amount_curr = 0;
					}

					return this.currencySymbol(this.funds.withdraw_currency.name) + this.usdFormatter(amount_curr)

				}


				let exchange = this.currencyExchange(this.funds.withdraw_currency.name)
				let amount_curr = Number(amount_usd) / exchange;

				let fees = amount_curr * .01
				if (fees < 3.99) {
					fees = 3.99
				}
				if (amount_curr > fees) {
					amount_curr -= fees
				} else {
					amount_curr = 0;
				}

				return this.currencySymbol(this.funds.withdraw_currency.name) + this.usdFormatter(amount_curr)
			} catch (err: any) {
				console.log('withdrawal_estimate', err.toString())
			}
		},
		airdropBlocked() {
			if (this.user && this.user.airdrop) {
				return (this.user?.airdrop?.withdrawal_blocked || 0) / Math.pow(10, 18);
			} else {
				return 0;
			}
		},
		// Determine maximum user can deposit today based on daily/weekly/yearly limits
		maxLeft() {
			if (this.balance.purchase_limits.purchase_crypto) {
				// Check crypto Limit
				return Number(this.usdFormatter(20000 - this.balance.purchase_limits.purchase_crypto));
			} else {
				return Number(this.usdFormatter(20000));
			}
		},
		network_fee() {
			if (this.funds.withdraw_currency.chain == 'polygon') {
				return 0;
			}
			if (this.funds.withdraw_currency.chain == 'moonpay') {
				return 0;
			}
			const gas_price_eth = this.balance.gas_price * 400000;
			const gas_price_usd = (gas_price_eth * this.balance.eth_price) / Math.pow(10, 9);
			const gas_price_mph = gas_price_usd / this.balance.mph_rate;
			return Math.ceil(gas_price_mph / 500) * 500;
		},
		withdrawal_amount_total() {
			if (this.withdraw_amount_mph) {
				return this.withdraw_amount_mph
			}
			let withdrawal_amount_total = this.withdrawal_amount_number() / this.balance.mph_rate;

			if (withdrawal_amount_total > 0) {
				withdrawal_amount_total = Math.round(withdrawal_amount_total * 100000000) / 100000000;
				return withdrawal_amount_total;
			} else {
				return 0;
			}
		},
		// Calculate Withdrawal Date
		withdrawalUnlockDate() {
			const unlockDate = new Date(Number(this.user?.access_level?.withdrawal_unblock_date || 0));
			const day = unlockDate.getDate();
			const months = [
				this.$t('months.JANUARY'),
				this.$t('months.FEBRUARY'),
				this.$t('months.MARCH'),
				this.$t('months.APRIL'),
				this.$t('months.MAY'),
				this.$t('months.JUNE'),
				this.$t('months.JULY'),
				this.$t('months.AUGUST'),
				this.$t('months.SEPTEMBER'),
				this.$t('months.OCTOBER'),
				this.$t('months.NOVEMBER'),
				this.$t('months.DECEMBER')
			];
			const month = months[unlockDate.getMonth()];
			const year = unlockDate.getFullYear();
			return month + ' ' + day + ', ' + year;
		},
		withdraw_limit() {
			if (!this.balance || !this.balance.purchase_limits) {
				return 0;
			}
			const limit_day = 200000 - this.balance.purchase_limits.withdrawal_day;
			const limit_month = 1000000 - this.balance.purchase_limits.withdrawal_month;
			const limit_year = 5000000 - this.balance.purchase_limits.withdrawal_year;
			const user_balance = this.balance.mph_sidechain / Math.pow(10, 18);
			if (this.airdropBlocked > 0) {
				const withdrawal_blocked = (this.user?.activePortfolio?.current_value || 0) / Math.pow(10, 18) - this.airdropBlocked;

				return Math.min(limit_day, limit_month, limit_year, user_balance, withdrawal_blocked);
			} else {
				return Math.min(limit_day, limit_month, limit_year, user_balance);
			}
		},
		calcMinAmount() {

			let pol = this.polMarket?.close || 0.88
			let usd = 22 * pol;

			let mph_amount = 100 * (this.balance?.mph_rate || 0);
			usd += mph_amount;


			return usd

		},
		withdrawal_message() {
			let message = '';

			if (this.withdrawal_amount && this.withdrawal_amount !== '0' && this.withdrawal_amount_number() > 0) {
				let mph_amount = this.withdrawal_amount_number() / this.balance.mph_rate;

				const withdraw_limit = this.withdraw_limit;

				if (mph_amount > Number((this.activePortfolio?.cash_balance || 0) / Math.pow(10, 18))) {
					this.withdraw_limit_exceeded = true;
					this.withdraw_amount_fees = false;
					message = this.$t('funds.WITHDRAW_BALANCE', {
						symbol: '$',
						amount: this.usdFormatter(withdraw_limit * this.balance.mph_rate)
					});
				} else if (mph_amount > withdraw_limit) {
					this.withdraw_limit_exceeded = true;
					this.withdraw_amount_fees = false;
					message = this.$t('funds.WITHDRAW_CANNOT_EXCEED', {
						symbol: '$',
						amount: this.usdFormatter(withdraw_limit * this.balance.mph_rate)
					});
				} else {
					if (this.funds.withdraw_currency.chain === 'moonpay' && this.calcMinAmount > this.withdrawal_amount_number()) {
						this.withdraw_amount_fees = true;
						message = this.$t('funds.WITHDRAW_MIN_MOONPAY', {
							symbol: '$',
							amount: this.usdFormatter(this.calcMinAmount)
						});
					} else if (mph_amount < this.network_fee + 100) {
						this.withdraw_amount_fees = true;
						message = this.$t('funds.WITHDRAW_LESS_THAN_FEES', {
							symbol: '$',
							amount: this.usdFormatter((this.network_fee + 100) * this.balance.mph_rate + 0.01)
						});
					} else {
						this.withdraw_limit_exceeded = false;

						this.withdraw_amount_fees = false;
						message =
							this.roundFormatter(this.withdrawal_amount_number() / this.balance.mph_rate - (this.network_fee + 100)) +
							' MPH';
					}
				}
			} else {
				this.withdraw_limit_exceeded = false;
				this.withdraw_amount_fees = false;
				let balance = 0;
				if (this.balance && this.balance.mph_sidechain) {
					balance = this.balance.mph_sidechain / Math.pow(10, 18);
				}

				message = this.$t('funds.CURRENT_BALANCE') + ' $ ' + this.round(balance * this.balance.mph_rate, 2);
			}
			return message;
		},
		destination_amount() {
			if (this.funds.withdraw_currency.name.toLowerCase() == 'pol' || this.funds.withdraw_currency.chain === 'moonpay') {
				const amount_mph = this.withdrawal_amount_total - 100 - this.network_fee;
				const amount_usd = amount_mph * this.balance.mph_rate;
				if (this.polMarket && this.polMarket.close) {
					return amount_usd / this.polMarket.close;
				} else {
					return '';
				}
			} else if (this.funds.withdraw_currency.name.toLowerCase() == 'eth') {
				const amount_mph = this.withdrawal_amount_total - 100 - this.network_fee;
				const amount_usd = amount_mph * this.balance.mph_rate;
				if (this.ethMarket && this.ethMarket.close) {
					return amount_usd / this.ethMarket.close;
				} else {
					return '';
				}
			} else {
				return this.withdrawal_amount_total - 100 - this.network_fee;
			}
		}
	},
	async mounted() {
		if (this.funds?.withdraw_amount) {
			this.withdrawal_amount = String(this.funds.withdraw_amount);
		}

		this.handleResize();

		setTimeout(() => {
			this.focusWithdrawAmount();
		}, 50);

		EventBus.$off('payment_update', this.handlePaymentUpdate);
		EventBus.$on('payment_update', this.handlePaymentUpdate);
		this.loadMarket();
		const browser = parse(navigator.userAgent);
		this.os = browser.os.toLowerCase();
		if (this.os == 'ipad' || this.os == 'iphone') {
			this.os = 'ios';
		}

		if (this.funds.step == "moonpay_deposit") {
			this.transaction_data = this.funds.current_order;
			this.withdraw_amount_mph = this.transaction_data.payload.withdraw_amount_mph;

			this.withdrawStart();
		}

		let cur = this.funds.withdraw_currency.name
		if (['EUR', 'GBP'].includes(cur) && !this.funds.withdraw_conversion[cur]) {
			this.updateCurrencyConversion(cur)
		}

		if (['EUR', 'GBP'].includes(cur)) {
			if (this.funds.provider !== 'moonpay') {
				this.updateFunds({ provider: 'moonpay' })
			}
		}


	},

	unmounted() {
		window.removeEventListener('resize', this.handleResize);

	},
	async created() {
		this.getNumberSeparators();
		window.addEventListener('resize', this.handleResize);
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		...mapActions(useStatusStore, {
			error: ERROR,
			success: SUCCESS,
			loading: LOADING
		}),

		...mapActions(useUserStore, {
			getTokenUserData: 'getTokenUserData',
			checkWithdrawUnlock: 'checkWithdrawUnlock',
			unlockWithdraw: 'unlockWithdraw',
		}),
		...mapActions(useModalsStore, {
			toggleUpgradeTierModal: 'toggleUpgradeTierModal',
			toggleDepositWithdrawModal: 'toggleDepositWithdrawModal',
			toggleNetworkMetamaskModal: 'toggleNetworkMetamaskModal',
		}),
		...mapActions(useContractStore, {
			setNetwork: 'setNetwork',
			startWallet: 'startWallet',
			morpherwalletLogout: 'morpherwalletLogout',
		}),
		...mapActions(useFundsStore, {
			updateFunds: 'updateFunds',
			resetFunds: 'resetFunds',
		}),

		handlePaymentUpdate(data: any) {
			if (data && data.provider == 'withdrawal' && data.status == 'success') {
				if (this.funds.withdraw_wallet_exchange == 'moonpay') {
					this.updatePageStatus('withdraw_processing_moonpay');
				} else {
					this.updatePageStatus('withdraw_complete');
				}


				if (data.transaction_id) {
					this.getTransactionInfo(data.transaction_id);
				}
			}

			if (data && data.provider == 'withdrawal' && data.status == 'failed') {
				this.updatePageStatus('withdraw_error');
				if (data.transaction_id) {
					this.getTransactionInfo(data.transaction_id);
				}
			}
		},
		focusWithdrawAmount() {
			if (!this.funds.withdraw_page) {
				if (this.$refs.withdraw_amount) {
					(this.$refs.withdraw_amount as InstanceType<typeof HTMLInputElement>).focus();
				} else {
					setTimeout(() => {
						this.focusWithdrawAmount();
					}, 50);
				}
			}
		},


		updateCurrencyConversion(currency: string) {
			let conversion = this.funds.withdraw_conversion
			let cur = 'POL_POLYGON'.toLowerCase()
			let url = `https://api.moonpay.com/v3/currencies/${cur}/sell_quote?quoteCurrencyCode=${currency.toLowerCase()}&baseCurrencyAmount=100&extraFeePercentage=0&apiKey=${import.meta.env.VITE_MOONPAY_API_KEY}`
			fetch(url).then(async (result) => {
				try {
					let data = await result.json()
					conversion[currency] = data.baseCurrencyPrice || -1
					await this.updateFunds({ withdraw_conversion: conversion })
					let amt = this.withdrawal_amount;
					this.withdrawal_amount = '0';
					this.withdrawal_amount = amt;
				} catch (err: any) {
					console.log('error fetching conversion', err.toString())
				}

			})
				.catch(err => {
					console.log('error fetching conversion', err.toString())
					conversion[currency] = -1
					this.updateFunds({ withdraw_conversion: conversion })

				})
		},

		async loadMarket() {
			if (this.markets && this.markets.length > 0) {

				this.markets.forEach(market => {
					if (market.name) {
						if (market.name.toLowerCase() === 'euro' && market.symbol == 'EUR/USD') {
							this.eur_market = market;
						}
						if (market.type?.toLowerCase() === 'crypto' && market.symbol == 'POL') {
							this.pol_market = market;
						}

						if (market.name.toLowerCase() === 'british pound' && market.symbol == 'GBP/USD') {
							this.gbp_market = market;
						}
						if (market.name.toLowerCase() === 'russian ruble' && market.symbol == 'GBP/USD') {
							this.rub_market = market;
						}
					}
				});
			} else {
				window.setTimeout(() => {
					this.loadMarket();
				}, 500);
			}
		},
		currencySymbol(source_currency: string) {

			if (!source_currency) {
				return '$';
			}

			source_currency = source_currency.toLowerCase();
			if (source_currency == 'usd') {
				return '$';
			}
			if (source_currency == 'eur') {
				return '€';
			}
			if (source_currency == 'gbp') {
				return '£';
			}

			if (source_currency == 'rub') {
				return '₽';
			}

			return source_currency;
		},
		currencyExchange(source_currency: string) {
			if (!source_currency) {
				return 1;
			}

			source_currency = source_currency.toLowerCase();

			if (source_currency === 'eur') {
				return this.eur_market ? Number(this.eur_market.close) : 1;
			} else if (source_currency === 'gbp') {
				return this.gbp_market ? Number(this.gbp_market.close) : 1;
			} else if (source_currency === 'rub') {
				return this.rub_market ? 1 / Number(this.rub_market.close) : 1;
			} else if (source_currency === 'pol') {
				return this.pol_market ? 1 / Number(this.pol_market.close) : 1;

			} else {
				return 1;
			}
		},
		switchPOL() {
			const currency = { chain: 'polygon', icon: 'pol.svg', name: 'POL', symbol: 'POL ' + this.$t('ON') + ' Polygon' };
			this.updateFunds({ withdraw_currency: currency, showPaymentMethod: false, showWithdrawMethod: false });
		},
		exchangeCheck() {
			if (this.active_btn.yes && this.funds.withdraw_currency.name === 'MPH') {
				return;
			} else if (this.active_btn.yes && this.wallet_exchange == '') {
				return;
			} else if (this.active_btn.yes && this.wallet_exchange !== '') {
				this.updateFunds({
					withdraw_wallet_type: 'exchange',
					withdraw_wallet_exchange: this.wallet_exchange
				});
			}
			this.updatePageStatus('withdraw_confirm');
		},
		withdrawMax() {
			const withdraw_limit = this.withdraw_limit;
			this.withdrawal_amount = (withdraw_limit * this.balance.mph_rate).toLocaleString();
		},
		openWithdrawLimit() {
			window.open(this.supportArticleLink('RewardsProgram'), '_blank');
		},
		checkWithdrawAmount() {
			if (this.withdrawal_amount && this.withdrawal_amount !== '0' && this.withdrawal_amount_number() > 0) {
				let mph_amount = this.withdrawal_amount_number() / this.balance.mph_rate;

				const withdraw_limit = this.withdraw_limit;

				if (mph_amount > withdraw_limit) {
					this.withdrawMax();
				}
			}
		},
		setDefaultAmount(percent: number) {
			let amt = this.floorRound(
				(percent / 100) * Number(((this.activePortfolio?.cash_balance || 0) / Math.pow(10, 18)) * this.balance.mph_rate),
				2
			)

			if (this.number_seperators.decimal && this.number_seperators.decimal !== '.') {
				amt = amt.replace(/\./g, this.number_seperators.decimal)
			}

			this.withdrawal_amount = amt
		},


		getNumberSeparators() {
			// default
			var res = {
				decimal: '.',
				thousand: ''
			};

			// convert a number formatted according to locale
			var str = parseFloat('1234.56').toLocaleString();

			// if the resulting number does not contain previous number
			// (i.e. in some Arabic formats), return defaults
			if (!str.match('1')) return res;

			// get decimal and thousand separators
			res.decimal = str.replace(/.*4(.*)5.*/, '$1');
			res.thousand = str.replace(/.*1(.*)2.*/, '$1');
			// return results
			this.number_seperators = res;
		},
		async getTransactionInfo(transaction_id: string) {
			const return_data = await TransactionService.getTransaction({
				eth_address: this.user?.activePortfolio?.eth_address,
				transaction_id
			});
			if (return_data && return_data.data && return_data.data.getTransaction) {
				this.transaction_data = return_data.data.getTransaction;
			} else {
				this.transaction_data = null;
			}

			this.new_address = false;

			if (this.transaction_data && this.transaction_data.payload.destinationAddress) {
				const data = await PortfolioService.getWithdrawalAddress({ eth_address: this.user?.activePortfolio?.eth_address });


				if (data) {
					const address = this.transaction_data.payload.destinationAddress.toLowerCase();

					const withdrawal_address = data.find(
						(method: any) =>
							method.type == 'user' &&
							((method.eth_address || '').toLowerCase() == address ||
								(method.address_resolved || '').toLowerCase() == address)
					);

					if (!withdrawal_address) {
						this.new_address = true;
					}
				}
			}
		},
		// Function which waits on Metamask to be initialized (logging in)
		// In case when user wants to create an order but he is not logged in into Metamask
		// The function is checking if "eth_address" exists -> means logged in
		// And in that case executes callback function which is passed in parameters and stops.
		async waitOnWallet(callback: any) {
			this.waitOnMetamaskInterval = setInterval(async () => {
				if (this.wrongWallet) {
					this.loading(false);

					if (this.user?.wallet_type === 'morpherwallet') {
						await this.morpherwalletLogout();
					}
					if (this.waitOnMetamaskInterval) {
						clearInterval(this.waitOnMetamaskInterval);
						this.waitOnMetamaskInterval = null;
					}
					this.$buefy.dialog.alert({
						title: this.$t('INCORRECT_ACCOUNT_HEAD'),
						message: this.$t('INCORRECT_ACCOUNT_TRADE'),
						confirmText: this.$t('OK')
					});

					return;
				}
				let loggedin = true;
				if (this.user?.wallet_type === 'morpherwallet') {
					loggedin = false;

					if (!this.morpherwallet) {
						loggedin = false
					} else {
						let logged = await this.morpherwallet.isLoggedIn();

						if (logged) {
							loggedin = logged.isLoggedIn;
						}

					}
				}

				if (loggedin && this.eth_address !== '' && this.wrongNetwork === false && this.morpherBridge) {
					if (this.waitOnMetamaskInterval) {
						clearInterval(this.waitOnMetamaskInterval);
						this.waitOnMetamaskInterval = null;
					}
					callback();
				}
			}, 1000);
		},
		showWithdrawMethod() {
			this.updateFunds({ showWithdrawMethod: true, withdraw_amount: this.withdrawal_amount_number() });
		},
		showWithdrawAddress() {
			this.updateFunds({ showWithdrawAddress: true, withdraw_amount: this.withdrawal_amount_number() });
		},
		formatAmount(withdraw_account: string) {
			let number_unformatted = String(withdraw_account || '0');

			const regex = new RegExp('[^0-9' + +this.number_seperators.thousand + this.number_seperators.decimal + ']', 'g');

			number_unformatted = number_unformatted.replace(regex, '');

			if (this.number_seperators.thousand) {
				var re = new RegExp('[' + this.number_seperators.thousand + ']', 'g');
				number_unformatted = String(number_unformatted).replace(re, '');

			}

			const decimal_split = number_unformatted.split(this.number_seperators.decimal);

			let after_decimal = null;
			let before_decimal = null;

			if (decimal_split.length > 2) {
				after_decimal = decimal_split[decimal_split.length - 1];
				decimal_split.pop();
				before_decimal = decimal_split.join('');
			} else if (decimal_split.length == 2) {
				before_decimal = decimal_split[0];
				after_decimal = decimal_split[1];
			} else {
				before_decimal = number_unformatted;
			}

			if (after_decimal) after_decimal = after_decimal.substring(0, 2);

			return (
				parseFloat(before_decimal).toLocaleString() + (after_decimal == null ? '' : this.number_seperators.decimal + after_decimal)
			);
		},
		withdrawal_amount_number() {
			if (!this.withdrawal_amount) {
				return Number(this.withdrawal_amount);
			}
			let number_unformatted = this.withdrawal_amount;

			const regex = new RegExp('[^0-9' + this.number_seperators.thousand + this.number_seperators.decimal + ']', 'g');

			number_unformatted = String(number_unformatted).replace(regex, '');

			if (this.number_seperators.thousand) {
				var re = new RegExp('[' + this.number_seperators.thousand + ']', 'g');
				number_unformatted = String(number_unformatted).replace(re, '');

			}


			if (this.number_seperators.decimal) {
				var re = new RegExp('[' + this.number_seperators.decimal + ']', 'g');
				number_unformatted = String(number_unformatted).replace(re, '.');
			}

			return Number(number_unformatted);
		},
		openWithdrawal() {
			this.$router.push('/funds/withdraw').catch(() => { });
			this.toggleDepositWithdrawModal(false);
		},
		handleResize() {
			if (document.documentElement.clientWidth) {
				const screenWidth = document.documentElement.clientWidth;
				if (screenWidth < 768) {
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			}
		},
		closeModal() {
			this.toggleDepositWithdrawModal(false);
		},

		cancelPurchase() {
			this.$router.push('/funds').catch(() => { });
			this.updateFunds({ showPaymentType: null, showWithdrawMethod: false, withdraw_page: '', withdraw_amount: 0 });
			this.toggleDepositWithdrawModal(false);
			this.withdraw_progress_step = 1;
		},
		bookmarkAddress(address: string) {
			if (this.new_address) {
				this.$router.push('/funds').catch(() => { });
				this.updateFunds({
					showPaymentType: null,
					showWithdrawMethod: false,
					withdraw_page: '',
					withdraw_amount: 0,
					bookmark_address: address
				});
				this.toggleDepositWithdrawModal(false);
			}
		},
		withdrawal_transaction_cost() {
			if (this.balance && this.balance.gas_price && this.balance.gas_price > 0) {
				return this.balance.gas_price * 500000;
			} else {
				return 0;
			}
		},
		async unlockWithdrawal() {
			this.unlock_processing = true;
			if (!this.withdraw_unlock_code || this.withdraw_unlock_code.length < 6 || isNaN(Number(this.withdraw_unlock_code))) {
				this.error({ message: 'WITHDRAW_INVALID_UNLOCK_CODE' });
				this.unlock_processing = false;
				return;
			}
			const data = await this.unlockWithdraw({ code: this.withdraw_unlock_code });
			if (data && data.unlocked) {
				this.updatePageStatus('withdraw_confirm');
				this.withdrawStart();
			}

			this.unlock_processing = false;
		},
		async checkAmount() {

			if (this.user?.access_level?.tier == 1) {
				this.toggleDepositWithdrawModal(false);
				this.toggleUpgradeTierModal(true)
				return;
			}

			if (this.user?.access_level?.withdrawal_blocked) {
				this.toggleDepositWithdrawModal(false);
				this.toggleUpgradeTierModal(true)
				return;
			}

			if (!this.balanceLoaded) {
				this.withdrawWaiting = true;
				setTimeout(() => {
					this.checkAmount();
				}, 1000);
				return;
			}
			this.withdrawWaiting = false;
			if (this.withdrawDisabled) {
				return;
			}

			if (this.funds.withdraw_currency.chain !== 'moonpay') {

				if (!this.funds.withdraw_account || !isAddress(this.funds.withdraw_account)) {
					this.address_invalid = true;
					return;
				}

				if (
					!this.funds.withdraw_wallet_type ||
					(this.funds.withdraw_wallet_type == 'exchange' && !this.funds.withdraw_wallet_exchange)
				) {
					this.address_invalid = true;
					return;
				}
			}
			const withdraw_limit = this.withdraw_limit;
			let mph_amount = this.withdrawal_amount_number() / this.balance.mph_rate;

			if (mph_amount > Number((this.activePortfolio?.cash_balance || 0) / Math.pow(10, 18))) {
				return;
			} else if (mph_amount > withdraw_limit) {
				return;
			} else {
				if (mph_amount < this.network_fee + 100) {
					return;
				}
			}

			this.address_invalid = false;

			const withdrawal_amount = this.withdrawal_amount_number() || 0;

			if (withdrawal_amount > this.withdrawal_limit_day) {
				this.$buefy.dialog.alert({
					title: this.$t('DAILY_LIMIT_EXCEEDED'),
					message: this.$t('DAILY_LIMIT_EXCEEDED_DESC', {
						withdrawal_limit_day: this.withdrawal_limit_day
					}),
					confirmText: this.$t('OK')
				});
				return;
			}

			if (withdrawal_amount > this.withdrawal_limit_day - this.balance.linked_chain_tokens_to_claim / Math.pow(10, 18)) {
				await this.$buefy.dialog.confirm({
					title: this.$t('DAILY_LIMIT_EXCEEDED'),
					message: this.$t('DAILY_LIMIT_EXCEEDED_DESC_1'),
					cancelText: this.$t('CANCEL'),
					confirmText: this.$t('WITHDRAW_ANYWAY'),
					onConfirm: () => this.updatePageStatus('withdraw_confirm')
				});
				return;
			}

			if (withdrawal_amount > this.withdrawal_limit_month - this.balance.linked_chain_tokens_to_claim / Math.pow(10, 18)) {
				await this.$buefy.dialog.confirm({
					title: this.$t('MONTHLY_LIMIT_EXCEEDED'),
					message: this.$t('MONTHLY_LIMIT_EXCEEDED_DESC'),
					cancelText: this.$t('CANCEL'),
					confirmText: this.$t('WITHDRAW_ANYWAY'),

					onConfirm: () => this.updatePageStatus('withdraw_confirm')
				});
				return;
			}
			if (this.funds.withdraw_currency.chain == 'moonpay') {
				this.updateFunds({ showPaymentType: 'moonpay_withdraw', amount: this.withdrawal_amount_number(), withdraw_amount: this.withdrawal_amount_total });


			} else {
				this.updatePageStatus('withdraw_confirm');

			}
		},
		async updatePageStatus(status: string) {
			if (status == 'withdraw_confirm') {
				if (Number(this.user?.access_level?.tier) < 1 || this.user?.access_level?.withdrawal_block_reason == 'portfolio value') {
					this.toggleDepositWithdrawModal(false);
					this.toggleUpgradeTierModal(true)
					return;
				} else if (this.user?.access_level?.withdrawal_block_reason == 'paypal') {
					status = 'withdraw_block_paypal';
				} else if (this.user?.access_level?.withdrawal_blocked) {
					status = 'withdraw_block_other';
				} else if (this.funds.withdraw_wallet_type !== 'exchange' && !this.active_btn.no) {
					status = 'withdraw_exchange_check';
				}
			}
			// Reset toggles if leaving withdraw_exchange_check
			if (status == '') this.active_btn = { yes: false, no: false };

			this.updateFunds({ withdraw_page: status });
		},
		async resendEmail() {
			this.resend_email = true;
			const withdraw = await this.checkWithdrawUnlock({
				user_id: this.user?.id,
				resend: true
			});

			window.setTimeout(() => {
				this.resend_email = false;
			}, 30000);
		},
		async withdrawStart() {
			if (this.withdrawDisabled) {
				return;
			}
			this.transfer_loading = true;
			const withdraw = await this.checkWithdrawUnlock({
				user_id: this.user?.id,
				resend: false
			});

			if (!withdraw || !withdraw.unlocked) {
				this.transfer_loading = false;
				return this.updatePageStatus('withdraw_code');
			}

			this.transfer_loading = false;
			this.withdraw_progress_step = 1;

			this.updatePageStatus('withdraw_progress');
			if (this.walletType !== 'morpherwallet') {
				this.withdrawRequest();
			}
		},
		openDeposit() {
			this.updateFunds({ type: 'deposit', showWithdrawMethod: false, withdraw_page: '', withdraw_amount: 0 });
		},
		async signEthTransaction(amountValueSign: bigint, withdraw_account: TAddress, chain_id: number, eth_address: string) {


			let hash = keccak256(encodePacked(
				['uint256', 'address', 'uint'],
				[
					amountValueSign,
					withdraw_account as TAddress,
					BigInt(chain_id)
				]
			))



			const result = await this.walletClient?.signMessage({ account: eth_address as TAddress, message: { raw: hash } });
			return result


		},
		openSettings() {
			this.$router.push('/settings').catch(() => { });
			this.toggleDepositWithdrawModal(false);
			this.resetFunds({});
		},
		/**
		 * Start a deposit / withdrawal request. Execute the request on the contract and record the transaction in the backend.
		 */
		async withdrawRequest() {
			if (!this.balance || !this.balance.mph_sidechain) {

				return setTimeout(() => {
					this.withdrawRequest()
				}, 1000)
			}



			if (
				!this.funds.withdraw_wallet_type ||
				(this.funds.withdraw_wallet_type == 'exchange' && !this.funds.withdraw_wallet_exchange)
			) {
				this.logSentryError('Error withdrawing token:' + this.$t('NO_WALLET_TYPE'), {
					eth_address: this.activePortfolio?.eth_address,
					balance: this.balance
				});

				this.error({ message: 'NO_WALLET_TYPE' });
				this.updatePageStatus('withdraw_confirm');
				return;
			}

			if (this.toBn(this.balance.mph_sidechain).lte(this.toBn(String(100 * Math.pow(10, 18))))) {
				this.logSentryError('Error withdrawing token:' + this.$t('WITHDRAW_LESS_THAN_FEES'), {
					eth_address: this.activePortfolio?.eth_address,
					balance: this.balance
				});

				this.error({ message: 'WITHDRAW_LESS_THAN_FEES' });
				this.updatePageStatus('withdraw_confirm');
				return;
			}
			if (this.withdrawal_amount_total < 100) {
				this.logSentryError('Error withdrawing token:' + this.$t('WITHDRAW_LESS_THAN_FEES'), {
					eth_address: this.activePortfolio?.eth_address,
					balance: this.balance
				});
				this.error({ message: 'WITHDRAW_LESS_THAN_FEES' });
				this.updatePageStatus('withdraw_confirm');
				return;
			}


			//this.error({ message: 'Error retreiving location information: ' + err.message });
			let bridgeAddress;

			// if (Number(this.balance.eth_sidechain) < 1000) {
			// 	this.logSentryError('Error withdrawing token:' + this.$t('NO_SIDECHAIN_ETH'), {
			// 		eth_address: this.activePortfolio.eth_address,
			// 		balance: this.balance
			// 	});
			// 	this.error({ message: 'NO_SIDECHAIN_ETH' });
			// 	this.updatePageStatus('withdraw_confirm');
			// 	return;
			// }
			this.setNetwork('sidechain');
			bridgeAddress = import.meta.env.VITE_MORPHER_BRIDGE_SIDECHAIN;
			if (!this.withdrawal_amount_total || Number(this.withdrawal_amount_total) === 0) {
				this.error({ message: 'NO_WITHDRAWAL_AMOUNT' });
				this.logSentryError('Error withdrawing token:' + this.$t('NO_WITHDRAWAL_AMOUNT'), {
					eth_address: this.activePortfolio?.eth_address,
					balance: this.balance
				});
				this.updatePageStatus('withdraw_confirm');
				return;
			}
			// update the backend with the new user balances.
			if (!bridgeAddress) {
				this.logSentryError('Error withdrawing token:' + 'No bridge address specified', {
					eth_address: this.activePortfolio?.eth_address,
					balance: this.balance
				});
				console.log('No bridge address specified');
				this.updatePageStatus('withdraw_confirm');
				return;
			}
			try {
				this.transfer_loading = true;
				this.loading(false);

				if (this.user?.wallet_type === 'morpherwallet') {
					let loggedin = false;

					if (!this.morpherwallet) {
						loggedin = false
					} else {
						let logged = await this.morpherwallet.isLoggedIn();

						if (logged) {
							loggedin = logged.isLoggedIn;
						}

					}

					if (!loggedin || !this.morpherBridge) {
						// start the portis wallet - order: true will ensure that the portis login is opened
						this.startWallet({ load: true, order: true });
						if (this.waitOnMetamaskInterval) {
							clearInterval(this.waitOnMetamaskInterval);
							this.waitOnMetamaskInterval = null;
						}
						this.waitOnWallet(() => {
							this.withdrawRequest();
						});
						return;
					}
				}

				if (this.walletType == 'metamask' && this.wrongWallet) {
					this.transfer_loading = false;

					this.updatePageStatus('withdraw_confirm');

					this.$buefy.dialog.alert({
						title: this.$t('INCORRECT_ACCOUNT_HEAD'),
						message:
							this.$t('INCORRECT_ACCOUNT_METAMASK'),
						confirmText: this.$t('OK')
					});
					return;
				}


				// check that metamask is on the correct network
				if (
					this.walletType == 'metamask' &&
					(Number(this.network_id) !== Number(this.user?.blockchain_info?.chain_id) || this.wrongNetwork)
				) {
					this.transfer_loading = false;

					this.updatePageStatus('withdraw_confirm');

					// show the network popup if the incorrect network is selected
					this.toggleNetworkMetamaskModal(true);
					return;
				}

				if (!this.morpherBridge) {
					// start the portis wallet - order: true will ensure that the portis login is opened
					this.startWallet({ load: true, order: true });
					if (this.waitOnMetamaskInterval) {
						clearInterval(this.waitOnMetamaskInterval);
						this.waitOnMetamaskInterval = null;
					}
					this.waitOnWallet(() => {
						this.withdrawRequest();
					});
					return;
				}


				const txParams = {
					from: this.activePortfolio?.eth_address,
					gasPrice: import.meta.env.VITE_SIDECHAIN_GAS || undefined,
					gas: 2000000,
					to: bridgeAddress
				};

				// Sign the transaction using fortmatic and call claimAirdrop on the airdrop the smart contract
				this.walletClient?.getAddresses().then(async (accounts: any[]) => {
					let amountValue = BigInt(Number(this.withdrawal_amount_total) * 10 ** 18);

					// if the withdrawal amount is greater than the users balance then withdraw the entire balance
					let withdrawal_amount = amountValue;

					const mph_balance = BigInt(this.balance.mph_sidechain);
					if (withdrawal_amount > mph_balance && mph_balance > BigInt(0)) {

						if (amountValue.toString().length < 19) {
							this.logSentryError('Error withdrawing token:' + this.$t('WITHDRAW_LESS_THAN_FEES'), {
								eth_address: this.activePortfolio?.eth_address,
								balance: this.balance
							});

							this.error({ message: 'WITHDRAW_LESS_THAN_FEES' });
							this.updatePageStatus('withdraw_confirm');
							this.transfer_loading = false;
							return;
						}
						amountValue = BigInt(amountValue.toString().substring(0, amountValue.toString().length - 13) + '0000000000000');
						withdrawal_amount = amountValue;
					}

					const amountValueSign = withdrawal_amount - BigInt(String(100 * Math.pow(10, 18)))

					let chain_id = import.meta.env.VITE_MAINCHAIN_ID;

					if (this.funds.withdraw_currency.chain == 'polygon') {
						chain_id = import.meta.env.VITE_POLYGONCHAIN_ID;
					}

					let destination_address = this.funds.withdraw_account;

					if (this.funds.withdraw_wallet_type == 'exchange') {
						destination_address = this.balance.exchange_forwarder;
					}

					const _signature: any = await this.signEthTransaction(
						amountValueSign,
						destination_address as TAddress,
						chain_id,
						this.activePortfolio?.eth_address || ''
					);

					if (!_signature || _signature.error) {
						this.logSentryError('Error withdrawing token:' + _signature.error, {
							eth_address: this.activePortfolio?.eth_address,
							balance: this.balance
						});
						this.updatePageStatus('withdraw_confirm');
						this.withdraw_progress_step = 1;

						// If there is a logon error then log it to sentry
						this.error({ message: 'SIGNATURE_FAILED' });
						this.transfer_loading = false;
						return;
					}

					let transaction

					if (this.funds.withdraw_wallet_exchange == 'moonpay' && this.transaction_data && this.transaction_data.status == 'deposit') {

						transaction = this.transaction_data
					} else {


						// update the backend with the new user balances.
						const result = await TransactionService.createTransaction({
							data: {
								eth_address: accounts[0].toLowerCase(),
								eth_tx_id: null,
								type: 'withdrawal',
								signature: null,
								payload: {
									from: this.activePortfolio?.eth_address,
									chain: this.funds.withdraw_currency.chain,
									tokens: amountValueSign.toString(),
									currency: this.funds.withdraw_currency.name,
									targetChainId: chain_id,
									totalTokenSent: '0',
									destinationAddress: this.funds.withdraw_account,
									exchange_forwarder: this.funds.withdraw_wallet_type == 'exchange' ? destination_address : undefined,
									withdraw_wallet_type: this.funds.withdraw_wallet_type,
									withdraw_wallet_exchange: this.funds.withdraw_wallet_exchange
								},
								amount: amountValue.toString()
							}
						});

						transaction = result?.data?.createTransaction
					}

					this.loading(false);

					if (transaction && transaction.id) {
						const tx_id = transaction.id;
						this.withdraw_progress_step = 2;

						const morpherBridgeContract = await getBridgeContract(this.user?.blockchain_info || undefined, this.walletClient)



						type TStageTokens = [bigint, bigint, `0x${string}`, string]

						const stageTokens: TStageTokens = [amountValue, BigInt(chain_id), destination_address as `0x${string}`, _signature]

						try {


							const transaction_hash = await morpherBridgeContract.contract.write
								.stageTokensForTransfer(stageTokens, { chain: morpherBridgeContract.public_client.chain, gas: 2000000, gasPrice: import.meta.env.VITE_SIDECHAIN_GAS || undefined, account: this.activePortfolio?.eth_address || '' as TAddress }
								)

							const result = await TransactionService.signTransaction({
								data: {
									eth_tx_id: transaction_hash,
									signature: null,
									id: tx_id
								}
							});

							const receipt = await morpherBridgeContract.public_client.waitForTransactionReceipt({ hash: transaction_hash })

							this.withdrawal_amount = '0';

							var signature = null;

							const logs = parseEventLogs({
								abi: morpherBridgeAbi,
								logs: receipt.logs,
								eventName: ['TransferToLinkedChain'],

							})

							logs.forEach((log: any) => {
								signature = log.args?.userSigature || log.args?.transferHash
							})

							const logs2 = parseEventLogs({
								abi: morpherBridgeAbi,
								logs: receipt.logs,
								eventName: ['TransferToLinkedChainAndWithdrawTo'],

							})

							logs2.forEach((log: any) => {
								signature = log.args?.userSigature || log.args?.transferHash
							})

							if (signature) {
								const result = await TransactionService.signTransaction({
									data: {
										eth_tx_id: receipt.transactionHash,
										signature: signature,
										id: tx_id
									}
								});
							}

							if (this.funds.withdraw_page == 'withdraw_progress') {
								if (this.funds.withdraw_wallet_exchange == 'moonpay') {
									this.updatePageStatus('withdraw_processing_moonpay');
								} else {
									this.updatePageStatus('withdraw_processing');
								}
							}

							this.transfer_loading = false;
						} catch (err: any) {
							this.updatePageStatus('withdraw_confirm');
							this.withdraw_progress_step = 1;

							// If there is a logon error then log it to sentry
							this.error({ message: 'WITHDRAWAL_TRANSACTION' });
							this.logSentryError('Error transferring token:' + (err.message || err.toString()), {
								method: 'stageTokensForTransfer',
								contract_address: bridgeAddress,
								txParams
							});
							this.transfer_loading = false;
						}


					}
				});
			} catch (err: any) {
				this.updatePageStatus('withdraw_confirm');
				this.withdraw_progress_step = 1;
				this.transfer_loading = false;
				// If there is a logon error then log it to sentry
				this.logSentryError('Error transferring token:' + err.toString(), {
					eth_address: this.activePortfolio?.eth_address,
					balance: this.balance
				});
			}
		}
	}
});
</script>
<style scoped src="../../assets/css/payment.css"></style>
<style>
input.input-2fa {
	border: 1px solid #eae9ed;
	font-size: 21px;
	color: #333 !important;
	text-align: center;
}

input.input-2fa:hover {
	border-color: #888;
}

input.input-2fa::placeholder {
	color: #999;
}

input.input-withdraw {
	border: 1px solid #eae9ed;
	font-size: 13px;
}

input.input-withdraw:hover {
	border-color: #888;
}

input.input-withdraw::placeholder {
	color: #999;
}
</style>
<style scoped>
.amount-percentage-selector:first-child {
	border: 1px solid red;
}

.amount-percentage-button:hover {
	background-color: #f5f5f5;
}

.first-button {
	border-radius: 7px 0px 0px 7px;
}

.last-button {
	margin-right: 0;
}

.amount-percentage-button {
	background-color: #fff;
	border: 1px solid #d0d5dd;

	font-weight: 700;
	font-size: 14px;
	line-height: 30px;

	color: #666666;
	cursor: pointer;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-bottom: calc(0.5em - 1px);
	padding-left: 1em;
	padding-right: 1em;
	padding-top: calc(0.5em - 1px);
	text-align: center;
	white-space: nowrap;
	-webkit-appearance: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	border-radius: 8px;
}

.toggle-button {
	background-color: #fff;
	border: 1px solid #d0d5dd;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #666666;
	cursor: pointer;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-bottom: calc(0.625em - 1px);
	padding-left: 1em;
	padding-right: 1em;
	padding-top: calc(0.625em - 1px);
	text-align: center;
	white-space: nowrap;
	-webkit-appearance: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	transition: background-color 0.25s;
}

.toggle-button:hover {
	background-color: #f5f5f5;
	transition: background-color 0.25s;
}

.toggle-button.is-active {
	background-color: #f5f5f5;
	transition: background-color 0.25s;
}

.amount-percentage-control {
	margin-right: 5px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	clear: both;
	font-size: 1rem;
	position: relative;
	text-align: inherit;
	width: 25%;
}

.toggle-control {
	margin-right: -1px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	clear: both;
	font-size: 1rem;
	position: relative;
	text-align: inherit;
	width: 50%;
}

.amount-percentage-selector {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.toggle-selector {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.withdraw-input {
	max-width: 352px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	margin-top: 24px;
	justify-content: center;
}

.withdraw-amount {
	width: 100%;
	border: 0;
	text-align: center;
	color: #333333;
	font-size: 40px;
	font-weight: 400;
	font-family: 'Manrope';
}

.withdraw-amount:focus {
	outline: none;
}

.box-header {
	font-weight: 500;
	color: #333;
	font-size: 18px;
	line-height: 20px;
}

.currency-symbol {
	font-family: 'Manrope';
	font-size: 20px;
	font-weight: 500;
	color: #333333;
	padding-top: 5px;
}

.upgrade_icon {
	position: absolute;
}

.upgrade_header {
	font-size: 16px;
	font-weight: 500;
	color: #333333;
	padding-left: 35px;
}

.upgrade_body {
	font-size: 14px;
	font-weight: 400;
	color: #666666;
	padding-left: 35px;
}

.upgrade_section {
	margin-top: 15px;
}

.is-64 {
	height: 64px;
}

.divider {
	margin: 15px 0;
	background-color: #eae9ed;
}

.divider2 {
	margin: 12px 0;
	background-color: #eae9ed;
}

.hover-position {
	position: absolute;
	left: 24px;
	right: auto;
	top: 54px;
}

.withdraw-message {
	color: #666666;
	text-align: center;
	font-size: 14px;
	line-height: 1;
}

.wallet-select {
	font-weight: 700;
	font-size: 14px;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -1rem;
	padding: 1rem;
	transition: background-color 0.25s;
	border-radius: 0 0 7px 7px;
}

.wallet-select:hover {
	background-color: #f5f5f5;
	cursor: pointer;
	transition: background-color 0.25s;
}

.regular-section {
	font-size: 14px;
	border: 1px solid #eae9ed;
	border-radius: 8px;
}

.regular-header {
	font-size: 16px;
	font-weight: 500;
	border-bottom: 1px solid #eae9ed;
	display: flex;
	align-items: center;
	padding: 1rem;
	align-items: center;
	gap: 5px;
}

.bottom-none {
	border-bottom: none;
}

.provider-header {
	font-weight: 700;
	font-size: 14px;
	color: #000;
	border-bottom: 1px solid #eae9ed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -1rem;
	margin-bottom: 1rem;
	padding: 1rem;
	transition: background-color 0.25s;
	border-radius: 7px 7px 0 0;
}

.provider-header:hover {
	background-color: #f5f5f5;
	cursor: pointer;
	transition: background-color 0.25s;
}

.provider-icon {
	height: 24px;
}

.payment-currency {
	font-size: 12px;
	line-height: 20px;
	color: #666666;
}

.centered-flex-gap {
	display: flex;
	align-items: center;
	gap: 5px;
}

.top-flex-gap {
	display: flex;
	align-items: flex-start;
	gap: 5px;
}

.address-invalid {
	border-color: #D70228;
}

.withdraw-message-error {
	color: #D70228;
}

.token_header {
	font-size: 16px;
	font-weight: bold;
	border-bottom: 1px solid #eae9ed;
	padding: 15px;
	cursor: pointer;
}

.token_icon_image {
	position: relative;
	top: 4px;
}

.token_address_copy_icon {
	float: right;
	display: block;
	width: 25px;
	position: relative;
	top: -60px;
}

.input-box-withdraw {
	height: 44px !important;

	/* font-size: 18px !important; */
}

.input-box-2fa {
	height: 44px !important;

	/* font-size: 18px !important; */
}

/* .wallet-message-icon {
	padding-right: 1px;
	display: inline-block;
	position: relative;
	top: 2px;
} */

.step_icon {
	width: 20px;
	margin-right: 8px;
}

.user-notification {
	display: flex;
	align-items: center;
	gap: 5px;
}

.has-text-gold {
	background: linear-gradient(134deg, #ffc77c 0%, #d8a159 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.big-bold-text {
	font-weight: 500;
	font-family: 'Rubik', sans-serif;
	font-size: 80px;
}

.wallet-exchange-error {
	border: 1px solid #eae9ed;
	border-radius: 8px;
	line-height: 20px;
	padding: 16px;
}

.gap-8 {
	gap: 8px;
}

.dark-hover {
	color: #ababac;
	cursor: pointer;
}

.dark-hover:hover {
	color: #666;
	cursor: pointer;
}

.address-confirm {
	background: #f7f5fc;
	border: 1px solid #eae9ed;
	border-radius: 8px;
	line-height: 20px;
	padding: 16px;
	font-size: 14px;
}

.pending-rewards-tag {
	background: #f3f2f6;
	border-radius: 12px;
	font-weight: 700;
	font-size: 12px;
	color: #333333;
	display: inline-block;
	padding: 2px 15px;
	line-height: 20px;
	margin-top: 10px;
}

.info-icon {
	float: right;
	margin-top: 2px;
	cursor: pointer;
}

.provider-detail {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	margin-top: 5px;
	color: #686475;
}

.success-check {
	width: 24px;
	height: 24px;
	margin-right: 8px
}
</style>
