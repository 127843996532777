
import { defineStore } from 'pinia'
import { AUTH } from '../mutation-types';

export interface ModalsState {
	showMobileAppModal: boolean;
	showMPHRewardModal: boolean;
	showInterviewModal: boolean;
	showNewPortfolioModal: boolean;
	showTier3Modal: boolean;
	showTier4Modal: boolean;
	showAllDoneModal: boolean;
	showPortfolioInUseModal: boolean;
	showOnboardModal: boolean;
	showClaimAirdropModal: boolean;
	showWalletRecoveryModal: boolean;
	showWalletMigrationModal: boolean;
	showUnlockWalletModal: boolean;
	showResetMetamaskModal: Boolean;
	showMainchainTransferModal: Boolean;
	showNotificationModal: boolean;
	showNotificationRewardModal: boolean;
	showKYCStatusModal: boolean;
	showKYCCaptureModal: boolean;
	showNetworkMetamaskModal: boolean;
	showAddMPHMetamaskModal: boolean;
	showETHBalanceModal: boolean;
	showDepositWithdrawModal: boolean;
	showUpgradeTierModal: boolean;
	showHeaderNotificationModal: boolean;
	showHeaderFirebaseModal: boolean;
	showBrowserAlertsModal: boolean;
	showBraveAlertsModal: boolean;
	showTrustPilotModal: boolean;
	showAIInsightsModal: string;
	showFAQModal: boolean;
	FAQPage: string;
	anyModalOpen: boolean;
	supportModal: {
		active: boolean,
		link: string
	}
}

// Set a flag if there is an open modal - this is used for preventing multple modals at the same time.
const checkAnyModalOpen = (state: ModalsState) => {
	let anyModalOpen = false;
	const keys = Object.keys(state);

	keys.forEach(key  => {
		if (key !== 'anyModalOpen' && (state as any)[key] === true) {
			anyModalOpen = true;
		}
	});

	if (state.showAIInsightsModal) {
		anyModalOpen = true;
	}

	return anyModalOpen;
};


export const useModalsStore = defineStore('modals', {
  state: () => {
    const initialState: ModalsState = {
      showMobileAppModal: false,
      showMPHRewardModal: false,
      showInterviewModal: false,
      showNewPortfolioModal: false,
      showTier3Modal: false,
      showTier4Modal: false,
      showAllDoneModal: false,
      showPortfolioInUseModal: false,
      showOnboardModal: false,
      showClaimAirdropModal: false,
      showWalletRecoveryModal: false,
      showWalletMigrationModal: false,
      showUnlockWalletModal: false,
      showResetMetamaskModal: false,
      showMainchainTransferModal: false,
      showNotificationModal: false,
      showNotificationRewardModal: false,
      showKYCStatusModal: false,
      showKYCCaptureModal: false,
      showNetworkMetamaskModal: false,
      showAddMPHMetamaskModal: false,
      showETHBalanceModal: false,
      showAIInsightsModal: '',
      showUpgradeTierModal: false,
      showHeaderNotificationModal: false,
      showHeaderFirebaseModal: false,
      showBrowserAlertsModal: false,
      showBraveAlertsModal: false,
      showDepositWithdrawModal: false,
      showTrustPilotModal: false,
      showFAQModal: false,
      FAQPage: '',
      anyModalOpen: false,
      supportModal: {
        active: false,
        link: ''
      },
    }
    return initialState;
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions:  {
		toggleMobileAppModal( toggle: boolean) {
      this.showMobileAppModal = toggle
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleMPHRewardModal( toggle: boolean) {
      this.showMPHRewardModal = toggle
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleInterviewModal( toggle: boolean) {
      this.showInterviewModal = toggle
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleNewPortfolioModal( toggle: boolean) {
			this.showNewPortfolioModal = toggle;
		},
		toggleTier3Modal( toggle: boolean) {
			this.showTier3Modal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleTier4Modal( toggle: boolean) {
			this.showTier4Modal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleAllDoneModal( toggle: boolean) {
			this.showAllDoneModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		togglePortfolioInUseModal( toggle: boolean) {
			this.showPortfolioInUseModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleOnboardModal( toggle: boolean) {
			this.showOnboardModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleClaimAirdropModal( toggle: boolean) {
			this.showClaimAirdropModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleWalletRecoveryModal( toggle: boolean) {
			this.showWalletRecoveryModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleWalletMigrationModal( toggle: boolean) {
			this.showWalletMigrationModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleUnlockWalletModal( toggle: boolean) {
			this.showUnlockWalletModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},		
		toggleResetMetamaskModal( toggle: boolean) {
			this.showResetMetamaskModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleMainchainTransferModal( toggle: boolean) {
			this.showMainchainTransferModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleNotificationModal( toggle: boolean) {
			this.showNotificationModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleRewardNotificationModal( toggle: boolean) {
			this.showNotificationRewardModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleKYCStatusModal( toggle: boolean) {
			this.showKYCStatusModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleKYCCaptureModal( toggle: boolean) {
			this.showKYCCaptureModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleNetworkMetamaskModal( toggle: boolean) {
			this.showNetworkMetamaskModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleAddMPHMetamaskModal( toggle: boolean) {
			this.showAddMPHMetamaskModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleETHBalanceModal( toggle: boolean) {
			this.showETHBalanceModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleAIInsightsModal( toggle: string) {
			this.showAIInsightsModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleDepositWithdrawModal( toggle: boolean) {
			this.showDepositWithdrawModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleUpgradeTierModal( toggle: boolean) {
			this.showUpgradeTierModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleHeaderNotificationModal( toggle: boolean) {
			this.showHeaderNotificationModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleHeaderFirebaseModal( toggle: boolean) {
			this.showHeaderFirebaseModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleBrowserAlertsModal( toggle: boolean) {
			this.showBrowserAlertsModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleBraveAlertsModal( toggle: boolean) {
			this.showBraveAlertsModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleTrustPilotModal( toggle: boolean) {
			this.showTrustPilotModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		toggleFAQModal( toggle: boolean) {
			this.showFAQModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		setFAQModalPage( page: string) {
			this.FAQPage = page;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
		closeAllModals( toggle: boolean) {
			if (toggle) {
				this.$reset()
			}
		},
		setSupportModal( toggle: {active: boolean,link: string}) {
			this.supportModal = toggle;
      this.anyModalOpen = checkAnyModalOpen(this.$state);
		},
  }
})