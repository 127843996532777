
import BN from 'bn.js'


const PRECISION = new BN('100000000');
const deployedTimestampNumber = Number(1617094819);

const INTEREST_RATES = [
    { validFrom: deployedTimestampNumber, rate: new BN(15000) },
    { validFrom: 1644491427, rate: new BN(30000) }
]
type LivePortfolioPointData = { cash: number, stake: number, portfolio: { [market_id: string]: any }, splits: { [market_id: string]: any }, cumulativeReturnsInsideInterval: number, lastTotalPortfolioValue: number };
type PortfolioPoint = { timestamp: number, cash: number, stake: number, positions: number, total: number, returns: number };

const getLivePortfolioPoint = (data: LivePortfolioPointData): PortfolioPoint => {
	const timestamp = Date.now();
	const positions = bnToFloat(calculatePositionsValue(data.portfolio, timestamp, data.splits));
	const total = data.cash + data.stake + positions;
	const returns = calculateReturns(data.lastTotalPortfolioValue, total, data.cumulativeReturnsInsideInterval);
	return { timestamp, cash: data.cash, stake: data.stake, positions, total, returns };
};

const calculateReturns = (lastTotalPortfolioValue: number, newTotal: number, cumulativeReturnsInsideInterval: number) => {
	return lastTotalPortfolioValue == 0 ? 0 :
		cumulativeReturnsInsideInterval * (1 + (newTotal - lastTotalPortfolioValue) / lastTotalPortfolioValue) - 1;
}

const calculatePositionsValue = (portfolio: any, ts: number, splits: { [market_id: string]: any[] }) => {
	let positionsValue = new BN(0);

	for (const market of Object.keys(portfolio)) {
		const position = portfolio[market];
		if (Number(portfolio[market]?.close || 0) > 0) {
			position.average_price = new BN(position.average_price);
			position.average_leverage = new BN(position.average_leverage);
			position.average_spread = new BN(position.average_spread);
			position.shares = new BN(position.shares);
			const adjustedPrice = adjustPrice(Number(portfolio[market].close || 0), market, position.created_at, Date.now(), splits);
			let shareValue: any;


			const spread = new BN(0);

			if (position.is_long) {
				shareValue = longShareValue(position, adjustedPrice, spread, ts, true);
			} else {
				shareValue = shortShareValue(position, adjustedPrice, spread, ts, true);
			}

			position.value = position.shares.mul(shareValue).toString();
			const interestRateCalculationTimestamp = new BN(
				Number(position.timestamp) || Number(position.created_at) || 0,
			);
			const gain = calculateGain(
				position.shares,
				position.average_leverage,
				adjustedPrice,
				position.average_price,
				position.average_spread,
				interestRateCalculationTimestamp,
				position.is_long,
			);
			
			const gain_percent = calculateGainPercent(position.value, gain);

			position.total_return = gain.toString();
			position.total_return_percent = (gain_percent * 100).toString();

			positionsValue = positionsValue.add(position.shares.mul(shareValue));
		} else {
			positionsValue = positionsValue.add(new BN(position.value || 0));

		}

	}

	return positionsValue;
}

function getInterestRate(positionTimestamp: number) {

    let sumInterestRatesWeighted = new BN(0);
    let startingTimestamp = 0;
    const numInterestRates = INTEREST_RATES.length;

    const now = Date.now();
    // if (import.meta.env.NODE_ENV) {
    //     now = 1615474090000;
    // }

    const blockTimestamp = Math.round(now / 1000);

    for (let i = 0; i < numInterestRates; i++) {
        if (i == numInterestRates - 1 || INTEREST_RATES[i + 1].validFrom > blockTimestamp) {
            //reached last interest rate
            sumInterestRatesWeighted = sumInterestRatesWeighted.add(INTEREST_RATES[i].rate.mul(new BN(blockTimestamp - INTEREST_RATES[i].validFrom)));
            if (startingTimestamp == 0) {
                startingTimestamp = INTEREST_RATES[i].validFrom;
            }
            break; //in case there are more in the future
        } else {
            //only take interest rates after the position was created
            if (INTEREST_RATES[i + 1].validFrom > positionTimestamp) {
                sumInterestRatesWeighted = sumInterestRatesWeighted.add(INTEREST_RATES[i].rate.mul(new BN(INTEREST_RATES[i + 1].validFrom - INTEREST_RATES[i].validFrom)));
                if (INTEREST_RATES[i].validFrom <= positionTimestamp) {
                    startingTimestamp = INTEREST_RATES[i].validFrom;
                }
            }
        }
    }
    return sumInterestRatesWeighted.div(new BN(blockTimestamp - startingTimestamp));

}

const calculateMarginInterest = (averagePrice: any, averageLeverage: any, positionTimeStampInMs: any) => {
   
	const deployedTimeStamp = new BN(1644451200)
    if (positionTimeStampInMs.div(new BN(1000)).lt(deployedTimeStamp)) {
        positionTimeStampInMs = deployedTimeStamp.mul(new BN(1000));
    }


    const now = new BN(Math.round(Date.now() / 1000));

    let marginInterest = averagePrice.mul(averageLeverage.sub(PRECISION));

    const diffDays = now
        .sub(positionTimeStampInMs.div(new BN(1000)))
        .div(new BN(86400));

    marginInterest = marginInterest.mul(diffDays.add(new BN(1)));

    marginInterest = (marginInterest.mul(getInterestRate(Math.round(positionTimeStampInMs.div(new BN(1000)).toNumber()))).div(PRECISION)).div(PRECISION);

    return marginInterest;
};

const calculateGain = (
    positionShares: any,
    avgLeverage: any,
    marketPrice: any,
    avgPrice: any,
    avgSpread: any,
    positionTimeStampInMs: any,
    direction: boolean,
) => {
    let gain;

    if (direction === true) {
        gain = avgLeverage.mul(marketPrice.sub(avgPrice.add(avgSpread))).div(PRECISION);
        gain = gain.sub(calculateMarginInterest(avgPrice, avgLeverage, positionTimeStampInMs));
    } else {
        gain = avgLeverage.mul(avgPrice.sub(avgSpread).sub(marketPrice)).div(PRECISION);
        gain = gain.sub(calculateMarginInterest(avgPrice, avgLeverage, positionTimeStampInMs));
    }

    gain = gain.mul(positionShares);

    return gain;
};

const calculateGainPercent = (value: any, gain: any) => {
    const decimalValue = Number(value) / 10**18;
    const decimalGain = Number(gain) / 10**18



    if (decimalGain.toString() === '0' || !decimalValue) return  0;
    else return (decimalValue / (decimalValue - decimalGain)) - 1;
};

const adjustPrice = (price: number, market: string, order_ts: number, current_ts: number, splits: { [market_id: string]: any[] }) => {
	let newPrice = new BN(Math.round(price * 100000000).toString());
	if (!Object.keys(splits).includes(market)) {
		return newPrice;
	}
	
	for (const split of splits[market]) {
		
		if (current_ts >= split.split_date && order_ts < split.split_date) {
			const multiplier = new BN(Math.round(Number(split.price_multiplier) * Math.pow(10, 10)).toString())
			newPrice = newPrice.mul(multiplier).div(new BN(Math.pow(10, 10)))
		}
	}
	return newPrice
}

const longShareValue = (position: any, price: any, spread: any, ts: number, sell: boolean) => {
	const discount = (position.average_price.mul(position.average_leverage.sub(PRECISION))).div(PRECISION);
	let v = (new BN(price).mul(position.average_leverage)).div(PRECISION).sub(discount);
	if (sell) {
		v = v.sub((new BN(spread).mul(position.average_leverage)).div(PRECISION));
		const interest = marginInterest(position, ts);
		return BN.max(v.sub(interest), new BN(0));
	} else {
		return v.add((new BN(spread).mul(position.average_leverage)).div(PRECISION));
	}
}

const shortShareValue = (position: any, price: any, spread: any, ts: number, sell: boolean) => {
	const discount = (position.average_price.mul(position.average_leverage.add(PRECISION))).div(PRECISION);
	let v = discount.sub((new BN(price).mul(position.average_leverage)).div(PRECISION));
	if (sell) {
		v = v.sub((new BN(spread).mul(position.average_leverage)).div(PRECISION));
		const interest = marginInterest(position, ts);
		return BN.max(v.sub(interest), new BN(0));
	} else {
		return v.add((new BN(spread).mul(position.average_leverage)).div(PRECISION));
	}
}

const marginInterest = (position: any, ts: number) => {
	if (position.created_at < 1617062400000) {
		return new BN(0);
	}
	const v = position.average_price.mul(position.average_leverage.sub(PRECISION));
	const diff = Math.floor((Math.floor(ts / 1000) - Math.floor(position.created_at / 1000)) / 86400) + 1;
	const interestRate = position.created_at > 1644451200000 ? 30000 : 15000;
	return v.mul(new BN(diff).mul(new BN(interestRate))).div(PRECISION.mul(PRECISION));
}

const bnToFloat = (bn: any, decimals = 18) => {
	const str = bn.toString().padStart(decimals + 1, '0');
	const integerPart = str.substring(0, str.length - decimals);
	const decimalPart = str.substring(str.length - decimals);
	return Number(`${integerPart}.${decimalPart}`);
}

export { getLivePortfolioPoint };