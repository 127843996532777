/* eslint no-undef: 0 */ // --> OFF

import to from 'await-to-js';
//vuex
import * as Sentry from '@sentry/vue';
import { OrderService } from '../services/OrderService';
import { TransactionService } from '../services/TransactionService';
import { UserService } from '../services/UserService';
import { i18n } from '../plugins/i18n';
import { supportArticles } from '../helpers/utils'
import { defineComponent, type ComponentOptionsMixin } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/modules/user';
import { useContractStore } from '@/store/modules/contract';
import { useModalsStore } from '@/store/modules/modals';
import moment from 'moment';
import { getStore } from '@/store';
import BN from 'bn.js'

export default defineComponent({
	data() {
		return {
			analytics_user: 'none',
			moment
		}
	},
	computed: {
		...mapState(useUserStore, {
			user: state => state.data,
			impersonating: state => state.impersonating
		}),
		...mapState(useContractStore, {
			walletClient: state => state.walletClient,
		})

	},
	mounted() {
		//this.startAnalytics()
	},
	methods: {
		...mapActions(useUserStore, {
			updateUserPayload: 'updateUserPayload',
			setDeviceId: 'setDeviceId',
		}),
		...mapActions(useModalsStore, {
			setSupportModal: 'setSupportModal',
		}),
		/** Sort by change function */
		compareChange(m1: any, m2: any) {
			let comparison = 0;
			if (m1.change_percent < m2.change_percent) {
				comparison = 1;
			} else if (m1.change_percent > m2.change_percent) {
				comparison = -1;
			}
			return comparison;
		},
		supportArticleLink(name: string) {
			const lang = i18n.locale;
			let link: string | undefined = supportArticles[name][lang]
			if (!link) {
				link= supportArticles[name]['en']
			}
			if (!link) {
				link= supportArticles.Support.en;
			}

			return link || ''

		},
		imageURL(src:string) {
			if (src.includes('@')) {
				src = src.replace('@', '/trade')
			}
			const url = new URL(src, import.meta.url).href
			return url
		},
		/** Sort by change function */
		compareChangeAbs(m1: any, m2: any) {
			let comparison = 0;
			if (Math.abs(m1.change_percent) < Math.abs(m2.change_percent)) {
				comparison = 1;
			} else if (Math.abs(m1.change_percent) > Math.abs(m2.change_percent)) {
				comparison = -1;
			}
			return comparison;
		},
		/** Function which checks if provided object is empty  */
		isEmptyObject(obj: any) {
			for (const prop in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, prop)) {
					return false;
				}
			}
			return JSON.stringify(obj) === JSON.stringify({});
		},
		/** Function which truncates string after provided length and adds ... */
		truncate(string: string, position: number) {
			if (string && string.length > position) return string.substring(0, position) + '...';
			else return string;
		},
		numberSuffixFormatter(number: any) {
			if (number > 999 && number <= 999999) return (number / 1000).toFixed(1) + 'K';
			else if (number > 999999) return (number / 1000000).toFixed(1) + 'M';
			return number;
		},
		firstLetterUppercase(string: string, lowercaseFirst = false) {
			if (lowercaseFirst) string = string.toLowerCase();
			return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
		},
		toBn(num: any) {
			if (!num) {
				return new BN(0);
			}
			let number_string = num.toString()
			if (number_string.includes('e') || number_string.includes('E')) {
				number_string = Number(num).toLocaleString('fullwide', { useGrouping: false })
			}

			const bn = new BN(number_string);
			return bn;
		},
		orderParser(orderJSON: any) {
			const formatedMessage =
				'MarketID: ' +
				orderJSON.market.id +
				'\nQuantity: ' +
				orderJSON.amount +
				'\nType: ' +
				orderJSON.type +
				'\nDirection: ' +
				orderJSON.direction +
				'\nTimestamp: ' +
				orderJSON.timestamp +
				'\nOrderID: ' +
				orderJSON.id +
				'\nAddress: ' +
				orderJSON.eth_address;

			return formatedMessage;
		},
		transactionParser(transaction: any) {
			return (
				'TransactionID: ' +
				transaction.id +
				'\nQuantity: ' +
				transaction.amount +
				'\nCurrency: ' +
				'MPR' +
				'\nTimestamp: ' +
				transaction.timestamp +
				'\nType: ' +
				transaction.type +
				'\nAddress: ' +
				transaction.eth_address
			);
		},
		formatEthAddress(eth_address: any) {
			if (!eth_address) return '';
			if (eth_address.length <= 11) return eth_address;
			return eth_address ? eth_address.substr(0, 5) + '...' + eth_address.substr(eth_address.length - 3) : '';
		},
		formatEthAddressLong(eth_address: any) {
			if (!eth_address) return '';
			if (eth_address.length <= 11) return eth_address;
			return eth_address ? eth_address.substr(0, 7) + '...' + eth_address.substr(eth_address.length - 8) : '';
		},		
		formatEmailAddress(email: any) {
			if (!email) return '';
			const [name, domain] = email.split('@');
			return `${name[0]}${new Array(name.length).join('*')}${name[name.length - 1]}@${domain}`;
		},
		/** Function which generates market id from type + symbol  */
		formatMarketId(type: any, symbol: any) {
			return (type + '_' + symbol).toUpperCase().replace(/[^a-zA-Z0-9]/g, '_');
		},
		/** Function to generate symbol without only underscore */
		formatMarketSymbol(symbol: any) {
			return symbol ? symbol.toUpperCase().replace(/[^a-zA-Z0-9]/g, '_') : '';
		},
		/** Function which generates labels for specific market type  */
		formatMarketType(type: string, isForex = false) {
			switch (type) {
				case 'stock':
					return 'Stocks';
				case 'forex':
					if (isForex) return 'Forex';
					return 'Foreign Exchange';
				case 'crypto':
					return 'Cryptocurrencies';
				case 'commodity':
					return 'Commodities';
				case 'unique':
					return 'Unique Markets';
				case 'index':
					return 'Indices';
			}
			return null;
		},
		formatSegmentName(segmentName: any) {
			return segmentName.replace(/\s+/g, '-').toLowerCase();
		},
		isNumber(evt: any, input: any) {
			evt = evt ? evt : window.event;
			const charCode = evt.which ? evt.which : evt.keyCode;

			// Numbers and dot only
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 44) {
				evt.preventDefault();
				return false;
			} else {
				// If "."(dot) already exists - prevent
				// Only 2 decimals after the comma
				//if ((charCode === 46 && input.indexOf('.') !== -1) || (input.indexOf('.') !== -1 && input.split('.')[1].length >= 2))
				//evt.preventDefault();
				//else
				return true;
			}
		},
		formatInputNumber(amount: any) {
			if (amount.includes(',')) amount = amount.replace(',', '.');

			if (amount && amount.split && amount.split('.').length > 1 && amount.split('.')[1].length > 2) {
				amount = parseFloat(amount).toFixed(2);
			}
			return amount;
		},
		daysRemaining(date: any) {
			const eventDate = moment(date);
			const todayDate = moment();
			return Math.abs(eventDate.diff(todayDate, 'days'));
		},
		toLocal(param: any) {
			let number;
			if (typeof param === 'number') number = param.toLocaleString();
			else if (typeof param === 'string') number = Number(param).toLocaleString();
			else number = '';
			return number;
		},
		percentFormatter(param: any) {
			const price = parseFloat(param);
			return price ? price.toFixed(2) : 0;
		},
		roundFormatter(param: any) {
			const price = parseFloat(param);
			const abs = Math.abs(price);
			let round = 0;
			if (10000 > abs && abs >= 10) round = 2;
			else if (10 > abs && abs >= 1) round = 3;
			else if (1 > abs && abs >= 0.1) round = 4;
			else if (0.1 > abs && abs >= 0.01) round = 5;
			else if (0.01 > abs && abs >= 0.001) round = 6;
			else if (0.001 > abs && abs >= 0.0001) round = 7;
			else if (0.0001 > abs && abs >= 0.00001) round = 8;
			else if (0.00001 > abs) round = 9;
			return price ? price.toFixed(round) : '0';
		},
		usdFormatter(param: any) {
			const price = parseFloat(param);
			const abs = Math.abs(price);
			let round = 0;
			if (abs < 0.01 && abs > 0) round = 4;
			else if (abs < 0.1 && abs > 0) round = 3;
			else round = 2;
			return price ? price.toFixed(round) : '0';
		},
		formatUSDEstimate(tokens: number, mphPrice: number, round: number) {
			let usdValue = tokens * mphPrice;
			usdValue = Math.round(usdValue / round) * round;
			return usdValue.toLocaleString();
		},
		round(number: number, decimals: number) {
			// Take number to power of decimal places and grab int floor, then divide back to get decimals
			// toFixed() makes sure that always show decimals e.g. 100.70 shows up as 100.70 instead of 100.7
			return number ? (Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals) : '0';
		},
		floorRound(number: number, decimals: number) {
			// Take number to power of decimal places and grab int floor, then divide back to get decimals
			// toFixed() makes sure that always show decimals e.g. 100.70 shows up as 100.70 instead of 100.7
			return number ? (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals) : '0';
		},
		roundToInteger(number: any) {
			// format a decimal to an integer * 10^8
			return Math.round(number * Math.pow(10, 8));
		},
		// Return deposit or withdrawal string depending on type parameter
		depositOrWithdrawal(type: any) {
			return type === 'withdraw' ? type + 'al' : type;
		},
		// Return buy or sell string depending on direction parameter
		buyOrSell(direction: string) {
			if (direction === 'pending') {
				return direction;
			}
			return direction === 'long' ? 'buy' : 'sell';
		},
		// Returning the timestamp x days ago from today
		timestampBeforeXDays(x: number) {
			return new Date(new Date().setDate(new Date().getDate() - x)).getTime();
		},
		// Time elapsed in nice format: 32min ago | 5h ago. Only designed for 24hr cycle
		timeElapsed(previous: number) {
			const current = new Date().getTime();
			const minutesPassed = (current - previous)/1000/60;
			const hoursPassed = minutesPassed/60;
			// If over 45 minutes ago
			if (hoursPassed > 0.75) {
				const hours = Math.round(hoursPassed);
				return hours + i18n.t('time.HOUR_AGO'); // Xh ago
			} else {
				if (minutesPassed < 1) return 1 + i18n.t('time.MIN_AGO'); // 1min ago
				const minutes = Math.round(minutesPassed);
				return minutes + i18n.t('time.MIN_AGO'); // Xmin ago
			}
		},
		// Start Enriched Analytics Data across Google, Quora, FullStory, CrispChat
		startAnalytics() {
			try {
				if (this.analytics_user === this.user?.id || '') {
					return
				}
				this.analytics_user = this.user?.id || ''
				if (this.$amplitude) {

					const user_list = JSON.parse(localStorage.getItem("user_list") || '{}');

					user_list[this.user?.id || ''] = Date.now();

					const keys = Object.keys(user_list)
					keys.forEach(key => {
						if (Number(user_list[key]) < Date.now() - 1000 * 60 * 60 * 24 * 120) {
							delete user_list[key];
						}
					})

					localStorage.setItem("user_list", JSON.stringify(user_list))
					
					let locale_country;

					const browserLocale =  (navigator.languages && navigator.languages[0]) || navigator.language || ''
					if (browserLocale && browserLocale.indexOf('-') > 0) {
						locale_country = browserLocale.split('-')[1]
					}

					const date = new Date(Number(this.user?.timestamp));

					const deviceId = this.$amplitude.getDeviceId() + "|" + this.$amplitude.getSessionId();
					
					if (deviceId) {
						this.setDeviceId(deviceId)
					}

					if (this.user?.id)
						this.$amplitude.setUserId(this.user?.id);

					const utm_source = localStorage.getItem('utm_source') || this.$route.query.utm_source;
					const utm_medium = localStorage.getItem('utm_medium') || this.$route.query.utm_medium;
					const utm_campaign = localStorage.getItem('utm_campaign') || this.$route.query.utm_campaign;
					const utm_term = localStorage.getItem('utm_term') || this.$route.query.utm_term;
					const utm_content = localStorage.getItem('utm_content') || this.$route.query.utm_content;
					const utm_referrer = localStorage.getItem('utm_referrer')
					const at_gd = localStorage.getItem('at_gd') || this.$route.query.at_gd;

					if (utm_referrer ) {
						UserService.sendAnalytics({event:'reactivation_source', data: {
							referrer_path: utm_referrer
						}})
						localStorage.removeItem('utm_referrer')
					}

					if (utm_source || utm_medium || utm_campaign || utm_term || utm_content || at_gd) {
						UserService.sendAnalytics({event:'utm_parameters', data: {
							utm_source,
							utm_medium,
							utm_campaign,
							utm_term,
							utm_content
						}})
						localStorage.removeItem('utm_source')						
						localStorage.removeItem('utm_medium')		
						localStorage.removeItem('utm_campaign')		
						localStorage.removeItem('utm_term')		
						localStorage.removeItem('utm_content')	
						
						

					}

					const initial_utm_source = localStorage.getItem('initial_utm_source');
					const initial_utm_medium = localStorage.getItem('initial_utm_medium');
					const initial_utm_campaign = localStorage.getItem('initial_utm_campaign');
					const initial_utm_term = localStorage.getItem('initial_utm_term');
					const initial_utm_content = localStorage.getItem('initial_utm_content');
					const initial_utm_referrer = localStorage.getItem('initial_utm_referrer');
					const initial_at_gd	 = localStorage.getItem('initial_at_gd');
					

					if (initial_utm_referrer || initial_utm_source || initial_utm_medium || initial_utm_campaign || initial_utm_term || initial_utm_content || initial_at_gd) {
						UserService.sendAnalytics({event:'initial_utm_parameters', data: {
							utm_source:initial_utm_source,
							utm_medium:initial_utm_medium,
							utm_campaign:initial_utm_campaign,
							utm_term:initial_utm_term,
							utm_content:initial_utm_content,
							utm_referrer:initial_utm_referrer,
							at_gd:initial_at_gd
						}})
	
						localStorage.removeItem('initial_utm_source');
						localStorage.removeItem('initial_utm_medium');
						localStorage.removeItem('initial_utm_campaign');
						localStorage.removeItem('initial_utm_term');
						localStorage.removeItem('initial_utm_content');
						localStorage.removeItem('initial_utm_referrer');
						localStorage.removeItem('initial_at_gd');

					}

					const identify = new this.$amplitude.Identify().set('doc_country', this.user?.document_country || '')
					    .set('locale_country', locale_country || '')
						.set('tier', this.user?.access_level?.tier || 0)
						.set('kyc_status', this.user?.kyc_status || '')
						.set('wallet_type', this.user?.wallet_type || '')
						.set('join_date', date?.toString() || '')
						.set('stake_value', Number(this.user?.activePortfolio?.stake_value || 0) / Math.pow(10,18) || 0)
						.set('portfolio_value', Number(this.user?.activePortfolio?.current_value || 0) / Math.pow(10,18) || 0)
						.set('portfolio_cash', Number(this.user?.activePortfolio?.cash_balance || 0) / Math.pow(10,18) || 0)
						.set('portfolio_currency', this.user?.payload.currency_view || 'MPH')
						.set('airdrop_total', this.user?.tokens_claimed || 0)
						.set('referral_source', this.user?.airdrop?.referral_id || '')
						.set('num_referrals', this.user?.airdrop?.num_referrals || 0)
						.set('platform', 'web');
						 


					this.$amplitude.identify(identify);

				}


			} catch (err) {
				console.log('Error initialising google analytics:', err)
			}


			// dont update analytics when impersonating a user
			if (this.impersonating) return;
			if (import.meta.env.VITE_MODE === 'production') {
				if (window.$crisp) {
					window.$crisp.push(['on', 'chat:closed', this.hideChat]);
					window.$crisp.push(['on', 'message:received', this.showChat]);
					this.setCrispChatData();
				}

				
			}
		},
		setCrispChatData() {
			// Set user info for Crisp Chat
			window.$crisp.push(['set', 'user:email', this.user?.email]);
			window.$crisp.push(['set', 'user:nickname', this.user?.username]);
			// Clear past/deprecated values
			window.$crisp.push([
				'on',
				'session:loaded',
				() => {
					const session_data = window.$crisp.get('session:data');
					if (session_data) {
						Object.keys(session_data).forEach(key => {
							if (
								['full_name', 'eth_address', 'wallet_type', 'user_status', 'kyc_status', 'date_added', 'tier'].indexOf(
									key
								) < 0
							) {
								window.$crisp.push(['set', 'session:data', [key, '']]);
							}
						});
					}
				}
			]);

			window.$crisp.push(['set', 'session:segments', [['tier' + (this.user?.access_level ? this.user?.access_level.tier : '0')], true]]);

			// Pass in basic information for quicker support
			window.$crisp.push([
				'set',
				'session:data',
				[
					[
						['full_name', this.user?.airdrop ? this.user?.airdrop.full_name || '' : ''],
						['eth_address', this.user?.eth_address?.substring(2, 100)],
						['wallet_type', this.user?.wallet_type],
						['user_status', this.user?.status],
						['kyc_status', this.user?.kyc_status],
						['date_added', this.user?.airdrop ? this.user?.airdrop.date_added || '' : ''],
						['tier', this.user?.access_level ? this.user?.access_level.tier || '' : '']
					]
				]
			]);
		},
		hideChat() {
			// Check if user is on support page, if not then hide chat from them on close
			if (window.$crisp) window.$crisp.push(['do', 'chat:hide']);
		},
		showChat() {
			if (window.$crisp && window.$crisp.is && window.$crisp.is('chat:hidden')) window.$crisp.push(['do', 'chat:show']);
		},
		startCookieAnalytics() {
			if (import.meta.env.VITE_MODE === 'production') {
				// Google Analytics
				if ((this as any).$gtag) {
					(this as any).$gtag.set('anonymize_ip', false);
					(this as any).$gtag.set('allow_google_signals', true);
				}

				// // Inject Quora pixel
				// !(function(q, e, v, n, t, s) {
				// 	if (q.qp) return;
				// 	n = q.qp = function() {
				// 		n.qp ? n.qp.apply(n, arguments) : n.queue.push(arguments);
				// 	};
				// 	n.queue = [];
				// 	t = document.createElement(e);
				// 	t.async = !0;
				// 	t.src = v;
				// 	s = document.getElementsByTagName(e)[0];
				// 	s.parentNode.insertBefore(t, s);
				// })(window, 'script', 'https://a.quora.com/qevents.js');
				// qp('init', '1d2046b63b4648f5910da20eb3a2806a');
				// qp('track', 'ViewContent');
			}
		},
		formatSymbol(symbol: string) {
			if (!symbol) {
				return ''
			}
			if (symbol.includes('NFT_')) {
				symbol = symbol.replace('NFT_', '')
				symbol = symbol.replace('_BOTTOM_1_21', '')
			}

			return symbol;
		},


		/**
		 * Log an error to sentry and include all relevant information from vue
		 * @param {*} errorDescription Error to be lodgged
		 */
		async logSentryError(errorDescription: any, customContext?: any) {
			const vueData: any = {};
			// Get the component and props data
			vueData.componentName = this.formatComponentName(this);
			if (this.$options.propsData) {
				vueData.propsData = this.$options.propsData;
			}

			if (customContext) {
				Sentry.setContext('custom', customContext);
			}

			Sentry.setContext('vue', vueData);

			// Get the component data
			if (this.$data) {
				Sentry.setContext('data', this.$data);
			}

			// Get the store data
			const store = getStore()
			if (store) {
				Sentry.setContext('store', store);
			}
			// Capture the exception
			Sentry.captureException(errorDescription);

			if (this.user && this.user?.eth_address && customContext) {
				try {
					OrderService.orderResultUpdate({ error_message: errorDescription, data: customContext });
				} catch (err) {}
			}
		},
		formatComponentName(vm: any) {
			// tslint:disable:no-unsafe-any
			if (vm.$root === vm) {
				return 'root instance';
			}
			const name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;
			return (
				(name ? 'component <' + name + '>' : 'anonymous component') +
				(vm._isVue && vm.$options.__file ? ' at ' + vm.$options.__file : '')
			);
		},
		async addSentryBreadcrumb(category: any, message: any) {
			Sentry.addBreadcrumb({
				category,
				message,
				level: 'info'
			});
		},

		async loadGoAffPro() {
			return new Promise(async (resolve) => {

				if (!document.getElementById('script_go_aff')) {
    				const elem = document.createElement("script");
    				elem.type = "application/javascript";
    				elem.src = "https://api.goaffpro.com/loader.js?shop=q7xlasdkje";
					elem.setAttribute('id', 'script_go_aff');
    				document.body.appendChild(elem);
				}

				if (window.goaffproTrackConversion) {
					resolve(true);
				} else {
					const interval = setInterval(() => {

						if (window.goaffproTrackConversion) {
							clearInterval(interval)
							resolve(true);
						}
					}, 500)
				}

			});

		},

		fillZero(input: any, length: number) {
			if (!input) {
				return ''
			}
			input = String(input)
			while (String(input).length < length) {
				input = '0' + input;

			}
			return String(input);

		},

		formatDateISO(date_input: any) {
			const date = new Date(Number(date_input))

			let date_output = date.getFullYear() + '-'
			date_output += this.fillZero(date.getMonth() + 1,2) + '-'
			date_output += this.fillZero(date.getDate(),2) + 'T'
			date_output += this.fillZero(date.getUTCHours(),2) + ':'
			date_output += this.fillZero(date.getUTCMinutes(),2)  + ':'
			date_output += this.fillZero(date.getUTCSeconds(),2)  + '.000Z'


			return date_output;

		},

		async updateAffiliate(transaction_id: any) {



			return new Promise(async (resolve) => {

				if (!window.goaffproTrackConversion) {
					await this.loadGoAffPro();
				}
				
				const return_data = await TransactionService.getTransaction({
					eth_address: this.user?.activePortfolio?.eth_address,
					transaction_id
				});


				if (return_data.data.getTransaction && return_data.data.getTransaction.id &&  return_data.data.getTransaction.affiliate_updated == false && return_data.data.getTransaction.status =='success' && return_data.data.getTransaction.type =='purchase') { 

					await TransactionService.updateTransactionAffiliate({ id: return_data.data.getTransaction.id})

					if (return_data.data.getTransaction.payload.provider && return_data.data.getTransaction.payload.purchase_total_usd && Number(this.user?.timestamp) > Date.now() - (1000 * 60 * 60 * 24 * 32)) {
						let transactionId = 'deposit-' + return_data.data.getTransaction.id;
						let ordervalue = return_data.data.getTransaction.payload.purchase_total_usd / 2;
						if (ordervalue > 1000) { 
							ordervalue = 1000
						}
						if (return_data.data.getTransaction.payload.provider =='paypal') {
							transactionId = 'paypal-' + return_data.data.getTransaction.id;
							ordervalue = return_data.data.getTransaction.payload.purchase_total_usd;
						}

						let commissionValue
						let product
						if (return_data.data.getTransaction.payload.provider =='paypal') {
							product = 'paypal'
							commissionValue = ordervalue * .1;
						} else {
							product = 'non paypal'
							commissionValue = ordervalue * .05;
						}

						const date = this.formatDateISO(return_data.data.getTransaction.timestamp)

						ordervalue = Math.round(ordervalue * 100) / 100;

						if (window.goaffproTrackConversion) {

							const goaffproOrder = {
								id: return_data.data.getTransaction.id,
								number: return_data.data.getTransaction.id,
								total: ordervalue,
								subtotal: ordervalue,
								discount: 0, 
								tax: 0, 
								shipping: 0, 
								currency:'USD', 
								date: date,
								line_items:[ 
									{
										name:product,
										quantity:1, 
										price: ordervalue,
										tax: 0,
										discount: 0, 
									}
								],
								
								status:"approved",
								commission: commissionValue
								
							} as any

							
							window.goaffproTrackConversion(goaffproOrder);
						}

					}

				}

				resolve(transaction_id)
			});
		},
		openTradingSupport(type: string) {
			
			const tradingSupportModal = {active: false, link: ''}
			if (type === 'spread') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('TradingSpreads')
			}
			if (type === 'margin') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('TradingMarginInterest')
			}
			if (type === 'paused') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('TradingPaused')
			}
			if (type === 'not-tradable') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('MarketsNonTradable');
			}
			if (type === 'splits') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('TradingStockSplit');
			}
			if (type === 'rollover') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('TradingCommodityRollover');
			}
			if (type === 'spread') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('TradingSpreads');
			}
			if (type === 'margin') {
				tradingSupportModal.active = true;
				tradingSupportModal.link = this.supportArticleLink('TradingMarginInterest');
			}

			this.setSupportModal(tradingSupportModal)
		},
	},

}) as ComponentOptionsMixin;
