import { defineStore } from 'pinia'

import router from '@/router';

import { ERROR, SUCCESS, LOADING } from '../mutation-types';
import { SnackbarProgrammatic } from 'buefy';
import EventBus from  '../event-bus';
import { i18n } from '@/plugins/i18n';

import { accessTokenName, refreshTokenName } from '@/helpers/utils';
import * as Sentry from '@sentry/vue';
import { processOverlay, closeFortmaticOverlay } from '@/helpers/fortmatic';
import { checkErrorFilter } from '@/helpers/sentry';
import { getStore } from '..';
import { getCurrentInstance } from 'vue';
let store = {
		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		admin:  {} as any,
		markets:  {} as any,
		portfolios:  {} as any,
		funds:{} as any,
		watchlist:{} as any,
	
}

export const useStatusStore = defineStore('status', {
  state: () => {
	
	store = getStore()

    return  {
      loading: true,
      success: '',
      error: 'hello',
	  sentry_recording: false

    }
  },
  getters: {
		/**
		 * Wallet text displayed in header next to wallet icon
		 * @param state
		 * @returns {{icon: string, message: string}}
		 */
		walletText() {
			if (store.contract.wrongNetwork)
				return {
					message: i18n.t('HEADER_WRONG_NETWORK'),
					icon: 'arrow-alt-circle-up'
				};
			if (store.contract.wrongWallet)
				return {
					message: i18n.t('HEADER_INCORRECT_WALLET'),
					icon: 'arrow-alt-circle-up'
				};
			if (store.contract.currentChainValid && store.contract.currentChainValid === 'same_not_valid')
				return {
					message: i18n.t('HEADER_WRONG_NETWORK'),
					icon: 'arrow-alt-circle-up'
				};

			if (store.contract.walletType === 'metamask' && !store.contract.isLoggedInMetamask)
				return {
					message: i18n.t('HEADER_UNLOCK_METAMASK'),
					icon: 'arrow-alt-circle-up'
				};

			if (store.user.data?.portfolios && store.user.data.portfolios.length === 0)
				return {
					message: 'Enable Trading',
					icon: ''
				};

			return null;
		}
	},
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
	setRecording() {
		this.sentry_recording = true
	},
    clearStore() {
		store.admin.$reset();
		store.contract.$reset();
		store.markets.$reset();
		store.modals.$reset();
		store.portfolios.$reset();
		store.funds.$reset();
		store.watchlist.$reset();
		
      this.$reset();
		},
		[LOADING](toggle: boolean) {
			this.loading = toggle;
		},

		async [ERROR](error: any) {
			let message;
			let sentryerror = '';
			// Process the error message - split messages logged to sentry from messages displayed to the user
			try {
				// if this is a graphql error then get the graphql error message
				if (error.graphQLErrors && error.graphQLErrors.length) {
					// if this is not a standard error then lofg the error to sentry and diplsy the standard 'GRAPHQL_ERROR' message
					if (error.graphQLErrors[0].message && !error.graphQLErrors[0].message.includes('_')) {
						sentryerror = error.graphQLErrors[0].message;
						message = 'GRAPHQL_ERROR';
					} else {
						message = error.graphQLErrors[0].message;
					}
					// get the redis error message
				} else if (error.message && error.message.includes('REDIS Error:')) {
					sentryerror = error.message;
					message = 'REDIS_ERROR';
					// if this is not a standard error then lofg the error to sentry and diplsy the standard 'ERROR_DEFAULT' message
				} else if (error.message && !error.message.includes('_')) {
					sentryerror = error.message;
					message = 'ERROR_DEFAULT';
				} else {
					message = error.message;
				}

				if (sentryerror) {
					// check for fortmatic logged out error. if this error is found then force a fortmatic logout and log back in
					if (
						sentryerror.toLowerCase().includes(String('Fortmatic RPC Error').toLowerCase()) &&
						sentryerror.toLowerCase().includes(String('Missing credentials in config').toLowerCase())
					) {
						console.log('Fortmatic Logged Out - sentry');
						console.log('Fortmatic Logged Out sentry - reload');

						window.location.reload();
						return;
					}

					if (checkErrorFilter(sentryerror)) {
						console.log('Error logged to sentry', sentryerror);
						// Get the store data
						Sentry.setContext('store', store);
						// Capture the exception
						Sentry.captureException(sentryerror);
					} else {
						console.log('error filtered', sentryerror);
						return;
					}
				}
			} catch (err: any) {
				console.log('Error processing message', (err as any).message || err.toString());
			}

			this.loading = false;

			// If message undefined, do nothing
			if (message === undefined) return;

			// if the access token is expired then try refresh it
			if (message === 'TOKEN_EXPIRED') {
				return EventBus.$emit('refreshToken');
			}

			if (message === 'ACCESS_DENIED') {
				return;
			}

			// Ethereum address is already in use!
			if (message === 'ETH_ADDRESS_ALREADY_EXIST') {
				EventBus.$emit('resetState');
				if (store.contract.walletType === 'portis') {
					store.contract.portis.showPortis();
				}
				store.contract.startedWallet = false;
				return (store.modals.showPortfolioInUseModal = true);
			}

			// In case that email is not verified, redirect to verify page
			if (message === 'EMAIL_NOT_VERIFIED') {
				return router.push('/register/verify').catch(() => {});
			}

			// In case that verification token has expired, redirect to resend page
			if (message === 'VERIFY_TOKEN_EXPIRED') {
				return router.push('/register/expired').catch(() => {});
			}

			// In case that reset token has expired, redirect to resend page
			if (message === 'RESET_TOKEN_EXPIRED') {
				return router.push('/reset/expired').catch(() => {});
			}

			// In case that market doesn't exists, show 404 page
			if (message === 'MARKET_NOT_EXIST') return router.push({ name: '404' }).catch(() => {});

			// Show error Snack
			// Snackbar.open({
			// 	duration: 5000,
			// 	message: String(message && message.includes('_') ? i18n.t(message) : message),
			// 	type: 'is-danger',
			// 	position: 'is-bottom-left',
			// 	actionText: 'OK',
			// 	queue: true,
			// 	onAction: () => {}
			// });
			this.error = error;
		},
		[SUCCESS](message: any) {
			if (message === 'WELCOME_TO_MORPHER') {
				return (store.modals.showOnboardModal = true);
			}

			let a = SnackbarProgrammatic as any
			let app = getCurrentInstance()
			const Snackbar = new a(app)

			Snackbar.open({
				duration: 5000,
				message: message === message.toLocaleUpperCase() ? i18n.t(message) : message,
				type: 'is-success',
				position: 'is-bottom-left',
				actionText: 'OK',
				queue: true,
				onAction: () => {}
			});
			this.success = message;
			this.loading = false;
		}
  },
})