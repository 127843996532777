import axios from 'axios';

export default {
	async formatReturnData(result_data: any) {
		try {
			let data = await result_data
			data = JSON.parse(JSON.stringify(data))
			return data
		} catch (err: any) {

			throw err.toString()
		}
	},
	/** Function which checks if provided object is empty  */
	isEmptyObject(obj: Object) {
		for (const prop in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, prop)) {
				return false;
			}
		}
		return JSON.stringify(obj) === JSON.stringify({});
	},
	/** Function which generates market id from type + symbol  */
	formatMarketId(type: string, symbol: string) {
		return (type + '_' + symbol).toUpperCase().replace(/[^a-zA-Z0-9]/g, '_');
	},
	formatMarketSymbol(symbol: string) {
		return (symbol).toUpperCase().replace(/[^a-zA-Z0-9]/g, '_');
	},
	/** Function which calculates share value  */
	calculateShareValue(direction: string, long_shares: number, average_price: number, short_shares: number, currentPrice: number) {
		return direction === 'long'
			? long_shares * currentPrice
			: 2 * average_price - currentPrice * short_shares < 0
			? 0
			: 2 * average_price - currentPrice * short_shares;
	},

	/**
	 * Get the location information for the user based on the public ip address
	 */
	async getLocation() {
		try {
			const returnData = { country_code: '', ip: '' };
			/*
            // get the IP location from the ipdata.co api
            try {
                const locationData = await axios.get(
                    'https://api.ipdata.co?api-key=0551ed4815f0cc7425f016665a5dcf3d5c1874d58cde2df33fa9b10c'
                );
                returnData.country_code = locationData.data.country_code;
                returnData.ip = locationData.data.ip;
            } catch {
                returnData.country_code = returnData.country_code || '';
                returnData.ip = returnData.ip || '';
            }

            // get the IP location from the ipinfo.io api
            if (!returnData.country_code) {
                try {
                    const locationData = await axios.get('https://ipinfo.io/json');

                    returnData.country_code = locationData.data.country;
                    returnData.ip = locationData.data.ip;
                } catch {
                    returnData.country_code = returnData.country_code || '';
                    returnData.ip = returnData.ip || '';
                }
            }*/

			if (!returnData.country_code) {
				try {
					// get the IP location from the geojs.io api
					const locationData = await axios.get('https://get.geojs.io/v1/ip/geo.json');

					returnData.country_code = locationData.data.country_code;
					returnData.ip = locationData.data.ip;
				} catch {
					returnData.country_code = returnData.country_code || '';
					returnData.ip = returnData.ip || '';
				}
			}

			return returnData;
		} catch (err) {
			return { Error: (err as any).message || (err as any).toString() };
		}
	},
	shuffleArray(array: any) {
		if (!array) {
			return array
		}
		let currentIndex = array.length,
			randomIndex;

		// While there remain elements to shuffle...
		while (currentIndex != 0) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
		}

		return array;
	}
};
