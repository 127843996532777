<template>
	<section>
		<b-modal :modelValue="showUpgradeTierModal" has-modal-card
			:can-cancel="block_close ? [] : ['escape', 'x', 'outside']" @close="closeModal()">
			<!-- Upgrade to Mogul: Tier 1/2/3 - KYC Required beyond Investor Limits -->
			<div v-if="user?.access_level?.withdrawal_block_reason == 'portfolio value'" class="modal-card terminal">
				<div class="header-image yellow">
					<img src="@/assets/img/img_identity_flat.svg" />
				</div>
				<div class="p-4 has-text-left">
					<h2 class="is-size-18 has-text-weight-bold">{{ $t('VERIFY_IDENTITY') }}</h2>
					<p class="is-size-14 is-line-height-20 mt-3">{{ $t('TIER_UPGRADE_KYC') }}</p>
					<div class="bullet-point">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<p>{{ $t('TIER_MOGUL_POINT_2') }}</p>
					</div>
					<div class="bullet-point">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<p>{{ $t('TIER_MOGUL_POINT_1') }}</p>
					</div>
					<div class="bullet-point">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<p>{{ $t('TIER_MOGUL_POINT_3') }}</p>
					</div>
					<button class="button plain-primary-btn is-fullwidth mt-4"
						@click="(user?.access_level?.tier || 0) < 3 ? sendTo('/settings') : sendTo('/settings?action=verify')">
						{{ $t('COMPLETE_VERIFICATION_BUTTON') }}
					</button>
				</div>
			</div>

			<div v-else-if="user?.access_level?.withdrawal_block_reason == 'paypal'" class="modal-card terminal paypal">
				<h2 class="title is-size-4 has-text-weight-medium has-text-centered">
					{{ $t('WAITING_FOR_BONUS_UNLOCK') }}
				</h2>

				<div class="has-text-centered">
					<p class="is-size-18 has-text-grey">
						<span class="has-text-gold big-bold-text">10%&nbsp;</span>
						<b-icon icon="fas fa-gift" pack="fas" size="is-small" />
						{{ $t('BONUS_EARNED') }}
					</p>
				</div>

				<p class="mt-20">{{ $t('BONUS_EARNED_DESC') }}</p>

				<p class="mt-20 has-text-weight-medium">{{ $t('WHY_WITHDRAWALS_LOCKED') }}</p>

				<p>{{ $t('WHY_WITHDRAWALS_LOCKED_DESC') }}</p>

				<div v-if="user.access_level.withdrawal_unblock_date" class="mt-20">
					<p class="has-text-weight-medium">{{ $t('WITHDRAWALS_UNLOCK_AFTER') }}:</p>
					<p class="is-size-5">{{ withdrawalUnlockDate }}</p>
				</div>

				<div v-if="user.access_level.withdrawal_unblock_date" class="mt-20">
					<p class="has-text-weight-medium">
						{{ $t('NEED_HELP') }}
						<a :href="supportArticleLink('WithdrawLocked')" class="transition-faster" target="_blank">{{
							$t('auth.VISIT_SUPPORT') }}</a>
					</p>
				</div>

				<button class="button plain-primary-btn is-fullwidth mt-4" @click="sendTo('/funds')">
					{{ $t('BACK_FUNDS') }}
				</button>

			</div>

			<!-- Upgrade to Investor: Tier 1/2 - Deposit required to unlock withdrawals -->
			<div v-else-if="(user?.access_level?.tier || 0) < 3" class="modal-card terminal">
				<div class="header-image blue">
					<img src="@/assets/img/img_deposit_flat.svg" />
				</div>
				<div class="p-4 has-text-left">
					<h2 class="is-size-18 has-text-weight-bold">{{ $t('FUND_ACCOUNT_HEADER') }}</h2>
					<p class="is-size-14 is-line-height-20 mt-3">{{ $t('TIER_UPGRADE_DEPOSIT') }}</p>
					<div class="bullet-point">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<p>{{ $t('TIER_INVESTOR_POINT_1') }}</p>
					</div>
					<div class="bullet-point">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<p>{{ $t('TIER_INVESTOR_POINT_2') }}</p>
					</div>
					<div class="bullet-point">
						<img src="@/assets/icons/check-bulletpoint.svg" />
						<p>{{ $t('TIER_INVESTOR_POINT_3') }}</p>
					</div>
					<button class="button plain-primary-btn is-fullwidth mt-4" @click="sendTo('/funds/deposit')">
						{{ $t('funds.DEPOSIT_NOW') }}
					</button>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalsStore } from '@/store/modules/modals';
import { useUserStore } from '@/store/modules/user';
import { mapActions, mapState } from 'pinia';

//vuex

/* @group Components_Modals */
/*
	<h4> <b> Upgrade Tier Modal </b> </h4>
	<br> Show user upgrade screen and take to settings page.
*/
export default defineComponent({
	name: 'UpgradeTierModal',
	components: {},
	data() {
		return {
			page_type: '',
			block_close: false
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showUpgradeTierModal: (state) => state.showUpgradeTierModal,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),

		// Calculate Withdrawal Date
		withdrawalUnlockDate() {
			const unlockDate = new Date(this.user?.access_level?.withdrawal_unblock_date || 0);
			const day = unlockDate.getDate();
			const months = [
				this.$t('months.JANUARY'),
				this.$t('months.FEBRUARY'),
				this.$t('months.MARCH'),
				this.$t('months.APRIL'),
				this.$t('months.MAY'),
				this.$t('months.JUNE'),
				this.$t('months.JULY'),
				this.$t('months.AUGUST'),
				this.$t('months.SEPTEMBER'),
				this.$t('months.OCTOBER'),
				this.$t('months.NOVEMBER'),
				this.$t('months.DECEMBER')
			];
			const month = months[unlockDate.getMonth()];
			const year = unlockDate.getFullYear();
			return month + ' ' + day + ', ' + year;
		},
	},
	watch: {
		// Catch users navigating to purchase page
		$route(to, from) {
			if (to.name !== 'Purchase' && this.block_close) {
				this.closeModal();
			}
		},
		async showUpgradeTierModal(nv) {
			if (nv) {

				if (this.$route.name === 'Purchase') {
					this.page_type = 'deposit';
					this.block_close = true;
				} else if (this.$route.params.type === 'withdraw' || this.$route.path === '/funds') {
					this.page_type = 'withdraw';
				} else {
					this.page_type = 'deposit';
				}
			}
		}
	},
	mounted() {
		// If user lands on Purchase page, force open modal
		if (this.$route.name === 'Purchase') {
			this.page_type = 'deposit';
			this.block_close = true;
		} else if (this.$route.params.type === 'withdraw' || this.$route.path === '/funds') {
			this.page_type = 'withdraw';
		} else {
			this.page_type = 'deposit';
		}
	},
	methods: {
		...mapActions(useModalsStore, {
			toggleUpgradeTierModal: 'toggleUpgradeTierModal',
		}),

		closeModal() {
			this.toggleUpgradeTierModal(false);
			this.page_type = '';
			this.block_close = false;
		},
		sendTo(route: string) {
			this.$router.push(route).catch(() => { });
			this.closeModal();
		}
	}
});
</script>
<style scoped>
.modal-card {
	font-family: 'Manrope', sans-serif;
}

.modal-card.paypal {
	padding: 20px;
}

.header-image {
	text-align: center;
	/* height: 188px; */
	padding: 1.5rem 0;
}

.header-image.yellow {
	background-color: #FFF9E3;
}

.header-image.blue {
	background-color: #EAFCFF;
}

/* .header-image img {
	height: 200px;
	margin: 16px auto;
} */

.bullet-point {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-top: 1rem;
}

.bullet-point p {
	font-size: 14px;
	font-weight: 700;
	line-height: 1.25rem;
}

.has-text-gold {
	background: linear-gradient(134deg, #ffc77c 0%, #d8a159 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
</style>
