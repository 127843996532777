<template>
	<div class="has-font-manrope">
		<!-- currency selection -->
		<div class="has-text-left" v-if="selectCurrency">
			<div class="header-bar-global">
				<button class="button back-btn ml-5" @click="closeWithdrawAddress()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.DESTINATION_ADDRESS') }}
			</div>

			<div>
				<div v-if="!wallet_selected">
					<div class="wallet-message padded mt-2">
						<p>
							{{ $t('funds.ENTER_ADDRESS', {
								network: $t('chains.' +
									funds.withdraw_currency.chain.toUpperCase())
							}) }}
						</p>
					</div>
				</div>
				<!-- search bar -->
				<div class="mt-15 padded is-44h">
					<b-input icon="../../assets/funds/wallet-search.svg" class="history-search large" expanded
						id="search-input" ref="search" v-model="search" :placeholder="$t('transfer.SEND_PLACEHOLDER')"
						autocomplete="off" @focus="searchActive = true" @input="updateSearchFilter"
						@change="updateSearchFilter" :icon-right="search ? 'times' : ''" icon-right-clickable
						@icon-right-click="clearSearch" />
					<img class="search-icon" src="../../assets/funds/wallet-search.svg" />
				</div>

				<div v-if="!wallet_selected">
					<div v-if="recent_filtered.length > 0 || user_filtered.length > 0"
						class="payment_method_body has-text-left pt-4">
						<!-- Recent Withdrawal Addresses -->
						<p class="small-headers" v-if="recent_filtered.length > 0">{{ $t('funds.RECENT_WITHDRAWALS') }}
						</p>
						<div class="list-section" v-for="(method, index) in recent_filtered" :key="method.index"
							:class="{ 'list-section-last': index == recent_filtered.length - 1 }"
							@click="selectAddress(method)">
							<div style="width: 100%" v-if="method.withdraw_wallet_type == 'exchange'">
								<p class="currency-header">{{ formatExchange(method.withdraw_wallet_exchange) }}</p>
								<p class="currency-symbol">
									{{ $t('funds.WALLET_TYPE_EXCHANGE') }} -
									{{ formatEthAddressLong(method.eth_address) }}
								</p>
							</div>
							<div style="width: 100%" v-else>
								<p class="currency-header">{{ formatEthAddressLong(method.eth_address) }}</p>
							</div>
							<img src="@/assets/funds/arrow-right.svg" />
						</div>
						<!-- Saved Wallets -->
						<p class="small-headers" v-if="user_filtered.length > 0">{{ $t('funds.YOUR_WALLETS') }}</p>
						<div class="list-section" v-for="(method, index) in user_filtered" :key="method.index"
							:class="{ 'list-section-last': index == user_filtered.length - 1 }"
							@click="selectAddress(method)">
							<div style="width: 100%">
								<p class="currency-header">{{ method.name.replace('Main Wallet',
									$t('funds.MAIN_WALLET')) }}</p>
								<p class="currency-symbol">
									<span v-if="method.withdraw_wallet_type == 'exchange'">
										{{ $t('funds.WALLET_TYPE_EXCHANGE') }} -
									</span>
									{{ method.eth_address.includes('0x') ? formatEthAddressLong(method.eth_address) :
										method.eth_address }}
								</p>
							</div>
							<img src="@/assets/funds/arrow-right.svg" />
						</div>
					</div>
					<div v-else class="empty-state-section has-text-centered padded">
						<!-- Currency Search Empty State -->
						<img src="@/assets/funds/search_wallet_empty_state.svg" class="empty-img"
							alt="Empty search for wallet address" />
						<p class="empty-text">{{ $t('funds.NO_WALLET_FOUND') }}</p>
					</div>
				</div>

				<div class="is-size-12 has-text-darkgrey padded mt-5px" v-else>
					<div class="wallet-message mt-2">
						<img src="@/assets/funds/selected.svg" alt="Checkbox Icon" />
						<p>
							{{
								$t('funds.VALID_ADDRESS', {
									wallet_name: wallet_selected_name || formatEthAddress(wallet_selected_address)
								})
							}}
						</p>
					</div>

					<!-- Exchange Wallet Toggle -->
					<div v-if="funds.withdraw_currency.name === 'POL' || exchangeWallet"
						class="mt-15 is-inline-flex is-align-items-start">
						<b-checkbox v-model="exchangeWallet"
							:disabled="impersonating || funds.withdraw_currency.name !== 'POL'" size="14"
							class="exchange-checkbox" />
						<div>
							<div>
								<p class="has-text-weight-bold has-text-darkergrey is-line-height-20 is-size-14">
									{{ $t('funds.WALLET_TYPE_EXCHANGE') }}
									<b-tooltip :label="$t('funds.WALLET_TYPE_DESC')" size="is-medium" position="is-top"
										type="is-dark" class="ToolTipText" multilined animated>
										<a class="dark-hover transition-faster is-size-14" style="color:#686475">
											<b-icon icon="question-circle" pack="fas" size="is-small" />
										</a>
									</b-tooltip>
								</p>
							</div>
							<p class="is-size-12 is-line-height-20 has-text-darkergrey">{{
								$t('funds.WALLET_TYPE_SUPPORT') }}</p>
						</div>
					</div>
					<!-- Dropdown -->
					<div v-if="exchangeWallet" class="mt-15">
						<b-dropdown ref="wallet_exchange" v-model="wallet_exchange" aria-role="list"
							:mobile-modal="false" class="type-dropdown left-normal" expanded scrollable
							max-height="160px">
							<template #trigger="{ active }">
								<div
									class="is-flex is-align-items-center is-justify-content-space-between type-trigger transition-fast">
									<p v-if="!wallet_exchange" class="has-text-dark-grey">{{ $t('funds.WALLET_EXCHANGE')
										}}</p>
									<p v-if="wallet_exchange === 'binance'">Binance</p>
									<p v-if="wallet_exchange === 'coinbase'">Coinbase</p>
									<p v-if="wallet_exchange === 'kucoin'">KuCoin</p>
									<p v-if="wallet_exchange === 'kraken'">Kraken</p>
									<p v-if="wallet_exchange === 'gate.io'">Gate.io</p>
									<p v-if="wallet_exchange === 'bybit'">ByBit</p>
									<p v-if="wallet_exchange === 'other'">{{ $t('OTHER') }}</p>
									<img src="@/assets/funds/chevron-down-feather.svg" class="toggle-arrow-icon" :class="{
										'rotate-arrow': active
									}" />
								</div>
							</template>
							<b-dropdown-item value="binance" aria-role="listitem">Binance</b-dropdown-item>
							<b-dropdown-item value="coinbase" aria-role="listitem">Coinbase</b-dropdown-item>
							<b-dropdown-item value="kucoin" aria-role="listitem">KuCoin</b-dropdown-item>
							<b-dropdown-item value="kraken" aria-role="listitem">Kraken</b-dropdown-item>
							<b-dropdown-item value="gate.io" aria-role="listitem">Gate.io</b-dropdown-item>
							<b-dropdown-item value="bybit" aria-role="listitem">ByBit</b-dropdown-item>
							<b-dropdown-item value="other" aria-role="listitem">{{ $t('OTHER') }}</b-dropdown-item>
						</b-dropdown>
					</div>

					<div class="wallet-exchange-error mt-15"
						v-if="!exchangeWallet && funds.withdraw_currency.name !== 'POL'">
						<div class="is-flex is-align-items-center gap-8" v-if="funds.withdraw_currency">
							<div>
								<img src="@/assets/funds/warning-icon.svg" class="transaction_icon" />
							</div>
							<div class="is-size-12">
								<p class="is-line-height-1">
									<span class="has-text-darkergrey" v-html="$t('funds.WITHDRAW_MPH_NO_EXCHANGE')" />
								</p>
								<div class="mt-1">
									<a @click="switchPOL()" class="transition-faster is-size-12">{{
										$t('funds.SWITCH_POL') }}</a>
								</div>
							</div>
						</div>
					</div>

					<div class="wallet-exchange-error mt-15"
						v-if="exchangeWallet && funds.withdraw_currency.name !== 'POL'">
						<div class="centered-flex-gap gap-8" v-if="funds.withdraw_currency">
							<img v-if="funds.withdraw_currency.icon" src="@/assets/funds/warning-icon.svg"
								class="provider-icon" />
							<p class="is-size-12 is-line-height-1">
								<span class="has-text-darkgrey">
									{{ $t('funds.WITHDRAW_EXCHANGE_ERROR_WALLET') }}
								</span>
							</p>
						</div>
					</div>

					<div v-else class="address-confirm mt-15">
						<p class="address-confirm-header">{{ $t('funds.CONFIRM_ADDRESS') }}</p>
						<p>{{ $t('funds.WALLET_ADDRESS_CONFIRM') }}</p>
					</div>

					<button data-cy="withdrawFunds" class="button flat-white-btn xlarge is-fullwidth mt-20 mb-5"
						@click="confirmWithdrawAccount()"
						:disabled="(exchangeWallet && noWalletExchange) || (exchangeWallet && funds.withdraw_currency.name !== 'POL')">
						<span class="withdraw-button has-font-manrope">{{ $t('funds.WITHDRAW_HERE') }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useFundsStore } from '@/store/modules/funds';
import { PortfolioService } from '@/services/PortfolioService';
import { copyToClipboard, resolveUnstoppable } from '@/helpers/utils';
import { isAddress } from 'viem';

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'Deposit',
	data() {
		return {
			recent_filtered: [] as any[],
			user_filtered: [] as any[],
			search: null as any,
			searchActive: false,
			selectCurrency: true,
			address_list: { recent: [] as any[], user: [] as any[] },
			wallet_selected: false,
			wallet_selected_address: '',
			wallet_selected_name: '',
			lookup_timeout: null as null | NodeJS.Timeout | number,
			exchangeWallet: false,
			wallet_type: 'user',
			wallet_exchange: '',
			noWalletType: false,
			noWalletExchange: true
		};
	},
	watch: {
		exchangeWallet(nv) {
			if (nv) {
				this.wallet_type = 'exchange';
			} else {
				this.wallet_type = 'user';
			}
		},
		wallet_type(nv) {
			if (nv) {
				this.noWalletType = false;
			}
		},
		wallet_exchange(nv) {
			if (nv) {
				this.noWalletExchange = false;
			}
		},
		search(nv) {
			if (!nv) {
				this.wallet_selected = false;
				this.wallet_selected_name = '';
				this.wallet_selected_address = '';
				return;
			}

			if (isAddress(nv)) {
				this.wallet_selected = true;
				this.wallet_selected_address = nv;
				const address = this.address_list.user.find(
					wallet =>
						(wallet.eth_address || '').toLowerCase() == this.wallet_selected_address.toLowerCase() ||
						(wallet.address_resolved || '').toLowerCase() == this.wallet_selected_address.toLowerCase()
				);
				if (address) {
					this.wallet_selected_name = address.name;
				}

				return;
			}

			const address = this.address_list.user.find(
				wallet =>
					(wallet.eth_address || '').toLowerCase() == nv.toLowerCase() ||
					(wallet.address_resolved || '').toLowerCase() == nv.toLowerCase() ||
					(wallet.name || '').toLowerCase() == nv.toLowerCase()
			);
			if (address) {
				this.wallet_selected = true;
				this.wallet_selected_address = nv;
				this.wallet_selected_name = address.name;
				this.wallet_selected_address = address.address_resolved;
				return;
			}

			if (nv.length > 2 && nv.substring(0, 2) !== '0x' && nv.includes('.')) {
				if (this.lookup_timeout) {
					clearTimeout(this.lookup_timeout);
					this.lookup_timeout = null;
				}
				this.lookup_timeout = setTimeout(async () => {
					let address = null;
					try {
						address = await resolveUnstoppable(nv, 'ETH');

						if (address) {
							this.wallet_selected = true;
							this.wallet_selected_name = '';
							this.wallet_selected_address = address;
							return;
						}
					} catch (err) {
						this.wallet_selected = false;
						this.wallet_selected_name = '';
						this.wallet_selected_address = '';
						return;
					}
				}, 500);
			}

			//address = await resolveUnstoppable(nv, 'ETH');

			this.wallet_selected = false;
			this.wallet_selected_name = '';
			this.wallet_selected_address = '';
		}
	},
	computed: {
		...mapState(useContractStore, {
			balance: (state) => state.balance,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
			impersonating: state => state.impersonating

		}),
		...mapState(useFundsStore, {
			funds: (state) => state,
		}),
		network_fee() {
			const gas_price_eth = this.balance.gas_price * 200000;
			const gas_price_usd = (gas_price_eth * this.balance.eth_price) / Math.pow(10, 9);
			const gas_price_mph = gas_price_usd / this.balance.mph_rate;
			return Math.ceil(gas_price_mph / 500) * 500 + 100;
		}
	},
	async mounted() {
		const data = await PortfolioService.getWithdrawalAddress({ eth_address: this.user?.activePortfolio?.eth_address });

		let index = 0;
		if (data && data.length > 0) {
			data.forEach((address: any) => {
				if (address.type == 'recent') {
					address.index = index;
					this.address_list.recent.push(address);
				} else {
					address.index = index;
					this.address_list.user.push(address);
				}

				index += 1;
			});
		}
		this.updateSearchFilter();
	},
	methods: {
		...mapActions(useFundsStore, {
			updateFunds: 'updateFunds',
		}),

		switchPOL() {
			const currency = { chain: 'polygon', icon: 'pol.svg', name: 'POL', symbol: 'POL ' + this.$t('ON') + ' Polygon' };
			this.updateFunds({ withdraw_currency: currency, showPaymentMethod: false, showWithdrawMethod: false });
		},
		closeWithdrawAddress() {
			this.updateFunds({ showWithdrawAddress: false });
		},
		updateSearchFilter() {
			this.recent_filtered = [];
			this.user_filtered = [];

			this.address_list.recent.forEach(method => {
				if (
					!this.search ||
					(method.name || '').toLowerCase().includes(this.search.toLowerCase()) ||
					(method.eth_address || '').toLowerCase().includes(this.search.toLowerCase()) ||
					(method.address_resolved || '').toLowerCase().includes(this.search.toLowerCase())
				) {
					this.recent_filtered.push(method);
				}
			});

			this.address_list.user.forEach(method => {
				if (
					!this.search ||
					(method.name || '').toLowerCase().includes(this.search.toLowerCase()) ||
					(method.eth_address || '').toLowerCase().includes(this.search.toLowerCase()) ||
					(method.address_resolved || '').toLowerCase().includes(this.search.toLowerCase())
				) {
					this.user_filtered.push(method);
				}
			});
		},
		clearSearch() {
			this.search = '';
			this.updateSearchFilter();
		},

		confirmWithdrawAccount() {
			if (!this.wallet_type) {
				this.noWalletType = true;
				return;
			}

			if (this.wallet_type == 'exchange' && !this.wallet_exchange) {
				this.noWalletExchange = true;
				return;
			}
			this.updateFunds({
				withdraw_account: this.wallet_selected_address,
				withdraw_account_name: this.wallet_selected_name,
				showWithdrawAddress: false,
				withdraw_wallet_type: this.wallet_type,
				withdraw_wallet_exchange: this.wallet_exchange
			});
		},
		selectAddress(address: any) {
			this.search = address.eth_address;
			this.wallet_type = address.withdraw_wallet_type || 'user';
			this.exchangeWallet = address.withdraw_wallet_type == 'exchange';
			this.wallet_exchange = address.withdraw_wallet_exchange;
		},
		formatExchange(exchange: string) {
			if (exchange == 'ftx') {
				return 'FTX';
			}
			if (exchange == 'kucoin') {
				return 'KuCoin';
			}
			if (exchange == 'other') {
				return 'Exchange (other)';
			}
			// Simple formatting for Binance, Gate.io, Crypto.com
			return this.firstLetterUppercase(exchange);
		}
	}
});
</script>
<style scoped>
.header-bar {
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	cursor: pointer;
	height: 48px;
	line-height: 48px;
	font-family: 'Rubik', sans-serif;
	font-size: 16px;
	font-weight: bold;
}

.deposit_message {
	font-family: 'Roboto', sans-serif !important;
	font-size: 14px;
	color: #666666;
	text-align: center;
}

.deposit_amount {
	width: 100%;
	border: 0;
	text-align: center;
	color: #333333;
	font-size: 50px;
}

.deposit_section {
	border: 1px solid #eae9ed;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding: 15px;
}

.deposit_content {
	margin-left: 24px;
	margin-right: 24px;
}

.padded {
	margin-left: 24px;
	margin-right: 24px;
}

.header_icon {
	width: 16px;
	float: left;
}

.payment_method_body {
	font-family: 'Roboto', sans-serif !important;
	font-size: 14px;
	line-height: 18px;
	color: #6e6e6e;

	overflow-y: auto;
	max-height: calc(50vh);
	padding-left: 24px;
	padding-right: 24px;
	min-height: 250px;
}

@media screen and (max-width: 768px) {
	.payment_method_body {
		max-height: calc(100vh - 180px);
		min-height: inherit;
	}

	.payment_provider_body {
		min-height: inherit;
	}
}

.agreement_list {
	list-style: disc outside none;
	padding-left: 16px;
}

.currency_section {
	padding-top: 16px;
	padding-bottom: 14px;
	border-bottom: 1px solid #eae9ed;
	cursor: pointer;
}

.currency_icon {
	display: inline-block;
	position: relative;
	top: -10px;
}

.currency_text {
	padding-left: 5px;
	display: inline-block;
}

.currency_section .image {
	width: 20px;
	height: 20px;
	border-radius: 13px;
	background-color: rgba(0, 195, 134, 0.2);
	font-size: 15px;
	color: #333333;
	font-family: 'Roboto', sans-serif !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}

.currency_header {
	font-size: 16px;
	line-height: 20px;
	color: #333333;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
}

.currency_symbol {
	font-size: 14px;
	line-height: 24px;
	color: #666666;
}

.payment_provider_body {
	overflow-y: auto;
	max-height: calc(100vh - 180px);
	display: inline-block;
	width: 100%;
	min-height: 375px;
}

.payment_provider_section {
	border: 1px solid #eae9ed;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding: 1rem;
	display: flex;
	transition: background-color 0.25s;
	align-items: center;
	gap: 0.5rem;
}

.payment_provider_section:hover {
	cursor: pointer;
	transition: background-color 0.25s;
	background-color: #f5f5f5;
}

.provider-icon {
	height: 24px;
	align-self: flex-start;
}

.rec-tag {
	padding: 5px 8px;
	display: inline-block;
	background: rgba(0, 195, 134, 0.1);
	border-radius: 5px;
	font-size: 12px;
	line-height: 14px;
	color: #007b55;
	font-weight: 500;
}

.provider_header {
	font-size: 16px;
	line-height: 1;
	font-weight: 500;
	color: #333;
}

.provider_body {
	font-size: 14px;
	line-height: 16px;
	color: #666666;
	margin-top: 0.5rem;
}

.provider_gift_icon {
	font-size: 14px;
	margin-right: 5px;
}

.provider-bonus {
	color: #333333;
	padding: 5px 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	background: #fae3c2;
	border-radius: 5px;
	margin-top: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.token_icon_image {
	width: 20px;
	height: 20px;
}

.empty-state-section {
	margin-top: 60px;
	margin-bottom: 100px;
}

.empty-state-section .empty-img {
	height: 64px;
}

.empty-state-section .empty-text {
	color: #666;
	font-weight: 500;
}

.list-section {
	transition: background-color 0.25s;
	display: flex;
	align-items: center;
	padding: 15px 26px;
	border-radius: 8px;
	border: 1px solid #eae9ed;
	display: flex;
	margin-top: 16px;
}

.list-section:hover {
	cursor: pointer;
	background: #f5f5f5;
	transition: background-color 0.25s;
}

.list-section-last {
	margin-bottom: 16px;
}

.list-section .image {
	width: 20px;
	height: 20px;
	background-color: rgba(0, 195, 134, 0.2);
	font-size: 15px;
	color: #333333;
	font-family: 'Roboto', sans-serif !important;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	margin-right: 8px;
}

.list-section .currency-header {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #333333;
}

.list-section .currency-symbol {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #666666;
	margin-top: 5px;
}

.list-section .icon-image {
	height: 20px;
	margin-right: 8px;
}

.withdraw-currency-section {
	font-size: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -1rem;
	padding: 1rem;
	transition: background-color 0.25s;
	border-radius: 7px 7px 0 0;
}

.withdraw-currency-section:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.provider-icon {
	height: 20px;
}

.centered-flex-gap {
	display: flex;
	align-items: center;
	gap: 5px;
}

.search-icon {
	position: relative;
	top: -33px;
	left: 15px;
}

.wallet-message {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #333333;
	display: flex;
	gap: 5px;
	align-items: center;
}

.wallet-message-icon {
	padding-right: 1px;
	display: inline-block;
	position: relative;
	top: 2px;
}

.address-confirm-header {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #333333;
	margin-bottom: 5px;
}

.address-confirm {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

.wallet-exchange-error {
	border: 1px solid #eae9ed;
	border-radius: 8px;
	line-height: 20px;
	padding: 16px;
}

.is-warning {
	color: #fc6404;
}

.gap-8 {
	gap: 8px;
}

.dark-hover {
	color: #ababac;
	cursor: pointer;
}

.dark-hover:hover {
	color: #666;
	cursor: pointer;
}

.transaction_icon {
	height: 20px;
	min-width: 20px;
}

.exchange-checkbox {
	padding-top: 3px;
}

.withdraw-button {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #686475;
}

.is-44h {
	height: 44px;
}
</style>
