
query getWithdrawTransaction($start_date: String, $end_date: String, $currency: String, $eth_address: String, $limit: Int, $offset: Int, $order: JSON) {
    getWithdrawTransaction(start_date: $start_date, end_date: $end_date, currency: $currency, eth_address: $eth_address, limit: $limit, offset: $offset, order: $order) {
        id
        type
        eth_tx_id
        timestamp
        type
        amount
        status
        eth_address
        payload
        payment_status
        email
        cash_balance
        current_value
        document_country
        join_date
    }
}
