import { defineStore } from 'pinia'
import { CONTRACT, ROOT, SUCCESS, ERROR } from '../mutation-types';
import { morpherOracleAbi, morpherTokenAbi, morpherBridgeAbi } from '@/contracts/abis';
import to from 'await-to-js';
import Fortmatic from 'fortmatic';

import axios from 'axios';
import Util from '@/services/shared';
import EventBus from '../event-bus';
import { UserService } from '@/services/UserService';
import { portisConfig } from '@/helpers/portis';
import { fortmaticConfig } from '@/helpers/fortmatic';
import { morpherWalletConfig } from '@/helpers/morpherwallet';
import MorpherWallet from 'morpherwallet-sdk';
import Cookie from 'js-cookie';
import { i18n } from '@/plugins/i18n';
import router from '../../router/';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { getStore } from '..';
import type { PublicClient, WalletClient } from 'viem';
import { ESideChainTransaction, getContract, getPublicClient, getWalletClient } from '@/helpers/viem';
import type { TAddress } from '@/types/graphql-global-types';
let store = {

		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		status:  {} as any,
	
}

export interface ContractState {
	eth_address: string;
	lastActivePortfolio: boolean;

	startedWallet: boolean;

	isLoggedInMetamask: boolean;
	isLoggedInWallet: boolean;

	networkCheckTimer: any;
	wrongNetwork: boolean;
	wrongWallet: boolean;
	morpherAlpha: boolean;
	morpherPlatform: boolean;
	morpherOracle: any | null;
	morpherBridge: any | null;
	morpherOracleLoaded: string;
	balance: any;
	loadingFreeTestEther: boolean;
	depositTransactionHash: string;
	loadingPortis: boolean;
	portisIFrameHeight: number;
	portisIFrameTimeout: NodeJS.Timeout | undefined;
	portis: any;
	portisError: string;
	walletClient?: any;
	walletClientMainchain: any;
	provider: any;
	walletType: string;
	fortmatic: any;
	morpherwallet: any;
	walletAmount: string;
	walletState: string;
	walletMarket: string;
	walletInitialisationDate: number;
	lastTransactionDate: number | null;
	allowMainchain: boolean;
	network_id: number;
	transactOnNetwork: string;
	currentChainValid: 'different' | 'same_not_valid' | null;
	socialRecovery: boolean;
	confirmationTimeout: boolean;
	orderCompleteMessage: String;
}

declare global {
	interface Window {
		ethereum: any;
	}
}

export const getLoggedIn = (state: any, retry = 0):Promise<boolean> => {
	let resolved = false;
	return new Promise((resolve) => {

		if (!state.morpherwallet) {
			return resolve(false);
		}

		const timer = setTimeout(async () => {

			if (retry < 10) {
				retry +=1;
				
				return resolve(await getLoggedIn(state, retry))
			} else {
				return resolve(false);
			}
		}, 1000)		

		state.morpherwallet.isLoggedIn().then((loggedIn: any) => {

			if (!resolved) {
				if (timer) {
					clearTimeout(timer);
				}
				resolved = true;
				return resolve(loggedIn.isLoggedIn as boolean)
			}
		});

	})
};


export const useContractStore = defineStore('contract', {
  state: () => {
	store = getStore()

    const initialState: ContractState = {
      eth_address: '',
      lastActivePortfolio: false,
      isLoggedInMetamask: false,
      networkCheckTimer: false,
      startedWallet: false,
      wrongNetwork: false,
      wrongWallet: false,
      morpherAlpha: false,
      morpherPlatform: false,
      morpherOracle: false,
      morpherBridge: false,
      morpherOracleLoaded: '',
      balance: {},
      loadingFreeTestEther: false,
      depositTransactionHash: '',
      loadingPortis: false,
      portisIFrameHeight: 0,
      portisIFrameTimeout: undefined,
      portis: null,
      isLoggedInWallet: false,
      portisError: '',
      walletClientMainchain: null,
      provider: null,
      walletType: '',
      fortmatic: null,
      morpherwallet: null,
	  walletClient: undefined,
      walletAmount: '',
      walletState: '',
      walletMarket: '',
      walletInitialisationDate: 0,
      lastTransactionDate: null,
      allowMainchain: false,
      network_id: 0,
      transactOnNetwork: 'sidechain',
      currentChainValid: null,
      socialRecovery: true,
      confirmationTimeout: false,
      orderCompleteMessage: ''
    }
    return initialState
  },
//   getters: {
// 	morpherAlpha: state => state.morpherAlpha,
// 	morpherPlatform: state => state.morpherPlatform,
// 	morpherOracle: state => state.morpherOracle,
// 	morpherBridge: state => state.morpherBridge,
// 	eth_address: state => state.eth_address
// },
  actions: {

	async setConfirmationTimeout(params: any) {
		this[CONTRACT.CONFIRMATION_TIMEOUT](params);
	},

	async setOrderCompleteMessage(params: any) {
		this[CONTRACT.ORDER_COMPLETE_MESSAGE](params);
	},

	

	/**
	 * Starting Metamask, fetching address, network checking, updating address, fetching contracts
	 
	 * @param state
	 * @param rootState
	 * @param params
	 * @returns {Promise<void>}
	 */
	async startWallet(params: any) {
		// clear the contract state if the wallet was changes
		if (store.user.data?.wallet_type !== this.walletType) {
			this[CONTRACT.WALLET_TYPE](store.user.data?.wallet_type || '');
			this.morpherOracle = null;
			this.morpherBridge = null;
			this.morpherOracleLoaded = '';
		}

		if ((!this.portis || !this.walletClient) && this.walletType === 'portis') {
			const result = await portisConfig((params && params.configPortis) || {});
			this.portis = result.portis;
			this.walletClient = result.walletClient;
		}

		if (this.walletType === 'fortmatic') {
			const result = await fortmaticConfig();
			this.fortmatic = result.fortmatic;
			this.walletClient = result.walletClient;

			this[CONTRACT.PROVIDER](await result.fortmatic.getProvider());
		}
		
		if (store.user.data?.portfolios?.length === 0 && !(store.user.data?.wallet_type === 'metamask')) {
			return store.status[ERROR]('NO_PORTFOLIO');
			
			
		}
		if (this.walletType === 'walletconnect') this.startWalletConnect(params);
		if (this.walletType === 'metamask') this.startMetamask(params);
		if (this.walletType === 'portis') this.startPortis();
		if (this.walletType === 'fortmatic') this.startFortmatic(params);
		if (this.walletType === 'morpherwallet') {
			if (!this.morpherwallet || !(await this.morpherwallet.isLoggedIn().isLoggedIn)) {
				if (!this.morpherwallet ) {
					// if the wallet has not been initialised then initialise the wqallet and log the user in
					const walletInit = await morpherWalletConfig();

					this.morpherwallet = walletInit.morpherwallet;
					
					this.morpherwallet.setLanguage(i18n.locale);

					this.morpherwallet.onSend(async () => {
						store.modals.toggleMainchainTransferModal(true);
					});

					this.walletClient = walletInit.walletClient;

					this[CONTRACT.PROVIDER](await walletInit.provider);
				}

				const isLoggedIn = await getLoggedIn(this); 

				this.morpherwallet.on2FAUpdate(async (method: any, enabled: any) => {
					if (enabled) {
						UserService.sendAnalytics({event:'add_2fa', data: {
							method,
						}})
					}
				})
	
				this.morpherwallet.onRecoveryUpdate(async (method: any, enabled: any) => {

					if (enabled) {
						UserService.sendAnalytics({event:'add_recovery', data: {
							method,
						}})
					}
				})					


				this.morpherwallet.onLogin(async (walletAddress: any, walletEmail: any) => {
					const provider = await this.morpherwallet.getProvider();


					this.morpherwallet.onLogin(() => {});
					this.morpherwallet.onClose(() => {});

					const hasSocialRecovery = await this.morpherwallet.hasSocialRecoveryMethods();
					this[CONTRACT.HAS_SOCIAL_RECOVERY](hasSocialRecovery);

					await this[CONTRACT.IS_LOGGED_IN_WALLET](true);

					this.startMorpherwallet( params);
				});

				await this[CONTRACT.IS_LOGGED_IN_WALLET](isLoggedIn);

				if ((this.isLoggedInWallet || (params && params.order) || (params && params.order)) && this.walletClient) {
					this.morpherwallet.onClose(async () => {
						EventBus.$emit('walletLoginFailed');
					});
					this.morpherwallet.loginWallet();
				}
			} else {

				this.morpherwallet.on2FAUpdate(async (method: any, enabled: any) => {
					console.log('on2FAUpdate', method, enabled)
				})
	
				this.morpherwallet.onRecoveryUpdate(async (method: any, enabled: any) => {
					console.log('onRecoveryUpdate', method, enabled)
				})

				this.morpherwallet.onSend(async () => {
					store.modals.toggleMainchainTransferModal(true);
				});

				// dont re-initialise the wallet if it is already logged in
				await this[CONTRACT.IS_LOGGED_IN_WALLET](true);

				this.startMorpherwallet(params);
			}
		}
		if (this.lastTransactionDate === null)this[CONTRACT.LAST_TRANSACTION_DATE](Date.now());


		await this.networkCheck();
		if (window.ethereum && window.ethereum.isMetaMask) {
			window.ethereum.autoRefreshOnNetworkChange = false;
			return this[CONTRACT.WALLETCLIENT_MAINCHAIN](null);
		}
	},
	/*
	 * Save the zero wallet provider to the state so that the wallet does not have to be initialised again after login
	 */
	setMorpherWalletProvider(params: any) {
		this.morpherwallet = params.morpherwallet;
		this.walletClient = params.walletClient;
		this[CONTRACT.PROVIDER](params.provider);
	},

	/**
	 * Set the "allow mainchain" flag if the current page is allowed to transaact on mainchain
	 */
	async setMainchain(params: any) {
		this[CONTRACT.ALLOW_MAINCHAIN](params);
		if (!params) {
			await this.setNetwork('sidechain');
		}
		return await this.networkCheck();
	},

	/**
	 * Set the expected network for the transaction
	 */
	async setNetwork(params: any) {
		this[CONTRACT.TRANSACT_ON_NETWORK]( params);
	},

	/**
	 * Check if the wallet is currently logged in
	 * @param params
	 */
	async checkWalletLoggedIn() {
		try {
			if (this.walletType === 'fortmatic') {
				const timestamp = Date.now();

				// re-initialise fortmatic if it was initialised more than 1 hour ago
				if (!this.walletInitialisationDate || Number(this.walletInitialisationDate) < timestamp - 3600000) {
					const result = await fortmaticConfig();
					this.fortmatic = result.fortmatic;
					this.walletClient = result.walletClient;

					this[CONTRACT.PROVIDER](await result.fortmatic.getProvider());
					this.walletInitialisationDate = timestamp;
				}

				if (this.fortmatic && this.fortmatic.user) {
					let isLoggedIn = await this.fortmatic.user.isLoggedIn();
					if (!isLoggedIn) {
						await this.fortmatic.fortmatic.user.login();
					}

					const account = await this.walletClient?.getAddresses();

					if (!account || account.length === 0 || !account[0]) {
						this.fortmatic.user.logout();
						isLoggedIn = false;
					} else {
						await this[CONTRACT.ETH_ADDRESS](account[0].toLowerCase());

						if (
							!this.eth_address ||
							store.user.data?.eth_address !== this.eth_address.toLowerCase() ||
							account[0].toLowerCase() !== this.eth_address.toLowerCase()
						) {
							if (!store.user.data?.migrate_user_id && router.currentRoute.value.name !== 'Migrate' ) {
								this[CONTRACT.WRONG_WALLET]( true);
								await this.fortmatic.user.logout();
								isLoggedIn = false;
								return this[CONTRACT.IS_LOGGED_IN_WALLET](false);
							} else {
								this.eth_address = store.user.data?.eth_address || '';
							}
						}
					}
					return this[CONTRACT.IS_LOGGED_IN_WALLET](isLoggedIn);
				} else {
					return this[CONTRACT.IS_LOGGED_IN_WALLET](false);
				}
			}

			if (this.walletType === 'portis') {
				if (this.portis) {
					const isLoggedIn = await this.portis.isLoggedIn();
					return this[CONTRACT.IS_LOGGED_IN_WALLET]( isLoggedIn);
				} else {
					return this[CONTRACT.IS_LOGGED_IN_WALLET](false);
				}
			}
		} catch {
			return this[CONTRACT.IS_LOGGED_IN_WALLET]( false);
		}
	},

	async setWalletClient(client: any) {
		this.walletClient = client;
	},


	async startWalletConnect(params: any) {
		// Check if ethereum exists on the window object
		const provider = await EthereumProvider.init({
			projectId: '4823d29af138b15b9c4cec762fb1916e',
			metadata: {
				name: 'Morpher',
				description: 'Morpher Trading Web Application',
				url: 'https://www.morpher.com', // origin must match your domain & subdomain
				icons: ['https://www.morpher.com/favicon.ico']
			},
			showQrModal: true,
			//chains: [210],

			optionalChains: [137],

			/*Optional - Add custom RPCs for each supported chain*/
			rpcMap: {
				137: 'http://ec2-3-68-113-82.eu-central-1.compute.amazonaws.com:8546'
			}
		})
		
		this.startedWalletMutation(true);
		if (!this.isLoggedInMetamask) await this.getCurrentWalletAddress();



		this.walletClient = getWalletClient(provider, store.user.data?.blockchain_info);

		this[CONTRACT.PROVIDER](provider);

		// Check  the network if the netwrork changes so thet the user cannot trade on the incorrect netowork
		
			provider.on('chainChanged', async (networkId: any) => {
				if (this.walletType !== 'metamask') {
					return;
				}

				await this.networkCheck();

				if (!this.wrongNetwork) {
					if (!this.currentChainValid) {
						
						await store.modals.toggleNetworkMetamaskModal(false);
					}
				}

				await this.networkCheck();
			});

			// Refresh the account info inf the account chnages so that the user cannot trade on the incorrect account
			provider.on('accountsChanged', async (accounts: any) => {
				await this.getCurrentWalletAddress();
				if (!this.wrongWallet) {
					await this.startMetamask({});
				}
			});
		

		await this.networkCheck();

		if (!this.wrongNetwork) {
			if (this.currentChainValid) {
				EventBus.$emit('resetState');
			} else {
				await this.setupContracts();
				await this.getEthBalance();
				this.walletInitialisationDate = Date.now();
			}
		} else {
			
			EventBus.$emit('resetState');
		}
	},

	/**
	 * Start Metamask Wallet Setup
	 
	 * @param state
	 * @returns {Promise<Vue | any>}
	 */
	async startMetamask(params: any) {
		// Check if ethereum exists on the window object
		if (!window.ethereum) return EventBus.$emit('resetState');
		const provider = window.ethereum;
		if (!provider.isMetaMask) return EventBus.$emit('resetState');

		this['startedWalletMutation'](true);
		if (!this.isLoggedInMetamask) await this.getCurrentWalletAddress();

		this.walletClient = getWalletClient(provider, store.user.data?.blockchain_info);

		this[CONTRACT.PROVIDER](provider);

		// Check  the network if the netwrork changes so thet the user cannot trade on the incorrect netowork
		if (provider && provider.isMetaMask) {
			provider.autoRefreshOnNetworkChange = false;
			provider.on('chainChanged', async (networkId: any) => {
				if (this.walletType !== 'metamask') {
					return;
				}

				await this.networkCheck();

				if (!this.wrongNetwork) {
					if (!this.currentChainValid) {
						await store.modals.toggleNetworkMetamaskModal(false);
					}
				}

				await this.networkCheck();
			});

			// Refresh the account info inf the account chnages so that the user cannot trade on the incorrect account
			provider.on('accountsChanged', async (accounts: any) => {
				await this.getCurrentWalletAddress();
				if (!this.wrongWallet) {
					await this.startMetamask({});
				}
			});
		}

		await this.networkCheck();


		if (!this.wrongNetwork) {
			if (this.currentChainValid) {
				if (params && params.modalShowable) await store.modals.toggleNetworkMetamaskModal(true);
				EventBus.$emit('resetState');
			} else {
				await store.modals.toggleNetworkMetamaskModal(false);
				await this.setupContracts();
				await this.getEthBalance();
				this.walletInitialisationDate = Date.now();
			}
		} else {
			if (params && params.modalShowable) await store.modals.toggleNetworkMetamaskModal(true);
			EventBus.$emit('resetState');
		}
	},
	/*
	 * Log the user out of fortmatic and reset the constract state
	 */
	async fortmaticLogout(params = null) {
		await await this.fortmatic.user.logout();
		this[CONTRACT.IS_LOGGED_IN_WALLET](false);
		await this[CONTRACT.ETH_ADDRESS]('');
	},
	/**
	 * Start Fortmatic Wallet Setup
	 
	 * @param state
	 * @param rootState
	 * @param params
	 * @returns {Promise<*>}
	 */
	async startFortmatic(params: any = null) {
		this[CONTRACT.LOADING_PORTIS](true);

		// check if the user is logged in
		const isLoggedIn = await this.fortmatic.user.isLoggedIn();
		await this[CONTRACT.IS_LOGGED_IN_WALLET](isLoggedIn);

		if ((this.isLoggedInWallet || (params && params.order) || (params && params.order)) && this.walletClient) {
			this.walletInitialisationDate = Date.now();
			const [err] = await to(this.walletClient.getAddresses());
			if (err) return store.status[ERROR](err); // err.message == "User denied signing in"

			this.refreshFortmaticData();
			this[CONTRACT.LOADING_PORTIS](false);
		} else {
			this[CONTRACT.LOADING_PORTIS](false);
		}
	},
	/**
	 * Start Morpherwallet Wallet Setup
	 
	 * @param state
	 * @param rootState
	 * @param params
	 * @returns {Promise<*>}
	 */
	async startMorpherwallet(params = null) {
		if (router.currentRoute.value.name === 'Migrate') {
			return;
		}
		this[CONTRACT.LOADING_PORTIS](true);
		// set the login state
		if (this.isLoggedInWallet) {
			this.walletInitialisationDate = Date.now();
			const accounts = await this.walletClient?.getAddresses();
			if (accounts) await this[CONTRACT.ETH_ADDRESS]( accounts[0] ? accounts[0].toLowerCase() : '');

			this['startedWalletMutation'](true);
			this.wrongNetwork = false;
			if (store.user.data?.eth_address !== this.eth_address) {
				if (!store.user.data?.migrate_user_id && router.currentRoute.value.name !== 'Migrate' ) {

					this[CONTRACT.WRONG_WALLET](true);

					this['startedWalletMutation'](false);
					this['isLoggedInMetamaskMutation'](false);
					this.eth_address = '';
				} else {
					this.eth_address = store.user.data?.eth_address || '';
				}

			} else {
				this['isLoggedInMetamaskMutation']( true);
				this[CONTRACT.LOADING_PORTIS](false);
				this[CONTRACT.WRONG_WALLET](false);
				await this.setupContracts();
				await this.getEthBalance();
			}

			setTimeout(async () => {
				if (store.user.data?.wallet_type == 'morpherwallet' && store.user.data?.eth_address == this.eth_address && this.morpherwallet && this.morpherwallet.hasSocialRecoveryMethods && store.user.data?.timestamp && Number(store.user.data?.timestamp) < Date.now() - (1000 * 60 * 60 * 24 * 14)) {
					if (!store.user.data?.payload.security_check || Number(store.user.data?.payload.security_check) < Date.now() - 1000 * 60 * 60 * 24 * 14) {
						
						if (!store.modals.anyModalOpen) {
							const hasSocialRecovery = await this.morpherwallet.hasSocialRecoveryMethods();
							if (hasSocialRecovery === false) {
								store.modals.toggleWalletRecoveryModal(true);
							}
						}
					}
				}
			}, 1000)
		}
	},
	/**
	 * Start Portis Wallet Setup
	 
	 * @param state
	 * @param rootState
	 * @param params
	 * @returns {Promise<*>}
	 */
	async startPortis(params: any = null) {
		if (params && params.load) this.portisIFrameInterval();
		this[CONTRACT.LOADING_PORTIS]( true);

		// Check for any unexpected portis errors
		//this.portis.onError(error => {
		// this.portisError = error.message;
		//  return store.status[ERROR]('PORTIS_ERROR');
		// });

		EventBus.$on('portisLogin', async () => {
			this.refreshPortisData();
		});

		this.portis.setDefaultEmail(store.user.data?.email);

		// check if the user is logged in
		await this.portis.isLoggedIn().then(async ({ error, result }: { error: any; result: any }) => {
			// set the login state
			await this[CONTRACT.IS_LOGGED_IN_WALLET](result);
			if (error) {
				this.portisError = error;
				return store.status[ERROR]('PORTIS_ERROR');
			}
		});

		if (this.isLoggedInWallet) {
			this.walletInitialisationDate = Date.now();
			this.refreshPortisData();
		}

		if ((this.isLoggedInWallet || (params && params.order)) && this.walletClient) {
			this[CONTRACT.PROVIDER](this.portis.provider);

			const [err] = await to(this.walletClient.getAddresses());
			if (err) return store.status[ERROR](err); // err.message == "User denied signing in"

			this.refreshPortisData();
			this[CONTRACT.LOADING_PORTIS](false);
		} else {
			this[CONTRACT.LOADING_PORTIS](false);
		}
	},

	async refreshFortmaticData() {
		// check if the user is logged in
		const isLoggedIn = await this.fortmatic.user.isLoggedIn();
		await this[CONTRACT.IS_LOGGED_IN_WALLET](isLoggedIn);

		if (this.isLoggedInWallet) {
			this.wrongNetwork = false;

			await this.getCurrentWalletAddress();

			this.startedWalletMutation(true);
			this.isLoggedInMetamaskMutation(true);

			if (store.user.data?.eth_address !== this.eth_address) {
				if (!store.user.data?.migrate_user_id && router.currentRoute.value.name !== 'Migrate' ) {

					this[CONTRACT.WRONG_WALLET](true);

					this.startedWalletMutation(false);
					this.isLoggedInMetamaskMutation(false);
				} else {
					this.eth_address = store.user.data?.eth_address || '';
				}

				if (this.eth_address)
					store.user.updateUserPayload({ payloadOption: 'incorrect_wallet', payloadValue: this.eth_address });
			} else {
				this[CONTRACT.WRONG_WALLET](false);
				await this.setupContracts();
				await this.getEthBalance();
			}
		}
	},

	async refreshPortisData() {
		// check if the user is logged in
		await this.portis.isLoggedIn().then(async ({ error, result }: { error: any; result: any }) => {
			// set the login state
			await this[CONTRACT.IS_LOGGED_IN_WALLET](result);
			if (error) {
				this.portisError = error;
				return store.status[ERROR]('PORTIS_ERROR');
			}
		});

		if (this.isLoggedInWallet) {
			this.wrongNetwork = false;
			this.startedWalletMutation(true);
			this.isLoggedInMetamaskMutation(true);
			await this.getCurrentWalletAddress();
			EventBus.$on('portisLogout', async () => {
				await this.logoutWallet();
			});

			if (store.user.data?.eth_address !== this.eth_address) {
				if (!store.user.data?.migrate_user_id && router.currentRoute.value.name !== 'Migrate' ) {

					this[CONTRACT.WRONG_WALLET](true);
					this.startedWalletMutation(false);
					this.isLoggedInMetamaskMutation(false);
				} else {
					this.eth_address = store.user.data?.eth_address || ''
				}

				if (this.eth_address)
					store.user.updateUserPayload({ payloadOption: 'incorrect_wallet', payloadValue: this.eth_address });
			} else {
				this[CONTRACT.WRONG_WALLET]( false);
				await this.updateWalletAddress( this.portis.provider);
				await this.setupContracts();
				await this.getEthBalance();
			}
		}
	},
	async connectToMainchain() {
		if (this.walletType === 'morpherwallet') {
			const config = {
				show_transaction: true,
				confirm_transaction: true,
				show_message: true,
				confirm_message: true,
				env: import.meta.env.VITE_MORPHER_WALLET_ENV,
				locale: Cookie.get('locale')
			};

			const morpherwallet = new MorpherWallet(
				import.meta.env.VITE_MORPHER_WALLET_MAINCHAIN_ADDRESS,
				Number(import.meta.env.VITE_MAINCHAIN_ID),
				config
			);
			const provider = await morpherwallet.getProvider();

			
			const walletClientMainchain = getWalletClient(provider, store.user.data?.blockchain_info)

			morpherwallet.onLogin(async (walletAddress: any, walletEmail: any) => {
				const account = await walletClientMainchain.getAddresses();
				if (account[0].toLowerCase() !== store.user?.activePortfolio?.eth_address?.toLowerCase()) {
					return store.status[ERROR]('INCORRECT_WALLET');
				}

				return this[CONTRACT.WALLETCLIENT_MAINCHAIN]( walletClientMainchain);
			});
			await morpherwallet.loginWallet();
		}
		if (this.walletType === 'portis') {
			
			const Portis = (await import('@portis/web3')).default;

			let portis;

			if (Number(import.meta.env.VITE_MAINCHAIN_ID) === 1) portis = new Portis(import.meta.env.VITE_PORTIS_API_KEY, 'mainnet');
			else portis = new Portis(import.meta.env.VITE_PORTIS_API_KEY, 'kovan');

			
			const walletClientMainchain = getWalletClient(portis.provider, store.user.data?.blockchain_info)

			const account = await walletClientMainchain.getAddresses();

			if (account[0].toLowerCase() !== store.user.activePortfolio?.eth_address?.toLowerCase()) {
				return store.status[ERROR]('INCORRECT_WALLET');
			}

			return this[CONTRACT.WALLETCLIENT_MAINCHAIN]( walletClientMainchain);
		}
		if (this.walletType === 'fortmatic') {
			const Fortmatic = require('fortmatic');
			let fm;
			if (Number(import.meta.env.VITE_MAINCHAIN_ID) === 1) fm = new Fortmatic(import.meta.env.VITE_FORTMATIC_API_KEY);
			else fm = new Fortmatic(import.meta.env.VITE_FORTMATIC_API_KEY, 'kovan');


			const walletClientMainchain = getWalletClient(await fm.getProvider(), store.user.data?.blockchain_info)

			const account = await walletClientMainchain.getAddresses();
			if (account[0].toLowerCase() !== store.user.activePortfolio?.eth_address?.toLowerCase()) {
				return store.status[ERROR]('INCORRECT_WALLET');
			}

			return this[CONTRACT.WALLETCLIENT_MAINCHAIN](walletClientMainchain);
		}
		if (this.walletType === 'metamask') {
			const provider = window.ethereum;
			if (!provider.isMetaMask) {
				return;
			}

			const walletClientMainchain = getWalletClient(provider, store.user.data?.blockchain_info)

			const account = await walletClientMainchain.getAddresses();
			if (account[0].toLowerCase() !== store.user.activePortfolio?.eth_address?.toLowerCase()) {
				return store.status[ERROR]('INCORRECT_WALLET');
			}

			return this[CONTRACT.WALLETCLIENT_MAINCHAIN](walletClientMainchain);
		}
	},
	/**
	 * Update callback function when wallet changed
	 * @param state
	 * @param currentProvider
	 * @returns {Promise<void>}
	 */
	async updateWalletAddress(currentProvider: any) {
		currentProvider.on('update', async (result: any) => {
			if (result.selectedAddress && result.selectedAddress.toLowerCase() !== this.eth_address) {
				this.eth_address = result.selectedAddress.toLowerCase();
			}
		});
	},
	/**
	 * Fetching morpherAlpha
	 * @param state
	 * @param morpherAlpha
	 */
	setMorpherAlpha(morpherAlpha: any) {
		this.morpherAlpha = morpherAlpha;
	},
	/**
	 * Fetching morpherPlatform
	 * @param state
	 * @param morpherPlatform
	 */
	setMorpherPlatform(morpherPlatform: any) {
		this.morpherPlatform = morpherPlatform;
	},

	setMorpherOracle(morpherOracle: any) {
		this.morpherOracle = morpherOracle;
	},
	setMorpherBridge(morpherBridge: any) {
		this.morpherBridge = morpherBridge;
	},
	/**
	 * Fetching morpherAlpha and morpherPlatform
	 * @param state
	 
	 * @returns {Promise<void>}
	 */
	async setupContracts() {
		try {
			if (this.morpherOracleLoaded === '' && this.walletClient) {
				this.morpherOracleLoaded = 'loading';


				const contractBridge = getContract(ESideChainTransaction.BRIDGE, store.user.data?.blockchain_info, this.walletClient)
				const contractInstance = getContract(ESideChainTransaction.TRADE, store.user.data?.blockchain_info, this.walletClient)

				this.setMorpherOracle(contractInstance);
				this.setMorpherBridge(contractBridge);
				this.morpherOracleLoaded = 'loaded';
			}
		} catch (err: any) {
			console.log('Error loading contract: ' + err.toString());
		}
	},
	/**
	 * Fetching current Metamask address
	 
	 * @param state
	 * @param rootState
	 * @param browserType
	 * @returns {Promise<*>}
	 */
	async getCurrentWalletAddress() {
		let errEnable: Error| null | undefined, addresses: string[] | null | undefined;
		if (this.walletType === 'metamask') {
			// Toggle sign in modal only if no portfolios created

			if (window.ethereum) {
				window.ethereum.autoRefreshOnNetworkChange = false;

				this.isLoggedInMetamaskMutation(false);

				[errEnable, addresses] = await to(window.ethereum.request({ method: 'eth_requestAccounts' }));

				if (errEnable) {
					if (!errEnable.message.includes('Already processing')) {
						EventBus.$emit('resetState');
						return store.status[ERROR]({ message: 'METAMASK_CONNECT_REJECTED' });
						
					}
				} else {
					this.isLoggedInMetamaskMutation(true);
					await this.networkCheck();
					if (addresses)
						this.eth_address = addresses[0] ? addresses[0].toLowerCase() : '';
				}
			}

			if (store.user.data?.eth_address !== this.eth_address) {
				if (!store.user.data?.migrate_user_id && router.currentRoute.value.name !== 'Migrate' ) {

					this[CONTRACT.WRONG_WALLET]( true);
					this.startedWalletMutation(false);
					this.isLoggedInMetamaskMutation(false);
				} else {
					this.eth_address = store.user.data?.eth_address || '';
				}

				if (this.eth_address)
					store.user.updateUserPayload({ payloadOption: 'incorrect_wallet', payloadValue: this.eth_address });
			} else {
				this[CONTRACT.WRONG_WALLET]( false);
			}
		} else {
			if (this.walletClient) {
				[errEnable] = await to(this.walletClient.getAddresses());
				if (errEnable && errEnable.message) {
					EventBus.$emit('resetState');
					return store.status[ERROR]({ message: errEnable.message });
				}

				const accounts = await this.walletClient.getAddresses();
				await this[CONTRACT.ETH_ADDRESS]( accounts[0] ? accounts[0].toLowerCase() : '');
			}
		}
	},
	/**
	 * Checking current network
	 * @param state
	 
	 * @returns {Promise<*>}
	 */
	async networkCheck() {
		try {
			if (this.networkCheckTimer) {
				clearTimeout(this.networkCheckTimer)
				this.networkCheckTimer = null;
			}
			if (this.walletType !== 'metamask') {
				this.wrongNetwork = false;
				this.currentChainValid = null;
				return;
			}
			const provider = window.ethereum;
			const result = await provider.request({ method: 'eth_chainId' });

			// 3 for Ropsten Network, 1 for Main Network, 21 for sidechain
			const mainchain_id = this.allowMainchain ? Number(import.meta.env.VITE_MAINCHAIN_ID) : 0;

			
			const isWrongNetwork = Number(result) !== Number(store.user.data?.blockchain_info?.chain_id) && Number(result) !== Number(mainchain_id);

			this.wrongNetwork = isWrongNetwork;

			const isRealSidechain = await this.checkIsRealSidechain();

			if (!isWrongNetwork) {
				if (Number(result) === Number(mainchain_id)) {
					this.wrongNetwork = false;
					this[CONTRACT.CURRENT_CHAIN_VALID]( null);
				} else {
					if (!isRealSidechain) {
						this.wrongNetwork = true;
						this[CONTRACT.CURRENT_CHAIN_VALID]('same_not_valid');
					} else {
						this[CONTRACT.CURRENT_CHAIN_VALID]( null);
					}
				}
			} else {
				this[CONTRACT.CURRENT_CHAIN_VALID]( 'different');
			}

			this[CONTRACT.NETWORK_ID]( Number(result));
		} catch (err) {
			store.status[ERROR](err);
		}
	},
	/**
	 * Adding MPR Token to the Metamask
	 * @param state
	 * @param eth_address
	 */
	addToken(eth_address: string) {
		if (!localStorage.getItem('morpher_' + eth_address) && this.walletType === 'metamask') {
			// should show modal for adding a token into the metamask
			const provider = window.ethereum;
			provider.sendAsync(
				{
					method: 'metamask_watchAsset',
					params: {
						type: 'ERC20',
						options: {
							address: store.user.data?.blockchain_info?.oracle,
							symbol: 'MPR',
							decimals: '18',
							image: 'https://s3.eu-central-1.amazonaws.com/cdn.morpher.com/favicon.png'
						}
					},
					id: Math.round(Math.random() * 100000)
				},
				(err: Error, added: any) => {
					if (err) return store.status[ERROR](err);
					if (added.result) {
						localStorage.setItem('morpher_' + eth_address, '1');
						return store.status[SUCCESS]('TOKEN_TRACKING');
					} else localStorage.removeItem('morpher_' + eth_address);
				}
			);
		}
	},
	logoutWallet() {
		this.startedWallet = false;
		this.eth_address = '';
		this.wrongNetwork = false;
		this.currentChainValid = null;

		this.isLoggedInMetamaskMutation(false);
	},
	/**
	 * Method invoked every second from DefaultLayouts Metamask Interval
	 * Check if user is logged in into Metamask or not.
	 * Turning isLoggedInMetamask to true if yes and false if not -> Changing header
	 * - Fires fetching of new portfolio if eth addresses of current and metamask are not same
	 * - This automatically triggers creation of new portfolio if portfolio doesn't exists
	 
	 * @param state
	 * @param rootState
	 * @param wallets
	 * @returns {Promise<*>}
	 */
	async toggleIsLoggedInMetamask(wallets: string[]) {
		if (wallets.length > 0) {
			const current = wallets[0].toLowerCase();
			if (this.wrongNetwork === null) await this.networkCheck();

			if (store.user.data && !Util.isEmptyObject(store.user.data) && !this.wrongNetwork && this.startedWallet) {
				const filtered = store.user.data?.portfolios?.filter((e : any) => e.eth_address === current);
				/** Create new portfolio in case of new metamask address **/
				if (filtered && filtered.length > 0) {

					if (store.user.activePortfolio && store.user.activePortfolio.eth_address !== current) {
						this.eth_address = current;
						await store.modals.togglePortfolioInUseModal(false);
						await this.getEthBalance();
					} else if (store.user.activePortfolio && this.eth_address === '') {
						this.eth_address = store.user.activePortfolio.eth_address || '';
					}
				}
			}
			if (this.walletType === 'metamask') {
				this.isLoggedInMetamaskMutation(true);
			}
		} else {
			if (!this.lastActivePortfolio) {
				this.lastActivePortfolio = true;
				await store.user.setActivePortfolio( store.user.data?.activePortfolio);
			}
			this.isLoggedInMetamaskMutation(false);
		}
	},
	/**
	 * Getting ETH balance via viem getBalance method
	 * Used on deposit page to see if user has some ETH or should request free ETH from faucet
	 * @param state
	 * @returns {Promise<*>}
	 */
	async getEthBalance() {
		//let err, balance: any;

			const publicClient = getPublicClient(store.user.data?.blockchain_info || undefined)

			
			const balance =  await publicClient.getBalance({address: this.eth_address as TAddress});
				
			return parseFloat((Number(balance) / 10**18).toString());
				// return balance
			
		
	},
	async morpherwalletLogout() {
		await await this.morpherwallet.logout();
		this[CONTRACT.IS_LOGGED_IN_WALLET](false);
		await this[CONTRACT.ETH_ADDRESS]( '');
		this[CONTRACT.WRONG_WALLET]( false);
		this[CONTRACT.LOADING_PORTIS]( false);
		this.morpherOracle = null;
		this.morpherBridge = null;
		this.morpherOracleLoaded = '';
	},
	async setBalance(balance_data: any) {
		this[CONTRACT.BALANCE]( balance_data);
	},
	loadingFreeTestEtherAction(loadingFreeTestEther: any) {
		this[CONTRACT.LOADING_FREE_TEST_ETHER](loadingFreeTestEther);
	},
	async openPortisWallet() {
		// enable portis if the user is not logged in. this will request the user to sign in
		if (!this.isLoggedInWallet && this.walletClient) {
			const [err] = await to(this.walletClient.getAddresses());
			if (err) return store.status[ERROR](err);
		}
		if (!this.eth_address) this.refreshPortisData();
		await this.portis.showPortis();
	},
	async openCryptoWallet() {
		if (this.walletType === 'morpherwallet') {
			if (this.morpherwallet) {
				this.morpherwallet.showWallet();
			}
		} else if (this.walletType === 'portis') {
			// enable portis if the user is not logged in. this will request the user to sign in
			if (!this.isLoggedInWallet && this.walletClient) {
				const [err] = await to(this.walletClient.getAddresses());
				if (err) return store.status[ERROR](err);
			}
			if (!this.eth_address) this.refreshPortisData();
			await this.portis.showPortis();
		} else if (this.walletType === 'fortmatic') {
			// enable portis if the user is not logged in. this will request the user to sign in
			if (!this.isLoggedInWallet && this.walletClient) {
				const [err] = await to(this.walletClient.getAddresses());
				if (err) return store.status[ERROR](err); // err.message == "User denied signing in"
			}
			if (!this.eth_address) this.refreshFortmaticData();

			this.fortmatic.user.settings();
		} else if (!this.isLoggedInMetamask) {
			await this.getCurrentWalletAddress();

			// Check network
			if (!this.wrongNetwork) {
				const METAMASK_ID = 'nkbihfbeogaeaoehlefnkodbefgpgknn';
				window.open(`chrome-extension://${METAMASK_ID}/popup.html`);
			}
		}
	},

	setDepositTransactionHash(hash: string) {
		this[CONTRACT.DEPOSIT_TRANSACTION_HASH](hash);
	},
	setLoadingPortis(loadingPortis: boolean) {
		this[CONTRACT.LOADING_PORTIS](loadingPortis);
	},
	setLastTransactionDate(date: number) {
		this[CONTRACT.LAST_TRANSACTION_DATE]( date);
	},
	/**
	 * Interval which checks if portis modal height is bigger than 0 (hack to see if portis modal is displayed or not)
	 * @param state
	 * @param params
	 */
	portisIFrameInterval() {
		clearInterval(this.portisIFrameTimeout);
		this.portisIFrameTimeout = setInterval(() => {
			const portisIFrame = document.getElementsByTagName('iframe')[0];
			if (portisIFrame && this.portisIFrameHeight !== portisIFrame.clientHeight) {
				this.portisIFrameHeight = portisIFrame.clientHeight;
				if (portisIFrame && this.portisIFrameHeight === 0) {
					this[CONTRACT.LOADING_PORTIS]( false);
					clearInterval(this.portisIFrameTimeout);
				}
			}
		}, 500);
	},

	setOverlayState(params: any) {
		this.walletAmount = params.amount || this.walletAmount;
		this.walletState = params.state || this.walletState;
		this.walletMarket = params.market || this.walletMarket;
	},
	/**
	 * Check if network is real Morpher Sidechain.
	 */
	async checkIsRealSidechain() {
		try {
			if (!this.walletClient) return false;

			const tokenContract = await getContract(ESideChainTransaction.TOKEN, store.user.data?.blockchain_info, this.walletClient);

			const symbol = await tokenContract.contract.read.symbol();

			if (symbol === 'MPH') {
				return true;
			}

			return false;
		} catch (e) {
			this.networkCheckTimer = setTimeout(() => {
				 this.networkCheck();
			}, 10000)
			
			return false;
		}
	},

		[CONTRACT.PROVIDER]( provider: any) {
			this.provider = provider;
		},
		[CONTRACT.STARTED_WALLET]( startedWallet: boolean) {
			this.startedWallet = startedWallet;
		},
		[CONTRACT.WALLETCLIENT_MAINCHAIN]( walletClient: any) {
			this.walletClientMainchain = walletClient;
		},
		[CONTRACT.IS_LOGGED_IN_METAMASK]( isLoggedInMetamask: boolean) {
			this.isLoggedInMetamask = isLoggedInMetamask;
		},
		[CONTRACT.HAS_SOCIAL_RECOVERY]( socialRecovery: boolean) {
			this.socialRecovery = socialRecovery;
		},
		[CONTRACT.IS_LOGGED_IN_WALLET]( isLoggedInWallet: boolean) {
			this.isLoggedInWallet = isLoggedInWallet;
		},
		[CONTRACT.ALPHA]( morpherAlpha: boolean) {
			this.morpherAlpha = morpherAlpha;
		},
		[CONTRACT.PLATFORM]( morpherPlatform: boolean) {
			this.morpherPlatform = morpherPlatform;
		},
		[CONTRACT.ORACLE]( morpherOracle: boolean) {
			this.morpherOracle = morpherOracle;
		},
	
		[CONTRACT.BALANCE]( balance: any) {
			this.balance = balance;
		},

		[CONTRACT.LOADING_FREE_TEST_ETHER]( loadingFreeTestEther: boolean) {
			this.loadingFreeTestEther = loadingFreeTestEther;
		},
		[CONTRACT.WALLET_TYPE]( walletType: string) {
			this.walletType = walletType;
		},
		[CONTRACT.CONFIRMATION_TIMEOUT]( confirmationTimeout: boolean) {
			this.confirmationTimeout = confirmationTimeout;
		},
		[CONTRACT.ORDER_COMPLETE_MESSAGE]( orderCompleteMessage: string) {
			this.orderCompleteMessage = orderCompleteMessage;
		},
		
		[CONTRACT.ETH_ADDRESS]( eth_address: string) {
			this.eth_address = eth_address;
		},
		[CONTRACT.DEPOSIT_TRANSACTION_HASH]( hash: string) {
			this.depositTransactionHash = hash;
		},
		[CONTRACT.LOADING_PORTIS]( loadingPortis: boolean) {
			this.loadingPortis = loadingPortis;
		},
		[CONTRACT.LAST_TRANSACTION_DATE]( date: number) {
			this.lastTransactionDate = date;
		},
		[CONTRACT.WRONG_WALLET]( wrongWallet: boolean) {
			this.wrongWallet = wrongWallet;
		},
		[CONTRACT.ALLOW_MAINCHAIN]( allowMainchain: boolean) {
			this.allowMainchain = allowMainchain;
		},
		[CONTRACT.NETWORK_ID]( network_id: number) {
			this.network_id = network_id;
		},
		[CONTRACT.TRANSACT_ON_NETWORK]( transactOnNetwork: string) {
			this.transactOnNetwork = transactOnNetwork;
		},
		[CONTRACT.CURRENT_CHAIN_VALID]( value: 'different' | 'same_not_valid' | null) {
			this.currentChainValid = value;
		},
		
	
},
})