fragment PositionFragment on Position {
    id
    direction
    average_price
    average_spread
    average_leverage
    long_shares
    short_shares
    timestamp
    value
    value_weight
    total_return
    total_return_percent
    total_interest
    stop_loss
    take_profit
    payload
    created_at
    market {
        id
        name
        type
        symbol
        close
        change
        change_percent
    }
}
