<template>
	<section>
		<b-modal :modelValue="showAddMPHMetamaskModal" has-modal-card @close="close">
			<form @submit.prevent="close">
				<div class="modal-card terminal">
					<div class="terminal-content has-text-left">
						<h1 class="has-font-rubik is-size-5 has-text-centered has-text-weight-medium mb-10">
							{{ $t('network.ADD_TOKENS') }}
						</h1>
						<p>{{ $t('network.ADD_MPH_DESC') }}</p>

						<button class="button big-button is-blue mt-4" @click="() => addMPHToMetamask('mainchain')"
							:disabled="network_id !== 1">
							{{
								$t('network.ADD_MPH_BTN', {
									network: $t('network.MAINCHAIN')
								})
							}}
						</button>
						<p v-show="network_id !== 1" class="help">
							{{ $t('network.HELP_MAIN') }}
						</p>

						<button class="button big-button is-blue mt-4" @click="() => addMPHToMetamask('sidechain')"
							:disabled="network_id !== Number(chainId) || (network_id === Number(chainId) && currentChainValid && currentChainValid === 'same_not_valid')
								|| false">
							{{
								$t('network.ADD_MPH_BTN', {
									network: $t('network.SIDECHAIN')
								})
							}}
						</button>
						<p v-show="network_id !== Number(chainId) || (network_id === Number(chainId) && currentChainValid && currentChainValid === 'same_not_valid')"
							class="help">
							{{ $t('network.HELP_SIDE') }}
						</p>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useModalsStore } from '@/store/modules/modals';
import EventBus from '@/store/event-bus';
import { copyToClipboard } from '@/helpers/utils';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { i18n } from '@/plugins/i18n';
/* @group Components_Modals */
/*
  <h4> <b> Modal which shows that ethereum network is wrong </b> </h4>
  <br> Example: https://sketch.cloud/s/49kjZ/52VOwnx
*/
export default defineComponent({
	name: 'AddMPHMetamaskModal',
	data() {
		return {
			manuallyAdd: false,
			sidechainURL: 'https://sidechain.morpher.com',
			chainId: import.meta.env.VITE_MORPHER_WALLET_CHAIN_ID
		};
	},
	computed: {
		...mapState(useModalsStore, {
			showAddMPHMetamaskModal: (state) => state.showAddMPHMetamaskModal,
		}),
		...mapState(useContractStore, {
			currentChainValid: (state) => state.currentChainValid,
			network_id: (state) => state.network_id,
		}),

	},
	methods: {
		...mapActions(useModalsStore, {
			toggleAddMPHMetamaskModal: 'toggleAddMPHMetamaskModal',
		}),

		close() {
			EventBus.$emit('resetState');
			this.toggleAddMPHMetamaskModal(false);
		},
		copyTextToClipboard() {
			copyToClipboard(this.sidechainURL, this);
		},
		addNetworkToMetamask() {
			if (!window.ethereum) return;
			window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [this.getNetworkParams()]
			});
		},
		getNetworkParams() {
			const chainRPC = import.meta.env.VITE_SIDECHAIN;
			const scan = import.meta.env.VITE_ETHERSCAN_URL;
			const environment = import.meta.env.VITE_MODE;

			return {
				chainId: '0x15',
				chainName: 'Morpher Sidechain' + (environment === 'development' || environment === 'staging' ? ' - Test' : ''),
				nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
				rpcUrls: [chainRPC],
				blockExplorerUrls: [scan]
			};
		},
		async addMPHToMetamask(chain: string) {
			if (!window.ethereum) return;
			try {
				const wasAdded = await window.ethereum.request({
					method: 'wallet_watchAsset',
					params: {
						type: 'ERC20',
						options: {
							address:
								(chain === 'mainchain'
									? import.meta.env.VITE_MORPHER_TOKEN_MAINCHAIN
									: import.meta.env.VITE_MORPHER_TOKEN_SIDECHAIN) || '',
							symbol: 'MPH',
							decimals: 18,
							image: 'https://assets.coingecko.com/coins/images/12619/small/morpher_200_200.png?1601524084'
						}
					}
				});

				if (wasAdded) {
					Snackbar.open({
						duration: 5000,
						message: i18n.t('network.ADD_SUCCESS'),
						type: 'is-success',
						position: 'is-bottom-left',
						actionText: 'OK',
						queue: true,
						onAction: () => { }
					});
				}
			} catch (error) {
				console.log(error);
			}
		}
	}
});
</script>
<style scoped>
.copy-btn {
	height: 40px;
	width: 3rem;
}

.manual-icon {
	transition: 200ms;
}

.manual-icon.open {
	transform: rotateZ(90deg);
}

.manual-button {
	cursor: pointer;
}
</style>
