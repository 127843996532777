import { defineStore } from 'pinia'
import { PortfolioService } from '@/services/PortfolioService';
import { PORTFOLIO, ERROR, ROOT } from '../mutation-types';
import to from 'await-to-js';
import { getStore } from '..';
let store = {
		contract: {} as any,
		user: {} as any,
		modals:  {} as any,
		status:  {} as any,
		markets:  {} as any,
	
}

export interface PortfoliosState {
	data_home: any[];
	data: any[];
	studentData: any[];
	data_timestamp: number;
	data_filter: any;
	data_home_filter: any;
	data_home_timestamp: number;
	data_student_filter: any;
	data_student_timestamp: number;

}



export const usePortfoliosStore = defineStore('portfolios', {
  state: () => {
	store = getStore()

    const initialState: PortfoliosState = {
      data: [],
      data_home: [],
      studentData: [],
      data_home_timestamp: 0,
      data_home_filter: null,
      data_timestamp: 0,
      data_filter: null,
      data_student_timestamp: 0,
      data_student_filter: null
    };

    return initialState
  },

  
  actions: {
    [PORTFOLIO.ALL]( data: any[]) {
			this.data = data;
		},
		[PORTFOLIO.HOME](data: any[]) {
			this.data_home = data;
		},
		[PORTFOLIO.STUDENT](data: any[]) {
			this.studentData = data;
		},
		reset() {
			this.$reset()
		}
  },
})