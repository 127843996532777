<template>
	<div class="has-font-manrope">
		<!-- currency selection -->
		<div class="has-text-left" v-if="selectCurrency">
			<div class="header-bar-global">
				<button class="button back-btn ml-5" @click="closeCurrency()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('CURRENCY') }}
			</div>


			<div v-if="funds.showWithdrawMethod">
				<div class="payment_method_body has-text-left mt-10 mb-5">
					<p class="is-size-14-spaced">
						{{ $t('funds.WITHDRAW_CHOOSE_CURRENCY') }}
					</p>

					<!-- cash -->
					<div class="withdraw-section mt-15">
						<div class="withdraw-currency-section"
							@click="updateWithdrawCurrency({ chain: 'moonpay', icon: 'eur.svg', name: 'EUR', symbol: 'EUR direct deposit Bank' })">
							<div class="centered-flex-gap">
								<img class="provider-icon" :src="imageURL('@/img/funds/tokens/eur.svg')" />

								<div>
									<p class="currency-symbol-head">EUR <span class="rec-tag new">{{ $t('NEW') }}</span>
									</p>

									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('NETWORK') }}: </span>{{
											$t('funds.MOONPAY_OFFRAMP') }}
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('funds.BANK_TRANSFER_FEE') }}: </span> €{{
											usdFormatter(feeAmount('eur')) }} + 1%
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('funds.VISA_CARD_FEE') }}: </span> €{{
											usdFormatter(feeAmount('eur')) }} + 4.5%
									</p>

								</div>
							</div>
							<img src="@/assets/funds/arrow-right.svg" />
						</div>
					</div>

					<div class="withdraw-section mt-15">
						<div class="withdraw-currency-section"
							@click="updateWithdrawCurrency({ chain: 'moonpay', icon: 'gbp.svg', name: 'GBP', symbol: 'GBP direct deposit Bank' })">
							<div class="centered-flex-gap">
								<img class="provider-icon" :src="imageURL('@/img/funds/tokens/gbp.svg')" />

								<div>
									<p class="currency-symbol-head">GBP<span class="rec-tag new">{{ $t('NEW') }}</span>
									</p>

									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('NETWORK') }}: </span>{{
											$t('funds.MOONPAY_OFFRAMP') }}
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('funds.BANK_TRANSFER_FEE') }}: </span> £{{
											usdFormatter(feeAmount('gbp')) }} + 1%
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('funds.VISA_CARD_FEE') }}: </span> £{{
											usdFormatter(feeAmount('gbp')) }} + 4.5%
									</p>
								</div>
							</div>
							<img src="@/assets/funds/arrow-right.svg" />
						</div>
					</div>




					<div class="withdraw-section mt-15">
						<div class="withdraw-currency-section" @click="
							updateWithdrawCurrency({ chain: 'polygon', icon: 'pol.svg', name: 'POL', symbol: 'POL on Polygon' })
							">
							<div class="centered-flex-gap">
								<img :src="imageURL('@/img/funds/tokens/pol.svg')" class="provider-icon" />
								<div>
									<p class="currency-symbol-head">{{ 'POL' }}<span class="rec-tag popular">{{
										$t('funds.POPULAR') }}</span></p>

									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('NETWORK') }}: </span>{{ $t('funds.POLYGON')
										}}
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('FEE') }}: </span> ${{ usdFormatter(100 *
											balance.mph_rate) }}
									</p>

									<p class="currency-symbol-text mt-2">
										{{ $t('funds.DEPOSIT_UNLOCK_DESC') }}
									</p>
								</div>
							</div>

							<img src="@/assets/funds/arrow-right.svg" />
						</div>
					</div>

					<div class="withdraw-section mt-15">
						<div class="withdraw-currency-section" @click="
							updateWithdrawCurrency({
								chain: 'polygon',
								icon: 'mph.svg',
								name: 'MPH',
								symbol: 'Morher Token on Polygon'
							})
							">
							<div class="centered-flex-gap">
								<img :src="imageURL('@/img/funds/tokens/mph.svg')" class="provider-icon" />
								<div>
									<p class="currency-symbol-head">MPH</p>

									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('NETWORK') }}: </span>{{ $t('funds.POLYGON')
										}}
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('FEE') }}: </span> ${{ usdFormatter(100 *
											balance.mph_rate) }}
									</p>
								</div>
							</div>

							<img src="@/assets/funds/arrow-right.svg" />
						</div>
					</div>

					<!--
						ETH withdrawals are currently disabled - re-enable after uniswap v3 migration 

						<div class="withdraw-section mt-15">
						<div
							class="withdraw-currency-section"
							@click="updateWithdrawCurrency({ chain: 'eth', icon: 'eth.svg', name: 'ETH', symbol: 'ETH on Ethereum' })"
						>
							<div class="centered-flex-gap">
								<img :src="imageURL('@/img/funds/tokens/eth.svg')" class="provider-icon" />
								<div>
									<p class="currency-symbol-head">ETH</p>

									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('NETWORK') }}: </span>{{ $t('funds.ETH') }}
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('FEE') }}: </span> ${{ usdFormatter((network_fee * balance.mph_rate)  + .01) }}
									</p>
								</div>
							</div>

							<img src="@/assets/funds/arrow-right.svg" />
						</div>
					</div> 
					-->
					<div class="withdraw-section mt-15 mb-1">
						<div class="withdraw-currency-section"
							@click="updateWithdrawCurrency({ chain: 'eth', icon: 'mph.svg', name: 'MPH', symbol: 'MPH on Ethereum' })">
							<div class="centered-flex-gap">
								<img :src="imageURL('@/img/funds/tokens/mph.svg')" class="provider-icon" />
								<div>
									<p class="currency-symbol-head">MPH</p>

									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('NETWORK') }}: </span>{{ $t('funds.ETH') }}
									</p>
									<p class="currency-symbol-text">
										<span class="is-weight-700">{{ $t('FEE') }}: </span> ${{
											usdFormatter(network_fee * balance.mph_rate + 0.01) }}
									</p>
								</div>
							</div>

							<img src="@/assets/funds/arrow-right.svg" />
						</div>
					</div>

				</div>
			</div>
			<div v-else>
				<!-- search bar -->
				<div class="mt-15 padded">
					<b-input icon="search" class="funds-search large" expanded id="search-input" ref="search"
						v-model="search" :placeholder="$t('SEARCH')" autocomplete="off" @focus="searchActive = true"
						@input="updateSearchFilter" @change="updateSearchFilter" :icon-right="search ? 'times' : ''"
						icon-right-clickable @icon-right-click="clearSearch" />
				</div>

				<div v-if="cash_filtered.length > 0 || crypto_filtered.length > 0"
					class="payment_method_body has-text-left mt-15">
					<!-- cash -->
					<p class="small-headers" v-if="cash_filtered.length > 0">{{ $t('funds.CASH') }}</p>
					<div class="list-section" v-for="(method, index) in cash_filtered" :key="method.id"
						:class="{ 'list-section-last': index == cash_filtered.length - 1 }"
						@click="updateCurrency(method)">

						<img v-if="method.icon_image" class="icon-image"
							:src="imageURL('@/img/funds/tokens/' + method.icon_image)" />

						<div style="width: 100%">
							<p class="currency-symbol-head">{{ method.name }}</p>
							<p class="currency-symbol">{{ method.symbol }}</p>
						</div>
						<img src="@/assets/funds/arrow-right.svg" />
					</div>
					<!-- crypto -->
					<p class="small-headers" v-if="crypto_filtered.length > 0">{{ $t('CRYPTO') }}</p>
					<div class="list-section" v-for="(method, index) in crypto_filtered"
						:key="method.id + '-' + method.chain"
						:class="{ 'list-section-last': index == crypto_filtered.length - 1 }"
						@click="updateCurrency(method)">
						<img v-if="method.icon !== '$'" class="icon-image"
							:src="imageURL('@/img/funds/tokens/' + method.icon)" />
						<div v-else class="image" :style="{
							backgroundColor: method.color ? method.color : 'rgba(0, 195, 134, 0.2);'
						}">
							<p>{{ method.icon }}</p>
						</div>
						<div style="width: 100%">
							<p class="currency-symbol-head">{{ method.name }}</p>
							<p class="currency-symbol">{{ method.symbol }}</p>
						</div>
						<img src="@/assets/funds/arrow-right.svg" />

					</div>
				</div>
				<div v-else class="empty-state-section has-text-centered padded">
					<!-- Currency Search Empty State -->
					<img src="@/assets/funds/search_currency_empty_state.svg" class="empty-img"
						alt="Empty search for currency" />
					<p class="empty-text">{{ $t('funds.NO_CURRENCY_FOUND') }}</p>
				</div>
			</div>
		</div>

		<!-- payment provider selection -->
		<div class="deposit-modal-spacing has-text-left" v-else>
			<div class="header-bar-global">
				<button class="button back-btn" @click="closePaymentMethod()">
					<img src="@/assets/funds/arrow-left.svg" alt="Back" />
				</button>
				{{ $t('funds.PAYMENT_PROVIDER') }}
			</div>
			<div class="payment_provider_body mt-15">
				<div class="payment_provider_section mt-15" @click="selectPaymentMethod('sepa')">
					<img src="@/assets/funds/sepa.svg" class="provider-icon" />

					<div class="payment_provider_section_body">
						<div class="provider_header">
							{{ $t('funds.SEPA') }}
						</div>
						<div class="provider_body">
							<p>
								{{ $t('funds.SEPA_METHODS') }}
							</p>

							<p class="provider-bonus mt-2"><img src="@/assets/funds/zero_fees.svg"> {{
								$t('funds.ZERO_FEES') }}</p>
							<p v-if="funds.default_provider == 'sepa'" class="rec-tag mt-2">{{
								$t('funds.RECOMMENDED') }}</p>
						</div>
					</div>

					<img src="@/assets/funds/arrow-right.svg" class="ml-5px" />
				</div>
				<div v-if="!hideMoonpay" class="payment_provider_section mt-15" @click="selectPaymentMethod('moonpay')">
					<img src="@/assets/funds/moonpay.png" class="provider-icon" />

					<div class="payment_provider_section_body">
						<div class="provider_header">
							{{ $t('funds.MOONPAY') }}
						</div>
						<div class="provider_body">
							<p>
								{{ $t('funds.MOONPAY_METHODS') }}
							</p>

							<p v-if="funds.default_provider == 'moonpay'" class="rec-tag mt-2">{{
								$t('funds.RECOMMENDED') }}</p>
						</div>
					</div>

					<img src="@/assets/funds/arrow-right.svg" class="ml-5px" />
				</div>



				<div class="payment_provider_section mt-15" @click="selectPaymentMethod('advcash')">
					<img src="@/assets/funds/volet.svg" class="provider-icon" />

					<div class="payment_provider_section_body">
						<div class="provider_header">
							{{ $t('funds.ADVCASH') }}
						</div>
						<div class="provider_body">
							<p v-html="$t('funds.ADVCASH_METHODS')">

							</p>

							<p class="kyc-tag mt-2">
								<i class="fas fa-exclamation-circle provider_gift_icon" />
								{{ $t('funds.REQUIRED_KYC') }}
							</p>

							<!-- <p v-if="funds.default_provider == 'advcash'" class="rec-tag mt-2">{{ $t('funds.RECOMMENDED') }}</p> -->
						</div>
					</div>

					<img src="@/assets/funds/arrow-right.svg" class="ml-5px" />
				</div>

				<div v-if="!hidePaypal" class="payment_provider_section mt-15" @click="selectPaymentMethod('paypal')">
					<img src="@/assets/funds/paypal.svg" class="provider-icon" />

					<div class="payment_provider_section_body">
						<div class="provider_header">
							{{ $t('funds.PAYPAL') }}
						</div>
						<div class="provider_body">
							<p v-html="$t('funds.PAYPAL_METHODS')">

							</p>

							<p class="provider-bonus mt-2"><i class="fas fa-gift provider_gift_icon" /> {{
								$t('funds.EARN_BONUS') }}</p>
						</div>
					</div>

					<img src="@/assets/funds/arrow-right.svg" class="ml-5px" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMarketsStore } from '@/store/modules/markets';
import { useUserStore } from '@/store/modules/user';
import { useContractStore } from '@/store/modules/contract';
import { mapActions, mapState } from 'pinia';
import { useModalsStore } from '@/store/modules/modals';
import { useFundsStore } from '@/store/modules/funds';
import { getCurrencyList, show_paypal, hide_ramp, hide_moonpay, sepa_country } from '@/helpers/funding';
import type { Market } from '@/types/schema';
//vuex

/* @group Application_View */
/*
  <h4> <b> Fund Account Pages </b> </h4>
  <br>
  <br> Dedicated routes for deposit and withdrawal flows.
  <br> Works in conjunction with PortfolioFunds page.
*/
export default defineComponent({
	name: 'Deposit',
	data() {
		return {
			cash_filtered: [] as any[],
			crypto_filtered: [] as any[],
			search: null as any,
			searchActive: false,
			selectCurrency: true
		};
	},
	computed: {
		...mapState(useContractStore, {
			balance: (state) => state.balance,
		}),
		...mapState(useUserStore, {
			user: (state) => state.data,
		}),
		...mapState(useFundsStore, {
			funds: (state) => state,
		}),
		...mapState(useMarketsStore, {
			markets: (state) => state.data,
		}),

		hidePaypal() {
			if (!this.user || !(this.user.document_country || this.user.ip_country)) {
				return false;
			}
			if (show_paypal.includes((this.user?.document_country || this.user?.ip_country || '').toUpperCase())) {
				return false;
			} else {
				return true;
			}
		},
		hideRamp() {
			if (!this.user || !(this.user.document_country || this.user.ip_country)) {
				return false;
			}
			if (hide_ramp.includes((this.user?.document_country || this.user?.ip_country || '').toUpperCase())) {
				return true;
			} else {
				return false;
			}
		},
		hideMoonpay() {
			if (!this.user || !(this.user.document_country || this.user.ip_country)) {
				return false;
			}
			if (hide_moonpay.includes(((this.user.document_country || this.user.ip_country || '')).toUpperCase())) {
				return true;
			} else {
				return false;
			}
		},
		network_fee() {
			const gas_price_eth = this.balance.gas_price * 400000;
			const gas_price_usd = (gas_price_eth * this.balance.eth_price) / Math.pow(10, 9);
			const gas_price_mph = gas_price_usd / this.balance.mph_rate;
			return Math.ceil(gas_price_mph / 500) * 500 + 100;
		}
	},
	async mounted() {
		this.updateSearchFilter();
		if (this.funds.currency && !this.funds.showWithdrawMethod) this.selectCurrency = false;
	},
	methods: {

		...mapActions(useFundsStore, {
			updateFunds: 'updateFunds',
		}),
		...mapActions(useModalsStore, {
			toggleDepositWithdrawModal: 'toggleDepositWithdrawModal',
		}),

		feeAmount(currency: string) {
			if (this.balance?.mph_rate) {
				if (this.markets && this.markets.length > 0) {
					let eur_market: Market = {}
					let gbp_market: Market = {}
					this.markets.forEach(market => {
						if (market.name?.toLowerCase() === 'euro' && market.symbol == 'EUR/USD') {
							eur_market = market;
						}
						if (market.name?.toLowerCase() === 'british pound' && market.symbol == 'GBP/USD') {
							gbp_market = market;
						}

					});

					let usd_rate = 100 * this.balance?.mph_rate

					if (currency == 'eur') {
						return usd_rate / (eur_market?.close || 1)
					} else if (currency == 'gbp') {
						return usd_rate / (gbp_market?.close || 1)
					} else {
						return usd_rate
					}



				}
				return 100 * this.balance?.mph_rate
			} else {
				return 0
			}
		},
		closePaymentMethod() {
			this.updateFunds({ showPaymentMethod: false, showWithdrawMethod: false });
		},
		closeCurrency() {
			let currency = null;
			if (this.funds.default_currency) {
				getCurrencyList().cash.forEach(curr => {
					if (curr.symbol == this.funds.default_currency) {
						currency = curr;
					}
				})
			}

			if (currency) {
				this.updateCurrency(currency)
			} else {
				this.toggleDepositWithdrawModal(false);
			}

		},

		updateSearchFilter() {
			this.cash_filtered = [];
			this.crypto_filtered = [];

			getCurrencyList().cash.forEach(method => {
				if (
					!this.search ||
					method.symbol.toLowerCase().includes(this.search.toLowerCase()) ||
					method.name.toLowerCase().includes(this.search.toLowerCase())
				) {
					this.cash_filtered.push(method);

				}
			});

			getCurrencyList().crypto.forEach(method => {
				if (
					!this.search ||
					method.symbol.toLowerCase().includes(this.search.toLowerCase()) ||
					method.name.toLowerCase().includes(this.search.toLowerCase())
				) {
					this.crypto_filtered.push(method);
				}
			});
		},
		clearSearch() {
			this.search = '';
			this.updateSearchFilter();
		},
		updateCurrency(method: any) {

			if (method.id === 'eur') {
				let country = ((this.user ? (this.user.document_country || this.user.ip_country) : '') || '').toUpperCase();
				if (sepa_country.includes(country)) {
					method.provider = 'sepa';
				}
			}

			if (method.provider) {
				if (method.provider == 'coinbase') {
					this.updateFunds({
						currency: method.id.toUpperCase(),
						provider: method.provider,
						chain: method.chain || '',
						showPaymentMethod: false,
						showPaymentType: method.provider
					});
				} else {
					this.updateFunds({
						currency: method.id.toUpperCase(),
						provider: method.provider,
						chain: method.chain || '',
						showPaymentMethod: false
					});
				}
			} else {
				this.updateFunds({ currency: method.id.toUpperCase(), showPaymentMethod: false });
			}
		},
		selectPaymentMethod(provider: any) {
			this.updateFunds({ provider, showPaymentMethod: false, showWithdrawMethod: false, step: null });
		},
		updateWithdrawCurrency(currency: any) {
			currency.symbol = currency.symbol.replace(' on ', ' ' + this.$t('ON') + ' ')
			this.updateFunds({ withdraw_currency: currency, showPaymentMethod: false, showWithdrawMethod: false });
		}
	}
});
</script>
<style scoped>
.header-bar {
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	cursor: pointer;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	font-weight: bold;
}

.deposit_message {
	font-size: 14px;
	color: #666666;
	text-align: center;
}

.deposit_amount {
	width: 100%;
	border: 0;
	text-align: center;
	color: #333333;
	font-size: 50px;
}

.deposit_section {
	border: 1px solid #eae9ed;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding: 1rem;
}

.deposit_content {
	margin-left: 24px;
	margin-right: 24px;
}

.padded {
	margin-left: 24px;
	margin-right: 24px;
}

.header_icon {
	width: 16px;
	float: left;
}

.payment_method_body {
	font-size: 14px;
	line-height: 18px;
	color: #6e6e6e;

	overflow-y: auto;
	max-height: calc(70vh);
	padding-left: 24px;
	padding-right: 24px;
	/* display: inline-block; */
	/* width: 100%; */
}

@media screen and (max-width: 768px) {
	.payment_method_body {
		max-height: calc(100vh - 180px);
	}

	.payment_provider_body {
		min-height: inherit;
	}
}

.agreement_list {
	list-style: disc outside none;
	padding-left: 16px;
}

.currency_section {
	padding-top: 16px;
	padding-bottom: 14px;
	border-bottom: 1px solid #eae9ed;
	cursor: pointer;
}

.currency_icon {
	display: inline-block;
	position: relative;
	top: -10px;
}

.currency_text {
	padding-left: 5px;
	display: inline-block;
}

.currency_section .image {
	width: 20px;
	height: 20px;
	border-radius: 13px;
	background-color: rgba(0, 195, 134, 0.2);
	font-size: 15px;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}

.currency_header {
	font-size: 16px;
	line-height: 20px;
	color: #333333;
	font-weight: 500;
}

.currency_symbol {
	font-size: 14px;
	line-height: 24px;
	color: #666666;
}

.payment_provider_body {
	overflow-y: auto;
	max-height: calc(100vh - 180px);
	display: inline-block;
	width: 100%;
	min-height: 375px;
}

.payment_provider_section {
	border: 1px solid #eae9ed;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding: 1rem;
	display: flex;
	transition: background-color 0.25s;
	align-items: center;
	gap: 0.5rem;
}

.payment_provider_section:hover {
	cursor: pointer;
	transition: background-color 0.25s;
	background-color: #f5f5f5;
}

.provider-icon {
	height: 24px;
	align-self: flex-start;
}

.payment_provider_section_body {
	width: 100%;
}

.rec-tag {
	padding: 5px 8px;
	display: inline-block;
	background: rgba(0, 195, 134, 0.1);
	border-radius: 5px;
	font-size: 12px;
	line-height: 14px;
	color: #007b55;
	font-weight: 500;
}

.rec-tag.new {
	margin-left: 5px;
	border-radius: 16px;
	font-weight: 700;
}

.rec-tag.popular {
	margin-left: 5px;
	background: #F6EBFF;
	color: #8053DA;
	border-radius: 16px;
	font-weight: 700;


}

.kyc-tag {
	padding: 5px 8px;
	display: inline-block;
	background: #F3F2F6;
	border-radius: 5px;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	color: #333333;
}

.provider_header {
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	color: #333;
}

.provider_body {
	font-size: 12px;
	line-height: 18px;
	color: #686475;
	margin-top: 0.5rem;
}

.provider_gift_icon {
	font-size: 14px;
	margin-right: 5px;
}

.provider-bonus {
	color: #1A6DF0;
	padding: 5px 8px;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	background: #E9EEFA;
	border-radius: 5px;
	margin-top: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.token_icon_image {
	width: 20px;
	height: 20px;
}

.empty-state-section {
	margin-top: 60px;
	margin-bottom: 100px;
}

.empty-state-section .empty-img {
	height: 64px;
}

.empty-state-section .empty-text {
	color: #666;
	font-weight: 500;
}

.list-section {
	transition: background-color 0.25s;
	display: flex;
	align-items: center;
	padding: 15px 0.5rem;
	border-radius: 8px;
	border: 1px solid #EAE9ED;
	display: flex;
	margin-top: 16px;

}

.list-section:hover {
	cursor: pointer;
	background: #f5f5f5;
	transition: background-color 0.25s;
}

.list-section-last {
	margin-bottom: 16px;
}

.list-section .image {
	width: 20px;
	height: 20px;
	background-color: rgba(0, 195, 134, 0.2);
	font-size: 15px;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	margin-right: 8px;
}

.list-section .currency-symbol-head {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #333333;
}

.list-section .currency-symbol {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #686475;
	margin-top: 5px;
}

.list-section .icon-image {
	height: 24px;
	margin-right: 8px;
}


.withdraw-section {
	border: 1px solid #eae9ed;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding: 1rem;
	transition: background-color 0.25s;
}

.withdraw-section.disabled {
	background-color: #f5f5f5;
	cursor: not-allowed !important;
}

.withdraw-section:hover {
	background-color: #f5f5f5;
	transition: background-color 0.25s;
	cursor: pointer;
}

.withdraw-currency-section.disabled {
	background-color: #f5f5f5;
}

.withdraw-currency-section.disabled:hover {
	cursor: not-allowed !important;

}

.withdraw-currency-section {
	font-size: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.centered-flex-gap {
	display: flex;
	align-items: center;
	gap: 8px;
}

.currency-symbol-head {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	color: #333;

}

.currency-symbol-text {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: #686475;

}
</style>
